































































// decoradores
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { Equipo } from "@/typings/store/plugins/easyFirestore/equipos";
import SVGIcon from "@/components/SVGIcon.vue";
// tipos

// tipos
@Component({
  components: {
    SVGIcon,
  },
})
export default class InfoEquipo extends Vue {
  @Prop() readonly equipo!: Equipo;
  @Prop({ type: Boolean }) readonly showTitulo!: boolean;

  get id(): string {
    return this.equipo.id || "N/R";
  }

  get nombre(): string {
    return this.equipo.nombre || "N/R";
  }

  get posicion(): string {
    return this.equipo.posicion || "N/R";
  }


  @Emit("cerrar")
  onCerrar(): void {
    return;
  }
}
