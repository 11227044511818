




















































































































import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { mapGetters } from "vuex";
import TablaRecurrente from "@/components/prenominas/TablaRecurrente.vue";
import {
  EstadoPrenomina,
  Prenomina,
} from "@/typings/store/plugins/easyFirestore/prenominas";
import { Usuario } from "@/typings/store/plugins/easyFirestore/usuarios";
import "@/helpers/extensions";
import VistaPreviaPdf from "@/components/VistaPreviaPdf.vue";
import { Archivo } from "@/typings/store/modules/archivos";
import SVGIcon from "@/components/SVGIcon.vue";
import "@/helpers/extensions";
import { MapeoGasto } from "@/typings/store/plugins/easyFirestore/MapeoGastos";

@Component({
  computed: mapGetters({
    getPrenominas: "prenominas/get",
  }),
  components: {
    SVGIcon,
    VistaPreviaPdf,
    TablaRecurrente,
  },
})
export default class VisualizaNomina extends Vue {
  @Prop() readonly ArrayMapeoGastos!: Array<MapeoGasto>;
  @Prop() readonly usuario?: Usuario;

// get cantidadRecurrentes ():number{
//   const numero = this.ArrayMapeoGastos.length;

//   return numero;
// }

  menu2 = false;

  periodo = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
    .toISOString()
    .substr(0, 10);

  get fechaPeriodoF(): string {
    let array_fecha = this.periodo.split("-");
    let dia = "01";
    let mes = array_fecha[1];
    let ano = array_fecha[0];
    console.log(array_fecha);
    return dia + "-" + mes + "-" + ano;
  }

  get periodoActualFormateado(): string {
    const periodo = new Date(
      Date.now() - new Date().getTimezoneOffset() * 60000
    )
      .toISOString()
      .substr(0, 10);

    let array_fecha = periodo.split("-");
    let dia = "01";
    let mes = array_fecha[1];
    let ano = array_fecha[0];
    return dia + "-" + mes + "-" + ano;
  }

  actualizarEstado(item: Prenomina): void {
    let estado: EstadoPrenomina;
    item.estado === "rechazado"
      ? (estado = "aprobado")
      : (estado = "rechazado");
    item.estado = estado;
    this.onCambiarEstado(item);
  }

  @Emit("cambiar-estado")
  onCambiarEstado(prenomina: Prenomina): Prenomina {
    return prenomina;
  }

  @Emit("abrir-info")
  onAbrirInfo(prenomina: Prenomina): Prenomina {
    return prenomina;
  }

  @Emit("abrir-editar")
  onAbrirEditar(prenomina: Prenomina): Prenomina {
    return prenomina;
  }

  @Emit("abrir-eliminar")
  onAbrirEliminar(prenomina: Prenomina): Prenomina {
    return prenomina;
  }

  @Emit("cerrar")
  onCerrar(): void {
    return;
  }

  abrirVistaPrevia(archivo: Archivo): void {
    this.vistaPrevia = {
      model: true,
      url: this.urlArchivo(archivo),
    };
  }

  urlArchivo(media: Archivo): string {
    return media.url ?? URL.createObjectURL(media.file);
  }

  vistaPrevia = {
    model: false,
    url: "",
  };

  cerrarVistaPrevia(): void {
    this.vistaPrevia = {
      model: false,
      url: "",
    };
  }
}
