




























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































// decoradores
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
// tipos
import { HeadersDataTable } from "@/typings/vuetify/vDataTable";
import { Orden } from "@/typings/store/plugins/easyFirestore/ordenes";
import { ItemsTablaOrdenes } from "@/typings/components/ordenes/tabla";
import "@/helpers/extensions";
import { Usuario } from "@/typings/store/plugins/easyFirestore/usuarios";
import { Prenomina } from "@/typings/store/plugins/easyFirestore/prenominas";
//aRCHIVOS
import VistaPreviaPdf from "@/components/VistaPreviaPdf.vue";
import { Archivo } from "@/typings/store/modules/archivos";

@Component({
  components: {
    VistaPreviaPdf,
  },
})
export default class TablaUsuarios extends Vue {
  @Prop() readonly opciones!: boolean;
  @Prop() readonly search!: string;
  @Prop() readonly ordenes!: Array<Orden>;
  @Prop() readonly prenominas!: Array<Prenomina>;
  @Prop() readonly usuario!: Usuario;
  // @Prop() readonly existe!: boolean;

  //@Prop() readonly orden!: Orden | null;

  options = {
    itemsPerPage: -1,
  };
  headerProps = {
    sortIcon: "mdi-chevron-down",
  };

  get headers(): HeadersDataTable {
    return [
      { text: "", value: "estado", sortable: false },
      { text: "Fecha Emision", value: "fechaEmision" },
      { text: "Nro Orden", value: "ordenCompra", sortable: true },
      { text: "Nombre", value: "nombre" },
      { text: "C. C.", value: "centroCosto" },
      { text: "Proveedor", value: "empresa" },
      { text: "Costo Total", value: "total" },
      { text: "Emisor", value: "" },
      { text: "Solicitante", value: "solicitante" },
      { text: "", value: "cargaArchivo", sortable: false },
      { text: "", value: "pdfOP", sortable: false },
      { text: "", value: "pdfOC", sortable: false },
      { text: "", value: "opciones", sortable: false },
    ];
  }

  ordenFecha = 0;
  ordenNumero = 0;
  ordenNombre = 0;
  ordenCC = 0;
  ordenProveedor = 0;
  ordenCostoTotal = 0;
  ordenSolicitante = 0;
  ordenFiltros = 0;
  ordenColor = 0;
  checkboxTipoGasto = false;

  ascenteFecha(): void {
    this.ordenFecha += 1;
    this.ordenFiltros = 1;
  }

  descenteFecha(): void {
    this.ordenFecha -= 1;
    this.ordenFiltros = 1;
  }

  ascenteNumero(): void {
    this.ordenNumero += 1;
    this.ordenFiltros = 2;
  }

  descenteNumero(): void {
    this.ordenNumero -= 1;
    this.ordenFiltros = 2;
  }

  ascenteNombre(): void {
    this.ordenNombre += 1;
    this.ordenFiltros = 3;
  }

  descenteNombre(): void {
    this.ordenNombre -= 1;
    this.ordenFiltros = 3;
  }

  ascenteCC(): void {
    this.ordenCC += 1;
    this.ordenFiltros = 4;
  }

  descenteCC(): void {
    this.ordenCC -= 1;
    this.ordenFiltros = 4;
  }

  ascenteProveedor(): void {
    this.ordenProveedor += 1;
    this.ordenFiltros = 5;
  }

  descenteProveedor(): void {
    this.ordenProveedor -= 1;
    this.ordenFiltros = 5;
  }

  ascenteCostoTotal(): void {
    this.ordenCostoTotal += 1;
    this.ordenFiltros = 6;
  }

  descenteCostoTotal(): void {
    this.ordenCostoTotal -= 1;
    this.ordenFiltros = 6;
  }

  ascenteSolicitante(): void {
    this.ordenSolicitante += 1;
    this.ordenFiltros = 7;
  }

  descenteSolicitante(): void {
    this.ordenSolicitante -= 1;
    this.ordenFiltros = 7;
  }

  ascenteColor(): void {
    this.ordenColor += 1;
    this.ordenFiltros = 8;
  }

  descenteColor(): void {
    this.ordenColor -= 1;
    this.ordenFiltros = 8;
  }

  verificaExistencia = (item: Orden): boolean => {
    let existe = false;
    this.prenominas.forEach((prenomina) => {
      if (prenomina.ordenCompra == item?.ordenCompra?.toString()) {
        //       //orden = prenomina.ordenCompra;
        existe = true;
      }
    });
    return existe;
    //   return existe;
  };

  get items(): ItemsTablaOrdenes {
    return this.ordenes
      .map((orden) => {
        const estado = orden.estado;
        const ordenCompra = orden.ordenCompra;
        const nombre = orden.nombre;
        const fechaEmision = orden.fechaEmision;
        const empresa = orden.empresa;
        const total = orden.total;

        const centroCosto = orden.centroCosto;
        const solicitante = orden.solicitante;
        const color = orden.color;
        const existe = this.verificaExistencia(orden);
        // // verifica en PRENOMINA si existe la orden
        // get existeEnPrenomina(): boolean {
        //   let existe = false;
        //   //  let orden = "";
        //   this.getArrayPrenominas.forEach((prenomina) => {
        //     if (prenomina.ordenCompra == ordenes?.ordenCompra?.toString()) {
        //       //orden = prenomina.ordenCompra;
        //       existe = true;
        //     }
        //   });
        //   return existe;
        // }
        return {
          estado,
          ordenCompra,
          nombre,
          fechaEmision,
          empresa,
          total,
          centroCosto,
          solicitante,
          color,
          existe,

          data: orden,
        };
      })
      .sort((sesionA, sesionB) => {
        if (this.ordenFiltros === 1) {
          if (this.ordenFecha === 0) {
            return (
              parseInt(sesionA.fechaEmision) - parseInt(sesionB.fechaEmision)
            );
          }
          if (this.ordenFecha === 1) {
            return (
              parseInt(sesionB.fechaEmision) - parseInt(sesionA.fechaEmision)
            );
          }
        }
        if (this.ordenFiltros === 2) {
          if (this.ordenNumero === 0) {
            return (
              parseInt(sesionA.ordenCompra.toString()) -
              parseInt(sesionB.ordenCompra.toString())
            );
          }
          if (this.ordenNumero === 1) {
            return (
              parseInt(sesionB.ordenCompra.toString()) -
              parseInt(sesionA.ordenCompra.toString())
            );
          }
        }

        if (this.ordenFiltros === 3) {
          if (this.ordenNombre === 0) {
            return sesionA.nombre.localeCompare(sesionB.nombre);
          }
          if (this.ordenNombre === 1) {
            return sesionB.nombre.localeCompare(sesionA.nombre);
          }
        }
        if (this.ordenFiltros === 4) {
          if (this.ordenCC === 0) {
            return (
              parseInt(sesionA.centroCosto) - parseInt(sesionB.centroCosto)
            );
          }
          if (this.ordenCC === 1) {
            return (
              parseInt(sesionB.centroCosto) - parseInt(sesionA.centroCosto)
            );
          }
        }
        if (this.ordenFiltros === 5) {
          if (this.ordenProveedor === 0) {
            return sesionA.empresa.localeCompare(sesionB.empresa);
          }
          if (this.ordenProveedor === 1) {
            return sesionB.empresa.localeCompare(sesionA.empresa);
          }
        }
        if (this.ordenFiltros === 6) {
          if (this.ordenCostoTotal === 0) {
            return sesionA.total - sesionB.total;
          }
          if (this.ordenCostoTotal === 1) {
            return sesionB.total - sesionA.total;
          }
        }
        if (this.ordenFiltros === 7) {
          if (this.ordenSolicitante === 0) {
            return sesionA.solicitante.localeCompare(sesionB.solicitante);
          }
          if (this.ordenSolicitante === 1) {
            return sesionB.solicitante.localeCompare(sesionA.solicitante);
          }
        }
        if (this.ordenFiltros === 8) {
          if (this.ordenColor === 0) {
            return sesionA.color.localeCompare(sesionB.color);
          }
          if (this.ordenColor === 1) {
            return sesionB.color.localeCompare(sesionA.color);
          }
        }
        // if (this.ordenFiltros === 8) {
        //   if (this.ordenRecurrente === 0) {
        //     return sesionA.recurrente.localeCompare(sesionB.recurrente);
        //   }
        //   if (this.ordenRecurrente === 1) {
        //     return sesionB.recurrente.localeCompare(sesionA.recurrente);
        //   }
        // }
        return sesionA.color.localeCompare(sesionB.color);
      });
  }

  actualizarEstadoAprobado(item: Orden): void {
    if (item.estado === "aprobado") {
      item.estado = "pendiente";
      item.color = "#D5D8E3";
      item.autorizado = this.usuario.nombre;
      // item.autorizado = "11111111-1";
    }
    this.onCambiarEstado(item);
  }

  actualizarEstadoRechazado(item: Orden): void {
    if (item.estado === "rechazado") {
      item.estado = "pendiente";
      item.color = "#D5D8E3";
      item.autorizado = this.usuario.nombre;
      // item.autorizado = "11111111-1";
    }
    this.onCambiarEstado(item);
  }

  actualizarEstadoPendiente(item: Orden): void {
    if (item.estado === "pendiente") {
      if (
        item.tipoArchivo === "BOLETA/FACTURA" ||
        item.tipoArchivo2 === "BOLETA/FACTURA" ||
        item.tipoArchivo3 === "BOLETA/FACTURA" ||
        item.tipoArchivo4 === "BOLETA/FACTURA" ||
        item.tipoArchivo5 === "BOLETA/FACTURA" ||
        item.tipoArchivo6 === "BOLETA/FACTURA"
      ) {
        item.estado = "aprobado";
        item.color = "success";
      } else {
        item.estado = "aprobado";
        item.color = "#FFA726";
      }
      if (this.usuario.tipo === "admin") {
        item.autorizado = "SEBASTIÁN RIUS MARTINI";
      }
      if (this.usuario.tipo === "gerente_operaciones") {
        item.autorizado = "EDUARDO ARCE PARADA";
      }
      if (this.usuario.tipo === "gerente_comercial") {
        item.autorizado = "MARGARITA VILLALOBOS";
      }
    }
    this.onCambiarEstado(item);
  }

  rechazarPago(item: Orden): void {
    if (item.estado === "pendiente") {
      item.estado = "rechazado";
      item.color = "red";
      item.autorizado = this.usuario.nombre;
    }

    this.onCambiarEstado(item);
  }

  actualizarCuentaSoftland(item: Orden): void {
    this.onCambiarEstado(item);
  }

  actualizarTipoGasto(item: Orden): void {
    this.onCambiarEstado(item);
  }

  // VISTA DE LOS ARCHIVOS
  abrirVistaPrevia(archivo: Archivo): void {
    this.vistaPrevia = {
      model: true,
      url: this.urlArchivo(archivo),
    };
  }

  urlArchivo(media: Archivo): string {
    return media.url ?? URL.createObjectURL(media.file);
  }

  vistaPrevia = {
    model: false,
    url: "",
  };
  cerrarVistaPrevia(): void {
    this.vistaPrevia = {
      model: false,
      url: "",
    };
  }

  // tERMINA vISTA DE LOS ARCHIVOS
  showTooltipPendiente(
    estado: string,
    total: number,
    solicitante: string,
    tipo: string,
    centroCosto: string
  ): boolean {
    if (estado === "pendiente" && tipo === "admin") {
      return true;
    }
    if (
      estado === "pendiente" &&
      tipo === "gerente_operaciones" &&
      total < 3000001 
    ) {
      return true;
    }
    if (
      estado === "pendiente" &&
      tipo === "gerente_comercial" &&
      total < 500001 &&
      solicitante.toUpperCase() === "MARGARITA VILLALOBOS" &&
      centroCosto === "4 - MALL TALCA - TALCA"
    ) {
      return true;
    }
    if ( 
      this.usuario.rut === "13605050-8" &&
      estado === "pendiente" &&
      tipo === "relator" &&
      total < 500001 &&
      (solicitante.toUpperCase() === "JOSE JARA") &&
      (centroCosto === "4 - MALL TALCA - TALCA" || centroCosto === "150 - CINE MALL - FLORIDA GO TALCA" || centroCosto === "30 - STRIP CENTER - AVDA. COLIN 0695| TALCA")
    ) {
      return true;
    }
    if ( 
      this.usuario.rut === "16836930-1" &&
      estado === "pendiente" &&
      tipo === "relator" &&
      total < 500001 &&
      (solicitante.toUpperCase() === "FRANCISCO VILLEGAS") &&
      (centroCosto === "4 - MALL TALCA - TALCA" || centroCosto === "150 - CINE MALL - FLORIDA GO TALCA" || centroCosto === "30 - STRIP CENTER - AVDA. COLIN 0695| TALCA")
    ) {
      return true;
    }
    return false;
  }

  showTooltipAprobado(
    estado: string,
    total: number,
    solicitante: string,
    tipo: string,
    centroCosto: string
  ): boolean {
    if (estado === "aprobado" && tipo === "admin") {
      return true;
    }
    if (
      estado === "aprobado" &&
      tipo === "gerente_operaciones" &&
      total < 3000001 
    ) {
      return true;
    }
    if (
      estado === "aprobado" &&
      tipo === "gerente_comercial" &&
      total < 500001 &&
      solicitante.toUpperCase() === "MARGARITA VILLALOBOS" &&
      centroCosto === "4 - MALL TALCA - TALCA"
    ) {
      return true;
    }
    if ( 
      this.usuario.rut === "13605050-8" &&
      estado === "aprobado" &&
      tipo === "relator" &&
      total < 500001 &&
      (solicitante.toUpperCase() === "JOSE JARA") &&
      (centroCosto === "4 - MALL TALCA - TALCA" || centroCosto === "150 - CINE MALL - FLORIDA GO TALCA" || centroCosto === "30 - STRIP CENTER - AVDA. COLIN 0695| TALCA")
    ) {
      return true;
    }
    if ( 
      this.usuario.rut === "16836930-1" &&
      estado === "aprobado" &&
      tipo === "relator" &&
      total < 500001 &&
      (solicitante.toUpperCase() === "FRANCISCO VILLEGAS") &&
      (centroCosto === "4 - MALL TALCA - TALCA" || centroCosto === "150 - CINE MALL - FLORIDA GO TALCA" || centroCosto === "30 - STRIP CENTER - AVDA. COLIN 0695| TALCA")
    ) {
      return true;
    }
    return false;
  }

  showTooltipRechazado(
    estado: string,
    total: number,
    solicitante: string,
    tipo: string,
    centroCosto: string
  ): boolean {
    if (estado === "rechazado" && tipo === "admin") {
      return true;
    }
    if (
      estado === "rechazado" &&
      tipo === "gerente_operaciones" &&
      total < 3000001 
    ) {
      return true;
    }
    if (
      estado === "rechazado" &&
      tipo === "gerente_comercial" &&
      total < 500001 &&
      solicitante.toUpperCase() === "MARGARITA VILLALOBOS" &&
      centroCosto === "4 - MALL TALCA - TALCA"
    ) {
      return true;
    }

    if ( 
      this.usuario.rut === "13605050-8" &&
      estado === "rechazado" &&
      tipo === "relator" &&
      total < 500001 &&
      (solicitante.toUpperCase() === "JOSE JARA") &&
      (centroCosto === "4 - MALL TALCA - TALCA" || centroCosto === "150 - CINE MALL - FLORIDA GO TALCA" || centroCosto === "30 - STRIP CENTER - AVDA. COLIN 0695| TALCA")
    ) {
      return true;
    }
    if ( 
      this.usuario.rut === "16836930-1" &&
      estado === "rechazado" &&
      tipo === "relator" &&
      total < 500001 &&
      (solicitante.toUpperCase() === "FRANCISCO VILLEGAS") &&
      (centroCosto === "4 - MALL TALCA - TALCA" || centroCosto === "150 - CINE MALL - FLORIDA GO TALCA" || centroCosto === "30 - STRIP CENTER - AVDA. COLIN 0695| TALCA")
    ) {
      return true;
    }
    return false;
  }

  @Emit("cambiar-cuenta-softland")
  onCambiarCuentaSoftland(orden: Orden): Orden {
    return orden;
  }

  @Emit("cambiar-tipo-gasto")
  onCambiarTipoGasto(orden: Orden): Orden {
    return orden;
  }

  @Emit("cambiar-estado")
  onCambiarEstado(orden: Orden): Orden {
    return orden;
  }

  // @Emit("existe-en-prenomina")
  // onExisteEnPrenomina(orden: Orden): Orden {
  //   return orden;
  // }

  @Emit("abrir-info")
  onAbrirInfo(orden: Orden): Orden {
    return orden;
  }

  @Emit("abrir-editar")
  onAbrirEditar(orden: Orden): Orden {
    return orden;
  }

  @Emit("abrir-eliminar")
  onAbrirEliminar(orden: Orden): Orden {
    return orden;
  }

  @Emit("abrir-archivo")
  onAbrirArchivo(orden: Orden): Orden {
    return orden;
  }

  @Emit("abrir-observacion")
  onAbrirObservacion(orden: Orden): Orden {
    return orden;
  }

  @Emit("abrir-respuesta")
  onAbrirRespuesta(orden: Orden): Orden {
    return orden;
  }

  @Emit("pdf-pago")
  onCrearPdfOrdenPago(orden: Orden): Orden {
    return orden;
  }

  @Emit("pdf-compra")
  onCrearPdfOrdenCompra(orden: Orden): Orden {
    return orden;
  }

  // @Emit("")
}
