
































































































// decoradores
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { MapeoGasto } from "@/typings/store/plugins/easyFirestore/mapeoGastos";
import SVGIcon from "@/components/SVGIcon.vue";
// tipos

// tipos
@Component({
  components: {
    SVGIcon,
  },
})
export default class InfoPrenomina extends Vue {
  @Prop() readonly mapeoGasto!: MapeoGasto;
  @Prop({ type: Boolean }) readonly showTitulo!: boolean;

  get id(): number {
    return this.mapeoGasto.id || 0;
  }

  get centroCosto(): string {
    return this.mapeoGasto.centroCosto || "N/R";
  }

  get rut(): string {
    return this.mapeoGasto.rut || "N/R";
  }

  get nombreProveedor(): string {
    return this.mapeoGasto.nombreProveedor || "N/R";
  }

  get tipoGasto(): string {
    return this.mapeoGasto.tipoGasto || "N/R";
  }

  get cuentaGasto(): string {
    return this.mapeoGasto.cuentaGasto || "N/R";
  }

  get cuentaEstadoResultado(): string {
    return this.mapeoGasto.cuentaEstadoResultado || "N/R";
  }

  get nCliente(): string {
    return this.mapeoGasto.nCliente || "N/R";
  }

  get fechaAsignacion(): string {
    return this.mapeoGasto.fechaAsignacion || "N/R";
  }

  @Emit("cerrar")
  onCerrar(): void {
    return;
  }
}
