








































import {Component, Emit, Prop, Vue} from "vue-property-decorator";
// helpers
// tipos
// validaciones
import {required} from "vuelidate/lib/validators";
import {CategoriaCurso} from "@/typings/store/plugins/easyFirestore/categoriasCurso";
import {DataFormularioCategoriaCurso} from "@/typings/components/categoriasCurso/formulario";

@Component({
  validations: {
    nombre: { required },
  },
})
export default class FormularioCategoriaCurso extends Vue {
  @Prop() readonly categoriaCurso!: CategoriaCurso | null;

  created(): void {
    const categoriaCurso = this.categoriaCurso;
    if(!categoriaCurso){
      return;
    }
    this.nombre = categoriaCurso.nombre ?? "";
  }

  id: string | null = null;
  nombre = "";

  get titulo(): string {
    return this.categoriaCurso
        ? "editar categoria curso"
        : "nueva categoria curso";
  }

  get nombreError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.nombre.$dirty) return errors;
    if (!this.$v.nombre.required) errors.push("Campo requerido");
    return errors;
  }

  @Emit("close")
  onClose(): null {
    return null;
  }

  @Emit("cerrar-dialogo")
  onCerrarDialogo(): void {
    return;
  }

  @Emit("click-guardar")
  onClickGuardar(
      data: DataFormularioCategoriaCurso
  ): DataFormularioCategoriaCurso {
    return data;
  }

  guardar(): void {
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }
    const doc: CategoriaCurso = {
      nombre: this.nombre.toLowerCase(),
    };
    if (this.id) {
      doc.id = this.id;
    }
    const data: DataFormularioCategoriaCurso = {
      doc: doc,
    };
    this.onClickGuardar(data);
  }

}
