












































































import { Component, Vue, Prop, Model, Emit } from "vue-property-decorator";
import { Ruta } from "@/typings/store/modules/rutas";
import SVGIcon from "@/components/SVGIcon.vue";

//import { Usuario } from "@/typings/store/modules/usuario"
import { mapGetters } from "vuex";

@Component({
  computed: mapGetters({
    getRutas: "rutas/getRutas",
    getRutasUsuario: "usuario/getRutas",
  }),
  components: { SVGIcon },
})
export default class Inicio extends Vue {
  @Model("input", { type: Boolean }) readonly value!: boolean;

  @Prop() readonly items!: Array<Ruta>;

  @Emit("input")
  onInput(value: boolean): boolean {
    return value;
  }
  get itemsRutas(): Array<Ruta> {
    return this.getRutasArray;
  }
  get rutasUsuario(): Array<Ruta> {
    return this.getRutasUsuario;
  }
  to(ruta: Ruta): void {
    if (ruta.to && this.$route.name !== ruta.to?.name) {
      this.$router.push(ruta.to);
    }
  }

  get hideSlider(): boolean {
    return !this.items.some(
      (ruta) =>
        ruta.id.toLowerCase() === this.actualRute.toLowerCase() && ruta.show
    );
  }

  get actualRute(): string {
    return this.$route.name ?? "";
  }
}
