




















































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
// helpers
// tipos
// validaciones
//import { idtify } from "@/helpers/string";
import { required } from "vuelidate/lib/validators";
import { RegistrosEgresos } from "@/typings/store/plugins/easyFirestore/registrosEgresos";
import { DataFormularioRegistrosEgresos } from "@/typings/components/registrosEgresos/formulario";
import { Archivo, Archivos } from "@/typings/store/modules/archivos";
import { Banco } from "@/typings/store/plugins/easyFirestore/bancos";
import { CentroCosto } from "@/typings/store/plugins/easyFirestore/centrosCostos";
import { CodCuenta } from "@/typings/store/plugins/easyFirestore/codCuentas";
import { TipoEgreso } from "@/typings/store/plugins/easyFirestore/tipoEgresos";
import { Proveedor } from "@/typings/store/plugins/easyFirestore/proveedores";
import { TipoDocumento } from "@/typings/store/plugins/easyFirestore/tipoDocumentos";
import { TipoCargo } from "@/typings/store/plugins/easyFirestore/tipoCargos";
import { MapeoGasto } from "@/typings/store/plugins/easyFirestore/MapeoGastos";
import { Prenomina } from "@/typings/store/plugins/easyFirestore/prenominas";

@Component({
  validations: {
    // nombre: { required },
    periodo: { required },
    periodoServicio: { required },
    centroCosto: { required },
    descripcionCuenta: { required },
    codigoCuenta: { required },
    banco: { required },
    //ordenCompra: { required },
    fechaEgreso: { required },
    tipoEgreso: { required },
    montoEgreso: { required },
    glosa: { required },
    rutProveedor: { required },
    nombreProveedor: { required },
    pagoCP: { required },
    porcTotal: { required },
    nominaPago: { required },
    tipoDocumento: { required },
    nDocumento: { required },
    FacDocNota: { required },
    ctaEstadoResultado: { required },
    ctaControlGasto: { required },
    tipoGasto: { required },
    pagado: { required },
  },
})
export default class FormularioRegistrosEgresos extends Vue {
  @Prop() readonly registrosEgresos!: RegistrosEgresos | null;
  @Prop() readonly arrayRegistrosEgresos!: RegistrosEgresos[];
  @Prop() readonly bancos!: Banco[];
  @Prop() readonly codCuentas!: CodCuenta[];
  @Prop() readonly centrosCostos!: CentroCosto[];
  @Prop() readonly tipoEgresos!: TipoEgreso[];
  @Prop() readonly proveedores!: Proveedor[];
  @Prop() readonly tipoDocumentos!: TipoDocumento[];
  @Prop() readonly tipoCargos!: TipoCargo[];
  @Prop() readonly mapeoGastos!: MapeoGasto[];
  @Prop() readonly prenominas!: Prenomina[];

  created(): void {
    const registrosEgresos = this.registrosEgresos;
    if (!registrosEgresos) {
      console.log(this.tipoDocumento);
      return;
    }
    this.id = registrosEgresos.id ?? "";
    this.periodo = registrosEgresos.PERIODO.toDate().format("YYYY-MM-DD") ?? "";
    this.centroCosto = registrosEgresos.CENTRO_DE_COSTO;
    this.descripcionCuenta = registrosEgresos.DESCRIPCION_CUENTA;
    this.codigoCuenta = registrosEgresos.CODIGO_CUENTA;
    this.banco = registrosEgresos.BANCO;
    this.ordenCompra = registrosEgresos.ORDEN_DE_COMPRA ?? "";
    this.fechaEgreso =
      registrosEgresos.FECHA_EGRESO.toDate().format("YYYY-MM-DD") ?? "";
    this.tipoEgreso = registrosEgresos.TIPO_EGRESO;
    this.montoEgreso = registrosEgresos.MONTO_EGRESO ?? "";
    this.glosa = registrosEgresos.GLOSA ?? "";
    this.rutProveedor = registrosEgresos.RUT_PROVEEDOR;
    this.nombreProveedor = registrosEgresos.NOMBRE_PROVEEDOR;
    this.pagoCP = registrosEgresos.PAGO_C_P ?? "";
    this.porcTotal = registrosEgresos.PORC_DEL_TOTAL ?? "";
    this.nominaPago = registrosEgresos.NOMINA_PAGO ?? "";
    this.tipoDocumento = registrosEgresos.TIPO_DE_DOCUMENTO;
    this.nDocumento = registrosEgresos.N_DOCUMENTO ?? "";
    this.FacDocNota = registrosEgresos.FAC_DOC_NOTA ?? "";
    this.ctaEstadoResultado = registrosEgresos.CTA_ESTADO_RESULTADO;
    this.ctaControlGasto = registrosEgresos.CTA_CONTROL_GASTO;
    this.tipoGasto = registrosEgresos.TIPO_GASTO;
    this.nCliente = registrosEgresos.N_CLIENTE ?? "";
    this.nNomina = registrosEgresos.N_NOMINA ?? "";
    this.tipoFactura = registrosEgresos.TIPO_FACTURA ?? "";
    this.neto_factura = registrosEgresos.NETO_FACTURA ?? "";
    this.iva_factura = registrosEgresos.IVA_FACTURA ?? "";
    this.total_factura = registrosEgresos.TOTAL_FACTURA ?? "";
    this.pagado = registrosEgresos.PAGADO ?? "";
    if (registrosEgresos.PERIODO_SERVICIO === "Invalid Date") {
      this.periodoServicio = "2022-01-01";
    } 
    if (registrosEgresos.PERIODO_SERVICIO.length === 7) {
      this.periodoServicio = registrosEgresos.PERIODO_SERVICIO+"-01";
    }    
    if (registrosEgresos.PERIODO_SERVICIO.length === 10) {
      this.periodoServicio = registrosEgresos.PERIODO_SERVICIO;
    } 
    
    // else {
    //   this.periodoServicio = registrosEgresos.PERIODO_SERVICIO.toDate().format(
    //     "YYYY-MM-DD"
    //   );
    // }
    // this.periodoServicio =
    //   registrosEgresos.PERIODO_SERVICIO + "-01" ?? "";

   // this.periodoServicio =
   //   registrosEgresos.PERIODO_SERVICIO.toDate().format("YYYY-MM-DD") ?? "";
    console.log((this.periodoServicio));
    if (registrosEgresos.ORDEN_DE_COMPRA === "N/A") {
      this.noAplicaOc = "N/A";
      // console.log("JUJUJU");
    }
    //this
    // console.log("-registro-", registrosEgresos);
  }

  // datePeriodo = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
  //   .toISOString()
  //   .substr(0, 10);

  // fechaEgreso = new Date(
  //   Date.now() - new Date().getTimezoneOffset() * 60000
  // )
  //   .toISOString()
  //   .substr(0, 10);
  noAplicaOc = "";
  menu = false;
  //modal = false;
  variableborrar = "";
  menu2 = false;
  menu3 = false;
  id: string | null = null;
  // periodo = this.fechaPeriodoF;
  periodo = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
    .toISOString()
    .substr(0, 10);
  periodoServicio = new Date(
    Date.now() - new Date().getTimezoneOffset() * 60000
  )
    .toISOString()
    .substr(0, 7);
  centroCosto = "";
  descripcionCuenta = "";
  codigoCuenta = "";
  banco = "";
  ordenCompra = "";
  fechaEgreso = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
    .toISOString()
    .substr(0, 10);
  tipoEgreso = "";
  montoEgreso = "";
  glosa = "";
  rutProveedor = "";
  nombreProveedor = "";
  pagoCP = "";
  porcTotal = "";
  nominaPago = "";
  tipoDocumento = "";
  tipoFactura = "";
  neto_factura = "";
  iva_factura = "";
  total_factura = "";
  nDocumento = "";
  nCliente = "";
  FacDocNota = "";
  ctaEstadoResultado = "";
  ctaControlGasto = "";
  tipoGasto = "";
  numero = "";
  nNomina = "";
  posicion = 0;
  pagado = "";

  options = {
    locale: "es-CL",
    prefix: "$",
    suffix: "",
    length: 18,
    precision: 0,
  };

  get numeroNomina(): string {
    let numero = "";
    if (this.nominaPago === "SI") {
      numero = this.nNomina;
    } else {
      numero = "";
    }
    return numero;
  }

  imagen: Archivo | null = null;
  archivosBorrar: Archivos = {};
  items_PagoCP = ["COMPLETA", "PARCIAL"];
  items_porcTotal = [
    "10%",
    "20%",
    "30%",
    "40%",
    "50%",
    "60%",
    "70%",
    "80%",
    "90%",
    "100%",
  ];
  items_NominaPago = ["SI", "NO"];
  items_tiposFactura = ["AFECTA", "EXENTA"];
  get titulo(): string {
    return this.registrosEgresos
      ? "Editar Registro Egreso"
      : "Nuevo Registro Egreso";
  }

  get fechaPeriodoF(): string {
    let array_fecha = this.periodo.split("-");
    let dia = "01";
    let mes = array_fecha[1];
    let ano = array_fecha[0];
    console.log(array_fecha);
    this.periodo = dia + "-" + mes + "-" + ano;
    return dia + "-" + mes + "-" + ano;
  }

  get fechaEgresoF(): string {
    let array_fecha = this.fechaEgreso.split("-");
    let dia = array_fecha[2];
    let mes = array_fecha[1];
    let ano = array_fecha[0];
    console.log(array_fecha);
    let fecha = dia + "-" + mes + "-" + ano;
    this.fechaEgreso = fecha;
    console.log(fecha);
    return fecha;
  }

  get itemCentrosCostos(): Array<string> {
    const itemCentroCosto: Array<string> = [];
    this.mapeoGastos.forEach((mapeoGasto) => {
      if (mapeoGasto.tipoGasto === this.tipoGasto) {
        itemCentroCosto.push(mapeoGasto.centroCosto);
      }
    });
    return itemCentroCosto.sort();
  }

  get verificaExistenciaPrenomina(): boolean {
    const itemFacturaPrenomina: Array<string> = [];
    let estaEnPrenomina = false;
    if (this.rutProveedor) {
      this.prenominas.forEach((factura) => {
        if (factura.rut.toString() === this.rutProveedor) {
          if (
            factura.nFactura.toString() !== "0" ||
            factura.nFactura.toString() !== "N/A"
          ) {
            itemFacturaPrenomina.push(factura.nFactura.toString());
          }
        }
      });
    }
    // si un valor de itemFacturaPrenomina es igual a this.nfactura, significa que hay repetidos
    if (
      itemFacturaPrenomina.includes(this.FacDocNota) ||
      this.tipoDocumento === "PAC" ||
      this.tipoGasto === "DEVOLUCIÓN DINEROS"
    ) {
      estaEnPrenomina = false;
      //pero no tiene que estar en egreso
    } else {
      estaEnPrenomina = true;
    }
    return estaEnPrenomina;
  }

  get verificaFacturasConciliadas(): boolean {
    const itemFactura: Array<string> = [];
    let repetida = false;
    if (this.rutProveedor) {
      if (this.arrayRegistrosEgresos)
        this.arrayRegistrosEgresos.forEach((factura) => {
          if (factura.RUT_PROVEEDOR.toString() === this.rutProveedor) {
            itemFactura.push(factura.FAC_DOC_NOTA.toString());
            if (
              factura.FAC_DOC_NOTA.toString() !== "0" ||
              factura.FAC_DOC_NOTA.toString() !== "N/A"
            ) {
              itemFactura.push(factura.FAC_DOC_NOTA.toString());
            }
          }
        });
    }
    //  si un valor de itemFactura es igual a this.nfactura, significa que hay repetidos
    if (itemFactura.includes(this.FacDocNota)) {
      repetida = true;
    } else {
      repetida = false;
    }
    return repetida;
  }

  // get itemDescripcionCuentas(): Array<string> {
  //   const itemDescripcionCuenta: Array<string> = [];
  //   this.codCuentas.forEach((descripcionCuenta) => {
  //     itemDescripcionCuenta.push(descripcionCuenta.PCDESC);
  //   });
  //   return itemDescripcionCuenta.sort();
  // }

  get itemDescripcionCuentas(): string | undefined {
    let itemDescripcionCuentas = "";
    this.codCuentas.forEach((descripcionCuenta) => {
      if (descripcionCuenta.PCCODI === this.codigoCuenta) {
        itemDescripcionCuentas = descripcionCuenta.PCDESC;
        this.descripcionCuenta = itemDescripcionCuentas;
      }
    });
    return itemDescripcionCuentas;
  }

  // get itemCodigoCuentas(): string | undefined {
  //   let itemCodigoCuenta = "";
  //   this.codCuentas.forEach((descripcionCuenta) => {
  //     if (descripcionCuenta.PCDESC === this.descripcionCuenta) {
  //       itemCodigoCuenta = descripcionCuenta.PCCODI;
  //       this.codigoCuenta = itemCodigoCuenta;
  //     }
  //   });
  //   return itemCodigoCuenta;
  // }
  get itemCodigoCuentas(): Array<string> {
    const itemCodigoCuentas: Array<string> = [];
    this.codCuentas.forEach((descripcionCuenta) => {
      itemCodigoCuentas.push(descripcionCuenta.PCCODI);
    });
    return itemCodigoCuentas.sort();
  }

  get itemBancos(): Array<string> {
    const itemBanco: Array<string> = [];
    this.bancos.forEach((banco) => {
      itemBanco.push(banco.nombre);
    });
    return itemBanco.sort();
  }

  get itemTipoEgresos(): Array<string> {
    const itemTipoEgreso: Array<string> = [];
    this.tipoEgresos.forEach((tipoEgreso) => {
      itemTipoEgreso.push(tipoEgreso.nombre);
    });
    return itemTipoEgreso.sort();
  }

  get itemRutProveedores(): Array<string> {
    const itemRutProveedor: Array<string> = [];
    this.mapeoGastos.forEach((mapeoGasto) => {
      if (mapeoGasto.tipoGasto === this.tipoGasto) {
        if (mapeoGasto.centroCosto === this.centroCosto) {
          itemRutProveedor.push(mapeoGasto.rut);
        }
      }
    });
    return itemRutProveedor.sort();
  }

  get itemNombreProveedores(): string | undefined {
    let itemNombreProveedor = "";
    this.mapeoGastos.forEach((mapeoGasto) => {
      if (mapeoGasto.rut === this.rutProveedor) {
        // if (mapeoGasto.centroCosto === this.centroCosto) {
        itemNombreProveedor = mapeoGasto.nombreProveedor;
        this.nombreProveedor = itemNombreProveedor;
        // }
      }
    });
    return itemNombreProveedor;
  }

  get itemRutTipoDocumentos(): Array<string> {
    const itemTipoDocumento: Array<string> = [];
    this.tipoDocumentos.forEach((tipoDocumento) => {
      itemTipoDocumento.push(tipoDocumento.nombre);
    });
    return itemTipoDocumento.sort();
  }

  // get itemTipoGastos(): Array<string> {
  //   const itemTipoGasto: Array<string> = [];
  //   this.tipoCargos.forEach((tipoCargo) => {
  //     itemTipoGasto.push(tipoCargo.nombre);
  //   });
  //   return itemTipoGasto.sort();
  // }
  get itemTipoGastos(): Array<string> {
    const itemTipoGasto: Array<string> = [];
    this.mapeoGastos.forEach((mapeoGasto) => {
      itemTipoGasto.push(mapeoGasto.tipoGasto);
    });
    return itemTipoGasto.sort();
  }

  get valorOrdenCompra(): string {
    let valor = "";
    if (this.noAplicaOc === "N/A") {
      console.log("A");
      valor = "N/A";
    } else {
      console.log("B");
      valor = this.ordenCompra;
    }
    console.log(valor);

    return valor;
  }

  // get itemControlGastos(): string | undefined {
  //   let itemControlGasto = "";
  //   this.tipoCargos.forEach((tipoCargo) => {
  //     if (tipoCargo.nombre === this.tipoGasto) {
  //       itemControlGasto = tipoCargo.cuentaControlGasto;
  //       this.ctaControlGasto = itemControlGasto;
  //     }
  //   });
  //   return itemControlGasto;
  // }

  get itemControlGastos(): string | undefined {
    let itemControlGasto = "";
    this.mapeoGastos.forEach((mapeoGasto) => {
      if (mapeoGasto.tipoGasto === this.tipoGasto) {
        itemControlGasto = mapeoGasto.cuentaGasto;
        this.ctaControlGasto = itemControlGasto;
      }
    });
    return itemControlGasto;
  }

  // get itemEstadoResultados(): string | undefined {
  //   let itemEstadoResultado = "";
  //   this.tipoCargos.forEach((tipoCargo) => {
  //     if (tipoCargo.nombre === this.tipoGasto) {
  //       itemEstadoResultado = tipoCargo.cuentaEstadoResultado;
  //       this.ctaEstadoResultado = itemEstadoResultado;
  //     }
  //   });
  //   return itemEstadoResultado;
  // }

  get itemEstadoResultados(): string | undefined {
    let itemEstadoResultado = "";
    this.mapeoGastos.forEach((mapeoGasto) => {
      if (mapeoGasto.tipoGasto === this.tipoGasto) {
        itemEstadoResultado = mapeoGasto.cuentaEstadoResultado;
        this.ctaEstadoResultado = itemEstadoResultado;
      }
    });
    return itemEstadoResultado;
  }

  get centroCostoError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.centroCosto.$dirty) return errors;
    if (!this.$v.centroCosto.required) errors.push("Campo requerido");
    return errors;
  }
  get descripcionCuentaError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.descripcionCuenta.$dirty) return errors;
    if (!this.$v.descripcionCuenta.required) errors.push("Campo requerido");
    return errors;
  }
  get codigoCuentaError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.codigoCuenta.$dirty) return errors;
    if (!this.$v.codigoCuenta.required) errors.push("Campo requerido");
    return errors;
  }
  get bancoError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.banco.$dirty) return errors;
    if (!this.$v.banco.required) errors.push("Campo requerido");
    return errors;
  }
  // get ordenCompraError(): Array<string> {
  //   const errors: Array<string> = [];
  //   if (!this.$v.ordenCompra.$dirty) return errors;
  //   if (!this.$v.ordenCompra.required) errors.push("Campo requerido");
  //   return errors;
  // }
  get fechaEgresoError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.fechaEgreso.$dirty) return errors;
    if (!this.$v.fechaEgreso.required) errors.push("Campo requerido");
    return errors;
  }
  get tipoEgresoError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.tipoEgreso.$dirty) return errors;
    if (!this.$v.tipoEgreso.required) errors.push("Campo requerido");
    return errors;
  }
  get montoEgresoError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.montoEgreso.$dirty) return errors;
    if (!this.$v.montoEgreso.required) errors.push("Campo requerido");
    return errors;
  }
  get glosaError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.glosa.$dirty) return errors;
    if (!this.$v.glosa.required) errors.push("Campo requerido");
    return errors;
  }
  get rutProveedorError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.rutProveedor.$dirty) return errors;
    if (!this.$v.rutProveedor.required) errors.push("Campo requerido");
    return errors;
  }
  get nombreProveedorError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.nombreProveedor.$dirty) return errors;
    if (!this.$v.nombreProveedor.required) errors.push("Campo requerido");
    return errors;
  }
  get pagoCPError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.pagoCP.$dirty) return errors;
    if (!this.$v.pagoCP.required) errors.push("Campo requerido");
    return errors;
  }
  get porcTotalError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.porcTotal.$dirty) return errors;
    if (!this.$v.porcTotal.required) errors.push("Campo requerido");
    return errors;
  }
  get nominaPagoError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.nominaPago.$dirty) return errors;
    if (!this.$v.nominaPago.required) errors.push("Campo requerido");
    return errors;
  }

  get pagadoError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.pagado.$dirty) return errors;
    if (!this.$v.pagado.required) errors.push("Campo requerido");
    return errors;
  }

  get tipoDocumentoError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.tipoDocumento.$dirty) return errors;
    if (!this.$v.tipoDocumento.required) errors.push("Campo requerido");
    return errors;
  }
  get nDocumentoError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.nDocumento.$dirty) return errors;
    if (!this.$v.nDocumento.required) errors.push("Campo requerido");
    return errors;
  }
  get FacDocNotaError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.FacDocNota.$dirty) return errors;
    if (!this.$v.FacDocNota.required) errors.push("Campo requerido");
    if (this.verificaExistenciaPrenomina)
      errors.push("El número de factura no está registrado en la Nomina");

    if (this.verificaFacturasConciliadas)
      errors.push("El número de factura ya está registrada en la Conciliación");

    return errors;
  }
  get ctaEstadoResultadoError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.ctaEstadoResultado.$dirty) return errors;
    if (!this.$v.ctaEstadoResultado.required) errors.push("Campo requerido");
    return errors;
  }
  get ctaControlGastoError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.ctaControlGasto.$dirty) return errors;
    if (!this.$v.ctaControlGasto.required) errors.push("Campo requerido");
    return errors;
  }
  get tipoGastoError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.tipoGasto.$dirty) return errors;
    if (!this.$v.tipoGasto.required) errors.push("Campo requerido");
    return errors;
  }

  get descripcionCuentaerror(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.descripcionCuenta.$dirty) return errors;
    if (!this.$v.descripcionCuenta.required) errors.push("Campo requerido");
    return errors;
  }

  get periodoerror(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.periodo.$dirty) return errors;
    if (!this.$v.periodo.required) errors.push("Campo requerido");
    return errors;
  }

  get periodServiciooerror(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.periodoServicio.$dirty) return errors;
    if (!this.$v.periodoServicio.required) errors.push("Campo requerido");
    return errors;
  }

  @Emit("close")
  onClose(): null {
    return null;
  }
  @Emit("cerrar-dialogo")
  onCerrarDialogo(): void {
    return;
  }
  @Emit("click-guardar")
  onClickGuardar(
    data: DataFormularioRegistrosEgresos
  ): DataFormularioRegistrosEgresos {
    return data;
  }
  guardar(): void {
    this.$v.$touch();
    if (
      this.$v.$invalid ||
      !this.banco ||
      !this.centroCosto ||
      !this.descripcionCuenta ||
      !this.codigoCuenta ||
      !this.tipoEgreso ||
      !this.rutProveedor ||
      !this.nombreProveedor ||
      !this.tipoDocumento ||
      !this.tipoGasto ||
      !this.ctaEstadoResultado ||
      !this.ctaControlGasto
    ) {
      return;
    }

    const doc: RegistrosEgresos = {
      //nombre: this.nombre.toLowerCase(),
      PERIODO: this.fechaPeriodoF,
      CENTRO_DE_COSTO: this.centroCosto,
      DESCRIPCION_CUENTA: this.descripcionCuenta,

      CODIGO_CUENTA: this.codigoCuenta,
      BANCO: this.banco,
      ORDEN_DE_COMPRA: this.valorOrdenCompra,
      FECHA_EGRESO: this.fechaEgreso.toDate().format("DD-MM-YYYY") ?? "",
      TIPO_EGRESO: this.tipoEgreso,
      MONTO_EGRESO: this.montoEgreso,
      GLOSA: this.glosa,
      RUT_PROVEEDOR: this.rutProveedor,
      NOMBRE_PROVEEDOR: this.nombreProveedor,
      PAGO_C_P: this.pagoCP,
      PORC_DEL_TOTAL: this.porcTotal,
      NOMINA_PAGO: this.nominaPago,
      TIPO_DE_DOCUMENTO: this.tipoDocumento,
      N_DOCUMENTO: this.nDocumento,
      FAC_DOC_NOTA: this.FacDocNota,
      CTA_ESTADO_RESULTADO: this.ctaEstadoResultado,
      N_NOMINA: this.numeroNomina,
      CTA_CONTROL_GASTO: this.ctaControlGasto,
      TIPO_GASTO: this.tipoGasto,
      N_CLIENTE: this.nCliente,
      TIPO_FACTURA: this.tipoFactura,
      NETO_FACTURA: this.neto_factura,
      IVA_FACTURA: this.iva_factura,
      TOTAL_FACTURA: this.total_factura,
      PAGADO: this.pagado,
      PERIODO_SERVICIO: this.periodoServicio.substring(0,7),
    };
    if (this.id) {
      doc.id = this.id;
    }
    const data: DataFormularioRegistrosEgresos = {
      doc: doc,
      borrar: this.archivosBorrar,
      imagen: {},
    };
    // if (this.imagen) {
    //   data.imagen = {
    //     [idtify(this.imagen.id)]: this.imagen,
    //   };
    // }
    this.onClickGuardar(data);
  }
}
