




















































// decoradores
import { Component, Emit, Vue } from "vue-property-decorator";
// vuex
import { mapActions, mapGetters } from "vuex";
// firebase
import { Firebase } from "../config/firebase";
// helpers
import { idtify } from "@/helpers/string";
// componentes
import Formulario from "@/components/login/Formulario.vue";
import ActivarCuenta from "@/components/login/ActivarCuenta.vue";
import { Usuarios } from "@/typings/store/plugins/easyFirestore/usuarios";
import DialogoExito from "@/components/DialogoExito.vue";
import SVGIcon from "@/components/SVGIcon.vue";
import FormularioRegistro from "@/components/login/FormularioRegistro.vue";

const routeGuard = (vm: Vue): boolean => {
  const rutas = vm.getRutasUsuario;
  if (rutas.length > 0) {
    const to = rutas[0].to;
    if (to) {
      const ruta = vm.$route;
      if (to.name == ruta.name) {
        return false;
      }
      if (vm.$route.name !== to.name) {
        vm.$router.replace(to);
      }
      return false;
    }
  }
  return true;
};

@Component({
  computed: mapGetters({
    getErrorLogin: "getErrorLogin",
    getUsuarios: "usuarios/get",
    getRutasUsuario: "usuario/getRutas",
  }),
  methods: mapActions({
    fetchByIdUsuario: "usuarios/fetchById",
  }),
  beforeRouteEnter(to, from, next) {
    next(routeGuard);
  },
  components: {
    FormularioRegistro,
    SVGIcon,
    DialogoExito,
    Formulario,
    ActivarCuenta,
  },
})
export default class VistaLogin extends Vue {
  created(): void {
    if (!routeGuard(this)) {
      return;
    }
  }

  email = "";
  dialogoActivar = false;
  registro = false;
  dialogoExito = {
    model: false,
    icono: "",
    texto: "",
  };

  get usuarios(): Usuarios {
    return this.getUsuarios;
  }

  @Emit("loginError")
  onLoginError(data: string): string {
    return data;
  }

  @Emit("cerrar")
  onCerrar(): void {
    return;
  }

  @Emit("terminos")
  onTerminos(): void {
    return;
  }

  abrirSnackBar(mensaje: { text: string; color: string }): void {
    this.dialogoExito = {
      model: true,
      icono: require("../assets/icons/cancelar-duo.svg"),
      texto: `¡${mensaje.text}!`,
    };
  }

  async activarCuenta(data: {
    email: string;
    password: string;
  }): Promise<void> {
    const id = idtify(data.email);
    await this.fetchByIdUsuario(id);
    const usuario = this.usuarios[id];
    if (!usuario) {
      this.dialogoExito = {
        model: true,
        icono: require("../assets/icons/cancelar-duo.svg"),
        texto: "Usuario no encontrado",
      };
      return;
    }
    if (usuario.rut === data.password) {
      this.email = data.email;
      this.dialogoActivar = true;
    } else {
      this.dialogoExito = {
        model: true,
        icono: require("../assets/icons/cancelar-duo.svg"),
        texto: "Clave incorrecta",
      };
    }
  }

  activar(password: string): void {
    Firebase.auth()
      .createUserWithEmailAndPassword(this.email, password)
      .catch((error) => {
        switch (error.code) {
          case "auth/invalid-email":
            this.dialogoExito = {
              model: true,
              icono: require("../assets/icons/cancelar-duo.svg"),
              texto: "Correo inválido",
            };
            break;
        }
      });
  }

  loginGoogle(): void {
    const provider = new Firebase.auth.GoogleAuthProvider();
    Firebase.auth()
      .signInWithPopup(provider)
      .catch((error) => {
        console.log(error);
      })
      .then(() => {
        this.onCerrar();
      });
  }

  loginFacebook(): void {
    const provider = new Firebase.auth.FacebookAuthProvider();
    Firebase.auth()
      .signInWithPopup(provider)
      .catch((error) => {
        console.log(error);
      })
      .then(() => {
        this.onCerrar();
      });
  }

  cerrarDialogoExito(): void {
    this.dialogoExito = {
      model: false,
      icono: "",
      texto: "",
    };
  }
  
}
