































































// decoradores
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { Mantenimiento } from "@/typings/store/plugins/easyFirestore/mantenimientos";
import SVGIcon from "@/components/SVGIcon.vue";
// tipos

// tipos
@Component({
  components: {
    SVGIcon,
  },
})
export default class InfoMantenimiento extends Vue {
  @Prop() readonly mantenimiento!: Mantenimiento;
  @Prop({ type: Boolean }) readonly showTitulo!: boolean;

  get id(): string {
    return this.mantenimiento.id || "N/R";
  }

  get nombre(): string {
    return this.mantenimiento.nombre || "N/R";
  }

  get posicion(): string {
    return this.mantenimiento.posicion || "N/R";
  }


  @Emit("cerrar")
  onCerrar(): void {
    return;
  }
}
