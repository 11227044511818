















































































































































































































































































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
// helpers
//import { idtify } from "@/helpers/string";
// tipos
//import { Rutas } from "@/typings/store/modules/rutas";
//import {
//  Permisos,
//  TipoUsuario,
//  Usuario,
//} from "@/typings/store/plugins/easyFirestore/usuarioRegistrador";
import {
  Lectura,
  //Bancos
} from "@/typings/store/plugins/easyFirestore/lecturas";
import { Medidor } from "@/typings/store/plugins/easyFirestore/medidores";
import { Tarifa } from "@/typings/store/plugins/easyFirestore/tarifas";

// validaciones
import {
  // email,
  required,
} from "vuelidate/lib/validators";
import { DataFormularioLectura } from "@/typings/components/lecturas/formulario";
import SVGIcon from "@/components/SVGIcon.vue";
import { CentroCosto } from "@/typings/store/plugins/easyFirestore/centrosCostos";
//import { Archivo, Archivos } from "@/typings/store/modules/archivos";

@Component({
  components: {
    SVGIcon,
  },
  validations: {
    usuario: { required },
    fecha: { required },
    centroCosto: { required },
    empresa: { required },
    lecturaAnterior: { required },
    lecturaActual: { required },
    porcentaje: { required },
    codigoMedidor: { required },
  },
})
export default class FormularioBanco extends Vue {
  @Prop() readonly lectura!: Lectura | null;
  @Prop() readonly cargando!: boolean;
  @Prop() readonly usuarioRegistrador!: string;
  @Prop() readonly medidores!: Medidor[];
  @Prop() readonly tarifas!: Tarifa[];
  @Prop() readonly centrosCostos!: CentroCosto[];
  //   @Prop() readonly rutas!: Rutas;
  //   @Prop() readonly ciudades!: Ciudad[];
  //   @Prop() readonly cargando!: boolean;
  //   @Prop() readonly regiones!: Region[];

  created(): void {
    // Object.keys(this.rutas).forEach((key) => {
    //   this.$set(this.permisos, key, false);
    // });
    // console.log(this.permisos);
    const lectura = this.lectura;
    if (!lectura) {
      return;
    }
    this.id = lectura.id ?? "";
    //this.usuario = lectura.usuario ?? "";
    this.fecha = lectura.fecha.toDate().format("YYYY-MM-DD") ?? "";
    this.centroCosto = lectura.centroCosto ?? "";
    this.comuna = lectura.comuna ?? "";
    this.codigoMedidor = lectura.codigoMedidor ?? "";
    this.empresa = lectura.empresa ?? "";
    this.codigoMedidor = lectura.codigoMedidor ?? "";
    this.lecturaAnterior = lectura.lecturaAnterior ?? "";
    this.lecturaActual = lectura.lecturaActual ?? "";
    this.porcentaje = lectura.porcentaje ?? "";
  }

  id: string | null = null;

  // usuario = this.usuarioRegistrador;
  fecha = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
    .toISOString()
    .substr(0, 10);

  centroCosto = "";
  empresa = "";
  comuna = "";
  codigoMedidor = "";
  lecturaAnterior = "";
  lecturaActual = "";
  porcentaje = "";
  tipoMedidor = "";
  tipoTarifa = "";
  tipoLectura = "";
  consumoTotalActual = "";
  consumoTotalAnterior = "";
  fueraDeRango = "";
  consumoTotal = "";
  periodo = "";
  estado = "";
  menu = false;

  optionsPorc = {
    locale: "es-CL",
    prefix: "",
    suffix: "%",
    length: 5,
    precision: 2,
  };

  optionsLectura = {
    locale: "es-CL",
    prefix: "",
    suffix: "",
    length: 10,
    precision: 2,
  };

  get titulo(): string {
    return this.lectura ? "editar lectura" : "nueva lectura";
  }

  get usuario(): string {
    return this.lectura ? this.lectura.usuario : this.usuarioRegistrador;
  }

  get porcentajeCalculado(): number {
    let lecturaAnterior = parseFloat(this.lecturaAnterior);
    let lecturaActual = parseFloat(this.lecturaActual);

    let calculo = lecturaActual - lecturaAnterior;

    return (calculo / lecturaAnterior) * 100;
  }

  get arrayCentroCosto(): Array<string> {
    const arrayCentroCosto: Array<string> = [];
    // let centroCosto = "";
    // let arrayCentroCosto = [centroCosto];
    this.medidores.forEach((medidor) => {
      arrayCentroCosto.push(medidor.centroCostoOperador);
    });

    const unicos = arrayCentroCosto.filter((valor, indice) => {
      return arrayCentroCosto.indexOf(valor) === indice;
    });
    return unicos.sort();
  }

  get arrayEmpresa(): Array<string> {
    const arrayEmpresa: Array<string> = [];
    this.medidores.forEach((medidor) => {
      if (medidor.centroCostoOperador === this.centroCosto) {
        arrayEmpresa.push(medidor.operador);
      }
    });

    const unicos = arrayEmpresa.filter((valor, indice) => {
      return arrayEmpresa.indexOf(valor) === indice;
    });
    return unicos.sort();
  }

  get arrayCodigoMedidor(): Array<string> {
    const arrayMedidor: Array<string> = [];
    this.medidores.forEach((medidor) => {
      if (
        medidor.centroCostoOperador === this.centroCosto &&
        medidor.operador === this.empresa
      ) {
        arrayMedidor.push(medidor.nroMedidor);
      }
    });

    const unicos = arrayMedidor.filter((valor, indice) => {
      return arrayMedidor.indexOf(valor) === indice;
    });
    return unicos.sort();
  }

  get arrayTipoTarifa(): string {
    let tarifaMedidor = "";
    this.medidores.forEach((medidor) => {
      if (
        medidor.centroCostoOperador === this.centroCosto &&
        medidor.operador === this.empresa &&
        medidor.nroMedidor === this.codigoMedidor
      ) {
        tarifaMedidor = medidor.tipoTarifa;
      }
    });
    return tarifaMedidor;
  }

  get arrayTipoMedidor(): string {
    let tipoMedidor = "";
    this.medidores.forEach((medidor) => {
      if (
        medidor.centroCostoOperador === this.centroCosto &&
        medidor.operador === this.empresa &&
        medidor.nroMedidor === this.codigoMedidor
      ) {
        tipoMedidor = medidor.tipoMedidor;
      }
    });
    return tipoMedidor;
  }

  get arrayTipoLectura(): string {
    let tipoLectura = "";
    this.tarifas.forEach((tarifa) => {
      if (tarifa.tipoTarifa === this.arrayTipoTarifa) {
        tipoLectura = tarifa.tipoLectura;
      }
    });
    return tipoLectura;
  }

  get costoFijo(): number {
    let costoFijoSinIVA = 0;
    this.tarifas.forEach((tarifa) => {
      if (tarifa.tipoLectura === this.arrayTipoLectura) {
        costoFijoSinIVA = tarifa.costoFijoSinIVA;
      }
    });
    return costoFijoSinIVA;
  }

  get consumo(): number {
    let tipoLectura = 0;
    this.tarifas.forEach((tarifa) => {
      if (tarifa.tipoTarifa === this.arrayTipoTarifa) {
        tipoLectura = tarifa.costoConsumoSinIVA;
      }
    });
    return tipoLectura;
  }

  get zonaCC(): string {
    let zonaCC = "";
    this.centrosCostos.forEach((centroCosto) => {
      if (centroCosto.Desc_formato === this.centroCosto) {
        zonaCC = centroCosto.Comuna;
      }

      //       medidor.centroCostoOperador === this.centroCosto &&
      //       medidor.operador === this.empresa &&
      //       medidor.nroMedidor === this.codigoMedidor
      //     ) {
      //       zonaCC = medidor.zonaCC;
      //     }
    });
    return zonaCC;
  }

  get comunaCentrosCostos(): string {
    let comuna = "";
    this.getArrayCentrosCostos.map((item) => {
      if (this.lectura) {
        if (item.Desc_formato === this.lectura.centroCosto) {
          comuna = item.Comuna;
        }
      }
    });
    return comuna;
  }

  get periodoTarifa(): string {
    let periodoTarifa = "";
    this.tarifas.forEach((tarifa) => {
      if (
        tarifa.tipoTarifa === this.arrayTipoTarifa &&
        tarifa.tipoLectura === this.arrayTipoLectura &&
        tarifa.zona === this.zonaCC
      ) {
        periodoTarifa = "01" + tarifa.fecha.substring(2, 10);
      }
    });
    return periodoTarifa;
  }

  get consiguePeriodo(): string {
    let periodo = this.fecha.substr(0, 7);
    let periodoGirado =
      "01-" + periodo.substr(5, 2) + "-" + periodo.substr(0, 4);
    return periodoGirado;
  }

  get difereciaLectura(): number {
    return parseFloat(this.lecturaActual) - parseFloat(this.lecturaAnterior);
  }

  get montoConsumos(): number {
    let montoConsumos = 0;
    if (this.consiguePeriodo === this.periodoTarifa) {
      montoConsumos = this.difereciaLectura * this.consumo + this.costoFijo;
    }
    return montoConsumos;
  }

  //   get permisosKey(): Array<string> {
  //     // return Object.keys(this.permisos).filter((key) => {
  //     //   if(this.rutas[key].tipos.includes(this.tipo)){
  //     //     this.permisos[key] = true;
  //     //   }
  //     // });
  //     return Object.keys(this.permisos).filter((key) => {
  //       if (!this.rutas[key]) {
  //         return false;
  //       }
  //       const tipo = this.rutas[key].tipos.includes(this.tipo);
  //       return tipo;
  //     });
  //   }

  get usuarioError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.usuario.$dirty) return errors;
    if (!this.$v.usuario.required) errors.push("");
    return errors;
  }

  get fechaError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.fecha.$dirty) return errors;
    if (!this.$v.fecha.required) errors.push("");
    return errors;
  }

  get centroCostoError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.centroCosto.$dirty) return errors;
    if (!this.$v.centroCosto.required) errors.push("");
    return errors;
  }

  get empresaError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.empresa.$dirty) return errors;
    if (!this.$v.empresa.required) errors.push("");
    return errors;
  }

  get codigoMedidorError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.codigoMedidor.$dirty) return errors;
    if (!this.$v.codigoMedidor.required) errors.push("");
    return errors;
  }

  get lecturaActualError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.lecturaActual.$dirty) return errors;
    if (!this.$v.lecturaActual.required) errors.push("");
    return errors;
  }

  get lecturaAnteriorError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.lecturaAnterior.$dirty) return errors;
    if (!this.$v.lecturaAnterior.required) errors.push("");
    return errors;
  }

  get porcentajeError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.porcentaje.$dirty) return errors;
    if (!this.$v.porcentaje.required) errors.push("");
    return errors;
  }

  @Emit("close")
  onClose(): null {
    return null;
  }

  @Emit("cerrar-dialogo")
  onCerrarDialogo(): void {
    return;
  }

  @Emit("click-guardar")
  onClickGuardar(data: DataFormularioLectura): DataFormularioLectura {
    return data;
  }

  //   @Watch("permisosKey", { immediate: true })
  //   onTipoChange(): void {
  //     console.log("a");
  //     for (const key of Object.keys(this.permisos)) {
  //       if (this.permisos[key]) {
  //         this.permisos[key] = false;
  //       }
  //     }
  //     this.permisosKey.forEach((key) => {
  //       this.permisos[key] = true;
  //     });
  //   }

  //   nombrePermiso(key: string): string {
  //     return this.rutas[key].text;
  //   }

  guardar(): void {
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }
    const doc: Lectura = {
      usuario: this.usuario,
      fecha: this.fecha.toDate().format("DD-MM-YYYY") ?? "",
      centroCosto: this.centroCosto,
      empresa: this.empresa,
      comuna: this.zonaCC,
      codigoMedidor: this.codigoMedidor,
      lecturaAnterior: this.lecturaAnterior,
      lecturaActual: this.lecturaActual,
      porcentaje: this.porcentaje,
      tipoMedidor: this.tipoMedidor,
      tipoTarifa: this.tipoTarifa,
      tipoLectura: this.tipoLectura,
      consumoTotalActual: this.consumoTotalActual,
      consumoTotalAnterior: this.consumoTotalAnterior,
      fueraDeRango: this.fueraDeRango,
      consumoTotal: this.consumoTotal,
      periodo: this.periodo,
      estado: this.estado,
    };
    if (this.id) {
      doc.id = this.id;
    }
    const data: DataFormularioLectura = {
      doc: doc,
    };
    this.onClickGuardar(data);
  }
}
