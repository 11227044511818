import { RouteConfig } from "vue-router";
import MatrizPagos from "@/views/MatrizPagos.vue";

const matrizPagos: RouteConfig = {
    path: "/matrizPagos",
    name: "matrizPagos",
    component: MatrizPagos,
};

export default matrizPagos;
