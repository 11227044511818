var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"rounded-lg elevation-6",attrs:{"height":"690","outlined":""}},[_c('v-row',{attrs:{"dense":"","align":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-card-title',{staticClass:"text-capitalize poppins text-h6 primary--text font-weight-bold col"},[_vm._v(" Cursos por: ")])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{staticClass:"select-custom poppins",attrs:{"placeholder":"Categoria","hide-details":"auto","items":_vm.items,"dense":"","outlined":"","return-object":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"poppins primary--text text-capitalize"},[_vm._v(_vm._s(item))])]}}]),model:{value:(_vm.select),callback:function ($$v) {_vm.select=$$v},expression:"select"}})],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-container',[(
            this.select != 'Categorias' &&
              this.select != 'Relatores' &&
              this.select != 'Ventas' &&
              _vm.cursoItems.length != 0
          )?_c('GChart',{staticStyle:{"height":"550px"},attrs:{"type":"BarChart","data":_vm.cursoPorCategoria,"options":_vm.chartOptions}}):_vm._e()],1),_c('v-container',[(this.select === 'Relatores' && _vm.cursoItems.length != 0)?_c('GChart',{staticStyle:{"height":"550px"},attrs:{"type":"BarChart","data":_vm.cursoPorRelator,"options":_vm.chartOptions}}):_vm._e()],1),_c('v-container',[(this.select === 'Categorias' && _vm.cursoItems.length != 0)?_c('GChart',{staticStyle:{"height":"550px"},attrs:{"type":"BarChart","data":_vm.cursoPorCategoria,"options":_vm.chartOptions}}):_vm._e()],1),_c('v-container',[(this.select === 'Ventas' && _vm.filtroPagoFecha.length != 0)?_c('GChart',{staticStyle:{"height":"530px"},attrs:{"type":"BarChart","data":_vm.cursoPorVenta,"options":_vm.chartOptions}}):_vm._e(),(this.select === 'Ventas' && _vm.filtroPagoFecha.length == 0)?_c('v-col',[_c('v-container',{staticClass:"text-capitalize poppins text-h6 grey--text text-center",attrs:{"fluid":""}},[_vm._v("No Existen Datos")])],1):_vm._e(),(this.select === 'Relatores' && _vm.cursoItems.length == 0)?_c('v-col',[_c('v-container',{staticClass:"text-capitalize poppins text-h6 grey--text text-center",attrs:{"fluid":""}},[_vm._v("No Existen Datos")])],1):_vm._e(),(this.select === 'Categorias' && _vm.cursoItems.length == 0)?_c('v-col',[_c('v-container',{staticClass:"text-capitalize poppins text-h6 grey--text text-center",attrs:{"fluid":""}},[_vm._v("No Existen Datos")])],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }