















































































































































// decoradores
import { Vue, Component } from "vue-property-decorator";
import { mapGetters } from "vuex";
import { Curso, Cursos } from "@/typings/store/plugins/easyFirestore/cursos";
import { ItemsTablaCursos } from "@/typings/components/cursos/tabla";
import { Pago, Pagos } from "@/typings/store/plugins/easyFirestore/pagos";
import { fechaLocal } from "@/helpers/date";
import { Inscripcion } from "@/typings/store/plugins/easyFirestore/inscripciones";
import {
  Usuario,
  TipoUsuario,
} from "@/typings/store/plugins/easyFirestore/usuarios";

import TituloVista from "@/components/TituloVista.vue";
import indicadorRecaudacionTotal from "@/components/monitoreo/indicadorRecaudacionTotal.vue";
import indicadorRecaudacionMensual from "@/components/monitoreo/indicadorRecaudacionMensual.vue";
import indicadorTotalCursosVendidos from "@/components/monitoreo/indicadorTotalCursosVendidos.vue";
import indicadorRecaudacionHistorica from "@/components/monitoreo/indicadorRecaudacionHistorica.vue";
import indicadorCursoMes from "@/components/monitoreo/indicadorCursoMes.vue";
import indicadorCursosCategoria from "@/components/monitoreo/indicadorCursosCategoria.vue";
import indicadorComparativo from "@/components/monitoreo/indicadorComparativo.vue";
import indicadorEstadoCurso from "@/components/monitoreo/indicadorEstadoCurso.vue";
import indicadorCursosRelator from "@/components/monitoreo/indicadorCursosRelator.vue";
import indicadorInscritosRegistrados from "@/components/monitoreo/indicadorInscritosRegistrados.vue";
import indicadorPagos from "@/components/monitoreo/indicadorPagos.vue";

const routeGuard = (vm: Vue): boolean => {
  const permisos = vm.getUsuario?.permisos;
  if (permisos && permisos["monitoreo"]) {
    return true;
  }

  const rutas = vm.getRutasUsuario;
  if (rutas.length > 0) {
    const to = rutas[0].to;
    if (to) {
      if (vm.$route.name == to.name) {
        if (vm.$route.name !== to.name) {
          vm.$router.replace(to);
        }
        return false;
      }
      return false;
    }
  }
  if (vm.$route.name !== "inicio") {
    vm.$router.replace({ name: "inicio" });
  }
  return false;
};

@Component({
  computed: mapGetters({
    getCursos: "cursos/get",
    getArrayCursos: "cursos/getArray",
    getArrayCategoriasCurso: "categoriasCurso/getArray",
    getPagos: "pagos/get",
    getArrayPagos: "pagos/getArray",
    getRutasUsuario: "usuario/getRutas",
    getUsuario: "usuario/get",
    getArrayUsuarios: "usuarios/getArray",
    getArrayInscripciones: "inscripciones/getArray",
  }),
  beforeRouteEnter(to, from, next) {
    next(routeGuard);
  },
  components: {
    TituloVista,
    indicadorRecaudacionTotal,
    indicadorRecaudacionMensual,
    indicadorTotalCursosVendidos,
    indicadorRecaudacionHistorica,
    indicadorCursoMes,
    indicadorCursosCategoria,
    indicadorComparativo,
    indicadorEstadoCurso,
    indicadorCursosRelator,
    indicadorInscritosRegistrados,
    indicadorPagos,
  },
})
export default class VistaMonitoreo extends Vue {
  tabs = null;
  tabsAño = null;
  date1 = "";
  date2 = "";
  fechas: Array<string> = [];
  estadoCurso = "";
  relator: Usuario | null = null;
  metodoPago: { nombre: string; tipo: Array<string> } | null = null;
  region = "";
  metodosdePago = [
    { nombre: "Crédito ", tipo: ["VN", "S2", "SI", "NC", "VC"] },
    { nombre: "Débito", tipo: ["VD"] },
    { nombre: "Prepago", tipo: ["VP"] },
  ];
  estadosCursos = [{ nombre: "aceptado" }, { nombre: "terminado" }];
  tiposPagos = {
    VN: "Crédito",
    S2: "Crédito",
    SI: "Crédito",
    NC: "Crédito",
    VC: "Crédito",
    VD: "Debito",
    VP: "Prepago",
  };

  get fechasMostrar(): string {
    return this.fechas
      .map((fecha) => fecha.toDate().format("DD-MM-YYYY"))
      .join(" ~ ");
  }

  set fechasMostrar(array: string) {
    this.fechas = [];
  }

  created(): void {
    if (!routeGuard(this)) {
      return;
    }
  }
  get cursos(): Cursos {
    return this.getCursos;
  }

  get items(): Array<Curso> {
    return this.getArrayCursos;
  }

  get pagos(): Pagos {
    return this.getPagos;
  }

  get usuarios(): Array<Usuario> {
    return this.getArrayUsuarios;
  }

  get inscripciones(): Array<Inscripcion> {
    return this.getArrayInscripciones;
  }

  get itemsPagos(): Array<Pago> {
    return this.getArrayPagos;
  }

  get tipoUsuario(): TipoUsuario {
    if (!this.getUsuario) {
      return "sudo";
    }
    return this.getUsuario.tipo;
  }

  get pagosFiltrados(): Array<Pago> {
    // const startDate = this.fechas.firstOrNull?.toDate();
    // startDate?.setHours(0, 0, 0, 0);
    // const endDate = this.fechas.lastOrNull?.toDate();
    // endDate?.setHours(23, 59, 59);
    let startDate = (fechaLocal() + " 00:00:00").toDate();
    let endDate = (fechaLocal() + " 23:59:59").toDate();
    // Filtro por Dia
    if (this.tabs == 0) {
      startDate.setDate(startDate.getDate());
    }
    // Filtro por Semana
    else if (this.tabs == 1) {
      startDate.setDate(startDate.getDate() - 7);
    }
    // Filtro por Mes
    else if (this.tabs == 2) {
      startDate.setDate(startDate.getDate() - 30);
    }
    // Filtro por Año
    else if (this.tabs == 3) {
      startDate.setDate(startDate.getDate() - 365);
    }
    return this.getArrayInscripciones
      .sort(
        (inscripcion1, inscripcion2) =>
          inscripcion1.pago.resultado?.transaction_date.toDate().getTime() ??
          0 -
            (inscripcion2.pago.resultado?.transaction_date.toDate().getTime() ??
              0)
      )
      .filter((inscripcion) => {
        if (this.tipoUsuario == "admin") {
          const filtroCurso = this.curso
            ? Object.values(inscripcion.pago.cursos).some(
                (curso) => curso.id === this.curso?.id
              )
            : true;
          const filtroFecha =
            startDate && endDate
              ? (inscripcion.pago.resultado?.transaction_date
                  .toDate()
                  .getTime() ??
                  0 >= startDate.getTime()) &&
                (inscripcion.pago.resultado?.transaction_date
                  .toDate()
                  .getTime() ??
                  0 <= endDate.getTime())
              : true;
          const filtroMetodoPago = this.metodoPago
            ? this.metodoPago.tipo.some(
                (tipo) => tipo == inscripcion.pago.resultado?.payment_type_code
              )
            : true;
          const filtroRegion = this.region
            ? inscripcion.pago.cliente.ciudad?.region.nombre === this.region
            : true;
          const filtroRelator = this.relator
            ? Object.values(inscripcion.pago.cursos).some(
                (curso) => curso.relator.id === this.relator?.id
              )
            : true;
          return (
            inscripcion &&
            filtroCurso &&
            filtroFecha &&
            filtroMetodoPago &&
            filtroRegion &&
            filtroRelator
          );
        }

        if (this.tipoUsuario == "relator") {
          const filtroRelator = Object.values(inscripcion.pago.cursos).some(
            (curso) => curso.relator.id == this.getUsuario?.id
          );
          const filtroCurso = Object.values(inscripcion.pago.cursos).some(
            (curso) => curso.estado == "aceptado" || curso.estado == "terminado"
          );
          const filtroEstadoCurso = this.estadoCurso
            ? Object.values(inscripcion.pago.cursos).some(
                (curso) => curso.estado == this.estadoCurso
              )
            : true;
          const filtroFecha =
            endDate && startDate
              ? Object.values(inscripcion.pago.cursos).some(
                  (curso) =>
                    curso.inicio.toDate().getTime() >= startDate.getTime() &&
                    curso.inicio.toDate().getTime() <= endDate.getTime()
                )
              : true;
          return (
            inscripcion &&
            filtroRelator &&
            filtroCurso &&
            filtroEstadoCurso &&
            filtroFecha
          );
        }
      })
      .map((inscripcion) => inscripcion.pago);
  }

  get recaudacionTotal(): number {
    let suma = 0;
    for (const inscripcion of this.getArrayInscripciones) {
      if (inscripcion.pago.resultado?.status == "AUTHORIZED") {
        suma += inscripcion.pago.resultado.amount;
      }
    }
    return suma;
  }

  get recaudacionMensual(): number {
    let suma = 0;
    for (const pago of this.filtroPagoFecha) {
      if (pago.resultado?.status == "AUTHORIZED") {
        suma += pago.resultado.amount;
      }
    }
    return suma;
  }

  get recaudacionDosMeses(): number {
    let suma = 0;
    for (const pago of this.filtroPagoDosMes) {
      if (pago.resultado?.status == "AUTHORIZED") {
        suma += pago.resultado.amount;
      }
    }
    return suma;
  }

  // Filtro de Pagos por Fechas
  get filtroPagoFecha(): Array<Pago> {
    let startDate = (fechaLocal() + " 00:00:00").toDate();
    let endDate = (fechaLocal() + " 23:59:59").toDate();
    // Filtro por Dia
    if (this.tabs == 0) {
      startDate.setDate(startDate.getDate());
    }
    // Filtro por Semana
    else if (this.tabs == 1) {
      startDate.setDate(startDate.getDate() - 7);
    }
    // Filtro por Mes
    else if (this.tabs == 2) {
      startDate.setDate(startDate.getDate() - 30);
    }
    // Filtro por Año
    else if (this.tabs == 3) {
      startDate.setDate(startDate.getDate() - 365);
    }
    // Filtro Historico
    // else if (this.tabs == 4) {
    //   //startDate = (this.date1 + " 00:00:00").toDate();
    //   //endDate = (this.date2 + " 23:59:59").toDate();
    //   startDate.setDate(startDate.getDate() - 1.825);
    // }
    return this.getArrayInscripciones
      .filter((inscripcion) => {
        const inicio = startDate;
        const fin = endDate;
        const fechaPago =
          inscripcion.pago.resultado?.transaction_date.toDate() || 0;
        //console.log(fechaPago);
        const fechaValida = fechaPago >= inicio && fechaPago <= fin;
        //console.log(fechaValida);
        return fechaValida;
      })
      .map((inscripcion) => inscripcion.pago);
    // .sort((a, b) => {
    //   return (
    //     (b.resultado?.transaction_date.toDate().valueOf() || 0) -
    //     (a.resultado?.transaction_date.toDate().valueOf() || 0)
    //   );
    //});
  }

  get filtroPagoMes(): Array<Pago> {
    let startDate = (fechaLocal() + " 00:00:00").toDate();
    let endDate = (fechaLocal() + " 23:59:59").toDate();
    startDate.setDate(startDate.getDate() - 30);
    return Object.values(this.pagos)
      .filter((pago) => {
        const inicio = startDate;
        const fin = endDate;
        const fechaPago = pago.resultado?.transaction_date.toDate() || 0;
        const fechaValida = fechaPago >= inicio && fechaPago <= fin;
        return fechaValida;
      })
      .sort((a, b) => {
        return (
          (b.resultado?.transaction_date.toDate().valueOf() || 0) -
          (a.resultado?.transaction_date.toDate().valueOf() || 0)
        );
      });
  }

  get filtroPagoDosMes(): Array<Pago> {
    let startDate = (fechaLocal() + " 00:00:00").toDate();
    let endDate = (fechaLocal() + " 23:59:59").toDate();

    endDate.setDate(endDate.getDate() - 31);
    startDate.setDate(startDate.getDate() - 61);
    return Object.values(this.pagos)
      .filter((pago) => {
        const inicio = startDate;
        const fin = endDate;
        const fechaPago = pago.resultado?.transaction_date.toDate() || 0;
        const fechaValida = fechaPago >= inicio && fechaPago <= fin;
        return fechaValida;
      })
      .sort((a, b) => {
        return (
          (b.resultado?.transaction_date.toDate().valueOf() || 0) -
          (a.resultado?.transaction_date.toDate().valueOf() || 0)
        );
      });
  }

  get diferenciaPorcentualRecaudacion(): number {
    let valorUno = this.recaudacionDosMeses;
    let valorDos = this.recaudacionMensual;

    let divisor = valorUno;
    if (divisor == 0) {
      divisor = 1;
    }
    // console.log(">", valorUno, valorDos);
    const diferenciaPorcentual = ((valorDos - valorUno) / divisor) * 100;
    return diferenciaPorcentual;
  }

  get vendidosCursosTotal(): number {
    let suma = 0;
    for (const pago of this.filtroPagoFecha) {
      if (pago.resultado?.status == "AUTHORIZED") {
        if (pago.cursos) {
          suma = suma + 1;
        }
      }
    }
    return suma;
  }

  get vendidosCursosMes(): number {
    let suma = 0;
    for (const pago of this.filtroPagoMes) {
      if (pago.resultado?.status == "AUTHORIZED") {
        if (pago.cursos) {
          suma += suma + 1;
        }
      }
    }
    return suma;
  }

  get vendidosCursosDosMes(): number {
    let suma = 0;
    for (const pago of this.filtroPagoDosMes) {
      if (pago.resultado?.status == "AUTHORIZED") {
        if (pago.cursos) {
          suma += suma + 1;
        }
      }
    }
    return suma;
  }

  get diferenciaPorcentualVendidos(): number {
    let valorUno = this.vendidosCursosDosMes;
    let valorDos = this.vendidosCursosMes;

    let divisor = valorUno;
    if (divisor == 0) {
      divisor = 1;
    }
    const diferenciaPorcentual = ((valorDos - valorUno) / divisor) * 100;
    return diferenciaPorcentual;
  }

  curso: Curso | null = null;

  get cursoItems(): ItemsTablaCursos {
    return this.items.map((curso) => {
      const nombre = curso.nombre;
      const descripcion = curso.descripcion;
      const categoria = curso.categoria.nombre;
      const relator = curso.relator.nombre;
      const precio = curso.precio.toCurrency({ symbol: "$" });
      const inicio = curso.inicio.toDate().format("DD-MM-YYYY");
      const fin = curso.fin.toDate().format("DD-MM-YYYY");
      const estado = curso.estado.toString();
      return {
        nombre,
        descripcion,
        categoria,
        relator,
        precio,
        inicio,
        fin,
        estado,
        data: curso,
      };
    });
  }

  pago: Pago | null = null;
}
