








































































































































// decoradores
// vuex
import { mapActions, mapGetters } from "vuex";
// tipos
// decoradores
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { Consumo } from "@/typings/store/plugins/easyFirestore/consumos";
import SVGIcon from "@/components/SVGIcon.vue";
// medidores
// componentes
import TablaMedidor from "@/components/medidores/Tabla.vue";
import FormularioMedidor from "@/components/medidores/Formulario.vue";
import { DataFormularioMedidor } from "@/typings/components/medidores/formulario";
import DialogoExito from "@/components/DialogoExito.vue";
import DialogoEliminar from "@/components/DialogoEliminar.vue";
import InfoMedidor from "@/components/medidores/Info.vue";
//import BotonesCrud from "@/components/BotonesCrud.vue";
import MensajeEliminar from "@/components/MensajeEliminar.vue";
//import TituloVista from "@/components/TituloVista.vue";
import {
  Medidor,
  Medidores,
  //  Regiones,
} from "@/typings/store/plugins/easyFirestore/medidores";
//import { DataFormularioRegion } from "@/typings/components/regiones/formulario";
import CargarMedidoresExcel from "@/components/excel/CargarMedidores.vue";
import { HeadersDataTable } from "@/typings/vuetify/vDataTable";

const routeGuard = (vm: Vue): boolean => {
  const permisos = vm.getUsuario?.permisos;
  if (permisos && permisos["medidores"]) {
    return true;
  }

  const rutas = vm.getRutasUsuario;
  if (rutas.length > 0) {
    const to = rutas[0].to;
    if (to) {
      if (vm.$route.name !== to.name) {
        vm.$router.replace(to);
      }
      return false;
    }
  }
  if (vm.$route.name !== "inicio") {
    vm.$router.replace({ name: "inicio" });
  }
  return false;
};

@Component({
  computed: mapGetters({
    getUsuarios: "usuarios/get",
    getMedidores: "medidores/get",
    getArrayUsuarios: "usuarios/getArray",
    getUsuario: "usuario/get",
    getRutas: "rutas/get",
    getRutasUsuario: "usuario/getRutas",
    getArrayMedidores: "medidores/getArray",
  }),
  methods: mapActions({
    setMedidor: "medidores/set",
    deleteMedidor: "medidores/delete",
    insertBatchMedidor: "medidores/insertBatch",
    //setArchivo: "archivos/set",
    //deleteArchivo: "archivos/delete",
  }),
  beforeRouteEnter(to, from, next) {
    next(routeGuard);
  },
  components: {
    SVGIcon,
    DialogoEliminar,
    DialogoExito,
    TablaMedidor,
    FormularioMedidor,
    InfoMedidor,
    MensajeEliminar,
    CargarMedidoresExcel,
  },
})
export default class InfoConsumo extends Vue {
  @Prop() readonly consumo!: Consumo;
  @Prop({ type: Boolean }) readonly showTitulo!: boolean;

  options = {
    itemsPerPage: -1,
  };
  headerProps = {
    sortIcon: "mdi-chevron-down",
  };

  get headers(): HeadersDataTable {
    return [
      //{text: "", value: "nroMedidor", sortable: false},
      { text: "OPM Medidor", value: "nroMedidor" },
      { text: "Punto de Medida", value: "" },
      { text: "Ultimo Operador en SUC", value: "" },

      { text: "Estado Medidor", value: "estado" },
      { text: "Fecha Creación", value: "" },
      { text: "Tipo Medidor", value: "tipoMedidor" },
      { text: "Tipo Tarifa", value: "tipoTarifa" },
      { text: "Marca Medidor", value: "marca" },
      { text: "Modelo Medidor", value: "modelo" },
      { text: "Serie Medidor", value: "serie" },
      { text: "Limite Inv", value: "" },
      { text: "Arriendo Equipo", value: "" },
      { text: "Pot H Normal", value: "" },
      { text: "Pot H Punta", value: "potenciaHP" },
      { text: "Respaldo", value: "" },
      { text: "Constante", value: "" },
      { text: "Diametro", value: "" },
      { text: "Entero", value: "enteros" },
      { text: "Decimal", value: "decimales" },
      { text: "Con Cobro", value: "" },
      { text: "Opciones", value: "" },



    ];
  }

  cargando = false;
  dialogoExito = {
    model: false,
    texto: "",
    icono: "",
  };
  search = "";
  medidor: Medidor | null = null;
  dialogoInfo = false;
  dialogoFormulario = false;
  dialogoEliminar = false;
  textoEliminar = "¿ESTÁS SEGURO QUE DESEAS ELIMINAR ESTE BANCO?";
  dialogoCargarExcel = false;

  get medidores(): Medidores {
    return this.getMedidores;
  }

  get items(): Array<Medidor> {
    return this.getArrayMedidores;
  }

  get itemsMedidoresTabla(): Array<Medidor> {
    return this.getArrayMedidores.filter((item) => {
      if (this.consumo.id)
        return item.idOperador
          .toLowerCase()
          .includes(this.consumo.id.toLowerCase());
    });
  }

  get id(): string {
    return this.consumo.id || "N/R";
  }

  get centroCosto(): string {
    return this.consumo.centroCosto || "N/R";
  }

  get unidades(): string {
    return this.consumo.unidades || "N/R";
  }

  get empresa(): string {
    return this.consumo.empresa || "N/R";
  }

  clickItem(val: { id: string } | null): void {
    this.medidor = val ? this.medidores[val.id] : null;
  }

  clickPlus(): void {
    this.medidor = null;
    this.dialogoFormulario = true;
  }

  async clickGuardar(data: DataFormularioMedidor): Promise<void> {
    this.cargando = true;
    const doc = data.doc;
    await this.setMedidor(doc);
    if (doc.id != this.medidor?.id) {
      this.dialogoExito = {
        model: true,
        texto: "¡Banco creado con éxito!",
        icono: "../assets/icons/double-check-duo.svg",
      };
    }
    if (doc.id == this.medidor?.id) {
      this.dialogoExito = {
        model: true,
        texto: "¡Banco editado con éxito!",
        icono: "../assets/icons/editar.svg",
      };
      this.medidor = doc;
    }
    this.cerrarDialogoFormulario();
    this.cargando = false;
  }

  cerrarDialogoExito(): void {
    this.dialogoExito = {
      model: false,
      texto: "",
      icono: "",
    };
  }

  clickEliminar(): void {
    const medidor = this.medidor;
    if (!medidor?.id) {
      console.error("error id: ", medidor);
      return;
    }
    this.deleteMedidor(medidor.id);
    this.medidor = null;
    this.cerrarDialogoEliminar();
  }

  abrirDialogoInfo(medidor: Medidor): void {
    if (!medidor.id) {
      console.error("error id: ", medidor);
      return;
    }
    const doc = this.medidores[medidor.id];
    if (doc) {
      this.medidor = doc;
      this.dialogoInfo = true;
    }
  }

  cerrarDialogoInfo(): void {
    this.dialogoInfo = false;
    this.medidor = null;
  }

  abrirDialogoFormulario(medidor: Medidor): void {
    if (!medidor.id) {
      console.error("error id: ", medidor);
      return;
    }
    const doc = this.medidores[medidor.id];
    if (doc) {
      this.medidor = doc;
      this.dialogoFormulario = true;
    }
  }

  abrirDialogoEliminar(medidor: Medidor): void {
    if (!medidor.id) {
      console.error("error id: ", medidor);
      return;
    }
    const doc = this.medidores[medidor.id];
    if (doc) {
      this.dialogoEliminar = true;
      this.medidor = doc;
    }
  }

  cerrarDialogoEliminar(): void {
    this.dialogoEliminar = false;
    this.medidor = null;
  }

  cerrarDialogoFormulario(): void {
    this.dialogoFormulario = false;
    this.medidor = null;
  }

  @Emit("cerrar")
  onCerrar(): void {
    return;
  }
}
