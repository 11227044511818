












































































// decoradores
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import {Notificacion} from "@/typings/store/plugins/easyFirestore/notificaciones";
import SVGIcon from "@/components/SVGIcon.vue";
import "@/helpers/extensions"
// tipos
@Component({
  components: {SVGIcon}
})
export default class InfoCiudad extends Vue {
  @Prop() readonly notificacion!: Notificacion;
  @Prop({ type: Boolean }) readonly showTitulo!: boolean;

  get id(): string {
    return this.notificacion.id || "N/R";
  }

  get titulo(): string {
    return this.notificacion.titulo || "N/R";
  }

  get contenido(): string {
    return this.notificacion.contenido || "N/R";
  }

  get fecha(): string {
    return this.notificacion.fecha?.toDate()?.format("DD-MM-YYYY") || "N/R";
  }

  get hora(): string {
    return this.notificacion.fecha?.toDate()?.format("HH:mm") || "N/R";
  }

  get topico(): string {
    return this.notificacion.topico || "N/R";
  }

  get tipo(): string {
    return this.notificacion.tipo || "N/R";
  }

  @Emit("cerrar")
  onCerrar(): void {
    return;
  }
}
