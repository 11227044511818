import { Store } from "vuex";
import { State } from "@/typings/store";
import { Usuario } from "@/typings/store/plugins/easyFirestore/usuarios";
import {
  PathVariables,
  Clauses,
} from "@/typings/store/plugins/easyFirestore/iniciar/abrirCanalesBD";
import modulos from "./modulos";

const abrirCanalesBD = async (
  store: Store<State>,
  usuario: Usuario
): Promise<void> => {
  const promesas = modulos.map((modulo) => {
    const valido = modulo.permisos.some((permiso) => {
      return usuario.permisos[permiso];
    });
    if (valido || modulo.permisos.length === 0) {
      const pathVariables: PathVariables = {};
      const clauses: Clauses = {
        where: [],
        orderBy: [],
      };
      modulo.parametros.forEach((parametro) => {
        if (!parametro.name || !parametro.value) {
          return;
        }
        let valor = usuario as never;
        parametro.value.split(".").forEach((key) => {
          if (!valor || !valor[key]) {
            return;
          }
          valor = valor[key];
        });
        if (!valor) {
          return;
        }
        pathVariables[parametro.name] = valor;
      });
      modulo.where.forEach((item) => {
        if (
          item.some((val) => {
            return !val;
          })
        ) {
          return;
        }
        let valor = usuario as never;
        item[2].split(".").forEach((key) => {
          if (!valor || !valor[key]) {
            return;
          }
          valor = valor[key];
        });
        if (!valor) {
          return;
        }
        clauses.where.push([item[0], item[1], valor]);
      });
      return store.dispatch(modulo.nombre + "/openDBChannel", {
        pathVariables,
        clauses,
      });
    }
    return null;
  });
  await Promise.all(promesas);
};

export default abrirCanalesBD;
