import { RouteConfig } from "vue-router";
import Mantenimientos from "@/views/Mantenimientos.vue";

const mantenimientos: RouteConfig = {
    path: "/mantenimientos",
    name: "mantenimientos",
    component: Mantenimientos,
};

export default mantenimientos;
