import { ActionTree, MutationTree, GetterTree } from "vuex";
// firebase
import { Firebase } from "@/config/firebase";
// tipos
import { State } from "@/typings/store";
import { Archivos, Archivo } from "@/typings/store/modules/archivos";

interface StateArchivos {
  all: Archivos;
}

const state: StateArchivos = {
  all: {},
};

const getters: GetterTree<StateArchivos, State> = {};

const mutations: MutationTree<StateArchivos> = {};

const actions: ActionTree<StateArchivos, State> = {
  get(ctx, data: Archivo) {
    const storage = Firebase.storage();
    const storageRef = storage.ref();
    const imagenRef = storageRef.child(data.id);
    return imagenRef
      .getDownloadURL()
      .then((url) => {
        const doc: Archivo = {
          id: data.id,
          nombre: data.nombre,
          url: url,
        };
        return doc;
      })
      .catch((error) => {
        console.log(error);
        return null;
      });
  },
  set(ctx, data: Archivo) {
    const storage = Firebase.storage();
    const storageRef = storage.ref();
    const imagenRef = storageRef.child(data.id);
    if (data.file) {
      return imagenRef
        .put(data.file)
        .then(() => {
          return imagenRef.getDownloadURL().then((url) => {
            const doc: Archivo = {
              id: data.id,
              nombre: data.nombre,
              url: url,
            };
            return doc;
          });
        })
        .catch((error) => {
          console.log(error);
          return null;
        });
    } else {
      return null;
    }

  },
  delete(ctx, id: string) {
    if (!id) {
      return;
    }
    const storage = Firebase.storage();
    const storageRef = storage.ref();
    const imagenRef = storageRef.child(id);
    return imagenRef
      .delete()
      .then(() => {
        return id;
      })
      .catch((error) => {
        console.log(error);
        return id;
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
