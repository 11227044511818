





































import { Vue, Component, Prop } from "vue-property-decorator";
import { mapActions, mapGetters } from "vuex";
import { Usuario } from "@/typings/store/plugins/easyFirestore/usuarios";
import { Curso } from "@/typings/store/plugins/easyFirestore/cursos";
import { Pago } from "@/typings/store/plugins/easyFirestore/pagos";
import CardCurso from "@/components/cursos/Card.vue";
import InformacionCompletaCurso from "@/views/InformacionCompleta.vue";

const routeGuard = (vm: Vue): boolean => {
  const permisos = vm.getUsuario?.permisos;
  if (permisos && permisos["misCursos"]) {
    return true;
  }

  const rutas = vm.getRutasUsuario;
  if (rutas.length > 0) {
    const to = rutas[0].to;
    if (to) {
      if (vm.$route.name !== to.name) {
        vm.$router.replace(to);
      }
      return false;
    }
  }
  if (vm.$route.name !== "inicio") {
    vm.$router.replace({ name: "inicio" });
  }
  return false;
};

@Component({
  computed: mapGetters({
    getCursos: "cursos/get",
    getPagos: "pagos/get",
    getArrayPagos: "pagos/getArray",
    getUsuario: "usuario/get",
    getRutasUsuario: "usuario/getRutas",
    getArraySesiones: "sesiones/getArray",
    getArrayInscripciones: "inscripciones/getArray",
  }),
  methods: mapActions({}),
  beforeRouteEnter(to, from, next) {
    next(routeGuard);
  },
  components: {
    CardCurso,
    InformacionCompletaCurso,
  },
})
export default class VistaMisCursos extends Vue {
  @Prop() readonly usuario!: Usuario;

  created(): void {
    if (!routeGuard(this)) {
      return;
    }
    if (this.$route.params?.curso) {
      const curso = this.getCursos[this.$route.params.curso];
      if (curso) {
        this.seleccionarCurso(curso);
      }
    }
  }

  curso: Curso | null = null;

  get cursos(): Curso[] {
    let cursos: Curso[] = [];
    for (const pago of this.arrayPagosUsuario) {
      for (const curso of Object.values(pago.cursos)) {
        if (curso.id) {
          const cursoDos = this.getCursos[curso.id];
          if (cursoDos) {
            cursos.push(cursoDos);
          }
        }
      }
    }
    return cursos;
  }

  get arrayPagosUsuario(): Pago[] {
    return this.getArrayInscripciones
      .filter((inscripciones) => inscripciones.cliente.id === this.usuario.id)
      .map((inscripcion) => inscripcion.pago);
  }

  get arrayPagos(): Pago[] {
    return this.getArrayPagos;
  }

  seleccionarCurso(curso: Curso): void {
    this.curso = curso;
    const id = this.curso.id ?? "";
    if (
      this.$route.name !== "informacionCompleta" ||
      this.$route.params.id !== id
    ) {
      this.$router.push({
        name: "informacionCompleta",
        params: { id: id },
      });
    }
  }
}
