






















































































































































































































































































































































































// decoradores
import { Vue, Component } from "vue-property-decorator";
// vuex
import { mapActions, mapGetters } from "vuex";
// tipos

// componentes
import TablaRegistrosEgresos from "@/components/registrosEgresos/Tabla.vue";
import FormularioRegistrosEgresos from "@/components/registrosEgresos/Formulario.vue";
import InfoRegistrosEgresos from "@/components/registrosEgresos/Info.vue";
// import FormularioRegion from "@/components/regiones/Formulario.vue";
// import InfoRegion from "@/components/regiones/Info.vue";
import BotonesCrud from "@/components/BotonesCrud.vue";
//

import MensajeEliminar from "@/components/MensajeEliminar.vue";
import TituloVista from "@/components/TituloVista.vue";
import {
  Egresos,
  RegistrosEgresos,
} from "@/typings/store/plugins/easyFirestore/registrosEgresos";
import { Banco } from "@/typings/store/plugins/easyFirestore/bancos";
import { CentroCosto } from "@/typings/store/plugins/easyFirestore/centrosCostos";
import { CodCuenta } from "@/typings/store/plugins/easyFirestore/codCuentas";
import { TipoEgreso } from "@/typings/store/plugins/easyFirestore/tipoEgresos";
import { Proveedor } from "@/typings/store/plugins/easyFirestore/proveedores";
import { TipoDocumento } from "@/typings/store/plugins/easyFirestore/tipoDocumentos";
import { TipoCargo } from "@/typings/store/plugins/easyFirestore/tipoCargos";
import {
  // MapeoGastos,
  MapeoGasto,
} from "@/typings/store/plugins/easyFirestore/MapeoGastos";
import CargarRegistrosEgresosExcel from "@/components/excel/CargarRegistrosEgresos.vue";
import { DataFormularioRegistrosEgresos } from "@/typings/components/registrosEgresos/formulario";
//import { Usuario } from "@/typings/store/plugins/easyFirestore/usuarios";

import { fechaLocal } from "@/helpers/date";
// Excel
import Exceljs from "exceljs";

const routeGuard = (vm: Vue): boolean => {
  const permisos = vm.getUsuario?.permisos;
  if (permisos && permisos["registrosEgresos"]) {
    return true;
  }

  const rutas = vm.getRutasUsuario;
  if (rutas.length > 0) {
    const to = rutas[0].to;
    if (to) {
      if (vm.$route.name !== to.name) {
        vm.$router.replace(to);
      }
      return false;
    }
  }
  if (vm.$route.name !== "inicio") {
    vm.$router.replace({ name: "inicio" });
  }
  return false;
};

@Component({
  computed: mapGetters({
    getRegistrosEgresos: "registrosEgresos/get",
    // getEgresos: "egresos/get",
    getArrayRegistrosEgresos: "registrosEgresos/getArray",
    getUsuario: "usuario/get",
    getRutasUsuario: "usuario/getRutas",
    getArrayBancos: "bancos/getArray",
    getArrayCentrosCostos: "centrosCostos/getArray",
    getArrayCodCuentas: "codCuentas/getArray",
    getArrayTipoEgresos: "tipoEgresos/getArray",
    getArrayProveedores: "proveedores/getArray",
    getArrayTipoDocumentos: "tipoDocumentos/getArray",
    getArrayTipoCargos: "tipoCargos/getArray",
    getArrayMapeoGastos: "mapeoGastos/getArray",
    getArrayPrenominas: "prenominas/getArray",
  }),
  methods: mapActions({
    setEgreso: "registrosEgresos/set",
    deleteEgreso: "registrosEgresos/delete",
    insertBatchEgreso: "registrosEgresos/insertBatch",
  }),
  beforeRouteEnter(to, from, next) {
    next(routeGuard);
  },
  components: {
    TablaRegistrosEgresos,
    FormularioRegistrosEgresos,
    InfoRegistrosEgresos,
    BotonesCrud,
    MensajeEliminar,
    TituloVista,
    CargarRegistrosEgresosExcel,
  },
})
export default class VistaRegistrosEgresos extends Vue {
  created(): void {
    if (!routeGuard(this)) {
      return;
    }
    console.log("A");
  }
  cargando = false;
  dialogoExito = {
    model: false,
    texto: "",
    icono: "",
  };
  search = "";
  seleccionadorPeriodo = "";
  seleccionadorFechaEgreso = "";
  seleccionadorNCliente = "";
  seleccionadorNNomina = "";
  seleccionadorBanco = "";
  banco: Banco | null = null;
  centroCosto: CentroCosto | null = null;
  codCuenta: CodCuenta | null = null;
  tipoEgreso: TipoEgreso | null = null;
  proveedor: Proveedor | null = null;
  tipoDocumento: TipoDocumento | null = null;
  tipoCargo: TipoCargo | null = null;
  mapeoGasto: MapeoGasto | null = null;
  registrosEgresos: RegistrosEgresos | null = null; //region: Region | null = null;
  dialogoInfo = false;
  dialogoFormulario = false;
  dialogoEliminar = false;
  dialogoCargarExcel = false;

  get egresos(): Egresos {
    //regiones
    return this.getRegistrosEgresos;
  }

  get items(): Array<RegistrosEgresos> {
    if (
      this.seleccionadorPeriodo ||
      this.seleccionadorBanco ||
      this.seleccionadorFechaEgreso ||
      this.seleccionadorNCliente ||
      this.seleccionadorNNomina
    ) {
      return this.getArrayRegistrosEgresos.filter((egresos) => {
        const periodo = egresos.PERIODO === this.seleccionadorPeriodo;
        const banco = egresos.BANCO === this.seleccionadorBanco;
        const fechaEgreso =
          egresos.FECHA_EGRESO === this.seleccionadorFechaEgreso;
        const nCliente = egresos.N_CLIENTE === this.seleccionadorNCliente;
        const nNomina = egresos.N_NOMINA === this.seleccionadorNNomina;
        if (
          this.seleccionadorPeriodo &&
          this.seleccionadorBanco &&
          this.seleccionadorFechaEgreso && 
          this.seleccionadorNCliente &&
          this.seleccionadorNNomina
        ) {
          return periodo && banco && fechaEgreso && nCliente && nNomina;
        } else {
          if (this.seleccionadorBanco) {
            return banco;
          }
          if (this.seleccionadorFechaEgreso) {
            return fechaEgreso;
          }
          if (this.seleccionadorPeriodo) {
            return periodo;
          }
          if (this.seleccionadorNCliente) {
            return nCliente;
          }   
          if (this.seleccionadorNNomina) {
            return nNomina;
          }
        }
      });
    } else {
      return this.getArrayRegistrosEgresos;
    }
  }

  get selectPeriodo(): Array<string> {
    let periodo = "";
    let arrayPeriodos = [periodo];
    this.getArrayRegistrosEgresos.forEach((registro) => {
      arrayPeriodos.push(registro.PERIODO);
    });

    const unicos = arrayPeriodos.filter((valor, indice) => {
      return arrayPeriodos.indexOf(valor) === indice;
    });

    unicos.sort((a, b) => {
      const fechaA = new Date(a);
      const fechaB = new Date(b);
      return fechaB.getTime() - fechaA.getTime();
    });
    return unicos;
  }

  get selectBancos(): Array<string> {
    let banco = "";
    let arrayBancos = [banco];
    this.getArrayRegistrosEgresos.forEach((registro) => {
      arrayBancos.push(registro.BANCO);
    });

    const unicos = arrayBancos.filter((valor, indice) => {
      return arrayBancos.indexOf(valor) === indice;
    });
    return unicos.sort();
  }

  get selectFechaEgreso(): Array<string> {
    //define fecha vacia

    let fecha = "";
    let arrayFechas = [fecha];
    this.getArrayRegistrosEgresos.forEach((registro) => {
      arrayFechas.push(registro.FECHA_EGRESO);
    });

    const unicos = arrayFechas.filter((valor, indice) => {
      return arrayFechas.indexOf(valor) === indice;
    });

    // ordena unicos
    return unicos.sort();
  }

  get selectNCliente(): Array<string> {
    //define fecha vacia

    let nCliente = "";
    let arrayNCliente = [nCliente];
    this.getArrayRegistrosEgresos.forEach((registro) => {
      arrayNCliente.push(registro.N_CLIENTE);
    });

    const unicos = arrayNCliente.filter((valor, indice) => {
      return arrayNCliente.indexOf(valor) === indice;
    });

    // ordena unicos
    return unicos.sort();
  }

  get selectNNomina(): Array<string> {
    //define fecha vacia

    let nNomina = "";
    let arrayNNomina = [nNomina];
    this.getArrayRegistrosEgresos.forEach((registro) => {
      arrayNNomina.push(registro.N_NOMINA);
    });

    const unicos = arrayNNomina.filter((valor, indice) => {
      return arrayNNomina.indexOf(valor) === indice;
    });

    // ordena unicos
    return unicos.sort((a, b) => b.localeCompare(a, undefined, { numeric: true, sensitivity: 'base' }));

  }

  clickItem(val: { id: string } | null): void {
    this.registrosEgresos = val ? this.egresos[val.id] : null;
  }

  clickPlus(): void {
    this.registrosEgresos = null;
    this.dialogoFormulario = true;
  }

  async clickGuardar(data: DataFormularioRegistrosEgresos): Promise<void> {
    this.cargando = true;
    const doc = data.doc;
    this.setEgreso(doc);
    if (doc.id != this.registrosEgresos?.id) {
      this.dialogoExito = {
        model: true,
        texto: "¡Registro creado con éxito!",
        icono: require("../assets/icons/double-check-duo.svg"),
      };
    }
    if (doc.id == this.registrosEgresos?.id) {
      this.dialogoExito = {
        model: true,
        texto: "¡Registro editado con éxito!",
        icono: require("../assets/icons/editar.svg"),
      };
      this.registrosEgresos = doc;
    }
    this.cerrarDialogoFormulario();
    this.cargando = false;
  }

  clickEliminar(): void {
    const registrosEgresos = this.registrosEgresos;
    if (!registrosEgresos?.id) {
      console.error("error id: ", registrosEgresos);
      return;
    }
    this.deleteEgreso(registrosEgresos.id);
    this.registrosEgresos = null;
    this.cerrarDialogoEliminar();
  }

  abrirDialogoInfo(registrosEgresos: RegistrosEgresos): void {
    if (!registrosEgresos.id) {
      console.error("error id: ", registrosEgresos);
      return;
    }
    const doc = this.egresos[registrosEgresos.id];
    if (doc) {
      this.registrosEgresos = doc;
      this.dialogoInfo = true;
    }
  }

  cerrarDialogoInfo(): void {
    this.dialogoInfo = false;
    this.registrosEgresos = null;
  }

  abrirDialogoFormulario(registrosEgresos: RegistrosEgresos): void {
    if (!registrosEgresos.id) {
      console.error("error id: ", registrosEgresos);
      return;
    }
    const doc = this.egresos[registrosEgresos.id];
    if (doc) {
      this.registrosEgresos = doc;
      this.dialogoFormulario = true;
    }
  }

  cerrarDialogoFormulario(): void {
    this.dialogoFormulario = false;
    this.registrosEgresos = null;
  }

  abrirDialogoEliminar(registrosEgresos: RegistrosEgresos): void {
    if (!registrosEgresos.id) {
      console.error("error id: ", registrosEgresos);
      return;
    }
    const doc = this.egresos[registrosEgresos.id];
    if (doc) {
      this.dialogoEliminar = true;
      this.registrosEgresos = doc;
    }
  }

  cerrarDialogoEliminar(): void {
    this.dialogoEliminar = false;
    this.registrosEgresos = null;
  }

  async createExcel(): Promise<void> {
    const workbook = new Exceljs.Workbook();
    const sheet = workbook.addWorksheet("EGRESOS", {
      properties: { defaultColWidth: 25 },
    });

    const rows2 = [
      [
        "ID",
        "PERIODO",
        "CENTRO_DE_COSTO",
        "DESCRIPCION_CUENTA",
        "CODIGO_CUENTA",
        "BANCO",
        "ORDEN_DE_COMPRA",
        "FECHA_EGRESO",
        "TIPO_EGRESO",
        "MONTO_EGRESO",
        "GLOSA",
        "RUT_PROVEEDOR",
        "NOMBRE_PROVEEDOR",
        "PAGO_C_P",
        "PORC_DEL_TOTAL",
        "NOMINA_PAGO",
        "TIPO_DE_DOCUMENTO",
        "N_DOCUMENTO",
        "FAC_DOC_NOTA",
        "CTA_ESTADO_RESULTADO",
        "CTA_CONTROL_GASTO",
        "TIPO_GASTO",
      ],
      ...this.itemsExcel,
      [],
    ];
    sheet.addRows(rows2);

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: "application/xlsx" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "Registro Egresos " + fechaLocal() + ".xlsx";
    link.click();
  }

  //ITEMS PDF
  get itemsExcel(): Array<Array<string | number | undefined>> {
    //let PrenominasRecorridas: Array<string> = [];
    return this.items.map((egreso) => {
      const ID = egreso.id;
      const PERIODO = egreso.PERIODO;
      const CENTRO_DE_COSTO = egreso.CENTRO_DE_COSTO;
      const DESCRIPCION_CUENTA = egreso.DESCRIPCION_CUENTA;
      const CODIGO_CUENTA = egreso.CODIGO_CUENTA;
      const BANCO = egreso.BANCO;
      const ORDEN_DE_COMPRA = egreso.ORDEN_DE_COMPRA;
      const FECHA_EGRESO = egreso.FECHA_EGRESO;
      const TIPO_EGRESO = egreso.TIPO_EGRESO;
      const MONTO_EGRESO = Number(egreso.MONTO_EGRESO);
      const GLOSA = egreso.GLOSA;
      const RUT_PROVEEDOR = egreso.RUT_PROVEEDOR;
      const NOMBRE_PROVEEDOR = egreso.NOMBRE_PROVEEDOR;
      const PAGO_C_P = egreso.PAGO_C_P;
      const PORC_DEL_TOTAL = egreso.PORC_DEL_TOTAL;
      const NOMINA_PAGO = egreso.NOMINA_PAGO;
      const TIPO_DE_DOCUMENTO = egreso.TIPO_DE_DOCUMENTO;
      const N_DOCUMENTO = egreso.N_DOCUMENTO;
      const FAC_DOC_NOTA = egreso.FAC_DOC_NOTA;
      const CTA_ESTADO_RESULTADO = egreso.CTA_ESTADO_RESULTADO;
      const CTA_CONTROL_GASTO = egreso.CTA_CONTROL_GASTO;
      const TIPO_GASTO = egreso.TIPO_GASTO;
      const N_CLIENTE = egreso.N_CLIENTE;
      return Object.values({
        ID,
        PERIODO,
        CENTRO_DE_COSTO,
        DESCRIPCION_CUENTA,
        CODIGO_CUENTA,
        BANCO,
        ORDEN_DE_COMPRA,
        FECHA_EGRESO,
        TIPO_EGRESO,
        MONTO_EGRESO,
        GLOSA,
        RUT_PROVEEDOR,
        NOMBRE_PROVEEDOR,
        PAGO_C_P,
        PORC_DEL_TOTAL,
        NOMINA_PAGO,
        TIPO_DE_DOCUMENTO,
        N_DOCUMENTO,
        FAC_DOC_NOTA,
        CTA_ESTADO_RESULTADO,
        CTA_CONTROL_GASTO,
        TIPO_GASTO,
        N_CLIENTE,
      });
    });
  }
}
