































































// decoradores
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { Solicitudes ,Solicitud } from "@/typings/store/plugins/easyFirestore/solicitudes";
import SVGIcon from "@/components/SVGIcon.vue";
// tipos

// tipos
@Component({
  components: {
    SVGIcon,
  },
})
export default class InfoSolicitudes extends Vue {
  @Prop() readonly solicitudes!: Solicitudes;
  @Prop({ type: Boolean }) readonly showTitulo!: boolean;

  // get id(): Solicitud {
  //   return this.solicitudes.id || "N/R";
  // }

  get nombre(): Solicitud {
    return this.solicitudes.nombre || "N/R";
  }

  // get posicion(): string {
  //   return this.solicitudes.posicion || "N/R";
  // }


  @Emit("cerrar")
  onCerrar(): void {
    return;
  }
}
