var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"rounded-0 tabla poppins primary--text font-weight-light",attrs:{"search":_vm.search,"headers":_vm.headers,"options":_vm.options,"items":_vm.items,"header-props":_vm.headerProps},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.estados",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-card',{staticClass:"rounded-pill",attrs:{"width":"24px","height":"24px","flat":"","color":_vm.obtenerColorTenue(item.data.estado)}},[_c('v-row',{staticClass:"fill-height",attrs:{"no-gutters":"","justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-card',{staticClass:"rounded-pill",attrs:{"width":"8px","height":"8px","flat":"","color":_vm.obtenerColor(item.data.estado)}})],1)],1)],1)],1)],1)]}},{key:"item.opciones",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"grey","icon":"","x-small":"","fab":""},on:{"click":function($event){return _vm.onAbrirAprobar(item.data)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"black"}},[_vm._v(_vm._s("mdi-file-document-outline"))])],1)]}}],null,true)},[_c('span',[_vm._v("Confirmación")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"grey","icon":"","x-small":"","fab":""},on:{"click":function($event){return _vm.onAbrirEditar(item.data)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"black"}},[_vm._v(_vm._s("mdi-comment-outline"))])],1)]}}],null,true)},[_c('span',[_vm._v("Comentario")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"grey","icon":"","x-small":"","fab":""},on:{"click":function($event){return _vm.onAbrirEditar(item.data)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"black"}},[_vm._v(_vm._s("mdi-file-edit-outline"))])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"grey","icon":"","x-small":"","fab":""},on:{"click":function($event){return _vm.onGenerarPdf(item.data)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"black"}},[_vm._v(_vm._s("mdi-download-outline"))])],1)]}}],null,true)},[_c('span',[_vm._v("Descargar Solicitud")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"grey","icon":"","x-small":"","fab":""},on:{"click":function($event){return _vm.onAbrirEliminar(item.data)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"black"}},[_vm._v(_vm._s("mdi-trash-can-outline"))])],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar")])])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }