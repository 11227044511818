























































































































import { Vue, Component } from "vue-property-decorator";
import { mapActions, mapGetters } from "vuex";
import TablaVisualizacionPagos from "@/components/visualizacionPagos/Tabla.vue";
import { Prenomina } from "@/typings/store/plugins/easyFirestore/prenominas";
import { Orden } from "@/typings/store/plugins/easyFirestore/ordenes";

const routeGuard = (vm: Vue): boolean => {
  const permisos = vm.getUsuario?.permisos;
  if (permisos && permisos["visualizacionPagos"]) {
    return true;
  }

  const rutas = vm.getRutasUsuario;
  if (rutas.length > 0) {
    const to = rutas[0].to;
    if (to) {
      if (vm.$route.name !== to.name) {
        vm.$router.replace(to);
      }
      return false;
    }
  }
  if (vm.$route.name !== "inicio") {
    vm.$router.replace({ name: "inicio" });
  }
  return false;
};

@Component({
  computed: mapGetters({
    getUsuario: "usuario/get",
    getRutasUsuario: "usuario/getRutas",
    getArrayPrenominas: "prenominas/getArray",
    getArrayOrdenes: "ordenes/getArray",
  }),
  methods: mapActions({}),
  beforeRouteEnter(to, from, next) {
    next(routeGuard);
  },
  components: {
    TablaVisualizacionPagos,
  },
})
export default class VisualizacionPagos extends Vue {
  created(): void {
    if (!routeGuard(this)) {
      return;
    }
  }

  orden: Orden | null = null;
  prenomina: Prenomina | null = null;

  filtroNombre = "";
  filtroEstado = "";
  filtroOrden = "";
  filtroSolicitante = "";
  filtroNomina = "";

  buscar = "";

  itemsFiltroEstado = [
    "PAGADO",
    "PARA PAGO",
    "PRENOMINA",
    "OC APROBADA - SIN FACTURA",
    "OC - CON FACTURA",
    "OC - PENDIENTE APROBACIÓN",
  ];

  // funcion que agrega los de getArrayPrenominas y getArrayOrdenes en un arreglo de objetos
  // eslint-disable-next-line
  get itemsPrenomina(): any[] {
    const prenomina = this.getArrayPrenominas;
    const orden = this.getArrayOrdenes;
    // eslint-disable-next-line
    const itemsPrenomina: any[] = [];
    // eslint-disable-next-line
    const itemsOrdenes: any[] = [];
    // eslint-disable-next-line
    const items: any[] = [];

    // recorre el arreglo de prenomina
    // filtra los datos de prenomina APROBADOS
    prenomina
      .filter((prenomina) => {
        const tipoGasto = prenomina.tipoGasto !== "SUELDOS";
        const ingresada = prenomina.ingresada === true;
        const aprobada = prenomina.estado === "aprobado";

        return tipoGasto && ingresada && aprobada;
      })
      .sort((a, b) => {
        return parseInt(b.ordenCompra) - parseInt(a.ordenCompra);
      })
      .forEach((prenomina) => {
        const item = {
          ESTADO: "PAGADO",
          OC: prenomina.ordenCompra,
          NOMBRE: prenomina.nombreProveedor,
          RUT: prenomina.rut,
          SOLICITANTE: "-",
          FACTURA: prenomina.nFactura,
          TOTAL: (
            parseInt(prenomina.montoAP, 10).toCurrency({ symbol: "$" }) ?? ""
          ).toString(),
          PAGADA: "SI",
          NOMINA: prenomina.numeroPrenominas,
          F_A_NOMINA: prenomina.pasoNomina,
          OBSERVACION: prenomina.observacion,
          COLOR: "success",
        };
        itemsPrenomina.push(item);
      });

    prenomina

      //ordenalas segun orden de compra de mayor a menor

      .filter((prenomina) => {
        const tipoGasto = prenomina.tipoGasto !== "SUELDOS";
        const ingresada = prenomina.ingresada !== true;
        const nominaCero = prenomina.numeroPrenominas === 0;
        const aprobada = prenomina.estado === "aprobado";
        return tipoGasto && ingresada && aprobada && nominaCero;
      })
      .sort((a, b) => {
        return parseInt(b.ordenCompra) - parseInt(a.ordenCompra);
      })
      .forEach((prenomina) => {
        const item = {
          ESTADO: "PARA PAGO",
          OC: prenomina.ordenCompra,
          NOMBRE: prenomina.nombreProveedor,
          RUT: prenomina.rut,
          SOLICITANTE: "-",
          FACTURA: prenomina.nFactura,
          TOTAL: (
            parseInt(prenomina.montoAP, 10).toCurrency({ symbol: "$" }) ?? ""
          ).toString(),
          PAGADA: "NO",
          NOMINA: "-",
          F_A_NOMINA: "-",
          OBSERVACION: prenomina.observacion,
          COLOR: "warning",
        };
        itemsPrenomina.push(item);
      });

    // recorre el arreglo de prenomina
    // filtra los datos de prenomina PENDIENTES
    prenomina
      .filter((prenomina) => {
        const tipoGasto = prenomina.tipoGasto !== "SUELDOS";
        const ingresada = prenomina.ingresada !== true;
        const aprobada = prenomina.estado === "pendiente";
        return tipoGasto && ingresada && aprobada;
      })
      .sort((a, b) => {
        return parseInt(b.ordenCompra) - parseInt(a.ordenCompra);
      })
      .forEach((prenomina) => {
        const item = {
          ESTADO: "PRENOMINA",
          OC: prenomina.ordenCompra,
          NOMBRE: prenomina.nombreProveedor,
          RUT: prenomina.rut,
          SOLICITANTE: "-",
          FACTURA: prenomina.nFactura,
          TOTAL: (
            parseInt(prenomina.montoAP, 10).toCurrency({ symbol: "$" }) ?? ""
          ).toString(),
          PAGADA: "NO",
          NOMINA: "-",
          F_A_NOMINA: "-",
          OBSERVACION: prenomina.observacion,
          COLOR: "blue",
        };
        itemsPrenomina.push(item);
      });

    // recorre el arreglo de ordenes
    // filtra los datos de ordenes APROBADAS - SIN FACTURA
    orden
      .sort((a, b) => {
        return b.ordenCompra - a.ordenCompra;
      })

      .filter((orden) => {
        const tipoGasto = orden.tipoGasto !== "SUELDOS";
        const aprobada = orden.estado === "aprobado";
        const tipoArchivo = orden.tipoArchivo !== "BOLETA/FACTURA";
        const tipoArchivo2 = orden.tipoArchivo2 !== "BOLETA/FACTURA";
        const tipoArchivo3 = orden.tipoArchivo3 !== "BOLETA/FACTURA";
        const tipoArchivo4 = orden.tipoArchivo4 !== "BOLETA/FACTURA";
        const tipoArchivo5 = orden.tipoArchivo5 !== "BOLETA/FACTURA";
        const tipoArchivo6 = orden.tipoArchivo6 !== "BOLETA/FACTURA";
        return (
          tipoGasto &&
          aprobada &&
          tipoArchivo &&
          tipoArchivo2 &&
          tipoArchivo3 &&
          tipoArchivo4 &&
          tipoArchivo5 &&
          tipoArchivo6
        );
      })
      .forEach((orden) => {
        const item = {
          ESTADO: "OC APROBADA - SIN FACTURA",
          OC: orden.ordenCompra,
          NOMBRE: orden.empresa,
          RUT: orden.rut,
          SOLICITANTE: orden.solicitante,
          FACTURA: "NO INGRESADA",
          TOTAL: (orden.total.toCurrency({ symbol: "$" }) ?? "").toString(),
          PAGADA: "NO",
          NOMINA: "-",
          F_A_NOMINA: "-",
          OBSERVACION: orden.observaciones,
          COLOR: "red",

          //ordenes: ordenes.filter((o) => o.ordenCompra.toString() === p.ordenCompra),
        };
        itemsOrdenes.push(item);
      });
    // filtra los datos de las ordenes PENDIENTES - CON FACTURA
    orden
      .sort((a, b) => {
        return b.ordenCompra - a.ordenCompra;
      })

      .filter((orden) => {
        const tipoGasto = orden.tipoGasto !== "SUELDOS";
        const aprobada = orden.estado === "pendiente";
        const tipoArchivo = orden.tipoArchivo === "BOLETA/FACTURA";
        const tipoArchivo2 = orden.tipoArchivo2 === "BOLETA/FACTURA";
        const tipoArchivo3 = orden.tipoArchivo3 === "BOLETA/FACTURA";
        const tipoArchivo4 = orden.tipoArchivo4 === "BOLETA/FACTURA";
        const tipoArchivo5 = orden.tipoArchivo5 === "BOLETA/FACTURA";
        const tipoArchivo6 = orden.tipoArchivo6 === "BOLETA/FACTURA";
        return (
          tipoGasto &&
          aprobada &&
          (tipoArchivo ||
            tipoArchivo2 ||
            tipoArchivo3 ||
            tipoArchivo4 ||
            tipoArchivo5 ||
            tipoArchivo6)
        );
      })
      .forEach((orden) => {
        const item = {
          ESTADO: "OC - CON FACTURA",
          OC: orden.ordenCompra,
          NOMBRE: orden.empresa,
          RUT: orden.rut,
          SOLICITANTE: orden.solicitante,
          FACTURA: "NO INGRESADA",
          TOTAL: (orden.total.toCurrency({ symbol: "$" }) ?? "").toString(),
          PAGADA: "NO",
          NOMINA: "-",
          F_A_NOMINA: "-",
          OBSERVACION: orden.observaciones,
          COLOR: "orange",

          //ordenes: ordenes.filter((o) => o.ordenCompra.toString() === p.ordenCompra),
        };
        itemsOrdenes.push(item);
      });

    // filtra los datos de las ordenes PENDIENTES - SIN FACTURA
    orden
      .sort((a, b) => {
        return b.ordenCompra - a.ordenCompra;
      })

      .filter((orden) => {
        const tipoGasto = orden.tipoGasto !== "SUELDOS";
        const aprobada = orden.estado === "pendiente";
        const tipoArchivo = orden.tipoArchivo !== "BOLETA/FACTURA";
        const tipoArchivo2 = orden.tipoArchivo2 !== "BOLETA/FACTURA";
        const tipoArchivo3 = orden.tipoArchivo3 !== "BOLETA/FACTURA";
        const tipoArchivo4 = orden.tipoArchivo4 !== "BOLETA/FACTURA";
        const tipoArchivo5 = orden.tipoArchivo5 !== "BOLETA/FACTURA";
        const tipoArchivo6 = orden.tipoArchivo6 !== "BOLETA/FACTURA";
        return (
          tipoGasto &&
          aprobada &&
          tipoArchivo &&
          tipoArchivo2 &&
          tipoArchivo3 &&
          tipoArchivo4 &&
          tipoArchivo5 &&
          tipoArchivo6
        );
      })
      .forEach((orden) => {
        const item = {
          ESTADO: "OC - PENDIENTE APROBACIÓN",
          OC: orden.ordenCompra,
          NOMBRE: orden.empresa,
          RUT: orden.rut,
          SOLICITANTE: orden.solicitante,
          FACTURA: "NO INGRESADA",
          TOTAL: (orden.total.toCurrency({ symbol: "$" }) ?? "").toString(),
          PAGADA: "NO",
          NOMINA: "-",
          F_A_NOMINA: "-",
          OBSERVACION: orden.observaciones,
          COLOR: "brown",

          //ordenes: ordenes.filter((o) => o.ordenCompra.toString() === p.ordenCompra),
        };
        itemsOrdenes.push(item);
      });

    // juntar itemsPrenomina y itemsOrdenes en items
    itemsPrenomina.forEach((item) => {
      items.push(item);
    });
    itemsOrdenes.forEach((item) => {
      items.push(item);
    });

    return items.filter((item) => {
      if (this.buscar) {
        return (
          item.OBSERVACION.toLowerCase().indexOf(this.buscar.toLowerCase()) !==
          -1
        );
      }
      if (this.filtroNombre) {
        return (
          item.NOMBRE.toUpperCase().indexOf(this.filtroNombre.toUpperCase()) !==
          -1
        );
      }
      if (this.filtroEstado) {
        return (
          item.ESTADO.toString()
            .toUpperCase()
            .indexOf(this.filtroEstado.toUpperCase()) !== -1
        );
      }
      if (this.filtroOrden) {
        return (
          item.OC.toString()
            .toUpperCase()
            .indexOf(this.filtroOrden.toUpperCase()) !== -1
        );
      }
      if (this.filtroSolicitante) {
        return (
          item.SOLICITANTE.toString()
            .toUpperCase()
            .indexOf(this.filtroSolicitante.toUpperCase()) !== -1
        );
      }

      if (this.filtroNomina) {
        return (
          item.NOMINA.toString()
            .toUpperCase()
            .indexOf(this.filtroNomina.toUpperCase()) !== -1
        );
      }

      return true;
    });
  }

  // funcion que lleva los datos del campo NOMBRE de itemsPrenomina en un arreglo
  get itemsFiltroNombres(): Array<string> {
    const nombre = "";
    const nombres = [nombre];

    this.itemsPrenomina.forEach((item) => {
      nombres.push(item.NOMBRE);
    });
    //return nombres.sort();
    //ELIMINA NOBRES REPETIDOS Y VACIOS
    return nombres.filter((v, i, a) => a.indexOf(v) === i && v !== "").sort();
  }

  get itemsFiltroOrden(): Array<string> {
    const orden = "";
    const ordenes = [orden];

    this.itemsPrenomina.forEach((item) => {
      ordenes.push(item.OC);
    });

    return ordenes
      .filter((v, i, a) => a.indexOf(v) === i && v !== "")

      .sort((a, b) => {
        return parseInt(b) - parseInt(a);
      });
  }

  get itemsFiltroNomina(): Array<string> {
    const nomina = "";
    const nominas = [nomina];

    this.getArrayPrenominas.forEach((item) => {
      nominas.push(item.numeroPrenominas.toString());
    });

    return nominas
      .filter((v, i, a) => a.indexOf(v) === i && v !== "")

      .sort((a, b) => {
        return parseInt(b) - parseInt(a);
      });
  }

  get itemsFiltroSolicitante(): Array<string> {
    const solicitante = "";
    const solicitudes = [solicitante];

    this.itemsPrenomina.forEach((item) => {
      solicitudes.push(item.SOLICITANTE);
    });

    return solicitudes
      .filter((v, i, a) => a.indexOf(v) === i && v !== "")

      .sort();
  }
}
