import Vue from "vue";
import Vuex, {GetterTree, MutationTree} from "vuex";
import { State } from "@/typings/store";

Vue.use(Vuex);

import plugins from "./plugins";
import modules from "./modules";

import iniciarFirebase from "./plugins/easyFirestore/iniciar";

const getters: GetterTree<State, State> = {
  getErrorLogin(state) {
    return state.errorLogin;
  },
};

const mutations: MutationTree<State> = {
  setErrorLogin(state, data: string) {
    state.errorLogin = data;
  },
};

const state: State = {
  dev: "",
  errorLogin: ""
};

const storeData = {
  state,
  modules,
  plugins,
  getters,
  mutations,
};

const store = new Vuex.Store(storeData);

// initFirebase
iniciarFirebase(store);
export default store;
