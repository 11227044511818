




























































































































import { ChartData } from "@/typings/vista/monitoreo";
import { Component, Vue, Prop } from "vue-property-decorator";
import { GChart } from "vue-google-charts";
import { Curso } from "@/typings/store/plugins/easyFirestore/cursos";
import { ItemsTablaCursos } from "@/typings/components/cursos/tabla";
import { Pago } from "@/typings/store/plugins/easyFirestore/pagos";

@Component({
  components: { GChart },
})
export default class ComponenteIndicadorCursosRelator extends Vue {
  @Prop() readonly cursos!: Array<Curso>;
  @Prop() readonly cursoItems!: ItemsTablaCursos;
  @Prop() readonly filtroPagoFecha!: Array<Pago>;

  items = ["Relatores", "Categorias", "Ventas"];
  select = "";
  dialog = false;
  chartOptions = {
    legend: "none",
    chart: {
      title: "Company Performance",
      subtitle: "Ventas, Expenses, and Profit: 2014-2017",

      //color: "#1E88E5",
      // series: {
      //   0: { color: "blue", visibleInLegend: true },
      //   1: { color: "lightgreen", visibleInLegend: true },
      // },
    },
  };

  get cursoPorRelator(): ChartData {
    let relatores: {
      [id: string]: { relator: string; cantidad: number; color: string };
    } = {};

    let cantidad = 1;

    this.cursoItems.forEach((item) => {
      if (relatores[item.relator]) {
        relatores[item.relator].cantidad += cantidad;
      } else {
        relatores = Object.assign({}, relatores, {
          [item.relator]: {
            relator: item.relator,
            cantidad: cantidad,
            color: "#3d3569",
          },
        });
      }
    });

    const data: ChartData = Object.values(relatores).map((item) => {
      return [item.relator, item.cantidad, item.color];
    });

    data.unshift(["Relator", "Cursos", { role: "style" }]);
    return data;
  }

  get cursoPorCategoria(): ChartData {
    let categorias: {
      [id: string]: { categoria: string; cantidad: number; color: string };
    } = {};

    let cantidad = 1;

    this.cursoItems.forEach((item) => {
      if (categorias[item.categoria]) {
        categorias[item.categoria].cantidad += cantidad;
      } else {
        categorias = Object.assign({}, categorias, {
          [item.categoria]: {
            categoria: item.categoria,
            cantidad: cantidad,
            color: "#3d3569",
          },
        });
      }
    });

    const data: ChartData = Object.values(categorias).map((item) => {
      return [item.categoria, item.cantidad, item.color];
    });

    data.unshift(["Relator", "Cursos", { role: "style" }]);
    return data;
  }

  get cursoPorVenta(): ChartData {
    let acumulaCursos: {
      [id: string]: { nombre: string; cantidad: number; color: string };
    } = {};
    let cantidad = 1;
    for (const pago of this.filtroPagoFecha) {
      if (pago.resultado?.status == "AUTHORIZED") {
        //suma += pago.resultado.amount;
        for (const curso of Object.values(pago.cursos ?? {})) {
          if (acumulaCursos[curso.nombre]) {
            acumulaCursos[curso.nombre].cantidad += cantidad;
          } else {
            acumulaCursos = Object.assign({}, acumulaCursos, {
              [curso.nombre]: {
                nombre: curso.nombre,
                cantidad: cantidad,
                color: "#3d3569",
              },
            });
          }
        }
      }
    }

    const data: ChartData = Object.values(acumulaCursos).map((curso) => {
      return [curso.nombre, curso.cantidad, curso.color];
    });
    //console.log(data);
    data.unshift(["Curso", "Cantidad", { role: "style" }]);
    //console.log(Object.values(acumulaCursos));
    return data;
  }
}
