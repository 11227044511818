












































































































































































// decoradores
import {Component, Emit, Prop, Vue} from "vue-property-decorator";
// tipos
import {HeadersDataTable} from "@/typings/vuetify/vDataTable";
import { TipoDocumento } from "@/typings/store/plugins/easyFirestore/tipoDocumentos";
import {ItemsTablaTipoDocumentos} from "@/typings/components/tipoDocumentos/tabla";
import "@/helpers/extensions";

@Component
export default class TablaUsuarios extends Vue {
  @Prop() readonly opciones!: boolean;
  @Prop() readonly search!: string;
  @Prop() readonly tipoDocumentos!: Array<TipoDocumento>;

  options = {
    itemsPerPage: -1,
  };
  headerProps = {
    sortIcon: "mdi-chevron-down"
  }

  get headers(): HeadersDataTable {
    return [
      //{text: "", value: "estado", sortable: false},
      {text: "Nombre", value: "nombre"},
      {text: "Posicion", value: "posicion"},
      {text: "", value: "opciones", sortable: false}
    ];
  }

  get items(): ItemsTablaTipoDocumentos {
    return this.tipoDocumentos.map((banco) => {
      const nombre = banco.nombre;
      const posicion = banco.posicion;
    
      return {
        nombre,
        posicion,
       
        data: banco,
      };
    });
  }

//   actualizarEstado(item: Usuario): void {
//     let estado: EstadoUsuario;
//     item.estado === 'libre' ? estado = 'bloqueado' : estado = 'libre';
//     item.estado = estado;
//     this.onCambiarEstado(item);
//   }

//   @Emit("cambiar-estado")
//   onCambiarEstado(usuario: Usuario): Usuario {
//     return usuario;
//   }

  @Emit("abrir-info")
  onAbrirInfo(banco: TipoDocumento): TipoDocumento {
    return banco;
  }

  @Emit("abrir-editar")
  onAbrirEditar(banco: TipoDocumento): TipoDocumento {
    return banco;
  }

  @Emit("abrir-eliminar")
  onAbrirEliminar(banco: TipoDocumento): TipoDocumento {
    return banco;
  }
}
