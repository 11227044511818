








































































































































































































































































































































































































































// Decoradores
import { Vue, Component, Emit } from "vue-property-decorator";
// Vuex
import { mapActions, mapGetters } from "vuex";
// Tipos
import { Pago, Pagos } from "@/typings/store/plugins/easyFirestore/pagos";
import { DataFormularioPago } from "@/typings/components/pagos/formulario";
import { Curso } from "@/typings/store/plugins/easyFirestore/cursos";
import { Sesion } from "@/typings/store/plugins/easyFirestore/sesiones";
import {
  Usuario,
  TipoUsuario,
} from "@/typings/store/plugins/easyFirestore/usuarios";
import { Promocion } from "@/typings/store/plugins/easyFirestore/promociones";
import { Region } from "@/typings/store/plugins/easyFirestore/regiones";

// Componentes
import BannerPago from "@/components/pagos/Banner.vue";
import TablaPagos from "@/components/pagos/Tabla.vue";
import FormularioPago from "@/components/pagos/Formulario.vue";
import InfoPago from "@/components/pagos/Info.vue";
import BotonesCrud from "@/components/BotonesCrud.vue";
import MensajeEliminar from "@/components/MensajeEliminar.vue";
import TituloVista from "@/components/TituloVista.vue";
// Helpers
import { fechaLocal } from "@/helpers/date";
import "@/helpers/extensions";
// PDF
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { TDocumentDefinitions } from "pdfmake/interfaces";

// Excel
import Exceljs from "exceljs";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const routeGuard = (vm: Vue): boolean => {
  const permisos = vm.getUsuario?.permisos;
  const rutas = vm.getRutasUsuario;

  if (permisos && permisos["pagos"]) {
    return true;
  }

  if (rutas.length > 0) {
    const to = rutas[0].to;
    if (to) {
      if (vm.$route.name == to.name) {
        if (vm.$route.name !== to.name) {
          vm.$router.replace(to);
        }
        return false;
      }
      return false;
    }
  }
  if (vm.$route.name !== "inicio") {
    vm.$router.replace({ name: "inicio" });
  }
  return false;
};

@Component({
  computed: mapGetters({
    getPagos: "pagos/get",
    getArrayPagos: "pagos/getArray",
    getArrayInscripciones: "inscripciones/getArray",
    getArrayCursos: "cursos/getArray",
    getArraySesiones: "sesiones/getArray",
    getArrayUsuarios: "usuarios/getArray",
    getArrayPromociones: "promociones/getArray",
    getArrayRegiones: "regiones/getArray",
    getUsuario: "usuario/get",
    getRutasUsuario: "usuario/getRutas",
  }),
  methods: mapActions({
    setPago: "pagos/set",
    deletePago: "pagos/delete",
  }),
  beforeRouteEnter(to, from, next) {
    next(routeGuard);
  },
  components: {
    TablaPagos,
    FormularioPago,
    InfoPago,
    BotonesCrud,
    MensajeEliminar,
    TituloVista,
    BannerPago,
  },
})
export default class VistaPagos extends Vue {
  created(): void {
    if (!routeGuard(this)) {
      return;
    }
  }

  dialogoInfo = false;
  dialogoFormulario = false;
  dialogoEliminar = false;
  menu = false;

  pago: Pago | null = null;
  fechas: Array<string> = [];
  metodoPago: { nombre: string; tipo: Array<string> } | null = null;
  relator: Usuario | null = null;
  region = "";
  curso: Curso | null = null;
  today = "";
  deshabilitado = true;
  selectedItem = "0";
  buscar = "";
  estadoCurso = "";

  metodosdePago = [
    { nombre: "Crédito ", tipo: ["VN", "S2", "SI", "NC", "VC"] },
    { nombre: "Débito", tipo: ["VD"] },
    { nombre: "Prepago", tipo: ["VP"] },
  ];
  estadosCursos = [{ nombre: "aceptado" }, { nombre: "terminado" }];
  tiposPagos = {
    VN: "Crédito",
    S2: "Crédito",
    SI: "Crédito",
    NC: "Crédito",
    VC: "Crédito",
    VD: "Debito",
    VP: "Prepago",
  };

  get fechasMostrar(): string {
    return this.fechas
      .map((fecha) => fecha.toDate().format("DD-MM-YYYY"))
      .join(" ~ ");
  }

  set fechasMostrar(array: string) {
    this.fechas = [];
  }

  //funciones

  get tipoUsuario(): TipoUsuario {
    if (!this.getUsuario) {
      return "sudo";
    }
    return this.getUsuario.tipo;
  }

  get pagos(): Pagos {
    return this.getPagos;
  }

  get pagosFiltrados(): Array<Pago> {
    const fechaDesde = this.fechas.firstOrNull?.toDate();
    fechaDesde?.setHours(0, 0, 0, 0);
    const fechaHasta = this.fechas.lastOrNull?.toDate();
    fechaHasta?.setHours(23, 59, 59);

    return this.getArrayInscripciones
      .sort(
        (inscripcion1, inscripcion2) =>
          inscripcion1.pago.resultado?.transaction_date.toDate().getTime() ??
          0 -
            (inscripcion2.pago.resultado?.transaction_date.toDate().getTime() ??
              0)
      )
      .filter((inscripcion) => {
        if (this.tipoUsuario == "admin") {
          const filtroCurso = this.curso
            ? Object.values(inscripcion.pago.cursos).some(
                (curso) => curso.id === this.curso?.id
              )
            : true;
          const filtroFecha =
            fechaDesde && fechaHasta
              ? (inscripcion.pago.resultado?.transaction_date
                  .toDate()
                  .getTime() ??
                  0 >= fechaDesde.getTime()) &&
                (inscripcion.pago.resultado?.transaction_date
                  .toDate()
                  .getTime() ??
                  0 <= fechaHasta.getTime())
              : true;
          const filtroMetodoPago = this.metodoPago
            ? this.metodoPago.tipo.some(
                (tipo) => tipo == inscripcion.pago.resultado?.payment_type_code
              )
            : true;
          const filtroRegion = this.region
            ? this.getArrayUsuarios.find(
                (usuario) => usuario.id == inscripcion.cliente.id
              )?.ciudad?.region.nombre === this.region
            : true;
          const filtroRelator = this.relator
            ? Object.values(inscripcion.pago.cursos).some(
                (curso) => curso.relator.id === this.relator?.id
              )
            : true;
          return (
            inscripcion &&
            filtroCurso &&
            filtroFecha &&
            filtroMetodoPago &&
            filtroRegion &&
            filtroRelator
          );
        }

        if (this.tipoUsuario == "relator") {
          const filtroRelator = Object.values(inscripcion.pago.cursos).some(
            (curso) => curso.relator.id == this.getUsuario?.id
          );
          const filtroCurso = Object.values(inscripcion.pago.cursos).some(
            (curso) => curso.estado == "aceptado" || curso.estado == "terminado"
          );
          const filtroEstadoCurso = this.estadoCurso
            ? Object.values(inscripcion.pago.cursos).some(
                (curso) => curso.estado == this.estadoCurso
              )
            : true;
          const filtroFecha =
            fechaHasta && fechaDesde
              ? Object.values(inscripcion.pago.cursos).some(
                  (curso) =>
                    curso.inicio.toDate().getTime() >= fechaDesde.getTime() &&
                    curso.inicio.toDate().getTime() <= fechaHasta.getTime()
                )
              : true;
          return (
            inscripcion &&
            filtroRelator &&
            filtroCurso &&
            filtroEstadoCurso &&
            filtroFecha
          );
        }

        if (this.tipoUsuario == "cliente") {
          const filtroCliente = inscripcion.cliente.id == this.getUsuario?.id;
          return inscripcion && filtroCliente;
        }
      })
      .map((inscripcion) => inscripcion.pago);
  }

  get clientes(): Array<Usuario> {
    return this.getArrayUsuarios.filter((usuario) => {
      if (usuario.tipo == "cliente") {
        return usuario;
      }
    });
  }

  get relatores(): Array<Usuario> {
    return this.getArrayUsuarios.filter((usuario) => {
      if (usuario.tipo == "relator") {
        return usuario;
      }
    });
  }

  get regiones(): Array<Region> {
    return this.getArrayRegiones.sort(
      (regionA, regionB) => regionA.posicion - regionB.posicion
    );
  }

  get cursos(): Array<Curso> {
    const fechaDesde = this.fechas.firstOrNull?.toDate();
    fechaDesde?.setHours(0, 0, 0, 0);
    const fechaHasta = this.fechas.lastOrNull?.toDate();
    fechaHasta?.setHours(23, 59, 59);
    return this.getArrayCursos.filter((curso) => {
      if (
        curso.estado == "aceptado" ||
        (curso.estado == "terminado" && this.getUsuario?.id == curso.relator.id)
      ) {
        const filtroCurso = this.estadoCurso
          ? curso.estado === this.estadoCurso
          : true;
        const filtroFecha =
          fechaDesde && fechaHasta
            ? curso.inicio.toDate().getTime() >= fechaDesde.getTime() &&
              curso.inicio.toDate().getTime() <= fechaHasta.getTime()
            : true;
        return curso && filtroCurso && filtroFecha;
      }
    });
  }

  get sesiones(): Array<Sesion> {
    return this.getArraySesiones.filter((sesion) => {
      if (sesion.curso.estado == "aceptado") {
        return sesion;
      }
    });
  }

  get promociones(): Array<Promocion> {
    return this.getArrayPromociones;
  }

  get itemsExcel(): Array<Array<string>> {
    if (this.tipoUsuario == "relator") {
      let CursosRecorridos: Array<string> = [];
      return this.cursos.map((curso) => {
        const nombre = curso.nombre;
        const inicio = curso.inicio.toDate().format("DD-MM-YYYY");
        const fin = curso.inicio.toDate().format("DD-MM-YYYY");
        const asistentes = this.pagosFiltrados
          .reduce((total, pago) => {
            if (
              Object.values(pago.cursos).some((course) => course.id == curso.id)
            ) {
              return total + 1;
            }
            return total;
          }, 0)
          .toString();
        const ganancias = this.pagosFiltrados
          .reduce((total, pago) => {
            if (
              Object.values(pago.cursos).some((course) => course.id == curso.id)
            ) {
              if (curso.comision.tipo == "Por participante") {
                if (curso.comision.valor.includes("%")) {
                  return (
                    total +
                    Math.round(
                      curso.precio *
                        (parseFloat(curso.comision.valor.replace("%", "")) /
                          100)
                    )
                  );
                } else {
                  return total + parseFloat(curso.comision.valor);
                }
              } else {
                if (
                  CursosRecorridos.isEmpty ||
                  (!CursosRecorridos.includes(curso.id ?? "") &&
                    !curso.comision.valor.includes("$"))
                ) {
                  CursosRecorridos.push(curso.id ?? "");
                  return total + parseFloat(curso.comision.valor);
                }
              }
            }
            return total;
          }, 0)
          .toCurrency({ symbol: "$" });
        return Object.values({
          nombre,
          inicio,
          fin,
          asistentes,
          ganancias,
        });
      });
    }
    return this.pagosFiltrados.map((pago) => {
      const fecha =
        pago.resultado?.transaction_date?.toDate()?.format("DD-MM-YYYY") ??
        new Date().format("DD-MM-YYYY");
      const hora =
        pago.resultado?.transaction_date?.toDate()?.format("HH:mm:ss") ??
        new Date().format("HH:mm:ss");
      const cursos = Object.values(pago.cursos)
        .map((pago) => pago.nombre)
        .join(" | ");
      const relator = Object.values(pago.cursos)
        .map((pago) => pago.relator.nombre)
        .join(" | ");
      const descuento = pago.promocion?.descuento ?? "0%";
      const metodoPago = pago.resultado
        ? this.tiposPagos[pago.resultado.payment_type_code]
        : "";
      const valor = Object.values(pago.cursos)
        .map((pago) => pago.precio.toCurrency({ symbol: "$" }))
        .join(" | ");
      const monto = Object.values(pago.cursos)
        .reduce((sum, pago) => sum + pago.precio, 0)
        .toCurrency({ symbol: "$" });
      return Object.values({
        fecha,
        hora,
        cursos,
        relator,
        descuento,
        metodoPago,
        valor,
        monto,
      });
    });
  }

  get itemsPdf(): Array<Array<string>> {
    if (this.tipoUsuario == "relator") {
      let CursosRecorridos: Array<string> = [];
      return this.cursos.map((curso) => {
        const nombre = curso.nombre;
        const inicio = curso.inicio.toDate().format("DD-MM-YYYY");
        const fin = curso.inicio.toDate().format("DD-MM-YYYY");
        const asistentes = this.pagosFiltrados
          .reduce((total, pago) => {
            if (
              Object.values(pago.cursos).some((course) => course.id == curso.id)
            ) {
              return total + 1;
            }
            return total;
          }, 0)
          .toString();
        const ganancias = this.pagosFiltrados
          .reduce((total, pago) => {
            if (
              Object.values(pago.cursos).some((course) => course.id == curso.id)
            ) {
              if (curso.comision.tipo == "Por participante") {
                if (curso.comision.valor.includes("%")) {
                  return (
                    total +
                    Math.round(
                      curso.precio *
                        (parseFloat(curso.comision.valor.replace("%", "")) /
                          100)
                    )
                  );
                } else {
                  return total + parseFloat(curso.comision.valor);
                }
              } else {
                if (
                  CursosRecorridos.isEmpty ||
                  (!CursosRecorridos.includes(curso.id ?? "") &&
                    !curso.comision.valor.includes("$"))
                ) {
                  CursosRecorridos.push(curso.id ?? "");
                  return total + parseFloat(curso.comision.valor);
                }
              }
            }
            return total;
          }, 0)
          .toCurrency({ symbol: "$" });
        return Object.values({
          nombre,
          inicio,
          fin,
          asistentes,
          ganancias,
        });
      });
    }
    return this.pagosFiltrados.map((pago) => {
      const fecha =
        pago.resultado?.transaction_date?.toDate()?.format("DD-MM-YYYY") ??
        new Date().format("DD-MM-YYYY");
      const hora =
        pago.resultado?.transaction_date?.toDate()?.format("HH:mm:ss") ??
        new Date().format("HH:mm:ss");
      const cursos = Object.values(pago.cursos)
        .map((pago) => pago.nombre)
        .join(" | ");
      const relator = Object.values(pago.cursos)
        .map((pago) => pago.relator.nombre)
        .join(" | ");
      const descuento = pago.promocion?.descuento ?? "0%";
      const metodoPago = pago.resultado
        ? this.tiposPagos[pago.resultado.payment_type_code]
        : "";
      const valor = Object.values(pago.cursos)
        .map((pago) => pago.precio.toCurrency({ symbol: "$" }))
        .join(" | ");
      const monto = Object.values(pago.cursos)
        .reduce((sum, pago) => sum + pago.precio, 0)
        .toCurrency({ symbol: "$" });

      return Object.values({
        fecha,
        hora,
        cursos,
        relator,
        descuento,
        metodoPago,
        valor,
        monto,
      });
    });
  }

  fechasPermitidas(fecha: string): boolean {
    if (this.fechas[0]) {
      return this.fechas[1]
        ? true
        : new Date(fecha) >= new Date(this.fechas[0]);
    }

    return true;
  }

  clickItem(val: { id: string } | null): void {
    this.pago = val ? this.pagos[val.id] : null;
  }

  clickPlus(): void {
    this.pago = null;
    this.dialogoFormulario = true;
  }

  async clickGuardar(data: DataFormularioPago): Promise<void> {
    const doc = data.doc;
    this.setPago(doc);
    if (doc.id == this.pago?.id) {
      this.pago = doc;
    }
    this.cerrarDialogo("formulario");
  }

  clickEliminar(): void {
    const pago = this.pago;
    if (!pago?.id) {
      // console.log("error id: ", pago);
      return;
    }
    this.deletePago(pago.id);
    this.pago = null;
    this.cerrarDialogo("eliminar");
  }

  abrirDialogoInfo(pago: Pago): void {
    if (!pago.id) {
      // console.log("error id: ", pago);
      return;
    }
    const doc = this.pagos[pago.id];
    if (doc) {
      this.pago = doc;
      this.dialogoInfo = true;
    }
  }

  abrirDialogoFormulario(pago: Pago): void {
    if (!pago.id) {
      // console.log("error id: ", pago);
      return;
    }
    const doc = this.pagos[pago.id];
    if (doc) {
      this.pago = doc;
      this.dialogoFormulario = true;
    }
  }

  abrirDialogoEliminar(pago: Pago): void {
    if (!pago.id) {
      // console.log("error id: ", pago);
      return;
    }
    const doc = this.pagos[pago.id];
    if (doc) {
      this.dialogoEliminar = true;
      this.pago = doc;
    }
  }

  cerrarDialogo(tipoFormulario: string): void {
    if (tipoFormulario === "info") {
      this.dialogoInfo = false;
    }
    if (tipoFormulario === "formulario") {
      this.dialogoFormulario = false;
    }
    if (tipoFormulario === "eliminar") {
      this.dialogoEliminar = false;
    }
    if (tipoFormulario === "cancelar") {
      this.dialogoEliminar = false;
    }
    this.pago = null;
  }

  async createPDF(): Promise<void> {
    const link = { url: require("@/assets/logo_salhuspdf.png") };
    const result = await fetch(link.url);
    const result2 = await result.blob();
    const base64: string = await new Promise((callback) => {
      let reader = new FileReader();
      reader.onload = function() {
        callback(this.result?.toString() ?? "");
      };
      reader.readAsDataURL(result2);
    });

    if (this.tipoUsuario == "relator" || this.tipoUsuario == "admin") {
      const dd2: TDocumentDefinitions = {
        pageOrientation: "landscape",
        pageMargins: [20, 60, 20, 60],
        header: {
          margin: [50, 20, 50, 0],

          columns: [
            {
              text: "Reporte de Pagos",
              alignment: "center",
              fontSize: 10,
              bold: true,
            },
            {
              image: "logo",
              alignment: "right",
              height: 54,
            },
          ],
        },
        content: [
          {
            margin: 80,
            table: {
              widths: [
                "9.8%",
                "9.8%",
                "10.8%",
                "9.8%",
                "9.8%",
                "9.8%",
                "9.8%",
                "9.8%",
              ],

              body: [
                [
                  "Fecha",
                  "Hora",
                  "Curso",
                  "Relator",
                  "Descuento",
                  "Medio de Pago",
                  "Valor ",
                  "Monto",
                ],
                ...this.itemsPdf,
              ],
            },
          },
        ],
        images: {
          logo: base64,
        },
        defaultStyle: {
          fontSize: 10,
          alignment: "center",
        },
      };

      pdfMake.createPdf(dd2).download(`Reporte ${fechaLocal()}`);
    }
    // else {
    //   const dd1: TDocumentDefinitions = {
    //     pageOrientation: "landscape",
    //     pageMargins: [20, 60, 20, 60],
    //     header: {
    //       margin: [50, 20, 50, 0],

    //       columns: [
    //         {
    //           text: "Reporte de Pagos",
    //           alignment: "center",
    //           fontSize: 10,
    //           bold: true,
    //         },
    //         {
    //           image: "logo",
    //           alignment: "right",
    //           height: 54,
    //         },
    //       ],
    //     },
    //     content: [
    //       {
    //         margin: 80,
    //         table: {
    //           widths: [
    //             "9.8%",
    //             "9.8%",
    //             "10.8%",
    //             "9.8%",
    //             "9.8%",
    //             "9.8%",
    //             "9.8%",
    //             "9.8%",
    //           ],

    //           body: [
    //             ["Curso", "Inicio", "Fin", "Asistentes", "Ganancias"],
    //             ...this.itemsPdf,
    //           ],
    //         },
    //       },
    //     ],
    //     images: {
    //       logo: base64,
    //     },
    //     defaultStyle: {
    //       fontSize: 10,
    //       alignment: "center",
    //     },
    //   };
    //   pdfMake.createPdf(dd1).download("Reporte " + fechaLocal());
    // }
  }

  async createExcel(): Promise<void> {
    const workbook = new Exceljs.Workbook();
    const sheet = workbook.addWorksheet("Pagos", {
      properties: { defaultColWidth: 25 },
    });
    if (this.tipoUsuario == "relator") {
      const rows1 = [
        ["Nombre", "Inicio", "Fin", "Asistentes", "Ganancias"],
        ...this.itemsExcel,
        [],
      ];
      sheet.addRows(rows1);
    } else {
      const rows2 = [
        [
          "Fecha",
          "Hora",
          "Curso",
          "Relator",
          "Descuento",
          "Medio de Pago",
          "Valor ",
          "Monto",
        ],
        ...this.itemsExcel,
        [],
      ];
      sheet.addRows(rows2);
    }

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: "application/xlsx" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "Reporte" + fechaLocal() + ".xlsx";
    link.click();
  }

  async crearPDF(): Promise<void> {
    const link = { url: require("@/assets/logo_salhus_white2.png") };
    const result = await fetch(link.url);
    const result2 = await result.blob();
    const fechaFormateada = new Date();
    const base64: string = await new Promise((callback) => {
      let reader = new FileReader();
      reader.onload = function() {
        callback(this.result?.toString() ?? "");
      };
      reader.readAsDataURL(result2);
    });
    // console.log(this.tipoUsuario);
    if (this.tipoUsuario == "relator") {
      const dd2: TDocumentDefinitions = {
        pageOrientation: "landscape",
        // pageMargins: [20, 60, 20, 60],
        header: {
          margin: [50, 30, 50, 0],

          columns: [
            {
              text: "Reporte de Pagos",
              alignment: "center",
              fontSize: 10,
              bold: true,
            },
            {
              image: "logo",
              alignment: "right",
              height: 50,
              width: 100,
            },
          ],
        },
        content: [
          { text: "Reporte de Pagos", style: "header" },
          fechaFormateada.format("DD de MMMM del YYYY"),

          {
            margin: 20,
            table: {
              widths: ["*", "*", "*", "*", "*"],
              // widths: [
              //   "9.8%",
              //   "9.8%",
              //   "10.8%",
              //   "9.8%",
              //   "9.8%",
              //   "9.8%",
              //   "9.8%",
              //   "9.8%",
              // ],

              body: [
                [
                  //     "Fecha",
                  //     "Hora",
                  { text: "Curso", style: "tableHeader" },
                  { text: "Inicio", style: "tableHeader" },
                  { text: "Termino", style: "tableHeader" },
                  { text: "Asistentes", style: "tableHeader" },
                  { text: "Ganancias", style: "tableHeader" },
                  //     "Relator",
                  //     "Descuento",
                  //     "Medio de Pago",
                  //     "Valor ",
                  //     "Monto",
                ],
                ...this.itemsPdf,
              ],
            },
          },
        ],
        images: {
          logo: base64,
        },
        styles: {
          header: {
            color: "#0d0a39",
            fontSize: 15,
            bold: true,
            margin: [0, 20, 0, 10],
          },
          tableHeader: {
            bold: true,
            fontSize: 10,
            color: "black",
          },
        },
        defaultStyle: {
          fontSize: 10,
          alignment: "center",
        },
      };

      pdfMake.createPdf(dd2).download(`Reporte ${fechaLocal()}`);
    }
    if (this.tipoUsuario == "admin") {
      const dd2: TDocumentDefinitions = {
        pageOrientation: "landscape",
        // pageMargins: [20, 60, 20, 60],
        header: {
          margin: [50, 30, 50, 0],

          columns: [
            {
              text: "Reporte de Pagos",
              alignment: "center",
              fontSize: 10,
              bold: true,
            },
            {
              image: "logo",
              alignment: "right",
              height: 50,
              width: 100,
            },
          ],
        },
        content: [
          { text: "Reporte de Pagos", style: "header" },
          fechaFormateada.format("DD de MMMM del YYYY"),

          {
            margin: 20,
            table: {
              widths: ["*", "*", "*", "*", "*", "*", "*", "*"],
              // widths: [
              //   "9.8%",
              //   "9.8%",
              //   "10.8%",
              //   "9.8%",
              //   "9.8%",
              //   "9.8%",
              //   "9.8%",
              //   "9.8%",
              // ],

              body: [
                [
                  //     "Fecha",
                  //     "Hora",
                  { text: "Fecha", style: "tableHeader" },
                  { text: "Hora", style: "tableHeader" },
                  { text: "Curso", style: "tableHeader" },
                  { text: "Relator", style: "tableHeader" },
                  { text: "Descuento", style: "tableHeader" },
                  { text: "Medio de Pago", style: "tableHeader" },
                  { text: "Valor", style: "tableHeader" },
                  { text: "Monto", style: "tableHeader" },

                  //     "Relator",
                  //     "Descuento",
                  //     "Medio de Pago",
                  //     "Valor ",
                  //     "Monto",
                ],
                ...this.itemsPdf,
              ],
            },
          },
        ],
        images: {
          logo: base64,
        },
        styles: {
          header: {
            color: "#0d0a39",
            fontSize: 15,
            bold: true,
            margin: [0, 20, 0, 10],
          },
          tableHeader: {
            bold: true,
            fontSize: 10,
            color: "black",
          },
        },
        defaultStyle: {
          fontSize: 10,
          alignment: "center",
        },
      };

      pdfMake.createPdf(dd2).download(`Reporte ${fechaLocal()}`);
    }
  }
  @Emit("cerrar")
  onCerrar(): void {
    return;
  }
}
