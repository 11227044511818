
































































































































































































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
// helpers
//import { idtify } from "@/helpers/string";
// tipos
//import { Rutas } from "@/typings/store/modules/rutas";
//import {
//  Permisos,
//  TipoUsuario,
//  Usuario,
//} from "@/typings/store/plugins/easyFirestore/usuarios";
import {
  MatrizPago,
  //Bancos
} from "@/typings/store/plugins/easyFirestore/matrizPagos";
// validaciones
import {
  // email,
  required,
} from "vuelidate/lib/validators";
import { DataFormularioMatrizPago } from "@/typings/components/matrizPagos/formulario";
import SVGIcon from "@/components/SVGIcon.vue";
//import { Archivo, Archivos } from "@/typings/store/modules/archivos";

@Component({
  components: {
    SVGIcon,
  },
  validations: {
    centroCosto: { required },
    periodo: { required },
    cuentaEstadoResultado: { required },
    cuentaControlGasto: { required },
    tipoGasto: { required },
    aplica: { required },
    montoMesAnterior: { required },
    monto: { required },
  },
})
export default class FormularioBanco extends Vue {
  @Prop() readonly matrizPago!: MatrizPago | null;
  @Prop() readonly cargando!: boolean;
  //   @Prop() readonly rutas!: Rutas;
  //   @Prop() readonly ciudades!: Ciudad[];
  //   @Prop() readonly cargando!: boolean;
  //   @Prop() readonly regiones!: Region[];

  created(): void {
    // Object.keys(this.rutas).forEach((key) => {
    //   this.$set(this.permisos, key, false);
    // });
    // console.log(this.permisos);
    const matrizPago = this.matrizPago;
    if (!matrizPago) {
      return;
    }
    this.id = matrizPago.id ?? "";
    this.centroCosto = matrizPago.centroCosto;
    this.periodo = matrizPago.periodo ?? "";
    this.cuentaEstadoResultado = matrizPago.cuentaEstadoResultado ?? "";
    this.cuentaControlGasto = matrizPago.cuentaControlGasto ?? "";
    this.tipoGasto = matrizPago.tipoGasto ?? "";
    this.aplica = matrizPago.aplica ?? "";
    this.montoMesAnterior = matrizPago.montoMesAnterior ?? "";
    this.monto = matrizPago.monto ?? "";
  }

  id: string | null = null;
  centroCosto = "";
  periodo = "";
  cuentaEstadoResultado = "";
  cuentaControlGasto = "";
  tipoGasto = "";
  aplica = "";
  montoMesAnterior = "";
  monto = "";

  get titulo(): string {
    return this.matrizPago ? "editar matrizPago" : "nuevo matrizPago";
  }

  get centroCostoError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.centroCosto.$dirty) return errors;
    if (!this.$v.centroCosto.required) errors.push("Completar datos obligatorios");
    return errors;
  }

  get periodoError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.periodo.$dirty) return errors;
    if (!this.$v.periodo.required) errors.push("Completar datos obligatorios");
    return errors;
  }

  get cuentaEstadoResultadoError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.cuentaEstadoResultado.$dirty) return errors;
    if (!this.$v.cuentaEstadoResultado.required) errors.push("Completar datos obligatorios");
    return errors;
  }

  get cuentaControlGastoError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.cuentaControlGasto.$dirty) return errors;
    if (!this.$v.cuentaControlGasto.required) errors.push("Completar datos obligatorios");
    return errors;
  }

  get tipoGastoError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.tipoGasto.$dirty) return errors;
    if (!this.$v.tipoGasto.required) errors.push("Completar datos obligatorios");
    return errors;
  }

  get aplicaError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.aplica.$dirty) return errors;
    if (!this.$v.aplica.required) errors.push("Completar datos obligatorios");
    return errors;
  }

    get montoMesAnteriorError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.montoMesAnterior.$dirty) return errors;
    if (!this.$v.montoMesAnterior.required) errors.push("Completar datos obligatorios");
    return errors;
  }

  get montoError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.monto.$dirty) return errors;
    if (!this.$v.monto.required) errors.push("Completar datos obligatorios");
    return errors;
  }



  @Emit("close")
  onClose(): null {
    return null;
  }

  @Emit("cerrar-dialogo")
  onCerrarDialogo(): void {
    return;
  }

  @Emit("click-guardar")
  onClickGuardar(data: DataFormularioMatrizPago): DataFormularioMatrizPago {
    return data;
  }

  //   @Watch("permisosKey", { immediate: true })
  //   onTipoChange(): void {
  //     console.log("a");
  //     for (const key of Object.keys(this.permisos)) {
  //       if (this.permisos[key]) {
  //         this.permisos[key] = false;
  //       }
  //     }
  //     this.permisosKey.forEach((key) => {
  //       this.permisos[key] = true;
  //     });
  //   }

  //   nombrePermiso(key: string): string {
  //     return this.rutas[key].text;
  //   }
color="";
  guardar(): void {
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }
    const doc: MatrizPago = {
      centroCosto: this.centroCosto.toUpperCase(),
      periodo: this.periodo.toUpperCase(),
      cuentaEstadoResultado: this.cuentaEstadoResultado.toUpperCase(),
      cuentaControlGasto: this.cuentaControlGasto.toUpperCase(),
      tipoGasto: this.tipoGasto.toUpperCase(),
      aplica: this.aplica.toUpperCase(),
      montoMesAnterior: this.montoMesAnterior.toUpperCase(),
      monto: this.monto.toUpperCase(),
      color: this.color.toUpperCase(),
    };
    if (this.id) {
      doc.id = this.id;
    }
    const data: DataFormularioMatrizPago = {
      doc: doc,
    };
    this.onClickGuardar(data);
  }
}
