
















































































































































































// decoradores
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
// tipos
import { HeadersDataTable } from "@/typings/vuetify/vDataTable";
import { Mantenimiento } from "@/typings/store/plugins/easyFirestore/mantenimientos";
import { ItemsTablaMantenimientos } from "@/typings/components/mantenimientos/tabla";
import "@/helpers/extensions";

@Component
export default class TablaUsuarios extends Vue {
  @Prop() readonly opciones!: boolean;
  @Prop() readonly search!: string;
  @Prop() readonly mantenimientos!: Array<Mantenimiento>;

  options = {
    itemsPerPage: -1,
  };
  headerProps = {
    sortIcon: "mdi-chevron-down",
  };

  get headers(): HeadersDataTable {
    return [
      //{text: "", value: "estado", sortable: false},
      { text: "Nombre", value: "nombre" },
      { text: "Posicion", value: "posicion" },
      { text: "Cuenta Softland", value: "cuentaSoftland" },
      { text: "Empresa", value: "empresa"},
      { text: "Contrato", value: "contrato"},
      { text: "Item", value: "item"},
      { text: "Itemizado", value: "itemizado"},
      { text: "Piso", value: "piso"},
      { text: "Equipos", value: "equipos"},
      { text: "DesgloseEquipos", value: "desgloseEquipos"},
      { text: "Descripcion", value: "descripcion"},
      { text: "marca", value: "marca"},
      { text: "modelo", value: "modelo"},
      { text: "ubicacion", value: "ubicacion"},
      { text: "periosidad", value: "periosidad"},
      { text: "valor", value: "valor"},
      { text: "ultimaMantencion", value: "ultimaMantencion"},
      { text: "proximaMantencion", value: "proximaMantencion"},
      { text: "controlan", value: "controlan"},
      { text: "fotos", value: "fotos"},
      { text: "observaciones", value: "observaciones"},

      { text: "", value: "opciones", sortable: false },
    ];
  }

  get items(): ItemsTablaMantenimientos {
    return this.mantenimientos.map((mantenimiento) => {
      const nombre = mantenimiento.nombre;
      const posicion = mantenimiento.posicion;

      const empresa = mantenimiento.empresa;
      const contrato = mantenimiento.contrato;
      const cuentaSoftland = mantenimiento.cuentaSoftland;
      const item = mantenimiento.item;
      const itemizado = mantenimiento.itemizado;
      const piso = mantenimiento.piso;
      const equipos = mantenimiento.equipos;
      const desgloseEquipos = mantenimiento.desgloseEquipos;
      const descripcion = mantenimiento.descripcion;
      const marca = mantenimiento.marca;
      const modelo = mantenimiento.ubicacion;
      const ubicacion = mantenimiento.ubicacion;
      const periosidad = mantenimiento.periosidad;
      const valor = mantenimiento.valor;
      const ultimaMantencion = mantenimiento.ultimaMantencion;
      const proximaMantencion = mantenimiento.proximaMantencion;
      const controlan = mantenimiento.controlan;
      const fotos = mantenimiento.fotos;
      const observaciones = mantenimiento.observaciones;

      return {
        nombre,
        posicion,
        cuentaSoftland,
        empresa,
        contrato,
        item,
        itemizado,
        piso,
        equipos,
        desgloseEquipos,
        descripcion,
        marca,
        modelo,
        ubicacion,
        periosidad,
        valor,
        ultimaMantencion,
        proximaMantencion,
        controlan,
        fotos,
        observaciones,

        data: mantenimiento,
      };
    });
  }

  //   actualizarEstado(item: Usuario): void {
  //     let estado: EstadoUsuario;
  //     item.estado === 'libre' ? estado = 'bloqueado' : estado = 'libre';
  //     item.estado = estado;
  //     this.onCambiarEstado(item);
  //   }

  //   @Emit("cambiar-estado")
  //   onCambiarEstado(usuario: Usuario): Usuario {
  //     return usuario;
  //   }

  @Emit("abrir-info")
  onAbrirInfo(mantenimiento: Mantenimiento): Mantenimiento {
    return mantenimiento;
  }

  @Emit("abrir-editar")
  onAbrirEditar(mantenimiento: Mantenimiento): Mantenimiento {
    return mantenimiento;
  }

  @Emit("abrir-eliminar")
  onAbrirEliminar(mantenimiento: Mantenimiento): Mantenimiento {
    return mantenimiento;
  }
}
