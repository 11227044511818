












































































































































































// decoradores
import {Component, Emit, Prop, Vue} from "vue-property-decorator";
// tipos
import {HeadersDataTable} from "@/typings/vuetify/vDataTable";
import { Cliente } from "@/typings/store/plugins/easyFirestore/clientes";
import {ItemsTablaClientes} from "@/typings/components/clientes/tabla";
import "@/helpers/extensions";

@Component
export default class TablaUsuarios extends Vue {
  @Prop() readonly opciones!: boolean;
  @Prop() readonly search!: string;
  @Prop() readonly clientes!: Array<Cliente>;

  options = {
    itemsPerPage: -1,
  };
  headerProps = {
    sortIcon: "mdi-chevron-down"
  }

  get headers(): HeadersDataTable {
    return [
      //{text: "", value: "estado", sortable: false},
      {text: "Codigo", value: "codigo"},
      {text: "Razón Social", value: "nombre"},
      {text: "Rut", value: "rut"},
      {text: "Giro", value: "giro"},
      {text: "Telefono", value: "telefono"},
      {text: "Giro", value: "giro"},
      {text: "", value: "opciones", sortable: false}
    ];
  }

  get items(): ItemsTablaClientes {
    return this.clientes.map((cliente) => {
      const codigo = cliente.codigo;
      const nombre = cliente.nombre;
      const rut = cliente.rut;
      const giro = cliente.giro;
      const telefono = cliente.telefono;
      const correo = cliente.correo;
    
      return {
        codigo,
        nombre,
        rut,
        giro,
        telefono,
        correo,
        
       
        data: cliente,
      };
    });
  }

//   actualizarEstado(item: Usuario): void {
//     let estado: EstadoUsuario;
//     item.estado === 'libre' ? estado = 'bloqueado' : estado = 'libre';
//     item.estado = estado;
//     this.onCambiarEstado(item);
//   }

//   @Emit("cambiar-estado")
//   onCambiarEstado(usuario: Usuario): Usuario {
//     return usuario;
//   }

  @Emit("abrir-info")
  onAbrirInfo(cliente: Cliente): Cliente {
    return cliente;
  }

  @Emit("abrir-editar")
  onAbrirEditar(cliente: Cliente): Cliente {
    return cliente;
  }

  @Emit("abrir-eliminar")
  onAbrirEliminar(cliente: Cliente): Cliente {
    return cliente;
  }
}
