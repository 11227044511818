import { RouteConfig } from "vue-router";
import Ingresos from "@/views/Ingresos.vue";

const ingresos: RouteConfig = {
    path: "/ingresos",
    name: "ingresos",
    component: Ingresos,
};

export default ingresos;
