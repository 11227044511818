












































































































































































// decoradores
import {Component, Emit, Prop, Vue} from "vue-property-decorator";
// tipos
import {HeadersDataTable} from "@/typings/vuetify/vDataTable";
import { CondicionDivision } from "@/typings/store/plugins/easyFirestore/condicionesDivisiones";
import {ItemsTablaCondicionesDivisiones} from "@/typings/components/condicionesDivisiones/tabla";
import "@/helpers/extensions";

@Component
export default class TablaUsuarios extends Vue {
  @Prop() readonly opciones!: boolean;
  @Prop() readonly search!: string;
  @Prop() readonly condicionesDivisiones!: Array<CondicionDivision>;

  options = {
    itemsPerPage: -1,
  };
  headerProps = {
    sortIcon: "mdi-chevron-down"
  }

  get headers(): HeadersDataTable {
    return [
      //{text: "", value: "estado", sortable: false},
      {text: "Nombre", value: "codigo_division"},
      {text: "Posicion", value: "nombre_division"},
      {text: "", value: "opciones", sortable: false}
    ];
  }

  get items(): ItemsTablaCondicionesDivisiones {
    return this.condicionesDivisiones.map((codigoDivision) => {
      const codigo_division = codigoDivision.codigo_division;
      const nombre_division = codigoDivision.nombre_division;
    
      return {
        codigo_division,
        nombre_division,
       
        data: codigoDivision,
      };
    });
  }

//   actualizarEstado(item: Usuario): void {
//     let estado: EstadoUsuario;
//     item.estado === 'libre' ? estado = 'bloqueado' : estado = 'libre';
//     item.estado = estado;
//     this.onCambiarEstado(item);
//   }

//   @Emit("cambiar-estado")
//   onCambiarEstado(usuario: Usuario): Usuario {
//     return usuario;
//   }

  @Emit("abrir-info")
  onAbrirInfo(codigoDivision: CondicionDivision): CondicionDivision {
    return codigoDivision;
  }

  @Emit("abrir-editar")
  onAbrirEditar(codigoDivision: CondicionDivision): CondicionDivision {
    return codigoDivision;
  }

  @Emit("abrir-eliminar")
  onAbrirEliminar(codigoDivision: CondicionDivision): CondicionDivision {
    return codigoDivision;
  }
}
