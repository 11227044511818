
























































































































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { TipoCargo } from "@/typings/store/plugins/easyFirestore/tipoCargos";
// validaciones
import { required } from "vuelidate/lib/validators";
import { DataFormularioTipoCargo } from "@/typings/components/tipoCargos/formulario";
import SVGIcon from "@/components/SVGIcon.vue";

@Component({
  components: {
    SVGIcon,
  },
  validations: {
    nombre: { required },
    idTipo: { required },
    cuentaEstadoResultado: { required },
    cuentaControlGasto: { required },
  },
})
export default class FormularioTipoCargo extends Vue {
  @Prop() readonly tipoCargo!: TipoCargo | null;
  @Prop() readonly cargando!: boolean;
  //   @Prop() readonly rutas!: Rutas;
  //   @Prop() readonly ciudades!: Ciudad[];
  //   @Prop() readonly cargando!: boolean;
  //   @Prop() readonly regiones!: Region[];

  created(): void {
    // Object.keys(this.rutas).forEach((key) => {
    //   this.$set(this.permisos, key, false);
    // });
    // console.log(this.permisos);
    const tipoCargo = this.tipoCargo;
    if (!tipoCargo) {
      return;
    }
    this.id = tipoCargo.id ?? "";
    this.nombre = tipoCargo.nombre;
    this.idTipo = tipoCargo.idTipo ?? "";
    this.cuentaEstadoResultado = tipoCargo.cuentaEstadoResultado ?? "";
    this.cuentaControlGasto = tipoCargo.cuentaControlGasto ?? "";
  }

  id: string | null = null;
  idTipo = "";
  nombre = "";

  cuentaEstadoResultado = "";
  cuentaControlGasto = "";

  get titulo(): string {
    return this.tipoCargo ? "editar tipoCargo" : "nuevo tipoCargo";
  }

  //   get permisosKey(): Array<string> {
  //     // return Object.keys(this.permisos).filter((key) => {
  //     //   if(this.rutas[key].tipos.includes(this.tipo)){
  //     //     this.permisos[key] = true;
  //     //   }
  //     // });
  //     return Object.keys(this.permisos).filter((key) => {
  //       if (!this.rutas[key]) {
  //         return false;
  //       }
  //       const tipo = this.rutas[key].tipos.includes(this.tipo);
  //       return tipo;
  //     });
  //   }

  get nombreError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.nombre.$dirty) return errors;
    if (!this.$v.nombre.required) errors.push("Completar datos obligatorios");
    return errors;
  }

  get posicionError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.idTipo.$dirty) return errors;
    if (!this.$v.idTipo.required) errors.push("Completar datos obligatorios");
    return errors;
  }

  @Emit("close")
  onClose(): null {
    return null;
  }

  @Emit("cerrar-dialogo")
  onCerrarDialogo(): void {
    return;
  }

  @Emit("click-guardar")
  onClickGuardar(data: DataFormularioTipoCargo): DataFormularioTipoCargo {
    return data;
  }

  //   @Watch("permisosKey", { immediate: true })
  //   onTipoChange(): void {
  //     console.log("a");
  //     for (const key of Object.keys(this.permisos)) {
  //       if (this.permisos[key]) {
  //         this.permisos[key] = false;
  //       }
  //     }
  //     this.permisosKey.forEach((key) => {
  //       this.permisos[key] = true;
  //     });
  //   }

  //   nombrePermiso(key: string): string {
  //     return this.rutas[key].text;
  //   }

  guardar(): void {
    this.$v.$touch();
    console.log(this.$v.$invalid);
    const doc: TipoCargo = {
      nombre: this.nombre.toUpperCase(),
      idTipo: this.idTipo,
      cuentaEstadoResultado: this.cuentaEstadoResultado.toUpperCase(),
      cuentaControlGasto: this.cuentaControlGasto.toUpperCase(),
    };
    if (this.id) {
      doc.id = this.id;
    }
    const data: DataFormularioTipoCargo = {
      doc: doc,
    };
    this.onClickGuardar(data);
  }
}
