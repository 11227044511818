































































// decoradores
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { CondicionComercial } from "@/typings/store/plugins/easyFirestore/condicionesComerciales";
import SVGIcon from "@/components/SVGIcon.vue";
// tipos

// tipos
@Component({
  components: {
    SVGIcon,
  },
})
export default class InfoCondicionComercial extends Vue {
  @Prop() readonly condicionComercial!: CondicionComercial;
  @Prop({ type: Boolean }) readonly showTitulo!: boolean;

  get id(): string {
    return this.condicionComercial.id || "N/R";
  }

  get liquidacion(): string {
    return this.condicionComercial.liquidacion || "N/R";
  }

  get desde(): string {
    return this.condicionComercial.desde || "N/R";
  }

  get hasta(): string {
    return this.condicionComercial.hasta || "N/R";
  }


  @Emit("cerrar")
  onCerrar(): void {
    return;
  }
}
