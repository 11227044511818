import { RouteConfig } from "vue-router";
import InformacionCompleta from "@/views/InformacionCompleta.vue";

const informacionCompleta: RouteConfig = {
    path: "/informacionCompleta/:id",
    name: "informacionCompleta",
    component: InformacionCompleta,
};

export default informacionCompleta;
