



























































































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { SesionProgramada } from "@/typings/store/plugins/easyFirestore/sesionesProgramadas";
import { Sesion } from "@/typings/store/plugins/easyFirestore/sesiones";
import { EventCalendar } from "@/typings/vuetify/vCalendar";

@Component
export default class Informacion extends Vue {
  @Prop() readonly sesionProgramada!: SesionProgramada | null;
  @Prop() readonly evento!: EventCalendar | null;
  @Prop() readonly hoy!: Date;

  created(): void {
    const sesionProgramada = this.sesionProgramada;
   // const evento = this.evento;

    if (sesionProgramada) {
      this.id = sesionProgramada.id ?? "";
      this.sesion = sesionProgramada.sesion;
      this.nombre = sesionProgramada.sesion.tema;
      this.fecha = sesionProgramada.fecha;
      this.horaInicio = sesionProgramada.horaInicio;
      this.horaFin = sesionProgramada.horaFin;
      this.link = sesionProgramada.link;
      this.habilitar = true;
    // } else {
    //   if (evento) {
    //     this.id = evento.id ?? "";
    //     this.sesion = evento.sesion;
    //     this.nombre = this.sesion.tema;
    //     this.fecha = new Date(evento.start).format("DD-MM-YYYY");
    //     this.horaInicio = new Date(evento.start).format("HH:mm");
    //     this.horaFin = new Date(evento.end).format("HH:mm");
    //     this.habilitar = false;
    //   }
    }
  }

  id: string | null = null;
  sesion: Sesion | null = null;
  nombre = "";
  fecha = "";
  horaInicio = "";
  horaFin = "";
  link = "";
  disabled = true;
  habilitar = true;

  get titulo(): string {
    return `Sesion N°${this.sesion?.numero} - ${this.sesion?.curso.nombre}`;
  }

  habilitarHola(): boolean {
    return this.hoy ? true : false;
  }

  @Emit("close")
  onClose(): null {
    return null;
  }
}
