import { RouteConfig } from "vue-router";
import Usuarios from "@/views/Usuarios.vue";

const usuarios: RouteConfig = {
  path: "/usuarios",
  name: "usuarios",
  component: Usuarios,
};

export default usuarios;
