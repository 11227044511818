export {};

declare global {
  interface String {
    toDate(): Date;

    toRUT(): string;

    toInt(): number;

    validateRUT(): boolean;

    validateEmail(): boolean;

    capitalizeFirstLetter(): string;
  }

  interface Number {
    toCurrency(params?: { symbol?: string, fixed?: number }): string;
  }

  interface Date {
    format(format: string): string;
  }

  interface Array<T> {
    isEmpty: boolean;
    isNotEmpty: boolean;
    first: T;
    last: T;
    firstOrNull: T | null;
    lastOrNull: T | null;
  }
}

Object.defineProperty( Array.prototype, 'isEmpty', {
  get: function () {
    return !(this.length > 0);
  }
});

Object.defineProperty( Array.prototype, 'isNotEmpty', {
  get: function () {
    return this.length > 0;
  }
});

Object.defineProperty( Array.prototype, 'first', {
  get: function () {
    return this[0];
  }
});

Object.defineProperty( Array.prototype, 'last', {
  get: function () {
    return this[this.length - 1];
  }
});

Object.defineProperty( Array.prototype, 'firstOrNull', {
  get: function () {
    return this[0] ?? null;
  }
});

Object.defineProperty( Array.prototype, 'lastOrNull', {
  get: function () {
    return this[this.length - 1] ?? null;
  }
});

Number.prototype.toCurrency = function (params?: { symbol?: string, fixed?: number }) {
  if (!isNaN(Number(this))) {
    const splitted = this.toString().split(".");
    let negative = false;
    if(splitted[0].includes("-")) {
      negative = true;
    }
    const reverse: string = splitted[0].replaceAll("-", "").split("").reverse().join("");
    const moneyReverse: RegExpMatchArray | null = reverse.match(/.{1,3}/g);
    const symbolAdd = params?.symbol ? `${params.symbol} ` : '';
    const decimals = splitted[1];
    const decimalsAdd = params?.fixed && params.fixed > 0 && decimals ? `,${decimals.slice(0, params?.fixed ?? decimals.length)}` : "";
    const negativeAdd = negative ? "-" : '';
    if (moneyReverse) {
      return `${negativeAdd}${symbolAdd}${moneyReverse.join(".").split("").reverse().join("")}${decimalsAdd}`;
    }
  }
  return "ERROR";
}

String.prototype.toInt = function () {
  return parseInt(String(this).replace(/[^\d]/g, ""));
}

String.prototype.toDate = function () {
  const isoRegexUTC = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(((\.\d+)*Z)|(\+\d{2}:\d{2}))$/;
  const isoRegexNoUTC = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d+$/;
  const stringThis = String(this);
  if (isoRegexNoUTC.test(stringThis) || isoRegexUTC.test(stringThis)) {
    return new Date(stringThis);
  }
  const dateRegex = /\d{2,4}[-/]\d{2}[-/]\d{2,4}/g;
  const hourRegex = /\d{2}:\d{2}(:\d{2})*/g;
  const dateMatch = stringThis.match(dateRegex);
  const hourMatch = stringThis.match(hourRegex);
  let dateString = dateMatch ? dateMatch[0] : "";
  const hourString = hourMatch ? hourMatch[0] : "00:00:00";
  if (/\d{2}[-/]\d{2}[-/]\d{4}/g.test(dateString)) {
    dateString = dateString.split("-").reverse().join("/");
  }
  dateString = dateString.replace(/-/g, "/");
  try {
    return new Date(`${dateString} ${hourString}`);
  } catch (err) {
    console.error(err)
    return new Date();
  }
}

String.prototype.capitalizeFirstLetter = function () {
  if (String(this)) {
    return `${this[0].toUpperCase()}${this.slice(1, this.length)}`;
  }
  return "";
}

String.prototype.validateRUT = function () {
  const rutRegExp = /^\d{7,8}-[\dkK]$/;
  const rut = this.replace(/\./g, "").toLowerCase();
  if (rutRegExp.test(rut)) {
    const rutArray = rut.split("-");
    const cuerpo = rutArray[0];
    const digitoVerificador = rutArray[1];
    let suma = 0;
    let multiplicador = 2;
    for (let i = cuerpo.length - 1; i >= 0; i--) {
      if (multiplicador > 7) multiplicador = 2;
      suma += parseInt(cuerpo[i]) * multiplicador;
      multiplicador++;
    }
    const result = 11 - (suma % 11);
    const digitoReal = result == 11 ? 0 : result == 10 ? "k" : result;
    return digitoReal == digitoVerificador;
  } else {
    return false;
  }
}

String.prototype.validateEmail = function () {
  const EmailRegEx = /[a-zñ][\w-.]*@[a-zñ][\w-.]*\.[a-zñ]+/g;
  return EmailRegEx.test(String(this));
}

String.prototype.toRUT = function () {
  const rutRegExp = /^\d{7,8}-[\dkK]$/;
  if (rutRegExp.test(String(this))) {
    const reverse: string = this.split("-")[0].split("").reverse().join("");
    const rutReverse: RegExpMatchArray | null = reverse.match(/.{1,3}/g);
    if (rutReverse) {
      return `${rutReverse.join(".").split("").reverse().join("")}-${this.split("-")[1]}`;
    }
  }
  return "ERROR";
}

Date.prototype.format = function (format: string) {
  if (this.toString().toLowerCase() !== "invalid date") {
    let fechaReturn = format;
    const months = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre"
    ];
    const days = [
      "Domingo",
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado"
    ];
    fechaReturn = fechaReturn.replaceAll("DDDD", days[this.getDay() == 7 ? 0 : this.getDay()]);
    fechaReturn = fechaReturn.replaceAll("DDD", days[this.getDay() == 7 ? 0 : this.getDay()].substring(0, 3));
    fechaReturn = fechaReturn.replaceAll("DD", this.getDate().toString().padStart(2, "0"));
    if(format.includes("MMMM")) {
      fechaReturn = fechaReturn.replaceAll("MMMM", months[this.getMonth()]);
    } else if(format.includes("MMM")) {
      fechaReturn = fechaReturn.replaceAll("MMM", months[this.getMonth()].substring(0, 3));
    } else if(format.includes("MM")) {
      fechaReturn = fechaReturn.replaceAll("MM", (this.getMonth() + 1).toString().padStart(2, "0"));
    }
    if(format.includes("YYYY")) {
      fechaReturn = fechaReturn.replaceAll("YYYY", this.getFullYear().toString());
    } else if(format.includes("YY")) {
      fechaReturn = fechaReturn.replaceAll("YY", this.getFullYear().toString().substring(2, 4));
    }
    fechaReturn = fechaReturn.replaceAll("HH", this.getHours().toString().padStart(2, "0"));
    fechaReturn = fechaReturn.replaceAll("mm", this.getMinutes().toString().padStart(2, "0"));
    fechaReturn = fechaReturn.replaceAll("ss", this.getSeconds().toString().padStart(2, "0"));
    fechaReturn = fechaReturn.replaceAll("tt", this.getHours() >= 12 ? "PM" : "AM");
    return fechaReturn;
  }
  return "Invalid Date"
};