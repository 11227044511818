

























































import {Component, Emit, Prop, Vue} from "vue-property-decorator";
// helpers
// tipos
// validaciones
import {required} from "vuelidate/lib/validators";
import {Ciudad} from "@/typings/store/plugins/easyFirestore/ciudades";
import {DataFormularioCiudad} from "@/typings/components/ciudades/formulario";
import {Region, Regiones} from "@/typings/store/plugins/easyFirestore/regiones";

@Component({
  validations: {
    nombre: { required },
    region: { required },
  },
})
export default class FormularioCiudad extends Vue {
  @Prop() readonly ciudad!: Ciudad | null;
  @Prop() readonly regiones!: Regiones | null;

  created(): void {
    const ciudad = this.ciudad;
    if(!ciudad){
      return;
    }
    this.nombre = ciudad.nombre ?? "";
    this.region = ciudad.region;
  }

  id: string | null = null;
  nombre = "";
  region: Region | null = null;

  get titulo(): string {
    return this.ciudad
        ? "editar ciudad"
        : "nueva ciudad";
  }

  get nombreError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.nombre.$dirty) return errors;
    if (!this.$v.nombre.required) errors.push("Campo requerido");
    return errors;
  }

  get regionError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.region.$dirty) return errors;
    if (!this.$v.region.required) errors.push("Campo requerido");
    return errors;
  }

  @Emit("close")
  onClose(): null {
    return null;
  }

  @Emit("cerrar-dialogo")
  onCerrarDialogo(): void {
    return;
  }

  @Emit("click-guardar")
  onClickGuardar(
      data: DataFormularioCiudad
  ): DataFormularioCiudad {
    return data;
  }

  guardar(): void {
    this.$v.$touch();
    if (this.$v.$invalid || !this.region) {
      return;
    }
    const doc: Ciudad = {
      nombre: this.nombre.toLowerCase(),
      region: this.region
    };
    if (this.id) {
      doc.id = this.id;
    }
    const data: DataFormularioCiudad = {
      doc: doc,
    };
    this.onClickGuardar(data);
  }

}
