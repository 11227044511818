
















































































































































































// decoradores
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
// tipos
import { HeadersDataTable } from "@/typings/vuetify/vDataTable";
import { Lectura } from "@/typings/store/plugins/easyFirestore/lecturas";
import { ItemsTablaLecturas } from "@/typings/components/lecturas/tabla";
import "@/helpers/extensions";

@Component
export default class TablaUsuarios extends Vue {
  @Prop() readonly opciones!: boolean;
  @Prop() readonly search!: string;
  @Prop() readonly lecturas!: Array<Lectura>;

  options = {
    itemsPerPage: -1,
  };
  headerProps = {
    sortIcon: "mdi-chevron-down",
  };

  get headers(): HeadersDataTable {
    return [
      //{text: "", value: "estado", sortable: false},
      { text: "Usuario", value: "usuario" },
      { text: "Fecha", value: "fecha" },
      { text: "Centro de Costo", value: "centroCosto" },
      { text: "Empresa", value: "empresa" },
      { text: "Codigo Medidor", value: "codigoMedidor" },
      { text: "Lect. Ant.", value: "lecturaAnterior" },
      { text: "Lect. Act.", value: "lecturaActual" },
      { text: "Porcentaje", value: "porcentaje" },
      { text: "", value: "opciones", sortable: false },
    ];
  }

  get items(): ItemsTablaLecturas {
    return this.lecturas.map((lectura) => {
      const usuario = lectura.usuario;
      const fecha = lectura.fecha;
      const centroCosto = lectura.centroCosto;
      const empresa = lectura.empresa;
      const codigoMedidor = lectura.codigoMedidor;
      const lecturaAnterior = lectura.lecturaAnterior;
      const lecturaActual = lectura.lecturaActual;
      const porcentaje = lectura.porcentaje;

      return {
        usuario,
        fecha,
        centroCosto,
        empresa,
        codigoMedidor,
        lecturaAnterior,
        lecturaActual,
        porcentaje,

        data: lectura,
      };
    });
  }

  //   actualizarEstado(item: Usuario): void {
  //     let estado: EstadoUsuario;
  //     item.estado === 'libre' ? estado = 'bloqueado' : estado = 'libre';
  //     item.estado = estado;
  //     this.onCambiarEstado(item);
  //   }

  //   @Emit("cambiar-estado")
  //   onCambiarEstado(usuario: Usuario): Usuario {
  //     return usuario;
  //   }

  @Emit("abrir-info")
  onAbrirInfo(lectura: Lectura): Lectura {
    return lectura;
  }

  @Emit("abrir-editar")
  onAbrirEditar(lectura: Lectura): Lectura {
    return lectura;
  }

  @Emit("abrir-eliminar")
  onAbrirEliminar(lectura: Lectura): Lectura {
    return lectura;
  }
}
