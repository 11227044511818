import { render, staticRenderFns } from "./SVGIcon.vue?vue&type=template&id=727dda14&scoped=true&"
import script from "./SVGIcon.vue?vue&type=script&lang=ts&"
export * from "./SVGIcon.vue?vue&type=script&lang=ts&"
import style0 from "./SVGIcon.vue?vue&type=style&index=0&id=727dda14&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "727dda14",
  null
  
)

export default component.exports