









































































































































































































































































































































































































































































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
// helpers
//import { idtify } from "@/helpers/string";
// tipos
//import { Rutas } from "@/typings/store/modules/rutas";
//import {
//  Permisos,
//  TipoUsuario,
//  Usuario,
//} from "@/typings/store/plugins/easyFirestore/usuarios";
import {
  Tarifa,
  //Bancos
} from "@/typings/store/plugins/easyFirestore/tarifas";
import { DistribuidorEnergia } from "@/typings/store/plugins/easyFirestore/distribuidoresEnergia";
// validaciones
import {
  // email,
  required,
} from "vuelidate/lib/validators";
import { DataFormularioTarifa } from "@/typings/components/tarifas/formulario";
import SVGIcon from "@/components/SVGIcon.vue";
//import { Archivo, Archivos } from "@/typings/store/modules/archivos";

@Component({
  components: {
    SVGIcon,
  },
  validations: {
    tipoLectura: { required },
    tipoTarifa: { required },
    fecha: { required },
    zona: { required },
    costo: { required },
    costoFijo: { required },
    // sectorTarifario: { required },
  },
})
export default class FormularioTarifa extends Vue {
  @Prop() readonly tarifa!: Tarifa | null;
  @Prop() readonly cargando!: boolean;
  @Prop() readonly distribuidoresEnergia!: DistribuidorEnergia[];
  //   @Prop() readonly rutas!: Rutas;
  //   @Prop() readonly ciudades!: Ciudad[];
  //   @Prop() readonly cargando!: boolean;
  //   @Prop() readonly regiones!: Region[];

  created(): void {
    // Object.keys(this.rutas).forEach((key) => {
    //   this.$set(this.permisos, key, false);
    // });
    // console.log(this.permisos);
    const tarifa = this.tarifa;
    if (!tarifa) {
      return;
    }
    this.id = tarifa.id ?? "";
    this.tipoLectura = tarifa.tipoLectura;
    this.tipoTarifa = tarifa.tipoTarifa ?? "";
    this.fecha = tarifa.fecha.toDate().format("YYYY-MM-DD") ?? "";
    this.zona = tarifa.zona ?? "";
    this.periodo = tarifa.periodo ?? "";
    this.costo = tarifa.costo ?? "";
    this.costoFijo = tarifa.costoFijo ?? "";
    this.condicionesEspeciales = tarifa.condicionesEspeciales ?? "";
    this.empresa = tarifa.empresa ?? "";
    this.cargoPotenciaContratada = tarifa.cargoPotenciaContratada ?? "";
    this.cargoPotenciaMaximaLeida = tarifa.cargoPotenciaMaximaLeida ?? "";
    this.cargoPotenciaLeidaHP = tarifa.cargoPotenciaLeidaHP ?? "";
    this.cargoPotenciaLeidaFP = tarifa.cargoPotenciaLeidaFP ?? "";
    this.aereoOsubterraneo = tarifa.aereoOsubterraneo ?? "";
    this.sectorTarifario = tarifa.sectorTarifario ?? "";
  }

  id: string | null = null;

  tipoLectura = "";
  tipoTarifa = "";
  fecha = "";
  periodo = "";
  zona = "";
  costo = 0;
  costoFijo = 0;
  condicionesEspeciales = "";
  empresa = "";
  sectorTarifario = "";

  aereoOsubterraneo = "";

  cargoPotenciaContratada = 0;
  cargoPotenciaMaximaLeida = 0;
  cargoPotenciaLeidaHP = 0;
  cargoPotenciaLeidaFP = 0;

  optionsCosto = {
    //locale: "es-CL",
    prefix: "$",
    suffix: "",
    length: 10,
    precision: 3,
  };

  optionsCostoLectura = {
    locale: "es-CL",
    prefix: "$",
    suffix: "",
    length: 10,
    precision: 3,
    readonly: true,
  };

  menu = false;

  itemsTarifa = ["BT1", "BT2", "BT3", "AT4.3", "SOBRECONSUMO"];
  //itemsZona = ["LA FLORIDA","MAIPÚ","LINARES","TALCA"];
  //itemsEmpresa = ["CGE DISTRIBUCIÓN", "ENEL", "FRONTEL"];
  //itemsAereoOsubterraneo = ["AÉREO", "SUBT.1", "SUBT.2", "SUBT.3"];

  get titulo(): string {
    return this.tarifa ? "editar tarifa" : "nueva tarifa";
  }

  get itemsLectura(): string[] {
    let lecturas: string[] = [];
    if (this.tipoTarifa === "BT1") {
      lecturas = ["Consumo Energía kw"];
    } else if (this.tipoTarifa === "BT2") {
      lecturas = ["Consumo Energía kw", "Potencia Contratada HP"];
    } else if (this.tipoTarifa === "BT3") {
      lecturas = ["Consumo Energía kw", "Potencia Leída HP"];
    } else if (this.tipoTarifa === "AT4.3") {
      lecturas = [
        "Consumo Energía kw",
        "Potencia Leída HP",
        "Potencia Leída FP",
      ];
    } else if (this.tipoTarifa === "SOBRECONSUMO") {
      lecturas = ["Sobreconsumo"];
    }
    return lecturas;
  }

  get itemsEmpresa(): string[] {
    let empresas: string[] = [];
    this.distribuidoresEnergia.forEach((distribuidor) => {
      if (distribuidor.empresaDecretoTarifario) {
        empresas.push(distribuidor.empresaDecretoTarifario);
      }
    });
    return empresas.sort();
  }

  get itemsZona(): string[] {
    let zonas: string[] = [];
    this.distribuidoresEnergia.forEach((distribuidor) => {
      if (distribuidor.empresaDecretoTarifario === this.empresa) {
        zonas.push(distribuidor.comuna);
      }
    });
    return zonas.sort();
  }

  get itemSectorTarifario(): string {
    let sector = "";
    this.distribuidoresEnergia.forEach((distribuidor) => {
      if (distribuidor.empresaDecretoTarifario === this.empresa && 
        distribuidor.comuna === this.zona) {
        sector = distribuidor.sectorTarifario
      }
    });
    return sector;
  }

  get itemsAereoOsubterraneo(): string[] {
    let aerOsubt: string[] = [];
    this.distribuidoresEnergia.forEach((distribuidor) => {
      if (distribuidor.empresaDecretoTarifario === this.empresa && 
        distribuidor.comuna === this.zona) {
        aerOsubt.push(distribuidor.aereoOsubterraneo)
      }
    });
    return aerOsubt.sort();
  }

  get costoFijoSinIVA(): number {
    return this.costoFijo / 1.19;
  }

  get costoConsumoSinIVA(): number {
    return this.costo / 1.19;
  }

  get periodoTarifa(): string {
    let periodoTarifa = "";

    periodoTarifa =
      "01" + this.fecha.toDate().format("DD-MM-YYYY").substring(2, 10) ?? "";

    return periodoTarifa;
  }
  //   get permisosKey(): Array<string> {
  //     // return Object.keys(this.permisos).filter((key) => {
  //     //   if(this.rutas[key].tipos.includes(this.tipo)){
  //     //     this.permisos[key] = true;
  //     //   }
  //     // });
  //     return Object.keys(this.permisos).filter((key) => {
  //       if (!this.rutas[key]) {
  //         return false;
  //       }
  //       const tipo = this.rutas[key].tipos.includes(this.tipo);
  //       return tipo;
  //     });
  //   }

  get tipoLecturaError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.tipoLectura.$dirty) return errors;
    if (!this.$v.tipoLectura.required) errors.push("");
    return errors;
  }

  get tipoTarifaError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.tipoTarifa.$dirty) return errors;
    if (!this.$v.tipoTarifa.required) errors.push("");
    return errors;
  }

  get fechaError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.fecha.$dirty) return errors;
    if (!this.$v.fecha.required) errors.push("");
    return errors;
  }

  get zonaError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.zona.$dirty) return errors;
    if (!this.$v.zona.required) errors.push("");
    return errors;
  }

  get costoError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.costo.$dirty) return errors;
    if (!this.$v.costo.required) errors.push("");
    if (this.costo === 0) errors.push("");
    return errors;
  }

  get costoFijoError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.costoFijo.$dirty) return errors;
    if (!this.$v.costoFijo.required) errors.push("");
    if (this.costoFijo === 0) errors.push("");
    return errors;
  }

  // get sectorTarifarioError(): Array<string> {
  //   const errors: Array<string> = [];
  //   if (!this.$v.sectorTarifario.$dirty) return errors;
  //   if (!this.$v.sectorTarifario.required) errors.push("");
  //   return errors;
  // }

  @Emit("close")
  onClose(): null {
    return null;
  }

  @Emit("cerrar-dialogo")
  onCerrarDialogo(): void {
    return;
  }

  @Emit("click-guardar")
  onClickGuardar(data: DataFormularioTarifa): DataFormularioTarifa {
    return data;
  }

  //   @Watch("permisosKey", { immediate: true })
  //   onTipoChange(): void {
  //     console.log("a");
  //     for (const key of Object.keys(this.permisos)) {
  //       if (this.permisos[key]) {
  //         this.permisos[key] = false;
  //       }
  //     }
  //     this.permisosKey.forEach((key) => {
  //       this.permisos[key] = true;
  //     });
  //   }

  //   nombrePermiso(key: string): string {
  //     return this.rutas[key].text;
  //   }

  guardar(): void {
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }
    const doc: Tarifa = {
      tipoLectura: this.tipoLectura,
      tipoTarifa: this.tipoTarifa,
      fecha: this.fecha.toDate().format("DD-MM-YYYY") ?? "",
      periodo: this.periodoTarifa,
      zona: this.zona,
      costo: this.costo,
      costoConsumoSinIVA: this.costoConsumoSinIVA,
      costoFijo: this.costoFijo,
      costoFijoSinIVA: this.costoFijoSinIVA,
      condicionesEspeciales: this.condicionesEspeciales,
      empresa: this.empresa,
      cargoPotenciaContratada: this.cargoPotenciaContratada,
      cargoPotenciaMaximaLeida: this.cargoPotenciaMaximaLeida,
      cargoPotenciaLeidaHP: this.cargoPotenciaLeidaHP,
      cargoPotenciaLeidaFP: this.cargoPotenciaLeidaFP,
      aereoOsubterraneo: this.aereoOsubterraneo,
      sectorTarifario: this.itemSectorTarifario,
    };
    if (this.id) {
      doc.id = this.id;
    }
    const data: DataFormularioTarifa = {
      doc: doc,
    };
    this.onClickGuardar(data);
  }
}
