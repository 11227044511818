var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"rounded-lg elevation-6",attrs:{"headers":_vm.headers,"options":_vm.options,"items":_vm.items,"search":_vm.search,"hide-default-footer":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.opciones",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"error","icon":"","x-small":"","fab":""},on:{"click":function($event){return _vm.onAbrirEliminar(item.data)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar")])])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"warning","icon":"","x-small":"","fab":""},on:{"click":function($event){return _vm.onAbrirEditar(item.data)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"info","icon":"","x-small":"","fab":""},on:{"click":function($event){return _vm.onAbrirInfo(item.data)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-information-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Información")])])],1)],1)],1)],1)],1)],1)],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }