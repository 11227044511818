





























// import { CoreData } from "@/typings/vista/monitoreo";
import { Component, Prop, Vue } from "vue-property-decorator";
import { GChart } from "vue-google-charts";

import { Curso } from "@/typings/store/plugins/easyFirestore/cursos";
import { Pago } from "@/typings/store/plugins/easyFirestore/pagos";
// import string from "@/helpers/string";
//import { Usuario } from "@/typings/store/plugins/easyFirestore/usuarios";

@Component({
  components: {
    GChart,
  },
})
export default class ComponenteEstadoCurso extends Vue {
  @Prop() readonly pagos!: Array<Pago>;
  // @Prop() readonly relatores!: Array<Usuario>;
  @Prop() readonly tipoUsuario!: string;
  @Prop() readonly cursos!: Array<Curso>;

  created(): void {
    if (!this.pagos) {
      return;
    }
  }

  pago: Pago | null = null;

  get totalDebito(): number {
    return this.pagos
      .filter((pago) => {
        if (
          pago.resultado?.payment_type_code === "VD" ||
          pago.resultado?.payment_type_code === "VP"
        ) {
          return pago;
        }
      })
      .reduce((total, pago) => total + (pago.resultado?.amount ?? 0), 0);
  }

  get total(): number {
    return this.subtotal - this.comision - this.comisionRelator;
  }

  get totalCredito(): number {
    return Math.round(
      this.pagos
        .filter((pago) => {
          if (
            pago.resultado?.payment_type_code === "VN" ||
            pago.resultado?.payment_type_code === "VC" ||
            pago.resultado?.payment_type_code === "SI" ||
            pago.resultado?.payment_type_code === "S2" ||
            pago.resultado?.payment_type_code === "NC"
          ) {
            return pago;
          }
        })
        .reduce((total, pago) => total + (pago.resultado?.amount ?? 0), 0)
    );
  }

  get subtotal(): number {
    return Math.round(this.totalCredito + this.totalDebito);
  }

  get comision(): number {
    const comisionDebito = Math.round(this.totalDebito * 0.0139);
    const ivaDebito = Math.round(comisionDebito * 0.19);
    const comisionCredito = Math.round(this.totalCredito * 0.0236);
    const ivaCredito = Math.round(comisionCredito * 0.19);

    return Math.round(
      comisionDebito + ivaDebito + comisionCredito + ivaCredito
    );
  }

  get comisionRelator(): number {
    let CursosRecorridos: Array<string> = [];

    const comisionCurso = Math.round(
      this.pagos.reduce(
        (totalCurso, pago) =>
          totalCurso +
          Object.values(pago.cursos).reduce((total, curso) => {
            if (curso.comision.tipo === "Por participante") {
              if (curso.comision.valor.includes("%")) {
                return (
                  total +
                  Math.round(
                    curso.precio *
                      (parseFloat(curso.comision.valor.replace("%", "")) / 100)
                  )
                );
              } else {
                return total + parseFloat(curso.comision.valor);
              }
            } else {
              if (
                CursosRecorridos.isEmpty ||
                (!CursosRecorridos.includes(curso.id ?? "") &&
                  !curso.comision.valor.includes("$"))
              ) {
                CursosRecorridos.push(curso.id ?? "");
                return total + parseFloat(curso.comision.valor);
              }
            }
            return total;
          }, 0),
        0
      )
    );

    const comisionSesion = Math.round(
      this.pagos.reduce(
        (totalSesion, pago) =>
          totalSesion +
          Object.values(pago.sesiones).reduce((sum, sesion) => {
            if (sesion.precio !== 0) {
              if (sesion.curso.comision.tipo.includes("%")) {
                return (
                  sum +
                  sesion.precio *
                    (parseFloat(sesion.curso.comision.valor.replace("%", "")) /
                      100)
                );
              }
              return sum + sesion.curso.comision.valor.toInt();
            }
            return sum;
          }, 0),
        0
      )
    );
    return comisionCurso + comisionSesion;
  }

  pieOptions = {
    width: 302,
    height: 190,
    pieHole: 0.5,
    backgroundColor: "#FFFFFF",
    pieSliceTextStyle: {
      color: "none",
    },
    colors: ["#0d0a39", "#251f50", "#3d3569", "#564c82", "#6f649c", "#897cb7"],
  };

  get distribucionPago(): Array<Array<string | number>> {
    let arrayMedios = [
      ["Medio de Pago", "Monto"],
      ["C. Relatores", this.comisionRelator],
      ["C. Webpay", this.comision],
      ["Total", this.total],
    ];
    return arrayMedios;
  }

  get medioPago(): Array<Array<string | number>> {
    let arrayMedios = [
      ["Medio de Pago", "Monto"],
      ["P. Débito", this.totalDebito],
      ["P. Credito", this.totalCredito],
    ];
    return arrayMedios;
  }
}
