import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
// modulos
import usuarios from "./usuarios";
import regiones from "./regiones";
import ciudades from "./ciudades";
import categoriasCurso from "./categoriasCurso";
import inscripciones from "./inscripciones";
import cursos from "./cursos";
import promociones from "./promociones";
import monitoreo from "./monitoreo";
import pagos from "./pagos";
import perfil from "./perfil";
import notificaciones from "./notificaciones";
// import cursosDisponibles from "./cursosDisponibles";
import agenda from "@/router/agenda";
import misCursos from "@/router/misCursos";
import carrito from "@/router/carrito";
import inicio from "@/router/inicio";
import principal from "@/router/principal";
import reproductor from "./reproductor";
import informacionCompleta from "./informacionCompleta";
import terminosCondiciones from "@/router/terminosCondiciones";
import compraExitosa from "@/router/compraExitosa";
import registrosEgresos from "@/router/registrosEgresos";
import bancos from "@/router/bancos";
import centrosCostos from "@/router/centrosCostos";
import codCuentas from "@/router/codCuentas";
import tipoEgresos from "@/router/tipoEgresos";
import proveedores from "@/router/proveedores";
import tipoDocumentos from "@/router/tipoDocumentos";
import tipoCargos from "@/router/tipocCargos";
import matrizPagos from "@/router/matrizPagos";
import prenominas from "@/router/prenominas";
import saldoCuentas from "@/router/saldoCuentas";
import legales from "@/router/legales";
import cajaChicas from "@/router/cajaChicas";
import mapeoGastos from "@/router/mapeoGastos";
import vacancias from "@/router/vacancias";
import contratos from "@/router/contratos";
import locales from "@/router/locales";
import consumos from "@/router/consumos";
import clientes from "@/router/clientes";
import ordenes from "@/router/ordenes";
import medidores from "@/router/medidores";
import ingresos from "@/router/ingresos";
import tarifas from "@/router/tarifas";
import lecturas from "@/router/lecturas";
import distribuidoresEnergia from "@/router/distribuidoresEnergia";
import visualizacionPagos from "@/router/visualizacionPagos";
import cajaChicasLinares from "@/router/cajaChicasLinares";
import cajaChicasTalca from "@/router/cajaChicasTalca";
import solicitudes from "@/router/solicitudes";
import mantenimientos from "@/router/mantenimientos";
import equipos from "@/router/equipos";
import ordenesERG from "@/router/ordenesERG";
import planCuentasIngresos from "./planCuentasIngresos";
import condicionesComerciales from "./condicionesComerciales";
import condicionesDivisiones from "./condicionesDivisiones";
import facturas from "./facturas";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  usuarios,
  regiones,
  ciudades,
  categoriasCurso,
  inscripciones,
  cursos,
  promociones,
  pagos,
  perfil,
  notificaciones,
  // cursosDisponibles,
  agenda,
  misCursos,
  inicio,
  monitoreo,
  carrito,
  reproductor,
  informacionCompleta,
  terminosCondiciones,
  compraExitosa,
  registrosEgresos,
  bancos,
  centrosCostos,
  codCuentas,
  tipoEgresos,
  proveedores,
  tipoDocumentos,
  tipoCargos,
  matrizPagos,
  prenominas,
  saldoCuentas,
  legales,
  cajaChicas,
  mapeoGastos,
  principal,
  vacancias,
  contratos,
  locales,
  consumos,
  clientes,
  ordenes,
  medidores,
  ingresos,
  tarifas,
  lecturas,
  distribuidoresEnergia,
  visualizacionPagos,
  cajaChicasLinares,
  cajaChicasTalca,
  solicitudes,
  mantenimientos,
  equipos,
  ordenesERG,
  planCuentasIngresos,
  condicionesComerciales,
  condicionesDivisiones,
  facturas,
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
