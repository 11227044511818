var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',{staticClass:"pa-3 cuartiary",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"\n        text-h6 text-center\n        secondary--text\n        poppins\n        font-weight-bold\n        text-capitalize\n      ",attrs:{"cols":"8","offset":"2"}},[_vm._v(" "+_vm._s(_vm.titulo)+" ")]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"2"}},[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":_vm.onClose}},[_c('SVGIcon',{attrs:{"src":require('../../assets/icons/cerrar-duo.svg')}})],1)],1)],1),_c('v-row',{staticClass:"pt-3",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"primary--text poppins font-weight-bold pl-6",attrs:{"cols":"4"}},[_vm._v(" Pago Recurrente: ")]),_c('v-col',{staticClass:"d-flex px-2 py-1",attrs:{"cols":"12","sm":"8"}},[_c('v-autocomplete',{attrs:{"placeholder":"Selecciona si el Pago es Recurrente","items":_vm.item_recurrente,"hide-details":"auto","item-text":"rut","item-value":"id","dense":"","outlined":"","return-object":"","error-messages":_vm.centroCostoError},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"poppins primary--text text-capitalize"},[_vm._v(_vm._s(item))])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"poppins primary--text text-capitalize"},[_vm._v(_vm._s(item))])]}}]),model:{value:(_vm.recurrente),callback:function ($$v) {_vm.recurrente=$$v},expression:"recurrente"}})],1)],1),_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"primary--text poppins font-weight-bold pl-6",attrs:{"cols":"4"}},[_vm._v(" Centro de Costo: ")]),_c('v-col',{staticClass:"d-flex px-2 py-1",attrs:{"cols":"12","sm":"8"}},[_c('v-autocomplete',{attrs:{"placeholder":"Selecciona el Centro de Costo","items":_vm.itemCentrosCostos,"hide-details":"auto","item-text":"rut","item-value":"id","dense":"","outlined":"","return-object":"","error-messages":_vm.centroCostoError},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"poppins primary--text text-capitalize"},[_vm._v(_vm._s(item))])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"poppins primary--text text-capitalize"},[_vm._v(_vm._s(item))])]}}]),model:{value:(_vm.centroCosto),callback:function ($$v) {_vm.centroCosto=$$v},expression:"centroCosto"}})],1)],1),_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"primary--text poppins font-weight-bold pl-6",attrs:{"cols":"4"}},[_vm._v(" Rut: ")]),_c('v-col',{staticClass:"d-flex px-2 py-1",attrs:{"cols":"12","sm":"8"}},[_c('v-autocomplete',{attrs:{"placeholder":"Selecciona el Rut Proveedor","items":_vm.itemRutProveedores,"hide-details":"auto","item-text":"rut","item-value":"id","dense":"","outlined":"","return-object":"","error-messages":_vm.rutError},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"poppins primary--text text-capitalize"},[_vm._v(_vm._s(item))])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"poppins primary--text text-capitalize"},[_vm._v(_vm._s(item))])]}}]),model:{value:(_vm.rut),callback:function ($$v) {_vm.rut=$$v},expression:"rut"}})],1)],1),_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"primary--text poppins font-weight-bold pl-6",attrs:{"cols":"4"}},[_vm._v(" Nombre Proveedor: ")]),_c('v-col',{staticClass:"d-flex px-2 py-1",attrs:{"cols":"12","sm":"8"}},[_c('v-text-field',{attrs:{"placeholder":"Nombre Proveedor","dense":"","outlined":"","hide-details":"auto","readonly":""},model:{value:(_vm.itemNombreProveedores),callback:function ($$v) {_vm.itemNombreProveedores=$$v},expression:"itemNombreProveedores"}})],1)],1),_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"primary--text poppins font-weight-bold pl-6",attrs:{"cols":"4"}},[_vm._v(" Codigo Cuenta: ")]),_c('v-col',{staticClass:"d-flex px-2 py-1",attrs:{"cols":"12","sm":"8"}},[_c('v-autocomplete',{attrs:{"placeholder":"Selecciona el Codigo de Cuenta","items":_vm.itemCodigoCuentas,"hide-details":"auto","dense":"","outlined":"","return-object":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"poppins primary--text text-capitalize"},[_vm._v(_vm._s(item))])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"poppins primary--text text-capitalize"},[_vm._v(_vm._s(item))])]}}]),model:{value:(_vm.codigoCuenta),callback:function ($$v) {_vm.codigoCuenta=$$v},expression:"codigoCuenta"}})],1)],1),_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"primary--text poppins font-weight-bold pl-6",attrs:{"cols":"4"}},[_vm._v(" Descripción Cuenta: ")]),_c('v-col',{staticClass:"d-flex px-2 py-1",attrs:{"cols":"12","sm":"8"}},[_c('v-text-field',{attrs:{"placeholder":"Descripcion Cuenta Contable","dense":"","outlined":"","hide-details":"auto","readonly":""},model:{value:(_vm.itemDescripcionCuentas),callback:function ($$v) {_vm.itemDescripcionCuentas=$$v},expression:"itemDescripcionCuentas"}})],1)],1),_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"primary--text poppins font-weight-bold pl-6",attrs:{"cols":"4"}},[_vm._v(" Tipo Gasto: ")]),_c('v-col',{staticClass:"d-flex px-2 py-1",attrs:{"cols":"12","sm":"8"}},[_c('v-autocomplete',{attrs:{"placeholder":"Selecciona el Tipo de Gasto","items":_vm.itemTipoGastos,"hide-details":"auto","dense":"","outlined":"","return-object":"","error-messages":_vm.tipoGastoError},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"poppins primary--text text-capitalize"},[_vm._v(_vm._s(item))])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"poppins primary--text text-capitalize"},[_vm._v(_vm._s(item))])]}}]),model:{value:(_vm.tipoGasto),callback:function ($$v) {_vm.tipoGasto=$$v},expression:"tipoGasto"}})],1)],1),_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"primary--text poppins font-weight-bold pl-6",attrs:{"cols":"4"}},[_vm._v(" Cuenta Control Gasto: ")]),_c('v-col',{staticClass:"d-flex px-2 py-1",attrs:{"cols":"12","sm":"8"}},[_c('v-text-field',{attrs:{"placeholder":"Cuenta Control Gasto","dense":"","outlined":"","hide-details":"auto","readonly":""},model:{value:(_vm.itemControlGastos),callback:function ($$v) {_vm.itemControlGastos=$$v},expression:"itemControlGastos"}})],1)],1),_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"primary--text poppins font-weight-bold pl-6",attrs:{"cols":"4"}},[_vm._v(" Cuenta Estado Resultado: ")]),_c('v-col',{staticClass:"d-flex px-2 py-1",attrs:{"cols":"12","sm":"8"}},[_c('v-text-field',{attrs:{"placeholder":"Cuenta Estado Resultado","dense":"","outlined":"","hide-details":"auto","readonly":""},model:{value:(_vm.itemEstadoResultados),callback:function ($$v) {_vm.itemEstadoResultados=$$v},expression:"itemEstadoResultados"}})],1)],1),(
      _vm.tipoGasto === 'CONSUMO AGUA' ||
      _vm.tipoGasto === 'CONSUMO ENERGIA' ||
      _vm.tipoGasto === 'CONSUMO TELEFONIA' ||
      _vm.tipoGasto === 'SERVICIO GUARDIAS' ||
      _vm.tipoGasto === 'SOPORTE OFICINA CENTRAL'||
      _vm.tipoGasto === 'SERVICIO ALARMAS'

    )?_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"primary--text poppins font-weight-bold pl-6",attrs:{"cols":"4"}},[_vm._v(" N° Cliente: ")]),_c('v-col',{staticClass:"pl-2"},[_c('v-text-field',{attrs:{"label":"N Cliente","hide-details":"auto","dense":"","outlined":""},model:{value:(_vm.nCliente),callback:function ($$v) {_vm.nCliente=$$v},expression:"nCliente"}})],1)],1):_vm._e(),_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"primary--text poppins font-weight-bold pl-6",attrs:{"cols":"4"}},[_vm._v(" Tipo de Documento: ")]),_c('v-col',{staticClass:"d-flex px-2 py-1",attrs:{"cols":"12","sm":"8"}},[_c('v-select',{attrs:{"placeholder":"Selecciona el Tipo de Documento","items":_vm.itemRutTipoDocumentos,"hide-details":"auto","item-text":"nombre","item-value":"id","dense":"","outlined":"","return-object":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
    var item = ref.item;
return [_c('span',{staticClass:"poppins primary--text text-capitalize"},[_vm._v(_vm._s(item))])]}},{key:"selection",fn:function(ref){
    var item = ref.item;
return [_c('span',{staticClass:"poppins primary--text text-capitalize"},[_vm._v(_vm._s(item))])]}}]),model:{value:(_vm.tipoDocumento),callback:function ($$v) {_vm.tipoDocumento=$$v},expression:"tipoDocumento"}})],1)],1),_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"primary--text poppins font-weight-bold pl-6",attrs:{"cols":"4"}},[_vm._v(" Glosa: ")]),_c('v-col',{staticClass:"d-flex px-2 py-1",attrs:{"cols":"12","sm":"8"}},[_c('v-text-field',{attrs:{"label":"Glosa","hide-details":"auto","dense":"","outlined":""},model:{value:(_vm.observacion),callback:function ($$v) {_vm.observacion=$$v},expression:"observacion"}})],1)],1),_c('v-row',{staticClass:"pa-3 cuartiary",attrs:{"no-gutters":"","justify":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"poppins font-weight-bold text-initial rounded-lg",attrs:{"color":"secondary","depressed":"","disabled":_vm.cargando},on:{"click":_vm.guardar}},[(!_vm.cargando)?_c('span',[_vm._v(" Guardar ")]):_c('img',{attrs:{"src":require("../../assets/three-dots.svg"),"alt":"Three dots","width":"64px"}})])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }