














































// decoradores
import { Vue, Component, Emit } from "vue-property-decorator";
// validaciones
import { required, sameAs } from "vuelidate/lib/validators";

@Component({
  validations: {
    password: {
      required,
    },
    confirmar: {
      required,
      sameAsPassword: sameAs("password"),
    },
  },
})
export default class ActivarCuenta extends Vue {
  password = "";
  confirmar = "";

  get errorPassword(): Array<string> {
    const error: Array<string> = [];
    if (!this.$v.password.$dirty) return error;
    this.$v.password.required || error.push("El campo no puede estar vacio");
    return error;
  }

  get errorConfirmar(): Array<string> {
    const error: Array<string> = [];
    if (!this.$v.confirmar.$dirty) return error;
    this.$v.confirmar.required || error.push("El campo no puede estar vacio");
    this.$v.confirmar.sameAsPassword ||
      error.push("Los dos campos tienen que ser iguales");
    return error;
  }

  @Emit("click-btn")
  onClickBtn(data: string): string {
    return data;
  }

  activar(): void {
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }
    this.onClickBtn(this.password);
  }
}
