import { RouteConfig } from "vue-router";
import Consumos from "@/views/Consumos.vue";

const consumos: RouteConfig = {
    path: "/consumos",
    name: "consumos",
    component: Consumos,
};

export default consumos;
