var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',{staticClass:"pa-3 cuartiary",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"\n        text-h6 text-center\n        secondary--text\n        poppins\n        font-weight-bold\n        text-capitalize\n      ",attrs:{"cols":"8","offset":"2"}},[_vm._v(" Historico de Nóminas ")]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"2"}},[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":_vm.onCerrar}},[_c('SVGIcon',{attrs:{"src":require('/src/assets/icons/cerrar-duo.svg')}})],1)],1)],1),_c('v-card-text',[_c('v-row',{staticClass:"pa-3",attrs:{"align":"center","dense":""}},[_c('v-col',{staticClass:"pr-2",attrs:{"cols":"12","md":"2"}},[_c('v-autocomplete',{staticClass:"rounded-lg input-buscar",attrs:{"clearable":"","outlined":"","hide-details":"","label":"Centro de Costo","items":_vm.itemsCentroCosto,"dense":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-row',{staticClass:"fill-height",attrs:{"dense":"","align":"center"}},[_c('v-col',[_c('img',{attrs:{"width":"8px","height":"8px","src":require("../../assets/icons/flecha-abajo.svg"),"alt":"Flecha"}})])],1)]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-capitalize poppins primary--text font-weight-light"},[_vm._v(_vm._s(item))])]}}]),model:{value:(_vm.selectCentroCosto),callback:function ($$v) {_vm.selectCentroCosto=$$v},expression:"selectCentroCosto"}})],1),_c('v-col',{staticClass:"pr-2",attrs:{"cols":"12","md":"2"}},[_c('v-autocomplete',{staticClass:"rounded-lg input-buscar",attrs:{"clearable":"","items":_vm.itemsProveedor,"dense":"","outlined":"","hide-details":"","label":"Proveedor"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-row',{staticClass:"fill-height",attrs:{"dense":"","align":"center"}},[_c('v-col',[_c('img',{attrs:{"width":"8px","height":"8px","src":require("../../assets/icons/flecha-abajo.svg"),"alt":"Flecha"}})])],1)]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-capitalize poppins primary--text font-weight-light"},[_vm._v(_vm._s(item))])]}}]),model:{value:(_vm.selectProveedor),callback:function ($$v) {_vm.selectProveedor=$$v},expression:"selectProveedor"}})],1),_c('v-col',{staticClass:"pr-2",attrs:{"cols":"12","md":"2"}},[_c('v-autocomplete',{staticClass:"rounded-lg input-buscar",attrs:{"clearable":"","outlined":"","hide-details":"","label":"Tipo de Gasto","items":_vm.itemsTipoGasto,"dense":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-row',{staticClass:"fill-height",attrs:{"dense":"","align":"center"}},[_c('v-col',[_c('img',{attrs:{"width":"8px","height":"8px","src":require("../../assets/icons/flecha-abajo.svg"),"alt":"Flecha"}})])],1)]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-capitalize poppins primary--text font-weight-light"},[_vm._v(_vm._s(item))])]}}]),model:{value:(_vm.selectTipoGasto),callback:function ($$v) {_vm.selectTipoGasto=$$v},expression:"selectTipoGasto"}})],1),_c('v-col',{staticClass:"pr-2",attrs:{"cols":"12","md":"2"}}),_c('v-col',{staticClass:"pr-2",attrs:{"cols":"12","md":"2"}},[_c('v-text-field',{staticClass:"rounded-lg input-buscar",attrs:{"outlined":"","hide-details":"","label":"Buscar Nómina","dense":""},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('img',{attrs:{"width":"20px","height":"20px","src":require("../../assets/icons/buscar.svg"),"alt":"Buscar"}})]},proxy:true}]),model:{value:(_vm.searchNomina),callback:function ($$v) {_vm.searchNomina=$$v},expression:"searchNomina"}})],1),_c('v-col',[_c('v-btn',{attrs:{"elevation":"5","block":"","outlined":""},on:{"click":_vm.crearExcelHistorico}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"pr-0",attrs:{"cols":"1"}},[_c('img',{attrs:{"width":"20px","height":"20px","src":require("../../assets/icons/excel.svg"),"alt":"Excel"}})]),_c('v-col',{attrs:{"cols":"11"}},[_vm._v(" Descarga Historico ")])],1)],1)],1)],1),_c('v-data-table',{staticClass:"rounded-0 tabla poppins primary--text font-weight-light",attrs:{"headers":_vm.headers,"items":_vm.itemsFiltradoNomina,"header-props":_vm.headerProps,"search":_vm.searchNomina},scopedSlots:_vm._u([{key:"item.estado",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-card',{staticClass:"rounded-pill",attrs:{"width":"24px","height":"24px","flat":"","color":item.data.estado === 'aprobado'
                  ? 'rgba(39, 155, 24, 0.4)'
                  : 'rgba(234, 81, 67, 0.4)'}},[_c('v-row',{staticClass:"fill-height",attrs:{"no-gutters":"","justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-card',{staticClass:"rounded-pill",attrs:{"width":"8px","height":"8px","flat":"","color":item.data.estado === 'aprobado' ? '#279b18' : '#ea5143'}})],1)],1)],1)],1)],1)]}},{key:"item.pdf",fn:function(ref){
                  var item = ref.item;
return [_c('v-row',{attrs:{"no-gutters":"","justify":"end"}},[(item.data.archivo)?_c('v-col',{attrs:{"cols":"auto"}},[_c('v-menu',{staticClass:"pa-0",attrs:{"offset-x":"","content-class":"elevation-0 rounded-1rem primary-border"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.abrirVistaPrevia(Object.values(item.data.archivo)[0])}}},'v-btn',attrs,false),on),[_c('img',{attrs:{"width":"20px","height":"20px","src":require("../../assets/icons/pdf.svg"),"alt":"PDF"}})])]}}],null,true)})],1):_vm._e()],1)]}}])})],1),_c('v-dialog',{attrs:{"max-width":"1200"},model:{value:(_vm.vistaPrevia.model),callback:function ($$v) {_vm.$set(_vm.vistaPrevia, "model", $$v)},expression:"vistaPrevia.model"}},[(_vm.vistaPrevia.model)?_c('VistaPreviaPdf',{attrs:{"url":_vm.vistaPrevia.url},on:{"cerrar":_vm.cerrarVistaPrevia}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }