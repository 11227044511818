































































































































































































































































































































































































































































































































































































































































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
// helpers
//import { idtify } from "@/helpers/string";
// tipos
//import { Rutas } from "@/typings/store/modules/rutas";
//import {
//  Permisos,
//  TipoUsuario,
//  Usuario,
//} from "@/typings/store/plugins/easyFirestore/usuarios";
import { Consumo } from "@/typings/store/plugins/easyFirestore/consumos";
import { Local } from "@/typings/store/plugins/easyFirestore/locales";
import { Cliente } from "@/typings/store/plugins/easyFirestore/clientes";

// validaciones
import {
  // email,
  required,
} from "vuelidate/lib/validators";
import { DataFormularioConsumo } from "@/typings/components/consumos/formulario";
import SVGIcon from "@/components/SVGIcon.vue";
//import { Archivo, Archivos } from "@/typings/store/modules/archivos";

@Component({
  components: {
    SVGIcon,
  },
  validations: {
    // periodo: { required },
    centroCosto: { required },
    cantidadLocales: { required },
    unidadLocativa: { required },
    empresa: { required },
    //rut: { required },
    // tipoServicio: { required },
    // nroContrato: { required },
    // nroLectura: { required },
    // nroCliente: { required },
    // nroMedidor: { required },
    // monto: { required },
  },
})
export default class FormularioConsumo extends Vue {
  @Prop() readonly consumo!: Consumo | null;
  @Prop() readonly cargando!: boolean;
  @Prop() readonly locales!: Local[];
  @Prop() readonly clientes!: Cliente[];
  //   @Prop() readonly rutas!: Rutas;
  //   @Prop() readonly ciudades!: Ciudad[];
  //   @Prop() readonly cargando!: boolean;
  //   @Prop() readonly regiones!: Region[];

  created(): void {
    // Object.keys(this.rutas).forEach((key) => {
    //   this.$set(this.permisos, key, false);
    // });
    // console.log(this.permisos);
    const consumo = this.consumo;
    if (!consumo) {
      return;
    }
    this.id = consumo.id ?? "";
    // this.periodo = consumo.periodo.toDate().format("YYYY-MM-DD") ?? "";
    this.centroCosto = consumo.centroCosto ?? "";
    this.unidadLocativa = consumo.unidadLocativa ?? "";
    this.unidadLocativa_2 = consumo.unidadLocativa_2 ?? "";
    this.unidadLocativa_3 = consumo.unidadLocativa_3 ?? "";
    this.unidadLocativa_4 = consumo.unidadLocativa_4 ?? "";
    this.unidadLocativa_5 = consumo.unidadLocativa_5 ?? "";
    this.unidades = consumo.unidades ?? "";
    this.empresa = consumo.empresa ?? "";
    this.rut = consumo.rut ?? "";
    this.cantidadLocales = consumo.cantidadLocales ?? "";
    this.estado = consumo.estado ?? "";
    // this.tipoServicio = consumo.tipoServicio ?? "";
    // this.nroContrato = consumo.nroContrato ?? "";
    // this.nroLectura = consumo.nroLectura ?? "";
    // this.nroCliente = consumo.nroCliente ?? "";
    // this.nroMedidor = consumo.nroMedidor ?? "";
    // this.imagen1 = consumo.imagen1 ?? "";
    // this.imagen2 = consumo.imagen2 ?? "";
    // this.imagen3 = consumo.imagen3 ?? "";
    // this.monto = consumo.monto ?? "";
  }

  id: string | null = null;

  // periodo = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
  //   .toISOString()
  //   .substr(0, 10);

  centroCosto = "";
  unidadLocativa = "";
  unidadLocativa_2 = "";
  unidadLocativa_3 = "";
  unidadLocativa_4 = "";
  unidadLocativa_5 = "";
  empresa = "";
  rut = "";
  // tipoServicio = "";
  // nroContrato = "";
  // nroLectura = "";
  // nroCliente = "";
  // nroMedidor = "";
  // imagen1 = "";
  // imagen2 = "";
  // imagen3 = "";
  // monto = 0;
  unidades = "";

  menu2 = false;

  itemTiposServicios = [
    "AGUA",
    "ELECTRICIDAD",
    "GAS",
    "TELEFONO",
    "INTERNET",
    "OTROS",
  ];
  itemCantidadLocales = ["1", "2", "3", "4", "5"];
  cantidadLocales = "0";
  estado = "";
  itemEstados = ["ACTIVO", "INACTIVO"];

  // get fechaPeriodoF(): string {
  //   let array_fecha = this.periodo.split("-");
  //   let dia = "01";
  //   let mes = array_fecha[1];
  //   let ano = array_fecha[0];
  //   console.log(array_fecha);
  //   this.periodo = dia + "-" + mes + "-" + ano;
  //   return dia + "-" + mes + "-" + ano;
  // }

  get localesUnidos(): string {
    let unidades = "";
    if (this.unidadLocativa) {
      unidades += this.unidadLocativa;
    }
    if (this.unidadLocativa_2) {
      unidades += " - " + this.unidadLocativa_2;
    }
    if (this.unidadLocativa_3) {
      unidades += " - " + this.unidadLocativa_3;
    }
    if (this.unidadLocativa_4) {
      unidades += " - " + this.unidadLocativa_4;
    }
    if (this.unidadLocativa_5) {
      unidades += " - " + this.unidadLocativa_5;
    }
    return unidades;
  }

  get itemCentrosCostos(): Array<string> {
    const itemCentroCosto: Array<string> = [];
    this.locales.forEach((local) => {
      itemCentroCosto.push(local.centroCosto);
    });

    return itemCentroCosto.sort((sesionA, sesionB) => {
      return parseInt(sesionA) - parseInt(sesionB);
    });
  }

  get itemUnidadesLocativas(): Array<string> {
    const itemUnidadLocativa: Array<string> = [];
    this.locales.forEach((local) => {
      if (local.centroCosto === this.centroCosto) {
        itemUnidadLocativa.push(local.unidadLocativa);
      }
    });

    return itemUnidadLocativa.sort();
  }

  get itemRut(): string {
    let clienteRut = "";
    this.clientes.forEach((cliente) => {
      if (cliente.nombre === this.empresa) {
        clienteRut = cliente.rut;
        this.rut = clienteRut;
      }
    });
    return clienteRut;
  }

  // select cliente
  get itemClientes(): Array<string> {
    // let cliente = "";
    // let arrayCliente = [cliente];
    // this.getArrayClientes.forEach((cliente) => {
    //   arrayCliente.push(cliente.nombre);
    // });

    // const unicos = arrayCliente.filter((valor, indice) => {
    //   return arrayCliente.indexOf(valor) === indice;
    // });
    // return unicos.sort();
    return this.clientes
      ? this.clientes.map((cliente) => cliente.nombre).sort()
      : [];
  }

  get titulo(): string {
    return this.consumo ? "editar consumo" : "nuevo consumo";
  }

  //   get permisosKey(): Array<string> {
  //     // return Object.keys(this.permisos).filter((key) => {
  //     //   if(this.rutas[key].tipos.includes(this.tipo)){
  //     //     this.permisos[key] = true;
  //     //   }
  //     // });
  //     return Object.keys(this.permisos).filter((key) => {
  //       if (!this.rutas[key]) {
  //         return false;
  //       }
  //       const tipo = this.rutas[key].tipos.includes(this.tipo);
  //       return tipo;
  //     });
  //   }

  // get periodoError(): Array<string> {
  //   const errors: Array<string> = [];
  //   if (!this.$v.periodo.$dirty) return errors;
  //   if (!this.$v.periodo.required) errors.push("Completar datos obligatorios");
  //   return errors;
  // }

  get centroCostoError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.centroCosto.$dirty) return errors;
    if (!this.$v.centroCosto.required)
      errors.push("Completar datos obligatorios");
    return errors;
  }

  get unidadLocativaError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.unidadLocativa.$dirty) return errors;
    if (!this.$v.unidadLocativa.required)
      errors.push("Completar datos obligatorios");
    return errors;
  }

  get empresaError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.empresa.$dirty) return errors;
    if (!this.$v.empresa.required) errors.push("Completar datos obligatorios");
    return errors;
  }

  get tipoServicioError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.tipoServicio.$dirty) return errors;
    if (!this.$v.tipoServicio.required)
      errors.push("Completar datos obligatorios");
    return errors;
  }

  get nroContratoError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.nroContrato.$dirty) return errors;
    if (!this.$v.nroContrato.required)
      errors.push("Completar datos obligatorios");
    return errors;
  }

  get nroLecturaError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.nroLectura.$dirty) return errors;
    if (!this.$v.nroLectura.required)
      errors.push("Completar datos obligatorios");
    return errors;
  }

  get nroClienteError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.nroCliente.$dirty) return errors;
    if (!this.$v.nroCliente.required)
      errors.push("Completar datos obligatorios");
    return errors;
  }

  get nroMedidorError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.nroMedidor.$dirty) return errors;
    if (!this.$v.nroMedidor.required)
      errors.push("Completar datos obligatorios");
    return errors;
  }

  get montoError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.monto.$dirty) return errors;
    if (!this.$v.monto.required) errors.push("Completar datos obligatorios");
    return errors;
  }

  get cantidadLocalesError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.cantidadLocales.$dirty) return errors;
    if (!this.$v.cantidadLocales.required)
      errors.push("Completar datos obligatorios");
    return errors;
  }

  @Emit("close")
  onClose(): null {
    return null;
  }

  @Emit("cerrar-dialogo")
  onCerrarDialogo(): void {
    return;
  }

  @Emit("click-guardar")
  onClickGuardar(data: DataFormularioConsumo): DataFormularioConsumo {
    return data;
  }

  //   @Watch("permisosKey", { immediate: true })
  //   onTipoChange(): void {
  //     console.log("a");
  //     for (const key of Object.keys(this.permisos)) {
  //       if (this.permisos[key]) {
  //         this.permisos[key] = false;
  //       }
  //     }
  //     this.permisosKey.forEach((key) => {
  //       this.permisos[key] = true;
  //     });
  //   }

  //   nombrePermiso(key: string): string {
  //     return this.rutas[key].text;
  //   }

  guardar(): void {
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }
    if (this.cantidadLocales === "1") {
      this.unidadLocativa_2 = "";
      this.unidadLocativa_3 = "";
      this.unidadLocativa_4 = "";
      this.unidadLocativa_5 = "";
    }
    if (this.cantidadLocales === "2") {
      this.unidadLocativa_3 = "";
      this.unidadLocativa_4 = "";
      this.unidadLocativa_5 = "";
    }
    if (this.cantidadLocales === "3") {
      this.unidadLocativa_4 = "";
      this.unidadLocativa_5 = "";
    }
    if (this.cantidadLocales === "4") {
      this.unidadLocativa_5 = "";
    }
    if (this.cantidadLocales === "5") {
      this.centroCosto = "";
    }
    const doc: Consumo = {
      // periodo: this.fechaPeriodoF,
      centroCosto: this.centroCosto,
      unidadLocativa: this.unidadLocativa,
      unidadLocativa_2: this.unidadLocativa_2,
      unidadLocativa_3: this.unidadLocativa_3,
      unidadLocativa_4: this.unidadLocativa_4,
      unidadLocativa_5: this.unidadLocativa_5,
      unidades: this.localesUnidos,
      empresa: this.empresa,
      rut: this.rut,
      estado: this.estado,
      cantidadLocales: this.cantidadLocales,
      // tipoServicio: this.tipoServicio,
      // nroContrato: this.nroContrato,
      // nroLectura: this.nroLectura,
      // nroCliente: this.nroCliente,
      // nroMedidor: this.nroMedidor,
      // imagen1: this.imagen1,
      // imagen2: this.imagen2,
      // imagen3: this.imagen3,
      // monto: this.monto,
    };
    if (this.id) {
      doc.id = this.id;
    }
    const data: DataFormularioConsumo = {
      doc: doc,
    };
    this.onClickGuardar(data);
  }
}
