import { RouteConfig } from "vue-router";
import TipoCargos from "@/views/TipoCargos.vue";

const tipoCargos: RouteConfig = {
    path: "/tipoCargos",
    name: "tipoCargos",
    component: TipoCargos,
};

export default tipoCargos;
