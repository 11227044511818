
















































import {Component, Prop, Vue} from "vue-property-decorator";
import {Curso} from "@/typings/store/plugins/easyFirestore/cursos";
import {mapGetters} from "vuex";
import "@/helpers/extensions";
import {Inscripcion} from "@/typings/store/plugins/easyFirestore/inscripciones";

@Component({
  computed: mapGetters({
    getArrayInscripciones: "inscripciones/getArray"
  })
})
export default class HistorialCursos extends Vue {
  @Prop() readonly cursos!: Array<Curso>;

  inscripcionesPorCurso(id: string): Array<Inscripcion> {
    return this.getArrayInscripciones.filter(inscripcion => Object.values(inscripcion.pago.cursos).some(curso => curso.id === id));
  }
}
