








import { Component, Emit, Vue } from "vue-property-decorator";
import { mapActions, mapGetters } from "vuex";
import { Usuario } from "@/typings/store/plugins/easyFirestore/usuarios";
import Login from "@/components/Login.vue";

@Component({
  components: {
    Login,
  },
  methods: mapActions({}),
  computed: mapGetters({
    getUsuario: "usuario/get",
    getArraySesiones: "sesiones/getArray",
    getArrayUsuarios: "usuarios/getArray",
  }),
})

export default class Inicio extends Vue {
  dialogoLogin = true;
  dialogoExito = {
    model: false,
    icono: "",
    texto: "",
  };

  get relatores(): Array<Usuario> {
    return this.getArrayUsuarios.filter(
      (usuario) => usuario.tipo === "relator"
    );
  }

  @Emit("abrir-iniciar-sesion")
  onAbrirIniciarSesion(): void {
    return;
  }

  loginError(data: string): void {
    if (data === "inhabilitado") {
      this.dialogoExito = {
        model: true,
        icono: "",
        texto: "¡Usuario inhabilitado!",
      };
    }
  }
}
