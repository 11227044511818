

























































































































































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
// helpers
// tipos

import { CentroCosto } from "@/typings/store/plugins/easyFirestore/centrosCostos";
import { Ciudad } from "@/typings/store/plugins/easyFirestore/ciudades";
// validaciones
import { required } from "vuelidate/lib/validators";
import { DataFormularioCentroCosto } from "@/typings/components/centrosCostos/formulario";
import SVGIcon from "@/components/SVGIcon.vue";

@Component({
  components: {
    SVGIcon,
  },
  validations: {
    codigo: { required },
    descripcion: { required },
    nivel: { required },
    activo: { required },
    descFormato: { required },
  },
})
export default class FormularioCentroCosto extends Vue {
  @Prop() readonly centroCosto!: CentroCosto | null;
  @Prop() readonly cargando!: boolean;
  @Prop() readonly comunas!: Ciudad[];
  //   @Prop() readonly rutas!: Rutas;

  created(): void {
    // Object.keys(this.rutas).forEach((key) => {
    //   this.$set(this.permisos, key, false);
    // });
    // console.log(this.permisos);
    const centroCosto = this.centroCosto;
    if (!centroCosto) {
      return;
    }
    this.id = centroCosto.id ?? "";
    this.codigo = centroCosto.CodiCC;
    this.descripcion = centroCosto.DescCC ?? "";
    this.nivel = centroCosto.NivelCC;
    this.activo = centroCosto.Activo ?? "";
    this.descFormato = centroCosto.Desc_formato;
    this.comuna = centroCosto.Comuna ?? "";
  }

  id: string | null = null;

  codigo = "";
  descripcion = "";
  nivel = "";
  activo = "";
  descFormato = "";
  comuna = "";

  get titulo(): string {
    return this.centroCosto ? "editar centro Costo" : "nuevo centro Costo";
  }

  get itemsComunas(): Array<string> {
    return this.comunas.map((item) => item.nombre.toUpperCase()).sort();
  }

  get codigoError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.codigo.$dirty) return errors;
    if (!this.$v.codigo.required) errors.push("Completar datos obligatorios");
    return errors;
  }

  get descripcionError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.descripcion.$dirty) return errors;
    if (!this.$v.descripcion.required)
      errors.push("Completar datos obligatorios");
    return errors;
  }

  get nivelError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.nivel.$dirty) return errors;
    if (!this.$v.nivel.required) errors.push("Completar datos obligatorios");
    return errors;
  }

  get activoError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.activo.$dirty) return errors;
    if (!this.$v.activo.required) errors.push("Completar datos obligatorios");
    return errors;
  }

  get descFormatoError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.descFormato.$dirty) return errors;
    if (!this.$v.descFormato.required)
      errors.push("Completar datos obligatorios");
    return errors;
  }
  @Emit("close")
  onClose(): null {
    return null;
  }

  @Emit("cerrar-dialogo")
  onCerrarDialogo(): void {
    return;
  }

  @Emit("click-guardar")
  onClickGuardar(data: DataFormularioCentroCosto): DataFormularioCentroCosto {
    return data;
  }

  guardar(): void {
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }
    const doc: CentroCosto = {
      CodiCC: this.codigo,
      DescCC: this.descripcion,
      NivelCC: this.nivel,
      Activo: this.activo,
      Desc_formato: this.descFormato,
      Comuna: this.comuna,
    };
    if (this.id) {
      doc.id = this.id;
    }
    const data: DataFormularioCentroCosto = {
      doc: doc,
    };
    this.onClickGuardar(data);
  }
}
