













































































































































// decoradores
import { Vue, Component } from "vue-property-decorator";
// vuex
import { mapActions, mapGetters } from "vuex";
// tipos

// componentes
import TablaTipoCargo from "@/components/tipoCargos/Tabla.vue";
import FormularioTipoCargo from "@/components/tipoCargos/Formulario.vue";
import { DataFormularioTipoCargo } from "@/typings/components/tipoCargos/formulario";
import DialogoExito from "@/components/DialogoExito.vue";
import DialogoEliminar from "@/components/DialogoEliminar.vue";
import InfoTipoCargo from "@/components/tipoCargos/Info.vue";
import { fechaLocal } from "@/helpers/date";
import "@/helpers/extensions";
//import BotonesCrud from "@/components/BotonesCrud.vue";
import MensajeEliminar from "@/components/MensajeEliminar.vue";
//import TituloVista from "@/components/TituloVista.vue";
import {
  TipoCargo,
  TipoCargos,
  //  Regiones,
} from "@/typings/store/plugins/easyFirestore/tipoCargos";
//import { DataFormularioRegion } from "@/typings/components/regiones/formulario";
import CargarTipoCargosExcel from "@/components/excel/CargarTipoCargos.vue";
// Excel
import Exceljs from "exceljs";

const routeGuard = (vm: Vue): boolean => {
  const permisos = vm.getUsuario?.permisos;
  if (permisos && permisos["tipoCargos"]) {
    return true;
  }

  const rutas = vm.getRutasUsuario;
  if (rutas.length > 0) {
    const to = rutas[0].to;
    if (to) {
      if (vm.$route.name !== to.name) {
        vm.$router.replace(to);
      }
      return false;
    }
  }
  if (vm.$route.name !== "inicio") {
    vm.$router.replace({ name: "inicio" });
  }
  return false;
};

@Component({
  computed: mapGetters({
    getUsuarios: "usuarios/get",
    getTipoCargos: "tipoCargos/get",
    getArrayUsuarios: "usuarios/getArray",
    getUsuario: "usuario/get",
    getRutas: "rutas/get",

    getRutasUsuario: "usuario/getRutas",
    getArrayTipoCargos: "tipoCargos/getArray",
  }),
  methods: mapActions({
    setTipoCargo: "tipoCargos/set",
    deleteTipoCargo: "tipoCargos/delete",
    insertBatchTipoCargos: "tipoCargos/insertBatch",
    //setArchivo: "archivos/set",
    //deleteArchivo: "archivos/delete",
  }),
  beforeRouteEnter(to, from, next) {
    next(routeGuard);
  },
  components: {
    DialogoEliminar,
    DialogoExito,
    TablaTipoCargo,
    FormularioTipoCargo,
    InfoTipoCargo,
    //BotonesCrud,
    MensajeEliminar,
    CargarTipoCargosExcel,
    //TituloVista,
  },
})
export default class VistaBancos extends Vue {
  created(): void {
    if (!routeGuard(this)) {
      return;
    }
  }

  cargando = false;
  dialogoExito = {
    model: false,
    texto: "",
    icono: "",
  };
  search = "";
  tipoCargo: TipoCargo | null = null;
  dialogoInfo = false;
  dialogoFormulario = false;
  dialogoEliminar = false;
  textoEliminar = "¿ESTÁS SEGURO QUE DESEAS ELIMINAR ESTE BANCO?";
  dialogoCargarExcel = false;

  get tipoCargos(): TipoCargos {
    return this.getTipoCargos;
  }

  get items(): Array<TipoCargo> {
    return this.getArrayTipoCargos;
  }

  clickItem(val: { id: string } | null): void {
    this.tipoCargo = val ? this.tipoCargos[val.id] : null;
  }

  clickPlus(): void {
    this.tipoCargo = null;
    this.dialogoFormulario = true;
  }

    async createExcel(): Promise<void> {
    const workbook = new Exceljs.Workbook();
    const sheet = workbook.addWorksheet("TIPO GASTOS", {
      properties: { defaultColWidth: 25 },
    });

    const rows2 = [
      [
        "ID TIPO",
        "TIPO GASTO",
        "CUENTA DE GASTO",
        "CUENTA ESTADO RESULTADO",
      ],
      ...this.itemsImprimir,
      [],
    ];
    sheet.addRows(rows2);

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: "application/xlsx" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "TipoGastos " + fechaLocal() + ".xlsx";
    link.click();
  }


  //ITEMS PDF
  get itemsImprimir(): Array<Array<string | number>> {
    //let PrenominasRecorridas: Array<string> = [];
    return this.getArrayTipoCargos.map((gasto) => {
      const idTipo = gasto.idTipo;
      const nombre = gasto.nombre;
      const cuentaControlGasto = gasto.cuentaControlGasto;
      const cuentaEstadoResultado = gasto.cuentaEstadoResultado;

      return Object.values({
        idTipo,
        nombre,
        cuentaControlGasto,
        cuentaEstadoResultado,

      });
    });
  }

  async clickGuardar(data: DataFormularioTipoCargo): Promise<void> {
    this.cargando = true;
    const doc = data.doc;
    await this.setTipoCargo(doc);
    if (doc.id != this.tipoCargo?.id) {
      this.dialogoExito = {
        model: true,
        texto: "¡Banco creado con éxito!",
        icono: require("../assets/icons/double-check-duo.svg"),
      };
    }
    if (doc.id == this.tipoCargo?.id) {
      this.dialogoExito = {
        model: true,
        texto: "¡Banco editado con éxito!",
        icono: require("../assets/icons/editar.svg"),
      };
      this.tipoCargo = doc;
    }
    this.cerrarDialogoFormulario();
    this.cargando = false;
  }

  cerrarDialogoExito(): void {
    this.dialogoExito = {
      model: false,
      texto: "",
      icono: "",
    };
  }

  clickEliminar(): void {
    const tipoCargo = this.tipoCargo;
    if (!tipoCargo?.id) {
      console.error("error id: ", tipoCargo);
      return;
    }
    this.deleteTipoCargo(tipoCargo.id);
    this.tipoCargo = null;
    this.cerrarDialogoEliminar();
  }

  abrirDialogoInfo(tipoCargo: TipoCargo): void {
    if (!tipoCargo.id) {
      console.error("error id: ", tipoCargo);
      return;
    }
    const doc = this.tipoCargos[tipoCargo.id];
    if (doc) {
      this.tipoCargo = doc;
      this.dialogoInfo = true;
    }
  }

  cerrarDialogoInfo(): void {
    this.dialogoInfo = false;
    this.tipoCargo = null;
  }

  abrirDialogoFormulario(tipoCargo: TipoCargo): void {
    if (!tipoCargo.id) {
      console.error("error id: ", tipoCargo);
      return;
    }
    const doc = this.tipoCargos[tipoCargo.id];
    if (doc) {
      this.tipoCargo = doc;
      this.dialogoFormulario = true;
    }
  }

  abrirDialogoEliminar(tipoCargo: TipoCargo): void {
    if (!tipoCargo.id) {
      console.error("error id: ", tipoCargo);
      return;
    }
    const doc = this.tipoCargos[tipoCargo.id];
    if (doc) {
      this.dialogoEliminar = true;
      this.tipoCargo = doc;
    }
  }

  cerrarDialogoEliminar(): void {
    this.dialogoEliminar = false;
    this.tipoCargo = null;
  }

  cerrarDialogoFormulario(): void {
    this.dialogoFormulario = false;
    this.tipoCargo = null;
  }
}
