


























































































































































































































































































































// decoradores
import { Component, Prop, Vue } from "vue-property-decorator";
// vuex
import { mapActions, mapGetters } from "vuex";
// tipos
// componentes
import TablaCursos from "@/components/cursos/Tabla.vue";
import FormularioCurso from "@/components/cursos/Formulario.vue";
import InfoCurso from "@/components/cursos/Info.vue";
import BotonesCrud from "@/components/BotonesCrud.vue";
import MensajeEliminar from "@/components/MensajeEliminar.vue";
import TituloVista from "@/components/TituloVista.vue";
import CardCurso from "@/components/cursos/Card.vue";
import {
  Curso,
  Cursos,
  EstadoCurso,
} from "@/typings/store/plugins/easyFirestore/cursos";
import { Usuario } from "@/typings/store/plugins/easyFirestore/usuarios";
import { DataFormularioCurso } from "@/typings/components/cursos/formulario";
import { Tag, Tags } from "@/typings/store/plugins/easyFirestore/tags";
import { CategoriaCurso } from "@/typings/store/plugins/easyFirestore/categoriasCurso";
import { Sesion } from "@/typings/store/plugins/easyFirestore/sesiones";
import { DataFormularioSesion } from "@/typings/components/sesiones/formulario";
import RevisionCurso from "@/components/cursos/RevisionCurso.vue";
import { idtify } from "@/helpers/string";
import "@/helpers/extensions";
import { Pago } from "@/typings/store/plugins/easyFirestore/pagos";
import { Notificacion } from "@/typings/store/plugins/easyFirestore/notificaciones";
import { Inscripcion } from "@/typings/store/plugins/easyFirestore/inscripciones";
import Duplicar from "@/components/cursos/Duplicar.vue";
import DialogoExito from "@/components/DialogoExito.vue";
import { Archivos } from "@/typings/store/modules/archivos";

const routeGuard = (vm: Vue): boolean => {
  const permisos = vm.getUsuario?.permisos;
  if (permisos && permisos["cursos"]) {
    return true;
  }

  const rutas = vm.getRutasUsuario;
  if (rutas.length > 0) {
    const to = rutas[0].to;
    if (to) {
      if (vm.$route.name !== to.name) {
        vm.$router.replace(to);
      }
      return false;
    }
  }
  if (vm.$route.name !== "inicio") {
    vm.$router.replace({ name: "inicio" });
  }
  return false;
};

@Component({
  computed: mapGetters({
    getCursos: "cursos/get",
    getArrayCursos: "cursos/getArray",
    getArrayPagos: "pagos/getArray",
    getArrayUsuarios: "usuarios/getArray",
    getArrayCategoriasCurso: "categoriasCurso/getArray",
    getArraySesiones: "sesiones/getArray",
    getArrayInscripciones: "inscripciones/getArray",
    getTags: "tags/get",
    getArrayTags: "tags/getArray",
    getUsuario: "usuario/get",
    getRutasUsuario: "usuario/getRutas",
  }),
  methods: mapActions({
    setCurso: "cursos/set",
    setSesion: "sesiones/set",
    setArchivo: "archivos/set",
    setNotificacion: "notificaciones/set",
    deleteArchivo: "archivos/delete",
    deleteSesion: "sesiones/delete",
    deleteCurso: "cursos/delete",
  }),
  beforeRouteEnter(to, from, next) {
    next(routeGuard);
  },
  components: {
    DialogoExito,
    Duplicar,
    RevisionCurso,
    TablaCursos,
    FormularioCurso,
    InfoCurso,
    BotonesCrud,
    MensajeEliminar,
    TituloVista,
    CardCurso,
  },
})
export default class VistaCiudad extends Vue {
  @Prop() usuario!: Usuario;

  created(): void {
    if (!routeGuard(this)) {
      return;
    }
  }

  infoCompleta = false;
  dialogoExito = {
    model: false,
    icono: "",
    texto: "",
  };
  dialogoDuplicar: {
    model: boolean;
    curso: Curso | null;
  } = {
    model: false,
    curso: null,
  };
  search = "";
  estado: EstadoCurso | null = null;
  categoria = "";
  estados = [
    { text: "Pendiente", value: "pendiente" },
    { text: "Revisado", value: "revisado" },
    { text: "Aceptado", value: "aceptado" },
    { text: "Rechazado", value: "rechazado" },
    { text: "Impartiendo", value: "impartiendo" },
    { text: "Terminado", value: "terminado" },
  ];
  fechas: Array<string> = [];
  dialogRevisionCurso = false;
  curso: Curso | null = null;
  dialogoInfo = false;
  dialogoFormulario = false;
  dialogoEliminar = false;
  relator: Usuario | null = null;
  menu = false;
  cargando = false;
  duplicar = false;

  get fechasMostrar(): string {
    return this.fechas
      .map((fecha) => fecha.toDate().format("DD-MM-YYYY"))
      .join(" ~ ");
  }

  set fechasMostrar(array: string) {
    this.fechas = array ? [] : [];
  }

  get cursos(): Cursos {
    return this.getCursos;
  }

  get relatores(): Array<Usuario> {
    return this.getArrayUsuarios.filter(
      (usuario) => usuario.tipo === "relator" && usuario.estado === "libre"
    );
  }

  get arrayInscripciones(): Array<Inscripcion> {
    return this.getArrayInscripciones;
  }

  get arrayPagos(): Pago[] {
    return this.getArrayPagos;
  }

  get arrayCategoriasCurso(): Array<CategoriaCurso> {
    return this.getArrayCategoriasCurso;
  }

  get arraySesiones(): Array<Sesion> {
    return this.getArraySesiones;
  }

  get tags(): Tags {
    return this.getTags;
  }

  get arrayTags(): Array<Tag> {
    return this.getArrayTags;
  }

  get items(): Array<Curso> {
    let estados = [
      "revisado",
      "pendiente",
      "aceptado",
      "impartiendo",
      "rechazado",
      "terminado",
    ];
    return this.getArrayCursos
      .filter((curso) => {
        const estado = this.estado ? curso.estado === this.estado : true;
        const categoria = this.categoria
          ? curso.categoria.nombre === this.categoria
          : true;
        const relator = this.relator
          ? curso.relator.id === this.relator.id
          : this.usuario.tipo === "relator"
          ? curso.relator.id === this.usuario.id
          : true;
        const fechaInicio = this.fechas.firstOrNull?.toDate();
        const fechaFin = this.fechas.lastOrNull?.toDate();
        const fecha =
          this.fechas && fechaInicio && fechaFin
            ? curso.inicio.toDate().getTime() >= fechaInicio.getTime() &&
              curso.inicio.toDate().getTime() <= fechaFin.getTime()
            : true;
        const buscar =
          this.search && this.usuario.tipo !== "relator"
            ? `${curso.nombre} ${curso.relator.nombre} ${curso.resumen} ${curso.categoria.nombre}`
                .toLowerCase()
                .includes(this.search)
            : true;
        return estado && categoria && relator && fecha && buscar;
      })
      .sort((a, b) => {
        const indexA = estados.indexOf(a.estado);
        const indexB = estados.indexOf(b.estado);
        return indexA - indexB;
      });
  }

  get cursosSinAprobar(): Array<Curso> {
    return this.getArrayCursos.filter((curso) => curso.estado === "pendiente");
  }

  cerrarDialogoDuplicar(): void {
    this.dialogoDuplicar = {
      model: false,
      curso: null,
    };
  }

  abrirDialogoDuplicar(curso: Curso): void {
    this.curso = curso;
    this.duplicar = true;
    this.dialogoFormulario = true;
  }

  // duplicarCurso(curso: Curso): void {
  //   this.clickGuardar({
  //     data: {
  //       doc: curso,
  //     },
  //     dataSesiones: null,
  //     dataSesionesEliminadas: null,
  //   });
  //   this.cerrarDialogoDuplicar();
  // }

  clickItem(val: { id: string } | null): void {
    this.curso = val ? this.cursos[val.id] : null;
  }

  clickPlus(): void {
    this.curso = null;
    this.dialogoFormulario = true;
  }

  async clickGuardarNotiCurso(data: {
    imagen: Archivos;
    curso: Curso;
    notificacion: Notificacion;
  }): Promise<void> {
    const imagen = data.imagen;
    data.curso.imagen = imagen ?? {};
    for (const key in imagen) {
      if (imagen[key].file) {
        const doc = imagen[key];
        const res = await this.setArchivo(doc);
        if (res && res.url) {
          this.$set(imagen, idtify(res.id), res);
        }
      }
    }
    this.setCurso(data.curso);
    this.setNotificacion(data.notificacion);
    this.dialogRevisionCurso = false;
    this.dialogoExito = {
      model: true,
      icono: require("../assets/icons/double-check-duo.svg"),
      texto: "Se ha notificado al relator",
    };
  }

  async clickGuardarEstado(data: DataFormularioCurso): Promise<void> {
    const imagen = data.imagen;
    data.doc.imagen = imagen ?? {};
    for (const key in imagen) {
      if (imagen[key].file) {
        const doc = imagen[key];
        const res = await this.setArchivo(doc);
        if (res && res.url) {
          this.$set(imagen, idtify(res.id), res);
        }
      }
    }

    this.setCurso(data.doc);
    let titulo = "";
    if (data.doc.estado === "aceptado") {
      titulo = `Se ha aprobado tu curso ${data.doc.nombre}`;
    }
    if (titulo) {
      const notificacion: Notificacion = {
        curso: data.doc,
        titulo: titulo,
        contenido: "Tu curso está listo para impartir!",
        tipo: "curso-aprobado",
        fecha: new Date().toISOString(),
        image: null,
        usuario: data.doc.relator,
        topico: null,
        enviada: false,
      };
      this.setNotificacion(notificacion);
    }
    this.dialogRevisionCurso = false;
    this.dialogoExito = {
      model: true,
      icono: require("../assets/icons/double-check-duo.svg"),
      texto: "Has guardado el curso con éxito",
    };
  }

  clickGuardarSesiones(sesiones: Sesion[]): void {
    for (const sesion of sesiones) {
      this.setSesion(sesion);
    }
  }

  async clickGuardar(dataObj: {
    data: DataFormularioCurso;
    dataSesiones: Array<DataFormularioSesion> | null;
    dataSesionesEliminadas: Array<DataFormularioSesion> | null;
  }): Promise<void> {
    const doc = dataObj.data.doc;
    const docSesiones = dataObj.dataSesiones;
    const docSesionesEliminadas = dataObj.dataSesionesEliminadas;
    const imagen = dataObj.data.imagen;
    const borrar = dataObj.data.borrar;
    const invitados = doc.invitados?.slice();
    if (invitados) {
      for (const invitado of invitados) {
        if (invitado.imagen?.file) {
          const res = await this.setArchivo(invitado.imagen);
          if (res && res.url) {
            this.$set(invitado, "imagen", res);
          }
        }
      }
    }
    doc.invitados = invitados;
    doc.imagen = imagen ?? {};
    for (const key in imagen) {
      if (imagen[key].file) {
        const doc = imagen[key];
        const res = await this.setArchivo(doc);
        if (res && res.url) {
          this.$set(imagen, idtify(res.id), res);
        }
      }
    }
    for (const key in borrar) {
      if (borrar[key]) {
        const doc = borrar[key];
        await this.deleteArchivo(doc.id);
      }
    }
    doc.id = await this.setCurso(doc);
    for (const sesion of docSesiones ?? ([] as Array<DataFormularioSesion>)) {
      sesion.curso = doc;
      if (sesion.curso) {
        await this.setSesion(sesion as Sesion);
      }
    }
    for (const sesion of docSesionesEliminadas ??
      ([] as Array<DataFormularioSesion>)) {
      if (sesion.id) {
        await this.deleteSesion(sesion.id);
      }
    }
    const notificacion: Notificacion = {
      curso: doc,
      titulo: "Se ha creado un nuevo curso",
      contenido: `El relator ${doc.relator.nombre} ha creado el curso ${doc.nombre}`,
      tipo: "curso-creado",
      fecha: new Date().toISOString(),
      image: null,
      usuario: null,
      topico: "admins",
      enviada: false,
    };
    await this.setNotificacion(notificacion);

    if (doc.id != this.curso?.id) {
      this.dialogoExito = {
        model: true,
        icono: require("../assets/icons/double-check-duo.svg"),
        texto: "Has creado el curso",
      };
    }
    if (doc.id == this.curso?.id) {
      this.dialogoExito = {
        model: true,
        icono: require("../assets/icons/double-check-duo.svg"),
        texto: "Has editado el curso",
      };
      this.curso = doc;
    }
    this.cerrarDialogoFormulario();
  }

  clickEliminar(): void {
    const curso = this.curso;
    if (!curso?.id) {
      console.error("error id: ", curso);
      return;
    }
    for (const sesion of this.arraySesiones) {
      if (sesion.curso.id === curso.id && sesion && sesion.id) {
        this.deleteSesion(sesion.id);
      }
    }
    this.deleteCurso(curso.id);
    this.curso = null;
    this.dialogoExito = {
      model: true,
      icono: require("../assets/icons/cancelar-duo.svg"),
      texto: "Has eliminado el curso",
    };
    this.cerrarDialogoEliminar();
  }

  abrirDialogoInfo(curso: Curso): void {
    if (!curso.id) {
      console.error("error id: ", curso);
      return;
    }
    const doc = this.cursos[curso.id];
    if (doc) {
      this.curso = doc;
      this.dialogoInfo = true;
    }
  }

  abrirDialogoRevision(curso: Curso): void {
    if (!curso.id) {
      console.error("error id: ", curso);
      return;
    }
    const doc = this.cursos[curso.id];
    if (doc) {
      this.curso = doc;
      this.dialogRevisionCurso = true;
    }
  }

  abrirDialogoInfoCompleta(curso: Curso): void {
    const id = curso.id ?? "";
    if (this.$route.name != "informacionCompleta" || this.$route.name !== id) {
      this.$router.push({
        name: "informacionCompleta",
        params: { id: id },
      });
    }
  }

  cerrarDialogoInfo(): void {
    this.dialogoInfo = false;
    this.curso = null;
  }

  cerrarDialogoRevision(): void {
    this.dialogRevisionCurso = false;
    this.curso = null;
  }

  abrirDialogoFormulario(curso: Curso): void {
    if (!curso.id) {
      console.error("error id: ", curso);
      return;
    }
    const doc = this.cursos[curso.id];
    if (doc) {
      this.curso = doc;
      this.dialogoFormulario = true;
    }
  }

  cerrarDialogoFormulario(): void {
    this.dialogoFormulario = false;
    this.curso = null;
  }

  abrirDialogoEliminar(curso: Curso): void {
    if (!curso.id) {
      console.error("error id: ", curso);
      return;
    }
    const doc = this.cursos[curso.id];
    if (doc) {
      this.dialogoEliminar = true;
      this.curso = doc;
    }
  }

  cerrarDialogoExito(): void {
    this.dialogoExito = {
      model: false,
      icono: "",
      texto: "",
    };
  }

  cerrarInfoCompleta(): void {
    this.curso = null;
    this.infoCompleta = false;
  }

  cerrarDialogoEliminar(): void {
    this.dialogoEliminar = false;
    this.curso = null;
  }
}
