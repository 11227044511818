

































































// decoradores
import { Vue, Component } from "vue-property-decorator";
// vuex
import { mapActions, mapGetters } from "vuex";
// tipos

// componentes
import TablaCategoriasCurso from "@/components/categoriasCurso/Tabla.vue";
import FormularioCategoriaCurso from "@/components/categoriasCurso/Formulario.vue";
import InfoCategoriaCurso from "@/components/categoriasCurso/Info.vue";
import BotonesCrud from "@/components/BotonesCrud.vue";
import MensajeEliminar from "@/components/MensajeEliminar.vue";
import TituloVista from "@/components/TituloVista.vue";
import {
  CategoriaCurso,
  CategoriasCurso,
} from "@/typings/store/plugins/easyFirestore/categoriasCurso";
import { DataFormularioCategoriaCurso } from "@/typings/components/categoriasCurso/formulario";

const routeGuard = (vm: Vue): boolean => {
  const permisos = vm.getUsuario?.permisos;
  if (permisos && permisos["categoriasCurso"]) {
    return true;
  }

  const rutas = vm.getRutasUsuario;
  if (rutas.length > 0) {
    const to = rutas[0].to;
    if (to) {
      if (vm.$route.name !== to.name) {
        vm.$router.replace(to);
      }
      return false;
    }
  }
  if (vm.$route.name !== "inicio") {
    vm.$router.replace({ name: "inicio" });
  }
  return false;
};

@Component({
  computed: mapGetters({
    getCategoriasCurso: "categoriasCurso/get",
    getArrayCategoriasCurso: "categoriasCurso/getArray",
    getUsuario: "usuario/get",
    getRutasUsuario: "usuario/getRutas",
  }),
  methods: mapActions({
    setCategoriaCurso: "categoriasCurso/set",
    deleteCategoriaCurso: "categoriasCurso/delete",
  }),
  beforeRouteEnter(to, from, next) {
    next(routeGuard);
  },
  components: {
    TablaCategoriasCurso,
    FormularioCategoriaCurso,
    InfoCategoriaCurso,
    BotonesCrud,
    MensajeEliminar,
    TituloVista,
  },
})
export default class VistaCiudad extends Vue {
  created(): void {
    if (!routeGuard(this)) {
      return;
    }
  }

  search = "";
  categoriaCurso: CategoriaCurso | null = null;
  dialogoInfo = false;
  dialogoFormulario = false;
  dialogoEliminar = false;

  get categoriasCurso(): CategoriasCurso {
    return this.getCategoriasCurso;
  }

  get items(): Array<CategoriaCurso> {
    return this.getArrayCategoriasCurso;
  }

  clickItem(val: { id: string } | null): void {
    this.categoriaCurso = val ? this.categoriasCurso[val.id] : null;
  }

  clickPlus(): void {
    this.categoriaCurso = null;
    this.dialogoFormulario = true;
  }

  async clickGuardar(data: DataFormularioCategoriaCurso): Promise<void> {
    const doc = data.doc;
    this.setCategoriaCurso(doc);
    if (doc.id == this.categoriaCurso?.id) {
      this.categoriaCurso = doc;
    }
    this.cerrarDialogoFormulario();
  }

  clickEliminar(): void {
    const categoriaCurso = this.categoriaCurso;
    if (!categoriaCurso?.id) {
      console.error("error id: ", categoriaCurso);
      return;
    }
    this.deleteCategoriaCurso(categoriaCurso.id);
    this.categoriaCurso = null;
    this.cerrarDialogoEliminar();
  }

  abrirDialogoInfo(categoriaCurso: CategoriaCurso): void {
    if (!categoriaCurso.id) {
      console.error("error id: ", categoriaCurso);
      return;
    }
    const doc = this.categoriasCurso[categoriaCurso.id];
    if (doc) {
      this.categoriaCurso = doc;
      this.dialogoInfo = true;
    }
  }

  cerrarDialogoInfo(): void {
    this.dialogoInfo = false;
    this.categoriaCurso = null;
  }

  abrirDialogoFormulario(categoriaCurso: CategoriaCurso): void {
    if (!categoriaCurso.id) {
      console.error("error id: ", categoriaCurso);
      return;
    }
    const doc = this.categoriasCurso[categoriaCurso.id];
    if (doc) {
      this.categoriaCurso = doc;
      this.dialogoFormulario = true;
    }
  }

  cerrarDialogoFormulario(): void {
    this.dialogoFormulario = false;
    this.categoriaCurso = null;
  }

  abrirDialogoEliminar(categoriaCurso: CategoriaCurso): void {
    if (!categoriaCurso.id) {
      console.error("error id: ", categoriaCurso);
      return;
    }
    const doc = this.categoriasCurso[categoriaCurso.id];
    if (doc) {
      this.dialogoEliminar = true;
      this.categoriaCurso = doc;
    }
  }

  cerrarDialogoEliminar(): void {
    this.dialogoEliminar = false;
    this.categoriaCurso = null;
  }
}
