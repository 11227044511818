

























































































































































// decoradores
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { Vacancia } from "@/typings/store/plugins/easyFirestore/vacancias";
import SVGIcon from "@/components/SVGIcon.vue";
// tipos

// tipos
@Component({
  components: {
    SVGIcon,
  },
})
export default class InfoBanco extends Vue {
  @Prop() readonly vacancia!: Vacancia;
  @Prop({ type: Boolean }) readonly showTitulo!: boolean;

  get id(): string {
    return this.vacancia.id || "N/R";
  }

  get centroCosto(): string {
    return this.vacancia.centroCosto || "N/R";
  }

  get direccion(): string {
    return this.vacancia.direccion || "N/R";
  }

  get local(): string {
    return this.vacancia.local || "N/R";
  }

  get superficie(): string {
    return this.vacancia.superficie || "N/R";
  }

  get ocupado(): string {
    return this.vacancia.ocupado || "N/R";
  }

  get fInicio(): string {
    return this.vacancia.fInicio || "N/R";
  }

  get fFin(): string {
    return this.vacancia.fFin || "N/R";
  }

  get precioUf(): string {
    return this.vacancia.precioUf || "N/R";
  }

  get nivel(): string {
    return this.vacancia.nivel || "N/R";
  }

  get rutLocatario(): string {
    return this.vacancia.rutLocatario || "N/R";
  }

  get razonSocial(): string {
    return this.vacancia.razonSocial || "N/R";
  }

  get telefonoContacto(): string {
    return this.vacancia.telefonoContacto || "N/R";
  }

  get periodo(): string {
    return this.vacancia.periodo || "N/R";
  }

  get url(): string {
    return this.vacancia.url || "N/R";
  }

  get imagen(): string {
    return this.vacancia.imagen || "N/R";
  }

  @Emit("cerrar")
  onCerrar(): void {
    return;
  }
}
