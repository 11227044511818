
















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { idtify } from "@/helpers/string";
import { Orden } from "@/typings/store/plugins/easyFirestore/ordenes";
import { Proveedor } from "@/typings/store/plugins/easyFirestore/proveedores";
import { Ciudad } from "@/typings/store/plugins/easyFirestore/ciudades";
import { Banco } from "@/typings/store/plugins/easyFirestore/bancos";
import { required } from "vuelidate/lib/validators";
import { DataFormularioOrden } from "@/typings/components/ordenes/formulario";
import SVGIcon from "@/components/SVGIcon.vue";
import { Archivo, Archivos } from "@/typings/store/modules/archivos";
import VistaPreviaPdf from "@/components/VistaPreviaPdf.vue";
import { RegistrosEgresos } from "@/typings/store/plugins/easyFirestore/registrosEgresos";
import { MapeoGasto } from "@/typings/store/plugins/easyFirestore/MapeoGastos";
import { Prenomina } from "@/typings/store/plugins/easyFirestore/prenominas";
import { DataFormularioPrenomina } from "@/typings/components/prenominas/formulario";
import { DataFormularioRegistrosEgresos } from "@/typings/components/registrosEgresos/formulario";

@Component({
  components: {
    SVGIcon,
    VistaPreviaPdf,
  },
  validations: {
    nombre: { required },
    // posicion: { required },
    empresa: { required },
    observaciones: { required },
    divisa: { required },
    direccion: { required },
    cantidad_1: { required },
    unidadMedida_1: { required },
    descripcion_1: { required },
    centroCosto_1: { required },
    precioUnitario_1: { required },
    tipoOrden: { required },
  },
})
export default class FormularioOrden extends Vue {
  @Prop() readonly orden!: Orden | null;
  @Prop() readonly prenomina!: Prenomina | null;
  @Prop() readonly proveedores!: Proveedor[];
  @Prop() readonly ciudades!: Ciudad[];
  @Prop() readonly cargando!: boolean;
  @Prop() readonly cantidadOrdenes!: number;
  @Prop() readonly bancos!: Banco[];
  @Prop() readonly mapeoGastos!: MapeoGasto[];
  @Prop() readonly prenominas!: Prenomina[];
  //   @Prop() readonly rutas!: Rutas;
  //   @Prop() readonly ciudades!: Ciudad[];
  //   @Prop() readonly cargando!: boolean;
  //   @Prop() readonly regiones!: Region[];

  created(): void {
    // const prenomina = this.prenomina;
    // if (!prenomina) {
    //   return;
    // }
    // this.prenomina_id = prenomina.id ?? 0;
    // this.prenomina_numeroPrenominas = prenomina.numeroPrenominas ?? 0;
    // this.prenomina_rut = prenomina.rut ?? "";
    // this.prenomina_nombreProveedor = prenomina.nombreProveedor ?? "";
    // this.prenomina_nFactura = prenomina.nFactura ?? "";
    // this.prenomina_ordenCompra = prenomina.ordenCompra ?? "";
    // this.prenomina_centroCosto = prenomina.centroCosto;
    // this.prenomina_montoOC = prenomina.montoOC;
    // this.prenomina_porcentajePagado = prenomina.porcentajePagado ?? "";
    // this.prenomina_montoSaldo = prenomina.montoSaldo;
    // this.prenomina_montoAP = prenomina.montoAP;
    // this.prenomina_archivo = Object.values(prenomina.archivo ?? {})[0] ?? null;
    // this.prenomina_observacion = prenomina.observacion ?? "";
    // this.prenomina_formaPago = prenomina.formaPago ?? "";
    // this.prenomina_numeroCheque = prenomina.numeroCheque ?? "";
    // this.prenomina_tipoGasto = prenomina.tipoGasto;
    // this.prenomina_banco = prenomina.banco ?? "";
    // this.prenomina_periodo =
    //   prenomina.periodo.toDate().format("YYYY-MM-DD") ?? "";
    // this.prenomina_recurrente = prenomina.recurrente ?? "";
    // this.prenomina_tipoRecurrencia = prenomina.tipoRecurrencia ?? "";
    // this.prenomina_periodoRecurrencia = prenomina.periodoRecurrencia ?? "";
    // if (prenomina.periodoRecurrencia) {
    //   this.prenomina_recurrenteMes =
    //     prenomina.periodoRecurrencia.substr(3, 2) ?? "";
    //   this.prenomina_recurrenteAnual =
    //     prenomina.periodoRecurrencia.substr(6) ?? "";
    // }
    // this.prenomina_color = prenomina.color ?? "";

    // Object.keys(this.rutas).forEach((key) => {
    //   this.$set(this.permisos, key, false);
    // });
    // console.log(this.permisos);
    const orden = this.orden;
    if (!orden) {
      return;
    }
    this.id = orden.id ?? "";
    this.nombre = orden.nombre;
    // this.posicion = orden.posicion ?? "";
    //
    this.ordenCompra = orden.ordenCompra ?? 0;
    this.fechaEmision = orden.fechaEmision ?? "";
    this.estado = orden.estado;
    this.autorizado = orden.autorizado ?? "";
    // Datos de carga autmatica
    this.subTotal = orden.subTotal ?? 0;
    this.afectoIVA = orden.afectoIVA ?? 0;
    this.exentoIVA = orden.exentoIVA ?? 0;
    this.subTotalNeto = orden.subTotalNeto ?? 0;
    this.IVA = orden.IVA ?? 0;
    this.total = orden.total ?? 0;
    // cambiar
    this.tipoOrden = orden.tipoOrden ?? "";
    this.cantidad_1 = orden.cantidad_1 ?? 0;
    this.cantidad_2 = orden.cantidad_2 ?? 0;
    this.cantidad_3 = orden.cantidad_3 ?? 0;
    this.cantidad_4 = orden.cantidad_4 ?? 0;
    this.cantidad_5 = orden.cantidad_5 ?? 0;
    this.cantidad_6 = orden.cantidad_6 ?? 0;
    this.cantidad_7 = orden.cantidad_7 ?? 0;
    this.cantidad_8 = orden.cantidad_8 ?? 0;
    this.cantidad_9 = orden.cantidad_9 ?? 0;
    this.cantidad_10 = orden.cantidad_10 ?? 0;
    this.cantidad_11 = orden.cantidad_11 ?? 0;
    this.cantidad_12 = orden.cantidad_12 ?? 0;
    this.cantidad_13 = orden.cantidad_13 ?? 0;
    this.cantidad_14 = orden.cantidad_14 ?? 0;
    this.cantidad_15 = orden.cantidad_15 ?? 0;
    this.cantidad_16 = orden.cantidad_16 ?? 0;
    this.unidadMedida_1 = orden.unidadMedida_1 ?? "";
    this.unidadMedida_2 = orden.unidadMedida_2 ?? "";
    this.unidadMedida_3 = orden.unidadMedida_3 ?? "";
    this.unidadMedida_4 = orden.unidadMedida_4 ?? "";
    this.unidadMedida_5 = orden.unidadMedida_5 ?? "";
    this.unidadMedida_6 = orden.unidadMedida_6 ?? "";
    this.unidadMedida_7 = orden.unidadMedida_7 ?? "";
    this.unidadMedida_8 = orden.unidadMedida_8 ?? "";
    this.unidadMedida_9 = orden.unidadMedida_9 ?? "";
    this.unidadMedida_10 = orden.unidadMedida_10 ?? "";
    this.unidadMedida_11 = orden.unidadMedida_11 ?? "";
    this.unidadMedida_12 = orden.unidadMedida_12 ?? "";
    this.unidadMedida_13 = orden.unidadMedida_13 ?? "";
    this.unidadMedida_14 = orden.unidadMedida_14 ?? "";
    this.unidadMedida_15 = orden.unidadMedida_15 ?? "";
    this.unidadMedida_16 = orden.unidadMedida_16 ?? "";
    this.descripcion_1 = orden.descripcion_1 ?? "";
    this.descripcion_2 = orden.descripcion_2 ?? "";
    this.descripcion_3 = orden.descripcion_3 ?? "";
    this.descripcion_4 = orden.descripcion_4 ?? "";
    this.descripcion_5 = orden.descripcion_5 ?? "";
    this.descripcion_6 = orden.descripcion_6 ?? "";
    this.descripcion_7 = orden.descripcion_7 ?? "";
    this.descripcion_8 = orden.descripcion_8 ?? "";
    this.descripcion_9 = orden.descripcion_9 ?? "";
    this.descripcion_10 = orden.descripcion_10 ?? "";
    this.descripcion_11 = orden.descripcion_11 ?? "";
    this.descripcion_12 = orden.descripcion_12 ?? "";
    this.descripcion_13 = orden.descripcion_13 ?? "";
    this.descripcion_14 = orden.descripcion_14 ?? "";
    this.descripcion_15 = orden.descripcion_15 ?? "";
    this.descripcion_16 = orden.descripcion_16 ?? "";
    this.centroCosto_1 = orden.centroCosto_1 ?? "";
    this.centroCosto_2 = orden.centroCosto_2 ?? "";
    this.centroCosto_3 = orden.centroCosto_3 ?? "";
    this.centroCosto_4 = orden.centroCosto_4 ?? "";
    this.centroCosto_5 = orden.centroCosto_5 ?? "";
    this.centroCosto_6 = orden.centroCosto_6 ?? "";
    this.centroCosto_7 = orden.centroCosto_7 ?? "";
    this.centroCosto_8 = orden.centroCosto_8 ?? "";
    this.centroCosto_9 = orden.centroCosto_9 ?? "";
    this.centroCosto_10 = orden.centroCosto_10 ?? "";
    this.centroCosto_11 = orden.centroCosto_11 ?? "";
    this.centroCosto_12 = orden.centroCosto_12 ?? "";
    this.centroCosto_13 = orden.centroCosto_13 ?? "";
    this.centroCosto_14 = orden.centroCosto_14 ?? "";
    this.centroCosto_15 = orden.centroCosto_15 ?? "";
    this.centroCosto_16 = orden.centroCosto_16 ?? "";
    this.precioUnitario_1 = orden.precioUnitario_1 ?? 0;
    this.precioUnitario_2 = orden.precioUnitario_2 ?? 0;
    this.precioUnitario_3 = orden.precioUnitario_3 ?? 0;
    this.precioUnitario_4 = orden.precioUnitario_4 ?? 0;
    this.precioUnitario_5 = orden.precioUnitario_5 ?? 0;
    this.precioUnitario_6 = orden.precioUnitario_6 ?? 0;
    this.precioUnitario_7 = orden.precioUnitario_7 ?? 0;
    this.precioUnitario_8 = orden.precioUnitario_8 ?? 0;
    this.precioUnitario_9 = orden.precioUnitario_9 ?? 0;
    this.precioUnitario_10 = orden.precioUnitario_10 ?? 0;
    this.precioUnitario_11 = orden.precioUnitario_11 ?? 0;
    this.precioUnitario_12 = orden.precioUnitario_12 ?? 0;
    this.precioUnitario_13 = orden.precioUnitario_13 ?? 0;
    this.precioUnitario_14 = orden.precioUnitario_14 ?? 0;
    this.precioUnitario_15 = orden.precioUnitario_15 ?? 0;
    this.precioUnitario_16 = orden.precioUnitario_16 ?? 0;
    this.precioTotal_1 = orden.precioTotal_1 ?? 0;
    this.precioTotal_2 = orden.precioTotal_2 ?? 0;
    this.precioTotal_3 = orden.precioTotal_3 ?? 0;
    this.precioTotal_4 = orden.precioTotal_4 ?? 0;
    this.precioTotal_5 = orden.precioTotal_5 ?? 0;
    this.precioTotal_6 = orden.precioTotal_6 ?? 0;
    this.precioTotal_7 = orden.precioTotal_7 ?? 0;
    this.precioTotal_8 = orden.precioTotal_8 ?? 0;
    this.precioTotal_9 = orden.precioTotal_9 ?? 0;
    this.precioTotal_10 = orden.precioTotal_10 ?? 0;
    this.precioTotal_11 = orden.precioTotal_11 ?? 0;
    this.precioTotal_12 = orden.precioTotal_12 ?? 0;
    this.precioTotal_13 = orden.precioTotal_13 ?? 0;
    this.precioTotal_14 = orden.precioTotal_14 ?? 0;
    this.precioTotal_15 = orden.precioTotal_15 ?? 0;
    this.precioTotal_16 = orden.precioTotal_16 ?? 0;
    // Datos de carga manual
    this.centroCosto = orden.centroCosto ?? "";
    this.divisa = orden.divisa ?? "";
    this.empresa = orden.empresa ?? "";
    this.rut = orden.rut ?? "";
    this.direccion = orden.direccion ?? "";
    this.comuna = orden.comuna ?? "";
    this.telefono = orden.telefono ?? "";
    this.contacto = orden.contacto ?? "";
    this.formaPago = orden.formaPago ?? "";
    this.correo = orden.correo ?? "";
    // servicios = orden. ?? "";
    this.observaciones = orden.observaciones ?? "";
    this.descuento = orden.descuento ?? 0;
    this.tipoDescuento = orden.tipoDescuento ?? "";
    this.destinatario = orden.destinatario ?? "";
    this.rutDestinatario = orden.rutDestinatario ?? "";
    this.banco = orden.banco ?? "";
    this.tipoCueta = orden.tipoCueta ?? "";
    this.nroCuenta = orden.nroCuenta ?? "";
    this.correoDestinatario = orden.correoDestinatario ?? "";

    this.fechaUf = orden.fechaUf ?? "";
    this.solicitante = orden.solicitante ?? "";
    this.motivoAnulacion = orden.motivoAnulacion ?? "";
    this.archivo = Object.values(orden.archivo ?? {})[0] ?? null;
    this.archivo2 = Object.values(orden.archivo2 ?? {})[0] ?? null;
    this.archivo3 = Object.values(orden.archivo3 ?? {})[0] ?? null;
    this.archivo4 = Object.values(orden.archivo4 ?? {})[0] ?? null;
    this.archivo5 = Object.values(orden.archivo5 ?? {})[0] ?? null;
    this.archivo6 = Object.values(orden.archivo6 ?? {})[0] ?? null;

    // this.archivo1 = orden.archivo1 ?? "";
    // this.archivo3 = orden.archivo3 ?? "";
    // this.archivo4 = orden.archivo4 ?? "";
    this.tipoArchivo = orden.tipoArchivo ?? "";
    this.tipoArchivo2 = orden.tipoArchivo2 ?? "";
    this.tipoArchivo3 = orden.tipoArchivo3 ?? "";
    this.tipoArchivo4 = orden.tipoArchivo4 ?? "";
    this.tipoArchivo5 = orden.tipoArchivo5 ?? "";
    this.tipoArchivo6 = orden.tipoArchivo6 ?? "";

    this.color = orden.color ?? "";

    this.observacion = orden.observacion ?? "";
    this.tipoGasto = orden.tipoGasto ?? "";
    this.observante = orden.observante ?? "";

    this.respuesta = orden.respuesta ?? "";
    this.respondedor = orden.respondedor ?? "";

    //this.prenomina_montoOC = orden.subTotalNeto.toString() ?? "";
    this.prenomina_nFactura = orden.nFactura ?? "";
    this.prenomina_periodo = orden.periodo.toDate().format("YYYY-MM-DD") ?? "";
    this.prenomina_recurrente = orden.recurrente ?? "";
    this.prenomina_tipoRecurrencia = orden.tipoRecurrencia ?? "";
    this.prenomina_periodoRecurrencia = orden.periodo ?? "";
    this.prenomina_montoSaldo = orden.montoSaldo ?? "";
    this.prenomina_montoAP = orden.montoAP ?? "";
    this.prenomina_numeroCheque = orden.numeroCheque ?? "";

    // if (this.tipoArchivo) {
    //   this.subidoTipoArchivo = "SI";
    // } else {
    //   this.subidoTipoArchivo = "NO";
    // }

    // if (this.tipoArchivo2) {
    //   this.subidoTipoArchivo2 = "SI";
    // } else {
    //   this.subidoTipoArchivo2 = "NO";
    // }
    this.codigoCuenta = orden.codigoCuenta ?? "";
    this.descripcionCuenta = orden.descripcionCuenta ?? "";

    this.tipoGastoCorrecto = orden.tipoGastoCorrecto ?? "";
    this.cuentaSoftlandCorrecto = orden.cuentaSoftlandCorrecto ?? "";
    this.fechaVencimiento =
      orden.fechaVencimiento.toDate().format("YYYY-MM-DD") ?? "";
  }

  options = {
    locale: "es-CL",
    prefix: "$",
    suffix: "",
    length: 18,
    precision: 0,
  };

  optionsUF = {
    locale: "es-CL",
    prefix: "",
    suffix: "UF",
    length: 18,
    precision: 2,
  };

  optionsPorc = {
    locale: "es-CL",
    prefix: "",
    suffix: "%",
    length: 3,
    precision: 0,
  };

  id: string | null = null;

  nombre = "";
  posicion = "";
  tipoOrden = "";
  //
  // Datos de carga autmatica
  ordenCompra = 0;
  fechaEmision = "";
  // estado: "pendiente" | "aprobado" | "rechazado" = "pendiente";
  estado = "";
  autorizado = "";
  // Datos de carga autmatica
  subTotal = 0;
  afectoIVA = 0;
  exentoIVA = 0;
  subTotalNeto = 0;
  IVA = 0;
  total = 0;
  // cambiar
  //servicios = "";
  // Datos de carga manual
  centroCosto = "";
  divisa = "";
  empresa = "";
  rut = "";
  direccion = "";
  comuna = "";
  telefono = "";
  contacto = "";
  formaPago = "";
  correo = "";
  // servicios = "";
  cantidad_1 = 0;
  cantidad_2 = 0;
  cantidad_3 = 0;
  cantidad_4 = 0;
  cantidad_5 = 0;
  cantidad_6 = 0;
  cantidad_7 = 0;
  cantidad_8 = 0;
  cantidad_9 = 0;
  cantidad_10 = 0;
  cantidad_11 = 0;
  cantidad_12 = 0;
  cantidad_13 = 0;
  cantidad_14 = 0;
  cantidad_15 = 0;
  cantidad_16 = 0;
  unidadMedida_1 = "";
  unidadMedida_2 = "";
  unidadMedida_3 = "";
  unidadMedida_4 = "";
  unidadMedida_5 = "";
  unidadMedida_6 = "";
  unidadMedida_7 = "";
  unidadMedida_8 = "";
  unidadMedida_9 = "";
  unidadMedida_10 = "";
  unidadMedida_11 = "";
  unidadMedida_12 = "";
  unidadMedida_13 = "";
  unidadMedida_14 = "";
  unidadMedida_15 = "";
  unidadMedida_16 = "";
  descripcion_1 = "";
  descripcion_2 = "";
  descripcion_3 = "";
  descripcion_4 = "";
  descripcion_5 = "";
  descripcion_6 = "";
  descripcion_7 = "";
  descripcion_8 = "";
  descripcion_9 = "";
  descripcion_10 = "";
  descripcion_11 = "";
  descripcion_12 = "";
  descripcion_13 = "";
  descripcion_14 = "";
  descripcion_15 = "";
  descripcion_16 = "";
  centroCosto_1 = "";
  centroCosto_2 = "";
  centroCosto_3 = "";
  centroCosto_4 = "";
  centroCosto_5 = "";
  centroCosto_6 = "";
  centroCosto_7 = "";
  centroCosto_8 = "";
  centroCosto_9 = "";
  centroCosto_10 = "";
  centroCosto_11 = "";
  centroCosto_12 = "";
  centroCosto_13 = "";
  centroCosto_14 = "";
  centroCosto_15 = "";
  centroCosto_16 = "";
  precioUnitario_1 = 0;
  precioUnitario_2 = 0;
  precioUnitario_3 = 0;
  precioUnitario_4 = 0;
  precioUnitario_5 = 0;
  precioUnitario_6 = 0;
  precioUnitario_7 = 0;
  precioUnitario_8 = 0;
  precioUnitario_9 = 0;
  precioUnitario_10 = 0;
  precioUnitario_11 = 0;
  precioUnitario_12 = 0;
  precioUnitario_13 = 0;
  precioUnitario_14 = 0;
  precioUnitario_15 = 0;
  precioUnitario_16 = 0;
  precioTotal_1 = 0;
  precioTotal_2 = 0;
  precioTotal_3 = 0;
  precioTotal_4 = 0;
  precioTotal_5 = 0;
  precioTotal_6 = 0;
  precioTotal_7 = 0;
  precioTotal_8 = 0;
  precioTotal_9 = 0;
  precioTotal_10 = 0;
  precioTotal_11 = 0;
  precioTotal_12 = 0;
  precioTotal_13 = 0;
  precioTotal_14 = 0;
  precioTotal_15 = 0;
  precioTotal_16 = 0;

  observaciones = "";
  descuento = 0;
  tipoDescuento = "";
  destinatario = "";
  rutDestinatario = "";
  banco = "";
  tipoCueta = "";
  nroCuenta = "";
  correoDestinatario = "";
  // archivo1 = "";
  // //archivo2 = "";
  // archivo3 = "";
  // archivo4 = "";
  fechaUf = "";
  solicitante = "";
  motivoAnulacion = "";

  codigoCuenta = "";
  descripcionCuenta = "";

  tipoGastoCorrecto = false;
  cuentaSoftlandCorrecto = false;
  // bancos = [
  //   "BANCO CHILE / EDWARDS",
  //   "BANCO INTERNACIONAL",
  //   "BANCO ESTADO",
  //   "BANCO SCOTIABANK",
  //   "BANCO BCI",
  //   "BANCO DO BRASIL S.A",
  //   "CORPBANCA",
  //   "BANCO BICE",
  //   "BANCO HSBC BANK",
  //   "BANCO SANTANDER",
  //   "BANCO ITAU",
  //   "BANCO SECURITY",
  //   "BANCO FALABELLA",
  //   "BANCO RIPLEY",
  //   "RABOBANK",
  //   "BANCO CONSORCIO",
  //   "BANCO PARIS",
  //   "BBVA",
  //   "BANCO DEL DESARROLLO",
  //   "COOPEUCH",
  //   "CAJA LOS HEROES",
  //   "TENPO PREPAGO",
  //   "TAPP CAJA LOS ANDES",
  //   "MERCADO PAGO",
  // ];
  tiposCuenta = ["CUENTA CORRIENTE", "CUENTA DE AHORRO", "CUENTA VISTA / RUT"];
  tiposFormaPago = [
    //  "EFECTIVO",
    "TRANSFERENCIA",
    "CHEQUE",
    //  "TARJETA DE CREDITO",
    //  "TARJETA DE DEBITO",
    //  "DEPOSITO",
  ];
  itemsDivisa = ["UF", "CLP", "USD", "EUR"];
  itemsUnidadesMedida = [
    "UNIDAD",
    "METRO",
    "METRO CUADRADO",
    "METRO CUBICO",
    "KILOGRAMO",
    "LITRO",
    "DOCENA",
  ];
  itemsCentroCosto = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
    "32",
    "33",
    "34",
    "35",
    "36",
    "37",
    "38",
    "39",
    "40",
    "41",
    "42",
    "50",
    "60",
    "70",
    "80",
    "90",
    "100",
    "110",
  ];

  itemsTipoOrden = ["EXENTA", "AFECTA"];

  CTA_ESTADO_RESULTADO = ""; //revisar
  CTA_CONTROL_GASTO = ""; //revisar

  tipoArchivo = "";

  get obtieneCuentaEstadoResultado(): string {
    let texto = "";
    this.mapeoGastos.forEach((element) => {
      if (element.tipoGasto == this.tipoGasto) {
        this.CTA_ESTADO_RESULTADO = element.cuentaEstadoResultado;
        texto = element.cuentaEstadoResultado;
      }
    });
    return texto;
  }

  get obtieneCuentaControlGasto(): string {
    let texto = "";
    this.mapeoGastos.forEach((element) => {
      if (element.tipoGasto == this.tipoGasto) {
        this.CTA_CONTROL_GASTO = element.cuentaGasto;
        texto = element.cuentaGasto;
      }
    });
    return texto;
  }

  get itemsTiposArchivo(): Array<string> {
    if (this.estado === "pendiente") {
      return [
        "COMPROBANTE FOTOGRAFICO DE TRABAJO",
        "COTIZACIÓN O PRESUPUESTO",
        //"DCTO F30.1",
        "BOLETA/FACTURA",
        "GUIA DE DESPACHO",
        "NOTA DE CREDITO",
        //"PRE FACTURA",
        "ORDEN DE COMPRA - SOFTLAND",
      ];
    } else {
      return [
        "COMPROBANTE FOTOGRAFICO DE TRABAJO",
        "COTIZACIÓN O PRESUPUESTO",
        //"DCTO F30.1",
        "BOLETA/FACTURA",
        "GUIA DE DESPACHO",
        "NOTA DE CREDITO",
        //"PRE FACTURA",
        "ORDEN DE COMPRA - SOFTLAND",
      ];
    }
  }

  archivo?: Archivo | null = null;
  archivo2?: Archivo | null = null;
  archivo3?: Archivo | null = null;
  archivo4?: Archivo | null = null;
  archivo5?: Archivo | null = null;
  archivo6?: Archivo | null = null;

  archivosBorrar: Archivos = {};
  vistaPrevia = {
    model: false,
    url: "",
  };

  // tipoArchivo = "";
  tipoArchivo2 = "";
  tipoArchivo3 = "";
  tipoArchivo4 = "";
  tipoArchivo5 = "";
  tipoArchivo6 = "";
  // tipoArchivo7 = "";
  // tipoArchivo8 = "";
  // tipoArchivo9 = "";
  // tipoArchivo10 = "";

  subidoTipoArchivo = "";
  subidoTipoArchivo2 = "";
  subidoTipoArchivo3 = "";
  subidoTipoArchivo4 = "";
  subidoTipoArchivo5 = "";
  subidoTipoArchivo6 = "";

  color = "";

  observacion = "";
  tipoGasto = "";
  observante = "";

  respuesta = "";
  respondedor = "";
  // subidoTipoArchivo7 = "";
  // subidoTipoArchivo8 = "";
  // subidoTipoArchivo9 = "";
  // subidoTipoArchivo10 = "";

  // DATOS CREADOS PARA PRENOMINA
  prenomina_id = 0;
  prenomina_numeroPrenominas = 0;
  prenomina_rut = "";
  prenomina_nombreProveedor = "";
  prenomina_nFactura = "";
  prenomina_ordenCompra = "";
  prenomina_centroCosto = "";
  prenomina_montoOC = "";
  prenomina_porcentajePagadog = "";
  prenomina_montoSaldo = "";
  prenomina_montoAP = "";
  prenomina_archivo?: Archivo | null = null;
  prenomina_archivosBorrar: Archivos = {};
  prenomina_observacion = "";
  prenomina_formaPago = "";
  prenomina_numeroCheque = "";
  prenomina_tipoGasto = "";
  prenomina_banco = "";
  prenomina_periodo = "";
  prenomina_recurrente = "";
  prenomina_tipoRecurrencia = "";
  prenomina_periodoRecurrencia = "";
  prenomina_color = "";

  prenomina_recurrenteMes = "";
  prenomina_recurrenteAnual = "";

  conciliacion_archivosBorrar: Archivos = {};

  // FIN DATOS DE PRENOMINA
  menu2 = false;
  menu3 = false;

  periodo = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
    .toISOString()
    .substr(0, 10);

  fechaVencimiento = new Date(
    Date.now() - new Date().getTimezoneOffset() * 60000
  )
    .toISOString()
    .substr(0, 10);

  comentario = "";
  emisorComentario = "";

  existe = false;

  get fechaPeriodoF(): string {
    let array_fecha = this.periodo.split("-");
    let dia = "01";
    let mes = array_fecha[1];
    let ano = array_fecha[0];
    console.log(array_fecha);
    this.periodo = dia + "-" + mes + "-" + ano;
    return dia + "-" + mes + "-" + ano;
  }

  get fechaVencimientoF(): string {
    let array_fecha = this.fechaVencimiento.split("-");
    let dia = array_fecha[2];
    let mes = array_fecha[1];
    let ano = array_fecha[0];
    console.log(array_fecha);
    this.fechaVencimiento = dia + "-" + mes + "-" + ano;
    return dia + "-" + mes + "-" + ano;
  }

  itemRecurrente = ["SI", "NO"];
  itemFormaRecurrencia = ["MENSUAL", "SEMESTRAL", "TRIMESTRAL", "ANUAL"];
  itemFormaRecurrenciaMes = [
    "12",
    "11",
    "10",
    "09",
    "08",
    "07",
    "06",
    "05",
    "04",
    "03",
    "02",
    "01",
  ];
  itemFormaRecurrenciaTrimestre = ["04", "03", "02", "01"];
  itemFormaRecurrenciaSemestre = ["02", "01"];
  itemFormaRecurrenciaAnual = ["2023", "2022", "2021", "2020"];

  conciliacion_id: string | null = null;

  get itemBancos(): Array<string> {
    const itemBanco: Array<string> = [];
    this.bancos.forEach((banco) => {
      itemBanco.push(banco.nombre.toUpperCase());
    });
    return itemBanco;
  }

  get datoRecurrencia(): string {
    let campo = "";
    let dia = "01";
    let mes = "";
    let año = "";
    if (this.prenomina_recurrente === "SI") {
      if (
        this.prenomina_tipoRecurrencia === "MENSUAL" ||
        this.prenomina_tipoRecurrencia === "SEMESTRAL" ||
        this.prenomina_tipoRecurrencia === "TRIMESTRAL"
      ) {
        mes = this.prenomina_recurrenteMes;
        año = this.prenomina_recurrenteAnual;
      }
      if (this.prenomina_tipoRecurrencia === "ANUAL") {
        mes = "01";
        año = this.prenomina_recurrenteAnual;
      }
      campo = dia + "-" + mes + "-" + año;
    }
    return campo;
  }

  get itemsRecurrencia(): Array<string> {
    let dato = "";
    let items = [dato];
    if (this.prenomina_tipoRecurrencia === "MENSUAL") {
      return this.itemFormaRecurrenciaMes;
      // mes = this.recurrenteMes;
      // año = this.recurrenteAnual;
    }
    if (this.prenomina_tipoRecurrencia === "SEMESTRAL") {
      return this.itemFormaRecurrenciaSemestre;
      // mes = this.recurrenteSemestre;
      // año = this.recurrenteAnual;
    }
    if (this.prenomina_tipoRecurrencia === "TRIMESTRAL") {
      return this.itemFormaRecurrenciaTrimestre;
      // mes = this.recurrenteTrimestre;
      // año = this.recurrenteAnual;
    }
    return items;
  }

  get defineFormaPago(): string {
    let formaPago = this.formaPago;
    if (formaPago === "TRANSFERENCIA" || formaPago === "CRÉDITO A 30 DÍAS") {
      return "NOMINA";
    } else {
      return "CHEQUE";
    }
  }

  get defineMontoSaldo(): string {
    if (this.prenomina_montoAP) {
      let total = this.totalCalculado;
      let montoSaldo = total - parseInt(this.prenomina_montoAP);
      this.prenomina_montoSaldo = montoSaldo.toString();
      return montoSaldo.toString();
    } else {
      return "0";
    }
  }

  get prenomina_porcentajePagado(): string {
    //calcula el porcentaje que representa el monto a pago del monto de la orden de compra
    if (this.prenomina_montoAP) {
      let porcentaje = (
        (100 * parseFloat(this.prenomina_montoAP)) /
        this.totalCalculado
      ).toString();
      this.prenomina_porcentajePagadog = porcentaje;
      return porcentaje;
    } else {
      return "0";
    }
  }

  verificaExistenciaPrenomina(item: Orden): boolean {
    let existe = false;
    this.prenominas.forEach((prenomina) => {
      if (prenomina.rut == item?.rut?.toString()) {
        if (prenomina.nFactura === item?.nFactura?.toString()) {
          existe = true;
          this.existe = true;
        }
      }
    });
    return existe;
  }

  // verificaExistenciaConciliacuib(item: Orden): boolean {
  //   let existe = false;
  //   this.prenominas.forEach((prenomina) => {
  //     if (prenomina.rut == item?.rut?.toString()) {
  //       if (prenomina.nFactura === item?.nFactura?.toString()) {
  //         existe = true;
  //       }
  //     }
  //   });
  //   return existe;
  // }

  get nombreArchivo(): string {
    return this.archivo?.file?.name ?? this.archivo?.nombre ?? "";
  }

  get nombreArchivo2(): string {
    return this.archivo2?.file?.name ?? this.archivo2?.nombre ?? "";
  }

  get nombreArchivo3(): string {
    return this.archivo3?.file?.name ?? this.archivo3?.nombre ?? "";
  }

  get nombreArchivo4(): string {
    return this.archivo4?.file?.name ?? this.archivo4?.nombre ?? "";
  }

  get nombreArchivo5(): string {
    return this.archivo5?.file?.name ?? this.archivo5?.nombre ?? "";
  }

  get nombreArchivo6(): string {
    return this.archivo6?.file?.name ?? this.archivo6?.nombre ?? "";
  }

  get titulo(): string {
    return this.orden ? "Archivos Asociados" : "nuevo orden";
  }

  get corrigeColor(): string {
    if (
      this.tipoArchivo === "BOLETA/FACTURA" ||
      this.tipoArchivo2 === "BOLETA/FACTURA" ||
      this.tipoArchivo3 === "BOLETA/FACTURA" ||
      this.tipoArchivo4 === "BOLETA/FACTURA" ||
      this.tipoArchivo5 === "BOLETA/FACTURA" ||
      this.tipoArchivo6 === "BOLETA/FACTURA"
    ) {
      if (this.estado === "aprobado") {
        this.color = "success";
      }
    }
    return this.color;
  }

  //   get permisosKey(): Array<string> {
  //     // return Object.keys(this.permisos).filter((key) => {
  //     //   if(this.rutas[key].tipos.includes(this.tipo)){
  //     //     this.permisos[key] = true;
  //     //   }
  //     // });
  //     return Object.keys(this.permisos).filter((key) => {
  //       if (!this.rutas[key]) {
  //         return false;
  //       }
  //       const tipo = this.rutas[key].tipos.includes(this.tipo);
  //       return tipo;
  //     });
  //   }

  // numero max de ordenes

  // condiciona si existe ordenCompra
  get ordenFiltrada(): number {
    let numero = 0;
    const orden = this.orden;
    if (orden) {
      if (this.ordenCompra === 0) {
        numero = this.cantidadOrdenes + 1;
      } else {
        numero = orden.ordenCompra;
      }
    } else {
      numero = this.cantidadOrdenes + 1;
    }

    return numero;
  }

  get valorTotal_1(): number {
    return this.precioUnitario_1 * this.cantidad_1;
  }

  get valorTotal_2(): number {
    return this.precioUnitario_2 * this.cantidad_2;
  }

  get valorTotal_3(): number {
    return this.precioUnitario_3 * this.cantidad_3;
  }

  get valorTotal_4(): number {
    return this.precioUnitario_4 * this.cantidad_4;
  }

  get valorTotal_5(): number {
    return this.precioUnitario_5 * this.cantidad_5;
  }

  get valorTotal_6(): number {
    return this.precioUnitario_6 * this.cantidad_6;
  }

  get valorTotal_7(): number {
    return this.precioUnitario_7 * this.cantidad_7;
  }

  get valorTotal_8(): number {
    return this.precioUnitario_8 * this.cantidad_8;
  }

  get valorTotal_9(): number {
    return this.precioUnitario_9 * this.cantidad_9;
  }

  get valorTotal_10(): number {
    return this.precioUnitario_10 * this.cantidad_10;
  }

  get valorTotal_11(): number {
    return this.precioUnitario_11 * this.cantidad_11;
  }

  get valorTotal_12(): number {
    return this.precioUnitario_12 * this.cantidad_12;
  }

  get valorTotal_13(): number {
    return this.precioUnitario_13 * this.cantidad_13;
  }

  get valorTotal_14(): number {
    return this.precioUnitario_14 * this.cantidad_14;
  }

  get valorTotal_15(): number {
    return this.precioUnitario_15 * this.cantidad_15;
  }

  get valorTotal_16(): number {
    return this.precioUnitario_16 * this.cantidad_16;
  }

  get fecha(): string {
    let fecha = new Date().toLocaleDateString("es-CL");
    if (this.orden) {
      return this.orden.fechaEmision;
    }
    return fecha;
  }

  get subTotalCalculado(): number {
    let numero =
      this.valorTotal_1 +
      this.valorTotal_2 +
      this.valorTotal_3 +
      this.valorTotal_4 +
      this.valorTotal_5 +
      this.valorTotal_6 +
      this.valorTotal_7 +
      this.valorTotal_8 +
      this.valorTotal_9 +
      this.valorTotal_10 +
      this.valorTotal_11 +
      this.valorTotal_12 +
      this.valorTotal_13 +
      this.valorTotal_14 +
      this.valorTotal_15 +
      this.valorTotal_16;
    return numero;
  }

  get afectoIVACalculado(): number {
    let numero = 0;
    if (this.tipoOrden === "AFECTA") {
      numero = this.subTotalCalculado;
      this.exentoIVA;
      this.exentoIVA = 0;
    }
    return numero;
  }

  get exentoIVACalculado(): number {
    let numero = 0;
    if (this.tipoOrden === "EXENTA") {
      numero = this.subTotalCalculado;
      this.afectoIVA = 0;
    }
    return numero;
  }

  get IVACalculado(): number {
    let numero = 0;
    if (this.tipoOrden === "EXENTA") {
      numero = 0;
    }
    if (this.tipoOrden === "AFECTA") {
      numero = this.subTotalCalculado * 0.19;
    }
    return numero;
  }

  get totalCalculado(): number {
    let numero = this.subTotalCalculado + this.IVACalculado;
    return numero;
  }

  get itemRutProveedores(): string | undefined {
    let itemRutProveedor = "";
    this.proveedores.forEach((proveedor) => {
      if (proveedor.NomAux === this.empresa) {
        itemRutProveedor = proveedor.RutAux;
        this.rut = itemRutProveedor;
      }
    });
    return itemRutProveedor;
  }

  get itemNombreProveedores(): Array<string> {
    const itemNombreProveedor: Array<string> = [];
    this.proveedores.forEach((proveedor) => {
      itemNombreProveedor.push(proveedor.NomAux);
    });
    return itemNombreProveedor.sort();
  }

  get itemsComunas(): Array<string> {
    const itemComuna: Array<string> = [];
    this.ciudades.forEach((comuna) => {
      itemComuna.push(comuna.nombre.toUpperCase());
    });
    return itemComuna.sort();
  }

  get nombreError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.nombre.$dirty) return errors;
    if (!this.$v.nombre.required) errors.push("");
    return errors;
  }

  get empresaError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.empresa.$dirty) return errors;
    if (!this.$v.empresa.required) errors.push("");
    return errors;
  }

  get posicionError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.posicion.$dirty) return errors;
    if (!this.$v.posicion.required) errors.push("");
    return errors;
  }

  get observacionesError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.observaciones.$dirty) return errors;
    if (!this.$v.observaciones.required) errors.push("");
    return errors;
  }

  get divisaError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.divisa.$dirty) return errors;
    if (!this.$v.divisa.required) errors.push("");
    return errors;
  }

  get direccionError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.direccion.$dirty) return errors;
    if (!this.$v.direccion.required) errors.push("");
    return errors;
  }

  get cantidad_1Error(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.cantidad_1.$dirty) return errors;
    if (!this.$v.cantidad_1.required) errors.push("");
    return errors;
  }

  get unidadMedida_1Error(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.unidadMedida_1.$dirty) return errors;
    if (!this.$v.unidadMedida_1.required) errors.push("");
    return errors;
  }

  get descripcion_1Error(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.descripcion_1.$dirty) return errors;
    if (!this.$v.descripcion_1.required) errors.push("");
    return errors;
  }

  get centroCosto_1Error(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.centroCosto_1.$dirty) return errors;
    if (!this.$v.centroCosto_1.required) errors.push("");
    return errors;
  }

  get precioUnitario_1Error(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.precioUnitario_1.$dirty) return errors;
    if (!this.$v.precioUnitario_1.required) errors.push("");
    return errors;
  }

  get tipoOrdenError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.tipoOrden.$dirty) return errors;
    if (!this.$v.tipoOrden.required) errors.push("");
    return errors;
  }

  // get precioTotal_1Error(): Array<string> {
  //   const errors: Array<string> = [];
  //   if (!this.$v.precioTotal_1.$dirty) return errors;
  //   if (!this.$v.precioTotal_1.required)
  //     errors.push("Completar datos obligatorios");
  //   return errors;
  // }

  @Emit("close")
  onClose(): null {
    return null;
  }

  @Emit("cerrar-dialogo")
  onCerrarDialogo(): void {
    return;
  }

  @Emit("existe-prenomina")
  defineExistenciaPrenomina(): boolean {
    return this.existe;
  }

  @Emit("click-guardar")
  onClickGuardar(data: DataFormularioOrden): DataFormularioOrden {
    return data;
  }

  @Emit("click-guardar-prenomina")
  onClickGuardarPrenomina(
    dataPrenomina: DataFormularioPrenomina
  ): DataFormularioPrenomina {
    return dataPrenomina;
  }

  @Emit("click-guardar-conciliacion")
  onClickGuardarConciliacion(
    dataConciliacion: DataFormularioRegistrosEgresos
  ): DataFormularioRegistrosEgresos {
    return dataConciliacion;
  }

  cargarArchivo(ev: Event): void {
    const input = ev.target as HTMLInputElement;
    const file = input?.files ? input.files[0] : null;
    if (file) {
      if (file.type.includes("pdf")) {
        const fecha = new Date();
        const extencion = file.name.split(".").pop() ?? "";
        const id = `ordenesCompra/${this.ordenCompra}/${
          this.tipoArchivo
        }/${fecha.valueOf()}.${extencion}`;
        this.archivo = {
          id: id,
          nombre: file.name,
          file: file,
        };
      }
    }
    // if (this.tipoArchivo === "BOLETA/FACTURA") {
    //   if (file) {
    //     if (file.type.includes("pdf")) {

    //       const fecha = new Date();
    //       const extencion = file.name.split(".").pop() ?? "";
    //       const id = `prenominas/${
    //         this.rut
    //       }/archivo/${fecha.valueOf()}.${extencion}`;
    //       this.archivo = {
    //         id: id,
    //         nombre: file.name,
    //         file: file,
    //       };
    //     }
    //   }
    // }
  }

  cargarArchivo2(ev: Event): void {
    const input = ev.target as HTMLInputElement;
    const file = input?.files ? input.files[0] : null;
    if (file) {
      if (file.type.includes("pdf")) {
        const fecha = new Date();
        const extencion = file.name.split(".").pop() ?? "";
        const id = `ordenesCompra/${this.ordenCompra}/${
          this.tipoArchivo
        }/${fecha.valueOf()}.${extencion}`;
        this.archivo2 = {
          id: id,
          nombre: file.name,
          file: file,
        };
      }
    }
  }

  cargarArchivo3(ev: Event): void {
    const input = ev.target as HTMLInputElement;
    const file = input?.files ? input.files[0] : null;
    if (file) {
      if (file.type.includes("pdf")) {
        const fecha = new Date();
        const extencion = file.name.split(".").pop() ?? "";
        const id = `ordenesCompra/${this.ordenCompra}/${
          this.tipoArchivo
        }/${fecha.valueOf()}.${extencion}`;
        this.archivo3 = {
          id: id,
          nombre: file.name,
          file: file,
        };
      }
    }
  }

  cargarArchivo4(ev: Event): void {
    const input = ev.target as HTMLInputElement;
    const file = input?.files ? input.files[0] : null;
    if (file) {
      if (file.type.includes("pdf")) {
        const fecha = new Date();
        const extencion = file.name.split(".").pop() ?? "";
        const id = `ordenesCompra/${this.ordenCompra}/${
          this.tipoArchivo
        }/${fecha.valueOf()}.${extencion}`;
        this.archivo4 = {
          id: id,
          nombre: file.name,
          file: file,
        };
      }
    }
  }

  cargarArchivo5(ev: Event): void {
    const input = ev.target as HTMLInputElement;
    const file = input?.files ? input.files[0] : null;
    if (file) {
      if (file.type.includes("pdf")) {
        const fecha = new Date();
        const extencion = file.name.split(".").pop() ?? "";
        const id = `ordenesCompra/${this.ordenCompra}/${
          this.tipoArchivo
        }/${fecha.valueOf()}.${extencion}`;
        this.archivo5 = {
          id: id,
          nombre: file.name,
          file: file,
        };
      }
    }
  }

  cargarArchivo6(ev: Event): void {
    const input = ev.target as HTMLInputElement;
    const file = input?.files ? input.files[0] : null;
    if (file) {
      if (file.type.includes("pdf")) {
        const fecha = new Date();
        const extencion = file.name.split(".").pop() ?? "";
        const id = `ordenesCompra/${this.ordenCompra}/${
          this.tipoArchivo
        }/${fecha.valueOf()}.${extencion}`;
        this.archivo6 = {
          id: id,
          nombre: file.name,
          file: file,
        };
      }
    }
  }

  clickInputArchivo(): void {
    const input = this.$refs.inputArchivo as HTMLInputElement;
    input.click();
  }

  clickInputArchivo2(): void {
    const input = this.$refs.inputArchivo2 as HTMLInputElement;
    input.click();
  }

  clickInputArchivo3(): void {
    const input = this.$refs.inputArchivo3 as HTMLInputElement;
    input.click();
  }

  clickInputArchivo4(): void {
    const input = this.$refs.inputArchivo4 as HTMLInputElement;
    input.click();
  }

  clickInputArchivo5(): void {
    const input = this.$refs.inputArchivo5 as HTMLInputElement;
    input.click();
  }

  clickInputArchivo6(): void {
    const input = this.$refs.inputArchivo6 as HTMLInputElement;
    input.click();
  }

  abrirVistaPrevia(archivo: Archivo): void {
    this.vistaPrevia = {
      model: true,
      url: this.urlArchivo(archivo),
    };
  }

  cerrarVistaPrevia(): void {
    this.vistaPrevia = {
      model: false,
      url: "",
    };
  }

  eliminarArchivo(): void {
    if (this.orden && this.archivo?.id) {
      this.archivosBorrar[this.archivo.id] = this.archivo;
    }
    this.archivo = null;
  }

  urlArchivo(media: Archivo): string {
    return media.url ?? URL.createObjectURL(media.file);
  }

  //   @Watch("permisosKey", { immediate: true })
  //   onTipoChange(): void {
  //     console.log("a");
  //     for (const key of Object.keys(this.permisos)) {
  //       if (this.permisos[key]) {
  //         this.permisos[key] = false;
  //       }
  //     }
  //     this.permisosKey.forEach((key) => {
  //       this.permisos[key] = true;
  //     });
  //   }

  //   nombrePermiso(key: string): string {
  //     return this.rutas[key].text;
  //   }

  // funcion capitalizar  primera letra de cada palabra
  capitalizar(texto: string): string {
    return texto
      .toLowerCase()
      .split(" ")
      .map((palabra) => {
        return palabra.charAt(0).toUpperCase() + palabra.slice(1);
      })
      .join(" ");
  }

  creditoATransferencia(texto: string): string {
    if (texto == "CHEQUE") {
      return texto;
    } else {
      return "TRANSFERENCIA";
    }
  }

  pagoCompletoParcial(texto: string): string {
    if (texto === "100") {
      return "COMPLETA";
    } else {
      return "PARCIAL";
    }
  }

  guardar(): void {
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }
    // if (this.estado === "aprobado") {
    //   console.log("aprobado");
    // }
    // else {
    //   console.log("no aprobado");
    // }
    const conciliacion_doc: RegistrosEgresos = {
      //nombre: this.nombre.toLowerCase(),
      PERIODO: this.fechaPeriodoF,
      CENTRO_DE_COSTO: this.centroCosto,
      DESCRIPCION_CUENTA: this.descripcionCuenta,
      CODIGO_CUENTA: this.codigoCuenta,
      BANCO: this.capitalizar(this.banco),
      ORDEN_DE_COMPRA: this.ordenCompra.toString(),
      FECHA_EGRESO: "01-01-2021",
      TIPO_EGRESO: "FACTURA",
      MONTO_EGRESO: this.prenomina_montoAP,
      GLOSA: this.observaciones,
      RUT_PROVEEDOR: this.rut,
      NOMBRE_PROVEEDOR: this.empresa,
      PAGO_C_P: this.pagoCompletoParcial(this.prenomina_porcentajePagado), //revisar
      PORC_DEL_TOTAL: this.prenomina_porcentajePagado + "%", //revisar
      NOMINA_PAGO: "SI", //revisar
      TIPO_DE_DOCUMENTO: this.creditoATransferencia(
        this.formaPago.toUpperCase()
      ), //revisar
      N_DOCUMENTO: "", //revisar
      FAC_DOC_NOTA: this.prenomina_nFactura ?? "",
      CTA_ESTADO_RESULTADO: this.obtieneCuentaEstadoResultado, //revisar
      N_NOMINA: "", //revisar
      CTA_CONTROL_GASTO: this.obtieneCuentaControlGasto, //revisar
      TIPO_GASTO: this.tipoGasto,
      N_CLIENTE: "", //revisar
      TIPO_FACTURA: this.tipoOrden, //revisar
      NETO_FACTURA: this.subTotalCalculado.toString(), //revisar
      IVA_FACTURA: this.IVACalculado.toString(), //revisar
      TOTAL_FACTURA: this.totalCalculado.toString(), //revisar
      PAGADO: "NO",
      PERIODO_SERVICIO: "", //revisar
    };

    const prenomina_doc: Prenomina = {
      // console.log("aprobados y modificados");
      //       cuentaAprobados++;
      //       existePrenomina = true;
      //       prenomina.modificado = true;
      //       prenomina.ingresada = true;
      //       prenomina.estado;
      //       prenomina.pasoNomina = new Date().format("DD-MM-YYYY")

      //       this.setPrenomina(prenomina);
      centroCosto: this.centroCosto,
      modificado: false,
      numeroPrenominas: 0,
      ingresada: false,
      estado: "pendiente",
      rut: this.rut,
      nombreProveedor: this.empresa,
      nFactura: this.prenomina_nFactura ?? "",
      ordenCompra: this.ordenCompra.toString() ?? "",
      montoOC: this.total.toString() ?? "",
      porcentajePagado: this.prenomina_porcentajePagado ?? "",
      montoSaldo: this.defineMontoSaldo,
      montoAP: this.prenomina_montoAP,
      observacion: this.observaciones ?? "",
      formaPago: this.defineFormaPago ?? "",
      numeroCheque: this.prenomina_numeroCheque ?? "",
      banco: this.banco ?? "",
      periodo: this.fechaPeriodoF,
      fechaAprobacion: "",
      tipoGasto: this.tipoGasto,
      recurrente: this.prenomina_recurrente ?? "",
      tipoRecurrencia: this.prenomina_tipoRecurrencia ?? "",
      periodoRecurrencia: this.prenomina_periodoRecurrencia ?? "",
      autorizado: "11111111-1",
      motivoRechazo: "",
      color: "#D5D8E3",
      pasoNomina: "",
      fechaVencimiento: this.fechaVencimientoF ?? "",
      comentario: this.comentario ?? "",
      emisorComentario: this.emisorComentario ?? "",
    };
    const doc: Orden = {
      nombre: this.nombre.toUpperCase(),
      // posicion: this.posicion,
      // Datos de carga autmatica
      // fecha actual
      ordenCompra: this.ordenCompra,
      fechaEmision: this.fecha,
      estado: this.estado,
      autorizado: this.autorizado,
      subTotal: this.subTotalCalculado,
      afectoIVA: this.afectoIVACalculado,
      exentoIVA: this.exentoIVACalculado,
      subTotalNeto: this.subTotalCalculado,
      IVA: this.IVACalculado,
      total: this.totalCalculado,
      tipoOrden: this.tipoOrden,
      // cambiar
      //servicios: this.servicios,
      cantidad_1: this.cantidad_1,
      cantidad_2: this.cantidad_2,
      cantidad_3: this.cantidad_3,
      cantidad_4: this.cantidad_4,
      cantidad_5: this.cantidad_5,
      cantidad_6: this.cantidad_6,
      cantidad_7: this.cantidad_7,
      cantidad_8: this.cantidad_8,
      cantidad_9: this.cantidad_9,
      cantidad_10: this.cantidad_10,
      cantidad_11: this.cantidad_11,
      cantidad_12: this.cantidad_12,
      cantidad_13: this.cantidad_13,
      cantidad_14: this.cantidad_14,
      cantidad_15: this.cantidad_15,
      cantidad_16: this.cantidad_16,

      unidadMedida_1: this.unidadMedida_1.toUpperCase(),
      unidadMedida_2: this.unidadMedida_2.toUpperCase(),
      unidadMedida_3: this.unidadMedida_3.toUpperCase(),
      unidadMedida_4: this.unidadMedida_4.toUpperCase(),
      unidadMedida_5: this.unidadMedida_5.toUpperCase(),
      unidadMedida_6: this.unidadMedida_6.toUpperCase(),
      unidadMedida_7: this.unidadMedida_7.toUpperCase(),
      unidadMedida_8: this.unidadMedida_8.toUpperCase(),
      unidadMedida_9: this.unidadMedida_9.toUpperCase(),
      unidadMedida_10: this.unidadMedida_10.toUpperCase(),
      unidadMedida_11: this.unidadMedida_11.toUpperCase(),
      unidadMedida_12: this.unidadMedida_12.toUpperCase(),
      unidadMedida_13: this.unidadMedida_13.toUpperCase(),
      unidadMedida_14: this.unidadMedida_14.toUpperCase(),
      unidadMedida_15: this.unidadMedida_15.toUpperCase(),
      unidadMedida_16: this.unidadMedida_16.toUpperCase(),

      descripcion_1: this.descripcion_1.toUpperCase(),
      descripcion_2: this.descripcion_2.toUpperCase(),
      descripcion_3: this.descripcion_3.toUpperCase(),
      descripcion_4: this.descripcion_4.toUpperCase(),
      descripcion_5: this.descripcion_5.toUpperCase(),
      descripcion_6: this.descripcion_6.toUpperCase(),
      descripcion_7: this.descripcion_7.toUpperCase(),
      descripcion_8: this.descripcion_8.toUpperCase(),
      descripcion_9: this.descripcion_9.toUpperCase(),
      descripcion_10: this.descripcion_10.toUpperCase(),
      descripcion_11: this.descripcion_11.toUpperCase(),
      descripcion_12: this.descripcion_12.toUpperCase(),
      descripcion_13: this.descripcion_13.toUpperCase(),
      descripcion_14: this.descripcion_14.toUpperCase(),
      descripcion_15: this.descripcion_15.toUpperCase(),
      descripcion_16: this.descripcion_16.toUpperCase(),

      centroCosto_1: this.centroCosto_1,
      centroCosto_2: this.centroCosto_2,
      centroCosto_3: this.centroCosto_3,
      centroCosto_4: this.centroCosto_4,
      centroCosto_5: this.centroCosto_5,
      centroCosto_6: this.centroCosto_6,
      centroCosto_7: this.centroCosto_7,
      centroCosto_8: this.centroCosto_8,
      centroCosto_9: this.centroCosto_9,
      centroCosto_10: this.centroCosto_10,
      centroCosto_11: this.centroCosto_11,
      centroCosto_12: this.centroCosto_12,
      centroCosto_13: this.centroCosto_13,
      centroCosto_14: this.centroCosto_14,
      centroCosto_15: this.centroCosto_15,
      centroCosto_16: this.centroCosto_16,

      precioUnitario_1: this.precioUnitario_1,
      precioUnitario_2: this.precioUnitario_2,
      precioUnitario_3: this.precioUnitario_3,
      precioUnitario_4: this.precioUnitario_4,
      precioUnitario_5: this.precioUnitario_5,
      precioUnitario_6: this.precioUnitario_6,
      precioUnitario_7: this.precioUnitario_7,
      precioUnitario_8: this.precioUnitario_8,
      precioUnitario_9: this.precioUnitario_9,
      precioUnitario_10: this.precioUnitario_10,
      precioUnitario_11: this.precioUnitario_11,
      precioUnitario_12: this.precioUnitario_12,
      precioUnitario_13: this.precioUnitario_13,
      precioUnitario_14: this.precioUnitario_14,
      precioUnitario_15: this.precioUnitario_15,
      precioUnitario_16: this.precioUnitario_16,

      precioTotal_1: this.valorTotal_1,
      precioTotal_2: this.valorTotal_2,
      precioTotal_3: this.valorTotal_3,
      precioTotal_4: this.valorTotal_4,
      precioTotal_5: this.valorTotal_5,
      precioTotal_6: this.valorTotal_6,
      precioTotal_7: this.valorTotal_7,
      precioTotal_8: this.valorTotal_8,
      precioTotal_9: this.valorTotal_9,
      precioTotal_10: this.valorTotal_10,
      precioTotal_11: this.valorTotal_11,
      precioTotal_12: this.valorTotal_12,
      precioTotal_13: this.valorTotal_13,
      precioTotal_14: this.valorTotal_14,
      precioTotal_15: this.valorTotal_15,
      precioTotal_16: this.valorTotal_16,
      // Datos de carga manual
      centroCosto: this.centroCosto,
      divisa: this.divisa,
      empresa: this.empresa,
      rut: this.rut.toUpperCase(),
      direccion: this.direccion.toUpperCase(),
      comuna: this.comuna,
      telefono: this.telefono,
      contacto: this.contacto.toUpperCase(),
      formaPago: this.formaPago.toUpperCase(),
      correo: this.correo.toUpperCase(),
      observaciones: this.observaciones.toUpperCase(),
      descuento: this.descuento,
      tipoDescuento: this.tipoDescuento,
      destinatario: this.destinatario.toUpperCase(),
      rutDestinatario: this.rutDestinatario.toUpperCase(),
      banco: this.banco.toUpperCase(),
      tipoCueta: this.tipoCueta.toUpperCase(),
      nroCuenta: this.nroCuenta,
      correoDestinatario: this.correoDestinatario.toUpperCase(),
      //archivo1: this.archivo1,
      //archivo2: this.archivo2,
      //archivo3: this.archivo3,
      //archivo4: this.archivo4,
      fechaUf: this.fechaUf,
      solicitante: this.solicitante.toUpperCase(),
      motivoAnulacion: this.motivoAnulacion.toUpperCase(),
      tipoArchivo: this.tipoArchivo,
      tipoArchivo2: this.tipoArchivo2,
      tipoArchivo3: this.tipoArchivo3,
      tipoArchivo4: this.tipoArchivo4,
      tipoArchivo5: this.tipoArchivo5,
      tipoArchivo6: this.tipoArchivo6,

      subidoTipoArchivo: this.subidoTipoArchivo,
      subidoTipoArchivo2: this.subidoTipoArchivo2,
      color: this.corrigeColor,
      observacion: this.observacion,
      tipoGasto: this.tipoGasto,

      nFactura: this.prenomina_nFactura ?? "",
      periodo: this.prenomina_periodo ?? "",
      recurrente: this.prenomina_recurrente ?? "",
      tipoRecurrencia: this.prenomina_tipoRecurrencia ?? "",
      periodoRecurrencia: this.prenomina_periodoRecurrencia ?? "",
      montoSaldo: this.prenomina_montoSaldo ?? "",
      montoAP: this.prenomina_montoAP ?? "",
      numeroCheque: this.prenomina_numeroCheque ?? "",

      codigoCuenta: this.codigoCuenta,
      descripcionCuenta: this.descripcionCuenta,

      tipoGastoCorrecto: this.tipoGastoCorrecto ?? "",
      cuentaSoftlandCorrecto: this.cuentaSoftlandCorrecto ?? "",
      observante: this.observante ?? "",
      respuesta: this.respuesta.toUpperCase() ?? "",
      respondedor: this.respondedor.toUpperCase() ?? "",
      fechaVencimiento: this.fechaVencimientoF ?? "",
    };

    if (this.id) {
      doc.id = this.id;
    }

    if (this.prenomina_id) {
      prenomina_doc.id = this.prenomina_id;
    }
    if (this.conciliacion_id) {
      conciliacion_doc.id = this.conciliacion_id;
    }
    // const data: DataFormularioOrden = {
    //   doc: doc,
    // };
    const data: DataFormularioOrden = {
      doc: doc,
      borrar: this.archivosBorrar,
      archivo: {},
      archivo2: {},
      archivo3: {},
      archivo4: {},
      archivo5: {},
      archivo6: {},
    };

    const prenomina_data: DataFormularioPrenomina = {
      doc: prenomina_doc,
      borrar: this.prenomina_archivosBorrar,
      archivo: {},
    };

    const conciliacion_data: DataFormularioRegistrosEgresos = {
      doc: conciliacion_doc,
      borrar: this.conciliacion_archivosBorrar,
      imagen: {},
    };

    if (this.archivo) {
      data.archivo = {
        [idtify(this.archivo.id)]: this.archivo,
      };
    }
    if (this.archivo2) {
      data.archivo2 = {
        [idtify(this.archivo2.id)]: this.archivo2,
      };
    }
    if (this.archivo3) {
      data.archivo3 = {
        [idtify(this.archivo3.id)]: this.archivo3,
      };
    }
    if (this.archivo4) {
      data.archivo4 = {
        [idtify(this.archivo4.id)]: this.archivo4,
      };
    }
    if (this.archivo5) {
      data.archivo5 = {
        [idtify(this.archivo5.id)]: this.archivo5,
      };
    }
    if (this.archivo6) {
      data.archivo6 = {
        [idtify(this.archivo6.id)]: this.archivo6,
      };
    }
    this.onClickGuardar(data);
    if (
      this.tipoArchivo === "BOLETA/FACTURA" &&
      this.archivo &&
      this.verificaExistenciaPrenomina(data.doc) === false
    ) {
      prenomina_data.archivo = {
        [idtify(this.archivo.id)]: this.archivo,
      };
      this.onClickGuardarPrenomina(prenomina_data);
      this.onClickGuardarConciliacion(conciliacion_data);
    }
    if (
      this.tipoArchivo2 === "BOLETA/FACTURA" &&
      this.archivo2 &&
      this.verificaExistenciaPrenomina(data.doc) === false
    ) {
      prenomina_data.archivo = {
        [idtify(this.archivo2.id)]: this.archivo2,
      };
      this.onClickGuardarPrenomina(prenomina_data);
      this.onClickGuardarConciliacion(conciliacion_data);
    }

    if (
      this.tipoArchivo3 === "BOLETA/FACTURA" &&
      this.archivo3 &&
      this.verificaExistenciaPrenomina(data.doc) === false
    ) {
      prenomina_data.archivo = {
        [idtify(this.archivo3.id)]: this.archivo3,
      };
      this.onClickGuardarPrenomina(prenomina_data);
      this.onClickGuardarConciliacion(conciliacion_data);
    }

    if (
      this.tipoArchivo4 === "BOLETA/FACTURA" &&
      this.archivo4 &&
      this.verificaExistenciaPrenomina(data.doc) === false
    ) {
      prenomina_data.archivo = {
        [idtify(this.archivo4.id)]: this.archivo4,
      };
      this.onClickGuardarPrenomina(prenomina_data);
      this.onClickGuardarConciliacion(conciliacion_data);
    }

    if (
      this.tipoArchivo5 === "BOLETA/FACTURA" &&
      this.archivo5 &&
      this.verificaExistenciaPrenomina(data.doc) === false
    ) {
      prenomina_data.archivo = {
        [idtify(this.archivo5.id)]: this.archivo5,
      };
      this.onClickGuardarPrenomina(prenomina_data);
      this.onClickGuardarConciliacion(conciliacion_data);
    }

    if (
      this.tipoArchivo6 === "BOLETA/FACTURA" &&
      this.archivo6 &&
      this.verificaExistenciaPrenomina(data.doc) === false
    ) {
      prenomina_data.archivo = {
        [idtify(this.archivo6.id)]: this.archivo6,
      };
      this.onClickGuardarPrenomina(prenomina_data);
      this.onClickGuardarConciliacion(conciliacion_data);
    }
  }
}
