





































































// decoradores
import { Component, Vue, Watch } from "vue-property-decorator";
// vuex
import { mapActions, mapGetters } from "vuex";
// firebase
import { Firebase } from "@/config/firebase";
// tipos
import { Ruta } from "./typings/store/modules/rutas";
// componentes
import NavigationDrawer from "@/components/NavigationDrawer.vue";
import AppBar from "@/components/AppBar.vue";
import TabRutas from "@/components/TabRutas.vue";
import AppBar2 from "@/components/AppBar2.vue";
//import { Promocion } from "@/typings/store/plugins/easyFirestore/promociones";
//import { Sesion } from "@/typings/store/plugins/easyFirestore/sesiones";
import Login from "@/components/Login.vue";
import FormularioInicioSesion from "@/components/login/cliente/FormularioInicioSesion.vue";
import { Usuario } from "@/typings/store/plugins/easyFirestore/usuarios";
import DialogoExito from "@/components/DialogoExito.vue";

@Component({
  computed: mapGetters({
    getUsuario: "usuario/get",
    getDrawer: "drawer/get",
    getRutasUsuario: "usuario/getRutas",
    getCargando: "cargando/get",
    getRutasArray: "rutas/getArray",
    // getArrayEmpresas: "empresas/getArray",
    //getArrayPromociones: "promociones/getArray",
    // getArraySesiones: "sesiones/getArray",
    getRutas: "rutas/get",
  }),
  methods: mapActions({
    setDrawer: "drawer/set",
  }),
  components: {
    DialogoExito,
    FormularioInicioSesion,
    Login,
    AppBar2,
    TabRutas,
    NavigationDrawer,
    AppBar,
  },
})
export default class App extends Vue {
  created(): void {
    if (!this.$route.name) {
      this.$router.replace({ name: "inicio" });
    }
    if (Notification.permission !== "granted") {
      Notification.requestPermission();
    }
  }

  dialogPrimeraVezLogin = false;
  dialogoLogin = false;
  dialogoExito = {
    model: false,
    icono: "",
    texto: "",
  };

  get drawer(): boolean {
    return this.getDrawer;
  }

  set drawer(data: boolean) {
    this.setDrawer(data);
  }

  get cargando(): boolean {
    return this.getCargando;
  }

  get usuario(): Usuario | null {
    return this.getUsuario;
  }

  // get arraySesiones(): Array<Sesion> {
  // //   return this.getArraySesiones;
  // }

  // get arrayPromociones(): Array<Promocion> {
  //   return this.getArrayPromociones;
  // }

  get itemsRutas(): Array<Ruta> {
    return this.getRutasArray;
  }

  get rutasUsuario(): Array<Ruta> {
    return this.getRutasUsuario;
  }

  get tituloAppBar(): string {
    const nombreRuta = this.$route.name;
    if (!nombreRuta) {
      return "";
    }
    const ruta = this.itemsRutas.find((item) => {
      return item.to?.name === nombreRuta;
    });
    return ruta?.text ?? "";
  }

  @Watch("usuario")
  onChangeUsuario(val: Usuario | null, old: Usuario | null): void {
    if (val == old) {
      return;
    }
    if (val) {
      if (
        (val && this.$route.name === "inicio") ||
        this.$route.name !== "inicio"
      ) {
        if (val.tipo === "admin") {
          this.$router.push({ name: "principal" });
        }
        if (val.tipo === "cliente") {
          // if (!val.nombre || !val.ciudad || !val.telefono || !val.rut) {
          //   this.dialogPrimeraVezLogin = true;
          // }
          this.$router.push({ name: "principal" });
        }
        if (val.tipo === "relator") {
          this.$router.push({ name: "principal" });
        }
      }
    }
  }

  loginError(data: string): void {
    if (data === "inhabilitado") {
      this.dialogoExito = {
        model: true,
        icono: require("./assets/icons/cancelar-duo.svg"),
        texto: "¡Usuario inhabilitado!",
      };
    }
  }

  cerrarDialogoExito(): void {
    this.dialogoExito = {
      model: false,
      icono: "",
      texto: "",
    };
  }

  logout(): void {
    Firebase.auth().signOut();
  }
}
