

































































































































// decoradores
import { Vue, Component } from "vue-property-decorator";
// vuex
import { mapActions, mapGetters } from "vuex";
// tipos

// componentes
import TablaCondicionComercial from "@/components/condicionesComerciales/Tabla.vue";
import FormularioCondicionComercial from "@/components/condicionesComerciales/Formulario.vue";
import { DataFormularioCondicionComercial } from "@/typings/components/condicionesComerciales/formulario";
import DialogoExito from "@/components/DialogoExito.vue";
import DialogoEliminar from "@/components/DialogoEliminar.vue";
import InfoCondicionComercial from "@/components/condicionesComerciales/Info.vue";
//import BotonesCrud from "@/components/BotonesCrud.vue";
import MensajeEliminar from "@/components/MensajeEliminar.vue";
//import TituloVista from "@/components/TituloVista.vue";
import {
  CondicionComercial,
  CondicionesComerciales,
  //  Regiones,
} from "@/typings/store/plugins/easyFirestore/condicionesComerciales";
//import { DataFormularioRegion } from "@/typings/components/regiones/formulario";
import CargarBancosExcel from "@/components/excel/CargarBancos.vue";
import Uf from "@/components/condicionesComerciales/Uf.vue";

const routeGuard = (vm: Vue): boolean => {
  const permisos = vm.getUsuario?.permisos;
  if (permisos && permisos["condicionesComerciales"]) {
    return true;
  }

  const rutas = vm.getRutasUsuario;
  if (rutas.length > 0) {
    const to = rutas[0].to;
    if (to) {
      if (vm.$route.name !== to.name) {
        vm.$router.replace(to);
      }
      return false;
    }
  }
  if (vm.$route.name !== "inicio") {
    vm.$router.replace({ name: "inicio" });
  }
  return false;
};

@Component({
  computed: mapGetters({
    getUsuarios: "usuarios/get",
    getCondicionesComerciales: "condicionesComerciales/get",
    getArrayUsuarios: "usuarios/getArray",
    getUsuario: "usuario/get",
    getRutas: "rutas/get",
    getRutasUsuario: "usuario/getRutas",
    getArrayCondicionesComerciales: "condicionesComerciales/getArray",
  }),
  methods: mapActions({
    setCondicionComercial: "condicionesComerciales/set",
    deleteCondicionComercial: "condicionesComerciales/delete",
    insertBatchCondicionesComerciales: "condicionesComerciales/insertBatch",
    //setArchivo: "archivos/set",
    //deleteArchivo: "archivos/delete",
  }),
  beforeRouteEnter(to, from, next) {
    next(routeGuard);
  },
  components: {
    DialogoEliminar,
    DialogoExito,
    TablaCondicionComercial,
    FormularioCondicionComercial,
    InfoCondicionComercial,
    //BotonesCrud,
    MensajeEliminar,
    CargarBancosExcel,
    Uf,
    //TituloVista,
  },
})
export default class VistaBancos extends Vue {
  created(): void {
    if (!routeGuard(this)) {
      return;
    }
  }

  cargando = false;
  dialogoExito = {
    model: false,
    texto: "",
    icono: "",
  };
  search = "";
  condicionComercial: CondicionComercial | null = null;
  dialogoInfo = false;
  dialogoFormulario = false;
  dialogoEliminar = false;
  textoEliminar = "¿ESTÁS SEGURO QUE DESEAS ELIMINAR ESTA CONDICION COMERCIAL?";
  dialogoCargarExcel = false;

  get condicionesComerciales(): CondicionesComerciales {
    return this.getCondicionesComerciales;
  }

  get items(): Array<CondicionComercial> {
    return this.getArrayCondicionesComerciales;
  }

  clickItem(val: { id: string } | null): void {
    this.condicionComercial = val ? this.condicionesComerciales[val.id] : null;
  }

  clickPlus(): void {
    this.condicionComercial = null;
    this.dialogoFormulario = true;
  }

  async clickGuardar(data: DataFormularioCondicionComercial): Promise<void> {
    this.cargando = true;
    const doc = data.doc;
    await this.setCondicionComercial(doc);
    if (doc.id != this.condicionComercial?.id) {
      this.dialogoExito = {
        model: true,
        texto: "¡Condicion creada con éxito!",
        icono: require("../assets/icons/double-check-duo.svg"),
      };
    }
    if (doc.id == this.condicionComercial?.id) {
      this.dialogoExito = {
        model: true,
        texto: "¡Condicion editada con éxito!",
        icono: require("../assets/icons/editar.svg"),
      };
      this.condicionComercial = doc;
    }
    this.cerrarDialogoFormulario();
    this.cargando = false;
  }

  cerrarDialogoExito(): void {
    this.dialogoExito = {
      model: false,
      texto: "",
      icono: "",
    };
  }

  clickEliminar(): void {
    const condicionComercial = this.condicionComercial;
    if (!condicionComercial?.id) {
      console.error("error id: ", condicionComercial);
      return;
    }
    this.deleteCondicionComercial(condicionComercial.id);
    this.condicionComercial = null;
    this.cerrarDialogoEliminar();
  }

  abrirDialogoInfo(condicionComercial: CondicionComercial): void {
    if (!condicionComercial.id) {
      console.error("error id: ", condicionComercial);
      return;
    }
    const doc = this.condicionesComerciales[condicionComercial.id];
    if (doc) {
      this.condicionComercial = doc;
      this.dialogoInfo = true;
    }
  }

  cerrarDialogoInfo(): void {
    this.dialogoInfo = false;
    this.condicionComercial = null;
  }

  abrirDialogoFormulario(condicionComercial: CondicionComercial): void {
    if (!condicionComercial.id) {
      console.error("error id: ", condicionComercial);
      return;
    }
    const doc = this.condicionesComerciales[condicionComercial.id];
    if (doc) {
      this.condicionComercial = doc;
      this.dialogoFormulario = true;
    }
  }

  abrirDialogoEliminar(condicionComercial: CondicionComercial): void {
    if (!condicionComercial.id) {
      console.error("error id: ", condicionComercial);
      return;
    }
    const doc = this.condicionesComerciales[condicionComercial.id];
    if (doc) {
      this.dialogoEliminar = true;
      this.condicionComercial = doc;
    }
  }

  cerrarDialogoEliminar(): void {
    this.dialogoEliminar = false;
    this.condicionComercial = null;
  }

  cerrarDialogoFormulario(): void {
    this.dialogoFormulario = false;
    this.condicionComercial = null;
  }
}
