
















































































































































































// decoradores
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
// tipos
import { HeadersDataTable } from "@/typings/vuetify/vDataTable";
import { MatrizPago } from "@/typings/store/plugins/easyFirestore/matrizPagos";
import { ItemsTablaMatrizPagos } from "@/typings/components/matrizPagos/tabla";
import "@/helpers/extensions";

@Component
export default class TablaUsuarios extends Vue {
  @Prop() readonly opciones!: boolean;
  @Prop() readonly search!: string;
  @Prop() readonly matrizPagos!: Array<MatrizPago>;

  options = {
    itemsPerPage: -1,
  };
  headerProps = {
    sortIcon: "mdi-chevron-down",
  };

  get headers(): HeadersDataTable {
    return [
      //{text: "", value: "estado", sortable: false},
      { text: "Centro Costo", value: "centroCosto" },
      { text: "Periodo", value: "periodo" },
      { text: "Cuenta Estado Resultado", value: "cuentaEstadoResultado" },
      { text: "Cuenta Control Gasto", value: "cuentaControlGasto" },
      { text: "Tipo Gasto", value: "tipoGasto" },
      { text: "Aplica", value: "aplica" },
      { text: "Monto Mes Anterio", value: "montoMesAnterior" },
      { text: "Monto", value: "monto" },

      { text: "", value: "opciones", sortable: false },
    ];
  }

  get items(): ItemsTablaMatrizPagos {
    return this.matrizPagos.map((matrizPago) => {
      const centroCosto = matrizPago.centroCosto;
      const periodo = matrizPago.periodo;
      const cuentaEstadoResultado = matrizPago.cuentaEstadoResultado;
      const cuentaControlGasto = matrizPago.cuentaControlGasto;
      const tipoGasto = matrizPago.tipoGasto;
      const aplica = matrizPago.aplica;
      const montoMesAnterior = matrizPago.montoMesAnterior;
      const monto = matrizPago.monto;

      return {
        centroCosto,
        periodo,
        cuentaEstadoResultado,
        cuentaControlGasto,
        tipoGasto,
        aplica,
        montoMesAnterior,
        monto,

        data: matrizPago,
      };
    });
  }

  //   actualizarEstado(item: Usuario): void {
  //     let estado: EstadoUsuario;
  //     item.estado === 'libre' ? estado = 'bloqueado' : estado = 'libre';
  //     item.estado = estado;
  //     this.onCambiarEstado(item);
  //   }

  //   @Emit("cambiar-estado")
  //   onCambiarEstado(usuario: Usuario): Usuario {
  //     return usuario;
  //   }

  @Emit("abrir-info")
  onAbrirInfo(matrizPago: MatrizPago): MatrizPago {
    return matrizPago;
  }

  @Emit("abrir-editar")
  onAbrirEditar(matrizPago: MatrizPago): MatrizPago {
    return matrizPago;
  }

  @Emit("abrir-eliminar")
  onAbrirEliminar(matrizPago: MatrizPago): MatrizPago {
    return matrizPago;
  }
}
