




























































































































































































































































































// decoradores
import { Vue, Component } from "vue-property-decorator";
// vuex
import { mapActions, mapGetters } from "vuex";
// tipos

// componentes
import TablaCajaChicaTalca from "@/components/cajaChicasTalca/Tabla.vue";
import FormularioCajaChicaTalca from "@/components/cajaChicasTalca/Formulario.vue";
import { DataFormularioCajaChicaTalca } from "@/typings/components/cajaChicasTalca/formulario";
import DialogoExito from "@/components/DialogoExito.vue";
import DialogoEliminar from "@/components/DialogoEliminar.vue";
import InfoCajaChicaLinares from "@/components/cajaChicasTalca/Info.vue";
//import BotonesCrud from "@/components/BotonesCrud.vue";
import MensajeEliminar from "@/components/MensajeEliminar.vue";
//import TituloVista from "@/components/TituloVista.vue";
import {
  CajaChicaTalca,
  CajaChicasTalca,
  //  Regiones,
} from "@/typings/store/plugins/easyFirestore/cajaChicasTalca";
//import { DataFormularioRegion } from "@/typings/components/regiones/formulario";
import CargarBancosExcel from "@/components/excel/CargarBancos.vue";
import { idtify } from "@/helpers/string";

const routeGuard = (vm: Vue): boolean => {
  const permisos = vm.getUsuario?.permisos;
  if (permisos && permisos["cajaChicasTalca"]) {
    return true;
  }

  const rutas = vm.getRutasUsuario;
  if (rutas.length > 0) {
    const to = rutas[0].to;
    if (to) {
      if (vm.$route.name !== to.name) {
        vm.$router.replace(to);
      }
      return false;
    }
  }
  if (vm.$route.name !== "inicio") {
    vm.$router.replace({ name: "inicio" });
  }
  return false;
};

@Component({
  computed: mapGetters({
    getUsuarios: "usuarios/get",
    getCajaChicasTalca: "cajaChicasTalca/get",
    getArrayUsuarios: "usuarios/getArray",
    getUsuario: "usuario/get",
    getRutas: "rutas/get",
    getRutasUsuario: "usuario/getRutas",
    getArrayCajaChicasTalca: "cajaChicasTalca/getArray",
  }),
  methods: mapActions({
    setCajaChicaTalca: "cajaChicasTalca/set",
    deleteCajaChicaTalca: "cajaChicasTalca/delete",
    insertBatchCajaChicasTalca: "cajaChicasTalca/insertBatch",
    setArchivo: "archivos/set",
    deleteArchivo: "archivos/delete",
  }),
  beforeRouteEnter(to, from, next) {
    next(routeGuard);
  },
  components: {
    DialogoEliminar,
    DialogoExito,
    TablaCajaChicaTalca,
    FormularioCajaChicaTalca,
    InfoCajaChicaLinares,
    //BotonesCrud,
    MensajeEliminar,
    CargarBancosExcel,
    //TituloVista,
  },
})
export default class VistaBancos extends Vue {
  created(): void {
    if (!routeGuard(this)) {
      return;
    }
  }

  cargando = false;
  dialogoExito = {
    model: false,
    texto: "",
    icono: "",
  };
  search = "";
  cajaChicaTalca: CajaChicaTalca | null = null;
  dialogoInfo = false;
  dialogoFormulario = false;
  dialogoEliminar = false;
  textoEliminar =
    "¿ESTÁS SEGURO QUE DESEAS ELIMINAR ESTE REGISTRO DE CAJA CHICA?";
  dialogoCargarExcel = false;

  get cajaChicasTalca(): CajaChicasTalca {
    return this.getCajaChicasTalca;
  }

  get items(): Array<CajaChicaTalca> {
    return this.getArrayCajaChicasTalca.sort((a, b) => {
      if (a.numero < b.numero) {
        return 1;
      }
      if (a.numero > b.numero) {
        return -1;
      }
      return 0;
    });
  }

    get tipoUsuario(): string {
    if (this.getUsuario) {
      return this.getUsuario.tipo.toString();
    }
    return "";
  }

  //cuenta los datos de la tabla
  get totalItems(): number {
    return this.items.length;
  }

  //suma todos los ingresos de la tabla
  get totalIngresos(): number {
    return this.items.reduce((total, item) => {
      return total + parseInt(item.ingreso);
    }, 0);
  }

  //suma todos los egresos de la tabla
  get totalEgresos(): number {
    return this.items.reduce((total, item) => {
      return total + parseInt(item.egreso);
    }, 0);
  }

  get total(): number {
    return this.totalIngresos - this.totalEgresos;
  }

  clickItem(val: { id: string } | null): void {
    this.cajaChicaTalca = val ? this.cajaChicasTalca[val.id] : null;
  }

  clickPlus(): void {
    this.cajaChicaTalca = null;
    this.dialogoFormulario = true;
  }

  async clickGuardar(data: DataFormularioCajaChicaTalca): Promise<void> {
    this.cargando = true;
    const doc = data.doc;
    const archivo = data.archivo;
    const borrar = data.borrar;
    for (const key in archivo) {
      if (archivo[key].file) {
        const doc = archivo[key];
        const res = await this.setArchivo(doc);
        if (res && res.url) {
          this.$set(archivo, idtify(res.id), res);
        }
      }
    }
    for (const key in borrar) {
      if (borrar[key]) {
        const doc = borrar[key];
        await this.deleteArchivo(doc.id);
      }
    }
    doc.archivo = archivo;
    await this.setCajaChicaTalca(doc);
    if (doc.id != this.cajaChicaTalca?.id) {
      this.dialogoExito = {
        model: true,
        texto: "¡Registro Caja Chica creado con éxito!",
        icono: require("../assets/icons/double-check-duo.svg"),
      };
    }
    if (doc.id == this.cajaChicaTalca?.id) {
      this.dialogoExito = {
        model: true,
        texto: "¡Registro Caja Chica editado con éxito!",
        icono: require("../assets/icons/editar.svg"),
      };
      this.cajaChicaTalca = doc;
    }
    this.cerrarDialogoFormulario();
    this.cargando = false;
  }

  cerrarDialogoExito(): void {
    this.dialogoExito = {
      model: false,
      texto: "",
      icono: "",
    };
  }

  clickEliminar(): void {
    const cajaChicaTalca = this.cajaChicaTalca;
    if (!cajaChicaTalca?.id) {
      console.error("error id: ", cajaChicaTalca);
      return;
    }
    this.deleteCajaChicaTalca(cajaChicaTalca.id);
    this.cajaChicaTalca = null;
    this.cerrarDialogoEliminar();
  }

  abrirDialogoInfo(cajaChicaTalca: CajaChicaTalca): void {
    if (!cajaChicaTalca.id) {
      console.error("error id: ", cajaChicaTalca);
      return;
    }
    const doc = this.cajaChicasTalca[cajaChicaTalca.id];
    if (doc) {
      this.cajaChicaTalca = doc;
      this.dialogoInfo = true;
    }
  }

  cerrarDialogoInfo(): void {
    this.dialogoInfo = false;
    this.cajaChicaTalca = null;
  }

  abrirDialogoFormulario(cajaChicaTalca: CajaChicaTalca): void {
    if (!cajaChicaTalca.id) {
      console.error("error id: ", cajaChicaTalca);
      return;
    }
    const doc = this.cajaChicasTalca[cajaChicaTalca.id];
    if (doc) {
      this.cajaChicaTalca = doc;
      this.dialogoFormulario = true;
    }
  }

  abrirDialogoEliminar(cajaChicaTalca: CajaChicaTalca): void {
    if (!cajaChicaTalca.id) {
      console.error("error id: ", cajaChicaTalca);
      return;
    }
    const doc = this.cajaChicasTalca[cajaChicaTalca.id];
    if (doc) {
      this.dialogoEliminar = true;
      this.cajaChicaTalca = doc;
    }
  }

  cerrarDialogoEliminar(): void {
    this.dialogoEliminar = false;
    this.cajaChicaTalca = null;
  }

  cerrarDialogoFormulario(): void {
    this.dialogoFormulario = false;
    this.cajaChicaTalca = null;
  }
}
