

















































































































































































































import {Component, Emit, Prop, Vue} from "vue-property-decorator";
import {Curso, Cursos} from "@/typings/store/plugins/easyFirestore/cursos";
import {Sesion, Sesiones} from "@/typings/store/plugins/easyFirestore/sesiones";
import {Pago} from "@/typings/store/plugins/easyFirestore/pagos";
import {Usuario} from "@/typings/store/plugins/easyFirestore/usuarios";
import {Promocion} from "@/typings/store/plugins/easyFirestore/promociones";
import {Carrito} from "@/typings/store/plugins/easyFirestore/carritos";
import DeseaCurso from "@/components/carrito/DeseaCurso.vue";
import SVGIcon from "@/components/SVGIcon.vue";
import Footer from "@/components/inicio/Footer.vue";
import DialogoExito from "@/components/DialogoExito.vue";
import {Inscripcion} from "@/typings/store/plugins/easyFirestore/inscripciones";

@Component({
  components: {DialogoExito, Footer, SVGIcon, DeseaCurso}
})
export default class DetalleCarrito extends Vue {
  @Prop() readonly carrito!: Carrito;
  @Prop() readonly usuario!: Usuario;
  @Prop() readonly cargando!: boolean;
  @Prop() readonly arrayPromociones!: Promocion[];
  @Prop() readonly arrayInscripciones!: Inscripcion[];
  @Prop() readonly arraySesiones!: Sesion[];
  @Prop() readonly cursos!: Cursos;
  @Prop() readonly arrayCursos!: Curso[];
  cuponValidado = false;
  codigo = "";
  dialogoExito = {
    model: false,
    icono: "",
    texto: ""
  };

  created(): void {
    if (this.carrito.promocion && this.carrito.promocion.codigo) {
      this.codigo = this.carrito.promocion.codigo
      this.validarCodigoDescuento(this.codigo);
    }
    if (Object.values(this.carrito.sesiones).length > 0) {
      for (const session of Object.values(this.carrito.sesiones)) {
        for (const cursso of Object.values(this.carrito.cursos)) {
          if (session.sesion.curso.id === cursso.curso.id) {
            this.eliminarSesion(session.sesion);
          }
        }
      }
    }
    // if (Object.values(this.carrito.sesiones).length > 0) {
    //   this.dialogDeseaCurso = true;
    // }
  }

  dialogDeseaCurso = false;

  get estadosProductos(): boolean {
    const sesionesEstado = Object.values(this.carrito.sesiones).some(sesion => sesion.error);
    const cursosEstado = Object.values(this.carrito.cursos).some(curso => curso.error);
    const carritoEstado = this.carrito.estado === "revisado";
    return !sesionesEstado && !cursosEstado && carritoEstado;
  }

  get cursosRestantesPromocion(): Curso[] {
    return this.arrayCursos.filter(curso => {
      const estado = curso.estado === 'aceptado';
      const promocion = curso.descuento && curso.descuento !== '0%';
      const productoCarrito = Object.values(this.carrito.cursos).some(cursoCarrito => cursoCarrito.id === curso.id);
      const cursoIns = this.arrayInscripciones.filter(inscripcion => inscripcion.cliente.id === this.usuario.id).some(ins => Object.values(ins.pago.cursos).some(cursso => cursso.id === curso.id));
      return estado && promocion && !productoCarrito && !cursoIns;
    });
  }

  get promoCursos(): Promocion[] {
    return this.arrayPromociones.filter(promo => Object.values(promo.cursos).some(curso => Object.values(this.carrito.cursos).some(carritoCurso => carritoCurso.id === curso.id)));
  }


  get total(): number {
    const totaal = Object.values(this.carrito.cursos).reduce((sum, cursoCarrito) => (sum += cursoCarrito.curso.precio - this.descuentoCurso(cursoCarrito.curso)), 0) + Object.values(this.carrito.sesiones).reduce((sum, sesionCarrito) => sum += sesionCarrito.sesion.precio, 0);
    const descuentoCodigo = this.descuentoCodigo(this.codigo);
    return totaal - descuentoCodigo < 0 ? 0 : Math.floor(totaal - descuentoCodigo);

  }

  get subtotal(): number {
    const subTotal = Object.values(this.carrito.cursos).reduce((sum, cursoCarrito) => (sum += cursoCarrito.curso.precio - this.descuentoCurso(cursoCarrito.curso)), 0) + Object.values(this.carrito.sesiones).reduce((sum, sesionCarrito) => sum += sesionCarrito.sesion.precio, 0);
    return subTotal < 0 ? 0 : subTotal;
  }

  @Emit("close")
  onClose(): null {
    return null;
  }

  @Emit("guardar-pago")
  onClickGuardarPago(pago: Pago): Pago {
    return pago;
  }

  @Emit("agregar-pack")
  onAgregarPack(cursos: Cursos): Cursos {
    return cursos;
  }

  @Emit("agregar-curso")
  onAgregarCurso(curso: Curso): Curso {
    return curso;
  }

  @Emit("agregar-promo-carrito")
  onAgregarPromoCarrito(promo: Promocion): Promocion {
    return promo;
  }

  @Emit("eliminar-curso")
  onClickEliminarCurso(curso: Curso): Curso {
    return curso;
  }

  @Emit("eliminar-sesion")
  onClickEliminarSesion(sesion: Sesion): Sesion {
    return sesion;
  }

  comprarCurso(data: { curso: Curso, sesion: Sesion }): void {
    if (data.sesion.id) {
      const sesionesDelCurso = Object.values(this.carrito.sesiones).filter(sesionCarrito => sesionCarrito.sesion.curso.id === data.curso.id);
      for (const sesion of sesionesDelCurso) {
        this.onClickEliminarSesion(sesion.sesion);
      }
    }
    if (data.curso.id) {
      this.onAgregarCurso(data.curso);
    }

    if (Object.values(this.carrito.sesiones).length === 0) this.dialogDeseaCurso = false;

  }

  cantSesionesCurso(curso: Curso): number {
    return this.arraySesiones.filter(sesion => sesion.curso.id === curso.id).length;
  }

  descuentoCurso(curso: Curso): number {
    let descuento = 0;
    if (curso && curso.descuento) {
      if (curso.descuento.includes('%')) {
        const valorSinSigno = curso.descuento.replace('%', '').replace(',', '.');
        descuento = (curso.precio * parseFloat(valorSinSigno)) / 100;
        return descuento;
      } else {
        isNaN(parseInt(curso.descuento)) ? descuento = 0 : descuento = parseInt(curso.descuento);
        return descuento;
      }
    } else {
      return 0;
    }
  }

  descuentoPromo(curso: Curso): number {
    let descuento = 0;
    const fechaHoy = new Date();
    const promo = this.arrayPromociones.filter(promocion => Object.values(promocion.cursos).some(cursoo => cursoo.id === curso.id) && new Date(promocion.inicio) <= fechaHoy && new Date(promocion.fin) >= fechaHoy)[0]
    if (!promo) {
      return descuento;
    }
    if (promo.descuento.includes('%')) {
      const valorSinSigno = promo.descuento.replace('%', '').replace(',', '.');
      descuento = (curso.precio * parseFloat(valorSinSigno)) / 100;
      return descuento;
    } else {
      return parseFloat(promo.descuento)
    }
  }

  comprar(): void {
    const sesionesArray: Sesion[] = this.arraySesiones.slice();
    const cursos = Object.values(this.carrito.cursos ?? {}).reduce((docs, doc) => {
      const curso = doc.curso;
      if (!curso.id) {
        console.error("doc.id == undefined => ", doc);
        return docs;
      }
      docs[curso.id] = curso;
      return docs;
    }, {} as Cursos);


    const sesiones = Object.values(this.carrito.sesiones ?? {}).reduce((docs, doc) => {
      const sesion = doc.sesion
      if (!sesion.id) {
        console.error("doc.id == undefined => ", doc);
        return docs;
      }
      docs[sesion.id] = sesion;
      return docs;
    }, {} as Sesiones);

    let sesionesCurso: Sesiones = {};
    for (const curso of Object.values(cursos)) {
      for (const sesion of sesionesArray.filter(sesiones => sesiones.curso.id === curso.id)) {
        const sesion2 = Object.assign({}, sesion, {
          precio: 0
        });
        if (sesion2 && sesion2.id) {
          sesionesCurso[sesion2.id] = sesion2
        }
      }
    }

    const resultadoSesiones = {
      ...sesiones, ...sesionesCurso
    }

    const promocion =
        this.arrayPromociones.find(
            (promocion) => promocion.codigo && promocion.codigo === this.codigo
        ) ?? null;

    const pago: Pago = {
      cliente: this.usuario,
      cursos: cursos,
      sesiones: resultadoSesiones,
      promocion: promocion ?? null,
      urlRetorno: location.origin + "/compraExitosa",
      estado: "pendiente",
    };
    this.onClickGuardarPago(pago);
  }

  validarCodigoDescuento(codigo: string): void {
    console.log(codigo);
    let fechaHoy = new Date();
    let promo = null;
    console.log(this.arrayPromociones);
    if (codigo) {
      promo = this.arrayPromociones.filter(promocion => promocion.codigo === codigo && new Date(promocion.inicio) <= fechaHoy && new Date(promocion.fin) >= fechaHoy)[0];
    }
    if (promo) {
      this.dialogoExito = {
        model: true,
        icono: require('../../assets/icons/double-check-duo.svg'),
        texto: "¡Cupón validado!"
      };
      this.onAgregarPromoCarrito(promo);
      this.cuponValidado = true;
    } else {
      this.dialogoExito = {
        model: true,
        icono: require("../../assets/icons/cancelar-duo.svg"),
        texto: "¡Cupón invalido!",
      };
      this.cuponValidado = false;
    }
  }

  descuentoCodigo(codigo: string): number {
    let fechaHoy = new Date();
    const promo = this.arrayPromociones.find(promocion => promocion.codigo === codigo && new Date(promocion.inicio) <= fechaHoy && new Date(promocion.fin) >= fechaHoy);
    let descuento = 0;
    if (!promo || !this.cuponValidado) {
      return 0;
    }
    if (promo.descuento.includes('%')) {
      const valorSinSigno = promo.descuento.replace('%', '').replace(',', '.');
      descuento = (this.subtotal * parseFloat(valorSinSigno)) / 100;
      return descuento;
    } else {
      return parseFloat(promo.descuento);
    }
  }

  eliminarSesion(sesion: Sesion): void {
    this.onClickEliminarSesion(sesion);
  }

  eliminarCurso(curso: Curso): void {
    this.onClickEliminarCurso(curso);
  }

  cerrarDialogoExito(): void {
    this.dialogoExito = {
      model: false,
      icono: "",
      texto: ""
    };
  }

  async goTo(ruta: string): Promise<void> {
    if (this.$route.name !== ruta) {
      await this.$router.push({name: ruta});
    }
  }

}
