




















































































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { TipoEgreso } from "@/typings/store/plugins/easyFirestore/tipoEgresos";
// validaciones
import { required } from "vuelidate/lib/validators";
import { DataFormularioTipoEgreso } from "@/typings/components/tipoEgresos/formulario";
import SVGIcon from "@/components/SVGIcon.vue";


@Component({
  components: {
    SVGIcon,
  },
  validations: {
    nombre: { required },
    posicion: { required },
  },
})
export default class FormularioTipoEgreso extends Vue {
  @Prop() readonly tipoEgreso!: TipoEgreso | null;
    @Prop() readonly cargando!: boolean;
  //   @Prop() readonly rutas!: Rutas;
  //   @Prop() readonly ciudades!: Ciudad[];
  //   @Prop() readonly cargando!: boolean;
  //   @Prop() readonly regiones!: Region[];

  created(): void {
    // Object.keys(this.rutas).forEach((key) => {
    //   this.$set(this.permisos, key, false);
    // });
    // console.log(this.permisos);
    const tipoEgreso = this.tipoEgreso;
    if (!tipoEgreso) {
      return;
    }
    this.id = tipoEgreso.id ?? "";
    this.nombre = tipoEgreso.nombre;
    this.posicion = tipoEgreso.posicion ?? "";
  }

  id: string | null = null;

  nombre = "";
  posicion = "";

  get titulo(): string {
    return this.tipoEgreso ? "editar tipoEgreso" : "nuevo tipoEgreso";
  }

  //   get permisosKey(): Array<string> {
  //     // return Object.keys(this.permisos).filter((key) => {
  //     //   if(this.rutas[key].tipos.includes(this.tipo)){
  //     //     this.permisos[key] = true;
  //     //   }
  //     // });
  //     return Object.keys(this.permisos).filter((key) => {
  //       if (!this.rutas[key]) {
  //         return false;
  //       }
  //       const tipo = this.rutas[key].tipos.includes(this.tipo);
  //       return tipo;
  //     });
  //   }

  get nombreError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.nombre.$dirty) return errors;
    if (!this.$v.nombre.required) errors.push("Completar datos obligatorios");
    return errors;
  }

  get posicionError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.posicion.$dirty) return errors;
    if (!this.$v.posicion.required) errors.push("Completar datos obligatorios");
    return errors;
  }

  @Emit("close")
  onClose(): null {
    return null;
  }

  @Emit("cerrar-dialogo")
  onCerrarDialogo(): void {
    return;
  }

  @Emit("click-guardar")
  onClickGuardar(data: DataFormularioTipoEgreso): DataFormularioTipoEgreso {
    return data;
  }

  //   @Watch("permisosKey", { immediate: true })
  //   onTipoChange(): void {
  //     console.log("a");
  //     for (const key of Object.keys(this.permisos)) {
  //       if (this.permisos[key]) {
  //         this.permisos[key] = false;
  //       }
  //     }
  //     this.permisosKey.forEach((key) => {
  //       this.permisos[key] = true;
  //     });
  //   }

  //   nombrePermiso(key: string): string {
  //     return this.rutas[key].text;
  //   }

  guardar(): void {
    this.$v.$touch();
    console.log(this.$v.$invalid);
    const doc: TipoEgreso = {
      nombre: this.nombre.toUpperCase(),
      posicion: this.posicion.toLowerCase(),
    };
    if (this.id) {
      doc.id = this.id;
    }
    const data: DataFormularioTipoEgreso = {
      doc: doc,
    };
    this.onClickGuardar(data);
  }
}
