

































































































































// decoradores
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { Cliente } from "@/typings/store/plugins/easyFirestore/clientes";
import SVGIcon from "@/components/SVGIcon.vue";
// tipos

// tipos
@Component({
  components: {
    SVGIcon,
  },
})
export default class InfoCliente extends Vue {
  @Prop() readonly cliente!: Cliente;
  @Prop({ type: Boolean }) readonly showTitulo!: boolean;

  get id(): string {
    return this.cliente.id || "N/R";
  }

  get codigo(): string {
    return this.cliente.codigo || "N/R";
  }

  get nombre(): string {
    return this.cliente.nombre || "N/R";
  }

  get rut(): string {
    return this.cliente.rut || "N/R";
  }

  get giro(): string {
    return this.cliente.giro || "N/R";
  }

  get telefono(): string {
    return this.cliente.telefono || "N/R";
  }

  get correo(): string {
    return this.cliente.correo || "N/R";
  }

  @Emit("cerrar")
  onCerrar(): void {
    return;
  }
}
