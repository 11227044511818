












































































































































// decoradores
import { Vue, Component } from "vue-property-decorator";
// vuex
import { mapActions, mapGetters } from "vuex";
// tipos

// componentes
import TablaIngreso from "@/components/ingresos/Tabla.vue";
import FormularioIngreso from "@/components/ingresos/Formulario.vue";
import { DataFormularioIngreso } from "@/typings/components/ingresos/formulario";
import DialogoExito from "@/components/DialogoExito.vue";
import DialogoEliminar from "@/components/DialogoEliminar.vue";
import InfoIngreso from "@/components/ingresos/Info.vue";
//import BotonesCrud from "@/components/BotonesCrud.vue";
import MensajeEliminar from "@/components/MensajeEliminar.vue";
//import TituloVista from "@/components/TituloVista.vue";
import {
  Ingreso,
  Ingresos,
  //  Regiones,
} from "@/typings/store/plugins/easyFirestore/ingresos";

//import { DataFormularioRegion } from "@/typings/components/regiones/formulario";
import CargarIngresosExcel from "@/components/excel/CargarIngresos.vue";


import {
  PlanCuentaIngreso,
  PlanCuentasIngresos,
  //  Regiones,
} from "@/typings/store/plugins/easyFirestore/planCuentasIngresos";


const routeGuard = (vm: Vue): boolean => {
  const permisos = vm.getUsuario?.permisos;
  if (permisos && permisos["ingresos"]) {
    return true;
  }

  const rutas = vm.getRutasUsuario;
  if (rutas.length > 0) {
    const to = rutas[0].to;
    if (to) {
      if (vm.$route.name !== to.name) {
        vm.$router.replace(to);
      }
      return false;
    }
  }
  if (vm.$route.name !== "inicio") {
    vm.$router.replace({ name: "inicio" });
  }
  return false;
};

@Component({
  computed: mapGetters({
    getUsuarios: "usuarios/get",
    getIngresos: "ingresos/get",
    getArrayUsuarios: "usuarios/getArray",
    getUsuario: "usuario/get",
    getRutas: "rutas/get",
    getRutasUsuario: "usuario/getRutas",
    getArrayIngresos: "ingresos/getArray",
    getArrayBancos: "bancos/getArray",
    getArrayCodCuentas: "codCuentas/getArray",
    getArrayClientes: "clientes/getArray",
    getArrayCentrosCostos: "centrosCostos/getArray",
    getArrayLocales: "locales/getArray",

    getPlanCuentasIngresos: "planCuentasIngresos/get",
    getArrayPlanCuentasIngresos: "planCuentasIngresos/getArray",

    

  }),
  methods: mapActions({
    setIngreso: "ingresos/set",
    deleteIngreso: "ingresos/delete",
    insertBatchIngresos: "ingresos/insertBatch",
    //setArchivo: "archivos/set",
    //deleteArchivo: "archivos/delete",
  }),
  beforeRouteEnter(to, from, next) {
    next(routeGuard);
  },
  components: {
    DialogoEliminar,
    DialogoExito,
    TablaIngreso,
    FormularioIngreso,
    InfoIngreso,
    //BotonesCrud,
    MensajeEliminar,
    CargarIngresosExcel,
    //TituloVista,
  },
})
export default class VistaBancos extends Vue {
  created(): void {
    if (!routeGuard(this)) {
      return;
    }
  }

  cargando = false;
  dialogoExito = {
    model: false,
    texto: "",
    icono: "",
  };
  search = "";
  ingreso: Ingreso | null = null;
  dialogoInfo = false;
  dialogoFormulario = false;
  dialogoEliminar = false;
  textoEliminar = "¿ESTÁS SEGURO QUE DESEAS ELIMINAR ESTE BANCO?";
  dialogoCargarExcel = false;
  planCuentaIngreso: PlanCuentaIngreso | null = null;

  get ingresos(): Ingresos {
    return this.getIngresos;
  }

  get items(): Array<Ingreso> {
    return this.getArrayIngresos;
  }

  get planCuentasIngresos(): PlanCuentasIngresos {
    return this.getPlanCuentasIngresos;
  }

  get itemsPlanCuentasIngresos(): Array<PlanCuentaIngreso> {
    return this.getArrayPlanCuentasIngresos;
  }

  clickItem(val: { id: string } | null): void {
    this.ingreso = val ? this.ingresos[val.id] : null;
  }

  clickPlus(): void {
    this.ingreso = null;
    this.dialogoFormulario = true;
  }

  async clickGuardar(data: DataFormularioIngreso): Promise<void> {
    this.cargando = true;
    const doc = data.doc;
    await this.setIngreso(doc);
    if (doc.id != this.ingreso?.id) {
      this.dialogoExito = {
        model: true,
        texto: "¡Ingreso creado con éxito!",
        icono: require("../assets/icons/double-check-duo.svg"),
      };
    }
    if (doc.id == this.ingreso?.id) {
      this.dialogoExito = {
        model: true,
        texto: "¡Ingreso editado con éxito!",
        icono: require("../assets/icons/editar.svg"),
      };
      this.ingreso = doc;
    }
    this.cerrarDialogoFormulario();
    this.cargando = false;
  }

  cerrarDialogoExito(): void {
    this.dialogoExito = {
      model: false,
      texto: "",
      icono: "",
    };
  }

  clickEliminar(): void {
    const ingreso = this.ingreso;
    if (!ingreso?.id) {
      console.error("error id: ", ingreso);
      return;
    }
    this.deleteIngreso(ingreso.id);
    this.ingreso = null;
    this.cerrarDialogoEliminar();
  }

  abrirDialogoInfo(ingreso: Ingreso): void {
    if (!ingreso.id) {
      console.error("error id: ", ingreso);
      return;
    }
    const doc = this.ingresos[ingreso.id];
    if (doc) {
      this.ingreso = doc;
      this.dialogoInfo = true;
    }
  }

  cerrarDialogoInfo(): void {
    this.dialogoInfo = false;
    this.ingreso = null;
  }

  abrirDialogoFormulario(ingreso: Ingreso): void {
    if (!ingreso.id) {
      console.error("error id: ", ingreso);
      return;
    }
    const doc = this.ingresos[ingreso.id];
    if (doc) {
      this.ingreso = doc;
      this.dialogoFormulario = true;
    }
  }

  abrirDialogoEliminar(ingreso: Ingreso): void {
    if (!ingreso.id) {
      console.error("error id: ", ingreso);
      return;
    }
    const doc = this.ingresos[ingreso.id];
    if (doc) {
      this.dialogoEliminar = true;
      this.ingreso = doc;
    }
  }

  cerrarDialogoEliminar(): void {
    this.dialogoEliminar = false;
    this.ingreso = null;
  }

  cerrarDialogoFormulario(): void {
    this.dialogoFormulario = false;
    this.ingreso = null;
  }
}
