























































































































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { Proveedor } from "@/typings/store/plugins/easyFirestore/proveedores";
// validaciones
import { required } from "vuelidate/lib/validators";
import { DataFormularioProveedor } from "@/typings/components/proveedores/formulario";
import SVGIcon from "@/components/SVGIcon.vue";

@Component({
  components: {
    SVGIcon,
  },
  validations: {
    codigo: { required },
    nombre: { required },
    rut: { required },
    nombreAux: { required },
  },
})
export default class FormularioProveedor extends Vue {
  @Prop() readonly proveedor!: Proveedor | null;
  @Prop() readonly cargando!: boolean;
  //   @Prop() readonly rutas!: Rutas;
  //   @Prop() readonly ciudades!: Ciudad[];
  //   @Prop() readonly cargando!: boolean;
  //   @Prop() readonly regiones!: Region[];

  created(): void {
    // Object.keys(this.rutas).forEach((key) => {
    //   this.$set(this.permisos, key, false);
    // });
    // console.log(this.permisos);
    const proveedor = this.proveedor;
    if (!proveedor) {
      return;
    }
    this.id = proveedor.id ?? "";
    this.codigo = proveedor.CodAux ?? "";
    this.nombre = proveedor.NomAux ?? "";
    this.rut = proveedor.RutAux ?? "";
    this.nombreAux = proveedor.NomAux2 ?? "";
  }

  id: string | null = null;
  codigo = "";
  nombre = "";
  rut = "";
  nombreAux = "";

  get titulo(): string {
    return this.proveedor ? "editar proveedor" : "nuevo proveedor";
  }

  //   get permisosKey(): Array<string> {
  //     // return Object.keys(this.permisos).filter((key) => {
  //     //   if(this.rutas[key].tipos.includes(this.tipo)){
  //     //     this.permisos[key] = true;
  //     //   }
  //     // });
  //     return Object.keys(this.permisos).filter((key) => {
  //       if (!this.rutas[key]) {
  //         return false;
  //       }
  //       const tipo = this.rutas[key].tipos.includes(this.tipo);
  //       return tipo;
  //     });
  //   }

  get nombreError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.nombre.$dirty) return errors;
    if (!this.$v.nombre.required) errors.push("Completar datos obligatorios");
    return errors;
  }

  get posicionError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.posicion.$dirty) return errors;
    if (!this.$v.posicion.required) errors.push("Completar datos obligatorios");
    return errors;
  }

  @Emit("close")
  onClose(): null {
    return null;
  }

  @Emit("cerrar-dialogo")
  onCerrarDialogo(): void {
    return;
  }

  @Emit("click-guardar")
  onClickGuardar(data: DataFormularioProveedor): DataFormularioProveedor {
    return data;
  }

  //   @Watch("permisosKey", { immediate: true })
  //   onTipoChange(): void {
  //     console.log("a");
  //     for (const key of Object.keys(this.permisos)) {
  //       if (this.permisos[key]) {
  //         this.permisos[key] = false;
  //       }
  //     }
  //     this.permisosKey.forEach((key) => {
  //       this.permisos[key] = true;
  //     });
  //   }

  //   nombrePermiso(key: string): string {
  //     return this.rutas[key].text;
  //   }

  guardar(): void {
    this.$v.$touch();
    console.log(this.$v.$invalid);
    const doc: Proveedor = {
      CodAux: this.codigo.toUpperCase(),
      NomAux: this.nombre.toUpperCase(),
      RutAux: this.rut.toUpperCase(),
      NomAux2: this.nombreAux.toUpperCase(),
    };
    if (this.id) {
      doc.id = this.id;
    }
    const data: DataFormularioProveedor = {
      doc: doc,
    };
    this.onClickGuardar(data);
  }
}
