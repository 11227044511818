import { RouteConfig } from "vue-router";
import Regiones from "@/views/Regiones.vue";

const regiones: RouteConfig = {
    path: "/regiones",
    name: "regiones",
    component: Regiones,
};

export default regiones;
