
















































































































































































// decoradores
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
// tipos
import { HeadersDataTable } from "@/typings/vuetify/vDataTable";
import { Local } from "@/typings/store/plugins/easyFirestore/locales";
import { ItemsTablaLocales } from "@/typings/components/locales/tabla";
import "@/helpers/extensions";

@Component
export default class TablaUsuarios extends Vue {
  @Prop() readonly opciones!: boolean;
  @Prop() readonly search!: string;
  @Prop() readonly locales!: Array<Local>;

  options = {
    itemsPerPage: -1,
  };
  headerProps = {
    sortIcon: "mdi-chevron-down",
  };

  get headers(): HeadersDataTable {
    return [
      //{text: "", value: "estado", sortable: false},
      { text: "Código", value: "codigo" },

      { text: "Centro Costo", value: "centroCosto" },
      // { text: "Direccion", value: "direccion" },
      { text: "Unidad Locativa", value: "unidadLocativa" },
      { text: "Tipo Local", value: "tipo_local" },

      { text: "Estado", value: "estado" },

      { text: "Superficie", value: "superficie" },
      { text: "Nivel", value: "nivel" },
      { text: "Fachada", value: "fachada" },
      { text: "Vista Planta", value: "vista_planta", sortable: false },

      { text: "", value: "opciones", sortable: false },
    ];
  }

  get items(): ItemsTablaLocales {
    return this.locales.map((local) => {
      const codigo = local.codigo;
      const centroCosto = local.centroCosto;
      // const direccion = local.direccion;
      const unidadLocativa = local.unidadLocativa;
      const superficie = local.superficie;
      const nivel = local.nivel;
      const fachada = local.fachada;
      const vista_planta = local.vista_planta;
      const tipo_local = local.tipo_local;
      const estado = local.estado;

      return {
        codigo,
        centroCosto,
        //direccion,
        unidadLocativa,
        superficie,
        nivel,
        fachada,
        vista_planta,
        tipo_local,
        estado,
        

        data: local,
      };
    });
  }

  @Emit("abrir-info")
  onAbrirInfo(local: Local): Local {
    return local;
  }

  @Emit("abrir-editar")
  onAbrirEditar(local: Local): Local {
    return local;
  }

  @Emit("abrir-eliminar")
  onAbrirEliminar(local: Local): Local {
    return local;
  }
}
