































































































































// decoradores
import {Component, Emit, Prop, Vue} from "vue-property-decorator";
// tipos
import {HeadersDataTable} from "@/typings/vuetify/vDataTable";
import {Promocion} from "@/typings/store/plugins/easyFirestore/promociones";
import {ItemsTablaPromociones} from "@/typings/components/promociones/tabla";
 

@Component
export default class TablaPromociones extends Vue {
  @Prop() readonly opciones!: boolean;
  @Prop() readonly search!: string;
  @Prop() readonly promociones!: Array<Promocion>;

  options = {
    itemsPerPage: -1,
  };
  headerProps = {
    sortIcon: "mdi-chevron-down"
  }

  get headers(): HeadersDataTable {
    return [
      {text: "Nombre", value: "nombre"},
      {text: "Descripción", value: "descripcion"},
      {text: "Tipo", value: "tipo"},
      {text: "Cursos", value: "cursos"},
      {text: "Código", value: "codigo"},
      {text: "Descuento", value: "descuento"},
      {text: "Fecha Inicio", value: "inicio"},
      {text: "Fecha Fin", value: "fin"},
      {text: "", value: "opciones", sortable: false},
    ];
  }

  get items(): ItemsTablaPromociones {
    return this.promociones.map((promocion) => {
      const nombre = promocion.nombre;
      const descripcion = promocion.descripcion;
      const codigo = promocion.codigo || '-';
      const tipo = promocion.tipo;
      const cursos = Object.values(promocion.cursos).map(curso => curso.nombre).join(", ");
      const descuento = promocion.descuento.includes("%") ? promocion.descuento : parseInt(promocion.descuento).toCurrency({symbol: '$'})
      const inicio = promocion.inicio.toDate().format("DD/MM/YYYY");
      const fin = promocion.fin.toDate().format("DD/MM/YYYY");
      return {
        nombre,
        descripcion,
        codigo,
        tipo,
        cursos,
        descuento,
        inicio,
        fin,
        data: promocion,
      };
    });
  }

  @Emit("abrir-info")
  onAbrirInfo(promocion: Promocion): Promocion {
    return promocion;
  }

  @Emit("abrir-editar")
  onAbrirEditar(promocion: Promocion): Promocion {
    return promocion;
  }

  @Emit("abrir-eliminar")
  onAbrirEliminar(promocion: Promocion): Promocion {
    return promocion;
  }
}
