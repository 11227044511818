const capitalize = (value: string): string => {
  return value.replace(/^\w/, (result: string) => result.toUpperCase());
};

const idtify = (id: string): string => {
  return id.toLowerCase().replace(/[\\./]/gm, "_");
};

const string = {
  idtify,
  capitalize,
};

export default string;

export { idtify, capitalize };
