































































































// decoradores
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import {Promocion} from "@/typings/store/plugins/easyFirestore/promociones";
// tipos

@Component
export default class InfoPromocion extends Vue {
  @Prop() readonly promocion!: Promocion;
  @Prop({ type: Boolean }) readonly showTitulo!: boolean;

  get id(): string {
    return this.promocion.id || "N/R";
  }

  get nombre(): string {
    return this.promocion.nombre || "N/R";
  }

  get descripcion(): string {
    return this.promocion.descripcion || "N/R";
  }

  get codigo(): string {
    return this.promocion.codigo || "N/R";
  }

  get tipo(): string {
    return this.promocion.tipo || "N/R";
  }

  get cursos(): string {
    return Object.values(this.promocion.cursos).map(curso => curso.nombre).join(", ") || "N/R";
  }

  get descuento(): string {
    return this.promocion.descuento || "N/R";
  }

  get inicio(): string {
    return this.promocion.inicio || "N/R";
  }
  get fin(): string {
    return this.promocion.fin || "N/R";
  }

  @Emit("close")
  onClose(): null {
    return null;
  }
}
