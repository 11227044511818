var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"rounded-0 tabla poppins primary--text text-capitalize font-weight-light",attrs:{"search":_vm.search,"headers":_vm.headers,"options":_vm.options,"items":_vm.items,"header-props":_vm.headerProps,"hide-default-footer":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.estado",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-card',_vm._g({staticClass:"rounded-pill",attrs:{"width":"24px","height":"24px","flat":"","color":_vm.getColor(item).last}},on),[_c('v-row',{staticClass:"fill-height",attrs:{"no-gutters":"","justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-card',_vm._g({staticClass:"rounded-pill",attrs:{"width":"8px","height":"8px","flat":"","color":_vm.getColor(item).first}},on))],1)],1)],1)],1)],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.estado)+" ")])])]}},{key:"item.clases",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_vm._v(" "+_vm._s(_vm.cantClases(item.data))+" ")])],1)]}},{key:"item.asistentes",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_vm._v(" "+_vm._s(_vm.cantAsistentes(item.data))+" / "+_vm._s(item.data.cupos)+" ")])],1)]}},{key:"item.duracion",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_vm._v(" "+_vm._s(_vm.cantMinutos(item.data))+" Hrs. ")])],1)]}},{key:"item.opciones",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"no-gutters":"","justify":"end"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-menu',{staticClass:"pa-0",attrs:{"offset-x":"","content-class":"elevation-0 rounded-1rem primary-border"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('img',{attrs:{"src":require(attrs['aria-expanded'] === 'true' ? '../../assets/icons/tres-puntos-active.svg' : '../../assets/icons/tres-puntos.svg'),"alt":"3 puntos","width":"24px","height":"24px"}})])]}}],null,true)},[_c('v-list',{staticClass:"pa-0"},[_c('v-list-item',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"info","icon":"","x-small":"","fab":""},on:{"click":function($event){return _vm.onAbrirDuplicar(item.data)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v("Duplicar")])])],1)],1)],1),_c('v-list-item',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"info","icon":"","x-small":"","fab":""},on:{"click":function($event){return _vm.onAbrirInfo(item.data)}}},'v-btn',attrs,false),on),[_c('img',{attrs:{"src":require("../../assets/icons/informacion.svg"),"alt":"Información","width":"20px","height":"20px"}})])]}}],null,true)},[_c('span',[_vm._v("Información")])])],1)],1)],1),((_vm.usuario.tipo === 'admin') || (_vm.usuario.tipo === 'relator' && item.estado === 'revisado'))?_c('v-list-item',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"warning","icon":"","x-small":"","fab":""},on:{"click":function($event){return _vm.onAbrirEditar(item.data)}}},'v-btn',attrs,false),on),[_c('img',{attrs:{"src":require("../../assets/icons/editar.svg"),"alt":"Editar","width":"20px","height":"20px"}})])]}}],null,true)},[_c('span',[_vm._v("Editar")])])],1)],1)],1):_vm._e(),((_vm.usuario.tipo === 'admin') || (_vm.usuario.tipo === 'relator' && (item.estado === 'pendiente' || item.estado === 'rechazado')))?_c('v-list-item',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"error","icon":"","x-small":"","fab":""},on:{"click":function($event){return _vm.onAbrirEliminar(item.data)}}},'v-btn',attrs,false),on),[_c('img',{attrs:{"src":require("../../assets/icons/eliminar.svg"),"alt":"Eliminar","width":"20px","height":"20px"}})])]}}],null,true)},[_c('span',[_vm._v("Eliminar")])])],1)],1)],1):_vm._e()],1)],1)],1)],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }