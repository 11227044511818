






































































































































































































































































































































































































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
// helpers
//import { idtify } from "@/helpers/string";
// tipos
//import { Rutas } from "@/typings/store/modules/rutas";
//import {
//  Permisos,
//  TipoUsuario,
//  Usuario,
//} from "@/typings/store/plugins/easyFirestore/usuarios";
import {
  Mantenimiento,
  //Bancos
} from "@/typings/store/plugins/easyFirestore/mantenimientos";
// validaciones
import {
  // email,
  required,
} from "vuelidate/lib/validators";
import { DataFormularioMantenimiento } from "@/typings/components/mantenimientos/formulario";
import SVGIcon from "@/components/SVGIcon.vue";
//import { Archivo, Archivos } from "@/typings/store/modules/archivos";

@Component({
  components: {
    SVGIcon,
  },
  validations: {
    nombre: { required },
 
  },
})
export default class FormularioMantenimiento extends Vue {
  @Prop() readonly mantenimiento!: Mantenimiento | null;
  @Prop() readonly cargando!: boolean;
  //   @Prop() readonly rutas!: Rutas;
  //   @Prop() readonly ciudades!: Ciudad[];
  //   @Prop() readonly cargando!: boolean;
  //   @Prop() readonly regiones!: Region[];

  created(): void {
    // Object.keys(this.rutas).forEach((key) => {
    //   this.$set(this.permisos, key, false);
    // });
    // console.log(this.permisos);
    const mantenimiento = this.mantenimiento;
    if (!mantenimiento) {
      return;
    }
    this.id = mantenimiento.id ?? "";
    this.nombre = mantenimiento.nombre;
    this.posicion = mantenimiento.posicion ?? "";

    this.empresa = mantenimiento.empresa ?? "";
    this.contrato = mantenimiento.contrato ?? "";
    this.cuentaSoftland = mantenimiento.cuentaSoftland ?? "";

    this.item = mantenimiento.item ?? "";
    this.itemizado = mantenimiento.itemizado ?? "";
    this.piso = mantenimiento.piso ?? "";
    this.equipos = mantenimiento.equipos ?? "";
    this.desgloseEquipos = mantenimiento.desgloseEquipos ?? "";
    this.descripcion = mantenimiento.descripcion ?? "";
    this.marca = mantenimiento.marca ?? "";
    this.modelo = mantenimiento.modelo ?? "";
    this.ubicacion = mantenimiento.ubicacion ?? "";
    this.periosidad = mantenimiento.periosidad ?? "";
    this.valor = mantenimiento.valor ?? "";
    this.ultimaMantencion = mantenimiento.ultimaMantencion ?? "";
    this.proximaMantencion = mantenimiento.proximaMantencion ?? "";
    this.controlan = mantenimiento.controlan ?? "";
    this.fotos = mantenimiento.fotos ?? "";
    this.observaciones = mantenimiento.observaciones ?? "";
  }

  id: string | null = null;

  nombre = "";
  posicion = "";
  empresa = "";
  contrato = "";
  cuentaSoftland = "";

  item = "";
  itemizado = "";
  piso = "";
  equipos = "";
  desgloseEquipos = "";
  descripcion = "";
  marca = "";
  modelo = "";
  ubicacion = "";
  periosidad = "";
  valor = "";
  ultimaMantencion = "";
  proximaMantencion = "";
  controlan = "";
  fotos = "";
  observaciones = "";

  get titulo(): string {
    return this.mantenimiento ? "editar mantenimiento" : "nuevo mantenimiento";
  }

  //   get permisosKey(): Array<string> {
  //     // return Object.keys(this.permisos).filter((key) => {
  //     //   if(this.rutas[key].tipos.includes(this.tipo)){
  //     //     this.permisos[key] = true;
  //     //   }
  //     // });
  //     return Object.keys(this.permisos).filter((key) => {
  //       if (!this.rutas[key]) {
  //         return false;
  //       }
  //       const tipo = this.rutas[key].tipos.includes(this.tipo);
  //       return tipo;
  //     });
  //   }

  get nombreError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.nombre.$dirty) return errors;
    if (!this.$v.nombre.required) errors.push("Completar datos obligatorios");
    return errors;
  }

  get posicionError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.posicion.$dirty) return errors;
    if (!this.$v.posicion.required) errors.push("Completar datos obligatorios");
    return errors;
  }

  @Emit("close")
  onClose(): null {
    return null;
  }

  @Emit("cerrar-dialogo")
  onCerrarDialogo(): void {
    return;
  }

  @Emit("click-guardar")
  onClickGuardar(
    data: DataFormularioMantenimiento
  ): DataFormularioMantenimiento {
    return data;
  }

  //   @Watch("permisosKey", { immediate: true })
  //   onTipoChange(): void {
  //     console.log("a");
  //     for (const key of Object.keys(this.permisos)) {
  //       if (this.permisos[key]) {
  //         this.permisos[key] = false;
  //       }
  //     }
  //     this.permisosKey.forEach((key) => {
  //       this.permisos[key] = true;
  //     });
  //   }

  //   nombrePermiso(key: string): string {
  //     return this.rutas[key].text;
  //   }

  guardar(): void {
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }
    const doc: Mantenimiento = {
      nombre: this.nombre,
      posicion: this.posicion,

      empresa: this.empresa,
      contrato: this.contrato,
      cuentaSoftland: this.cuentaSoftland,

      item: this.item,
      itemizado: this.itemizado,
      piso: this.piso,
      equipos: this.equipos,
      desgloseEquipos: this.desgloseEquipos,
      descripcion: this.descripcion,
      marca: this.marca,
      modelo: this.modelo,
      ubicacion: this.ubicacion,
      periosidad: this.periosidad,
      valor: this.valor,
      ultimaMantencion: this.ultimaMantencion,
      proximaMantencion: this.proximaMantencion,
      controlan: this.controlan,
      fotos: this.fotos,
      observaciones: this.observaciones,
    };
    if (this.id) {
      doc.id = this.id;
    }
    const data: DataFormularioMantenimiento = {
      doc: doc,
    };
    this.onClickGuardar(data);
  }
}
