
















































































































































































// decoradores
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
// tipos
import { HeadersDataTable } from "@/typings/vuetify/vDataTable";
import { Proveedor } from "@/typings/store/plugins/easyFirestore/proveedores";
import { ItemsTablaProveedores } from "@/typings/components/proveedores/tabla";
import "@/helpers/extensions";

@Component
export default class TablaProveedores extends Vue {
  @Prop() readonly opciones!: boolean;
  @Prop() readonly search!: string;
  @Prop() readonly proveedores!: Array<Proveedor>;

created():void{
  console.log(this.proveedores.length)
}

  options = {
    itemsPerPage: 15,
  };
  headerProps = {
    sortIcon: "mdi-chevron-down",
  };

  get headers(): HeadersDataTable {
    return [
      //{text: "", value: "estado", sortable: false},
      { text: "Codigo", value: "codigo" },
      { text: "Nombre", value: "nombre" },
      { text: "Rut", value: "rut" },
      { text: "Nombre Aux", value: "nombreAux" },
      { text: "", value: "opciones", sortable: false },
    ];
  }

  get items(): ItemsTablaProveedores {
    return this.proveedores.map((proveedor) => {
      const codigo = proveedor.CodAux;
      const nombre = proveedor.NomAux;
      const rut = proveedor.RutAux;
      const nombreAux = proveedor.NomAux2;

      return {
        codigo,
        nombre,
        rut,
        nombreAux,

        data: proveedor,
      };
    });
  }

  //   actualizarEstado(item: Usuario): void {
  //     let estado: EstadoUsuario;
  //     item.estado === 'libre' ? estado = 'bloqueado' : estado = 'libre';
  //     item.estado = estado;
  //     this.onCambiarEstado(item);
  //   }

  //   @Emit("cambiar-estado")
  //   onCambiarEstado(usuario: Usuario): Usuario {
  //     return usuario;
  //   }

  @Emit("abrir-info")
  onAbrirInfo(proveedor: Proveedor): Proveedor {
    return proveedor;
  }

  @Emit("abrir-editar")
  onAbrirEditar(proveedor: Proveedor): Proveedor {
    return proveedor;
  }

  @Emit("abrir-eliminar")
  onAbrirEliminar(proveedor: Proveedor): Proveedor {
    return proveedor;
  }
}
