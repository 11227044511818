import { RouteConfig } from "vue-router";
import Solicitudes from "@/views/Solicitudes.vue";

const solicitudes: RouteConfig = {
    path: "/solicitudes",
    name: "solicitudes",
    component: Solicitudes,
};

export default solicitudes;
