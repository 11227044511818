








































































































































































































































































































































import { Usuario } from "@/typings/store/plugins/easyFirestore/usuarios";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import "@/helpers/extensions";
import InfoBar from "@/components/InfoBar.vue";
import { mapGetters } from "vuex";
import { Ruta } from "@/typings/store/modules/rutas";
import SVGIcon from "@/components/SVGIcon.vue";

@Component({
  computed: mapGetters({
    getRutas: "rutas/getRutas",
    getRutasUsuario: "usuario/getRutas",
  }),
  components: { InfoBar, SVGIcon },
})
export default class AppBar extends Vue {
  @Prop() readonly titulo!: string;
  @Prop() readonly usuario!: Usuario;
  @Prop() readonly items!: Array<Ruta>;
  drawer = null;

  @Emit("click-nav-icon")
  onClickNavIcon(): void {
    return;
  }

  @Emit("click-empresa")
  onClickEmpresa(): void {
    return;
  }

    @Emit("abrir-login")
  onAbrirLogin(): void {
    return;
  }

  @Emit("logout")
  onLogout(): void {
    return;
  }


 @Emit("input")
  onInput(value: boolean): boolean {
    return value;
  }
  get itemsRutas(): Array<Ruta> {
    return this.getRutasArray;
  }
  get rutasUsuario(): Array<Ruta> {
    return this.getRutasUsuario;
  }
  to(ruta: Ruta): void {
    if (ruta.to && this.$route.name !== ruta.to?.name) {
      this.$router.push(ruta.to);
    }
  }

  get hideSlider(): boolean {
    return !this.items.some(
      (ruta) =>
        ruta.id.toLowerCase() === this.actualRute.toLowerCase() && ruta.show
    );
  }

  get actualRute(): string {
    return this.$route.name ?? "";
  }
  async goTo(ruta: string): Promise<void> {
    if (this.$route.name !== ruta) {
      await this.$router.push({ name: ruta });
    }
  }

  async move(name: string): Promise<void> {
    if (this.$route.name !== "inicio") {
      await this.goTo("inicio");
    }
    this.$vuetify.goTo(`#${name}`);
  }
}
