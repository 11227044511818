//tipos
import { GetterTree } from "vuex";
import { State } from "@/typings/store";
import { StateConsumos } from "@/typings/store/plugins/easyFirestore/consumos";

const getters: GetterTree<StateConsumos, State> = {
    get(state) {
        return state.all;
    },
    getArray(state) {
        return Object.values(state.all);
    },
};

const consumos = {
    firestorePath: "dev/v1/consumos",
    firestoreRefType: "collection",
    moduleName: "consumos",
    statePropName: "all",
    namespaced: true,
    serverChange: {
        convertTimestamps: {
            created_at: "%convertTimestamp%", // default
            updated_at: "%convertTimestamp%", // default
        },
    },
    state: {},
    getters,
    mutations: {},
    actions: {},
};

export default consumos;
