














































































































































// decoradores
import { Vue, Component } from "vue-property-decorator";
// vuex
import { mapActions, mapGetters } from "vuex";
// tipos

// componentes
//import BotonesCrud from "@/components/BotonesCrud.vue";
//import TituloVista from "@/components/TituloVista.vue";
import TablaBanco from "@/components/centrosCostos/Tabla.vue";
import FormularioCentroCosto from "@/components/centrosCostos/Formulario.vue";
import { DataFormularioCentroCosto } from "@/typings/components/centrosCostos/formulario";
import DialogoExito from "@/components/DialogoExito.vue";
import DialogoEliminar from "@/components/DialogoEliminar.vue";
import InfoBanco from "@/components/centrosCostos/Info.vue";
import MensajeEliminar from "@/components/MensajeEliminar.vue";
import {
  CentroCosto,
  CentrosCostos,
} from "@/typings/store/plugins/easyFirestore/centrosCostos";
import CargarCentrosCostosExcel from "@/components/excel/CargarCentrosCostos.vue";
import { fechaLocal } from "@/helpers/date";
import "@/helpers/extensions";
// Excel
import Exceljs from "exceljs";

const routeGuard = (vm: Vue): boolean => {
  const permisos = vm.getUsuario?.permisos;
  if (permisos && permisos["centrosCostos"]) {
    return true;
  }

  const rutas = vm.getRutasUsuario;
  if (rutas.length > 0) {
    const to = rutas[0].to;
    if (to) {
      if (vm.$route.name !== to.name) {
        vm.$router.replace(to);
      }
      return false;
    }
  }
  if (vm.$route.name !== "inicio") {
    vm.$router.replace({ name: "inicio" });
  }
  return false;
};

@Component({
  computed: mapGetters({
    getUsuarios: "usuarios/get",
    getCentrosCostos: "centrosCostos/get",
    getArrayUsuarios: "usuarios/getArray",
    getUsuario: "usuario/get",
    getRutas: "rutas/get",
    getArrayCiudades: "ciudades/getArray",

    getRutasUsuario: "usuario/getRutas",
    getArrayCentrosCostos: "centrosCostos/getArray",
  }),
  methods: mapActions({
    setCentroCosto: "centrosCostos/set",
    deleteCentroCosto: "centrosCostos/delete",
    insertBatchCentrosCostos: "centrosCostos/insertBatch",
    //setArchivo: "archivos/set",
    //deleteArchivo: "archivos/delete",
  }),
  beforeRouteEnter(to, from, next) {
    next(routeGuard);
  },
  components: {
    DialogoEliminar,
    DialogoExito,
    TablaBanco,
    FormularioCentroCosto,
    InfoBanco,
    //BotonesCrud,
    MensajeEliminar,
    CargarCentrosCostosExcel,
    //TituloVista,
  },
})
export default class VistaCentrosCostos extends Vue {
  created(): void {
    if (!routeGuard(this)) {
      return;
    }
  }

  cargando = false;
  dialogoExito = {
    model: false,
    texto: "",
    icono: "",
  };
  search = "";
  centroCosto: CentroCosto | null = null;
  dialogoInfo = false;
  dialogoFormulario = false;
  dialogoEliminar = false;
  textoEliminar = "¿ESTÁS SEGURO QUE DESEAS ELIMINAR ESTE CENTRO DE COSTO?";
  dialogoCargarExcel = false;

  get centrosCostos(): CentrosCostos {
    return this.getCentrosCostos;
  }

  get items(): Array<CentroCosto> {
    return this.getArrayCentrosCostos.sort((a, b) => {
      if (a.CodiCC < b.CodiCC) {
        return -1;
      }
      if (a.CodiCC > b.CodiCC) {
        return 1;
      }
      return 0;
    });
  }

  clickItem(val: { id: string } | null): void {
    this.centroCosto = val ? this.centrosCostos[val.id] : null;
  }

  clickPlus(): void {
    this.centroCosto = null;
    this.dialogoFormulario = true;
  }

  async createExcel(): Promise<void> {
    const workbook = new Exceljs.Workbook();
    const sheet = workbook.addWorksheet("CENTRO COSTOS", {
      properties: { defaultColWidth: 25 },
    });

    const rows2 = [
      ["ID TIPO", "TIPO GASTO", "CUENTA DE GASTO", "CUENTA ESTADO RESULTADO"],
      ...this.itemsImprimir,
      [],
    ];
    sheet.addRows(rows2);

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: "application/xlsx" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "CENTRO COSTOS " + fechaLocal() + ".xlsx";
    link.click();
  }

  //ITEMS PDF
  get itemsImprimir(): Array<Array<string | number>> {
    //let PrenominasRecorridas: Array<string> = [];
    return this.getArrayCentrosCostos.map((centroCosto) => {
      const CodiCC = centroCosto.CodiCC;
      const DescCC = centroCosto.DescCC;
      const NivelCC = centroCosto.NivelCC;
      const Activo = centroCosto.Activo;
      const Desc_formato = centroCosto.Desc_formato;

      return Object.values({
        CodiCC,
        DescCC,
        NivelCC,
        Activo,
        Desc_formato,
      });
    });
  }

  async clickGuardar(data: DataFormularioCentroCosto): Promise<void> {
    this.cargando = true;
    const doc = data.doc;
    await this.setCentroCosto(doc);
    if (doc.id != this.centroCosto?.id) {
      this.dialogoExito = {
        model: true,
        texto: "¡Centro Costo creado con éxito!",
        icono: require("../assets/icons/double-check-duo.svg"),
      };
    }
    if (doc.id == this.centroCosto?.id) {
      this.dialogoExito = {
        model: true,
        texto: "¡Centro Costo editado con éxito!",
        icono: require("../assets/icons/editar.svg"),
      };
      this.centroCosto = doc;
    }
    this.cerrarDialogoFormulario();
    this.cargando = false;
  }

  cerrarDialogoExito(): void {
    this.dialogoExito = {
      model: false,
      texto: "",
      icono: "",
    };
  }

  clickEliminar(): void {
    const centroCosto = this.centroCosto;
    if (!centroCosto?.id) {
      console.error("error id: ", centroCosto);
      return;
    }
    this.deleteCentroCosto(centroCosto.id);
    this.centroCosto = null;
    this.cerrarDialogoEliminar();
  }

  abrirDialogoInfo(centroCosto: CentroCosto): void {
    if (!centroCosto.id) {
      console.error("error id: ", centroCosto);
      return;
    }
    const doc = this.centrosCostos[centroCosto.id];
    if (doc) {
      this.centroCosto = doc;
      this.dialogoInfo = true;
    }
  }

  cerrarDialogoInfo(): void {
    this.dialogoInfo = false;
    this.centroCosto = null;
  }

  abrirDialogoFormulario(centroCosto: CentroCosto): void {
    if (!centroCosto.id) {
      console.error("error id: ", centroCosto);
      return;
    }
    const doc = this.centrosCostos[centroCosto.id];
    if (doc) {
      this.centroCosto = doc;
      this.dialogoFormulario = true;
    }
  }

  abrirDialogoEliminar(centroCosto: CentroCosto): void {
    if (!centroCosto.id) {
      console.error("error id: ", centroCosto);
      return;
    }
    const doc = this.centrosCostos[centroCosto.id];
    if (doc) {
      this.dialogoEliminar = true;
      this.centroCosto = doc;
    }
  }

  cerrarDialogoEliminar(): void {
    this.dialogoEliminar = false;
    this.centroCosto = null;
  }

  cerrarDialogoFormulario(): void {
    this.dialogoFormulario = false;
    this.centroCosto = null;
  }
}
