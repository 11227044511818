









































































// decoradores
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { CajaChicaTalca } from "@/typings/store/plugins/easyFirestore/cajaChicasTalca";
import SVGIcon from "@/components/SVGIcon.vue";
// tipos

// tipos
@Component({
  components: {
    SVGIcon,
  },
})
export default class InfoCajaChicaTalca extends Vue {
  @Prop() readonly cajaChicaTalca!: CajaChicaTalca;
  @Prop({ type: Boolean }) readonly showTitulo!: boolean;

  get id(): string {
    return this.cajaChicaTalca.id || "N/R";
  }

  get numero(): string {
    return this.cajaChicaTalca.numero.toString();
  }

  get fecha(): string {
    return this.cajaChicaTalca.fecha || "N/R";
  }

  get rutProveedor(): string {
    return this.cajaChicaTalca.rutProveedor || "N/R";
  }

  get nombreProveedor(): string {
    return this.cajaChicaTalca.nombreProveedor || "N/R";
  }
    
  get documento(): string {
    return this.cajaChicaTalca.documento || "N/R";
  }

  get importe(): string {
    return this.cajaChicaTalca.ingreso || this.cajaChicaTalca.egreso|| "";
  }



  @Emit("cerrar")
  onCerrar(): void {
    return;
  }
}
