









































































































































































































// decoradores
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { Contrato } from "@/typings/store/plugins/easyFirestore/contratos";
import TablaCondicion from "@/components/condicionesComerciales/Tabla.vue";
import FormularioCondicion from "@/components/condicionesComerciales/Formulario.vue";

import SVGIcon from "@/components/SVGIcon.vue";
// Todo de Condicion Comercial
import { CondicionComercial } from "@/typings/store/plugins/easyFirestore/condicionesComerciales";
import { DataFormularioCondicionComercial } from "@/typings/components/condicionesComerciales/formulario";
import { mapActions } from "vuex";

// tipos

// tipos
@Component({
  methods: mapActions({
    setCondicionComercial: "condicionesComerciales/set",
    deleteCondicionComercial: "condicionesComerciales/delete",
    insertBatchCondicionesComerciales: "condicionesComerciales/insertBatch",
    //setArchivo: "archivos/set",
    //deleteArchivo: "archivos/delete",
  }),
  components: {
    SVGIcon,
    TablaCondicion,
    FormularioCondicion,
  },
})
export default class InfoBanco extends Vue {
  @Prop() readonly contrato!: Contrato;
  @Prop() readonly condicionesComerciales!: Array<CondicionComercial>;
  @Prop({ type: Boolean }) readonly showTitulo!: boolean;

  get id(): string {
    return this.contrato.id || "";
  }
  get codigo(): number {
    return this.contrato.codigo || 0;
  }

  get razonSocial(): string {
    return this.contrato.razonSocial || "N/R";
  }

  get dia_uf(): number {
    return this.contrato.dia_uf || 0;
  }

  get superficieTotal(): string {
    return this.contrato.superficieTotal || "N/R";
  }

  get fechaInicio(): string {
    return this.contrato.fechaInicio || "N/R";
  }

  get fechaFin(): string {
    return this.contrato.fechaFin || "N/R";
  }

  get responsable(): string {
    return this.contrato.responsable || "N/R";
  }

  get telefono(): string {
    return this.contrato.telefono || "N/R";
  }

  get pdfContrato(): string {
    return this.contrato.pdfContrato || "N/R";
  }

  get centroCosto(): string {
    return this.contrato.centroCosto || "N/R";
  }

  get nombreComercial(): string {
    return this.contrato.nombre_comercial || "N/R";
  }

  get local(): string {
    return this.contrato.local || "N/R";
  }

  get renovacion(): string {
    return this.contrato.renovacion || "N/R";
  }

  get mesesRenovacion(): number {
    return this.contrato.dia_uf || 0;
  }

  get facturacion(): string {
    return this.contrato.facturacion || "N/R";
  }

  get codigoCondicionContrato(): number {
    let numero = 0;
    let arrayNumeros = [numero];
    this.condicionesComerciales.forEach((condicion) => {
      if (condicion.codigo_contrato === this.codigo) {
        arrayNumeros.push(condicion.codigo_condicion);
      }
    });
    let max = Math.max.apply(Math, arrayNumeros);
    return max;
  }

  @Emit("cerrar")
  onCerrar(): void {
    return;
  }
  // Todo de Condicion Comercial
  cargando = false;
  dialogoExito = {
    model: false,
    texto: "",
    icono: "",
  };
  search = "";
  condicionComercial: CondicionComercial | null = null;
  dialogoInfo = false;
  dialogoFormulario = false;
  dialogoEliminar = false;
  textoEliminar = "¿ESTÁS SEGURO QUE DESEAS ELIMINAR ESTA CONDICION COMERCIAL?";
  dialogoCargarExcel = false;
  snackbarCondicion = false;
  textSnackbarConciliacion = "CONDICIÓN COMERCIAL CREADA CON ÉXITO";
  timeoutCondicion = 2000;
  panelCondicion = false;

  // get condicionesComerciales(): CondicionesComerciales {
  //   return this.getCondicionesComerciales;
  // }
  // nececito una funcion get condicionesComerciales en donde codigo sea igual a codigo de contrato
  // get condicionesComerciales(): CondicionesComerciales {
  //   const resultado: CondicionesComerciales = {};
  //   Object.values(this.condicionesComerciales).forEach((item) => {
  //     if (item.codigo_contrato == this.codigo) {
  //       resultado[item.codigo_contrato] = Object.assign({}, item);
  //     }
  //   });
  //   return resultado;
  // }

  get items(): Array<CondicionComercial> {
    //necesito que retorne solo los que coinciden en numero de contrato
    const resultado: Array<CondicionComercial> = [];
    Object.values(this.condicionesComerciales).forEach((item) => {
      if (item.codigo_contrato == this.codigo) {
        resultado.push(Object.assign({}, item));
      }
    });
    resultado.sort((a, b) => a.codigo_condicion - b.codigo_condicion);
    return resultado;
  }

  // clickItem(val: { id: string } | null): void {
  //   this.condicionComercial = val ? this.condicionesComerciales[val.id] : null;
  // }

  // clickPlus(): void {
  //   this.condicionComercial = null;
  //   this.dialogoFormulario = true;
  // }

  // async clickGuardar(data: DataFormularioCondicionComercial): Promise<void> {
  //   this.cargando = true;
  //   const doc = data.doc;
  //   await this.setCondicionComercial(doc);
  //   if (doc.id != this.condicionComercial?.id) {
  //     this.dialogoExito = {
  //       model: true,
  //       texto: "¡Condicion creada con éxito!",
  //       icono: require("../assets/icons/double-check-duo.svg"),
  //     };
  //   }
  //   if (doc.id == this.condicionComercial?.id) {
  //     this.dialogoExito = {
  //       model: true,
  //       texto: "¡Condicion editada con éxito!",
  //       icono: require("../assets/icons/editar.svg"),
  //     };
  //     this.condicionComercial = doc;
  //   }
  //   this.cerrarDialogoFormulario();
  //   this.cargando = false;
  // }

  // cerrarDialogoExito(): void {
  //   this.dialogoExito = {
  //     model: false,
  //     texto: "",
  //     icono: "",
  //   };
  // }

  // clickEliminar(): void {
  //   const condicionComercial = this.condicionComercial;
  //   if (!condicionComercial?.id) {
  //     console.error("error id: ", condicionComercial);
  //     return;
  //   }
  //   this.deleteCondicionComercial(condicionComercial.id);
  //   this.condicionComercial = null;
  //   this.cerrarDialogoEliminar();
  // }

  // abrirDialogoInfo(condicionComercial: CondicionComercial): void {
  //   if (!condicionComercial.id) {
  //     console.error("error id: ", condicionComercial);
  //     return;
  //   }
  //   const doc = this.condicionesComerciales[condicionComercial.id];
  //   if (doc) {
  //     this.condicionComercial = doc;
  //     this.dialogoInfo = true;
  //   }
  // }

  // cerrarDialogoInfo(): void {
  //   this.dialogoInfo = false;
  //   this.condicionComercial = null;
  // }

  // abrirDialogoFormulario(condicionComercial: CondicionComercial): void {
  //   if (!condicionComercial.id) {
  //     console.error("error id: ", condicionComercial);
  //     return;
  //   }
  //   const doc = this.condicionesComerciales[condicionComercial.id];
  //   if (doc) {
  //     this.condicionComercial = doc;
  //     this.dialogoFormulario = true;
  //   }
  // }

  // abrirDialogoEliminar(condicionComercial: CondicionComercial): void {
  //   if (!condicionComercial.id) {
  //     console.error("error id: ", condicionComercial);
  //     return;
  //   }
  //   const doc = this.condicionesComerciales[condicionComercial.id];
  //   if (doc) {
  //     this.dialogoEliminar = true;
  //     this.condicionComercial = doc;
  //   }
  // }

  // cerrarDialogoEliminar(): void {
  //   this.dialogoEliminar = false;
  //   this.condicionComercial = null;
  // }

  // cerrarDialogoFormulario(): void {
  //   this.dialogoFormulario = false;
  //   this.condicionComercial = null;
  // }
  async clickGuardar(data: DataFormularioCondicionComercial): Promise<void> {
    this.cargando = true;
    const doc = data.doc;
    await this.setCondicionComercial(doc);
    if (doc.id != this.condicionComercial?.id) {
      // this.dialogoExito = {
      //   model: true,
      //   texto: "¡Condicion creada con éxito!",
      //   icono: require("../assets/icons/double-check-duo.svg"),
      // };
      console.log("¡Condicion creada con éxito!");
      this.panelCondicion = false;
      this.snackbarCondicion = true;
    }
    if (doc.id == this.condicionComercial?.id) {
      // this.dialogoExito = {
      //   model: true,
      //   texto: "¡Condicion editada con éxito!",
      //   icono: require("../assets/icons/editar.svg"),
      // };
      console.log("¡Condicion editada con éxito!");
      this.condicionComercial = doc;
    }

    this.cargando = false;
  }
}
