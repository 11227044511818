import { RouteConfig } from "vue-router";
import VisualizacionPagos from "@/views/VisualizacionPagos.vue";

const visualizacionPagos: RouteConfig = {
    path: "/visualizacionPagos",
    name: "visualizacionPagos",
    component: VisualizacionPagos,
};

export default visualizacionPagos;
