



















































































































































































































































































































































































































import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import TituloVista from "@/components/TituloVista.vue";
import {
  Comision,
  Curso,
  EstadoCurso,
} from "@/typings/store/plugins/easyFirestore/cursos";
import { DataFormularioCurso } from "@/typings/components/cursos/formulario";
import { CategoriaCurso } from "@/typings/store/plugins/easyFirestore/categoriasCurso";
import { Usuario } from "@/typings/store/plugins/easyFirestore/usuarios";
import SVGIcon from "@/components/SVGIcon.vue";
import {
  Sesion,
  Sesiones,
} from "@/typings/store/plugins/easyFirestore/sesiones";
import "@/helpers/extensions";
import DialogoClases from "@/components/cursos/DialogoClases.vue";
import DialogoExito from "@/components/DialogoExito.vue";
import { Notificacion } from "@/typings/store/plugins/easyFirestore/notificaciones";
import DialogoInvitadosTabla from "@/components/cursos/DialogoInvitadosTabla.vue";
import {Archivo, Archivos} from "@/typings/store/modules/archivos";
import {idtify} from "@/helpers/string";

@Component({
  components: {
    DialogoInvitadosTabla,
    DialogoExito,
    DialogoClases,
    SVGIcon,
    TituloVista,
  },
})
export default class RevisionCurso extends Vue {
  @Prop() readonly curso!: Curso;
  @Prop() readonly sesiones!: Array<Sesion>;

  certificado = false;

  created(): void {
    this.id = this.curso.id ?? "";
    this.nombre = this.curso.nombre;
    this.resumen = this.curso.resumen;
    this.descripcion = this.curso.descripcion;
    this.categoria = this.curso.categoria;
    this.relator = this.curso.relator;
    this.comision = this.curso.comision;
    this.tipoComision = this.curso.comision.tipo;
    this.valorComision = this.curso.comision.valor;
    this.precio = this.curso.precio;
    this.estadoActual = this.curso.estado;
    this.observacion = this.curso.observacion;
    this.fechaInicio = this.curso.inicio.toDate();
    this.imagen = Object.values(this.curso.imagen)[0] ?? null;
    this.fechaFin = this.curso.fin.toDate();
    this.descuento = this.curso.descuento;
    this.cupos = this.curso.cupos;
    this.certificado = this.curso.certificado;
  }
  id = "";
  cupos = 30;
  descuento = "";
  nombre = "";
  resumen = "";
  descripcion = "";
  comision: Comision | null = null;
  categoria: CategoriaCurso | null = null;
  relator: Usuario | null = null;
  precio = 0;
  observacion = "";
  imagen: Archivo | null = null;
  valorComision = "";
  dialogInvitados = false;
  tiposComision = [
    { text: "único", value: "Unico" },
    { text: "Por participante", value: "Por participante" },
  ];
  fechaInicio: Date = new Date();
  fechaFin: Date = new Date();
  tipoComision: "Unico" | "Por participante" = "Unico";
  estadoActual: EstadoCurso = "pendiente";
  estados: Array<{
    nombre: string;
    color: string;
    estado: string;
  }> = [
    { nombre: "Volver", color: "#E5DD0D", estado: "volver" },
    { nombre: "Aceptar", color: "#279B18", estado: "aceptado" },
    { nombre: "Rechazar", color: "#EA5143", estado: "rechazado" },
  ];
  dialogoSesiones = false;
  dialogoExito = {
    model: false,
    icono: "",
    texto: "",
  };

  get clases(): Array<Sesion> {
    return this.sesiones.filter((sesion) => sesion.curso.id === this.curso.id);
  }

  get duracionTotal(): number {
    return (
      this.clases.reduce((total, sesion) => total + sesion.duracion, 0) / 60
    );
  }

  get duracionPromedio(): number {
    return this.clases.length > 0 ? this.duracionTotal / this.clases.length : 0;
  }

  get urlImagen(): string {
    return this.imagen ? this.imagen.url ?? URL.createObjectURL(this.imagen.file) : "";
  }

  get descuentoValido(): boolean {
    const re = /(^\d+$)|(^\d{1,2}%$)/;
    return re.test(this.descuento);
  }

  get comisionValida(): boolean {
    const re = /(^\d+$)|(^\d{1,2}%$)/;
    const mayorCero = this.valorComision.toInt() > 0;
    return re.test(this.valorComision) && mayorCero;
  }

  @Emit("close")
  onClose(): null {
    return null;
  }

  @Emit("click-guardar")
  onClickGuardar(data: DataFormularioCurso): DataFormularioCurso {
    return data;
  }

  @Emit("click-guardar-noti-curso")
  onClickGuardarNotiyCurso(data: {
    curso: Curso;
    notificacion: Notificacion;
  }): { curso: Curso; notificacion: Notificacion } {
    return data;
  }

  @Emit("guardar-sesiones")
  onClickGuardarSesiones(sesiones: Sesiones): Sesiones {
    this.dialogoExito = {
      model: true,
      icono: require("../../assets/icons/double-check-duo.svg"),
      texto: "¡Se han guardado las clases!",
    };
    this.dialogoSesiones = false;
    return sesiones;
  }

  clickInputFile(): void {
    const input = this.$refs.inputFile as HTMLInputElement;
    input.click();
  }

  cargarFoto(ev: Event): void {
    const input = ev.target as HTMLInputElement;
    const file = input?.files ? input.files[0] : null;
    if (file) {
      if (file.type.includes("image")) {
        const fecha = new Date();
        const extension = file.name.split(".").pop() ?? "";
        const id = `cursos/${this.id}/imagen/${fecha.valueOf()}.${extension}`;
        this.imagen = {
          id: id,
          nombre: file.name,
          file: file,
        };
      }
    }
  }

  notificar(): void {
    if (this.precio <= 0) {
      this.dialogoExito = {
        model: true,
        icono: require("../../assets/icons/cancelar-duo.svg"),
        texto: "¡El precio no puede ser 0!",
      };
      return;
    }
    if (Object.values(this.imagen ?? {}).length <= 0) {
      this.dialogoExito = {
        model: true,
        icono: require("../../assets/icons/cancelar-duo.svg"),
        texto: "¡El curso  debe tener una imagen!",
      };
      return;
    }
    const curso: Curso = this.curso;
    this.estadoActual = "revisado";
    curso.estado = this.estadoActual;
    curso.observacion = this.observacion;
    curso.comision.tipo = this.tipoComision;
    curso.comision.valor = this.valorComision;
    curso.descuento = this.descuento;
    curso.precio = this.precio;
    curso.resumen = this.resumen;
    curso.descripcion = this.descripcion;
    curso.cupos = this.cupos;
    curso.imagen = {};
    if (this.descuento && this.descuentoValido) {
      if (
        !this.descuento.includes("%") &&
        this.precio - parseInt(this.descuento) <= 0
      ) {
        this.dialogoExito = {
          model: true,
          icono: require("../../assets/icons/double-check-duo.svg"),
          texto: "¡El descuento no puede ser mayor al precio!",
        };
        return;
      } else {
        curso.descuento = this.descuento;
      }
    } else {
      this.dialogoExito = {
        model: true,
        icono: require("../../assets/icons/cancelar-duo.svg"),
        texto: "¡El descuento es inválido!",
      };
      return;
    }
    const notificacion: Notificacion = {
      titulo: `Nuevas observaciones a su curso ${this.curso.nombre}`,
      contenido: "",
      tipo: "curso-revisado",
      curso: null,
      fecha: new Date().toISOString(),
      image: null,
      usuario: this.curso.relator,
      topico: null,
      enviada: false,
    };
    let imagen: Archivos = {};
    if (this.imagen) {
      imagen = {
        [idtify(this.imagen.id)]: this.imagen,
      };
    }
    const data = {
      imagen: imagen,
      curso: curso,
      notificacion: notificacion,
    };
    this.onClickGuardarNotiyCurso(data);
  }

  guardar(): void {
    if (this.precio <= 0) {
      this.dialogoExito = {
        model: true,
        icono: require("../../assets/icons/cancelar-duo.svg"),
        texto: "¡Ingrese precio!",
      };
      return;
    }
    if (Object.values(this.imagen ?? {}).length <= 0) {
      this.dialogoExito = {
        model: true,
        icono: require("../../assets/icons/cancelar-duo.svg"),
        texto: "¡El curso  debe tener una imagen!",
      };
      return;
    }
    const curso: Curso = this.curso;
    curso.estado = this.estadoActual;
    curso.observacion = this.observacion;
    curso.comision.tipo = this.tipoComision;
    curso.comision.valor = this.valorComision;
    curso.precio = this.precio;
    curso.descuento = this.descuento;
    curso.resumen = this.resumen;
    curso.imagen = {};
    curso.descripcion = this.descripcion;
    curso.cupos = this.cupos;
    curso.certificado = this.certificado;
    console.log(this.descuentoValido);
    if (this.descuento && this.descuentoValido) {
      if (
        !this.descuento.includes("%") &&
        this.precio - parseInt(this.descuento) < 0
      ) {
        this.dialogoExito = {
          model: true,
          icono: require("../../assets/icons/double-check-duo.svg"),
          texto: "¡El descuento no puede ser mayor al precio!",
        };
        return;
      } else {
        curso.descuento = this.descuento;
      }
    } else {
      console.log("descuento invalido");
      return;
    }
    let imagen: Archivos = {};
    if (this.imagen) {
      imagen = {
        [idtify(this.imagen.id)]: this.imagen,
      };
    }
    const data: DataFormularioCurso = {
      imagen: imagen,
      doc: curso,
    };
    this.onClickGuardar(data);
  }

  cambiarEstado(estado: string): void {
    console.log(this.precio);
    if (this.precio <= 0) {
      this.dialogoExito = {
        model: true,
        icono: require("../../assets/icons/cancelar-duo.svg"),
        texto: "¡Ingrese un precio al curso!",
      };
      return;
    }
    if (this.descuento && this.descuentoValido) {
      if (
        !this.descuento.includes("%") &&
        this.precio - parseInt(this.descuento) < 0
      ) {
        this.dialogoExito = {
          model: true,
          icono: require("../../assets/icons/double-check-duo.svg"),
          texto: "¡El descuento no puede ser mayor al precio!",
        };
        return;
      }
    } else {
      this.dialogoExito = {
        model: true,
        icono: require("../../assets/icons/double-check-duo.svg"),
        texto: "¡El descuento no puede ser mayor al precio!",
      };
      console.log("descuento invalido");
      return;
    }
    this.estadoActual = estado as EstadoCurso;
    if (estado === "volver") {
      this.onClose();
    }
    if (estado === "revisado") {
      this.dialogoExito = {
        model: true,
        icono: require("../../assets/icons/double-check-duo.svg"),
        texto: "¡Curso revisado con éxito!",
      };
      this.guardar();
    } else if (estado === "rechazado") {
      this.dialogoExito = {
        model: true,
        icono: require("../../assets/icons/cancelar-duo.svg"),
        texto: "¡Curso rechazado con éxito!",
      };
      this.guardar();
    } else if (estado === "aceptado") {
      // if (this.clases.some((sesion) => !sesion.link)) {
      //   console.log("error hay sesiones sin link");
      //   this.dialogoExito = {
      //     model: true,
      //     icono: require("../../assets/icons/cancelar-duo.svg"),
      //     texto: "¡Error hay clases sin link!",
      //   };
      //   return;
      // }
      console.log('a');
      if(!this.comisionValida){
        console.log('b');
        this.dialogoExito = {
          model: true,
          icono: require("../../assets/icons/cancelar-duo.svg"),
          texto: "¡Debe ingresar comisión al relator!",
        };
        return;
      }
      console.log('c');
      this.dialogoExito = {
        model: true,
        icono: require("../../assets/icons/double-check-duo.svg"),
        texto: "¡Curso aceptado con éxito!",
      };
      this.guardar();
    }
  }

  cerrarDialogoExito(): void {
    this.dialogoExito = {
      model: false,
      icono: "",
      texto: "",
    };
  }
}
