




























































































































































import { Component, Vue } from "vue-property-decorator";
import SVGIcon from "@/components/SVGIcon.vue";
import Footer from "@/components/inicio/Footer.vue";
import { mapGetters } from "vuex";
import { Pago } from "@/typings/store/plugins/easyFirestore/pagos";
import { Curso } from "@/typings/store/plugins/easyFirestore/cursos";
import { Sesion } from "@/typings/store/plugins/easyFirestore/sesiones";
import "@/helpers/extensions";

@Component({
  components: {
    Footer,
    SVGIcon,
  },
  computed: mapGetters({
    getUsuario: "usuario/get",
    getArrayPagos: "pagos/getArray",
  }),
})
export default class CompraExitosa extends Vue {
  created(): void {
    this.pagoId = this.$route.params.id;
    if (!this.pagoId) {
      this.goTo("cursosDisponibles");
    }
    const pagosUsuario = this.getArrayPagos.filter(
      (pago) => pago.cliente.id === this.getUsuario?.id
    );
    this.pago = pagosUsuario.find((pago) => pago.id === this.pagoId) ?? null;
    if (!this.pago) {
      this.goTo("cursosDisponibles");
    }
  }

  pagoId = "";
  pago: Pago | null = null;
  metodos = {
    VD: "Débito",
    VN: "Crédito",
    VC: "Crédito",
    SI: "Crédito",
    S2: "Crédito",
    NC: "Crédito",
    VP: "Prepago",
  };

  get cursos(): Array<Curso> {
    return Object.values(this.pago?.cursos ?? {}).filter(
      (curso) => curso.precio > 0
    );
  }

  get sesiones(): Array<Sesion> {
    return Object.values(this.pago?.sesiones ?? {}).filter(
      (sesion) => sesion.precio > 0
    );
  }

  get productos(): string {
    const cursos = this.cursos.map((curso) => curso.nombre);
    const sesiones = this.sesiones.map(
      (sesion) => `${sesion.curso.nombre} (${sesion.tema})`
    );
    return [...cursos, ...sesiones].join(", ");
  }

  get fecha(): string {
    return (
      this.pago?.resultado?.transaction_date?.toDate()?.format("DD-MM-YYYY") ??
      ""
    );
  }

  get hora(): string {
    return (
      this.pago?.resultado?.transaction_date?.toDate()?.format("HH:mm") ?? ""
    );
  }

  get metodoPago(): string {
    return this.metodos[this.pago?.resultado?.payment_type_code ?? "VD"] ?? "";
  }

  get numeroTarjeta(): string {
    return `XXXX XXXX XXXX ${this.pago?.resultado?.card_detail?.card_number ??
      ""}`;
  }

  get titularCompra(): string {
    return this.pago?.cliente?.nombre ?? "";
  }

  get rutTitular(): string {
    return this.pago?.cliente?.rut?.toRUT() ?? "";
  }

  get descuentoCursos(): number {
    return this.cursos.reduce(
      (total, curso) => total + this.getDescuentoCurso(curso),
      0
    );
  }

  get totalPago(): number {
    const totalCursos = this.cursos.reduce(
      (total, curso) => total + curso.precio,
      0
    );
    const totalSessiones = this.sesiones.reduce(
      (total, sesion) => total + sesion.precio,
      0
    );
    return totalCursos + totalSessiones;
  }

  get totalDescuento(): number {
    let descuento = 0;
    if (this.pago?.promocion) {
      descuento = this.pago.promocion.descuento.endsWith("%")
        ? this.calcularPorcentaje(
            this.totalPago,
            this.pago.promocion.descuento.toInt()
          )
        : this.pago.promocion.descuento.toInt();
    }
    return descuento + this.descuentoCursos;
  }

  get descuento(): string {
    return this.totalDescuento.toCurrency({ symbol: "$" });
  }

  get total(): string {
    return (this.totalPago - this.totalDescuento).toCurrency({ symbol: "$" });
  }

  calcularPorcentaje(total: number, descuento: number): number {
    return Math.floor((total * descuento) / 100);
  }

  getDescuentoCurso(curso: Curso): number {
    if (curso.descuento) {
      return curso.descuento.endsWith("%")
        ? this.calcularPorcentaje(curso.precio, curso.descuento.toInt())
        : parseInt(curso.descuento);
    }
    return 0;
  }

  async goTo(ruta: string): Promise<void> {
    if (this.$route.name !== ruta) {
      await this.$router.push({ name: ruta });
    }
  }
}
