import { RouteConfig } from "vue-router";
import Clientes from "@/views/Clientes.vue";

const bancos: RouteConfig = {
    path: "/clientes",
    name: "clientes",
    component: Clientes,
};

export default bancos;
