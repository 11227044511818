










































































































































































// decoradores
import { Vue, Component } from "vue-property-decorator";
// vuex
import { mapActions, mapGetters } from "vuex";
// tipos

// componentes
import TablaUsuarios from "@/components/usuarios/Tabla.vue";
import FormularioUsuario from "@/components/usuarios/Formulario.vue";
import FormularioRegistro from "@/components/usuarios/FormularioRegistro.vue";
import InfoUsuario from "@/components/usuarios/Info.vue";
import BotonesCrud from "@/components/BotonesCrud.vue";
import MensajeEliminar from "@/components/MensajeEliminar.vue";
import TituloVista from "@/components/TituloVista.vue";
import {
  Usuario,
  Usuarios,
} from "@/typings/store/plugins/easyFirestore/usuarios";
import { DataFormularioUsuario } from "@/typings/components/usuarios/formulario";
import { Rutas } from "@/typings/store/modules/rutas";
import { idtify } from "@/helpers/string";
import DialogoExito from "@/components/DialogoExito.vue";
import DialogoEliminar from "@/components/DialogoEliminar.vue";

const routeGuard = (vm: Vue): boolean => {
  const permisos = vm.getUsuario?.permisos;
  if (permisos && permisos["usuarios"]) {
    return true;
  }

  const rutas = vm.getRutasUsuario;
  if (rutas.length > 0) {
    const to = rutas[0].to;
    if (to) {
      if (vm.$route.name !== to.name) {
        vm.$router.replace(to);
      }
      return false;
    }
  }
  if (vm.$route.name !== "inicio") {
    vm.$router.replace({ name: "inicio" });
  }
  return false;
};

@Component({
  computed: mapGetters({
    getUsuarios: "usuarios/get",
    getArrayUsuarios: "usuarios/getArray",
    getUsuario: "usuario/get",
    getRutas: "rutas/get",
    getEmpresas: "empresas/get",
    getRutasUsuario: "usuario/getRutas",
    getArrayCiudades: "ciudades/getArray",
    getArrayRegiones: "regiones/getArray",
  }),
  methods: mapActions({
    setUsuario: "usuarios/set",
    deleteUsuario: "usuarios/delete",
    setArchivo: "archivos/set",
    deleteArchivo: "archivos/delete",
  }),
  beforeRouteEnter(to, from, next) {
    next(routeGuard);
  },
  components: {
    DialogoEliminar,
    DialogoExito,
    TablaUsuarios,
    FormularioUsuario,
    InfoUsuario,
    BotonesCrud,
    MensajeEliminar,
    TituloVista,
    FormularioRegistro,
  },
})
export default class VistaUsuarios extends Vue {
  created(): void {
    console.log(">usuario>", this.usuario);
    if (!routeGuard(this)) {
      return;
    }
  }

  dialogoExito = {
    model: false,
    texto: "",
    icono: "",
  };
  tipos = [
    { value: "admin", text: "Administrador" },
    { value: "gerente_operaciones", text: "Gerente Operaciones" },
    { value: "gerente_general", text: "Gerente General" },
    { value: "gerente_comercial", text: "Gerente Comercial" },
    { value: "cliente", text: "Cliente" },
    { value: "relator", text: "Relator" },
    { value: "supervisor", text:  "Supervisor" },
    { value: "operador", text: "Operador" },
    { value: "administrador_locales", text: "Administrador Locales",  },

  ];
  tipoUsuario: string | null = null;
  search = "";
  usuario: Usuario | null = null;
  dialogoInfo = false;
  dialogoFormulario = false;
  dialogoEliminar = false;
  cargando = false;
  registro = false;
  textoEliminar = "¿ESTÁS SEGURO QUE DESEAS ELIMINAR ESTE USUARIO?";

  get usuarioLogeado(): Usuario | null {
    return this.getUsuario;
  }

  get usuarios(): Usuarios {
    return this.getUsuarios;
  }

  get rutas(): Rutas {
    return this.getRutas;
  }

  get items(): Array<Usuario> {
    return this.getArrayUsuarios.filter((usuario) =>
      this.tipoUsuario ? this.tipoUsuario === usuario.tipo : true
    );
  }

  clickItem(val: { id: string } | null): void {
    this.usuario = val ? this.usuarios[val.id] : null;
  }

  clickPlus(): void {
    this.usuario = null;
    this.dialogoFormulario = true;
  }

  async clickGuardar(data: DataFormularioUsuario): Promise<void> {
    this.cargando = true;
    const doc = data.doc;
    const imagen = data.imagen;
    const borrar = data.borrar;
    for (const key in imagen) {
      if (imagen[key].file) {
        const doc = imagen[key];
        const res = await this.setArchivo(doc);
        if (res && res.url) {
          this.$set(imagen, idtify(res.id), res);
        }
      }
    }
    for (const key in borrar) {
      if (borrar[key]) {
        const doc = borrar[key];
        await this.deleteArchivo(doc.id);
      }
    }
    doc.imagen = imagen;
    await this.setUsuario(doc);
    if (doc.id != this.usuario?.id) {
      this.dialogoExito = {
        model: true,
        texto: "¡Usuario creado con éxito!",
        icono: require("../assets/icons/double-check-duo.svg"),
      };
    }
    if (doc.id == this.usuario?.id) {
      this.dialogoExito = {
        model: true,
        texto: "¡Usuario editado con éxito!",
        icono: require("../assets/icons/editar.svg"),
      };
      this.usuario = doc;
    }
    this.cerrarDialogoFormulario();
    this.cargando = false;
  }

  cerrarDialogoExito(): void {
    this.dialogoExito = {
      model: false,
      texto: "",
      icono: "",
    };
  }

  clickEliminar(): void {
    const usuario = this.usuario;
    if (!usuario?.id) {
      console.error("error id: ", usuario);
      return;
    }
    this.deleteUsuario(usuario.id);
    this.usuario = null;
    this.cerrarDialogoEliminar();
  }

  abrirDialogoInfo(usuario: Usuario): void {
    if (!usuario.id) {
      console.error("error id: ", usuario);
      return;
    }
    const doc = this.usuarios[usuario.id];
    if (doc) {
      this.usuario = doc;
      this.dialogoInfo = true;
    }
  }

  cambiarEstado(usuario: Usuario): void {
    this.setUsuario(usuario);
  }

  cerrarDialogoInfo(): void {
    this.dialogoInfo = false;
    this.usuario = null;
  }

  abrirDialogoFormulario(usuario: Usuario): void {
    if (!usuario.id) {
      console.error("error id: ", usuario);
      return;
    }
    const doc = this.usuarios[usuario.id];
    if (doc) {
      this.usuario = doc;
      this.dialogoFormulario = true;
    }
  }

  cerrarDialogoFormulario(): void {
    this.dialogoFormulario = false;
    this.usuario = null;
  }

  abrirDialogoEliminar(usuario: Usuario): void {
    if (!usuario.id) {
      console.error("error id: ", usuario);
      return;
    }
    const doc = this.usuarios[usuario.id];
    if (doc) {
      this.dialogoEliminar = true;
      this.usuario = doc;
    }
  }

  cerrarDialogoEliminar(): void {
    this.dialogoEliminar = false;
    this.usuario = null;
  }
}
