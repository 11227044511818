
























































































































































// decoradores
import { Vue, Component } from "vue-property-decorator";
// vuex
import { mapActions, mapGetters } from "vuex";
// tipos

// componentes
import TablaMatrizPago from "@/components/matrizPagos/Tabla.vue";
import FormularioMatrizPago from "@/components/matrizPagos/Formulario.vue";
import { DataFormularioMatrizPago } from "@/typings/components/matrizPagos/formulario";
import DialogoExito from "@/components/DialogoExito.vue";
import DialogoEliminar from "@/components/DialogoEliminar.vue";
import InfoMatrizPago from "@/components/matrizPagos/Info.vue";
//import BotonesCrud from "@/components/BotonesCrud.vue";
import MensajeEliminar from "@/components/MensajeEliminar.vue";
//import TituloVista from "@/components/TituloVista.vue";
import {
  MatrizPago,
  MatrizPagos,
} from "@/typings/store/plugins/easyFirestore/matrizPagos";
import {
  CentroCosto,
  CentrosCostos,
} from "@/typings/store/plugins/easyFirestore/centrosCostos";
import {
  Egresos,
  RegistrosEgresos,
} from "@/typings/store/plugins/easyFirestore/registrosEgresos";
//import { DataFormularioRegion } from "@/typings/components/regiones/formulario";
//import CargarBancosExcel from "@/components/excel/CargarBancos.vue";
import { fechaLocal } from "@/helpers/date";

const routeGuard = (vm: Vue): boolean => {
  const permisos = vm.getUsuario?.permisos;
  if (permisos && permisos["matrizPagos"]) {
    return true;
  }

  const rutas = vm.getRutasUsuario;
  if (rutas.length > 0) {
    const to = rutas[0].to;
    if (to) {
      if (vm.$route.name !== to.name) {
        vm.$router.replace(to);
      }
      return false;
    }
  }
  if (vm.$route.name !== "inicio") {
    vm.$router.replace({ name: "inicio" });
  }
  return false;
};

@Component({
  computed: mapGetters({
    getUsuarios: "usuarios/get",
    getMatrizPagos: "matrizPagos/get",
    getCentrosCostos: "centrosCostos/get",
    getRegistrosEgresos: "registrosEgresos/get",
    getArrayUsuarios: "usuarios/getArray",
    getUsuario: "usuario/get",
    getRutas: "rutas/get",
    getRutasUsuario: "usuario/getRutas",
    getArrayMatrizPagos: "matrizPagos/getArray",

    getArrayRegistrosEgresos: "registrosEgresos/getArray",
  }),
  methods: mapActions({
    setMatrizPago: "matrizPagos/set",
    deleteMatrizPago: "matrizPagos/delete",
    insertBatchMatrizPagos: "matrizPagos/insertBatch",
    //setArchivo: "archivos/set",
    //deleteArchivo: "archivos/delete",
  }),
  beforeRouteEnter(to, from, next) {
    next(routeGuard);
  },
  components: {
    DialogoEliminar,
    DialogoExito,
    TablaMatrizPago,
    FormularioMatrizPago,
    InfoMatrizPago,
    //BotonesCrud,
    MensajeEliminar,
    //CargarBancosExcel,
    //TituloVista,
  },
})
export default class VistaBancos extends Vue {
  created(): void {
    if (!routeGuard(this)) {
      return;
    }
  }

  cargando = false;
  dialogoExito = {
    model: false,
    texto: "",
    icono: "",
  };
  search = "";
  matrizPago: MatrizPago | null = null;
  egreso: RegistrosEgresos | null = null;
    centroCosto: CentroCosto | null = null;
  dialogoInfo = false;
  dialogoFormulario = false;
  dialogoEliminar = false;
  textoEliminar = "¿ESTÁS SEGURO QUE DESEAS ELIMINAR ESTE PAGO?";
  dialogoCargarExcel = false;

  get matrizPagos(): MatrizPagos {
    return this.getMatrizPagos;
  }

get egresos(): Egresos{
  return this.getRegistrosEgresos;
}

  get centrosCostos(): CentrosCostos {
    return this.getCentrosCostos;
  }
  
 get Filtro(): Array<RegistrosEgresos> {
   return Object.values(this.egresos)
   //hacer que filtre personalizada
   .filter((egreso) => {
        const inicio = new Date(fechaLocal() + " 00:00:00");
        const fin = new Date(fechaLocal() + " 23:59:59");
        const entrada = new Date(egreso.FECHA_EGRESO);
        const entradaValida = entrada >= inicio && entrada <= fin;
        const salida = new Date(egreso.FECHA_EGRESO);
        const salidaValida = salida >= inicio && salida <= fin;
        return entradaValida || salidaValida;
   })
 }

//
  get items(): Array<MatrizPago> {
    return this.Filtro.map((item) => {

    const centroCosto =item.PERIODO
     const periodo =item.GLOSA
     const cuentaEstadoResultado =item.CTA_ESTADO_RESULTADO
     const cuentaControlGasto =item.CTA_CONTROL_GASTO
     const tipoGasto =item.TIPO_GASTO
     const aplica = ""
     const montoMesAnterior = ""
     const monto =item.MONTO_EGRESO
     //const  color = ""

      return {
        centroCosto,
        periodo,
        cuentaEstadoResultado,
        cuentaControlGasto,
        tipoGasto,
        aplica,
        montoMesAnterior,
        monto,
        color: montoMesAnterior == "" ? "success" : "error",
      };
    });
  }
  
  clickItem(val: { id: string } | null): void {
    this.matrizPago = val ? this.matrizPagos[val.id] : null;
  }

  clickPlus(): void {
    this.matrizPago = null;
    this.dialogoFormulario = true;
  }

  async clickGuardar(data: DataFormularioMatrizPago): Promise<void> {
    this.cargando = true;
    const doc = data.doc;
    await this.setMatrizPago(doc);
    if (doc.id != this.matrizPago?.id) {
      this.dialogoExito = {
        model: true,
        texto: "¡Egreso creado con éxito!",
        icono: require("../assets/icons/double-check-duo.svg"),
      };
    }
    if (doc.id == this.matrizPago?.id) {
      this.dialogoExito = {
        model: true,
        texto: "¡Egreso editado con éxito!",
        icono: require("../assets/icons/editar.svg"),
      };
      this.matrizPago = doc;
    }
    this.cerrarDialogoFormulario();
    this.cargando = false;
  }

  cerrarDialogoExito(): void {
    this.dialogoExito = {
      model: false,
      texto: "",
      icono: "",
    };
  }

  clickEliminar(): void {
    const matrizPago = this.matrizPago;
    if (!matrizPago?.id) {
      console.error("error id: ", matrizPago);
      return;
    }
    this.deleteMatrizPago(matrizPago.id);
    this.matrizPago = null;
    this.cerrarDialogoEliminar();
  }

  abrirDialogoInfo(matrizPago: MatrizPago): void {
    if (!matrizPago.id) {
      console.error("error id: ", matrizPago);
      return;
    }
    const doc = this.matrizPagos[matrizPago.id];
    if (doc) {
      this.matrizPago = doc;
      this.dialogoInfo = true;
    }
  }

  cerrarDialogoInfo(): void {
    this.dialogoInfo = false;
    this.matrizPago = null;
  }

  abrirDialogoFormulario(matrizPago: MatrizPago): void {
    if (!matrizPago.id) {
      console.error("error id: ", matrizPago);
      return;
    }
    const doc = this.matrizPagos[matrizPago.id];
    if (doc) {
      this.matrizPago = doc;
      this.dialogoFormulario = true;
    }
  }

  abrirDialogoEliminar(matrizPago: MatrizPago): void {
    if (!matrizPago.id) {
      console.error("error id: ", matrizPago);
      return;
    }
    const doc = this.matrizPagos[matrizPago.id];
    if (doc) {
      this.dialogoEliminar = true;
      this.matrizPago = doc;
    }
  }

  cerrarDialogoEliminar(): void {
    this.dialogoEliminar = false;
    this.matrizPago = null;
  }

  cerrarDialogoFormulario(): void {
    this.dialogoFormulario = false;
    this.matrizPago = null;
  }
}
