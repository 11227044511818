import { RouteConfig } from "vue-router";
import TipoDocumentos from "@/views/TipoDocumentos.vue";

const tipoDocumentos: RouteConfig = {
    path: "/tipoDocumentos",
    name: "tipoDocumentos",
    component: TipoDocumentos,
};

export default tipoDocumentos;
