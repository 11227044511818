













































































































































































































































































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { CentroCosto } from "@/typings/store/plugins/easyFirestore/centrosCostos";

// helpers
//import { idtify } from "@/helpers/string";
// tipos
//import { Rutas } from "@/typings/store/modules/rutas";
//import {
//  Permisos,
//  TipoUsuario,
//  Usuario,
//} from "@/typings/store/plugins/easyFirestore/usuarios";

import {
  Local,
  //Locals
} from "@/typings/store/plugins/easyFirestore/locales";
// validaciones
import {
  // email,
  required,
} from "vuelidate/lib/validators";
import { DataFormularioLocal } from "@/typings/components/locales/formulario";
import SVGIcon from "@/components/SVGIcon.vue";
//import { Archivo, Archivos } from "@/typings/store/modules/archivos";

@Component({
  components: {
    SVGIcon,
  },
  validations: {
    centroCosto: { required },
    //direccion: { required },
    unidadLocativa: { required },
    superficie: { required },
    nivel: { required },
    fachada: { required },
    vista_planta: { required },
    estado: { required },
    tipo_local: { required },
  },
})
export default class FormularioLocal extends Vue {
  @Prop() readonly local!: Local | null;
  @Prop() readonly cargando!: boolean;
  @Prop() readonly codigoLocal!: number;
  @Prop() readonly centrosCostos!: CentroCosto[];

  created(): void {
    const local = this.local;
    if (!local) {
      return;
    }
    this.id = local.id ?? "";
    this.codigo = local.codigo ?? 0;
    this.centroCosto = local.centroCosto ?? "";
    //this.direccion = local.direccion ?? "";
    this.unidadLocativa = local.unidadLocativa ?? "";
    this.superficie = local.superficie ?? "";
    this.nivel = local.nivel ?? "";
    this.fachada = local.fachada ?? "";
    this.vista_planta = local.vista_planta ?? "";
    this.tipo_local = local.tipo_local ?? "";
    this.estado = local.estado ?? "";
  }

  id: string | null = null;
  codigo = 0;
  centroCosto = "";
  //direccion = "";
  unidadLocativa = "";
  superficie = "";
  nivel = "";
  fachada = "";
  vista_planta = "";
  estado = "";
  tipo_local = "";
  items_ocupado = ["SI", "NO"];
  items_tipo_local = ["CASA", "DEPARTAMENTO", "ESPACIO","ESTACIONAMIENTO","LOCAL","MODULO","SUPERMERCADO","OFICINA"];
  items_estado = ["VACIO", "OCUPADO"];

  get titulo(): string {
    return this.local ? "editar local" : "nuevo local";
  }

  get verificaCodigoLocal(): number {
    let numero = this.codigoLocal;
    let codigo = 0;
    if (this.codigo === 0) {
      this.codigo = numero + 1;
      codigo = this.codigo;
    } else {
      codigo = this.codigo;
    }
    return codigo;
  }

  get itemCentrosCostos(): Array<string> {
    const itemCentroCosto: Array<string> = [];
    this.centrosCostos.forEach((centroCosto) => {
      itemCentroCosto.push(centroCosto.Desc_formato);
    });

    return itemCentroCosto.sort((sesionA, sesionB) => {
      return parseInt(sesionA) - parseInt(sesionB);
    });
  }

  get centroCostoError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.centroCosto.$dirty) return errors;
    if (!this.$v.centroCosto.required)
      errors.push("Completar datos obligatorios");
    return errors;
  }

  // get direccionError(): Array<string> {
  //   const errors: Array<string> = [];
  //   if (!this.$v.direccion.$dirty) return errors;
  //   if (!this.$v.direccion.required) errors.push("Completar datos obligatorios");
  //   return errors;
  // }

  get unidadLocativaError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.unidadLocativa.$dirty) return errors;
    if (!this.$v.unidadLocativa.required)
      errors.push("Completar datos obligatorios");
    return errors;
  }

  get superficieError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.superficie.$dirty) return errors;
    if (!this.$v.superficie.required)
      errors.push("Completar datos obligatorios");
    return errors;
  }

  get nivelError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.nivel.$dirty) return errors;
    if (!this.$v.nivel.required) errors.push("Completar datos obligatorios");
    return errors;
  }

  get estadoError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.estado.$dirty) return errors;
    if (!this.$v.estado.required) errors.push("Completar datos obligatorios");
    return errors;
  }

  get tipo_localError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.tipo_local.$dirty) return errors;
    if (!this.$v.tipo_local.required) errors.push("Completar datos obligatorios");
    return errors;
  }

  get fachadaError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.fachada.$dirty) return errors;
    if (!this.$v.fachada.required) errors.push("Completar datos obligatorios");
    return errors;
  }

  get vista_plantaError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.vista_planta.$dirty) return errors;
    if (!this.$v.vista_planta.required)
      errors.push("Completar datos obligatorios");
    return errors;
  }

  @Emit("close")
  onClose(): null {
    return null;
  }

  @Emit("cerrar-dialogo")
  onCerrarDialogo(): void {
    return;
  }

  @Emit("click-guardar")
  onClickGuardar(data: DataFormularioLocal): DataFormularioLocal {
    return data;
  }

  //   @Watch("permisosKey", { immediate: true })
  //   onTipoChange(): void {
  //     console.log("a");
  //     for (const key of Object.keys(this.permisos)) {
  //       if (this.permisos[key]) {
  //         this.permisos[key] = false;
  //       }
  //     }
  //     this.permisosKey.forEach((key) => {
  //       this.permisos[key] = true;
  //     });
  //   }

  //   nombrePermiso(key: string): string {
  //     return this.rutas[key].text;
  //   }

  guardar(): void {
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }
    const doc: Local = {
      codigo: this.codigo,
      // nombre: this.nombre,
      // posicion: this.posicion,
      centroCosto: this.centroCosto,
      // direccion: this.direccion,
      unidadLocativa: this.unidadLocativa,
      superficie: this.superficie,
      nivel: this.nivel,
      fachada: this.fachada,
      vista_planta: this.vista_planta,
      estado: this.estado,
      tipo_local: this.tipo_local,
    
    };
    if (this.id) {
      doc.id = this.id;
    }
    const data: DataFormularioLocal = {
      doc: doc,
    };
    this.onClickGuardar(data);
  }
}
