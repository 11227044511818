import { RouteConfig } from "vue-router";
import Facturas from "@/views/Facturas.vue";

const facturas: RouteConfig = {
    path: "/facturas",
    name: "facturas",
    component: Facturas,
};

export default facturas;
