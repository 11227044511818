import { RouteConfig } from "vue-router";
import Inicio from "@/views/Inicio.vue";

const inicio: RouteConfig = {
  path: "/",
  name: "inicio",
  component: Inicio
};

export default inicio;
