


















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { idtify } from "@/helpers/string";
// helpers
//import { idtify } from "@/helpers/string";
// tipos
//import { Rutas } from "@/typings/store/modules/rutas";
//import {
//  Permisos,
//  TipoUsuario,
//  Usuario,
//} from "@/typings/store/plugins/easyFirestore/usuarios";
import {
  OrdenERG,
  //Bancos
} from "@/typings/store/plugins/easyFirestore/ordenesERG";
import { Proveedor } from "@/typings/store/plugins/easyFirestore/proveedores";
import { Ciudad } from "@/typings/store/plugins/easyFirestore/ciudades";
// validaciones
import {
  // email,
  required,
} from "vuelidate/lib/validators";
import { DataFormularioOrdenERG } from "@/typings/components/ordenesERG/formulario";
import { MapeoGasto } from "@/typings/store/plugins/easyFirestore/MapeoGastos";
import { CodCuenta } from "@/typings/store/plugins/easyFirestore/codCuentas";
import SVGIcon from "@/components/SVGIcon.vue";
import { Archivo } from "@/typings/store/modules/archivos";

//import { Archivo, Archivos } from "@/typings/store/modules/archivos";

@Component({
  components: {
    SVGIcon,
  },
  validations: {
    nombre: { required },
    // posicion: { required },
    empresa: { required },
    observaciones: { required },
    divisa: { required },
    direccion: { required },
    cantidad_1: { required },
    unidadMedida_1: { required },
    descripcion_1: { required },
    centroCosto_1: { required },
    precioUnitario_1: { required },
    tipoOrden: { required },
    tipoGasto: { required },
    centroCosto: { required },
  },
})
export default class FormularioOrdenERG extends Vue {
  @Prop() readonly ordenERG!: OrdenERG | null;
  @Prop() readonly proveedores!: Proveedor[];
  @Prop() readonly ciudades!: Ciudad[];
  @Prop() readonly mapeoGastos!: MapeoGasto[];
  @Prop() readonly codCuentas!: CodCuenta[];
  @Prop() readonly cargando!: boolean;
  @Prop() readonly cantidadOrdenes!: number;
  //   @Prop() readonly rutas!: Rutas;
  //   @Prop() readonly ciudades!: Ciudad[];
  //   @Prop() readonly cargando!: boolean;
  //   @Prop() readonly regiones!: Region[];

  created(): void {
    // Object.keys(this.rutas).forEach((key) => {
    //   this.$set(this.permisos, key, false);
    // });
    // console.log(this.permisos);
    const ordenERG = this.ordenERG;
    if (!ordenERG) {
      return;
    }
    this.id = ordenERG.id ?? "";
    this.nombre = ordenERG.nombre;
    // this.posicion = ordenERG.posicion ?? "";
    //
    this.ordenCompra = ordenERG.ordenCompra ?? 0;
    this.fechaEmision = ordenERG.fechaEmision ?? "";
    //this.estado = ordenERG.estado ?? "";
    this.autorizado = ordenERG.autorizado ?? "";
    // Datos de carga autmatica
    this.subTotal = ordenERG.subTotal ?? 0;
    this.afectoIVA = ordenERG.afectoIVA ?? 0;
    this.exentoIVA = ordenERG.exentoIVA ?? 0;
    this.subTotalNeto = ordenERG.subTotalNeto ?? 0;
    this.IVA = ordenERG.IVA ?? 0;
    this.total = ordenERG.total ?? 0;
    // cambiar
    this.tipoOrden = ordenERG.tipoOrden ?? "";
    this.cantidad_1 = ordenERG.cantidad_1 ?? 0;
    this.cantidad_2 = ordenERG.cantidad_2 ?? 0;
    this.cantidad_3 = ordenERG.cantidad_3 ?? 0;
    this.cantidad_4 = ordenERG.cantidad_4 ?? 0;
    this.cantidad_5 = ordenERG.cantidad_5 ?? 0;
    this.cantidad_6 = ordenERG.cantidad_6 ?? 0;
    this.cantidad_7 = ordenERG.cantidad_7 ?? 0;
    this.cantidad_8 = ordenERG.cantidad_8 ?? 0;
    this.cantidad_9 = ordenERG.cantidad_9 ?? 0;
    this.cantidad_10 = ordenERG.cantidad_10 ?? 0;
    this.cantidad_11 = ordenERG.cantidad_11 ?? 0;
    this.cantidad_12 = ordenERG.cantidad_12 ?? 0;
    this.cantidad_13 = ordenERG.cantidad_13 ?? 0;
    this.cantidad_14 = ordenERG.cantidad_14 ?? 0;
    this.cantidad_15 = ordenERG.cantidad_15 ?? 0;
    this.cantidad_16 = ordenERG.cantidad_16 ?? 0;

    this.unidadMedida_1 = ordenERG.unidadMedida_1 ?? "";
    this.unidadMedida_2 = ordenERG.unidadMedida_2 ?? "";
    this.unidadMedida_3 = ordenERG.unidadMedida_3 ?? "";
    this.unidadMedida_4 = ordenERG.unidadMedida_4 ?? "";
    this.unidadMedida_5 = ordenERG.unidadMedida_5 ?? "";
    this.unidadMedida_6 = ordenERG.unidadMedida_6 ?? "";
    this.unidadMedida_7 = ordenERG.unidadMedida_7 ?? "";
    this.unidadMedida_8 = ordenERG.unidadMedida_8 ?? "";
    this.unidadMedida_9 = ordenERG.unidadMedida_9 ?? "";
    this.unidadMedida_10 = ordenERG.unidadMedida_10 ?? "";
    this.unidadMedida_11 = ordenERG.unidadMedida_11 ?? "";
    this.unidadMedida_12 = ordenERG.unidadMedida_12 ?? "";
    this.unidadMedida_13 = ordenERG.unidadMedida_13 ?? "";
    this.unidadMedida_14 = ordenERG.unidadMedida_14 ?? "";
    this.unidadMedida_15 = ordenERG.unidadMedida_15 ?? "";
    this.unidadMedida_16 = ordenERG.unidadMedida_16 ?? "";

    this.descripcion_1 = ordenERG.descripcion_1 ?? "";
    this.descripcion_2 = ordenERG.descripcion_2 ?? "";
    this.descripcion_3 = ordenERG.descripcion_3 ?? "";
    this.descripcion_4 = ordenERG.descripcion_4 ?? "";
    this.descripcion_5 = ordenERG.descripcion_5 ?? "";
    this.descripcion_6 = ordenERG.descripcion_6 ?? "";
    this.descripcion_7 = ordenERG.descripcion_7 ?? "";
    this.descripcion_8 = ordenERG.descripcion_8 ?? "";
    this.descripcion_9 = ordenERG.descripcion_9 ?? "";
    this.descripcion_10 = ordenERG.descripcion_10 ?? "";
    this.descripcion_11 = ordenERG.descripcion_11 ?? "";
    this.descripcion_12 = ordenERG.descripcion_12 ?? "";
    this.descripcion_13 = ordenERG.descripcion_13 ?? "";
    this.descripcion_14 = ordenERG.descripcion_14 ?? "";
    this.descripcion_15 = ordenERG.descripcion_15 ?? "";
    this.descripcion_16 = ordenERG.descripcion_16 ?? "";

    this.centroCosto_1 = ordenERG.centroCosto_1 ?? "";
    this.centroCosto_2 = ordenERG.centroCosto_2 ?? "";
    this.centroCosto_3 = ordenERG.centroCosto_3 ?? "";
    this.centroCosto_4 = ordenERG.centroCosto_4 ?? "";
    this.centroCosto_5 = ordenERG.centroCosto_5 ?? "";
    this.centroCosto_6 = ordenERG.centroCosto_6 ?? "";
    this.centroCosto_7 = ordenERG.centroCosto_7 ?? "";
    this.centroCosto_8 = ordenERG.centroCosto_8 ?? "";
    this.centroCosto_9 = ordenERG.centroCosto_9 ?? "";
    this.centroCosto_10 = ordenERG.centroCosto_10 ?? "";
    this.centroCosto_11 = ordenERG.centroCosto_11 ?? "";
    this.centroCosto_12 = ordenERG.centroCosto_12 ?? "";
    this.centroCosto_13 = ordenERG.centroCosto_13 ?? "";
    this.centroCosto_14 = ordenERG.centroCosto_14 ?? "";
    this.centroCosto_15 = ordenERG.centroCosto_15 ?? "";
    this.centroCosto_16 = ordenERG.centroCosto_16 ?? "";

    this.precioUnitario_1 = ordenERG.precioUnitario_1 ?? 0;
    this.precioUnitario_2 = ordenERG.precioUnitario_2 ?? 0;
    this.precioUnitario_3 = ordenERG.precioUnitario_3 ?? 0;
    this.precioUnitario_4 = ordenERG.precioUnitario_4 ?? 0;
    this.precioUnitario_5 = ordenERG.precioUnitario_5 ?? 0;
    this.precioUnitario_6 = ordenERG.precioUnitario_6 ?? 0;
    this.precioUnitario_7 = ordenERG.precioUnitario_7 ?? 0;
    this.precioUnitario_8 = ordenERG.precioUnitario_8 ?? 0;
    this.precioUnitario_9 = ordenERG.precioUnitario_9 ?? 0;
    this.precioUnitario_10 = ordenERG.precioUnitario_10 ?? 0;
    this.precioUnitario_11 = ordenERG.precioUnitario_11 ?? 0;
    this.precioUnitario_12 = ordenERG.precioUnitario_12 ?? 0;
    this.precioUnitario_13 = ordenERG.precioUnitario_13 ?? 0;
    this.precioUnitario_14 = ordenERG.precioUnitario_14 ?? 0;
    this.precioUnitario_15 = ordenERG.precioUnitario_15 ?? 0;
    this.precioUnitario_16 = ordenERG.precioUnitario_16 ?? 0;

    this.precioTotal_1 = ordenERG.precioTotal_1 ?? 0;
    this.precioTotal_2 = ordenERG.precioTotal_2 ?? 0;
    this.precioTotal_3 = ordenERG.precioTotal_3 ?? 0;
    this.precioTotal_4 = ordenERG.precioTotal_4 ?? 0;
    this.precioTotal_5 = ordenERG.precioTotal_5 ?? 0;
    this.precioTotal_6 = ordenERG.precioTotal_6 ?? 0;
    this.precioTotal_7 = ordenERG.precioTotal_7 ?? 0;
    this.precioTotal_8 = ordenERG.precioTotal_8 ?? 0;
    this.precioTotal_9 = ordenERG.precioTotal_9 ?? 0;
    this.precioTotal_10 = ordenERG.precioTotal_10 ?? 0;
    this.precioTotal_11 = ordenERG.precioTotal_11 ?? 0;
    this.precioTotal_12 = ordenERG.precioTotal_12 ?? 0;
    this.precioTotal_13 = ordenERG.precioTotal_13 ?? 0;
    this.precioTotal_14 = ordenERG.precioTotal_14 ?? 0;
    this.precioTotal_15 = ordenERG.precioTotal_15 ?? 0;
    this.precioTotal_16 = ordenERG.precioTotal_16 ?? 0;
    // Datos de carga manual
    this.centroCosto = ordenERG.centroCosto ?? "";
    this.divisa = ordenERG.divisa ?? "";

    this.empresa = ordenERG.empresa ?? "";
    this.rut = ordenERG.rut ?? "";
    this.direccion = ordenERG.direccion ?? "";
    this.comuna = ordenERG.comuna ?? "";
    this.telefono = ordenERG.telefono ?? "";
    this.contacto = ordenERG.contacto ?? "";
    this.formaPago = ordenERG.formaPago ?? "";
    this.correo = ordenERG.correo ?? "";
    // servicios = ordenERG. ?? "";
    this.observaciones = ordenERG.observaciones ?? "";
    this.descuento = ordenERG.descuento ?? 0;
    this.tipoDescuento = ordenERG.tipoDescuento ?? "";
    this.destinatario = ordenERG.destinatario ?? "";
    this.rutDestinatario = ordenERG.rutDestinatario ?? "";
    this.banco = ordenERG.banco ?? "";
    this.tipoCueta = ordenERG.tipoCueta ?? "";
    this.nroCuenta = ordenERG.nroCuenta ?? "";
    this.correoDestinatario = ordenERG.correoDestinatario ?? "";
    // this.archivo1 = ordenERG.archivo1 ?? "";
    // this.archivo2 = ordenERG.archivo2 ?? "";
    // this.archivo3 = ordenERG.archivo3 ?? "";
    // this.archivo4 = ordenERG.archivo4 ?? "";
    this.fechaUf = ordenERG.fechaUf ?? "";
    this.solicitante = ordenERG.solicitante ?? "";
    this.motivoAnulacion = ordenERG.motivoAnulacion ?? "";

    this.archivo = Object.values(ordenERG.archivo ?? {})[0] ?? null;
    this.archivo2 = Object.values(ordenERG.archivo2 ?? {})[0] ?? null;
    this.archivo3 = Object.values(ordenERG.archivo3 ?? {})[0] ?? null;
    this.archivo4 = Object.values(ordenERG.archivo4 ?? {})[0] ?? null;
    this.archivo5 = Object.values(ordenERG.archivo5 ?? {})[0] ?? null;
    this.archivo6 = Object.values(ordenERG.archivo6 ?? {})[0] ?? null;

    this.tipoArchivo = ordenERG.tipoArchivo ?? "";
    this.tipoArchivo2 = ordenERG.tipoArchivo2 ?? "";
    this.tipoArchivo3 = ordenERG.tipoArchivo3 ?? "";
    this.tipoArchivo4 = ordenERG.tipoArchivo4 ?? "";
    this.tipoArchivo5 = ordenERG.tipoArchivo5 ?? "";
    this.tipoArchivo6 = ordenERG.tipoArchivo6 ?? "";

    this.subidoTipoArchivo = ordenERG.subidoTipoArchivo ?? "";
    this.subidoTipoArchivo2 = ordenERG.subidoTipoArchivo2 ?? "";

    //this.color = ordenERG.color ?? "";
    this.observacion = ordenERG.observacion ?? "";
    this.tipoGasto = ordenERG.tipoGasto ?? "";

    this.nFactura = ordenERG.nFactura ?? "";
    this.periodo = ordenERG.periodo ?? "";
    this.recurrente = ordenERG.recurrente ?? "";
    this.tipoRecurrencia = ordenERG.tipoRecurrencia ?? "";
    this.periodoRecurrencia = ordenERG.periodoRecurrencia ?? "";
    this.montoSaldo = ordenERG.montoSaldo ?? "";
    this.montoAP = ordenERG.montoAP ?? "";
    this.numeroCheque = ordenERG.numeroCheque ?? "";

    this.codigoCuenta = ordenERG.codigoCuenta ?? "";
    this.descripcionCuenta = ordenERG.descripcionCuenta ?? "";

    this.tipoGastoCorrecto = ordenERG.tipoGastoCorrecto ?? "";
    this.cuentaSoftlandCorrecto = ordenERG.cuentaSoftlandCorrecto ?? "";
    this.observante = ordenERG.observante ?? "";
    this.respuesta = ordenERG.respuesta ?? "";
    this.respondedor = ordenERG.respondedor ?? "";
    this.fechaVencimiento = ordenERG.fechaVencimiento.toDate().format("YYYY-MM-DD") ?? "";

  }

  options = {
    locale: "es-CL",
    prefix: "$",
    suffix: "",
    length: 18,
    precision: 0,
  };

  optionsUF = {
    locale: "es-CL",
    prefix: "",
    suffix: "UF",
    length: 18,
    precision: 2,
  };

  id: string | null = null;

  nombre = "";
  posicion = "";
  tipoOrden = "";
  //
  // Datos de carga autmatica
  ordenCompra = 0;
  fechaEmision = "";
  estado: "pendiente" | "aprobado" | "rechazado" = "pendiente";
  autorizado = "";
  // Datos de carga autmatica
  subTotal = 0;
  afectoIVA = 0;
  exentoIVA = 0;
  subTotalNeto = 0;
  IVA = 0;
  total = 0;
  // cambiar
  //servicios = "";
  // Datos de carga manual
  centroCosto = "";
  divisa = "CLP";
  empresa = "";
  rut = "";
  direccion = "";
  comuna = "";
  telefono = "";
  contacto = "";
  formaPago = "";
  correo = "";
  // servicios = "";
  cantidad_1 = 0;
  cantidad_2 = 0;
  cantidad_3 = 0;
  cantidad_4 = 0;
  cantidad_5 = 0;
  cantidad_6 = 0;
  cantidad_7 = 0;
  cantidad_8 = 0;
  cantidad_9 = 0;
  cantidad_10 = 0;
  cantidad_11 = 0;
  cantidad_12 = 0;
  cantidad_13 = 0;
  cantidad_14 = 0;
  cantidad_15 = 0;
  cantidad_16 = 0;
  unidadMedida_1 = "";
  unidadMedida_2 = "";
  unidadMedida_3 = "";
  unidadMedida_4 = "";
  unidadMedida_5 = "";
  unidadMedida_6 = "";
  unidadMedida_7 = "";
  unidadMedida_8 = "";
  unidadMedida_9 = "";
  unidadMedida_10 = "";
  unidadMedida_11 = "";
  unidadMedida_12 = "";
  unidadMedida_13 = "";
  unidadMedida_14 = "";
  unidadMedida_15 = "";
  unidadMedida_16 = "";
  descripcion_1 = "";
  descripcion_2 = "";
  descripcion_3 = "";
  descripcion_4 = "";
  descripcion_5 = "";
  descripcion_6 = "";
  descripcion_7 = "";
  descripcion_8 = "";
  descripcion_9 = "";
  descripcion_10 = "";
  descripcion_11 = "";
  descripcion_12 = "";
  descripcion_13 = "";
  descripcion_14 = "";
  descripcion_15 = "";
  descripcion_16 = "";
  centroCosto_1 = "";
  centroCosto_2 = "";
  centroCosto_3 = "";
  centroCosto_4 = "";
  centroCosto_5 = "";
  centroCosto_6 = "";
  centroCosto_7 = "";
  centroCosto_8 = "";
  centroCosto_9 = "";
  centroCosto_10 = "";
  centroCosto_11 = "";
  centroCosto_12 = "";
  centroCosto_13 = "";
  centroCosto_14 = "";
  centroCosto_15 = "";
  centroCosto_16 = "";
  precioUnitario_1 = 0;
  precioUnitario_2 = 0;
  precioUnitario_3 = 0;
  precioUnitario_4 = 0;
  precioUnitario_5 = 0;
  precioUnitario_6 = 0;
  precioUnitario_7 = 0;
  precioUnitario_8 = 0;
  precioUnitario_9 = 0;
  precioUnitario_10 = 0;
  precioUnitario_11 = 0;
  precioUnitario_12 = 0;
  precioUnitario_13 = 0;
  precioUnitario_14 = 0;
  precioUnitario_15 = 0;
  precioUnitario_16 = 0;
  precioTotal_1 = 0;
  precioTotal_2 = 0;
  precioTotal_3 = 0;
  precioTotal_4 = 0;
  precioTotal_5 = 0;
  precioTotal_6 = 0;
  precioTotal_7 = 0;
  precioTotal_8 = 0;
  precioTotal_9 = 0;
  precioTotal_10 = 0;
  precioTotal_11 = 0;
  precioTotal_12 = 0;
  precioTotal_13 = 0;
  precioTotal_14 = 0;
  precioTotal_15 = 0;
  precioTotal_16 = 0;

  observaciones = "";

  descuento = 0;
  tipoDescuento = "";
  destinatario = "";
  rutDestinatario = "";
  banco = "";
  tipoCueta = "";
  nroCuenta = "";
  correoDestinatario = "";
  // archivo1 = "";
  // archivo2 = "";
  // archivo3 = "";
  // archivo4 = "";
  fechaUf = "";
  solicitante = "";
  motivoAnulacion = "";
  bancos = [
    "BANCO CHILE / EDWARDS",
    "BANCO INTERNACIONAL",
    "BANCO ESTADO",
    "BANCO SCOTIABANK",
    "BANCO BCI",
    "BANCO DO BRASIL S.A",
    "CORPBANCA",
    "BANCO BICE",
    "BANCO HSBC BANK",
    "BANCO SANTANDER",
    "BANCO ITAU",
    "BANCO SECURITY",
    "BANCO FALABELLA",
    "BANCO RIPLEY",
    "RABOBANK",
    "BANCO CONSORCIO",
    "BANCO PARIS",
    "BBVA",
    "BANCO DEL DESARROLLO",
    "COOPEUCH",
    "CAJA LOS HEROES",
    "TENPO PREPAGO",
    "TAPP CAJA LOS ANDES",
    "MERCADO PAGO",
  ];
  tiposCuenta = ["CUENTA CORRIENTE", "CUENTA DE AHORRO", "CUENTA VISTA / RUT"];
  tiposFormaPago = [
    // "EFECTIVO",
    "TRANSFERENCIA",
    "CHEQUE",
    "CRÉDITO A 30 DÍAS",
    // "TARJETA DE CREDITO",
    // "TARJETA DE DEBITO",
    // "DEPOSITO",
  ];
  itemsDivisa = ["UF", "CLP", "USD", "EUR"];
  itemsUnidadesMedida = [
    "UNIDAD",
    "METRO",
    "METRO CUADRADO",
    "METRO CUBICO",
    "KILOGRAMO",
    "LITRO",
    "DOCENA",
  ];
  itemsCentroCosto = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
    "32",
    "33",
    "34",
    "35",
    "36",
    "37",
    "38",
    "39",
    "40",
    "41",
    "42",
    "50",
    "60",
    "70",
    "80",
    "90",
    "100",
    "110",
  ];

  itemsTipoOrden = ["EXENTA", "AFECTA"];

  archivo?: Archivo | null = null;
  archivo2?: Archivo | null = null;
  archivo3?: Archivo | null = null;
  archivo4?: Archivo | null = null;
  archivo5?: Archivo | null = null;
  archivo6?: Archivo | null = null;

  tipoArchivo = "";
  tipoArchivo2 = "";
  tipoArchivo3 = "";
  tipoArchivo4 = "";
  tipoArchivo5 = "";
  tipoArchivo6 = "";
  subidoTipoArchivo = "";

  subidoTipoArchivo2 = "";

  color: "#D5D8E3" | "success" | "red" = "#D5D8E3";

  observacion = "";
  tipoGasto = "";

  nFactura = "";
  periodo = "";
  recurrente = "";
  tipoRecurrencia = "";
  periodoRecurrencia = "";
  montoSaldo = "";
  montoAP = "";
  numeroCheque = "";

  codigoCuenta = "";
  descripcionCuenta = "";

  tipoGastoCorrecto = false;
  cuentaSoftlandCorrecto = false;

  observante = "";
  respuesta = "";
  respondedor = "";

  fechaVencimiento = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
    .toISOString()
    .substr(0, 10);


  get fechaVencimientoF(): string {
    let array_fecha = this.fechaVencimiento.split("-");
    let dia = array_fecha[2];
    let mes = array_fecha[1];
    let ano = array_fecha[0];
    console.log(array_fecha);
    this.fechaVencimiento = dia + "-" + mes + "-" + ano;
    return dia + "-" + mes + "-" + ano;
  }


  get titulo(): string {
    return this.ordenERG ? "editar ordenERG" : "nuevo ordenERG";
  }

  //   get permisosKey(): Array<string> {
  //     // return Object.keys(this.permisos).filter((key) => {
  //     //   if(this.rutas[key].tipos.includes(this.tipo)){
  //     //     this.permisos[key] = true;
  //     //   }
  //     // });
  //     return Object.keys(this.permisos).filter((key) => {
  //       if (!this.rutas[key]) {
  //         return false;
  //       }
  //       const tipo = this.rutas[key].tipos.includes(this.tipo);
  //       return tipo;
  //     });
  //   }

  // numero max de ordenesERG

  // condiciona si existe ordenCompra
  get ordenFiltrada(): number {
    let numero = 0;
    const ordenERG = this.ordenERG;
    if (ordenERG) {
      if (this.ordenCompra === 0) {
        numero = this.cantidadOrdenes + 1;
      } else {
        numero = ordenERG.ordenCompra;
      }
    } else {
      numero = this.cantidadOrdenes + 1;
    }

    return numero;
  }

  get valorTotal_1(): number {
    return this.precioUnitario_1 * this.cantidad_1;
  }

  get valorTotal_2(): number {
    return this.precioUnitario_2 * this.cantidad_2;
  }

  get valorTotal_3(): number {
    return this.precioUnitario_3 * this.cantidad_3;
  }

  get valorTotal_4(): number {
    return this.precioUnitario_4 * this.cantidad_4;
  }

  get valorTotal_5(): number {
    return this.precioUnitario_5 * this.cantidad_5;
  }

  get valorTotal_6(): number {
    return this.precioUnitario_6 * this.cantidad_6;
  }

  get valorTotal_7(): number {
    return this.precioUnitario_7 * this.cantidad_7;
  }

  get valorTotal_8(): number {
    return this.precioUnitario_8 * this.cantidad_8;
  }

  get valorTotal_9(): number {
    return this.precioUnitario_9 * this.cantidad_9;
  }

  get valorTotal_10(): number {
    return this.precioUnitario_10 * this.cantidad_10;
  }

  get valorTotal_11(): number {
    return this.precioUnitario_11 * this.cantidad_11;
  }

  get valorTotal_12(): number {
    return this.precioUnitario_12 * this.cantidad_12;
  }

  get valorTotal_13(): number {
    return this.precioUnitario_13 * this.cantidad_13;
  }

  get valorTotal_14(): number {
    return this.precioUnitario_14 * this.cantidad_14;
  }

  get valorTotal_15(): number {
    return this.precioUnitario_15 * this.cantidad_15;
  }

  get valorTotal_16(): number {
    return this.precioUnitario_16 * this.cantidad_16;
  }

  get fecha(): string {
    let fecha = new Date().toLocaleDateString("es-CL");
    if (this.ordenERG) {
      return this.ordenERG.fechaEmision;
    }
    return fecha;
  }

  get subTotalCalculado(): number {
    let numero =
      this.valorTotal_1 +
      this.valorTotal_2 +
      this.valorTotal_3 +
      this.valorTotal_4 +
      this.valorTotal_5 +
      this.valorTotal_6 +
      this.valorTotal_7 +
      this.valorTotal_8 +
      this.valorTotal_9 +
      this.valorTotal_10 +
      this.valorTotal_11 +
      this.valorTotal_12 +
      this.valorTotal_13 +
      this.valorTotal_14 +
      this.valorTotal_15 +
      this.valorTotal_16;
    numero = Math.round(numero);

    return numero;
  }

  get afectoIVACalculado(): number {
    let numero = 0;
    if (this.tipoOrden === "AFECTA") {
      numero = this.subTotalCalculado;
      this.exentoIVA;
      this.exentoIVA = 0;
    }
    return numero;
  }

  get exentoIVACalculado(): number {
    let numero = 0;
    if (this.tipoOrden === "EXENTA") {
      numero = this.subTotalCalculado;
      this.afectoIVA = 0;
    }
    return numero;
  }

  get IVACalculado(): number {
    let numero = 0;
    if (this.tipoOrden === "EXENTA") {
      numero = 0;
    }
    if (this.tipoOrden === "AFECTA") {
      numero = this.subTotalCalculado * 0.19;
      // que hace math in ceil  ?
      numero = Math.round(numero);
    }
    return numero;
  }

  get totalCalculado(): number {
    let numero = this.subTotalCalculado + this.IVACalculado;
    return Math.round(numero);
  }

  //total calculado aproximado hacia arriba
  get totalCalculadoAP(): number {
    let numero = Math.round(this.totalCalculado);
    return numero;
  }

  // get itemRutProveedores(): string | undefined {
  //   let itemRutProveedor = "";
  //   this.proveedores.forEach((proveedor) => {
  //     if (proveedor.NomAux === this.empresa) {
  //       itemRutProveedor = proveedor.RutAux;
  //       this.rut = itemRutProveedor;
  //     }
  //   });
  //   return itemRutProveedor;
  // }

  // get itemNombreProveedores(): Array<string> {
  //   const itemNombreProveedor: Array<string> = [];
  //   this.proveedores.forEach((proveedor) => {
  //     itemNombreProveedor.push(proveedor.NomAux);
  //   });
  //   return itemNombreProveedor.sort();
  // }

  get itemRutProveedores(): Array<string> {
    const itemRutProveedor: Array<string> = [];
    this.proveedores.forEach((proveedor) => {
      
        itemRutProveedor.push(proveedor.RutAux);
      
    });
    return itemRutProveedor.sort();
  }

  get itemNombreProveedores(): string | undefined {
    let itemNombreProveedor = "";
    this.proveedores.forEach((proveedor) => {
      if (
        proveedor.RutAux === this.rut
      ) {
        itemNombreProveedor = proveedor.NomAux;
        this.empresa = itemNombreProveedor;
      }
    });
    return itemNombreProveedor;
  }

  get itemsComunas(): Array<string> {
    const itemComuna: Array<string> = [];
    this.ciudades.forEach((comuna) => {
      itemComuna.push(comuna.nombre.toUpperCase());
    });
    return itemComuna.sort();
  }

  get itemCentrosCostos(): Array<string> {
    const itemCentroCosto: Array<string> = [];
    this.mapeoGastos.forEach((centroCosto) => {
      itemCentroCosto.push(centroCosto.centroCosto);
    });

    return itemCentroCosto.sort((sesionA, sesionB) => {
      return parseInt(sesionA) - parseInt(sesionB);
    });
  }

  get itemTipoGastos(): Array<string> {
    const itemTipoGasto: Array<string> = [];
    // this.mapeoGastos.forEach((mapeoGasto) => {
    //   if (
    //     mapeoGasto.centroCosto === this.centroCosto &&
    //     mapeoGasto.rut === this.rut
    //   ) {
        itemTipoGasto.push("MANTENCIÓN");
    //   }
    // });
    return itemTipoGasto.sort();
  }

  get itemCodigoCuentas(): Array<string> {
    const itemCodigoCuentas: Array<string> = [];
    this.codCuentas.forEach((descripcionCuenta) => {
      itemCodigoCuentas.push(descripcionCuenta.PCCODI);
    });
    return itemCodigoCuentas.sort();
  }

  get itemDescripcionCuentas(): string | undefined {
    let itemDescripcionCuentas = "";
    this.codCuentas.forEach((descripcionCuenta) => {
      if (descripcionCuenta.PCCODI === this.codigoCuenta) {
        itemDescripcionCuentas = descripcionCuenta.PCDESC;
        this.descripcionCuenta = itemDescripcionCuentas;
      }
    });
    return itemDescripcionCuentas;
  }

  get nombreError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.nombre.$dirty) return errors;
    if (!this.$v.nombre.required) errors.push("");
    return errors;
  }

  get empresaError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.empresa.$dirty) return errors;
    if (!this.$v.empresa.required) errors.push("");
    return errors;
  }

  get posicionError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.posicion.$dirty) return errors;
    if (!this.$v.posicion.required) errors.push("");
    return errors;
  }

  get observacionesError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.observaciones.$dirty) return errors;
    if (!this.$v.observaciones.required) errors.push("");
    return errors;
  }

  get divisaError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.divisa.$dirty) return errors;
    if (!this.$v.divisa.required) errors.push("");
    return errors;
  }

  get direccionError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.direccion.$dirty) return errors;
    if (!this.$v.direccion.required) errors.push("");
    return errors;
  }

  get cantidad_1Error(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.cantidad_1.$dirty) return errors;
    if (!this.$v.cantidad_1.required) errors.push("");
    return errors;
  }

  get unidadMedida_1Error(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.unidadMedida_1.$dirty) return errors;
    if (!this.$v.unidadMedida_1.required) errors.push("");
    return errors;
  }

  get descripcion_1Error(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.descripcion_1.$dirty) return errors;
    if (!this.$v.descripcion_1.required) errors.push("");
    return errors;
  }

  get centroCosto_1Error(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.centroCosto_1.$dirty) return errors;
    if (!this.$v.centroCosto_1.required) errors.push("");
    return errors;
  }

  get precioUnitario_1Error(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.precioUnitario_1.$dirty) return errors;
    if (!this.$v.precioUnitario_1.required) errors.push("");
    return errors;
  }

  get tipoOrdenError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.tipoOrden.$dirty) return errors;
    if (!this.$v.tipoOrden.required) errors.push("");
    return errors;
  }

  get centroCostoError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.centroCosto.$dirty) return errors;
    if (!this.$v.centroCosto.required) errors.push("");
    return errors;
  }

  get tipoGastoError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.tipoGasto.$dirty) return errors;
    if (!this.$v.tipoGasto.required) errors.push("");
    return errors;
  }

  @Emit("close")
  onClose(): null {
    return null;
  }

  @Emit("cerrar-dialogo")
  onCerrarDialogo(): void {
    return;
  }

  @Emit("click-guardar")
  onClickGuardar(data: DataFormularioOrdenERG): DataFormularioOrdenERG {
    return data;
  }

  //   @Watch("permisosKey", { immediate: true })
  //   onTipoChange(): void {
  //     console.log("a");
  //     for (const key of Object.keys(this.permisos)) {
  //       if (this.permisos[key]) {
  //         this.permisos[key] = false;
  //       }
  //     }
  //     this.permisosKey.forEach((key) => {
  //       this.permisos[key] = true;
  //     });
  //   }

  //   nombrePermiso(key: string): string {
  //     return this.rutas[key].text;
  //   }

  guardar(): void {
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }
    const doc: OrdenERG = {
      nombre: this.nombre.toUpperCase(),
      // posicion: this.posicion,

      // Datos de carga autmatica

      // fecha actual

      ordenCompra: this.ordenFiltrada,
      fechaEmision: this.fecha,
      estado: this.estado,
      autorizado: this.autorizado,
      subTotal: this.subTotalCalculado,
      afectoIVA: this.afectoIVACalculado,
      exentoIVA: this.exentoIVACalculado,
      subTotalNeto: this.subTotalCalculado,
      IVA: this.IVACalculado,
      total: this.totalCalculado,
      tipoOrden: this.tipoOrden,
      // cambiar
      //servicios: this.servicios,
      cantidad_1: this.cantidad_1,
      cantidad_2: this.cantidad_2,
      cantidad_3: this.cantidad_3,
      cantidad_4: this.cantidad_4,
      cantidad_5: this.cantidad_5,
      cantidad_6: this.cantidad_6,
      cantidad_7: this.cantidad_7,
      cantidad_8: this.cantidad_8,
      cantidad_9: this.cantidad_9,
      cantidad_10: this.cantidad_10,
      cantidad_11: this.cantidad_11,
      cantidad_12: this.cantidad_12,
      cantidad_13: this.cantidad_13,
      cantidad_14: this.cantidad_14,
      cantidad_15: this.cantidad_15,
      cantidad_16: this.cantidad_16,

      unidadMedida_1: this.unidadMedida_1.toUpperCase(),
      unidadMedida_2: this.unidadMedida_2.toUpperCase(),
      unidadMedida_3: this.unidadMedida_3.toUpperCase(),
      unidadMedida_4: this.unidadMedida_4.toUpperCase(),
      unidadMedida_5: this.unidadMedida_5.toUpperCase(),
      unidadMedida_6: this.unidadMedida_6.toUpperCase(),
      unidadMedida_7: this.unidadMedida_7.toUpperCase(),
      unidadMedida_8: this.unidadMedida_8.toUpperCase(),
      unidadMedida_9: this.unidadMedida_9.toUpperCase(),
      unidadMedida_10: this.unidadMedida_10.toUpperCase(),
      unidadMedida_11: this.unidadMedida_11.toUpperCase(),
      unidadMedida_12: this.unidadMedida_12.toUpperCase(),
      unidadMedida_13: this.unidadMedida_13.toUpperCase(),
      unidadMedida_14: this.unidadMedida_14.toUpperCase(),
      unidadMedida_15: this.unidadMedida_15.toUpperCase(),
      unidadMedida_16: this.unidadMedida_16.toUpperCase(),

      descripcion_1: this.descripcion_1.toUpperCase(),
      descripcion_2: this.descripcion_2.toUpperCase(),
      descripcion_3: this.descripcion_3.toUpperCase(),
      descripcion_4: this.descripcion_4.toUpperCase(),
      descripcion_5: this.descripcion_5.toUpperCase(),
      descripcion_6: this.descripcion_6.toUpperCase(),
      descripcion_7: this.descripcion_7.toUpperCase(),
      descripcion_8: this.descripcion_8.toUpperCase(),
      descripcion_9: this.descripcion_9.toUpperCase(),
      descripcion_10: this.descripcion_10.toUpperCase(),
      descripcion_11: this.descripcion_11.toUpperCase(),
      descripcion_12: this.descripcion_12.toUpperCase(),
      descripcion_13: this.descripcion_13.toUpperCase(),
      descripcion_14: this.descripcion_14.toUpperCase(),
      descripcion_15: this.descripcion_15.toUpperCase(),
      descripcion_16: this.descripcion_16.toUpperCase(),

      centroCosto_1: this.centroCosto_1,
      centroCosto_2: this.centroCosto_2,
      centroCosto_3: this.centroCosto_3,
      centroCosto_4: this.centroCosto_4,
      centroCosto_5: this.centroCosto_5,
      centroCosto_6: this.centroCosto_6,
      centroCosto_7: this.centroCosto_7,
      centroCosto_8: this.centroCosto_8,
      centroCosto_9: this.centroCosto_9,
      centroCosto_10: this.centroCosto_10,
      centroCosto_11: this.centroCosto_11,
      centroCosto_12: this.centroCosto_12,
      centroCosto_13: this.centroCosto_13,
      centroCosto_14: this.centroCosto_14,
      centroCosto_15: this.centroCosto_15,
      centroCosto_16: this.centroCosto_16,

      precioUnitario_1: this.precioUnitario_1,
      precioUnitario_2: this.precioUnitario_2,
      precioUnitario_3: this.precioUnitario_3,
      precioUnitario_4: this.precioUnitario_4,
      precioUnitario_5: this.precioUnitario_5,
      precioUnitario_6: this.precioUnitario_6,
      precioUnitario_7: this.precioUnitario_7,
      precioUnitario_8: this.precioUnitario_8,
      precioUnitario_9: this.precioUnitario_9,
      precioUnitario_10: this.precioUnitario_10,
      precioUnitario_11: this.precioUnitario_11,
      precioUnitario_12: this.precioUnitario_12,
      precioUnitario_13: this.precioUnitario_13,
      precioUnitario_14: this.precioUnitario_14,
      precioUnitario_15: this.precioUnitario_15,
      precioUnitario_16: this.precioUnitario_16,

      precioTotal_1: this.valorTotal_1,
      precioTotal_2: this.valorTotal_2,
      precioTotal_3: this.valorTotal_3,
      precioTotal_4: this.valorTotal_4,
      precioTotal_5: this.valorTotal_5,
      precioTotal_6: this.valorTotal_6,
      precioTotal_7: this.valorTotal_7,
      precioTotal_8: this.valorTotal_8,
      precioTotal_9: this.valorTotal_9,
      precioTotal_10: this.valorTotal_10,
      precioTotal_11: this.valorTotal_11,
      precioTotal_12: this.valorTotal_12,
      precioTotal_13: this.valorTotal_13,
      precioTotal_14: this.valorTotal_14,
      precioTotal_15: this.valorTotal_15,
      precioTotal_16: this.valorTotal_16,

      // Datos de carga manual
      centroCosto: this.centroCosto,
      divisa: this.divisa,
      empresa: this.empresa,
      rut: this.rut.toUpperCase(),
      direccion: this.direccion.toUpperCase(),
      comuna: this.comuna,
      telefono: this.telefono,
      contacto: this.contacto.toUpperCase(),
      formaPago: this.formaPago.toUpperCase(),
      correo: this.correo.toUpperCase(),
      observaciones: this.observaciones.toUpperCase(),
      descuento: this.descuento,
      tipoDescuento: this.tipoDescuento,
      destinatario: this.destinatario.toUpperCase(),
      rutDestinatario: this.rutDestinatario.toUpperCase(),
      banco: this.banco.toUpperCase(),
      tipoCueta: this.tipoCueta.toUpperCase(),
      nroCuenta: this.nroCuenta,
      correoDestinatario: this.correoDestinatario.toUpperCase(),
      // archivo1: this.archivo1,
      // archivo2: this.archivo2,
      // archivo3: this.archivo3,
      // archivo4: this.archivo4,
      fechaUf: this.fechaUf,
      solicitante: this.solicitante.toUpperCase(),
      motivoAnulacion: this.motivoAnulacion.toUpperCase(),
      tipoArchivo: this.tipoArchivo,
      tipoArchivo2: this.tipoArchivo2,
      tipoArchivo3: this.tipoArchivo3,
      tipoArchivo4: this.tipoArchivo4,
      tipoArchivo5: this.tipoArchivo5,
      tipoArchivo6: this.tipoArchivo6,
      subidoTipoArchivo: this.subidoTipoArchivo,
      subidoTipoArchivo2: this.subidoTipoArchivo2,
      color: this.color,
      observacion: this.observacion,
      tipoGasto: this.tipoGasto,
      // tipoArchivo2: this.tipoArchivo2,

      nFactura: this.nFactura,
      periodo: this.periodo,
      recurrente: this.recurrente,
      tipoRecurrencia: this.tipoRecurrencia,
      periodoRecurrencia: this.periodoRecurrencia,
      montoSaldo: this.montoSaldo,
      montoAP: this.montoAP,
      numeroCheque: this.numeroCheque,

      codigoCuenta: this.codigoCuenta,
      descripcionCuenta: this.descripcionCuenta,

      tipoGastoCorrecto: this.tipoGastoCorrecto,
      cuentaSoftlandCorrecto: this.cuentaSoftlandCorrecto,
      observante: this.observante,

      respuesta: this.respuesta.toUpperCase(),
      respondedor: this.respondedor.toUpperCase(),
      fechaVencimiento: this.fechaVencimientoF ?? "",

      
    };
    if (this.id) {
      doc.id = this.id;
    }
    const data: DataFormularioOrdenERG = {
      doc: doc,
      borrar: {},
      archivo: {},
      archivo2: {},
      archivo3: {},
      archivo4: {},
      archivo5: {},
      archivo6: {},
    };

    if (this.archivo) {
      data.archivo = {
        [idtify(this.archivo.id)]: this.archivo,
      };
    }
    if (this.archivo2) {
      data.archivo2 = {
        [idtify(this.archivo2.id)]: this.archivo2,
      };
    }
    if (this.archivo3) {
      data.archivo3 = {
        [idtify(this.archivo3.id)]: this.archivo3,
      };
    }
    if (this.archivo4) {
      data.archivo4 = {
        [idtify(this.archivo4.id)]: this.archivo4,
      };
    }
    if (this.archivo5) {
      data.archivo5 = {
        [idtify(this.archivo5.id)]: this.archivo5,
      };
    }
    if (this.archivo6) {
      data.archivo6 = {
        [idtify(this.archivo6.id)]: this.archivo6,
      };
    }

    this.onClickGuardar(data);
  }
}
