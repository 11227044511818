import { RouteConfig } from "vue-router";
import CategoriasCurso from "@/views/CategoriasCurso.vue";

const categoriasCurso: RouteConfig = {
    path: "/categoriasCurso",
    name: "categoriasCurso",
    component: CategoriasCurso,
};

export default categoriasCurso;
