import { RouteConfig } from "vue-router";
import MapeoGastos from "@/views/MapeoGastos.vue";

const mapeoGastos: RouteConfig = {
    path: "/mapeoGastos",
    name: "mapeoGastos",
    component: MapeoGastos,
};

export default mapeoGastos;
