//tipos
import { GetterTree } from "vuex";
import { State } from "@/typings/store";
import { StatePrenominas } from "@/typings/store/plugins/easyFirestore/prenominas";

const getters: GetterTree<StatePrenominas, State> = {
    get(state) {
        return state.all;
    },
    getArray(state) {
        return Object.values(state.all);
    },
};

const prenominas = {
    firestorePath: "dev/v1/prenominas",
    firestoreRefType: "collection",
    moduleName: "prenominas",
    statePropName: "all",
    namespaced: true,
    serverChange: {
        convertTimestamps: {
            created_at: "%convertTimestamp%", // default
            updated_at: "%convertTimestamp%", // default
        },
    },
    state: {},
    getters,
    mutations: {},
    actions: {},
};

export default prenominas;
