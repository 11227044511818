
















































































































































































// decoradores
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
// tipos
import { HeadersDataTable } from "@/typings/vuetify/vDataTable";
import { Ingreso } from "@/typings/store/plugins/easyFirestore/ingresos";
import { ItemsTablaIngresos } from "@/typings/components/ingresos/tabla";
import "@/helpers/extensions";

@Component
export default class TablaUsuarios extends Vue {
  @Prop() readonly opciones!: boolean;
  @Prop() readonly search!: string;
  @Prop() readonly ingresos!: Array<Ingreso>;

  options = {
    itemsPerPage: -1,
  };
  headerProps = {
    sortIcon: "mdi-chevron-down",
  };

  get headers(): HeadersDataTable {
    return [
      //{text: "", value: "estado", sortable: false},
      { text: "Periodo Arrendamiento", value: "periodoArrendamiento" },
      { text: "Fecha Ingreso", value: "fecha" },
      { text: "Preriodo SII", value: "periodoSii" },
      //{ text: "Tipo Ingreso", value: "tipoIngreso" },
      { text: "N° Documento", value: "nDocumento" },
      { text: "Monto Factura", value: "montoIngreso" },
      { text: "Monto Abono", value: "montoAbono" },
      { text: "Banco", value: "banco" },
      { text: "Observación", value: "observacion" },
      { text: "Rut Cliente", value: "rutCliente" },
      { text: "Nombre Cliente", value: "nombreCliente" },
      //{ text: "N Contrato", value: "nContrato" },
      { text: "Centro de Costo", value: "centroCosto" },
      { text: "Local", value: "local" },

      { text: "", value: "opciones", sortable: false },
    ];
  }

  get items(): ItemsTablaIngresos {
    return this.ingresos.map((ingreso) => {
      const periodoArrendamiento = ingreso.PERIODO_ARRENDAMIENTO;
      const fecha = ingreso.FECHA;
      const periodoSii = ingreso.PERIODO_LIBRO_SII;
      const tipoIngreso = ingreso.TIPO_IGRESO;
      const nDocumento = ingreso.N_DOCUMENTO;
      const montoIngreso = (((parseInt(ingreso.MONTO_INGRESO,10)).toCurrency({symbol: '$'})) ?? "").toString();
      const montoAbono = (((parseInt(ingreso.MONTO_ABONO,10)).toCurrency({symbol: '$'})) ?? "").toString();
      const banco = ingreso.BANCO;
      const observacion = ingreso.GLOSA;
      const rutCliente = ingreso.RUT_CLIENTE;
      const nombreCliente = ingreso.NOMBRE_CLIENTE;
      const nContrato = ingreso.N_CONTRATO;
      const centroCosto = ingreso.CENTRO_DE_COSTO;
      const local = ingreso.LOCAL;

      return {
        periodoArrendamiento,
        fecha,
        periodoSii,
        tipoIngreso,
        nDocumento,
        montoIngreso,
        montoAbono,
        banco,
        observacion,
        rutCliente,
        nombreCliente,
        nContrato,
        centroCosto,
        local,

        data: ingreso,
      };
    });
  }

  //   actualizarEstado(item: Usuario): void {
  //     let estado: EstadoUsuario;
  //     item.estado === 'libre' ? estado = 'bloqueado' : estado = 'libre';
  //     item.estado = estado;
  //     this.onCambiarEstado(item);
  //   }

  //   @Emit("cambiar-estado")
  //   onCambiarEstado(usuario: Usuario): Usuario {
  //     return usuario;
  //   }

  @Emit("abrir-info")
  onAbrirInfo(ingreso: Ingreso): Ingreso {
    return ingreso;
  }

  @Emit("abrir-editar")
  onAbrirEditar(ingreso: Ingreso): Ingreso {
    return ingreso;
  }

  @Emit("abrir-eliminar")
  onAbrirEliminar(ingreso: Ingreso): Ingreso {
    return ingreso;
  }
}
