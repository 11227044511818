import { RouteConfig } from "vue-router";
import Principal from "@/views/Principal.vue";

const principal: RouteConfig = {
  path: "/",
  name: "principal",
  component: Principal
};

export default principal;
