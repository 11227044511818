import { RouteConfig } from "vue-router";
import CajaChicas from "@/views/CajaChicas.vue";

const cajaChicas: RouteConfig = {
    path: "/cajaChicas",
    name: "cajaChicas",
    component: CajaChicas,
};

export default cajaChicas;
