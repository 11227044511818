















































































// decoradores
import {Component, Vue, Prop} from "vue-property-decorator";
import {Pago} from "@/typings/store/plugins/easyFirestore/pagos";
import {Usuario, Usuarios} from "@/typings/store/plugins/easyFirestore/usuarios";
import {Curso} from "@/typings/store/plugins/easyFirestore/cursos";

@Component
export default class BannerPago extends Vue {
  @Prop() readonly pagos!: Array<Pago>
  @Prop() readonly relatores!: Array<Usuario>
  @Prop() readonly tipoUsuario!: string
  @Prop() readonly cursos!: Array<Curso>

  created(): void {
    if (!this.pagos) {
      return;
    }
  }

  pago: Pago | null = null;

  get totalDebito(): number {
    return this.pagos.filter((pago) => {
      if (pago.resultado?.payment_type_code === "VD" || pago.resultado?.payment_type_code === "VP") {
        return pago;
      }
    }).reduce((total, pago) => total + (pago.resultado?.amount ?? 0), 0);
  }

  get total(): number {
    if (this.tipoUsuario == "relator"){
      return this.comisionRelator
    }
    if (this.tipoUsuario=="cliente"){
      return this.subtotal;
    }
    if (this.subtotal <= 0 && this.comision <= 0 && this.comisionRelator<= 0){
      return 0
    }
    
    return (this.subtotal - this.comision) - (this.comisionRelator)

  }

  get totalCredito(): number {
    return Math.round(this.pagos.filter((pago) => {
      if (pago.resultado?.payment_type_code === "VN" || pago.resultado?.payment_type_code === "VC" || pago.resultado?.payment_type_code === "SI" || pago.resultado?.payment_type_code === "S2" || pago.resultado?.payment_type_code === "NC") {
        return pago;
      }
    }).reduce((total, pago) => total + (pago.resultado?.amount ?? 0), 0))
  }

  get subtotal(): number {
    return Math.round(this.totalCredito + this.totalDebito);
  }

  get comision(): number {
    const comisionDebito = Math.round(this.totalDebito * 0.0139);
    const ivaDebito = Math.round(comisionDebito * 0.19);
    const comisionCredito = Math.round(this.totalCredito * 0.0236);
    const ivaCredito = Math.round(comisionCredito * 0.19);

    return Math.round((comisionDebito + ivaDebito + comisionCredito + ivaCredito));
  }

  get comisionRelator(): number {
    let CursosRecorridos: Array<string> = [];
   
    if (this.tipoUsuario == "relator"){
      const comisionCurso= Math.round(this.pagos.reduce((totalCurso,pago) => totalCurso + Object.values(pago.cursos).reduce((total,curso) =>{
        if (this.cursos.some((course) => course.id == curso.id)){
          if (curso.comision.tipo === "Por participante") {
            if (curso.comision.valor.includes('%')) {
              return total + Math.round(curso.precio * (parseFloat(curso.comision.valor.replace("%", "")) / 100));
            } 
            else {
              return total + parseFloat(curso.comision.valor);
            }
          } 
          else {
            if (CursosRecorridos.isEmpty || !CursosRecorridos.includes(curso.id ?? "") && !curso.comision.valor.includes('$')) {
              CursosRecorridos.push(curso.id ?? "");
              return total + parseFloat(curso.comision.valor);
            }
          }
        }
        return total;
      },0),0));

      const comisionSesion =Math.round(this.pagos.reduce((totalSesion, pago) =>
      totalSesion + Object.values(pago.sesiones).reduce((sum, sesion) => {
        if (sesion.precio !== 0) {
          if (sesion.curso.comision.tipo.includes("%")) {
            return sum + sesion.precio * (parseFloat(sesion.curso.comision.valor.replace("%", "")) / 100)
          }
          return sum + sesion.curso.comision.valor.toInt()
        }
        return sum;
      }, 0), 0));
      return comisionCurso+comisionSesion;
    }
    else{
      const comisionCurso = Math.round(this.pagos.reduce((totalCurso, pago) =>
      totalCurso + Object.values(pago.cursos).reduce((total, curso) => {
        if (curso.comision.tipo === "Por participante") {
          if (curso.comision.valor.includes('%')) {
            return total + Math.round(curso.precio * (parseFloat(curso.comision.valor.replace("%", "")) / 100));
          } 
          else {
            return total + parseFloat(curso.comision.valor);
          }
        } 
        else {
          if (CursosRecorridos.isEmpty || !CursosRecorridos.includes(curso.id ?? "") && !curso.comision.valor.includes('$')) {
            CursosRecorridos.push(curso.id ?? "");
            return total + parseFloat(curso.comision.valor);
          }
        }
        return total
      }, 0), 0))
      
    const comisionSesion = Math.round(this.pagos.reduce((totalSesion, pago) =>
      totalSesion + Object.values(pago.sesiones).reduce((sum, sesion) => {
        if (sesion.precio !== 0) {
          if (sesion.curso.comision.tipo.includes("%")) {
            return sum + sesion.precio * (parseFloat(sesion.curso.comision.valor.replace("%", "")) / 100)
          }
          return sum + sesion.curso.comision.valor.toInt()
        }
        return sum;
      }, 0), 0))
       return comisionCurso + comisionSesion;
    }
  }

  get asistentes(): number {
    const asistentes: Usuarios = {};
    for (const pago of this.pagos) {
      if (!asistentes[pago.cliente.id]) {
        asistentes[pago.cliente.id] = pago.cliente;
      }
    }
    return Object.values(asistentes).length;
  }
}
