





























































































































































// decoradores
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
// tipos
import { HeadersDataTable } from "@/typings/vuetify/vDataTable";
import { MapeoGasto } from "@/typings/store/plugins/easyFirestore/mapeoGastos";
import { ItemsTablaMapeoGastos } from "@/typings/components/mapeoGastos/tabla";
//import { fechaLocal } from "@/helpers/date";
import "@/helpers/extensions";

@Component
export default class TablaMapeoGastos extends Vue {
  @Prop() readonly opciones!: boolean;
  @Prop() readonly search!: string;
  @Prop() readonly mapeoGastos!: Array<MapeoGasto>;

  options = {
    itemsPerPage: 10,
  };
  headerProps = {
    sortIcon: "mdi-chevron-down",
  };

  get headers(): HeadersDataTable {
    return [
      { text: "Recurrente", value: "recurrente" },
      { text: "Centro Costo", value: "centroCosto" },
      { text: "Rut", value: "rut" },
      { text: "Nombre Proveedor", value: "nombreProveedor" },
      { text: "Tipo Gasto", value: "tipoGasto" },
      { text: "Cuenta Gasto", value: "cuentaGasto" },
      { text: "Cuenta Estado Resultado", value: "cuentaEstadoResultado" },
      { text: "N. Cliente", value: "nCliente" },
      { text: "F. Asignacion", value: "fechaAsignacion" },
      { text: "", value: "opciones", sortable: false },
    ];
  }

  get items(): ItemsTablaMapeoGastos {
    return this.mapeoGastos.map((mapeoGasto) => {
      const centroCosto = mapeoGasto.centroCosto;
      const rut = mapeoGasto.rut;
      const nombreProveedor = mapeoGasto.nombreProveedor;
      const tipoGasto = mapeoGasto.tipoGasto;
      const cuentaGasto = mapeoGasto.cuentaGasto;
      const cuentaEstadoResultado = mapeoGasto.cuentaEstadoResultado;
      const nCliente = mapeoGasto.nCliente;
      const fechaAsignacion = mapeoGasto.fechaAsignacion;
      const recurrente = mapeoGasto.recurrente;
      const observacion = mapeoGasto.observacion;
      const descripcionCuenta = mapeoGasto.descripcionCuenta;
      const tipoDocumento = mapeoGasto.tipoDocumento;
      const codigoCuenta = mapeoGasto.codigoCuenta;


      return {
        centroCosto,
        rut,
        nombreProveedor,
        tipoGasto,
        cuentaGasto,
        cuentaEstadoResultado,
        nCliente,
        fechaAsignacion,
        recurrente,
        observacion,
        descripcionCuenta,
        tipoDocumento,
        codigoCuenta,

        data: mapeoGasto,
      };
    });
  }

  @Emit("cambiar-estado")
  onCambiarEstado(mapeoGasto: MapeoGasto): MapeoGasto {
    return mapeoGasto;
  }

  @Emit("abrir-info")
  onAbrirInfo(mapeoGasto: MapeoGasto): MapeoGasto {
    return mapeoGasto;
  }

  @Emit("abrir-editar")
  onAbrirEditar(mapeoGasto: MapeoGasto): MapeoGasto {
    return mapeoGasto;
  }

  @Emit("abrir-eliminar")
  onAbrirEliminar(mapeoGasto: MapeoGasto): MapeoGasto {
    return mapeoGasto;
  }
}
