








































// decoradores
import {Component, Emit, Prop, Vue} from "vue-property-decorator";
// tipos
import {HeadersDataTable} from "@/typings/vuetify/vDataTable";
import {Notificacion} from "@/typings/store/plugins/easyFirestore/notificaciones";
import {ItemsTablaNotificaciones} from "@/typings/components/notificaciones/tabla";

@Component
export default class TablaNotificaciones extends Vue {
  @Prop() readonly opciones!: boolean;
  @Prop() readonly search!: string;
  @Prop() readonly notificaciones!: Array<Notificacion>;

  options = {
    itemsPerPage: -1,
  };
  headerProps = {
    sortIcon: "mdi-chevron-down"
  }

  get headers(): HeadersDataTable {
    return [
      {text: "Título", value: "titulo"},
      {text: "Contenido", value: "contenido"},
      {text: "", value: "opciones", sortable: false}
    ];
  }



  get items(): ItemsTablaNotificaciones {
    return this.notificaciones.map((notificacion) => {
      const titulo = notificacion.titulo;
      const contenido = notificacion.contenido;
      return {
        titulo,
        contenido,
        data: notificacion
      };
    });
  }

  @Emit("abrir-info")
  onAbrirInfo(notificacion: Notificacion): Notificacion {
    return notificacion;
  }

}
