



































































































































































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
// helpers
//import { idtify } from "@/helpers/string";
// tipos
//import { Rutas } from "@/typings/store/modules/rutas";
//import {
//  Permisos,
//  TipoUsuario,
//  Usuario,
//} from "@/typings/store/plugins/easyFirestore/usuarios";
import {
  Equipo,
  //Bancos
} from "@/typings/store/plugins/easyFirestore/equipos";
// validaciones
//import {
  // email,
//  required,
//} from "vuelidate/lib/validators";
import { DataFormularioEquipo } from "@/typings/components/equipos/formulario";
import SVGIcon from "@/components/SVGIcon.vue";
//import { Archivo, Archivos } from "@/typings/store/modules/archivos";

@Component({
  components: {
    SVGIcon,
  },
  validations: {

  },
})
export default class FormularioBanco extends Vue {
  @Prop() readonly equipo!: Equipo | null;
  @Prop() readonly cargando!: boolean;
  //   @Prop() readonly rutas!: Rutas;
  //   @Prop() readonly ciudades!: Ciudad[];
  //   @Prop() readonly cargando!: boolean;
  //   @Prop() readonly regiones!: Region[];

  created(): void {
    // Object.keys(this.rutas).forEach((key) => {
    //   this.$set(this.permisos, key, false);
    // });
    // console.log(this.permisos);
    const equipo = this.equipo;
    if (!equipo) {
      return;
    }
    this.id = equipo.id ?? "";
    this.nombre = equipo.nombre;
    this.posicion = equipo.posicion ?? "";
    this.desglose = equipo.desglose ?? "";
    this.marca = equipo.marca ?? "";
    this.modelo = equipo.modelo ?? "";
    this.ubicacion = equipo.ubicacion ?? "";
    this.foto = equipo.foto ?? "";
    this.piso = equipo.piso ?? "";
  }

  id: string | null = null;

  posicion = "";
  nombre = "";
  desglose = "";
  marca = "";
  modelo = "";
  ubicacion = "";
  foto = "";
  piso = "";

  get titulo(): string {
    return this.equipo ? "editar equipo" : "nuevo equipo";
  }

  //   get permisosKey(): Array<string> {
  //     // return Object.keys(this.permisos).filter((key) => {
  //     //   if(this.rutas[key].tipos.includes(this.tipo)){
  //     //     this.permisos[key] = true;
  //     //   }
  //     // });
  //     return Object.keys(this.permisos).filter((key) => {
  //       if (!this.rutas[key]) {
  //         return false;
  //       }
  //       const tipo = this.rutas[key].tipos.includes(this.tipo);
  //       return tipo;
  //     });
  //   }

  get nombreError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.nombre.$dirty) return errors;
    if (!this.$v.nombre.required) errors.push("Completar datos obligatorios");
    return errors;
  }

  get posicionError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.posicion.$dirty) return errors;
    if (!this.$v.posicion.required) errors.push("Completar datos obligatorios");
    return errors;
  }

  @Emit("close")
  onClose(): null {
    return null;
  }

  @Emit("cerrar-dialogo")
  onCerrarDialogo(): void {
    return;
  }

  @Emit("click-guardar")
  onClickGuardar(data: DataFormularioEquipo): DataFormularioEquipo {
    return data;
  }

  //   @Watch("permisosKey", { immediate: true })
  //   onTipoChange(): void {
  //     console.log("a");
  //     for (const key of Object.keys(this.permisos)) {
  //       if (this.permisos[key]) {
  //         this.permisos[key] = false;
  //       }
  //     }
  //     this.permisosKey.forEach((key) => {
  //       this.permisos[key] = true;
  //     });
  //   }

  //   nombrePermiso(key: string): string {
  //     return this.rutas[key].text;
  //   }

  guardar(): void {
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }
    const doc: Equipo = {
      nombre: this.nombre.toUpperCase() || "", 
      posicion: this.posicion,

      desglose: this.desglose.toUpperCase() || "",
      marca: this.marca.toUpperCase() || "",
      modelo: this.modelo.toUpperCase() || "",
      ubicacion: this.ubicacion.toUpperCase() || "",
      foto: this.foto.toUpperCase() || "",
      piso: this.piso.toUpperCase() || "",
    };
    if (this.id) {
      doc.id = this.id;
    }
    const data: DataFormularioEquipo = {
      doc: doc,
    };
    this.onClickGuardar(data);
  }
}
