import { ActionTree, GetterTree, MutationTree } from "vuex";

//tipos
import { State } from "@/typings/store";
import { UsuarioState } from "@/typings/store/modules/usuario";
import {
  Usuarios,
  Usuario,
} from "@/typings/store/plugins/easyFirestore/usuarios";
import { Ruta, Rutas } from "@/typings/store/modules/rutas";

const state: UsuarioState = {
  usuario: null,
};

const getters: GetterTree<UsuarioState, State> = {
  get(state, getters, rootState, rootGetters) {
    const usuarios = rootGetters["usuarios/get"] as Usuarios;
    const id = state.usuario?.id;
    return id ? usuarios[id] : null;
  },
  getRutas(state, getters, rootState, rootGetters) {
    const usuario = getters["get"] as Usuario | null;
    const permisos = usuario?.permisos ? usuario.permisos : {};
    console.log("permisos", permisos);
    const permisosVerdaderos = Object.keys(permisos).filter((key) => {
      return permisos[key];
    });
    const rutas = rootGetters["rutas/get"] as Rutas;
    return permisosVerdaderos
      .map((item) => {
        const rutaError: Ruta = {
          id: item,
          icon: "mdi-close-octagon",
          text: item,
          disabled: true,
          number: 999,
          tipos: [],
          show: false,
        };
        return rutas[item] ? rutas[item] : rutaError;
      })
      .filter((item) => {
        if (item.number == 999) {
          console.error(
            "permiso invalido => usuario: \n",
            usuario,
            "\n => ruta: \n",
            item
          );
          return false;
        }
        return true;
      })
      .sort((a, b) => {
        return a.number - b.number;
      });
  },
};

const mutations: MutationTree<UsuarioState> = {
  set(state, data: Usuario | null) {
    state.usuario = data;
  },
};

const actions: ActionTree<UsuarioState, State> = {
  set(ctx, data: Usuario | null) {
    if (!data) {
      return "";
    }
    ctx.commit("set", data);
    return data.id;
  },
};

const usuarios = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default usuarios;
