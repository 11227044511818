import { RouteConfig } from "vue-router";
import Agenda from "@/views/Agenda.vue";

const agenda: RouteConfig = {
  path: "/agenda",
  name: "agenda",
  component: Agenda,
};

export default agenda;
