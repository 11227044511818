




































































































































// decoradores
import { Vue, Component } from "vue-property-decorator";
// vuex
import { mapActions, mapGetters } from "vuex";
// tipos

// componentes
import TablaLocal from "@/components/locales/Tabla.vue";
import FormularioLocal from "@/components/locales/Formulario.vue";
import { DataFormularioLocal } from "@/typings/components/locales/formulario";
import DialogoExito from "@/components/DialogoExito.vue";
import DialogoEliminar from "@/components/DialogoEliminar.vue";
import InfoLocal from "@/components/locales/Info.vue";
//import BotonesCrud from "@/components/BotonesCrud.vue";
import MensajeEliminar from "@/components/MensajeEliminar.vue";
//import TituloVista from "@/components/TituloVista.vue";
import {
  Local,
  Locales,
  //  Regiones,
} from "@/typings/store/plugins/easyFirestore/locales";
import { CentroCosto } from "@/typings/store/plugins/easyFirestore/centrosCostos";
//import {CentrosCostos, CentroCosto} from "@/typings/store/plugins/easyFirestore/centrosCostos";
//import { DataFormularioRegion } from "@/typings/components/regiones/formulario";
import CargarLocalesExcel from "@/components/excel/CargarLocales.vue";

const routeGuard = (vm: Vue): boolean => {
  const permisos = vm.getUsuario?.permisos;
  if (permisos && permisos["locales"]) {
    return true;
  }

  const rutas = vm.getRutasUsuario;
  if (rutas.length > 0) {
    const to = rutas[0].to;
    if (to) {
      if (vm.$route.name !== to.name) {
        vm.$router.replace(to);
      }
      return false;
    }
  }
  if (vm.$route.name !== "inicio") {
    vm.$router.replace({ name: "inicio" });
  }
  return false;
};

@Component({
  computed: mapGetters({
    getUsuarios: "usuarios/get",
    getLocales: "locales/get",

    getArrayUsuarios: "usuarios/getArray",
    getUsuario: "usuario/get",
    getRutas: "rutas/get",
    getRutasUsuario: "usuario/getRutas",
    getArrayLocales: "locales/getArray",
    getArrayCentrosCostos: "centrosCostos/getArray",
  }),
  methods: mapActions({
    setLocal: "locales/set",
    deleteLocal: "locales/delete",
    insertBatchLocales: "locales/insertBatch",
    //setArchivo: "archivos/set",
    //deleteArchivo: "archivos/delete",
  }),
  beforeRouteEnter(to, from, next) {
    next(routeGuard);
  },
  components: {
    DialogoEliminar,
    DialogoExito,
    TablaLocal,
    FormularioLocal,
    InfoLocal,
    //BotonesCrud,
    MensajeEliminar,
    CargarLocalesExcel,
    //TituloVista,
  },
})
export default class VistaLocales extends Vue {
  created(): void {
    if (!routeGuard(this)) {
      return;
    }
  }

  cargando = false;
  dialogoExito = {
    model: false,
    texto: "",
    icono: "",
  };
  search = "";
  centroCosto: CentroCosto | null = null;
  local: Local | null = null;
  dialogoInfo = false;
  dialogoFormulario = false;
  dialogoEliminar = false;
  textoEliminar = "¿ESTÁS SEGURO QUE DESEAS ELIMINAR ESTE LOCAL?";
  dialogoCargarExcel = false;

  get locales(): Locales {
    return this.getLocales;
  }

  get codigoLocal(): number {
    let numero = 0;
    let arrayNumeros = [numero];
    this.getArrayLocales.forEach((local) => {
      if (local.codigo != numero) {
        arrayNumeros.push(local.codigo);
      }
    });
    let max = Math.max.apply(Math, arrayNumeros);
    return max;
  }


  get items(): Array<Local> {
    return this.getArrayLocales;
  }

  get items_CentrosCostos(): Array<string> {
    const itemCentroCosto: Array<string> = [];
    this.getArrayCentrosCostos.forEach((centroCosto) => {
      itemCentroCosto.push(centroCosto.DescCC);
    });
    return itemCentroCosto.sort();
  }
  clickItem(val: { id: string } | null): void {
    this.local = val ? this.locales[val.id] : null;
  }

  clickPlus(): void {
    this.local = null;
    this.dialogoFormulario = true;
  }

  async clickGuardar(data: DataFormularioLocal): Promise<void> {
    this.cargando = true;
    const doc = data.doc;
    await this.setLocal(doc);
    if (doc.id != this.local?.id) {
      this.dialogoExito = {
        model: true,
        texto: "¡Local creado con éxito!",
        icono: require("../assets/icons/double-check-duo.svg"),
      };
    }
    if (doc.id == this.local?.id) {
      this.dialogoExito = {
        model: true,
        texto: "¡Local editado con éxito!",
        icono: require("../assets/icons/editar.svg"),
      };
      this.local = doc;
    }
    this.cerrarDialogoFormulario();
    this.cargando = false;
  }

  cerrarDialogoExito(): void {
    this.dialogoExito = {
      model: false,
      texto: "",
      icono: "",
    };
  }

  clickEliminar(): void {
    const local = this.local;
    if (!local?.id) {
      console.error("error id: ", local);
      return;
    }
    this.deleteLocal(local.id);
    this.local = null;
    this.cerrarDialogoEliminar();
  }

  abrirDialogoInfo(local: Local): void {
    if (!local.id) {
      console.error("error id: ", local);
      return;
    }
    const doc = this.locales[local.id];
    if (doc) {
      this.local = doc;
      this.dialogoInfo = true;
    }
  }

  cerrarDialogoInfo(): void {
    this.dialogoInfo = false;
    this.local = null;
  }

  abrirDialogoFormulario(local: Local): void {
    if (!local.id) {
      console.error("error id: ", local);
      return;
    }
    const doc = this.locales[local.id];
    if (doc) {
      this.local = doc;
      this.dialogoFormulario = true;
    }
  }

  abrirDialogoEliminar(local: Local): void {
    if (!local.id) {
      console.error("error id: ", local);
      return;
    }
    const doc = this.locales[local.id];
    if (doc) {
      this.dialogoEliminar = true;
      this.local = doc;
    }
  }

  cerrarDialogoEliminar(): void {
    this.dialogoEliminar = false;
    this.local = null;
  }

  cerrarDialogoFormulario(): void {
    this.dialogoFormulario = false;
    this.local = null;
  }
}
