


























import { ChartData } from "@/typings/vista/monitoreo";
import { Component, Vue, Prop } from "vue-property-decorator";
import { GChart } from "vue-google-charts";
import { Curso } from "@/typings/store/plugins/easyFirestore/cursos";
import { ItemsTablaCursos } from "@/typings/components/cursos/tabla";
import { Pago } from "@/typings/store/plugins/easyFirestore/pagos";
//import { Cursos } from "@/typings/store/plugins/easyFirestore/cursos";
@Component({
  components: { GChart },
})
export default class ComponenteIndicadorComparativo extends Vue {
  @Prop() readonly cursos!: Array<Curso>;
  @Prop() readonly cursoItems!: ItemsTablaCursos;
  @Prop() readonly filtroPagoFecha!: Array<Pago>;

  // chartData = [
  //   ["Mes", "Ventas", { role: "style" }],
  //   ["Enero", 1000, "#1976D2"],
  //   ["Febrero", 1170, "#1976D2"],
  //   ["Marzo", 660, "#1976D2"],
  //   ["Abril", 1030, "#1976D2"],
  //   ["Mayo", 1000, "#1976D2"],
  //   ["Junio", 1170, "#1976D2"],
  //   ["Julio", 660, "#1976D2"],
  //   ["Agosto", 1030, "#1976D2"],
  //   ["Septiembre", 1000, "#1976D2"],
  //   ["Octubre", 1170, "#1976D2"],
  //   ["Noviembre", 660, "#1976D2"],
  //   ["Diciembre", 1030, "#1976D2"],
  // ];
  chartOptions = {
    legend: "none",

    chart: {
      title: "Company Performance",
      subtitle: "Ventas, Expenses, and Profit: 2014-2017",
    },
  };
  get chartData(): ChartData {
    let acumulaCursos: {
      [id: string]: { nombre: string; cantidad: number; color: string };
    } = {};
    let cantidad = 1;
    for (const pago of this.filtroPagoFecha) {
      if (pago.resultado?.status == "AUTHORIZED") {
        //suma += pago.resultado.amount;
        for (const curso of Object.values(pago.cursos ?? {})) {
          if (acumulaCursos[curso.nombre]) {
            acumulaCursos[curso.nombre].cantidad += cantidad;
          } else {
            acumulaCursos = Object.assign({}, acumulaCursos, {
              [curso.nombre]: {
                nombre: curso.nombre,
                cantidad: cantidad,
                color: "#3d3569",
              },
            });
          }
        }
      }
    }

    const data: ChartData = Object.values(acumulaCursos).map((curso) => {
      return [curso.nombre, curso.cantidad, curso.color];
    });
    //console.log(data);
    data.unshift(["Curso", "Cantidad", { role: "style" }]);
    //console.log(Object.values(acumulaCursos));
    return data;
  }
}
