














































// decoradores
import {Component, Vue, Prop, Model, Emit} from "vue-property-decorator";
// tipos
import {Ruta} from "@/typings/store/modules/rutas";
import SVGIcon from "@/components/SVGIcon.vue";
@Component({
  components: {SVGIcon}
})
export default class TabAdministracion extends Vue {
  @Model("input", {type: Boolean}) readonly value!: boolean;

  @Prop() readonly items!: Array<Ruta>;

  @Emit("input")
  onInput(value: boolean): boolean {
    return value;
  }

  get hideSlider(): boolean {
    return !this.items.some(ruta => ruta.id.toLowerCase() === this.actualRute.toLowerCase() && ruta.show);
  }

  get actualRute(): string {
    return this.$route.name ?? "";
  }
}
