




























































































































































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { Curso } from "@/typings/store/plugins/easyFirestore/cursos";
import "@/helpers/extensions";
import { Sesion } from "@/typings/store/plugins/easyFirestore/sesiones";
import { Usuario } from "@/typings/store/plugins/easyFirestore/usuarios";
import { Promocion } from "@/typings/store/plugins/easyFirestore/promociones";
import { Carrito } from "@/typings/store/plugins/easyFirestore/carritos";
import { Pago } from "@/typings/store/plugins/easyFirestore/pagos";
import SVGIcon from "@/components/SVGIcon.vue";
@Component({
  components: { SVGIcon },
})
export default class CardCurso extends Vue {
  @Prop() readonly curso!: Curso;
  @Prop() readonly sesiones!: Array<Sesion>;
  @Prop() readonly usuario?: Usuario;
  @Prop() readonly promociones?: Array<Promocion>;
  @Prop() readonly carrito?: Carrito;
  @Prop() readonly arrayPagos?: Pago[];
  @Prop() readonly arrayPagosUsuario?: Pago[];


  get imagenUrl(): string {
    const archivo = Object.values(this.curso.imagen ?? {})[0];
    return archivo?.url || "";
  }

  get color(): string {
    switch (this.curso.estado) {
      case "pendiente":
        return "rgba(116, 124, 141, 0.8)";
      case "revisado":
        return "rgba(229, 221, 13, 0.8)";
      case "aceptado":
        return "rgba(39, 155, 24, 0.8)";
      case "rechazado":
        return "rgba(234, 81, 67, 0.8)";
      case "impartiendo":
        return "rgba(55, 144, 209, 0.8)";
      case "terminado":
        return "rgba(0, 0, 0, 0.8)";
      default:
        return "";
    }
  }

  get descuento(): string {
    return (
      this.promociones?.filter((promocion) =>
        Object.values(promocion.cursos || {}).some(
          (cursso) => cursso.id === this.curso.id
        )
      )?.firstOrNull?.descuento ?? ""
    );
  }

  get sesionesCurso(): Array<Sesion> {
    return this.sesiones.filter((sesion) => sesion.curso.id === this.curso.id);
  }

  get totalDuracion(): number {
    return (
      this.sesionesCurso.reduce((sum, sesion) => sum + sesion.duracion, 0) / 60
    );
  }

  get promedioDuracion(): string {
    const result = this.totalDuracion / this.sesionesCurso.length;
    return isNaN(result)
      ? "0"
      : result % 1 === 0
      ? result.toString()
      : result.toFixed(1);
  }

  get enElCarrito(): boolean {
    return  Object.values(this.carrito?.cursos ?? {}).some((producto) => producto.curso.id === this.curso.id);
  }

  @Emit("ver-mas")
  onVerMas(): void {
    return;
  }

  @Emit("ver-info-completa")
  onVerInformacionCompleta(): void {
    return;
  }

  @Emit("lo-quiero")
  onLoQuiero(): void {
    return;
  }

  alumnosPorCurso(curso: Curso): number {
    return (
      this.arrayPagos?.filter(
        (pago) =>
          pago.resultado?.status === "AUTHORIZED" &&
          Object.values(pago.cursos).some((cursso) => cursso.id === curso.id)
      ).length ?? 0
    );
  }
}
