
















































































































































































// decoradores
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
// tipos
import { HeadersDataTable } from "@/typings/vuetify/vDataTable";
import { TipoCargo } from "@/typings/store/plugins/easyFirestore/tipoCargos";
import { ItemsTablaTipoCargos } from "@/typings/components/tipoCargos/tabla";
import "@/helpers/extensions";

@Component
export default class TablaUsuarios extends Vue {
  @Prop() readonly opciones!: boolean;
  @Prop() readonly search!: string;
  @Prop() readonly tipoCargos!: Array<TipoCargo>;

  options = {
    itemsPerPage: -1,
  };
  headerProps = {
    sortIcon: "mdi-chevron-down",
  };

  get headers(): HeadersDataTable {
    return [
      //{text: "", value: "estado", sortable: false},
      { text: "ID Tipo", value: "idTipo" },
      { text: "Tipo Gasto", value: "nombre" },
      { text: "Cuenta de Gasto", value: "cuentaControlGasto" },
      { text: "Cuenta Estado Resultado", value: "cuentaEstadoResultado" },
      { text: "", value: "opciones", sortable: false },
    ];
  }

  get items(): ItemsTablaTipoCargos {
    return this.tipoCargos.map((tipoCargo) => {
      const nombre = tipoCargo.nombre;
      const idTipo = tipoCargo.idTipo;
      const cuentaEstadoResultado = tipoCargo.cuentaEstadoResultado;
      const cuentaControlGasto = tipoCargo.cuentaControlGasto;

      return {
        nombre,
        idTipo,
        cuentaEstadoResultado,
        cuentaControlGasto,

        data: tipoCargo,
      };
    });
  }

  //   actualizarEstado(item: Usuario): void {
  //     let estado: EstadoUsuario;
  //     item.estado === 'libre' ? estado = 'bloqueado' : estado = 'libre';
  //     item.estado = estado;
  //     this.onCambiarEstado(item);
  //   }

  //   @Emit("cambiar-estado")
  //   onCambiarEstado(usuario: Usuario): Usuario {
  //     return usuario;
  //   }

  @Emit("abrir-info")
  onAbrirInfo(tipoCargo: TipoCargo): TipoCargo {
    return tipoCargo;
  }

  @Emit("abrir-editar")
  onAbrirEditar(tipoCargo: TipoCargo): TipoCargo {
    return tipoCargo;
  }

  @Emit("abrir-eliminar")
  onAbrirEliminar(tipoCargo: TipoCargo): TipoCargo {
    return tipoCargo;
  }
}
