















































import { Component, Vue, Prop } from "vue-property-decorator";
@Component
export default class ComponenteRecaudacionMensual extends Vue {
  @Prop() readonly recaudacionMensual!: number;
  @Prop() readonly diferenciaPorcentualRecaudacion!: number;
  @Prop() readonly tabs!: number;
}
