









































































































































































































































































































































































































































































































































































































































































































































































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { Ingreso } from "@/typings/store/plugins/easyFirestore/ingresos";
import { Banco } from "@/typings/store/plugins/easyFirestore/bancos";
import { CodCuenta } from "@/typings/store/plugins/easyFirestore/codCuentas";
import { Cliente } from "@/typings/store/plugins/easyFirestore/clientes";
import { CentroCosto } from "@/typings/store/plugins/easyFirestore/centrosCostos";
import { Local } from "@/typings/store/plugins/easyFirestore/locales";
import { required } from "vuelidate/lib/validators";
import { DataFormularioIngreso } from "@/typings/components/ingresos/formulario";
import SVGIcon from "@/components/SVGIcon.vue";
import { PlanCuentaIngreso } from "@/typings/store/plugins/easyFirestore/planCuentasIngresos";
import { ItemsTablaPlanCuentasIngresos } from "@/typings/components/planCuentasIngresos/tabla";
import { ItemsTablaIngresos } from "@/typings/components/ingresos/tabla";
import { HeadersDataTable } from "@/typings/vuetify/vDataTable";
import { GChart } from "vue-google-charts";

//import codCuentas from "@/router/codCuentas";

@Component({
  components: {
    SVGIcon,
    GChart,
  },
  validations: {
    // nombre: { required },
    // posicion: { required },
    montoIngreso: { required },
    montoAbono: { required },
    nDocumento: { required },
    //tipoIngreso: { required },
    periodoArrendamiento: { required },
    fecha: { required },
    formaPago: { required },

    //formaIngreso: { required },
  },
})
export default class FormularioIngreso extends Vue {
  @Prop() readonly arregloIngresos!: Ingreso[];
  @Prop() readonly ingreso!: Ingreso | null;
  @Prop() readonly cargando!: boolean;
  @Prop() readonly bancos!: Banco[];
  @Prop() readonly cuentas!: CodCuenta[];
  @Prop() readonly clientes!: Cliente[];
  @Prop() readonly centrosCostos!: CentroCosto[];
  @Prop() readonly locales!: Local[];
  @Prop() readonly planCuentasIngresos!: Array<PlanCuentaIngreso>;

  created(): void {
    // Object.keys(this.rutas).forEach((key) => {
    //   this.$set(this.permisos, key, false);
    // });
    // console.log(this.permisos);
    const ingreso = this.ingreso;
    if (!ingreso) {
      return;
    }
    this.id = ingreso.id ?? "";
    this.nombre = ingreso.nombre;
    this.posicion = ingreso.posicion ?? "";

    if (ingreso.PERIODO_ARRENDAMIENTO === "" || ingreso.PERIODO_ARRENDAMIENTO ==="Invalid Date") {
      this.periodoArrendamiento = "2023-01-01";
    } else {
      this.periodoArrendamiento =
        ingreso.PERIODO_ARRENDAMIENTO.toDate().format("YYYY-MM-DD") ?? "";
    }

    if (ingreso.FECHA === "" || ingreso.FECHA ==="Invalid Date") {
      this.fecha = "2023-01-01";
    } else {
      this.fecha =
        ingreso.FECHA.toDate().format("YYYY-MM-DD") ?? "";
    }


//    this.fecha = ingreso.FECHA.toDate().format("YYYY-MM-DD") ?? "";
    this.formaPago = ingreso.FORMA_PAGO ?? "";
    this.tipoIngreso = ingreso.TIPO_IGRESO ?? "";
    this.codCuenta = ingreso.COD_CUENTA ?? "";
    this.nDocumento = ingreso.N_DOCUMENTO ?? "";
    this.montoIngreso = ingreso.MONTO_INGRESO ?? "";
    this.montoAbono = ingreso.MONTO_ABONO ?? "";
    this.banco = ingreso.BANCO ?? "";
    this.observacion = ingreso.GLOSA ?? "";
    this.rutCliente = ingreso.RUT_CLIENTE ?? "";
    this.nombreCliente = ingreso.NOMBRE_CLIENTE ?? "";
    this.nContrato = ingreso.N_CONTRATO ?? "";
    this.centroCosto = ingreso.CENTRO_DE_COSTO ?? "";
    this.local = ingreso.LOCAL ?? "";
    this.formaIngreso = ingreso.FORMA_INGRESO ?? "";
    this.nDocumentoBancario = ingreso.N_DOCUMENTO_BANCARIO ?? "";
    this.localContrato = ingreso.LOCAL_CONTRATO ?? "";


    if (ingreso.PERIODO_LIBRO_SII === "" || ingreso.PERIODO_LIBRO_SII ==="Invalid Date") {
      this.periodoLibroSii = "2023-01-01";
    } else {
      this.periodoLibroSii =
        ingreso.PERIODO_LIBRO_SII.toDate().format("YYYY-MM-DD") ?? "";
    }

    //this.periodoLibroSii = ingreso.PERIODO_LIBRO_SII.toDate().format("YYYY-MM-DD") ?? "";

    this.tipoDocumentoReferencia = ingreso.TIPO_DOCUMENTO_REFERENCIA ?? "";
    this.tipoDocumento = ingreso.TIPO_DOCUMENTO ?? "";
    //this.nDocumento = ingreso.N_DOCUMENTO ?? "";
    this.nDocumentoReferencia = ingreso.N_DOCUMENTO_REFERENCIA ?? "";

    console.log("FECHA INGRESO", this.fecha);
    console.log("PERIODO ARRENDAMIENTO", this.periodoArrendamiento);
    console.log("PERIODO SII", this.periodoLibroSii);
  }

  options = {
    locale: "es-CL",
    prefix: "$",
    suffix: "",
    length: 18,
    precision: 0,
  };

  headerProps = {
    sortIcon: "mdi-chevron-down",
  };

  id: string | null = null;

  get fechaPeriodoF(): string {
    let array_fecha = this.periodoArrendamiento.split("-");
    let dia = "01";
    let mes = array_fecha[1];
    let ano = array_fecha[0];
    console.log(array_fecha);
    this.periodoArrendamiento = dia + "-" + mes + "-" + ano;
    return dia + "-" + mes + "-" + ano;
  }

  nombre = "";
  posicion = "";
  periodoArrendamiento = new Date(
    Date.now() - new Date().getTimezoneOffset() * 60000
  )
    .toISOString()
    .substr(0, 7);
  fecha = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
    .toISOString()
    .substr(0, 10);
  formaPago = "";
  tipoIngreso = "";
  codCuenta = "";
  nDocumento = "";
  montoIngreso = "";
  montoAbono = "";
  banco = "";
  observacion = "";
  rutCliente = "";
  nombreCliente = "";
  nContrato = "";
  centroCosto = "";
  local = "";
  formaIngreso = "";
  nDocumentoBancario = "";
  localContrato = "";

  itemsFormaPago = ["COMPLETO", "PARCIAL"];

  itemsTipoIngreso = [
    "ARRIENDO FIJO",
    "ARRIENDO FIJO BL",
    "ARRIENDO FIJO POR ANTENA",
    "ARRIENDO VARIABLE",
    "CONSUMOS DE AGUAS",
    "CONSUMOS DE LUZ",
    "CONSUMOS VARIOS",
    "GASTOS COMUNES",
    "DEVOLUCION DE CONTRIBUCIONES",
    "DIVIDENDOS ACCIONES BANCHILE",
    "DIVIDENDOS ACCIONARIO BTG",
    "DIVIDENDOS AMERIS",
    "DIVIDENDO ASSET SANTANDER",
    "DIVIDENDO BCI ASSET",
    "DIVIDENDOS BESALCO",
    "DIVIDENDOS CAP",
    "DIVIDENDOS RIACHUELO",
    "FONDO DE PROMOCION FIJO",
    "FONDO DE PROMOCION VARIABLE",
    "INTERESES DAP",
    "INTERESES DAP US$ BCI",
    "INTERESES DE PAGARE",
    "INTERESES FFMM",
    "INTERESES INV. BCI PREFERENTE",
    "INGRESO POR VENTA DE AF",
    "INGRESO VENTA DE ACCIONES",
    "INTERESES INVERSIONES BCI",
    "INGRESO IMPUESTO SUSTITUTIVO",
    "INGRESOS VENTA ACTIVOS LEASING",
    "MAYOR VALOR CFINRENTAS SANTANDER",
    "MAYOR VALOR FFMM  BCI",
    "MULTAS",
    "OTROS INGRESOS FUERA DE EXPLOTACION",
    "RETIRO BASURA",
    "UTILIDADES EN INVERSIONES EERR",

    "VARIOS",
  ];

  itemsLocales = [
    "LCXX",
    "LC01",
    "LC02",
    "LC03",
    "LC04",
    "LC05",
    "LC06",
    "LC07",
    "LC08",
    "LC09",
    "LC10",
    "LC11",
    "LC12",
    "LC13",
    "LC14",
    "LC15",
    "LC16",
    "OFXX",
    "OF01",
    "OF02",
    "OF03",
    "OF04",
    "OF05",
    "SMXX",
    "SM01",
    "SM02",
    "ANCLA",
    "ISLA NRO. 001",
    "ISLA NRO. 002",
    "ISLA NRO. 003",
    "ISLA NRO. 004",
    "ISLA NRO. 005",
    "ISLA NRO. 006",
    "ISLA NRO. 007",
    "ISLA NRO. 008",
    "ISLA NRO. 009",
    "ISLA NRO. 010",
    "ISLA NRO. 011",
    "ISLA NRO. 012",
    "ISLA NRO. 013",
    "ISLA NRO. 014",
    "ISLA NRO. 015",
    "ISLA NRO. 016",
    "ISLA NRO. 017",
    "ISLA NRO. 018",
    "ISLA NRO. 019",
    "ISLA NRO. 020",
    "ISLA NRO. 021",
    "ISLA NRO. 022",
    "ISLA NRO. 023",
    "ISLA NRO. XXX",
    "MMXX",
    "MM01",
    "MM02",
    "MM03",
    "MM04",
    "N/A",
  ];

  itemIngresos = ["CHEQUE", "TRANSFERENCIA", "EFECTIVO"];
  itemsTipoDoc = ["33", "34", "61"];
  itemsTipoDocRef = ["33", "34"];

  periodoLibroSii = "";
  tipoDocumentoReferencia = "";
  tipoDocumento = "";
  nDocumentoReferencia = "";

  menu = false;
  menu3 = false;
  menu4 = false;

  get headersMatrizIngreso(): HeadersDataTable {
    return [
      //{text: "", value: "estado", sortable: false},

      // { text: "TIPO DOCUMENTO", value: "TIPO_DOCUMENTO" },
      // { text: "Periodo Arrendamiento", value: "periodoArrendamiento" },
      { text: "FECHA", value: "fecha" },
      { text: "FORMA PAGO", value: "formaPago" },
      //  { text: "N° Documento", value: "nDocumento" },
      // { text: "Monto Factura", value: "montoIngreso" },
      { text: "MONTO ABONO", value: "montoAbono" },

      { text: "BANCO", value: "banco" },
      { text: "OBSERVACIÓN", value: "observacion" },
      // { text: "Rut Cliente", value: "rutCliente" },
      // { text: "Nombre Cliente", value: "nombreCliente" },
      //  { text: "N Contrato", value: "nContrato" },
      //  { text: "Centro de Costo", value: "centroCosto" },
      //  { text: "Local", value: "local" },

      //{ text: "", value: "opciones", sortable: false },
      // {text: "", value: "opciones", sortable: false}
    ];
  }

  get headers(): HeadersDataTable {
    return [
      //{text: "", value: "estado", sortable: false},

      // { text: "TIPO DOCUMENTO", value: "TIPO_DOCUMENTO" },
      { text: "NUMERO DOCUMENTO", value: "NUMERO_DOCUMENTO" },
      { text: "NUMERO CUENTA", value: "NUMERO_CUENTA" },
      { text: "CUENTA SOFTLAND", value: "CUENTA_SOFTLAND" },
      { text: "PRODUCTO", value: "PRODUCTO" },
      { text: "VALOR NETO", value: "VALOR_NETO" },

      // {text: "", value: "opciones", sortable: false}
    ];
  }

  get titulo(): string {
    return this.ingreso ? "editar ingreso" : "nuevo ingreso";
  }

  get itemBancos(): Array<string> {
    const itemBanco: Array<string> = [];
    this.bancos.forEach((banco) => {
      itemBanco.push(banco.nombre);
    });
    return itemBanco.sort();
  }

  get itemsCodigoCuentas(): string | undefined {
    let itemsCodigoCuentas = "";
    this.cuentas.forEach((cuenta) => {
      if (cuenta.PCDESC.toString().trim() === this.tipoIngreso.trim()) {
        itemsCodigoCuentas = cuenta.PCCODI;
      }
    });
    return itemsCodigoCuentas;
  }

  get itemsRutClientes(): Array<string> {
    const itemCliente: Array<string> = [];
    this.clientes.forEach((cliente) => {
      itemCliente.push(cliente.rut);
    });
    return itemCliente.sort();
  }

  get itemNombreClientes(): string | undefined {
    let itemNombreCliente = "";
    this.clientes.forEach((cliente) => {
      if (cliente.rut.toString().trim() === this.rutCliente.trim()) {
        // if (mapeoGasto.centroCosto === this.centroCosto) {

        itemNombreCliente = cliente.nombre;
        // }
      }
    });
    return itemNombreCliente;
  }

  get itemsHistoricosMatrizIngresos(): ItemsTablaIngresos {
    return this.arregloIngresos
      .filter((itemMatrizIngreso) => {
        const coincideFactura =
          itemMatrizIngreso.N_DOCUMENTO === this.nDocumento;
        const coincideTipoDocumento =
          itemMatrizIngreso.TIPO_DOCUMENTO === this.tipoDocumento;
        return coincideFactura && coincideTipoDocumento;
      })
      .map((ingreso) => {
        const periodoArrendamiento = ingreso.PERIODO_ARRENDAMIENTO;
        const fecha = ingreso.FECHA;
        const tipoIngreso = ingreso.TIPO_IGRESO;
        const nDocumento = ingreso.N_DOCUMENTO;
        const montoIngreso = (
          parseInt(ingreso.MONTO_INGRESO, 10).toCurrency({ symbol: "$" }) ?? ""
        ).toString();
        const montoAbono = (
          parseInt(ingreso.MONTO_ABONO, 10).toCurrency({ symbol: "$" }) ?? ""
        ).toString();
        const banco = ingreso.BANCO;
        const observacion = ingreso.GLOSA;
        const rutCliente = ingreso.RUT_CLIENTE;
        const nombreCliente = ingreso.NOMBRE_CLIENTE;
        const nContrato = ingreso.N_CONTRATO;
        const centroCosto = ingreso.CENTRO_DE_COSTO;
        const local = ingreso.LOCAL;

        return {
          periodoArrendamiento,
          fecha,
          tipoIngreso,
          nDocumento,
          montoIngreso,
          montoAbono,
          banco,
          observacion,
          rutCliente,
          nombreCliente,
          nContrato,
          centroCosto,
          local,

          data: ingreso,
        };
      });
  }

  get itemsCentrosCostos(): Array<string> {
    const itemsCentroCosto: Array<string> = [];
    this.centrosCostos.forEach((centroCosto) => {
      itemsCentroCosto.push(centroCosto.Desc_formato);
    });
    return itemsCentroCosto.sort();
  }

  get montoIngresoError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.montoIngreso.$dirty) return errors;
    if (!this.$v.montoIngreso.required) errors.push("");
    return errors;
  }

  get montoAbonoError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.montoAbono.$dirty) return errors;
    if (!this.$v.montoAbono.required) errors.push("");
    return errors;
  }

  get nDocumentoError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.nDocumento.$dirty) return errors;
    if (!this.$v.nDocumento.required) errors.push("");
    return errors;
  }

  // get tipoIngresoError(): Array<string> {
  //   const errors: Array<string> = [];
  //   if (!this.$v.tipoIngreso.$dirty) return errors;
  //   if (!this.$v.tipoIngreso.required) errors.push("");
  //   return errors;
  // }

  get periodoArrendamientoError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.periodoArrendamiento.$dirty) return errors;
    if (!this.$v.periodoArrendamiento.required) errors.push("");
    return errors;
  }

  get fechaError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.fecha.$dirty) return errors;
    if (!this.$v.fecha.required) errors.push("");
    return errors;
  }

  // get formaPagoError(): Array<string> {
  //   const errors: Array<string> = [];
  //   if (!this.$v.formaPago.$dirty) return errors;
  //   if (!this.$v.formaPago.required) errors.push("");
  //   return errors;
  // }

  get itemsTablaPlanCuentas(): ItemsTablaPlanCuentasIngresos {
    return this.planCuentasIngresos
      .filter((planCuentaIngreso) => {
        const coincideFactura =
          planCuentaIngreso.NUMERO_DOCUMENTO === this.nDocumento;
        const coincideTipoDocumento =
          planCuentaIngreso.TIPO_DOCUMENTO === this.tipoDocumento;
        return coincideFactura && coincideTipoDocumento;
      })

      .map((planCuentaIngreso) => {
        const nombre = planCuentaIngreso.nombre;
        const posicion = planCuentaIngreso.posicion;
        const TIPO_DOCUMENTO = planCuentaIngreso.TIPO_DOCUMENTO;
        const NUMERO_DOCUMENTO = planCuentaIngreso.NUMERO_DOCUMENTO;
        const CUENTA_SOFTLAND = planCuentaIngreso.CUENTA_SOFTLAND;
        const NUMERO_CUENTA = planCuentaIngreso.NUMERO_CUENTA;
        const PRODUCTO = planCuentaIngreso.PRODUCTO;
        const VALOR_NETO = parseInt(planCuentaIngreso.VALOR_NETO, 10)
          .toCurrency({ symbol: "$" })
          .toString();

        return {
          nombre,
          posicion,
          TIPO_DOCUMENTO,
          NUMERO_DOCUMENTO,
          CUENTA_SOFTLAND,
          NUMERO_CUENTA,
          PRODUCTO,
          VALOR_NETO,

          data: planCuentaIngreso,
        };
      });
  }

  get cantidadItemsPagosMatriz(): number {
    let cantidadElementos = 0;
    cantidadElementos = this.itemsHistoricosMatrizIngresos.length;
    return cantidadElementos;
  }

  get sumaPagadoItemsMatrizIngreso(): number {
    let arrayPagados: Array<number> = [];
    let total = 0;
    this.itemsHistoricosMatrizIngresos.forEach((pagado) => {
      if (pagado.data.MONTO_ABONO === undefined) {
        arrayPagados.push(0);
      } else {
        arrayPagados.push(parseInt(pagado.data.MONTO_ABONO));
      }
    });
    total = arrayPagados.reduce((a, b) => a + b, 0);
    return total;
  }

  get itemMontoFactura(): string | undefined {
    let montoFactura = "";
    this.itemsHistoricosMatrizIngresos.forEach((factura) => {
      if (
        factura.data.N_DOCUMENTO === this.nDocumento &&
        factura.data.TIPO_DOCUMENTO === this.tipoDocumento
      ) {
        this.montoIngreso = factura.data.MONTO_INGRESO;
        montoFactura = this.montoIngreso;
      } else {
        this.montoIngreso = "";
      }
    });
    return montoFactura;
  }

  get diferenciaPagadoItemsPlanCuentas(): number {
    let resultado = 0;
    resultado = parseInt(this.montoIngreso) - this.sumaPagadoItemsMatrizIngreso;

    return resultado;
  }

  get veificaFecha(): string {
    let fecha = "";
    return fecha;
  }

  get fechaFormato(): string {
    let array_fecha = this.fecha.split("-");
    let dia = array_fecha[2];
    let mes = array_fecha[1];
    let ano = array_fecha[0];
    console.log(array_fecha);
    this.fecha = dia + "-" + mes + "-" + ano;
    return dia + "-" + mes + "-" + ano;
  }

  get periodoFormato(): string {
    let array_fecha = this.periodoArrendamiento.split("-");
    let dia = array_fecha[2];
    let mes = array_fecha[1];
    let ano = array_fecha[0];
    console.log(array_fecha);
    this.periodoArrendamiento = dia + "-" + mes + "-" + ano;
    return "01" + "-" + mes + "-" + ano;
  }

  get periodoSiiFormato(): string {
    let array_fecha = this.periodoLibroSii.split("-");
    let dia = array_fecha[2];
    let mes = array_fecha[1];
    let ano = array_fecha[0];
    console.log(array_fecha);
    this.periodoLibroSii = dia + "-" + mes + "-" + ano;
    return "01" + "-" + mes + "-" + ano;
  }

  get porcentajePagos(): Array<Array<string | number>> {
    let arrayPagos = [
      ["Porcentaje Pago", "Monto"],
      ["P. Pagado", this.sumaPagadoItemsMatrizIngreso],
      ["P. Pendiente", this.diferenciaPagadoItemsPlanCuentas],
    ];
    return arrayPagos;
  }

  pieOptions = {
    width: "",
    height: "",
    pieHole: "",
    backgroundColor: "#FFFFFF",
    pieSliceTextStyle: {
      color: "none",
    },
    colors: [
      "#0d0a39",
      //2 "#251f50", "#3d3569", "#564c82", "#6f649c",
      "#897cb7",
    ],
  };

  @Emit("close")
  onClose(): null {
    return null;
  }

  @Emit("cerrar-dialogo")
  onCerrarDialogo(): void {
    return;
  }

  @Emit("click-guardar")
  onClickGuardar(data: DataFormularioIngreso): DataFormularioIngreso {
    return data;
  }

  //   @Watch("permisosKey", { immediate: true })
  //   onTipoChange(): void {
  //     console.log("a");
  //     for (const key of Object.keys(this.permisos)) {
  //       if (this.permisos[key]) {
  //         this.permisos[key] = false;
  //       }
  //     }
  //     this.permisosKey.forEach((key) => {
  //       this.permisos[key] = true;
  //     });
  //   }

  //   nombrePermiso(key: string): string {
  //     return this.rutas[key].text;
  //   }

  guardar(): void {
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }
    const doc: Ingreso = {
      nombre: this.nombre,
      posicion: this.posicion,
      PERIODO_ARRENDAMIENTO: this.periodoFormato,
      FECHA: this.fechaFormato,
      FORMA_PAGO: this.formaPago,
      TIPO_IGRESO: this.tipoIngreso,
      COD_CUENTA: this.itemsCodigoCuentas || "",
      N_DOCUMENTO: this.nDocumento, // N° de documento o factura
      MONTO_INGRESO: this.montoIngreso,
      MONTO_ABONO: this.montoAbono,
      GLOSA: this.observacion.toUpperCase(),
      RUT_CLIENTE: this.rutCliente,
      NOMBRE_CLIENTE: this.itemNombreClientes || "",
      N_CONTRATO: this.nContrato,
      CENTRO_DE_COSTO: this.centroCosto,
      LOCAL: this.local,
      BANCO: this.banco,
      FORMA_INGRESO: this.formaIngreso,
      N_DOCUMENTO_BANCARIO: this.nDocumentoBancario,
      LOCAL_CONTRATO: this.localContrato,

      PERIODO_LIBRO_SII: this.periodoSiiFormato,
      TIPO_DOCUMENTO_REFERENCIA: this.tipoDocumentoReferencia,
      TIPO_DOCUMENTO: this.tipoDocumento,
      N_DOCUMENTO_REFERENCIA: this.nDocumentoReferencia,
    };
    if (this.id) {
      doc.id = this.id;
    }
    const data: DataFormularioIngreso = {
      doc: doc,
    };
    this.onClickGuardar(data);
  }
}
