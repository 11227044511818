

























































































// decoradores
import { Component, Emit, Prop, Vue} from "vue-property-decorator";
// tipos
import { HeadersDataTable } from "@/typings/vuetify/vDataTable";
import {CategoriaCurso} from "@/typings/store/plugins/easyFirestore/categoriasCurso";
import {ItemsTablaCategoriasCurso} from "@/typings/components/categoriasCurso/tabla";
@Component
export default class TablaCategoriasCurso extends Vue {
  @Prop() readonly opciones!: boolean;
  @Prop() readonly search!: string;
  @Prop() readonly categoriasCurso!: Array<CategoriaCurso>;

  options = {
    itemsPerPage: -1,
  };


  get headers(): HeadersDataTable {
    return [
      { text: "Nombre", value: "nombre" },
      { text: "Opciones", value: "opciones" },
    ];
  }

  get items(): ItemsTablaCategoriasCurso {
    return this.categoriasCurso.map((categoriaCurso) => {
      const nombre = categoriaCurso.nombre;
      return {
        nombre,
        data: categoriaCurso,
      };
    });
  }

  @Emit("abrir-info")
  onAbrirInfo(categoriaCurso: CategoriaCurso): CategoriaCurso {
    return categoriaCurso;
  }

  @Emit("abrir-editar")
  onAbrirEditar(categoriaCurso: CategoriaCurso): CategoriaCurso {
    return categoriaCurso;
  }

  @Emit("abrir-eliminar")
  onAbrirEliminar(categoriaCurso: CategoriaCurso): CategoriaCurso {
    return categoriaCurso;
  }
}
