import { RouteConfig } from "vue-router";
import Equipos from "@/views/Equipos.vue";

const equipos: RouteConfig = {
    path: "/equipos",
    name: "equipos",
    component: Equipos,
};

export default equipos;
