import { RouteConfig } from "vue-router";
import Carrito from "@/views/Carrito.vue";

const carrito: RouteConfig = {
    path: "/carrito",
    name: "carrito",
    component: Carrito,
};

export default carrito;
