import { Modulos } from "@/typings/store/plugins/easyFirestore/iniciar/modulos";

// si permisos = [] todos pueden acceder; value de parametro obtiene su valor del objeto Usuario (id = usuario.id)
const modulos: Modulos = [
  // { nombre: "usuarios", permisos: [], parametros: [], where: [] },
  //{ nombre: "categoriasCurso", permisos: [], parametros: [], where: [] },
  // { nombre: "ciudades", permisos: [], parametros: [], where: [] },
  // { nombre: "cursos", permisos: [], parametros: [], where: [] },
  //{ nombre: "inscripciones", permisos: [], parametros: [], where: [] },
  //{ nombre: "pagos", permisos: [], parametros: [], where: [] },
  // { nombre: "promociones", permisos: [], parametros: [], where: [] },
  // { nombre: "regiones", permisos: [], parametros: [], where: [] },
  // { nombre: "sesiones", permisos: [], parametros: [], where: [] },
  //{ nombre: "sesionesProgramadas", permisos: [], parametros: [], where: [] },
  //{ nombre: "tags", permisos: [], parametros: [], where: [] },
  //{ nombre: "notificaciones", permisos: [], parametros: [], where: [] },
  //{ nombre: "carritos", permisos: [], parametros: [], where: [] },
  //{ nombre: "videosVistos", permisos: [], parametros: [], where: [] },
  //{ nombre: "encuestas", permisos: [], parametros: [], where: [] },
  // {
  //   nombre: "usuarios",
  //   permisos: [],
  //   parametros: [],
  //   where: [["empresa.id", "==", "empresa.id"]],
  // },
];

export default modulos;
