


































































































































// decoradores
import { Vue, Component } from "vue-property-decorator";
// vuex
import { mapActions, mapGetters } from "vuex";
// tipos

// componentes
import TablaTipoEgreso from "@/components/tipoEgresos/Tabla.vue";
import FormularioTipoEgreso from "@/components/tipoEgresos/Formulario.vue";
import { DataFormularioTipoEgreso } from "@/typings/components/tipoEgresos/formulario";
import DialogoExito from "@/components/DialogoExito.vue";
import DialogoEliminar from "@/components/DialogoEliminar.vue";
import InfoTipoEgreso from "@/components/tipoEgresos/Info.vue";
//import BotonesCrud from "@/components/BotonesCrud.vue";
import MensajeEliminar from "@/components/MensajeEliminar.vue";
//import TituloVista from "@/components/TituloVista.vue";
import {
  TipoEgreso,
  TipoEgresos,
  //  Regiones,
} from "@/typings/store/plugins/easyFirestore/tipoEgresos";
//import { DataFormularioRegion } from "@/typings/components/regiones/formulario";
import CargarTipoEgresosExcel from "@/components/excel/CargarTipoEgresos.vue";

const routeGuard = (vm: Vue): boolean => {
  const permisos = vm.getUsuario?.permisos;
  if (permisos && permisos["tipoEgresos"]) {
    return true;
  }

  const rutas = vm.getRutasUsuario;
  if (rutas.length > 0) {
    const to = rutas[0].to;
    if (to) {
      if (vm.$route.name !== to.name) {
        vm.$router.replace(to);
      }
      return false;
    }
  }
  if (vm.$route.name !== "inicio") {
    vm.$router.replace({ name: "inicio" });
  }
  return false;
};

@Component({
  computed: mapGetters({
    getUsuarios: "usuarios/get",
    getTipoEgresos: "tipoEgresos/get",
    getArrayUsuarios: "usuarios/getArray",
    getUsuario: "usuario/get",
    getRutas: "rutas/get",

    getRutasUsuario: "usuario/getRutas",
    getArrayTipoEgresos: "tipoEgresos/getArray",
  }),
  methods: mapActions({
    setTipoEgreso: "tipoEgresos/set",
    deleteTipoEgreso: "tipoEgresos/delete",
    insertBatchTipoEgresos: "tipoEgresos/insertBatch",
    //setArchivo: "archivos/set",
    //deleteArchivo: "archivos/delete",
  }),
  beforeRouteEnter(to, from, next) {
    next(routeGuard);
  },
  components: {
    DialogoEliminar,
    DialogoExito,
    TablaTipoEgreso,
    FormularioTipoEgreso,
    InfoTipoEgreso,
    //BotonesCrud,
    MensajeEliminar,
    //TituloVista,
    CargarTipoEgresosExcel,
  },
})
export default class VistaBancos extends Vue {
  created(): void {
    if (!routeGuard(this)) {
      return;
    }
  }

  cargando = false;
  dialogoExito = {
    model: false,
    texto: "",
    icono: "",
  };
  search = "";
  tipoEgreso: TipoEgreso | null = null;
  dialogoInfo = false;
  dialogoFormulario = false;
  dialogoEliminar = false;
  textoEliminar = "¿ESTÁS SEGURO QUE DESEAS ELIMINAR ESTE TIPO DE EGRESO?";
  dialogoCargarExcel = false;

  get tipoEgresos(): TipoEgresos {
    return this.getTipoEgresos;
  }

  get items(): Array<TipoEgreso> {
    return this.getArrayTipoEgresos;
  }

  clickItem(val: { id: string } | null): void {
    this.tipoEgreso = val ? this.tipoEgresos[val.id] : null;
  }

  clickPlus(): void {
    this.tipoEgreso = null;
    this.dialogoFormulario = true;
  }

  async clickGuardar(data: DataFormularioTipoEgreso): Promise<void> {
    this.cargando = true;
    const doc = data.doc;
    await this.setTipoEgreso(doc);
    if (doc.id != this.tipoEgreso?.id) {
      this.dialogoExito = {
        model: true,
        texto: "¡Tipo Egreso creado con éxito!",
        icono: require("../assets/icons/double-check-duo.svg"),
      };
    }
    if (doc.id == this.tipoEgreso?.id) {
      this.dialogoExito = {
        model: true,
        texto: "¡Tipo Egreso editado con éxito!",
        icono: require("../assets/icons/editar.svg"),
      };
      this.tipoEgreso = doc;
    }
    this.cerrarDialogoFormulario();
    this.cargando = false;
  }

  cerrarDialogoExito(): void {
    this.dialogoExito = {
      model: false,
      texto: "",
      icono: "",
    };
  }

  clickEliminar(): void {
    const tipoEgreso = this.tipoEgreso;
    if (!tipoEgreso?.id) {
      console.error("error id: ", tipoEgreso);
      return;
    }
    this.deleteTipoEgreso(tipoEgreso.id);
    this.tipoEgreso = null;
    this.cerrarDialogoEliminar();
  }

  abrirDialogoInfo(tipoEgreso: TipoEgreso): void {
    if (!tipoEgreso.id) {
      console.error("error id: ", tipoEgreso);
      return;
    }
    const doc = this.tipoEgresos[tipoEgreso.id];
    if (doc) {
      this.tipoEgreso = doc;
      this.dialogoInfo = true;
    }
  }

  cerrarDialogoInfo(): void {
    this.dialogoInfo = false;
    this.tipoEgreso = null;
  }

  abrirDialogoFormulario(tipoEgreso: TipoEgreso): void {
    if (!tipoEgreso.id) {
      console.error("error id: ", tipoEgreso);
      return;
    }
    const doc = this.tipoEgresos[tipoEgreso.id];
    if (doc) {
      this.tipoEgreso = doc;
      this.dialogoFormulario = true;
    }
  }

  abrirDialogoEliminar(tipoEgreso: TipoEgreso): void {
    if (!tipoEgreso.id) {
      console.error("error id: ", tipoEgreso);
      return;
    }
    const doc = this.tipoEgresos[tipoEgreso.id];
    if (doc) {
      this.dialogoEliminar = true;
      this.tipoEgreso = doc;
    }
  }

  cerrarDialogoEliminar(): void {
    this.dialogoEliminar = false;
    this.tipoEgreso = null;
  }

  cerrarDialogoFormulario(): void {
    this.dialogoFormulario = false;
    this.tipoEgreso = null;
  }
}
