var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":"","justify":"center"}},[_c('v-col',{attrs:{"cols":"10"}},[(_vm.tipoUsuario == 'admin')?_c('v-row',{staticClass:"mt-3 flex-nowrap",attrs:{"dense":"","align":"start"}},[_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-text-field',{staticClass:"input-buscar rounded-lg",attrs:{"dense":"","hide-details":"","outlined":"","label":"Buscar pago","clearable":""},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('img',{attrs:{"width":"20px","height":"20px","src":require("../assets/icons/buscar.svg"),"alt":"Buscar"}})]},proxy:true}],null,false,2190402220),model:{value:(_vm.buscar),callback:function ($$v) {_vm.buscar=$$v},expression:"buscar"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"select-custom",attrs:{"clearable":"","readonly":"","dense":"","outlined":"","hide-details":"","label":"Fecha"},on:{"click:clear":function($event){$event.preventDefault();_vm.fechasMostrar = ''}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('img',{attrs:{"width":"20px","height":"20px","src":require("../assets/icons/fecha.svg"),"alt":"Fecha"}})]},proxy:true},{key:"append",fn:function(){return [_c('v-row',{staticClass:"fill-height",attrs:{"dense":"","align":"center"}},[_c('v-col',[_c('img',{attrs:{"width":"8px","height":"8px","src":require("../assets/icons/flecha-abajo.svg"),"alt":"Flecha"}})])],1)]},proxy:true}],null,true),model:{value:(_vm.fechasMostrar),callback:function ($$v) {_vm.fechasMostrar=$$v},expression:"fechasMostrar"}},'v-text-field',attrs,false),on))]}}],null,false,1464809235),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"range":"","no-title":"","color":"primary","allowed-dates":_vm.fechasPermitidas},model:{value:(_vm.fechas),callback:function ($$v) {_vm.fechas=$$v},expression:"fechas"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-select',{staticClass:"select-custom text-capitalize",attrs:{"label":"Mét. Pago","hide-details":"auto","dense":"","return-object":"","item-text":"nombre","item-value":"id","items":_vm.metodosdePago,"outlined":"","clearable":"","menu-props":{ offsetY: true }},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('img',{attrs:{"width":"20px","height":"20px","src":require("../assets/icons/met-pagos.svg"),"alt":"Met Pago"}})]},proxy:true},{key:"append",fn:function(){return [_c('v-row',{staticClass:"fill-height",attrs:{"dense":"","align":"center"}},[_c('v-col',[_c('img',{attrs:{"width":"8px","height":"8px","src":require("../assets/icons/flecha-abajo.svg"),"alt":"Flecha"}})])],1)]},proxy:true},{key:"pago",fn:function(ref){
var pago = ref.pago;
return [_c('span',{staticClass:"text-capitalize poppins primary--text font-weight-light"},[_vm._v(_vm._s(pago.nombre))])]}}],null,false,1040799716),model:{value:(_vm.metodoPago),callback:function ($$v) {_vm.metodoPago=$$v},expression:"metodoPago"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-select',{staticClass:"select-custom text-capitalize",attrs:{"hide-details":"","dense":"","outlined":"","items":_vm.relatores,"label":"Relator","item-text":"nombre","item-value":"id","return-object":"","clearable":"","menu-props":{ offsetY: true }},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('img',{attrs:{"width":"20px","height":"20px","src":require("../assets/icons/relator.svg"),"alt":"Relator"}})]},proxy:true},{key:"append",fn:function(){return [_c('v-row',{staticClass:"fill-height",attrs:{"dense":"","align":"center"}},[_c('v-col',[_c('img',{attrs:{"width":"8px","height":"8px","src":require("../assets/icons/flecha-abajo.svg"),"alt":"Flecha"}})])],1)]},proxy:true},{key:"pago",fn:function(ref){
var pago = ref.pago;
return [_c('span',{staticClass:"text-capitalize poppins primary--text font-weight-light"},[_vm._v(_vm._s(pago.nombre))])]}}],null,false,1633473530),model:{value:(_vm.relator),callback:function ($$v) {_vm.relator=$$v},expression:"relator"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-select',{staticClass:"select-custom text-capitalize",attrs:{"hide-details":"","dense":"","outlined":"","items":_vm.regiones,"label":"Región","item-text":"nombre","item-value":"id","return-object":"","clearable":"","menu-props":{ offsetY: true }},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('img',{attrs:{"width":"20px","height":"20px","src":require("../assets/icons/region.svg"),"alt":"Región"}})]},proxy:true},{key:"append",fn:function(){return [_c('v-row',{staticClass:"fill-height",attrs:{"dense":"","align":"center"}},[_c('v-col',[_c('img',{attrs:{"width":"8px","height":"8px","src":require("../assets/icons/flecha-abajo.svg"),"alt":"Flecha"}})])],1)]},proxy:true},{key:"pago",fn:function(ref){
var pago = ref.pago;
return [_c('span',{staticClass:"text-capitalize poppins primary--text font-weight-light"},[_vm._v(_vm._s(pago.nombre))])]}}],null,false,454979462),model:{value:(_vm.region),callback:function ($$v) {_vm.region=$$v},expression:"region"}})],1),_c('v-col',[_c('v-row',{attrs:{"dense":"","justify":"end"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.crearPDF}},[_c('img',{attrs:{"width":"20px","height":"20px","src":require("../assets/icons/pdf.svg"),"alt":"PDF"}})])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.createExcel}},[_c('img',{attrs:{"width":"20px","height":"20px","src":require("../assets/icons/excel.svg"),"alt":"Excel"}})])],1)],1)],1)],1):(_vm.tipoUsuario == 'relator')?_c('v-row',{staticClass:"mt-3 flex-nowrap",attrs:{"dense":"","align":"start"}},[_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-text-field',{staticClass:"input-buscar rounded-lg",attrs:{"dense":"","hide-details":"","outlined":"","label":"Buscar Curso","clearable":""},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('img',{attrs:{"width":"20px","height":"20px","src":require("../assets/icons/buscar.svg"),"alt":"Buscar"}})]},proxy:true}]),model:{value:(_vm.buscar),callback:function ($$v) {_vm.buscar=$$v},expression:"buscar"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"select-custom",attrs:{"clearable":"","readonly":"","dense":"","outlined":"","hide-details":"","label":"Fecha"},on:{"click:clear":function($event){$event.preventDefault();_vm.fechasMostrar = ''}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('img',{attrs:{"width":"20px","height":"20px","src":require("../assets/icons/fecha.svg"),"alt":"Fecha"}})]},proxy:true},{key:"append",fn:function(){return [_c('v-row',{staticClass:"fill-height",attrs:{"dense":"","align":"center"}},[_c('v-col',[_c('img',{attrs:{"width":"8px","height":"8px","src":require("../assets/icons/flecha-abajo.svg"),"alt":"Flecha"}})])],1)]},proxy:true}],null,true),model:{value:(_vm.fechasMostrar),callback:function ($$v) {_vm.fechasMostrar=$$v},expression:"fechasMostrar"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"range":"","no-title":"","color":"primary","allowed-dates":_vm.fechasPermitidas},model:{value:(_vm.fechas),callback:function ($$v) {_vm.fechas=$$v},expression:"fechas"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-select',{staticClass:"select-custom text-capitalize",attrs:{"label":"Ordenar por","hide-details":"auto","dense":"","item-text":"nombre","item-value":"nombre","items":_vm.estadosCursos,"outlined":"","clearable":"","menu-props":{ offsetY: true }},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('img',{attrs:{"width":"20px","height":"20px","src":require("../assets/icons/met-pagos.svg"),"alt":"Met Pago"}})]},proxy:true},{key:"append",fn:function(){return [_c('v-row',{staticClass:"fill-height",attrs:{"dense":"","align":"center"}},[_c('v-col',[_c('img',{attrs:{"width":"8px","height":"8px","src":require("../assets/icons/flecha-abajo.svg"),"alt":"Flecha"}})])],1)]},proxy:true},{key:"pago",fn:function(ref){
var pago = ref.pago;
return [_c('span',{staticClass:"text-capitalize poppins primary--text font-weight-light"},[_vm._v(_vm._s(pago.nombre))])]}}]),model:{value:(_vm.estadoCurso),callback:function ($$v) {_vm.estadoCurso=$$v},expression:"estadoCurso"}})],1),_c('v-col',[_c('v-row',{attrs:{"dense":"","justify":"end"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.crearPDF}},[_c('img',{attrs:{"width":"20px","height":"20px","src":require("../assets/icons/pdf.svg"),"alt":"PDF"}})])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.createExcel}},[_c('img',{attrs:{"width":"20px","height":"20px","src":require("../assets/icons/excel.svg"),"alt":"Excel"}})])],1)],1)],1)],1):_vm._e(),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('BannerPago',{attrs:{"pagos":_vm.pagosFiltrados,"tipoUsuario":_vm.tipoUsuario,"relatores":_vm.relatores,"cursos":_vm.cursos}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('TablaPagos',{attrs:{"pagos":_vm.pagosFiltrados,"cursos":_vm.cursos,"tipoUsuario":_vm.tipoUsuario,"buscar":_vm.buscar},on:{"click-pago":_vm.clickItem,"abrir-eliminar":_vm.abrirDialogoEliminar,"abrir-editar":_vm.abrirDialogoFormulario,"abrir-info":_vm.abrirDialogoInfo}})],1),_c('v-dialog',{attrs:{"max-width":"600"},model:{value:(_vm.dialogoInfo),callback:function ($$v) {_vm.dialogoInfo=$$v},expression:"dialogoInfo"}},[_c('v-card',[(_vm.dialogoInfo)?_c('InfoPago',{attrs:{"pago":_vm.pago,"showTitulo":""},on:{"cerrar":function($event){_vm.dialogoInfo = false}}}):_vm._e()],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }