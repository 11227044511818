


































































































































































































































































































// decoradores
import { Vue, Component } from "vue-property-decorator";
// vuex
import { mapActions, mapGetters } from "vuex";
// tipos

// componentes
import TablaConsumo from "@/components/consumos/Tabla.vue";
import FormularioConsumo from "@/components/consumos/Formulario.vue";
import { DataFormularioConsumo } from "@/typings/components/consumos/formulario";
import DialogoExito from "@/components/DialogoExito.vue";
import DialogoEliminar from "@/components/DialogoEliminar.vue";
import InfoConsumo from "@/components/consumos/Info.vue";
import Medidor from "@/components/consumos/Medidor.vue";
//import BotonesCrud from "@/components/BotonesCrud.vue";
import MensajeEliminar from "@/components/MensajeEliminar.vue";
//import TituloVista from "@/components/TituloVista.vue";
import {
  Consumo,
  Consumos,
} from "@/typings/store/plugins/easyFirestore/consumos";
import {
  Locales,
  //  Regiones,
} from "@/typings/store/plugins/easyFirestore/locales";
import { Clientes } from "@/typings/store/plugins/easyFirestore/clientes";
//import { DataFormularioRegion } from "@/typings/components/regiones/formulario";
import CargarConsumosExcel from "@/components/excel/CargarConsumos.vue";

const routeGuard = (vm: Vue): boolean => {
  const permisos = vm.getUsuario?.permisos;
  if (permisos && permisos["consumos"]) {
    return true;
  }

  const rutas = vm.getRutasUsuario;
  if (rutas.length > 0) {
    const to = rutas[0].to;
    if (to) {
      if (vm.$route.name !== to.name) {
        vm.$router.replace(to);
      }
      return false;
    }
  }
  if (vm.$route.name !== "inicio") {
    vm.$router.replace({ name: "inicio" });
  }
  return false;
};

@Component({
  computed: mapGetters({
    getUsuarios: "usuarios/get",
    getConsumos: "consumos/get",
    getLocales: "locales/get",
    getClientes: "clientes/get",
    getArrayUsuarios: "usuarios/getArray",
    getUsuario: "usuario/get",
    getRutas: "rutas/get",
    getRutasUsuario: "usuario/getRutas",
    getArrayConsumos: "consumos/getArray",
    getArrayLocales: "locales/getArray",
    getArrayClientes: "clientes/getArray",
  }),
  methods: mapActions({
    setConsumo: "consumos/set",
    deleteConsumo: "consumos/delete",
    insertBatchConsumos: "consumos/insertBatch",
    //setArchivo: "archivos/set",
    //deleteArchivo: "archivos/delete",
  }),
  beforeRouteEnter(to, from, next) {
    next(routeGuard);
  },
  components: {
    DialogoEliminar,
    DialogoExito,
    TablaConsumo,
    FormularioConsumo,
    InfoConsumo,
    Medidor,
    //BotonesCrud,
    MensajeEliminar,
    CargarConsumosExcel,
    //TituloVista,
  },
})
export default class VistaConsumos extends Vue {
  created(): void {
    if (!routeGuard(this)) {
      return;
    }
  }

  cargando = false;
  dialogoExito = {
    model: false,
    texto: "",
    icono: "",
  };
  search = "";
  consumo: Consumo | null = null;
  dialogoInfo = false;
  dialogoFormulario = false;
  dialogoEliminar = false;
  dialogoMedidor = false;
  textoEliminar = "¿ESTÁS SEGURO QUE DESEAS ELIMINAR ESTE CONSUMO?";
  dialogoCargarExcel = false;

  seleccionadorPeriodo = "";
  seleccionadorLocal = "";
  seleccionadorCentroCosto = "";
  seleccionadorTipoServicio = "";

  // array de periodos para el filtro de periodo de consumo en la tabla

  get consumos(): Consumos {
    return this.getConsumos;
  }

  get locales(): Locales {
    return this.getLocales;
  }

  get clientes(): Clientes {
    return this.getClientes;
  }

  get items(): Array<Consumo> {
    return this.getArrayConsumos.filter((consumo) => {
      //   if (this.seleccionadorPeriodo !== "") {
      //     if (consumo.periodo !== this.seleccionadorPeriodo) {
      //       return false;
      //     }
      //   }
      //   if (this.seleccionadorLocal !== "") {
      //     if (consumo.unidadLocativa !== this.seleccionadorLocal) {
      //       return false;
      //     }
      //   }
      if (this.seleccionadorCentroCosto !== "") {
        if (consumo.centroCosto !== this.seleccionadorCentroCosto) {
          return false;
        }
      }
      //   if (this.seleccionadorTipoServicio !== "") {
      //     if (consumo.tipoServicio !== this.seleccionadorTipoServicio) {
      //       return false;
      //     }
      //   }
      return true;
    });
  }

  // calcula el monto total de consumos
  // get montoTotal(): number {
  //   let monto = 0;
  //   this.items.forEach((consumo) => {
  //     monto += 1 * consumo.monto;
  //   });
  //   return monto;
  // }

  // get selectPeriodo(): Array<string> {
  //   let periodo = "";
  //   let arrayPeriodos = [periodo];
  //   this.getArrayConsumos.forEach((consumo) => {
  //     arrayPeriodos.push(consumo.periodo);
  //   });

  //   const unicos = arrayPeriodos.filter((valor, indice) => {
  //     return arrayPeriodos.indexOf(valor) === indice;
  //   });
  //   return unicos.sort();
  // }

  // select centro de costo
  get selectCentroCosto(): Array<string> {
    let centroCosto = "";
    let arrayCentroCosto = [centroCosto];
    this.getArrayConsumos.forEach((consumo) => {
      arrayCentroCosto.push(consumo.centroCosto);
    });

    const unicos = arrayCentroCosto.filter((valor, indice) => {
      return arrayCentroCosto.indexOf(valor) === indice;
    });
    return unicos.sort();
  }

  // select local
  get selectLocal(): Array<string> {
    let local = "";
    let arrayLocal = [local];
    this.getArrayConsumos.forEach((consumo) => {
      arrayLocal.push(consumo.unidadLocativa);
    });

    const unicos = arrayLocal.filter((valor, indice) => {
      return arrayLocal.indexOf(valor) === indice;
    });
    return unicos.sort();
  }

  //select tipo de consumo
  get selectTipoConsumo(): Array<string> {
    let tipoConsumo = "";
    let arrayTipoConsumo = [tipoConsumo];
    // this.getArrayConsumos.forEach((consumo) => {
    //   arrayTipoConsumo.push(consumo.tipoServicio);
    // });

    const unicos = arrayTipoConsumo.filter((valor, indice) => {
      return arrayTipoConsumo.indexOf(valor) === indice;
    });
    return unicos.sort();
  }

  clickItem(val: { id: string } | null): void {
    this.consumo = val ? this.consumos[val.id] : null;
  }

  clickPlus(): void {
    this.consumo = null;
    this.dialogoFormulario = true;
  }

  async clickGuardar(data: DataFormularioConsumo): Promise<void> {
    this.cargando = true;
    const doc = data.doc;
    await this.setConsumo(doc);
    if (doc.id != this.consumo?.id) {
      this.dialogoExito = {
        model: true,
        texto: "¡Consumo creado con éxito!",
        icono: require("../assets/icons/double-check-duo.svg"),
      };
    }
    if (doc.id == this.consumo?.id) {
      this.dialogoExito = {
        model: true,
        texto: "¡Consumo editado con éxito!",
        icono: require("../assets/icons/editar.svg"),
      };
      this.consumo = doc;
    }
    this.cerrarDialogoFormulario();
    this.cargando = false;
  }

  cerrarDialogoExito(): void {
    this.dialogoExito = {
      model: false,
      texto: "",
      icono: "",
    };
  }

  clickEliminar(): void {
    const consumo = this.consumo;
    if (!consumo?.id) {
      console.error("error id: ", consumo);
      return;
    }
    this.deleteConsumo(consumo.id);
    this.consumo = null;
    this.cerrarDialogoEliminar();
  }

  abrirDialogoInfo(consumo: Consumo): void {
    if (!consumo.id) {
      console.error("error id: ", consumo);
      return;
    }
    const doc = this.consumos[consumo.id];
    if (doc) {
      this.consumo = doc;
      this.dialogoInfo = true;
    }
  }

  cerrarDialogoInfo(): void {
    this.dialogoInfo = false;
    this.consumo = null;
  }

  abrirDialogoMedidor(consumo: Consumo): void {
    if (!consumo.id) {
      console.error("error id: ", consumo);
      return;
    }
    const doc = this.consumos[consumo.id];
    if (doc) {
      this.consumo = doc;
      this.dialogoMedidor = true;
    }
  }

  abrirDialogoFormulario(consumo: Consumo): void {
    if (!consumo.id) {
      console.error("error id: ", consumo);
      return;
    }
    const doc = this.consumos[consumo.id];
    if (doc) {
      this.consumo = doc;
      this.dialogoFormulario = true;
    }
  }

  abrirDialogoEliminar(consumo: Consumo): void {
    if (!consumo.id) {
      console.error("error id: ", consumo);
      return;
    }
    const doc = this.consumos[consumo.id];
    if (doc) {
      this.dialogoEliminar = true;
      this.consumo = doc;
    }
  }

  cerrarDialogoEliminar(): void {
    this.dialogoEliminar = false;
    this.consumo = null;
  }

  cerrarDialogoFormulario(): void {
    this.dialogoFormulario = false;
    this.consumo = null;
  }
}
