






















































// decoradores
import { Component, Vue, Prop, Model, Emit } from "vue-property-decorator";
// tipos
import { Ruta } from "@/typings/store/modules/rutas";

@Component
export default class Administracion extends Vue {
  @Model("input", { type: Boolean }) readonly value!: boolean;

  @Prop() readonly items!: Array<Ruta>;

  get drawer(): boolean {
    return this.value;
  }

  set drawer(value: boolean) {
    this.onInput(value);
  }

  @Emit("input")
  onInput(value: boolean): boolean {
    return value;
  }

  @Emit("logout")
  onLogout(): void {
    return;
  }
}
