





















































































































// decoradores
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { Local } from "@/typings/store/plugins/easyFirestore/locales";
import SVGIcon from "@/components/SVGIcon.vue";
// tipos

// tipos
@Component({
  components: {
    SVGIcon,
  },
})
export default class InfoLocal extends Vue {
  @Prop() readonly local!: Local;
  @Prop({ type: Boolean }) readonly showTitulo!: boolean;

  get id(): string {
    return this.local.id || "N/R";
  }

  get centroCosto(): string {
    return this.local.centroCosto || "N/R";
  }

  // get direccion(): string {
  //   return this.local.direccion || "N/R";
  // }

  get unidadLocativa(): string {
    return this.local.unidadLocativa || "N/R";
  }

  get superficie(): string {
    return this.local.superficie || "N/R";
  }

  get nivel(): string {
    return this.local.nivel || "N/R";
  }

  get fachada(): string {
    return this.local.fachada || "N/R";
  }

  get vista_planta(): string {
    return this.local.vista_planta || "N/R";
  }

  get estado(): string {
    return this.local.estado || "N/R";
  }

  get tipo_local(): string {
    return this.local.tipo_local || "N/R";
  }

  @Emit("cerrar")
  onCerrar(): void {
    return;
  }
}
