










































































































































// decoradores
import { Vue, Component } from "vue-property-decorator";
// vuex
import { mapActions, mapGetters } from "vuex";
// tipos

// componentes
import FormularioPrenomina from "@/components/mapeoGastos/Formulario.vue";
import TablaPrenomina from "@/components/mapeoGastos/Tabla.vue";
import InfoPrenomina from "@/components/mapeoGastos/Info.vue";
import { DataFormularioMapeoGastos } from "@/typings/components/mapeoGastos/formulario";
import DialogoExito from "@/components/DialogoExito.vue";
import DialogoEliminar from "@/components/DialogoEliminar.vue";
import MensajeEliminar from "@/components/MensajeEliminar.vue";
import {
  MapeoGasto,
  MapeoGastos,
} from "@/typings/store/plugins/easyFirestore/mapeoGastos";
import { Proveedor } from "@/typings/store/plugins/easyFirestore/proveedores";
import { CentroCosto } from "@/typings/store/plugins/easyFirestore/centrosCostos";
import { TipoCargo } from "@/typings/store/plugins/easyFirestore/tipoCargos";
import { CodCuenta } from "@/typings/store/plugins/easyFirestore/codCuentas";
import { TipoDocumento } from "@/typings/store/plugins/easyFirestore/tipoDocumentos";
import CargarMapeoGastosExcel from "@/components/excel/CargarMapeoGastos.vue";
// import { Proveedor } from "@/typings/store/plugins/easyFirestore/proveedores";
// import { idtify } from "@/helpers/string";

import "@/helpers/extensions";

import SVGIcon from "@/components/SVGIcon.vue";

//import es from "vuetify/src/locale/es";

const routeGuard = (vm: Vue): boolean => {
  const permisos = vm.getUsuario?.permisos;
  if (permisos && permisos["mapeoGastos"]) {
    return true;
  }

  const rutas = vm.getRutasUsuario;
  if (rutas.length > 0) {
    const to = rutas[0].to;
    if (to) {
      if (vm.$route.name !== to.name) {
        vm.$router.replace(to);
      }
      return false;
    }
  }
  if (vm.$route.name !== "inicio") {
    vm.$router.replace({ name: "inicio" });
  }
  return false;
};

@Component({
  computed: mapGetters({
    getUsuarios: "usuarios/get",
    getMapeoGastos: "mapeoGastos/get",
    getArrayUsuarios: "usuarios/getArray",
    getUsuario: "usuario/get",
    getRutas: "rutas/get",
    getRutasUsuario: "usuario/getRutas",
    getArrayCentrosCostos: "centrosCostos/getArray",
    getArrayTipoCargos: "tipoCargos/getArray",
    getArrayMapeoGastos: "mapeoGastos/getArray",
    getArrayProveedores: "proveedores/getArray",
     getArrayCodCuentas: "codCuentas/getArray",
         getArrayTipoDocumentos: "tipoDocumentos/getArray",
  }),
  methods: mapActions({
    setMapeoGasto: "mapeoGastos/set",
    deleteMapeoGasto: "mapeoGastos/delete",
    insertBatchMapeoGastos: "mapeoGastos/insertBatch",
  }),
  beforeRouteEnter(to, from, next) {
    next(routeGuard);
  },
  components: {
    DialogoEliminar,
    DialogoExito,
    TablaPrenomina,
    FormularioPrenomina,
    InfoPrenomina,
    MensajeEliminar,
    SVGIcon,
    CargarMapeoGastosExcel,
  },
})
export default class VistaMapeoGastos extends Vue {
  created(): void {
    if (!routeGuard(this)) {
      return;
    }
  }

  cargando = false;
  dialogoExito = {
    model: false,
    texto: "",
    icono: "",
  };
  search = "";
  mapeoGasto: MapeoGasto | null = null;
  centroCosto: CentroCosto | null = null;
  tipoCargo: TipoCargo | null = null;
  codCuenta: CodCuenta | null = null;
   tipoDocumento: TipoDocumento | null = null;
  dialogoCargarExcel = false;
  dialogoInfo = false;
  dialogoFormulario = false;
  dialogoEliminar = false;
  textoEliminar = "¿ESTÁS SEGURO QUE DESEAS ELIMINAR ESTE MAPEO?";
  text = "";

  proveedor: Proveedor | null = null;

  get mapeoGastos(): MapeoGastos {
    return this.getMapeoGastos;
  }

  get items(): Array<MapeoGasto> {
    return this.getArrayMapeoGastos;
  }

  clickItem(val: { id: number } | null): void {
    this.mapeoGasto = val ? this.mapeoGastos[val.id] : null;
  }

  clickPlus(): void {
    this.mapeoGasto = null;
    this.dialogoFormulario = true;
  }

  async clickGuardar(data: DataFormularioMapeoGastos): Promise<void> {
    this.cargando = true;
    const doc = data.doc;
    await this.setMapeoGasto(doc);
    if (doc.id != this.mapeoGasto?.id) {
      this.dialogoExito = {
        model: true,
        texto: "¡Mapeo creado con éxito!",
        icono: require("../assets/icons/double-check-duo.svg"),
      };
    }
    if (doc.id == this.mapeoGasto?.id) {
      this.dialogoExito = {
        model: true,
        texto: "¡Mapeo editado con éxito!",
        icono: require("../assets/icons/editar.svg"),
      };
      this.mapeoGasto = doc;
    }
    this.cerrarDialogoFormulario();
    this.cargando = false;
  }

  cerrarDialogoExito(): void {
    this.dialogoExito = {
      model: false,
      texto: "",
      icono: "",
    };
  }

  clickEliminar(): void {
    const mapeoGasto = this.mapeoGasto;
    if (!mapeoGasto?.id) {
      console.error("error id: ", mapeoGasto);
      return;
    }
    this.deleteMapeoGasto(mapeoGasto.id.toString());
    this.mapeoGasto = null;
    this.cerrarDialogoEliminar();
  }

  abrirDialogoInfo(mapeoGasto: MapeoGasto): void {
    if (!mapeoGasto.id) {
      console.error("error id: ", mapeoGasto);
      return;
    }
    const doc = this.mapeoGastos[mapeoGasto.id];
    if (doc) {
      this.mapeoGasto = doc;
      this.dialogoInfo = true;
    }
  }

  cerrarDialogoInfo(): void {
    this.dialogoInfo = false;
    this.mapeoGasto = null;
  }

  abrirDialogoFormulario(mapeoGasto: MapeoGasto): void {
    if (!mapeoGasto.id) {
      console.error("error id: ", mapeoGasto);
      return;
    }
    const doc = this.mapeoGastos[mapeoGasto.id];
    if (doc) {
      this.mapeoGasto = doc;
      this.dialogoFormulario = true;
    }
  }

  abrirDialogoEliminar(mapeoGasto: MapeoGasto): void {
    if (!mapeoGasto.id) {
      console.error("error id: ", mapeoGasto);
      return;
    }
    const doc = this.mapeoGastos[mapeoGasto.id];
    if (doc) {
      this.dialogoEliminar = true;
      this.mapeoGasto = doc;
    }
  }

  cerrarDialogoEliminar(): void {
    this.dialogoEliminar = false;
    this.mapeoGasto = null;
  }

  cerrarDialogoFormulario(): void {
    this.dialogoFormulario = false;
    this.mapeoGasto = null;
  }
}
