








































































































































































































































































































































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
// helpers
//import { idtify } from "@/helpers/string";
// tipos
//import { Rutas } from "@/typings/store/modules/rutas";
//import {
//  Permisos,
//  TipoUsuario,
//  Usuario,
//} from "@/typings/store/plugins/easyFirestore/usuarios";
import {
  Medidor,
  //Bancos
} from "@/typings/store/plugins/easyFirestore/medidores";
// validaciones
import {
  // email,
  required,
} from "vuelidate/lib/validators";
import { DataFormularioMedidor } from "@/typings/components/medidores/Formulario";
import SVGIcon from "@/components/SVGIcon.vue";
//import { Archivo, Archivos } from "@/typings/store/modules/archivos";

@Component({
  components: {
    SVGIcon,
  },
  validations: {
    // nombre: { required },
    // posicion: { required },
    operador: { required },
    nroMedidor: { required },
    lecturaInicial: { required },
    estado: { required },
    tipoMedidor: { required },
    tipoTarifa: { required },
  },
})
export default class FormularioMedidor extends Vue {
  @Prop() readonly medidor!: Medidor | null;
  @Prop() readonly cargando!: boolean;
  @Prop() readonly identificadorOperador!: string;
  @Prop() readonly empresaOperador!: string;
  @Prop() readonly centroCOperador!: string;
  @Prop() readonly unidadesOperador!: string;
  //   @Prop() readonly rutas!: Rutas;
  //   @Prop() readonly ciudades!: Ciudad[];
  //   @Prop() readonly cargando!: boolean;
  //   @Prop() readonly regiones!: Region[];

  created(): void {
    // Object.keys(this.rutas).forEach((key) => {
    //   this.$set(this.permisos, key, false);
    // });
    // console.log(this.permisos);
    const medidor = this.medidor;
    if (!medidor) {
      return;
    }
    this.id = medidor.id ?? "";
    //this.nombre = medidor.nombre;
    //this.posicion = medidor.posicion ?? "";
    this.operador = this.empresaOperador ?? "";
    this.estado = medidor.estado ?? "";
    this.nroMedidor = medidor.nroMedidor ?? "";
    this.lecturaInicial = medidor.lecturaInicial ?? 0;
    this.tipoMedidor = medidor.tipoMedidor ?? "";
    this.tipoTarifa = medidor.tipoTarifa ?? "";
    this.potenciaHP = medidor.potenciaHP ?? 0;
    this.marca = medidor.marca ?? "";
    this.modelo = medidor.modelo ?? "";
    this.serie = medidor.serie ?? "";
    this.constante = medidor.constante ?? 0;
    this.enteros = medidor.enteros ?? 0;
    this.decimales = medidor.decimales ?? 0;

    this.idOperador = this.identificadorOperador ?? "";
    this.centroCostoOperador = this.centroCOperador ?? "";
    //this.nombreOperador = this.empresaOperador ?? "";
    this.localesOperador = this.unidadesOperador ?? "";
  }

  id: string | null = null;

  //nombre = "";
  //posicion = "";
  operador = this.empresaOperador;
  estado = "";
  nroMedidor = "";
  lecturaInicial = 0;
  tipoMedidor = "";
  tipoTarifa = "";
  potenciaHP = 0;
  marca = "";
  modelo = "";
  serie = "";
  constante = 0;
  enteros = 0;
  decimales = 0;

    idOperador = this.identificadorOperador;
    centroCostoOperador = this.centroCOperador;
 //   nombreOperador = "";
    localesOperador = this.unidadesOperador;

  itemsEstado = [
    { text: "ACTIVO", value: "ACTIVO" },
    { text: "INACTIVO", value: "INACTIVO" },
  ];

  itemsTipoMedidor = [
    { text: "ELECTRICIDAD", value: "ELECTRICIDAD" },
    { text: "AGUA", value: "AGUA" },
  ];

  itemsTipoTarifa = [
    { text: "AGUA", value: "AGUA" },
    { text: "BT 1", value: "BT1" },
    { text: "BT 2", value: "BT2" },
    { text: "BT 3", value: "BT3" },
    { text: "BT 4.3", value: "BT4.3" },
    { text: "AT 4.3", value: "AT4.3" },
  ];

  get titulo(): string {
    return this.medidor ? "editar medidor" : "nuevo medidor";
  }

  //   get permisosKey(): Array<string> {
  //     // return Object.keys(this.permisos).filter((key) => {
  //     //   if(this.rutas[key].tipos.includes(this.tipo)){
  //     //     this.permisos[key] = true;
  //     //   }
  //     // });
  //     return Object.keys(this.permisos).filter((key) => {
  //       if (!this.rutas[key]) {
  //         return false;
  //       }
  //       const tipo = this.rutas[key].tipos.includes(this.tipo);
  //       return tipo;
  //     });
  //   }

  // get nombreError(): Array<string> {
  //   const errors: Array<string> = [];
  //   if (!this.$v.nombre.$dirty) return errors;
  //   if (!this.$v.nombre.required) errors.push("Completar datos obligatorios");
  //   return errors;
  // }

  // get posicionError(): Array<string> {
  //   const errors: Array<string> = [];
  //   if (!this.$v.posicion.$dirty) return errors;
  //   if (!this.$v.posicion.required) errors.push("Completar datos obligatorios");
  //   return errors;
  // }

  get operadorError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.operador.$dirty) return errors;
    if (!this.$v.operador.required) errors.push("");
    return errors;
  }

  get nroMedidorError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.nroMedidor.$dirty) return errors;
    if (!this.$v.nroMedidor.required) errors.push("");
    return errors;
  }

  get lecturaInicialError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.lecturaInicial.$dirty) return errors;
    if (!this.$v.lecturaInicial.required) errors.push("");
    return errors;
  }
  get estadoError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.estado.$dirty) return errors;
    if (!this.$v.estado.required) errors.push("");
    return errors;
  }
  get tipoMedidorError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.tipoMedidor.$dirty) return errors;
    if (!this.$v.tipoMedidor.required) errors.push("");
    return errors;
  }
  get tipoTarifaError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.tipoTarifa.$dirty) return errors;
    if (!this.$v.tipoTarifa.required) errors.push("");
    return errors;
  }

  @Emit("close")
  onClose(): null {
    return null;
  }

  @Emit("cerrar-dialogo")
  onCerrarDialogo(): void {
    return;
  }

  @Emit("click-guardar")
  onClickGuardar(data: DataFormularioMedidor): DataFormularioMedidor {
    return data;
  }

  //   @Watch("permisosKey", { immediate: true })
  //   onTipoChange(): void {
  //     console.log("a");
  //     for (const key of Object.keys(this.permisos)) {
  //       if (this.permisos[key]) {
  //         this.permisos[key] = false;
  //       }
  //     }
  //     this.permisosKey.forEach((key) => {
  //       this.permisos[key] = true;
  //     });
  //   }

  //   nombrePermiso(key: string): string {
  //     return this.rutas[key].text;
  //   }

  guardar(): void {
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }
    const doc: Medidor = {
      // nombre: this.nombre,
      // posicion: this.posicion,

      operador: this.empresaOperador,
      nroMedidor: this.nroMedidor,
      lecturaInicial: this.lecturaInicial,
      estado: this.estado,
      tipoMedidor: this.tipoMedidor,
      tipoTarifa: this.tipoTarifa,
      potenciaHP: this.potenciaHP,
      marca: this.marca,
      modelo: this.modelo,
      serie: this.serie,
      constante: this.constante,
      enteros: this.enteros,
      decimales: this.decimales,

      idOperador: this.idOperador,
      centroCostoOperador: this.centroCostoOperador,
      localesOperador: this.localesOperador,
      
    };
    if (this.id) {
      doc.id = this.id;
    }
    const data: DataFormularioMedidor = {
      doc: doc,
    };
    this.onClickGuardar(data);
  }
}
