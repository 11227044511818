




























































































































































import { Component, Vue } from "vue-property-decorator";

@Component
export default class TerminosCondiciones extends Vue {
  async goTo(ruta: string): Promise<void> {
    if (this.$route.name !== ruta) {
      await this.$router.push({ name: ruta });
    }
  }
}
