





























































// decoradores
import { Vue, Component, Prop, Emit } from "vue-property-decorator";
// validaciones
import { required } from "vuelidate/lib/validators";
// tipos
import {
  Proveedor,
  Proveedores,
} from "@/typings/store/plugins/easyFirestore/proveedores";
// api excel
import Excel from "exceljs";

interface Registro {
  // entrada: Date;
  // salida: Date;
  CodAux: string;
  NomAux: string;
  RutAux: string;
  NomAux2: string;
}

interface NombresBancos {
  [NomAux: string]: Proveedor;
}

// const date = (value: Date) => {
//   return value.toString() != "Invalid Date";
// };

@Component({
  validations: {
    archivo: { required },
  },
})
export default class CargarExcel extends Vue {
  @Prop() readonly proveedores!: Proveedores;

  archivo: Blob | null = null;
  cargando = false;
  clickCargar = false;

  despachos: Array<Proveedor> = [];
  mensajes: Array<string> = [];

  get nombresBancos(): NombresBancos {
    const resultado: NombresBancos = {};
    Object.values(this.proveedores).forEach((item) => {
      resultado[item.NomAux] = Object.assign({}, item);
    });
    return resultado;
  }

  // get rfidPatentes(): RfidPatentes {
  //   const resultado: RfidPatentes = {};
  //   Object.values(this.rfids).forEach((item) => {
  //     resultado[item.patente] = Object.assign({}, item);
  //   });
  //   return resultado;
  // }

  get archivoError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.archivo.$dirty) return errors;
    if (!this.$v.archivo.required) errors.push("Requerido");
    return errors;
  }

  @Emit("carga-excel")
  onCargaExcel(): Array<Proveedor> {
    return this.despachos;
  }

  // valoresValidos(valores: Array<Excel.CellValue>): boolean {
  //   if (!valores[1] || !valores[2] ) {
  //     return false;
  //   }
  //   const NomAux = valores[1] ;
  //   const RutAux = valores[2];
  //   //return !!entrada && !!salida && date(entrada) && date(salida);
  //   return NomAux && RutAux
  // }

  async cargar(): Promise<void> {
    this.$v.$touch();
    if (this.$v.$invalid || !this.archivo) {
      return;
    }
    this.cargando = true;
    this.clickCargar = true;
    const workbook = new Excel.Workbook();
    const data = await this.archivo.arrayBuffer();
    await workbook.xlsx.load(data);
    // worksheet
    const worksheet = workbook.getWorksheet(1);
    console.log("registros", worksheet.actualRowCount - 1);
    if (worksheet.actualRowCount - 1 > 1500) {
      console.log("registros mayores a 500");
      this.mensajes.push("error: cantidad de registros mayor a 500");
      this.cargando = false;
      return;
    }
    worksheet.eachRow((row, rowNumber) => {
      if (rowNumber != 1) {
        const valores = row.values as Array<Excel.CellValue>;
        if (valores[3] == "ejemplo") {
          console.log("error row", rowNumber);
          this.mensajes.push(
            "error: row " +
              rowNumber +
              " invalida, contiene campos vacios, fechas invalidas o la etiqueta prueba"
          );
          return;
        }
        const registro: Registro = {
          CodAux: valores[1] as string,
          NomAux: valores[2] as string,
          RutAux: valores[3] as string,
          NomAux2: valores[4] as string,
          //NomAux: valores[1] as string,
          //RutAux: valores[2] as string,
        };
        // const banco = this.nombresBancos[registro.NomAux];
        //  if (!banco) {
        //    console.log("error row", rowNumber);
        //    this.mensajes.push(
        //      "error: row " + rowNumber + " invalida, patente no registrada"
        //    );
        //    return;
        //  }
        const ingreso: Proveedor = {
          CodAux: registro.CodAux,
          NomAux: registro.NomAux,
          RutAux: registro.RutAux,
          NomAux2: registro.NomAux2,
        };
        this.despachos.push(ingreso);
      }
    });
    if (this.mensajes.length > 0) {
      this.mensajes.push("cargado con errores");
      this.mensajes.push("corregir excel y volver a intentar");
      this.cargando = false;
    } else {
      this.mensajes.push("cargado completo");
      this.mensajes.push(this.despachos.length + " proveedores cargados");
      this.onCargaExcel();
      this.cargando = false;
    }
  }
}
