






















































































































































































































































































































































































import { Vue, Component } from "vue-property-decorator";
import { mapActions, mapGetters } from "vuex";
import { idtify } from "@/helpers/string";
import TablaOrden from "@/components/ordenesERG/Tabla.vue";
import FormularioOrden from "@/components/ordenesERG/Formulario.vue";
import { DataFormularioOrdenERG } from "@/typings/components/ordenesERG/formulario";
import DialogoExito from "@/components/DialogoExito.vue";
import DialogoEliminar from "@/components/DialogoEliminar.vue";
import InfoBanco from "@/components/ordenesERG/Info.vue";
import ArchivoOrden from "@/components/ordenesERG/Archivos.vue";
import ObservacionOrden from "@/components/ordenesERG/Observacion.vue";
import RespuestaOrden from "@/components/ordenesERG/Respuesta.vue";
import MensajeEliminar from "@/components/MensajeEliminar.vue";
import { CodCuenta } from "@/typings/store/plugins/easyFirestore/codCuentas";

import { OrdenERG, OrdenesERG } from "@/typings/store/plugins/easyFirestore/ordenesERG";
import { MapeoGasto } from "@/typings/store/plugins/easyFirestore/MapeoGastos";
import {
  Prenomina,
  //Prenominas,
} from "@/typings/store/plugins/easyFirestore/prenominas";
import { RegistrosEgresos } from "@/typings/store/plugins/easyFirestore/registrosEgresos";
import { DataFormularioPrenomina } from "@/typings/components/prenominas/formulario";
import { DataFormularioRegistrosEgresos } from "@/typings/components/registrosEgresos/formulario";
import CargarBancosExcel from "@/components/excel/CargarBancos.vue";

import { TDocumentDefinitions } from "pdfmake/interfaces";
import pdfMake from "pdfmake/build/pdfmake";

const routeGuard = (vm: Vue): boolean => {
  const permisos = vm.getUsuario?.permisos;
  if (permisos && permisos["ordenesERG"]) {
    return true;
  }

  const rutas = vm.getRutasUsuario;
  if (rutas.length > 0) {
    const to = rutas[0].to;
    if (to) {
      if (vm.$route.name !== to.name) {
        vm.$router.replace(to);
      }
      return false;
    }
  }
  if (vm.$route.name !== "inicio") {
    vm.$router.replace({ name: "inicio" });
  }
  return false;
};

@Component({
  computed: mapGetters({
    getUsuarios: "usuarios/get",
    getOrdenesERG: "ordenesERG/get",
    getProveedores: "proveedores/get",
    getArrayUsuarios: "usuarios/getArray",
    getUsuario: "usuario/get",
    getRutas: "rutas/get",
    getPrenominas: "prenominas/get",
    getRutasUsuario: "usuario/getRutas",
    getArrayOrdenesERG: "ordenesERG/getArray",
    getArrayProveedores: "proveedores/getArray",
    getArrayMapeoGastos: "mapeoGastos/getArray",
    getArrayCiudades: "ciudades/getArray",
    getArrayPrenominas: "prenominas/getArray",
    getArrayBancos: "bancos/getArray",
    getArrayCodCuentas: "codCuentas/getArray",
  

    getRegistrosEgresos: "registrosEgresos/get",
    getArrayRegistrosEgresos: "registrosEgresos/getArray",
  }),
  methods: mapActions({
    //ORDENES
    setOrdenERG: "ordenesERG/set",
    deleteOrdenERG: "ordenesERG/delete",
    insertBatchOrdenesERG: "ordenesERG/insertBatch",
    //ARCHIVOS
    setArchivo: "archivos/set",
    deleteArchivo: "archivos/delete",
    //PRENOMINAS
    setPrenomina: "prenominas/set",
    deletePrenomina: "prenominas/delete",
    insertBatchPrenominas: "prenominas/insertBatch",
    //REGISTROS EGRESOS
    setEgreso: "registrosEgresos/set",
    deleteEgreso: "registrosEgresos/delete",
    insertBatchEgreso: "registrosEgresos/insertBatch",
  }),
  beforeRouteEnter(to, from, next) {
    next(routeGuard);
  },
  components: {
    DialogoEliminar,
    DialogoExito,
    TablaOrden,
    FormularioOrden,
    InfoBanco,
    ArchivoOrden,
    ObservacionOrden,
    RespuestaOrden,
    //BotonesCrud,
    MensajeEliminar,
    CargarBancosExcel,
    //TituloVista,
  },
})
export default class VistaOrdenes extends Vue {
  created(): void {
    if (!routeGuard(this)) {
      return;
    }
  }

  cargando = false;
  dialogoExito = {
    model: false,
    texto: "",
    icono: "",
  };
  buscar = "";
  estados = [
    "TODO",
    "PENDIENTE",
    "APROBADO CON FACTURA",
    "APROBADO SIN FACTURA",
    "APROBADO",
    "RECHAZADO",
  ];
  filtrarCentroCosto = "";
  filtrarProveedor = "";

  filtrarOrden = "";
  filtrarEstado = "";
  ordenERG: OrdenERG | null = null;
  prenomina: Prenomina | null = null;
  registrosEgresos: RegistrosEgresos | null = null;
  codCuenta: CodCuenta | null = null;
  dialogoInfo = false;
  dialogoFormulario = false;
  dialogoEliminar = false;
  textoEliminar = "¿ESTÁS SEGURO QUE DESEAS ELIMINAR ESTA ORDEN DE COMPRA?";
  dialogoCargarExcel = false;
  dialogoArchivo = false;
  dialogoObservacion = false;
  dialogoRespuesta = false;

  snackbarPrenomina = false;
  textSnackbarPrenomina = "SE HA ENVIADO EL PAGO A PRENOMINA";
  timeoutPrenomina= 3000;

  textSnackbarExistentePrenomina = "PAGO YA EXISTE EN PRENOMINA";

  snackbarConciliacion = false;
  textSnackbarConciliacion = "SE HA CONCILIADO EL PAGO";
  timeoutConciliacion = 3000;

  objects = [{
  message:"",
  color:"",
  timeout:0
}]

  get ordenesERG(): OrdenesERG {
    return this.getOrdenesERG;
  }

  get items(): Array<OrdenERG> {
    return this.getArrayOrdenesERG.filter((ordenERG) => {
      // muestrame los datos que contengan buscar
      const aprobadoFactura =
        (ordenERG.estado === "APROBADO" &&
          ordenERG.tipoArchivo === "BOLETA/FACTURA") ||
        ordenERG.tipoArchivo2 === "BOLETA/FACTURA" ||
        ordenERG.tipoArchivo3 === "BOLETA/FACTURA" ||
        ordenERG.tipoArchivo4 === "BOLETA/FACTURA" ||
        ordenERG.tipoArchivo5 === "BOLETA/FACTURA" ||
        ordenERG.tipoArchivo6 === "BOLETA/FACTURA";

      const aprobadoSinFactura = ordenERG.color === "#FFA726";

      if (this.buscar) {
        return (
          ordenERG.nombre.toLowerCase().indexOf(this.buscar.toLowerCase()) !== -1
        );
      }
      if (this.filtrarCentroCosto) {
        return (
          ordenERG.centroCosto
            .toLowerCase()
            .indexOf(this.filtrarCentroCosto.toLowerCase()) !== -1
        );
      }
      if (this.filtrarProveedor) {
        return (
          ordenERG.empresa
            .toLowerCase()
            .indexOf(this.filtrarProveedor.toLowerCase()) !== -1
        );
      }
      if (this.filtrarOrden) {
        return (
          ordenERG.ordenCompra
            .toString()
            .toLowerCase()
            .indexOf(this.filtrarOrden.toLowerCase()) !== -1
        );
      }
      if (this.filtrarEstado) {
        if (this.filtrarEstado === "TODO") {
          return true;
        }
        if (this.filtrarEstado === "APROBADO CON FACTURA") {
          return aprobadoFactura;
        }
        if (this.filtrarEstado === "APROBADO SIN FACTURA") {
          return aprobadoSinFactura;
        }
        return (
          ordenERG.estado
            .toString()
            .toLowerCase()
            .indexOf(this.filtrarEstado.toLowerCase()) !== -1
        );
      }
      return ordenERG.estado.toString().toLowerCase().indexOf("pendiente") !== -1;
      // if (this.filtrarProveedor === ordenERG.empresa) {
      //   return true;
      // }
      // return false;
    });
  }

  // calculo de la cantidad de ordenesERG
  get cantidadOrdenes(): number {
    let numero = 0;
    let arrayNumeros = [numero];
    this.getArrayOrdenesERG.forEach((ordenERG) => {
      if (ordenERG.ordenCompra != numero) {
        arrayNumeros.push(ordenERG.ordenCompra);
      }
    });
    let max = Math.max.apply(Math, arrayNumeros);
    return max;
  }

  mapeoGasto: MapeoGasto | null = null;

  //ATRIBUTO EMPRESA DE ITEMS SIN REPETIR ordenados alfabeticamente

  get empresas(): Array<string> {
    let empresas: Array<string> = [];
    this.getArrayOrdenesERG.forEach((ordenERG) => {
      if (!empresas.includes(ordenERG.empresa)) {
        empresas.push(ordenERG.empresa);
      }
    });
    empresas.sort();
    return empresas;
  }

  get centrosCostos(): Array<string> {
    let centrosCostos: Array<string> = [];
    this.getArrayOrdenesERG.forEach((ordenERG) => {
      if (!centrosCostos.includes(ordenERG.centroCosto)) {
        centrosCostos.push(ordenERG.centroCosto);
      }
    });
    centrosCostos.sort((a, b) =>
      a.localeCompare(b, undefined, { numeric: true, sensitivity: "base" })
    );
    return centrosCostos;
  }

  //ATRIBUTO ESTADO DE ITEMS SIN REPETIR
  // get estados(): Array<string> {
  //   let estados: Array<string> = [];
  //   this.getArrayOrdenesERG.forEach((ordenERG) => {
  //     if (!estados.includes(ordenERG.estado)) {
  //       estados.push(ordenERG.estado.toUpperCase());
  //     }
  //   });
  //   return estados;
  // }

  //ATRIBUTO ORDEN DE ITEMS SIN REPETIR ordenadas de mayor a menor
  get ordenesCompra(): Array<string> {
    let ordenesERG: Array<string> = [];
    this.getArrayOrdenesERG.forEach((ordenERG) => {
      if (!ordenesERG.includes(ordenERG.ordenCompra.toString())) {
        ordenesERG.push(ordenERG.ordenCompra.toString());
      }
    });
    ordenesERG.sort((a, b) => {
      return parseInt(b) - parseInt(a);
    });
    return ordenesERG;
  }

  cambiarEstado(ordenERG: OrdenERG): void {
    this.setOrdenERG(ordenERG);
  }

  cambiarTipoGasto(ordenERG: OrdenERG): void {
    this.setOrdenERG(ordenERG);
  }

  cambiarCuentaSoftland(ordenERG: OrdenERG): void {
    this.setOrdenERG(ordenERG);
  }

  clickItem(val: { id: string } | null): void {
    this.ordenERG = val ? this.ordenesERG[val.id] : null;
  }

  clickPlus(): void {
    this.ordenERG = null;
    this.dialogoFormulario = true;
  }

  async clickGuardar(data: DataFormularioOrdenERG): Promise<void> {
    this.cargando = true;
    const doc = data.doc;
    const archivo = data.archivo;
    const archivo2 = data.archivo2;
    const archivo3 = data.archivo3;
    const archivo4 = data.archivo4;
    const archivo5 = data.archivo5;
    const archivo6 = data.archivo6;

    const borrar = data.borrar;
    for (const key in archivo) {
      if (archivo[key].file) {
        const doc = archivo[key];
        const res = await this.setArchivo(doc);
        if (res && res.url) {
          this.$set(archivo, idtify(res.id), res);
        }
      }
    }
    for (const key in archivo2) {
      if (archivo2[key].file) {
        const doc = archivo2[key];
        const res = await this.setArchivo(doc);
        if (res && res.url) {
          this.$set(archivo2, idtify(res.id), res);
        }
      }
    }
    for (const key in archivo3) {
      if (archivo3[key].file) {
        const doc = archivo3[key];
        const res = await this.setArchivo(doc);
        if (res && res.url) {
          this.$set(archivo3, idtify(res.id), res);
        }
      }
    }
    for (const key in archivo4) {
      if (archivo4[key].file) {
        const doc = archivo4[key];
        const res = await this.setArchivo(doc);
        if (res && res.url) {
          this.$set(archivo4, idtify(res.id), res);
        }
      }
    }
    for (const key in archivo5) {
      if (archivo5[key].file) {
        const doc = archivo5[key];
        const res = await this.setArchivo(doc);
        if (res && res.url) {
          this.$set(archivo5, idtify(res.id), res);
        }
      }
    }
    for (const key in archivo6) {
      if (archivo6[key].file) {
        const doc = archivo6[key];
        const res = await this.setArchivo(doc);
        if (res && res.url) {
          this.$set(archivo6, idtify(res.id), res);
        }
      }
    }
    for (const key in borrar) {
      if (borrar[key]) {
        const doc = borrar[key];
        await this.deleteArchivo(doc.id);
      }
    }
    doc.archivo = archivo;
    doc.archivo2 = archivo2;
    doc.archivo3 = archivo3;
    doc.archivo4 = archivo4;
    doc.archivo5 = archivo5;
    doc.archivo6 = archivo6;
    await this.setOrdenERG(doc);
    if (doc.id != this.ordenERG?.id) {
      this.dialogoExito = {
        model: true,
        texto: "¡Orden de compra creada con éxito!",
        icono: require("../assets/icons/double-check-duo.svg"),
      };
    }
    if (doc.id == this.ordenERG?.id) {
      this.dialogoExito = {
        model: true,
        texto: "¡Orden de compra editada con éxito!",
        icono: require("../assets/icons/editar.svg"),
      };
      this.ordenERG = doc;
    }
    this.cerrarDialogoFormulario();
    this.cerrarDialogoObservacion();
    this.cerrarDialogoArchivo();

  //   this.objects.push({
  // message:"Success",
  // color:"green",
  // timeout:5000
// })

    this.cargando = false;
  }

  async clickGuardarPrenomina(data: DataFormularioPrenomina): Promise<void> {
    this.cargando = true;
    const doc = data.doc;
    const archivo = data.archivo;
    const borrar = data.borrar;
    for (const key in archivo) {
      if (archivo[key].file) {
        const doc = archivo[key];
        const res = await this.setArchivo(doc);
        if (res && res.url) {
          this.$set(archivo, idtify(res.id), res);
        }
      }
    }
    for (const key in borrar) {
      if (borrar[key]) {
        const doc = borrar[key];
        await this.deleteArchivo(doc.id);
      }
    }
    doc.archivo = archivo;
    await this.setPrenomina(doc);
    if (doc.id != this.prenomina?.id) {
      // console.log("Paso el dato a prenomina");
      // this.dialogoExito = {
      //   model: true,
      //   texto: "¡Prenomina creada con éxito!",
      //   icono: require("../assets/icons/double-check-duo.svg"),
      // };
    this.snackbarPrenomina = true;

    } 
    // if (doc.id == this.prenomina?.id) {
    //   this.dialogoExito = {
    //     model: true,
    //     texto: "¡Prenomina editada con éxito!",
    //     icono: require("../assets/icons/editar.svg"),
    //   };
    //   this.prenomina = doc;
    // }
    this.cerrarDialogoFormulario();

    this.cargando = false;
  }

  async clickGuardarConciliacion(
    data: DataFormularioRegistrosEgresos
  ): Promise<void> {
    this.cargando = true;
    const doc = data.doc;
    this.setEgreso(doc);
    if (doc.id != this.registrosEgresos?.id) {
      // this.dialogoExito = {
      //   model: true,
      //   texto: "¡Registro creado con éxito!",
      //   icono: require("../assets/icons/double-check-duo.svg"),
      // };
    this.snackbarConciliacion = true;

    }
    if (doc.id == this.registrosEgresos?.id) {
      this.dialogoExito = {
        model: true,
        texto: "¡Registro editado con éxito!",
        icono: require("../assets/icons/editar.svg"),
      };
      this.registrosEgresos = doc;
    }
    this.cerrarDialogoFormulario();
    // this.messages.push("This is a message 2");
    this.cargando = false;
  }

  cerrarDialogoExito(): void {
    this.dialogoExito = {
      model: false,
      texto: "",
      icono: "",
    };
  }

  clickEliminar(): void {
    const ordenERG = this.ordenERG;
    if (!ordenERG?.id) {
      console.error("error id: ", ordenERG);
      return;
    }
    this.deleteOrdenERG(ordenERG.id);
    this.ordenERG = null;
    this.cerrarDialogoEliminar();
  }

  abrirDialogoInfo(ordenERG: OrdenERG): void {
    if (!ordenERG.id) {
      console.error("error id: ", ordenERG);
      return;
    }
    const doc = this.ordenesERG[ordenERG.id];
    if (doc) {
      this.ordenERG = doc;
      this.dialogoInfo = true;
    }
  }

  cerrarDialogoInfo(): void {
    this.dialogoInfo = false;
    this.ordenERG = null;
  }

  abrirDialogoFormulario(ordenERG: OrdenERG): void {
    if (!ordenERG.id) {
      console.error("error id: ", ordenERG);
      return;
    }
    const doc = this.ordenesERG[ordenERG.id];
    if (doc) {
      this.ordenERG = doc;
      this.dialogoFormulario = true;
    }
  }

  abrirDialogoEliminar(ordenERG: OrdenERG): void {
    if (!ordenERG.id) {
      console.error("error id: ", ordenERG);
      return;
    }
    const doc = this.ordenesERG[ordenERG.id];
    if (doc) {
      this.dialogoEliminar = true;
      this.ordenERG = doc;
    }
  }

  abrirDialogoArchivo(ordenERG: OrdenERG): void {
    if (!ordenERG.id) {
      console.error("error id: ", ordenERG);
      return;
    }
    const doc = this.ordenesERG[ordenERG.id];
    if (doc) {
      this.ordenERG = doc;
      this.dialogoArchivo = true;
    }
  }

  abrirDialogoObservacion(ordenERG: OrdenERG): void {
    if (!ordenERG.id) {
      console.error("error id: ", ordenERG);
      return;
    }
    const doc = this.ordenesERG[ordenERG.id];
    if (doc) {
      this.ordenERG = doc;
      this.dialogoObservacion = true;
    }
  }

  abrirDialogoRespuesta(ordenERG: OrdenERG): void {
    if (!ordenERG.id) {
      console.error("error id: ", ordenERG);
      return;
    }
    const doc = this.ordenesERG[ordenERG.id];
    if (doc) {
      this.ordenERG = doc;
      this.dialogoRespuesta = true;
    }
  }

  cerrarDialogoEliminar(): void {
    this.dialogoEliminar = false;
    this.ordenERG = null;
  }

  cerrarDialogoFormulario(): void {
    this.dialogoFormulario = false;
    this.ordenERG = null;
  }

  cerrarDialogoArchivo(): void {
    this.dialogoArchivo = false;
    this.ordenERG = null;
  }

  cerrarDialogoObservacion(): void {
    this.dialogoObservacion = false;
    this.ordenERG = null;
  }

  cerrarDialogoRespuesta(): void {
    this.dialogoRespuesta = false;
    this.ordenERG = null;
  }

  // abrirSnackbarPrenomina(): void {
  //   this.dialogoArchivo = false;
  // }


  async crearPdfOrdenPago(ordenERG: OrdenERG): Promise<void> {
    const link = { url: require("@/assets/logo_salhus_white2.jpg") };
    const linkAprobada = {
      url: require("@/assets/pdfestados_pages-to-jpg-aprobada.jpg"),
    };
    const result = await fetch(link.url);
    const resultAprobada = await fetch(linkAprobada.url);
    const result2 = await result.blob();
    const result2Aprobada = await resultAprobada.blob();
    //const fechaFormateada = new Date();
    const base64: string = await new Promise((callback) => {
      let reader = new FileReader();
      reader.onload = function () {
        callback(this.result?.toString() ?? "");
      };
      reader.readAsDataURL(result2);
    });

    const base64_1: string = await new Promise((callback) => {
      let reader = new FileReader();
      reader.onload = function () {
        callback(this.result?.toString() ?? "");
      };
      reader.readAsDataURL(result2Aprobada);
    });

    var dd: TDocumentDefinitions = {
      content: [
        {
          columns: [
            {
              image: "logo",
              alignment: "right",
              height: 30,
              width: 100,
            },
            {
              text: "",
            },
            {
              text: "",
            },
          ],
        },
        {
          text: "CONSTRUCTORA ERG SPA",
          style: "header",
        },
        {
          text: "77.029.089-9\nCONSTRUCCION DE EDIFICIOS PARA USO NO RESIDENCIAL\nLAUTARO 429 PISO 2 - LINARES\n732 210881\n\n",
          style: "subheaderSB_Centro",
        },

        {
          style: "tableExample",
          table: {
            headerRows: 1,
            widths: ["*"],
            body: [
              [
                {
                  text: "Orden de Pago N° " + ordenERG.ordenCompra,
                  style: "titulo",
                  border: [false, false, false, true],
                },
              ],
            ],
          },
        },

        "\n",
        {
          columns: [
            {
              image: base64_1,
              alignment: "center",
              height: 60,
              width: 200,
            },
            {
              style: "tableExample",
              table: {
                widths: ["*", "*", "*"],

                body: [
                  [
                    { text: "", style: "subheaderSB" },
                    { text: "Fecha Emisón:", style: "subheaderCB" },
                    { text: ordenERG.fechaEmision, style: "subheaderSB" },
                  ],
                  [
                    { text: "", style: "subheaderSB" },
                    { text: "Estado:", style: "subheaderCB" },
                    {
                      text: ordenERG.estado.capitalizeFirstLetter(),
                      style: "subheaderSB",
                    },
                  ],
                  [
                    { text: "", style: "subheaderSB" },
                    { text: "Aprobado por:", style: "subheaderCB" },
                    { text: ordenERG.autorizado, style: "subheaderSB" },
                  ],
                  [
                    { text: "", style: "subheaderSB" },
                    { text: "Centro de Costo:", style: "subheaderCB" },
                    { text: ordenERG.centroCosto, style: "subheaderSB" },
                  ],
                  [
                    { text: "", style: "subheaderSB" },
                    { text: "Moneda:", style: "subheaderCB" },
                    { text: ordenERG.divisa, style: "subheaderSB" },
                  ],
                ],
              },

              layout: "noBorders",
            },
          ],
        },
        "\n",

        {
          style: "tableExample",
          table: {
            widths: ["*"],
            body: [
              [
                {
                  style: "tableExample",
                  table: {
                    headerRows: 1,
                    widths: [10, 60, "*", 60, "*"],
                    body: [
                      [
                        { text: "" },
                        { text: "Razón Social:", style: "subheaderCB" },
                        { text: ordenERG.empresa, style: "subheaderSB" },
                        { text: "Rut:", style: "subheaderCB" },
                        { text: ordenERG.rut, style: "subheaderSB" },
                      ],
                      [
                        { text: "" },
                        { text: "Dirección:", style: "subheaderCB" },
                        { text: ordenERG.direccion, style: "subheaderSB" },
                        { text: "Teléfono:", style: "subheaderCB" },
                        { text: ordenERG.telefono, style: "subheaderSB" },
                      ],
                      [
                        { text: "" },
                        { text: "Comuna:", style: "subheaderCB" },
                        { text: ordenERG.comuna, style: "subheaderSB" },
                        { text: "Contacto:", style: "subheaderCB" },
                        { text: ordenERG.contacto, style: "subheaderSB" },
                      ],
                      [
                        { text: "" },
                        { text: "Forma de Pago:", style: "subheaderCB" },
                        { text: ordenERG.formaPago, style: "subheaderSB" },
                        { text: "Mail:", style: "subheaderCB" },
                        { text: ordenERG.correo, style: "subheaderSB" },
                      ],
                    ],
                  },
                  layout: "noBorders",
                },
              ],
            ],
          },
        },
        "\n",
        {
          style: "tableExample",
          table: {
            widths: [50, 50, 150, 50, "*", "*"],
            body: [
              [
                { text: "Cantidad", style: "subheaderCB", alignment: "center" },
                {
                  text: "Uni. Medida",
                  alignment: "center",
                  style: "subheaderCB",
                },
                {
                  text: "Descripción",
                  style: "subheaderCB",
                  alignment: "center",
                },
                {
                  text: "Centro Costo",
                  style: "subheaderCB",
                  alignment: "center",
                },
                {
                  text: "P. Unitario",
                  style: "subheaderCB",
                  alignment: "center",
                },
                {
                  text: "Valor Total",
                  style: "subheaderCB",
                  alignment: "center",
                },
              ],
              [
                {
                  text: ordenERG.cantidad_1,
                  style: "subheaderSB",
                  alignment: "center",
                },
                {
                  text: ordenERG.unidadMedida_1,
                  style: "subheaderSB",
                  alignment: "center",
                },
                {
                  text: ordenERG.descripcion_1,
                  style: "subheaderSB",
                  alignment: "center",
                },
                {
                  text: ordenERG.centroCosto_1,
                  style: "subheaderSB",
                  alignment: "center",
                },
                {
                  text: parseInt(ordenERG.precioUnitario_1.toString()).toCurrency({
                    symbol: "$",
                  }),
                  style: "subheaderSB_Derecha",
                },
                {
                  text: ordenERG.precioTotal_1.toCurrency({ symbol: "$" }),
                  style: "subheaderSB_Derecha",
                },
              ],
              ordenERG.precioTotal_2
                ? [
                    {
                      text: ordenERG.cantidad_2,
                      style: "subheaderSB",
                      alignment: "center",
                    },
                    {
                      text: ordenERG.unidadMedida_2,
                      style: "subheaderSB",
                      alignment: "center",
                    },
                    {
                      text: ordenERG.descripcion_2,
                      style: "subheaderSB",
                      alignment: "center",
                    },
                    {
                      text: ordenERG.centroCosto_2,
                      style: "subheaderSB",
                      alignment: "center",
                    },
                    {
                      text: parseInt(
                        ordenERG.precioUnitario_2.toString()
                      ).toCurrency({ symbol: "$" }),
                      style: "subheaderSB_Derecha",
                    },
                    {
                      text: ordenERG.precioTotal_2.toCurrency({ symbol: "$" }),
                      style: "subheaderSB_Derecha",
                    },
                  ]
                : [
                    {
                      text: "",
                      style: "subheaderSB",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB_Derecha",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB_Derecha",
                      border: [false, false, false, false],
                    },
                  ],

              ordenERG.precioTotal_3
                ? [
                    {
                      text: ordenERG.cantidad_3,
                      style: "subheaderSB",
                      alignment: "center",
                    },
                    {
                      text: ordenERG.unidadMedida_3,
                      style: "subheaderSB",
                      alignment: "center",
                    },
                    {
                      text: ordenERG.descripcion_3,
                      style: "subheaderSB",
                      alignment: "center",
                    },
                    {
                      text: ordenERG.centroCosto_3,
                      style: "subheaderSB",
                      alignment: "center",
                    },
                    {
                      text: parseInt(
                        ordenERG.precioUnitario_3.toString()
                      ).toCurrency({ symbol: "$" }),
                      style: "subheaderSB_Derecha",
                    },
                    {
                      text: ordenERG.precioTotal_3.toCurrency({ symbol: "$" }),
                      style: "subheaderSB_Derecha",
                    },
                  ]
                : [
                    {
                      text: "",
                      style: "subheaderSB",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB_Derecha",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB_Derecha",
                      border: [false, false, false, false],
                    },
                  ],

              ordenERG.precioTotal_4
                ? [
                    {
                      text: ordenERG.cantidad_4,
                      style: "subheaderSB",
                      alignment: "center",
                    },
                    {
                      text: ordenERG.unidadMedida_4,
                      style: "subheaderSB",
                      alignment: "center",
                    },
                    {
                      text: ordenERG.descripcion_4,
                      style: "subheaderSB",
                      alignment: "center",
                    },
                    {
                      text: ordenERG.centroCosto_4,
                      style: "subheaderSB",
                      alignment: "center",
                    },
                    {
                      text: parseInt(
                        ordenERG.precioUnitario_4.toString()
                      ).toCurrency({ symbol: "$" }),
                      style: "subheaderSB_Derecha",
                    },
                    {
                      text: ordenERG.precioTotal_4.toCurrency({ symbol: "$" }),
                      style: "subheaderSB_Derecha",
                    },
                  ]
                : [
                    {
                      text: "",
                      style: "subheaderSB",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB_Derecha",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB_Derecha",
                      border: [false, false, false, false],
                    },
                  ],

              ordenERG.precioTotal_5
                ? [
                    {
                      text: ordenERG.cantidad_5,
                      style: "subheaderSB",
                      alignment: "center",
                    },
                    {
                      text: ordenERG.unidadMedida_5,
                      style: "subheaderSB",
                      alignment: "center",
                    },
                    {
                      text: ordenERG.descripcion_5,
                      style: "subheaderSB",
                      alignment: "center",
                    },
                    {
                      text: ordenERG.centroCosto_5,
                      style: "subheaderSB",
                      alignment: "center",
                    },
                    {
                      text: parseInt(
                        ordenERG.precioUnitario_5.toString()
                      ).toCurrency({ symbol: "$" }),
                      style: "subheaderSB_Derecha",
                    },
                    {
                      text: ordenERG.precioTotal_5.toCurrency({ symbol: "$" }),
                      style: "subheaderSB_Derecha",
                    },
                  ]
                : [
                    {
                      text: "",
                      style: "subheaderSB",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB_Derecha",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB_Derecha",
                      border: [false, false, false, false],
                    },
                  ],
            ],
          },
        },
        "\n",

        {
          style: "tableExample",
          table: {
            widths: [50, 50, 150, 50, "*", "*"],
            body: [
              [
                {
                  text: "Observación:",
                  style: "subheaderCB",
                  border: [true, true, true, false],
                  colSpan: 3,
                },
                { text: "", style: "subheaderCB" },
                { text: "", style: "subheaderCB" },
                {
                  text: " ",
                  style: "subheaderCB",
                  border: [false, false, false, false],
                },
                { text: "Sub Total", style: "subheaderCB" },
                {
                  text: ordenERG.subTotal.toCurrency({ symbol: "$" }),
                  style: "subheaderSB_Derecha",
                },
              ],
              [
                {
                  text: ordenERG.observaciones,
                  style: "subheaderSB",
                  border: [true, false, true, true],
                  rowSpan: 5,
                  colSpan: 3,
                },
                "",
                "",
                {
                  text: " ",
                  style: "subheaderCB",
                  border: [false, false, false, false],
                },
                { text: "Descuento 1", style: "subheaderCB" },
                {
                  text: ordenERG.descuento.toCurrency({ symbol: "$" }),
                  style: "subheaderSB_Derecha",
                },
              ],
              [
                "",
                "",
                "",
                {
                  text: " ",
                  style: "subheaderCB",
                  border: [false, false, false, false],
                },
                { text: "Afecto IVA", style: "subheaderCB" },
                {
                  text: ordenERG.afectoIVA.toCurrency({ symbol: "$" }),
                  style: "subheaderSB_Derecha",
                },
              ],
              [
                "",
                "",
                "",
                {
                  text: " ",
                  style: "subheaderCB",
                  border: [false, false, false, false],
                },
                { text: "Excento IVA", style: "subheaderCB" },
                {
                  text: ordenERG.exentoIVA.toCurrency({ symbol: "$" }),
                  style: "subheaderSB_Derecha",
                },
              ],
              [
                "",
                "",
                "",
                {
                  text: " ",
                  style: "subheaderCB",
                  border: [false, false, false, false],
                },
                { text: "Sub Total Neto", style: "subheaderCB" },
                {
                  text: ordenERG.subTotalNeto.toCurrency({ symbol: "$" }),
                  style: "subheaderSB_Derecha",
                },
              ],
              [
                "",
                "",
                "",
                {
                  text: " ",
                  style: "subheaderCB",
                  border: [false, false, false, false],
                },
                { text: "IVA", style: "subheaderCB" },
                {
                  text: ordenERG.IVA.toCurrency({ symbol: "$" }),
                  style: "subheaderSB_Derecha",
                },
              ],
              [
                {
                  text: " ",
                  style: "subheaderCB",
                  border: [false, false, false, false],
                },
                {
                  text: " ",
                  style: "subheaderCB",
                  border: [false, false, false, false],
                },
                {
                  text: " ",
                  style: "subheaderCB",
                  border: [false, false, false, false],
                },
                {
                  text: " ",
                  style: "subheaderCB",
                  border: [false, false, false, false],
                },

                { text: "Total", style: "subheaderCB" },
                {
                  text: ordenERG.total.toCurrency({ symbol: "$" }),
                  style: "subheaderSB_Derecha",
                },
              ],
            ],
          },
        },

        "\n",
        {
          style: "tableExample",
          table: {
            headerRows: 1,
            widths: ["*"],
            body: [
              [
                {
                  text: "\nDatos de Transferencia",
                  style: "subheaderCB",
                  border: [false, true, false, false],
                },
              ],
            ],
          },
        },
        "\n",
        {
          style: "tableExample",
          table: {
            widths: ["*"],
            body: [
              [
                {
                  style: "tableExample",
                  table: {
                    headerRows: 1,
                    widths: [10, 60, "*", 60, "*"],
                    body: [
                      [
                        { text: "" },
                        { text: "Destinatario:", style: "subheaderCB" },
                        { text: ordenERG.destinatario, style: "subheaderSB" },
                        { text: "Rut:", style: "subheaderCB" },
                        { text: ordenERG.rutDestinatario, style: "subheaderSB" },
                      ],
                      [
                        { text: "" },
                        { text: "Banco:", style: "subheaderCB" },
                        { text: ordenERG.banco, style: "subheaderSB" },
                        { text: "Tipo de Cuenta:", style: "subheaderCB" },
                        { text: ordenERG.tipoCueta, style: "subheaderSB" },
                      ],
                      [
                        { text: "" },
                        { text: "N° Cuenta:", style: "subheaderCB" },
                        { text: ordenERG.nroCuenta, style: "subheaderSB" },
                        { text: "Email:", style: "subheaderCB" },
                        {
                          text: ordenERG.correoDestinatario,
                          style: "subheaderSB",
                        },
                      ],
                    ],
                  },
                  layout: "noBorders",
                },
              ],
            ],
          },
        },
        "\n",
        {
          text: "NOTA:",
          style: "subheaderCB",
          //border: [false, false, false, false],
          //colSpan: 3,
        },
        {
          text: "La orden NO será validada si no se encuentra en estado APROBADA.",
          style: "subheaderSB",
          //border: [false, false, false, false],
          //colSpan: 3,
        },
        "\n",

        {
          text: "Para efectos de envío de Facturas y XML, realizar a la cuenta naguilar@iisf.cl. Las facturas emitidas deben tener en referencias el número de ordenERG de compra.",
          style: ["quote", "small"],
        },
      ],
      images: {
        logo: base64,
        image: base64_1,
      },
      styles: {
        titulo: {
          fontSize: 12,
          bold: true,
          alignment: "center",
        },
        header: {
          fontSize: 10,
          bold: true,
          alignment: "center",
        },
        subheader: {
          fontSize: 15,
          bold: true,
        },
        subheaderSB: {
          fontSize: 8,
          bold: false,
          alignment: "left",
        },
        subheaderSB_Centro: {
          fontSize: 8,
          bold: false,
          alignment: "center",
        },
        subheaderSB_Derecha: {
          fontSize: 8,
          bold: false,
          alignment: "right",
        },
        subheaderCB: {
          fontSize: 8,
          bold: true,
          //aligment: "center",
        },
        quote: {
          italics: true,
        },
        small: {
          fontSize: 8,
        },
      },
    };

    pdfMake.createPdf(dd).download(`Orden de Pago ${ordenERG.ordenCompra} ERG.pdf`);
  }

  async crearPdfOrdenCompra(ordenERG: OrdenERG): Promise<void> {
    const link = { url: require("@/assets/logo_salhus_white2.jpg") };
    const linkAprobada = {
      url: require("@/assets/pdfestados_pages-to-jpg-aprobada.jpg"),
    };
    const linkPendiente = {
      url: require("@/assets/pdfestados_pages-to-jpg-pendiente.jpg"),
    };
    const linkRechazada = {
      url: require("@/assets/pdfestados_pages-to-jpg-rechazada.jpg"),
    };

    const result = await fetch(link.url);
    const resultAprobada = await fetch(linkAprobada.url);
    const resultPendiente = await fetch(linkPendiente.url);
    const resultRechazada = await fetch(linkRechazada.url);

    const result2 = await result.blob();
    const result2Aprobada = await resultAprobada.blob();
    const result2Pendiente = await resultPendiente.blob();
    const result2Rechazada = await resultRechazada.blob();
    //const fechaFormateada = new Date();
    const base64: string = await new Promise((callback) => {
      let reader = new FileReader();
      reader.onload = function () {
        callback(this.result?.toString() ?? "");
      };
      reader.readAsDataURL(result2);
    });

    const base64_1: string = await new Promise((callback) => {
      let reader = new FileReader();
      reader.onload = function () {
        callback(this.result?.toString() ?? "");
      };
      reader.readAsDataURL(result2Aprobada);
    });

    const base64_2: string = await new Promise((callback) => {
      let reader = new FileReader();
      reader.onload = function () {
        callback(this.result?.toString() ?? "");
      };
      reader.readAsDataURL(result2Pendiente);
    });

    const base64_3: string = await new Promise((callback) => {
      let reader = new FileReader();
      reader.onload = function () {
        callback(this.result?.toString() ?? "");
      };
      reader.readAsDataURL(result2Rechazada);
    });

    var dd: TDocumentDefinitions = {
      content: [
        {
          columns: [
            {
              // image: "logo",
              // alignment: "right",
              // height: 30,
              // width: 100,
              text: "",
            },
            {
              text: "",
            },
            {
              text: "",
            },
          ],
        },
        {
          text: "CONSTRUCTORA ERG SPA",
          style: "header",
        },
        {
          text: "77.029.089-9\nCONSTRUCCION DE EDIFICIOS PARA USO NO RESIDENCIAL\n30 ORIENTE 1528, TALCA, VII REGION DEL MAULE\n\n\n",
          style: "subheaderSB_Centro",
        },

        {
          style: "tableExample",
          table: {
            headerRows: 1,
            widths: ["*"],
            body: [
              [
                {
                  text: "Orden de Compra N° " + ordenERG.ordenCompra,
                  style: "titulo",
                  border: [false, false, false, true],
                },
              ],
            ],
          },
        },

        "\n",
        {
          columns: [
            // si estado es aprobado muestra imagen1, si estado es pendiente imagen2, si estado es rechazado imagen3
            {
              image:
                ordenERG.estado === "aprobado"
                  ? base64_1
                  : ordenERG.estado === "pendiente"
                  ? base64_2
                  : base64_3,
              alignment: "center",
              height: 60,
              width: 200,
            },

            // {
            //   image: "imagen1",
            //   alignment: "right",
            //   height: 60,
            //   width: 200,
            // },
            {
              style: "tableExample",

              table: {
                widths: ["*", "*", "*"],

                body: [
                  [
                    { text: "", style: "subheaderSB" },
                    { text: "Fecha Emisón:", style: "subheaderCB" },
                    { text: ordenERG.fechaEmision, style: "subheaderSB" },
                  ],

                  [
                    { text: "", style: "subheaderSB" },
                    { text: "Estado:", style: "subheaderCB" },
                    {
                      text: ordenERG.estado.toUpperCase(),
                      style: "subheaderSB",
                    },
                  ],
                  [
                    { text: "", style: "subheaderSB" },
                    { text: "Aprobado por:", style: "subheaderCB" },
                    { text: ordenERG.autorizado, style: "subheaderSB" },
                  ],
                  [
                    { text: "", style: "subheaderSB" },
                    { text: "Centro de Costo:", style: "subheaderCB" },
                    { text: ordenERG.centroCosto, style: "subheaderSB" },
                  ],
                  [
                    { text: "", style: "subheaderSB" },
                    { text: "Moneda:", style: "subheaderCB" },
                    { text: ordenERG.divisa, style: "subheaderSB" },
                  ],
                //   [
                //     { text: "", style: "subheaderSB" },
                //     { text: "Tipo de Gasto:", style: "subheaderCB" },
                //     { text: ordenERG.tipoGasto, style: "subheaderSB" },
                //   ],
                //   [
                //     { text: "", style: "subheaderSB" },
                //     { text: "Cuenta Softland:", style: "subheaderCB" },
                //     { text: ordenERG.descripcionCuenta, style: "subheaderSB" },
                //   ],
                //   [
                //     { text: "", style: "subheaderSB" },
                //     { text: "Codigo Softland:", style: "subheaderCB" },
                //     { text: ordenERG.codigoCuenta, style: "subheaderSB" },
                //   ],
                 ],
              },

              layout: "noBorders",
            },
          ],
        },
        // {
        //   style: "tableExample",

        //   table: {
        //     widths: ["*", "*", "*", "*", "*"],

        //     body: [
        //       [
        //         { text: "" },
        //         { text: "", style: "subheaderCB" },
        //         { text: "", style: "subheaderSB" },
        //         { text: "Fecha Emisón:", style: "subheaderCB" },
        //         { text: ordenERG.fechaEmision, style: "subheaderSB" },
        //       ],
        //       [
        //         { text: "" },
        //         { text: "", style: "subheaderCB" },
        //         { text: "", style: "subheaderSB" },
        //         { text: "Estado:", style: "subheaderCB" },
        //         {
        //           text: ordenERG.estado.capitalizeFirstLetter(),
        //           style: "subheaderSB",
        //         },
        //       ],
        //       [
        //         { text: "" },
        //         { text: "", style: "subheaderCB" },
        //         { text: "", style: "subheaderSB" },
        //         { text: "Aprobado por:", style: "subheaderCB" },
        //         { text: ordenERG.autorizado, style: "subheaderSB" },
        //       ],
        //       [
        //         { text: "" },
        //         { text: "", style: "subheaderCB" },
        //         { text: "", style: "subheaderSB" },
        //         { text: "Centro de Costo:", style: "subheaderCB" },
        //         { text: ordenERG.centroCosto, style: "subheaderSB" },
        //       ],
        //       [
        //         { text: "" },
        //         { text: "", style: "subheaderCB" },
        //         { text: "", style: "subheaderSB" },
        //         { text: "Moneda:", style: "subheaderCB" },
        //         { text: ordenERG.divisa, style: "subheaderSB" },
        //       ],
        //     ],
        //   },

        //   layout: "noBorders",
        // },
        "\n",

        {
          style: "tableExample",
          table: {
            widths: ["*"],
            body: [
              [
                {
                  style: "tableExample",
                  table: {
                    headerRows: 1,
                    widths: [10, 60, "*", 60, "*"],
                    body: [
                      [
                        { text: "" },
                        { text: "Razón Social:", style: "subheaderCB" },
                        { text: ordenERG.empresa, style: "subheaderSB" },
                        { text: "Rut:", style: "subheaderCB" },
                        { text: ordenERG.rut, style: "subheaderSB" },
                      ],
                      [
                        { text: "" },
                        { text: "Dirección:", style: "subheaderCB" },
                        { text: ordenERG.direccion, style: "subheaderSB" },
                        { text: "Teléfono:", style: "subheaderCB" },
                        { text: ordenERG.telefono, style: "subheaderSB" },
                      ],
                      [
                        { text: "" },
                        { text: "Comuna:", style: "subheaderCB" },
                        { text: ordenERG.comuna, style: "subheaderSB" },
                        { text: "Contacto:", style: "subheaderCB" },
                        { text: ordenERG.contacto, style: "subheaderSB" },
                      ],
                      [
                        { text: "" },
                        { text: "Forma de Pago:", style: "subheaderCB" },
                        { text: ordenERG.formaPago, style: "subheaderSB" },
                        { text: "Mail:", style: "subheaderCB" },
                        { text: ordenERG.correo, style: "subheaderSB" },
                      ],
                    ],
                  },
                  layout: "noBorders",
                },
              ],
            ],
          },
        },
        "\n",
        {
          style: "tableExample",
          table: {
            widths: [50, 50, 150, 50, "*", "*"],
            body: [
              [
                { text: "Cantidad", style: "subheaderCB", alignment: "center" },
                {
                  text: "Uni. Medida",
                  alignment: "center",
                  style: "subheaderCB",
                },
                {
                  text: "Descripción",
                  style: "subheaderCB",
                  alignment: "center",
                },
                {
                  text: "Centro Costo",
                  style: "subheaderCB",
                  alignment: "center",
                },
                {
                  text: "P. Unitario",
                  style: "subheaderCB",
                  alignment: "center",
                },
                {
                  text: "Valor Total",
                  style: "subheaderCB",
                  alignment: "center",
                },
              ],

              [
                {
                  text: ordenERG.cantidad_1,
                  style: "subheaderSB",
                  alignment: "center",
                },
                {
                  text: ordenERG.unidadMedida_1,
                  style: "subheaderSB",
                  alignment: "center",
                },
                {
                  text: ordenERG.descripcion_1,
                  style: "subheaderSB",
                  alignment: "center",
                },
                {
                  text: ordenERG.centroCosto_1,
                  style: "subheaderSB",
                  alignment: "center",
                },
                {
                  text: parseInt(ordenERG.precioUnitario_1.toString()).toCurrency({
                    symbol: "$",
                  }),
                  style: "subheaderSB_Derecha",
                },
                {
                  text: ordenERG.precioTotal_1.toCurrency({ symbol: "$" }),
                  style: "subheaderSB_Derecha",
                },
              ],

              ordenERG.precioTotal_2
                ? [
                    {
                      text: ordenERG.cantidad_2,
                      style: "subheaderSB",
                      alignment: "center",
                    },
                    {
                      text: ordenERG.unidadMedida_2,
                      style: "subheaderSB",
                      alignment: "center",
                    },
                    {
                      text: ordenERG.descripcion_2,
                      style: "subheaderSB",
                      alignment: "center",
                    },
                    {
                      text: ordenERG.centroCosto_2,
                      style: "subheaderSB",
                      alignment: "center",
                    },
                    {
                      text: parseInt(
                        ordenERG.precioUnitario_2.toString()
                      ).toCurrency({ symbol: "$" }),
                      style: "subheaderSB_Derecha",
                    },
                    {
                      text: ordenERG.precioTotal_2.toCurrency({ symbol: "$" }),
                      style: "subheaderSB_Derecha",
                    },
                  ]
                : [
                    {
                      text: "",
                      style: "subheaderSB",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB_Derecha",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB_Derecha",
                      border: [false, false, false, false],
                    },
                  ],

              ordenERG.precioTotal_3
                ? [
                    {
                      text: ordenERG.cantidad_3,
                      style: "subheaderSB",
                      alignment: "center",
                    },
                    {
                      text: ordenERG.unidadMedida_3,
                      style: "subheaderSB",
                      alignment: "center",
                    },
                    {
                      text: ordenERG.descripcion_3,
                      style: "subheaderSB",
                      alignment: "center",
                    },
                    {
                      text: ordenERG.centroCosto_3,
                      style: "subheaderSB",
                      alignment: "center",
                    },
                    {
                      text: parseInt(
                        ordenERG.precioUnitario_3.toString()
                      ).toCurrency({ symbol: "$" }),
                      style: "subheaderSB_Derecha",
                    },
                    {
                      text: ordenERG.precioTotal_3.toCurrency({ symbol: "$" }),
                      style: "subheaderSB_Derecha",
                    },
                  ]
                : [
                    {
                      text: "",
                      style: "subheaderSB",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB_Derecha",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB_Derecha",
                      border: [false, false, false, false],
                    },
                  ],

              ordenERG.precioTotal_4
                ? [
                    {
                      text: ordenERG.cantidad_4,
                      style: "subheaderSB",
                      alignment: "center",
                    },
                    {
                      text: ordenERG.unidadMedida_4,
                      style: "subheaderSB",
                      alignment: "center",
                    },
                    {
                      text: ordenERG.descripcion_4,
                      style: "subheaderSB",
                      alignment: "center",
                    },
                    {
                      text: ordenERG.centroCosto_4,
                      style: "subheaderSB",
                      alignment: "center",
                    },
                    {
                      text: parseInt(
                        ordenERG.precioUnitario_4.toString()
                      ).toCurrency({ symbol: "$" }),
                      style: "subheaderSB_Derecha",
                    },
                    {
                      text: ordenERG.precioTotal_4.toCurrency({ symbol: "$" }),
                      style: "subheaderSB_Derecha",
                    },
                  ]
                : [
                    {
                      text: "",
                      style: "subheaderSB",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB_Derecha",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB_Derecha",
                      border: [false, false, false, false],
                    },
                  ],

              ordenERG.precioTotal_5
                ? [
                    {
                      text: ordenERG.cantidad_5,
                      style: "subheaderSB",
                      alignment: "center",
                    },
                    {
                      text: ordenERG.unidadMedida_5,
                      style: "subheaderSB",
                      alignment: "center",
                    },
                    {
                      text: ordenERG.descripcion_5,
                      style: "subheaderSB",
                      alignment: "center",
                    },
                    {
                      text: ordenERG.centroCosto_5,
                      style: "subheaderSB",
                      alignment: "center",
                    },
                    {
                      text: parseInt(
                        ordenERG.precioUnitario_5.toString()
                      ).toCurrency({ symbol: "$" }),
                      style: "subheaderSB_Derecha",
                    },
                    {
                      text: ordenERG.precioTotal_5.toCurrency({ symbol: "$" }),
                      style: "subheaderSB_Derecha",
                    },
                  ]
                : [
                    {
                      text: "",
                      style: "subheaderSB",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB_Derecha",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB_Derecha",
                      border: [false, false, false, false],
                    },
                  ],
            ],
          },
        },
        "\n",

        {
          style: "tableExample",
          table: {
            widths: [50, 50, 150, 50, "*", "*"],
            body: [
              [
                {
                  text: "Observación:",
                  style: "subheaderCB",
                  border: [true, true, true, false],
                  colSpan: 3,
                },
                { text: "", style: "subheaderCB" },
                { text: "", style: "subheaderCB" },
                {
                  text: " ",
                  style: "subheaderCB",
                  border: [false, false, false, false],
                },
                { text: "Sub Total", style: "subheaderCB" },
                {
                  text: ordenERG.subTotal.toCurrency({ symbol: "$" }),
                  style: "subheaderSB_Derecha",
                },
              ],
              [
                {
                  text: ordenERG.observaciones,
                  style: "subheaderSB",
                  border: [true, false, true, true],
                  rowSpan: 5,
                  colSpan: 3,
                },
                "",
                "",
                {
                  text: " ",
                  style: "subheaderCB",
                  border: [false, false, false, false],
                },
                { text: "Descuento 1", style: "subheaderCB" },
                {
                  text: ordenERG.descuento.toCurrency({ symbol: "$" }),
                  style: "subheaderSB_Derecha",
                },
              ],
              [
                "",
                "",
                "",
                {
                  text: " ",
                  style: "subheaderCB",
                  border: [false, false, false, false],
                },
                { text: "Afecto IVA", style: "subheaderCB" },
                {
                  text: ordenERG.afectoIVA.toCurrency({ symbol: "$" }),
                  style: "subheaderSB_Derecha",
                },
              ],
              [
                "",
                "",
                "",
                {
                  text: " ",
                  style: "subheaderCB",
                  border: [false, false, false, false],
                },
                { text: "Excento IVA", style: "subheaderCB" },
                {
                  text: ordenERG.exentoIVA.toCurrency({ symbol: "$" }),
                  style: "subheaderSB_Derecha",
                },
              ],
              [
                "",
                "",
                "",
                {
                  text: " ",
                  style: "subheaderCB",
                  border: [false, false, false, false],
                },
                { text: "Sub Total Neto", style: "subheaderCB" },
                {
                  text: ordenERG.subTotalNeto.toCurrency({ symbol: "$" }),
                  style: "subheaderSB_Derecha",
                },
              ],
              [
                "",
                "",
                "",
                {
                  text: " ",
                  style: "subheaderCB",
                  border: [false, false, false, false],
                },
                { text: "IVA", style: "subheaderCB" },
                {
                  text: ordenERG.IVA.toCurrency({ symbol: "$" }),
                  style: "subheaderSB_Derecha",
                },
              ],
              [
                {
                  text: " ",
                  style: "subheaderCB",
                  border: [false, false, false, false],
                },
                {
                  text: " ",
                  style: "subheaderCB",
                  border: [false, false, false, false],
                },
                {
                  text: " ",
                  style: "subheaderCB",
                  border: [false, false, false, false],
                },
                {
                  text: " ",
                  style: "subheaderCB",
                  border: [false, false, false, false],
                },

                { text: "Total", style: "subheaderCB" },
                {
                  text: ordenERG.total.toCurrency({ symbol: "$" }),
                  style: "subheaderSB_Derecha",
                },
              ],
            ],
          },
        },

        "\n",
        {
          style: "tableExample",
          table: {
            headerRows: 1,
            widths: ["*"],
            body: [
              [
                {
                  text: "\nDatos de Transferencia",
                  style: "subheaderCB",
                  border: [false, true, false, false],
                },
              ],
            ],
          },
        },
        "\n",
        {
          style: "tableExample",
          table: {
            widths: ["*"],
            body: [
              [
                {
                  style: "tableExample",
                  table: {
                    headerRows: 1,
                    widths: [10, 60, "*", 60, "*"],
                    body: [
                      [
                        { text: "" },
                        { text: "Destinatario:", style: "subheaderCB" },
                        { text: ordenERG.destinatario, style: "subheaderSB" },
                        { text: "Rut:", style: "subheaderCB" },
                        { text: ordenERG.rutDestinatario, style: "subheaderSB" },
                      ],
                      [
                        { text: "" },
                        { text: "Banco:", style: "subheaderCB" },
                        { text: ordenERG.banco, style: "subheaderSB" },
                        { text: "Tipo de Cuenta:", style: "subheaderCB" },
                        { text: ordenERG.tipoCueta, style: "subheaderSB" },
                      ],
                      [
                        { text: "" },
                        { text: "N° Cuenta:", style: "subheaderCB" },
                        { text: ordenERG.nroCuenta, style: "subheaderSB" },
                        { text: "Email:", style: "subheaderCB" },
                        {
                          text: ordenERG.correoDestinatario,
                          style: "subheaderSB",
                        },
                      ],
                    ],
                  },
                  layout: "noBorders",
                },
              ],
            ],
          },
        },
        "\n",
        {
          text: "NOTA:",
          style: "subheaderCB",
          //border: [false, false, false, false],
          //colSpan: 3,
        },
        {
          text: "La orden NO será validada si no se encuentra en estado APROBADA.",
          style: "subheaderSB",
          //border: [false, false, false, false],
          //colSpan: 3,
        },
        "\n",

        {
          text: "Para efectos de envío de Facturas y XML, realizar a la cuenta algo@erg.cl. Las facturas emitidas deben tener en referencias el número de orden de compra.",
          style: ["quote", "small"],
        },
      ],
      images: {
        logo: base64,
        imagen1: base64_1,
        imagen2: base64_2,
        imagen3: base64_3,
      },
      styles: {
        titulo: {
          fontSize: 12,
          bold: true,
          alignment: "center",
        },
        header: {
          fontSize: 10,
          bold: true,
          alignment: "center",
        },
        subheader: {
          fontSize: 15,
          bold: true,
        },
        subheaderSB: {
          fontSize: 8,
          bold: false,
          alignment: "left",
        },
        subheaderSB_Centro: {
          fontSize: 8,
          bold: false,
          alignment: "center",
        },
        subheaderSB_Derecha: {
          fontSize: 8,
          bold: false,
          alignment: "right",
        },
        subheaderCB: {
          fontSize: 8,
          bold: true,
          //aligment: "center",
        },
        quote: {
          italics: true,
        },
        small: {
          fontSize: 8,
        },
      },
    };

    pdfMake.createPdf(dd).download(`Orden de Compra ${ordenERG.ordenCompra} ERG.pdf`);
  }
}
