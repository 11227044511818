














import {Component, Prop, Vue} from "vue-property-decorator";
import SVGIcon from "@/components/SVGIcon.vue";
import {Invitado} from "@/typings/store/plugins/easyFirestore/cursos";
import FormularioInvitado from "@/components/cursos/invitados/Formulario.vue";

@Component({
  components: {
    FormularioInvitado,
    SVGIcon
  }
})
export default class DialogoInvitados extends Vue {
  @Prop() readonly invitadosCurso!: Invitado[];

  created(): void {
    this.invitados = this.invitadosCurso.slice();
  }

  options = {
    itemsPerPage: -1,
  };
  headerProps = {
    sortIcon: "mdi-chevron-down"
  }

  headers= [
    {text: "RUT", value: "rut"},
    {text: "Nombre", value: "nombre"},
    {text: "Profesión", value: "profesion"},
    {text: "Telefono", value: "telefono"},
  ]
  invitados: Invitado[] = [];


}
