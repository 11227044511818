





































































































// decoradores
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { DistribuidorEnergia } from "@/typings/store/plugins/easyFirestore/distribuidoresEnergia";
import SVGIcon from "@/components/SVGIcon.vue";
// tipos

// tipos
@Component({
  components: {
    SVGIcon,
  },
})
export default class InfoDistribuidorEnergia extends Vue {
  @Prop() readonly distribuidorEnergia!: DistribuidorEnergia;
  @Prop({ type: Boolean }) readonly showTitulo!: boolean;

  get id(): string {
    return this.distribuidorEnergia.id || "N/R";
  }

  get empresaDecretoTarifario(): string {
    return this.distribuidorEnergia.empresaDecretoTarifario || "N/R";
  }

  get comuna(): string {
    return this.distribuidorEnergia.comuna || "N/R";
  }

  get sectorTarifario(): string {
    return this.distribuidorEnergia.sectorTarifario || "N/R";
  }

  get aereoOsubterraneo(): string {
    return this.distribuidorEnergia.aereoOsubterraneo || "N/R";
  }

  @Emit("cerrar")
  onCerrar(): void {
    return;
  }
}
