

















































































































































































// decoradores
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
// tipos
import { HeadersDataTable } from "@/typings/vuetify/vDataTable";
import { CajaChicaTalca } from "@/typings/store/plugins/easyFirestore/cajaChicasTalca";
//import { Usuario } from "@/typings/store/plugins/easyFirestore/usuarios";
import { ItemsTablaCajaChicasTalca } from "@/typings/components/cajaChicasTalca/tabla";
import "@/helpers/extensions";

@Component
export default class TablaCajaChicasTalca extends Vue {
  @Prop() readonly opciones!: boolean;
  @Prop() readonly search!: string;
  @Prop() readonly cajaChicasTalca!: Array<CajaChicaTalca>;
  @Prop() readonly usuario!: string;

  options = {
    itemsPerPage: -1,
  };
  headerProps = {
    sortIcon: "mdi-chevron-down",
  };

  get headers(): HeadersDataTable {
    return [
      //{text: "", value: "estado", sortable: false},
      { text: "N°", value: "numero" },
      { text: "Fecha", value: "fecha" },
      { text: "Rut Prov", value: "rutProveedor" },
      { text: "Nombre Prov", value: "nombreProveedor" },
      { text: "Acción", value: "accion" },
      { text: "Ingresos", value: "ingreso" },
      { text: "Egresos", value: "egreso" },
      { text: "Documento", value: "documento" },
      { text: "Concepto", value: "concepto" },
      { text: "Descripcion", value: "descripcion" },
      { text: "", value: "opciones", sortable: false },
    ];
  }

  // get verificaUsuario(): string {
  //   if (this.usuario === "admin") return this.usuario;
    
  //   else return "";
  // }

  get items(): ItemsTablaCajaChicasTalca {
    return this.cajaChicasTalca.map((cajaChicaTalca) => {
      const numero = cajaChicaTalca.numero;
      // const nombre = cajaChicaTalca.nombre;
      // const posicion = cajaChicaTalca.posicion;
      const fecha = cajaChicaTalca.fecha;
      const saldoActual = cajaChicaTalca.saldoActual;
      const rutProveedor = cajaChicaTalca.rutProveedor;
      const nombreProveedor = cajaChicaTalca.nombreProveedor;
      const accion = cajaChicaTalca.accion;
      const ingreso = (
        parseInt(cajaChicaTalca.ingreso, 10).toCurrency({ symbol: "$" }) ?? 0
      ).toString();
      const egreso = (
        parseInt(cajaChicaTalca.egreso, 10).toCurrency({ symbol: "$" }) ?? 0
      ).toString();
      const documento = cajaChicaTalca.documento;
      const concepto = cajaChicaTalca.concepto;
      const descripcion = cajaChicaTalca.descripcion;

      return {
        //nombre,
        //posicion,
        numero,
        fecha,
        saldoActual,
        rutProveedor,
        nombreProveedor,
        accion,
        ingreso,
        egreso,
        documento,
        concepto,
        descripcion,

        data: cajaChicaTalca,
      };
    });
  }

  //   actualizarEstado(item: Usuario): void {
  //     let estado: EstadoUsuario;
  //     item.estado === 'libre' ? estado = 'bloqueado' : estado = 'libre';
  //     item.estado = estado;
  //     this.onCambiarEstado(item);
  //   }

  //   @Emit("cambiar-estado")
  //   onCambiarEstado(usuario: Usuario): Usuario {
  //     return usuario;
  //   }

  @Emit("abrir-info")
  onAbrirInfo(cajaChicaTalca: CajaChicaTalca): CajaChicaTalca {
    return cajaChicaTalca;
  }

  @Emit("abrir-editar")
  onAbrirEditar(cajaChicaTalca: CajaChicaTalca): CajaChicaTalca {
    return cajaChicaTalca;
  }

  @Emit("abrir-eliminar")
  onAbrirEliminar(cajaChicaTalca: CajaChicaTalca): CajaChicaTalca {
    return cajaChicaTalca;
  }
}
