


























































import { Component, Emit, Prop, Vue } from "vue-property-decorator";

import { idtify } from "@/helpers/string";
import { Prenomina } from "@/typings/store/plugins/easyFirestore/prenominas";
import { CentroCosto } from "@/typings/store/plugins/easyFirestore/centrosCostos";
import { Banco } from "@/typings/store/plugins/easyFirestore/bancos";
import { Proveedor } from "@/typings/store/plugins/easyFirestore/proveedores";
import { TipoCargo } from "@/typings/store/plugins/easyFirestore/tipoCargos";
import { MapeoGasto } from "@/typings/store/plugins/easyFirestore/MapeoGastos";
import { Egresos } from "@/typings/store/plugins/easyFirestore/registrosEgresos";

// validaciones
import {
  // email,
  required,
} from "vuelidate/lib/validators";
import { DataFormularioPrenomina } from "@/typings/components/prenominas/formulario";
import SVGIcon from "@/components/SVGIcon.vue";
import { Archivo, Archivos } from "@/typings/store/modules/archivos";
import VistaPreviaPdf from "@/components/VistaPreviaPdf.vue";
//import usuarios from "@/router/usuarios";
import { Usuario } from "@/typings/store/plugins/easyFirestore/usuarios";
//import proveedores from "@/router/proveedores";

@Component({
  components: {
    SVGIcon,
    VistaPreviaPdf,
  },
  validations: {
    centroCosto: { required },
    numeroPrenominas: { required },
    rut: { required },
    nombreProveedor: { required },
    nFactura: { required },
    ordenCompra: { required },
    montoOC: { required },
    montoAP: { required },
    porcentajePagado: { required },

    formaPago: { required },
    tipoGasto: { required },
    //periodo: { required },

    // observacion: { required },
  },
})
export default class FormularioPrenomina extends Vue {
  @Prop() readonly prenomina!: Prenomina | null;
  @Prop() readonly usuario!: Usuario | null;

  @Prop() readonly cargando!: boolean;
  @Prop() readonly centrosCostos!: CentroCosto[];
  @Prop() readonly bancos!: Banco[];
  @Prop() readonly proveedores!: Proveedor[];
  @Prop() readonly tipoCargos!: TipoCargo[];
  @Prop() readonly mapeoGastos!: MapeoGasto[];
  @Prop() readonly registrosEgresos!: Egresos[];
  @Prop() readonly arrayPrenominas!: Prenomina[];

  //   @Prop() readonly rutas!: Rutas;
  //   @Prop() readonly ciudades!: Ciudad[];
  //   @Prop() readonly cargando!: boolean;
  //   @Prop() readonly regiones!: Region[];

  created(): void {
    // Object.keys(this.rutas).forEach((key) => {
    //   this.$set(this.permisos, key, false);
    // });
    // console.log(this.permisos);
    const prenomina = this.prenomina;
    if (!prenomina) {
      return;
    }
    this.id = prenomina.id ?? 0;
    this.numeroPrenominas = prenomina.numeroPrenominas ?? 0;
    this.rut = prenomina.rut ?? "";
    this.nombreProveedor = prenomina.nombreProveedor ?? "";
    this.nFactura = prenomina.nFactura ?? "";
    this.ordenCompra = prenomina.ordenCompra ?? "";
    this.centroCosto = prenomina.centroCosto;
    // this.montoOC = parseInt(prenomina.montoOC).toCurrency({symbol: '$'});
    this.montoOC = prenomina.montoOC;
    this.porcentajePagado = prenomina.porcentajePagado ?? "";
    this.montoSaldo = prenomina.montoSaldo;
    this.montoAP = prenomina.montoAP;
    //     this.montoSaldo = parseInt(prenomina.montoSaldo).toCurrency({symbol: '$'});
    // this.montoAP = parseInt(prenomina.montoAP).toCurrency({symbol: '$'});
    this.archivo = Object.values(prenomina.archivo ?? {})[0] ?? null;
    this.observacion = prenomina.observacion ?? "";
    this.formaPago = prenomina.formaPago ?? "";
    this.numeroCheque = prenomina.numeroCheque ?? "";
    this.tipoGasto = prenomina.tipoGasto;
    this.banco = prenomina.banco ?? "";
    this.periodo = prenomina.periodo.toDate().format("YYYY-MM-DD") ?? "";
    // this.nombre = prenomina.nombre;
    // this.posicion = prenomina.posicion ?? "";
    this.recurrente = prenomina.recurrente ?? "";
    this.tipoRecurrencia = prenomina.tipoRecurrencia ?? "";
    this.periodoRecurrencia = prenomina.periodoRecurrencia ?? "";
    if (prenomina.periodoRecurrencia) {
      this.recurrenteMes = prenomina.periodoRecurrencia.substr(3, 2) ?? "";
      this.recurrenteAnual = prenomina.periodoRecurrencia.substr(6) ?? "";
    }
    this.color = prenomina.color ?? "";
    this.fechaVencimiento =
      prenomina.fechaVencimiento.toDate().format("YYYY-MM-DD") ?? "";
    this.comentario = prenomina.comentario ?? "";
    this.emisorComentario = prenomina.emisorComentario ?? "";
    
    
  }

  options = {
    locale: "es-CL",
    prefix: "$",
    suffix: "",
    length: 18,
    precision: 0,
  };

  optionsPorc = {
    locale: "es-CL",
    prefix: "",
    suffix: "%",
    length: 3,
    precision: 0,
  };

  // formatCurrency(number:string) {
  //       let numero = 0;
  //       numero = parseInt(number)
  //       return numero.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // }

  periodo = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
    .toISOString()
    .substr(0, 10);
  fechaVencimiento = new Date(
    Date.now() - new Date().getTimezoneOffset() * 60000
  )
    .toISOString()
    .substr(0, 10);
  vistaPrevia = {
    model: false,
    url: "",
  };
  id: number | null = null;
  modificado = false;
  
  estado: "aprobado" | "pendiente" = "pendiente";
  fechaAprobacion = "";
  numeroPrenominas = 0;
  ingresada = false;
  rut = "";
  nombreProveedor = "";
  nFactura = "";
  ordenCompra = "";
  formaPago = "";
  centroCosto = "";
  montoOC = "";
  porcentajePagado = "";
  montoSaldo = "";
  montoAP = "";
  archivo?: Archivo | null = null;
  archivosBorrar: Archivos = {};
  observacion = "";
  numeroCheque = "";
  banco = "";
  tipoGasto = "";
  recurrente = "";
  recurrenteMes = "";
  autorizado = "";
  color = "#D5D8E3";

  comentario = "";
  emisorComentario = "";

  get emisor ():string { 
    let emisorC = ""
    if(this.usuario?.nombre)
      emisorC = this.usuario.nombre
    else
      emisorC=""
    
    return emisorC
  }

  // recurrenteTrimestre = "";
  // recurrenteSemestre = "";
  recurrenteAnual = "";
  tipoRecurrencia = "";
  periodoRecurrencia = "";
  motivoRechazo = "";
  itemRecurrente = ["SI", "NO"];
  itemFormaRecurrencia = ["MENSUAL", "SEMESTRAL", "TRIMESTRAL", "ANUAL"];
  itemFormaRecurrenciaMes = [
    "12",
    "11",
    "10",
    "09",
    "08",
    "07",
    "06",
    "05",
    "04",
    "03",
    "02",
    "01",
  ];
  itemFormaRecurrenciaTrimestre = ["04", "03", "02", "01"];
  itemFormaRecurrenciaSemestre = ["02", "01"];
  itemFormaRecurrenciaAnual = ["2022", "2021", "2020"];
  //registrosEgresos: RegistrosEgresos | null = null; //region: Region | null = null;

  get datoRecurrencia(): string {
    let campo = "";
    let dia = "01";
    let mes = "";
    let año = "";
    if (this.recurrente === "SI") {
      if (
        this.tipoRecurrencia === "MENSUAL" ||
        this.tipoRecurrencia === "SEMESTRAL" ||
        this.tipoRecurrencia === "TRIMESTRAL"
      ) {
        mes = this.recurrenteMes;
        año = this.recurrenteAnual;
      }
      if (this.tipoRecurrencia === "ANUAL") {
        mes = "01";
        año = this.recurrenteAnual;
      }
      campo = dia + "-" + mes + "-" + año;
    }
    return campo;
  }

  get itemsRecurrencia(): Array<string> {
    let dato = "";
    let items = [dato];
    if (this.tipoRecurrencia === "MENSUAL") {
      return this.itemFormaRecurrenciaMes;
      // mes = this.recurrenteMes;
      // año = this.recurrenteAnual;
    }
    if (this.tipoRecurrencia === "SEMESTRAL") {
      return this.itemFormaRecurrenciaSemestre;
      // mes = this.recurrenteSemestre;
      // año = this.recurrenteAnual;
    }
    if (this.tipoRecurrencia === "TRIMESTRAL") {
      return this.itemFormaRecurrenciaTrimestre;
      // mes = this.recurrenteTrimestre;
      // año = this.recurrenteAnual;
    }
    return items;
  }

  forma_Pago = ["CHEQUE", "NOMINA"];
  menu2 = false;
  menu3 = false;
  // periodo = this.fechaPeriodoF;
  //muestraNombreProveedor = this.itemNombreProveedores;

  get fechaPeriodoF(): string {
    let array_fecha = this.periodo.split("-");
    let dia = "01";
    let mes = array_fecha[1];
    let ano = array_fecha[0];
    console.log(array_fecha);
    this.periodo = dia + "-" + mes + "-" + ano;
    return dia + "-" + mes + "-" + ano;
  }

  get fechaVencimientoF(): string {
    let array_fecha = this.fechaVencimiento.split("-");
    let dia = array_fecha[2];
    let mes = array_fecha[1];
    let ano = array_fecha[0];
    console.log(array_fecha);
    this.fechaVencimiento = dia + "-" + mes + "-" + ano;
    return dia + "-" + mes + "-" + ano;
  }

  get nombreArchivo(): string {
    return this.archivo?.file?.name ?? this.archivo?.nombre ?? "";
  }

  get titulo(): string {
    return this.prenomina ? "editar comentario" : "agregar comentario";
  }

  get estados(): Array<{ text: string; value: string }> {
    return [
      { text: "Aceptado", value: "aprobado" },
      { text: "Pendiente", value: "pendiente" },
    ];
  }

  get itemCentrosCostos(): Array<string> {
    const itemCentroCosto: Array<string> = [];
    this.centrosCostos.forEach((centroCosto) => {
      itemCentroCosto.push(centroCosto.Desc_formato);
    });

    return itemCentroCosto.sort((sesionA, sesionB) => {
      return parseInt(sesionA) - parseInt(sesionB);
    });
  }

  get verificaFacturasRepetidas(): boolean {
    const itemFactura: Array<string> = [];
    let repetida = false;
    //let pagada = "";
    if (this.rut) {
      this.registrosEgresos.forEach((factura) => {
        // if(factura.PAGADO.toString() === "SI" ||
        // factura.PAGADO.toString() === ""
        // ){console.log("entro");}
        if (factura.RUT_PROVEEDOR.toString() === this.rut) {
          if (
            factura.FAC_DOC_NOTA.toString() !== "0" ||
            factura.FAC_DOC_NOTA.toString() !== "N/A"
          ) {
            itemFactura.push(factura.FAC_DOC_NOTA.toString());
            // pagada = factura.PAGADO.toString();
          }
        }
      });
    }
    // si un valor de itemFactura es igual a this.nfactura, significa que hay repetidos
    if (itemFactura.includes(this.nFactura)) {
      //  if (pagada === "SI") {
      repetida = true;
      //  }
    } else {
      repetida = false;
    }
    return repetida;
  }

  get verificaExistenciaPrenomina(): boolean {
    const itemFacturaPrenomina: Array<string> = [];
    let estaEnPrenomina = false;
    if (this.rut) {
      this.arrayPrenominas.forEach((factura) => {
        if (factura.rut.toString() === this.rut) {
          if (
            factura.nFactura.toString() !== "0" ||
            factura.nFactura.toString() !== "N/A"
          ) {
            itemFacturaPrenomina.push(factura.nFactura.toString());
          }
        }
      });
    }
    // si un valor de itemFacturaPrenomina es igual a this.nfactura, significa que hay repetidos
    if (
      itemFacturaPrenomina.includes(this.nFactura)
      //||
      // this.tipoGasto === "DEVOLUCIÓN DINEROS"
    ) {
      estaEnPrenomina = true;
      //pero no tiene que estar en egreso
    } else {
      estaEnPrenomina = false;
    }
    return estaEnPrenomina;
  }

  get itemRutProveedores(): Array<string> {
    const itemRutProveedor: Array<string> = [];
    this.mapeoGastos.forEach((mapeoGasto) => {
      if (mapeoGasto.centroCosto === this.centroCosto) {
        itemRutProveedor.push(mapeoGasto.rut);
      }
    });
    return itemRutProveedor.sort();
  }

  get itemNombreProveedores(): string | undefined {
    let itemNombreProveedor = "";
    this.mapeoGastos.forEach((proveedor) => {
      if (
        proveedor.rut === this.rut &&
        proveedor.centroCosto === this.centroCosto
      ) {
        itemNombreProveedor = proveedor.nombreProveedor;
        this.nombreProveedor = itemNombreProveedor;
      }
    });
    return itemNombreProveedor;
  }

  get itemTipoGastos(): Array<string> {
    const itemTipoGasto: Array<string> = [];
    this.mapeoGastos.forEach((mapeoGasto) => {
      if (
        mapeoGasto.centroCosto === this.centroCosto &&
        mapeoGasto.rut === this.rut
      ) {
        itemTipoGasto.push(mapeoGasto.tipoGasto);
      }
    });
    return itemTipoGasto.sort();
  }

  get itemBancos(): Array<string> {
    const itemBanco: Array<string> = [];
    this.bancos.forEach((banco) => {
      itemBanco.push(banco.nombre.toUpperCase());
    });
    return itemBanco;
  }

  get rutError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.rut.$dirty) return errors;
    if (!this.$v.rut.required) errors.push("Completar datos obligatorios");
    return errors;
  }

  get centroCostoError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.centroCosto.$dirty) return errors;
    if (!this.$v.centroCosto.required) errors.push("Campo requerido");
    return errors;
  }

  get nombreProveedorError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.nombreProveedor.$dirty) return errors;
    if (!this.$v.nombreProveedor.required)
      errors.push("Completar datos obligatorios");
    return errors;
  }

  get nFacturaError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.nFactura.$dirty) return errors;
    if (!this.$v.nFactura.required) errors.push("Completar datos obligatorios");
    if (this.verificaFacturasRepetidas)
      errors.push("El número de factura está Conciliado");

    if (this.verificaExistenciaPrenomina)
      errors.push("El número de factura está en Prenomina o Nomina");

    return errors;
  }

  get ordenCompraError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.ordenCompra.$dirty) return errors;
    if (!this.$v.ordenCompra.required)
      errors.push("Completar datos obligatorios");
    return errors;
  }

  get montoOCError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.montoOC.$dirty) return errors;
    if (!this.$v.montoOC.required) errors.push("Completar datos obligatorios");
    return errors;
  }

  get montoAPError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.montoAP.$dirty) return errors;
    if (!this.$v.montoAP.required) errors.push("Completar datos obligatorios");
    return errors;
  }

  get porcentajePagadoError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.porcentajePagado.$dirty) return errors;
    if (!this.$v.porcentajePagado.required)
      errors.push("Completar datos obligatorios");
    return errors;
  }

  get porcentajeSaldoError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.montoSaldo.$dirty) return errors;
    if (!this.$v.montoSaldo.required)
      errors.push("Completar datos obligatorios");
    return errors;
  }

  get numeroPrenominasError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.numeroPrenominas.$dirty) return errors;
    if (!this.$v.numeroPrenominas.required)
      errors.push("Completar datos obligatorios");
    return errors;
  }

  get formaPagoError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.formaPago.$dirty) return errors;
    if (!this.$v.formaPago.required)
      errors.push("Completar datos obligatorios");
    return errors;
  }

  get tipoGastoError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.tipoGasto.$dirty) return errors;
    if (!this.$v.tipoGasto.required) errors.push("Campo requerido");
    return errors;
  }
  get periodoerror(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.periodo.$dirty) return errors;
    if (!this.$v.periodo.required) errors.push("Campo requerido");
    return errors;
  }

  @Emit("close")
  onClose(): null {
    return null;
  }

  @Emit("cerrar-dialogo")
  onCerrarDialogo(): void {
    return;
  }

  @Emit("click-guardar")
  onClickGuardar(data: DataFormularioPrenomina): DataFormularioPrenomina {
    return data;
  }

  cargarArchivo(ev: Event): void {
    const input = ev.target as HTMLInputElement;
    const file = input?.files ? input.files[0] : null;
    if (file) {
      if (file.type.includes("pdf")) {
        console.log("vas aqui");
        const fecha = new Date();
        const extencion = file.name.split(".").pop() ?? "";
        const id = `prenominas/${
          this.rut
        }/archivo/${fecha.valueOf()}.${extencion}`;
        this.archivo = {
          id: id,
          nombre: file.name,
          file: file,
        };
      }
    }
  }

  clickInputArchivo(): void {
    const input = this.$refs.inputArchivo as HTMLInputElement;
    input.click();
  }

  abrirVistaPrevia(archivo: Archivo): void {
    this.vistaPrevia = {
      model: true,
      url: this.urlArchivo(archivo),
    };
  }

  cerrarVistaPrevia(): void {
    this.vistaPrevia = {
      model: false,
      url: "",
    };
  }

  eliminarArchivo(): void {
    if (this.prenomina && this.archivo?.id) {
      this.archivosBorrar[this.archivo.id] = this.archivo;
    }
    this.archivo = null;
  }

  urlArchivo(media: Archivo): string {
    return media.url ?? URL.createObjectURL(media.file);
  }

  guardar(): void {
    this.$v.$touch();

    if (this.$v.$invalid) {
      return;
    }
    const doc: Prenomina = {
      centroCosto: this.centroCosto.toUpperCase().replace(",", "|"),
      modificado: this.modificado,
      numeroPrenominas: this.numeroPrenominas,
      ingresada: this.ingresada,
      estado: this.estado,
      rut: this.rut.toUpperCase(),
      nombreProveedor: this.nombreProveedor.toUpperCase(),
      nFactura: this.nFactura.toUpperCase(),
      //tipoCambio: this.tipoCambio.toUpperCase(),
      ordenCompra: this.ordenCompra.toUpperCase(),
      montoOC: this.montoOC.toUpperCase(),
      porcentajePagado: this.porcentajePagado.toUpperCase(),
      montoSaldo: this.montoSaldo.toUpperCase(),
      montoAP: this.montoAP.toUpperCase(),
      // archivo?: Archivos,
      observacion: this.observacion.toUpperCase(),
      formaPago: this.formaPago.toUpperCase(),
      numeroCheque: this.numeroCheque.toUpperCase(),
      banco: this.banco.toUpperCase(),
      periodo: this.fechaPeriodoF,
      fechaAprobacion: this.fechaAprobacion,
      tipoGasto: this.tipoGasto,
      recurrente: this.recurrente,
      tipoRecurrencia: this.tipoRecurrencia,
      periodoRecurrencia: this.datoRecurrencia,
      autorizado: this.autorizado,
      motivoRechazo: this.motivoRechazo,
      color: "#D5D8E3",
      pasoNomina: "",
      fechaVencimiento: this.fechaVencimientoF,
      comentario: this.comentario.toUpperCase(),
      emisorComentario: this.emisor.toUpperCase(),
    };

    if (this.id) {
      doc.id = this.id;
    }

    const data: DataFormularioPrenomina = {
      doc: doc,
      borrar: this.archivosBorrar,
      archivo: {},
    };

    if (this.archivo) {
      data.archivo = {
        [idtify(this.archivo.id)]: this.archivo,
      };
    }

    this.onClickGuardar(data);
  }
}
