import { RouteConfig } from "vue-router";
import Contratos from "@/views/Contratos.vue";

const contratos: RouteConfig = {
    path: "/contratos",
    name: "contratos",
    component: Contratos,
};

export default contratos;
