import { RouteConfig } from "vue-router";
import Locales from "@/views/Locales.vue";

const locales: RouteConfig = {
    path: "/locales",
    name: "locales",
    component: Locales,
};

export default locales;
