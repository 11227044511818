






































































































































































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
// helpers
//import { idtify } from "@/helpers/string";
// tipos
//import { Rutas } from "@/typings/store/modules/rutas";
//import {
//  Permisos,
//  TipoUsuario,
//  Usuario,
//} from "@/typings/store/plugins/easyFirestore/usuarios";
import {
  Cliente,
  //Clientes
} from "@/typings/store/plugins/easyFirestore/clientes";
// validaciones
import {
  // email,
  required,
} from "vuelidate/lib/validators";
import { DataFormularioCliente } from "@/typings/components/clientes/formulario";
import SVGIcon from "@/components/SVGIcon.vue";
//import { Archivo, Archivos } from "@/typings/store/modules/archivos";

@Component({
  components: {
    SVGIcon,
  },
  validations: {
    codigo: {
      required,
    }, 
    nombre: { required },
    rut: { required },
  },
})
export default class FormularioCliente extends Vue {
  @Prop() readonly cliente!: Cliente | null;
  @Prop() readonly cargando!: boolean;
  //   @Prop() readonly rutas!: Rutas;
  //   @Prop() readonly ciudades!: Ciudad[];
  //   @Prop() readonly cargando!: boolean;
  //   @Prop() readonly regiones!: Region[];

  created(): void {
    // Object.keys(this.rutas).forEach((key) => {
    //   this.$set(this.permisos, key, false);
    // });
    // console.log(this.permisos);
    const cliente = this.cliente;
    if (!cliente) {
      return;
    }
    this.id = cliente.id ?? "";
    this.codigo = cliente.codigo ?? "";
    this.nombre = cliente.nombre;
    this.rut = cliente.rut ?? "";
    this.giro = cliente.giro ?? "";
    this.telefono = cliente.telefono ?? "";
    this.correo = cliente.correo ?? "";
  }

  id: string | null = null;
  codigo: string | null = null;
  nombre = "";
  rut = "";
  giro = "";
  telefono = "";
  correo = "";

  get titulo(): string {
    return this.cliente ? "editar cliente" : "nuevo cliente";
  }

  //   get permisosKey(): Array<string> {
  //     // return Object.keys(this.permisos).filter((key) => {
  //     //   if(this.rutas[key].tipos.includes(this.tipo)){
  //     //     this.permisos[key] = true;
  //     //   }
  //     // });
  //     return Object.keys(this.permisos).filter((key) => {
  //       if (!this.rutas[key]) {
  //         return false;
  //       }
  //       const tipo = this.rutas[key].tipos.includes(this.tipo);
  //       return tipo;
  //     });
  //   }

  get codigoError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.codigo.$dirty) return errors;
    if (!this.$v.codigo.required) errors.push("Completar datos obligatorios");
    return errors;
  }

  get nombreError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.nombre.$dirty) return errors;
    if (!this.$v.nombre.required) errors.push("Completar datos obligatorios");
    return errors;
  }

  get rutError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.rut.$dirty) return errors;
    if (!this.$v.rut.required) errors.push("Completar datos obligatorios");
    return errors;
  }

  @Emit("close")
  onClose(): null {
    return null;
  }

  @Emit("cerrar-dialogo")
  onCerrarDialogo(): void {
    return;
  }

  @Emit("click-guardar")
  onClickGuardar(data: DataFormularioCliente): DataFormularioCliente {
    return data;
  }

  //   @Watch("permisosKey", { immediate: true })
  //   onTipoChange(): void {
  //     console.log("a");
  //     for (const key of Object.keys(this.permisos)) {
  //       if (this.permisos[key]) {
  //         this.permisos[key] = false;
  //       }
  //     }
  //     this.permisosKey.forEach((key) => {
  //       this.permisos[key] = true;
  //     });
  //   }

  //   nombrePermiso(key: string): string {
  //     return this.rutas[key].text;
  //   }

  guardar(): void {
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }
    const doc: Cliente = {
      codigo: this.codigo || "",
      nombre: this.nombre,
      rut: this.rut,
      giro: this.giro,
      telefono: this.telefono,
      correo: this.correo,
    };
    if (this.id) {
      doc.id = this.id;
    }
    const data: DataFormularioCliente = {
      doc: doc,
    };
    this.onClickGuardar(data);
  }
}
