














































































































































// decoradores
import { Vue, Component } from "vue-property-decorator";
// vuex
import { mapActions, mapGetters } from "vuex";
// tipos

// componentes
import TablaPromociones from "@/components/promociones/Tabla.vue";
import FormularioPromocion from "@/components/promociones/Formulario.vue";
import InfoPromocion from "@/components/promociones/Info.vue";
import BotonesCrud from "@/components/BotonesCrud.vue";
import MensajeEliminar from "@/components/MensajeEliminar.vue";
import TituloVista from "@/components/TituloVista.vue";
import {
  Promocion,
  Promociones,
} from "@/typings/store/plugins/easyFirestore/promociones";
import { DataFormularioPromocion } from "@/typings/components/promociones/formulario";
import { Curso } from "@/typings/store/plugins/easyFirestore/cursos";
import { idtify } from "@/helpers/string";
import DialogoExito from "@/components/DialogoExito.vue";
import SVGIcon from "@/components/SVGIcon.vue";
import DialogoEliminar from "@/components/DialogoEliminar.vue";

const routeGuard = (vm: Vue): boolean => {
  const permisos = vm.getUsuario?.permisos;
  if (permisos && permisos["promociones"]) {
    return true;
  }

  const rutas = vm.getRutasUsuario;
  if (rutas.length > 0) {
    const to = rutas[0].to;
    if (to) {
      if (vm.$route.name !== to.name) {
        vm.$router.replace(to);
      }
      return false;
    }
  }
  if (vm.$route.name !== "inicio") {
    vm.$router.replace({ name: "inicio" });
  }
  return false;
};

@Component({
  computed: mapGetters({
    getPromociones: "promociones/get",
    getArrayPromociones: "promociones/getArray",
    getArrayCursos: "cursos/getArray",
    getUsuario: "usuario/get",
    getRutasUsuario: "usuario/getRutas",
  }),
  methods: mapActions({
    setPromocion: "promociones/set",
    setArchivo: "archivos/set",
    deleteArchivo: "archivos/delete",
    deletePromocion: "promociones/delete",
  }),
  beforeRouteEnter(to, from, next) {
    next(routeGuard);
  },
  components: {
    DialogoEliminar,
    SVGIcon,
    DialogoExito,
    TablaPromociones,
    FormularioPromocion,
    InfoPromocion,
    BotonesCrud,
    MensajeEliminar,
    TituloVista,
  },
})
export default class VistaPromociones extends Vue {
  created(): void {
    if (!routeGuard(this)) {
      return;
    }
  }

  dialogoExito = {
    model: false,
    texto: "",
    icono: "",
  };
  cargando = false;
  search = "";
  promocion: Promocion | null = null;
  dialogoInfo = false;
  dialogoFormulario = false;
  dialogoEliminar = false;
  curso: Curso | null = null;

  get promociones(): Promociones {
    return this.getPromociones;
  }

  get arrayCursos(): Curso[] {
    return this.getArrayCursos.filter((curso) => curso.estado === "aceptado");
  }

  get items(): Array<Promocion> {
    return this.getArrayPromociones.filter((promocion) =>
      this.curso
        ? Object.values(promocion.cursos).some(
            (curso) => curso.id === this.curso?.id
          )
        : true
    );
  }

  clickItem(val: { id: string } | null): void {
    this.promocion = val ? this.promociones[val.id] : null;
  }

  clickPlus(): void {
    this.promocion = null;
    this.dialogoFormulario = true;
  }

  async clickGuardar(data: DataFormularioPromocion): Promise<void> {
    this.cargando = true;
    const promocion = data.doc;
    const imagen = data.imagen;
    const borrar = data.borrar;
    for (const key in imagen) {
      if (imagen[key].file) {
        const doc = imagen[key];
        const res = await this.setArchivo(doc);
        if (res && res.url) {
          this.$set(imagen, idtify(res.id), res);
        }
      }
    }
    for (const key in borrar) {
      if (borrar[key]) {
        const doc = borrar[key];
        await this.deleteArchivo(doc.id);
      }
    }
    promocion.imagen = imagen;
    this.setPromocion(promocion);
    if (promocion.id != this.promocion?.id) {
      this.dialogoExito = {
        model: true,
        texto: "¡Promoción creada con éxito!",
        icono: require("../assets/icons/promociones.svg"),
      };
    }
    if (promocion.id == this.promocion?.id) {
      this.dialogoExito = {
        model: true,
        texto: "¡Promoción editada con éxito!",
        icono: require("../assets/icons/editar.svg"),
      };
      this.promocion = promocion;
    }
    this.cerrarDialogoFormulario();
    this.cargando = false;
  }

  cerrarDialogoExito(): void {
    this.dialogoExito = {
      model: false,
      texto: "",
      icono: "",
    };
  }

  clickEliminar(): void {
    const promocion = this.promocion;
    if (!promocion?.id) {
      console.error("error id: ", promocion);
      return;
    }
    this.deletePromocion(promocion.id);
    this.promocion = null;
    this.cerrarDialogoEliminar();
  }

  abrirDialogoInfo(promocion: Promocion): void {
    if (!promocion.id) {
      console.error("error id: ", promocion);
      return;
    }
    const doc = this.promociones[promocion.id];
    if (doc) {
      this.promocion = doc;
      this.dialogoInfo = true;
    }
  }

  cerrarDialogoInfo(): void {
    this.dialogoInfo = false;
    this.promocion = null;
  }

  abrirDialogoFormulario(promocion: Promocion): void {
    if (!promocion.id) {
      console.error("error id: ", promocion);
      return;
    }
    const doc = this.promociones[promocion.id];
    if (doc) {
      this.promocion = doc;
      this.dialogoFormulario = true;
    }
  }

  cerrarDialogoFormulario(): void {
    this.dialogoFormulario = false;
    this.promocion = null;
  }

  abrirDialogoEliminar(promocion: Promocion): void {
    if (!promocion.id) {
      console.error("error id: ", promocion);
      return;
    }
    const doc = this.promociones[promocion.id];
    if (doc) {
      this.dialogoEliminar = true;
      this.promocion = doc;
    }
  }

  cerrarDialogoEliminar(): void {
    this.dialogoEliminar = false;
    this.promocion = null;
  }
}
