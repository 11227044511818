












































































































































































// decoradores
import {Component, Emit, Prop, Vue} from "vue-property-decorator";
// tipos
import {HeadersDataTable} from "@/typings/vuetify/vDataTable";
import { PlanCuentaIngreso } from "@/typings/store/plugins/easyFirestore/planCuentasIngresos";
import {ItemsTablaPlanCuentasIngresos} from "@/typings/components/planCuentasIngresos/tabla";
import "@/helpers/extensions";

@Component
export default class TablaUsuarios extends Vue {
  @Prop() readonly opciones!: boolean;
  @Prop() readonly search!: string;
  @Prop() readonly planCuentasIngresos!: Array<PlanCuentaIngreso>;

  options = {
    itemsPerPage: -1,
  };
  headerProps = {
    sortIcon: "mdi-chevron-down"
  }

  get headers(): HeadersDataTable {
    return [
      //{text: "", value: "estado", sortable: false},
   
      {text: "TIPO DOCUMENTO", value: "TIPO_DOCUMENTO"},
      {text: "NUMERO DOCUMENTO", value: "NUMERO_DOCUMENTO"},
      {text: "NUMERO CUENTA", value: "NUMERO_CUENTA"},
      {text: "CUENTA SOFTLAND", value: "CUENTA_SOFTLAND"},
      {text: "PRODUCTO", value: "PRODUCTO"},
      {text: "VALOR NETO", value: "VALOR_NETO"},

      {text: "", value: "opciones", sortable: false}
    ];
  }

  get items(): ItemsTablaPlanCuentasIngresos {
    return this.planCuentasIngresos.map((planCuentaIngreso) => {
      const nombre = planCuentaIngreso.nombre;
      const posicion = planCuentaIngreso.posicion;
      const TIPO_DOCUMENTO = planCuentaIngreso.TIPO_DOCUMENTO ;
      const NUMERO_DOCUMENTO = planCuentaIngreso.NUMERO_DOCUMENTO ;
      const CUENTA_SOFTLAND = planCuentaIngreso.CUENTA_SOFTLAND ;
      const NUMERO_CUENTA = planCuentaIngreso.NUMERO_CUENTA
      const PRODUCTO = planCuentaIngreso.PRODUCTO ;
      const VALOR_NETO = planCuentaIngreso.VALOR_NETO ;
    
      return {
        nombre,
        posicion,
        TIPO_DOCUMENTO,
        NUMERO_DOCUMENTO,
        CUENTA_SOFTLAND,
        NUMERO_CUENTA,
        PRODUCTO,
        VALOR_NETO,
       
        data: planCuentaIngreso,
      };
    });
  }

//   actualizarEstado(item: Usuario): void {
//     let estado: EstadoUsuario;
//     item.estado === 'libre' ? estado = 'bloqueado' : estado = 'libre';
//     item.estado = estado;
//     this.onCambiarEstado(item);
//   }

//   @Emit("cambiar-estado")
//   onCambiarEstado(usuario: Usuario): Usuario {
//     return usuario;
//   }

  @Emit("abrir-info")
  onAbrirInfo(planCuentaIngreso: PlanCuentaIngreso): PlanCuentaIngreso {
    return planCuentaIngreso;
  }

  @Emit("abrir-editar")
  onAbrirEditar(planCuentaIngreso: PlanCuentaIngreso): PlanCuentaIngreso {
    return planCuentaIngreso;
  }

  @Emit("abrir-eliminar")
  onAbrirEliminar(planCuentaIngreso: PlanCuentaIngreso): PlanCuentaIngreso {
    return planCuentaIngreso;
  }
}
