







































































import {Component, Vue} from "vue-property-decorator";
import SVGIcon from "@/components/SVGIcon.vue";
@Component({
  components: {SVGIcon}
})
export default class Footer extends Vue {

}
