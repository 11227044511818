












































































































































































// decoradores
import {Component, Emit, Prop, Vue} from "vue-property-decorator";
// tipos
import {HeadersDataTable} from "@/typings/vuetify/vDataTable";
import { TipoEgreso } from "@/typings/store/plugins/easyFirestore/tipoEgresos";
import {ItemsTablaBancos} from "@/typings/components/tipoEgresos/tabla";
import "@/helpers/extensions";

@Component
export default class TablaUsuarios extends Vue {
  @Prop() readonly opciones!: boolean;
  @Prop() readonly search!: string;
  @Prop() readonly tipoEgresos!: Array<TipoEgreso>;

  options = {
    itemsPerPage: 15,
  };
  headerProps = {
    sortIcon: "mdi-chevron-down"
  }

  get headers(): HeadersDataTable {
    return [
      //{text: "", value: "estado", sortable: false},
      {text: "Nombre", value: "nombre"},
      {text: "Posicion", value: "posicion"},
      {text: "", value: "opciones", sortable: false}
    ];
  }

  get items(): ItemsTablaBancos {
    return this.tipoEgresos.map((tipoEgreso) => {
      const nombre = tipoEgreso.nombre;
      const posicion = tipoEgreso.posicion;
    
      return {
        nombre,
        posicion,
       
        data: tipoEgreso,
      };
    });
  }

//   actualizarEstado(item: Usuario): void {
//     let estado: EstadoUsuario;
//     item.estado === 'libre' ? estado = 'bloqueado' : estado = 'libre';
//     item.estado = estado;
//     this.onCambiarEstado(item);
//   }

//   @Emit("cambiar-estado")
//   onCambiarEstado(usuario: Usuario): Usuario {
//     return usuario;
//   }

  @Emit("abrir-info")
  onAbrirInfo(tipoEgreso: TipoEgreso): TipoEgreso {
    return tipoEgreso;
  }

  @Emit("abrir-editar")
  onAbrirEditar(tipoEgreso: TipoEgreso): TipoEgreso {
    return tipoEgreso;
  }

  @Emit("abrir-eliminar")
  onAbrirEliminar(tipoEgreso: TipoEgreso): TipoEgreso {
    return tipoEgreso;
  }
}
