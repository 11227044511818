import { RouteConfig } from "vue-router";
import CondicionesComerciales from "@/views/CondicionesComerciales.vue";

const condicionesComerciales: RouteConfig = {
    path: "/condicionesComerciales",
    name: "condicionesComerciales",
    component: CondicionesComerciales,
};

export default condicionesComerciales;
