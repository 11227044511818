import VuexEasyFirestore from "vuex-easy-firestore";

import { Firebase } from "@/config/firebase";

import categoriasCurso from "./categoriasCurso";
import ciudades from "./ciudades";
import cursos from "./cursos";
import inscripciones from "./inscripciones";
import pagos from "./pagos";
import promociones from "./promociones";
import regiones from "./regiones";
import sesiones from "./sesiones";
import sesionesProgramadas from "./sesionesProgramadas";
import tags from "./tags";
import usuarios from "./usuarios";
import notificaciones from "./notificaciones";
import carritos from "./carritos";
import encuestas from "./encuestas";
import videosVistos from "@/store/plugins/easyFirestore/videosVistos";
import contactos from "@/store/plugins/easyFirestore/contactos";
import registrosEgresos from "@/store/plugins/easyFirestore/registrosEgresos";
import banco from "@/store/plugins/easyFirestore/bancos";
import centroCosto from "@/store/plugins/easyFirestore/centrosCostos";
import codCuenta from "@/store/plugins/easyFirestore/codCuentas";
import tipoEgreso from "@/store/plugins/easyFirestore/tipoEgresos";
import proveedores from "@/store/plugins/easyFirestore/proveedores";
import tipoDocumentos from "@/store/plugins/easyFirestore/tipoDocumentos";
import tipoCargos from "@/store/plugins/easyFirestore/tipoCargos";
import matrizPagos from "@/store/plugins/easyFirestore/matrizPagos";
import prenominas from "@/store/plugins/easyFirestore/prenominas";
import saldoCuentas from "@/store/plugins/easyFirestore/saldoCuentas";
import legales from "@/store/plugins/easyFirestore/legales";
import cajaChicas from "@/store/plugins/easyFirestore/cajaChicas";
import mapeoGastos from "@/store/plugins/easyFirestore/mapeoGastos";
import vacancias from "@/store/plugins/easyFirestore/vacancias";
import contratos from "@/store/plugins/easyFirestore/contratos";
import locales from "@/store/plugins/easyFirestore/locales";
import consumos from "@/store/plugins/easyFirestore/consumos";
import clientes from "@/store/plugins/easyFirestore/clientes";
import ordenes from "@/store/plugins/easyFirestore/ordenes";
import medidores from "@/store/plugins/easyFirestore/medidores";
import ingresos from "@/store/plugins/easyFirestore/ingresos";
import tarifas from "@/store/plugins/easyFirestore/tarifas";
import lecturas from "@/store/plugins/easyFirestore/lecturas";
import distribuidoresEnergia from "./distribuidoresEnergia";
import cajaChicasLinares from "@/store/plugins/easyFirestore/cajaChicasLinares";
import cajaChicasTalca from "@/store/plugins/easyFirestore/cajaChicasTalca";
import solicitudes from "@/store/plugins/easyFirestore/solicitudes";
import mantenimientos from "@/store/plugins/easyFirestore/mantenimientos";
import equipos from "@/store/plugins/easyFirestore/equipos";
import ordenesERG from "@/store/plugins/easyFirestore/ordenesERG";
import planCuentasIngresos from "@/store/plugins/easyFirestore/planCuentasIngresos";
import condicionesComerciales from "@/store/plugins/easyFirestore/condicionesComerciales";
import condicionesDivisiones from "@/store/plugins/easyFirestore/condicionesDivisiones";

import facturas from "./facturas";
//import visualizacionPagos from "@/router/visualizacionPagos";

const easyFirestore = VuexEasyFirestore(
  [
    categoriasCurso,
    ciudades,
    cursos,
    inscripciones,
    pagos,
    promociones,
    regiones,
    sesiones,
    sesionesProgramadas,
    tags,
    usuarios,
    notificaciones,
    carritos,
    encuestas,
    videosVistos,
    contactos,
    registrosEgresos,
    banco,
    centroCosto,
    codCuenta,
    tipoEgreso,
    proveedores,
    tipoDocumentos,
    tipoCargos,
    matrizPagos,
    prenominas,
    saldoCuentas,
    legales,
    cajaChicas,
    mapeoGastos,
    vacancias,
    contratos,
    locales,
    consumos,
    clientes,
    ordenes,
    medidores,
    ingresos,
    tarifas,
    lecturas,
    distribuidoresEnergia,
    cajaChicasLinares,
    cajaChicasTalca,
    solicitudes,
    mantenimientos,
    equipos,
    ordenesERG,
    planCuentasIngresos,
    condicionesComerciales,
    condicionesDivisiones,
    facturas,

    //visualizacionPagos,
  ],
  {
    logging: true,
    FirebaseDependency: Firebase,
  }
);

export default easyFirestore;
