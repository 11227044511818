





























































// decoradores
import { Vue, Component, Prop, Emit } from "vue-property-decorator";
// validaciones
import { required } from "vuelidate/lib/validators";
// tipos
import {
  RegistrosEgresos,
  Egresos,
} from "@/typings/store/plugins/easyFirestore/registrosEgresos";
// api excel
import Excel from "exceljs";

interface Registro {
  // entrada: Date;
  // salida: Date;
  PERIODO: string;
  CENTRO_DE_COSTO: string;
  DESCRIPCION_CUENTA: string;
  CODIGO_CUENTA: string;
  BANCO: string;
  ORDEN_DE_COMPRA: string;
  FECHA_EGRESO: string;
  TIPO_EGRESO: string;
  MONTO_EGRESO: string;
  GLOSA: string;
  RUT_PROVEEDOR: string;
  NOMBRE_PROVEEDOR: string;
  PAGO_C_P: string;
  PORC_DEL_TOTAL: string;
  NOMINA_PAGO: string;
  TIPO_DE_DOCUMENTO: string;
  N_DOCUMENTO: string;
  FAC_DOC_NOTA: string;
  CTA_ESTADO_RESULTADO: string;
  CTA_CONTROL_GASTO: string;
  TIPO_GASTO: string;
  N_CLIENTE: string;
  N_NOMINA: string;
  PERIODO_SERVICIO: string;

  //posicion: string;
}

 interface NombresRegistrosEgresos {
   [PERIODO: string]: RegistrosEgresos;
 }

// const date = (value: Date) => {
//   return value.toString() != "Invalid Date";
// };

@Component({
  validations: {
    archivo: { required },
  },
})
export default class CargarExcel extends Vue {
  @Prop() readonly egresos!: Egresos;

  archivo: Blob | null = null;
  cargando = false;
  clickCargar = false;

  despachos: Array<RegistrosEgresos> = [];
  mensajes: Array<string> = [];

    get nombresBancos(): NombresRegistrosEgresos {
       const resultado: NombresRegistrosEgresos = {};
       Object.values(this.egresos).forEach((item) => {
         resultado[item.PERIODO] = Object.assign({}, item);
       });
       return resultado;
     }

  // get rfidPatentes(): RfidPatentes {
  //   const resultado: RfidPatentes = {};
  //   Object.values(this.rfids).forEach((item) => {
  //     resultado[item.patente] = Object.assign({}, item);
  //   });
  //   return resultado;
  // }

  get archivoError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.archivo.$dirty) return errors;
    if (!this.$v.archivo.required) errors.push("Requerido");
    return errors;
  }

  @Emit("carga-excel")
  onCargaExcel(): Array<RegistrosEgresos> {
    return this.despachos;
  }

  // valoresValidos(valores: Array<Excel.CellValue>): boolean {
  //   if (!valores[1] || !valores[2] ) {
  //     return false;
  //   }
  //   const nombre = valores[1] ;
  //   const posicion = valores[2];
  //   //return !!entrada && !!salida && date(entrada) && date(salida);
  //   return nombre && posicion
  // }

  async cargar(): Promise<void> {
    this.$v.$touch();
    if (this.$v.$invalid || !this.archivo) {
      return;
    }
    this.cargando = true;
    this.clickCargar = true;
    const workbook = new Excel.Workbook();
    const data = await this.archivo.arrayBuffer();
    await workbook.xlsx.load(data);
    // worksheet
    const worksheet = workbook.getWorksheet(1);
    console.log("registros", worksheet.actualRowCount - 1);
    if (worksheet.actualRowCount - 1 > 2500) {
      console.log("registros mayores a 2500");
      this.mensajes.push("error: cantidad de registros mayor a 2500");
      this.cargando = false;
      return;
    }
    worksheet.eachRow((row, rowNumber) => {
      if (rowNumber != 1) {
        const valores = row.values as Array<Excel.CellValue>;
        if (valores[3] == "ejemplo") {
          console.log("error row", rowNumber);
          this.mensajes.push(
            "error: row " +
              rowNumber +
              " invalida, contiene campos vacios, fechas invalidas o la etiqueta prueba"
          );
          return;
        }
        const registro: Registro = {
          //PERIODO: valores[1] as string,
          //posicion: valores[2] as string,
          PERIODO: valores[1] as string,
          CENTRO_DE_COSTO: valores[2] as string,
          DESCRIPCION_CUENTA: valores[3] as string,
          CODIGO_CUENTA: valores[4] as string,
          BANCO: valores[5] as string,
          ORDEN_DE_COMPRA: valores[6] as string,
          FECHA_EGRESO: valores[7] as string,
          TIPO_EGRESO: valores[8] as string,
          MONTO_EGRESO: valores[9] as string,
          GLOSA: valores[10] as string,
          RUT_PROVEEDOR: valores[11] as string,
          NOMBRE_PROVEEDOR: valores[12] as string,
          PAGO_C_P: valores[13] as string,
          PORC_DEL_TOTAL: valores[14] as string,
          NOMINA_PAGO: valores[15] as string,
          TIPO_DE_DOCUMENTO: valores[16] as string,
          N_DOCUMENTO: valores[17] as string,
          FAC_DOC_NOTA: valores[18] as string,
          CTA_ESTADO_RESULTADO: valores[19] as string,
          CTA_CONTROL_GASTO: valores[20] as string,
          TIPO_GASTO: valores[21] as string,
          N_CLIENTE: valores[22] as string,
          N_NOMINA: valores[23] as string,
          PERIODO_SERVICIO: valores[24] as string,
        };
        // const banco = this.nombresBancos[registro.nombre];
        //  if (!banco) {
        //    console.log("error row", rowNumber);
        //    this.mensajes.push(
        //      "error: row " + rowNumber + " invalida, patente no registrada"
        //    );
        //    return;
        //  }
        const ingreso: RegistrosEgresos = {
          PERIODO: registro.PERIODO,
          CENTRO_DE_COSTO: registro.CENTRO_DE_COSTO.replaceAll(',', '|'),
          DESCRIPCION_CUENTA: registro.DESCRIPCION_CUENTA ??"",
          CODIGO_CUENTA: registro.CODIGO_CUENTA,
          BANCO: registro.BANCO,
          ORDEN_DE_COMPRA: registro.ORDEN_DE_COMPRA,
          FECHA_EGRESO: registro.FECHA_EGRESO,
          TIPO_EGRESO: registro.TIPO_EGRESO ?? "",
          MONTO_EGRESO: registro.MONTO_EGRESO,
          GLOSA: registro.GLOSA.toUpperCase().replaceAll(',', '|'),
          RUT_PROVEEDOR: registro.RUT_PROVEEDOR ?? "",
          NOMBRE_PROVEEDOR: registro.NOMBRE_PROVEEDOR ??"",
          PAGO_C_P: registro.PAGO_C_P,
          PORC_DEL_TOTAL: registro.PORC_DEL_TOTAL,
          NOMINA_PAGO: registro.NOMINA_PAGO.replaceAll(',', '|'),
          TIPO_DE_DOCUMENTO: registro.TIPO_DE_DOCUMENTO,
          N_DOCUMENTO: registro.N_DOCUMENTO,
          FAC_DOC_NOTA: registro.FAC_DOC_NOTA,
          CTA_ESTADO_RESULTADO: registro.CTA_ESTADO_RESULTADO?? "",
          CTA_CONTROL_GASTO: registro.CTA_CONTROL_GASTO?? "",
          TIPO_GASTO: registro.TIPO_GASTO?? "",
          N_CLIENTE: registro.N_CLIENTE ?? "",
          N_NOMINA: registro.N_NOMINA ?? "",
          TIPO_FACTURA: "",
          NETO_FACTURA: "",
          IVA_FACTURA: "",
          TOTAL_FACTURA: "",
          PAGADO: "",
          PERIODO_SERVICIO: registro.PERIODO_SERVICIO ?? "",

        };
        this.despachos.push(ingreso);
      }
    });
    if (this.mensajes.length > 0) {
      this.mensajes.push("cargado con errores");
      this.mensajes.push("corregir excel y volver a intentar");
      this.cargando = false;
    } else {
      this.mensajes.push("cargado completo");
      this.mensajes.push(this.despachos.length + " egresos cargados");
      this.onCargaExcel();
      this.cargando = false;
    }
  }
}
