

































































// decoradores
import { Vue, Component } from "vue-property-decorator";
// vuex
import { mapActions, mapGetters } from "vuex";
// tipos

// componentes
import TablaRegiones from "@/components/regiones/Tabla.vue";
import FormularioRegion from "@/components/regiones/Formulario.vue";
import InfoRegion from "@/components/regiones/Info.vue";
import BotonesCrud from "@/components/BotonesCrud.vue";
import MensajeEliminar from "@/components/MensajeEliminar.vue";
import TituloVista from "@/components/TituloVista.vue";
import {
  Region,
  Regiones,
} from "@/typings/store/plugins/easyFirestore/regiones";
import { DataFormularioRegion } from "@/typings/components/regiones/formulario";

const routeGuard = (vm: Vue): boolean => {
  const permisos = vm.getUsuario?.permisos;
  if (permisos && permisos["regiones"]) {
    return true;
  }

  const rutas = vm.getRutasUsuario;
  if (rutas.length > 0) {
    const to = rutas[0].to;
    if (to) {
      if (vm.$route.name !== to.name) {
        vm.$router.replace(to);
      }
      return false;
    }
  }
  if (vm.$route.name !== "inicio") {
    vm.$router.replace({ name: "inicio" });
  }
  return false;
};

@Component({
  computed: mapGetters({
    getRegiones: "regiones/get",
    getArrayRegiones: "regiones/getArray",
    getUsuario: "usuario/get",
    getRutasUsuario: "usuario/getRutas",
  }),
  methods: mapActions({
    setRegion: "regiones/set",
    deleteRegion: "regiones/delete",
  }),
  beforeRouteEnter(to, from, next) {
    next(routeGuard);
  },
  components: {
    TablaRegiones,
    FormularioRegion,
    InfoRegion,
    BotonesCrud,
    MensajeEliminar,
    TituloVista,
  },
})
export default class VistaCiudad extends Vue {
  created(): void {
    if (!routeGuard(this)) {
      return;
    }
  }
  search = "";
  region: Region | null = null;
  dialogoInfo = false;
  dialogoFormulario = false;
  dialogoEliminar = false;

  get regiones(): Regiones {
    return this.getRegiones;
  }

  get items(): Array<Region> {
    return this.getArrayRegiones;
  }

  clickItem(val: { id: string } | null): void {
    this.region = val ? this.regiones[val.id] : null;
  }

  clickPlus(): void {
    this.region = null;
    this.dialogoFormulario = true;
  }

  async clickGuardar(data: DataFormularioRegion): Promise<void> {
    const doc = data.doc;
    this.setRegion(doc);
    if (doc.id == this.region?.id) {
      this.region = doc;
    }
    this.cerrarDialogoFormulario();
  }

  clickEliminar(): void {
    const region = this.region;
    if (!region?.id) {
      console.error("error id: ", region);
      return;
    }
    this.deleteRegion(region.id);
    this.region = null;
    this.cerrarDialogoEliminar();
  }

  abrirDialogoInfo(region: Region): void {
    if (!region.id) {
      console.error("error id: ", region);
      return;
    }
    const doc = this.regiones[region.id];
    if (doc) {
      this.region = doc;
      this.dialogoInfo = true;
    }
  }

  cerrarDialogoInfo(): void {
    this.dialogoInfo = false;
    this.region = null;
  }

  abrirDialogoFormulario(region: Region): void {
    if (!region.id) {
      console.error("error id: ", region);
      return;
    }
    const doc = this.regiones[region.id];
    if (doc) {
      this.region = doc;
      this.dialogoFormulario = true;
    }
  }

  cerrarDialogoFormulario(): void {
    this.dialogoFormulario = false;
    this.region = null;
  }

  abrirDialogoEliminar(region: Region): void {
    if (!region.id) {
      console.error("error id: ", region);
      return;
    }
    const doc = this.regiones[region.id];
    if (doc) {
      this.dialogoEliminar = true;
      this.region = doc;
    }
  }

  cerrarDialogoEliminar(): void {
    this.dialogoEliminar = false;
    this.region = null;
  }
}
