




















































































//decoradores
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
// tipos
import {
  AnuladoWebPay,
  Pago,
  ResultadoWebPay,
  StatusResultadoWebPay,
  PaymentTypeCodeResultadoWebPay,
} from "@/typings/store/plugins/easyFirestore/pagos";
import { Usuario } from "@/typings/store/plugins/easyFirestore/usuarios";
import { Curso, Cursos } from "@/typings/store/plugins/easyFirestore/cursos";
import {
  Sesion,
  Sesiones,
} from "@/typings/store/plugins/easyFirestore/sesiones";
import { Promocion } from "@/typings/store/plugins/easyFirestore/promociones";
import { DataFormularioPago } from "@/typings/components/pagos/formulario";

// validaciones
//import { required } from "vuelidate/lib/validators";

@Component({})
export default class FormularioPago extends Vue {
  @Prop() readonly pago!: Pago | null;
  @Prop() readonly clientes!: Array<Usuario>;
  @Prop() readonly listaCursos!: Array<Curso>;
  @Prop() readonly listaSesiones!: Array<Sesion>;
  @Prop() readonly listaPromociones!: Array<Promocion>;

  created(): void {
    const pago = this.pago;
    this.fecha = new Date().toISOString();
    if (!pago) {
      return;
    }
    this.id = pago.id ?? "";
    this.cliente = pago.cliente;
    if (pago.cursos) {
      Object.values(pago.cursos).forEach((curso) => {
        this.cursos.push(curso);
      });
    }
    if (pago.sesiones) {
      Object.values(pago.sesiones).forEach((sesion) => {
        this.sesiones.push(sesion);
      });
    }
    this.token = pago.token ?? "";
    this.url = pago.url ?? "";
    this.resultado = pago.resultado;
    this.anulado = pago.anulado;
    this.promocion = pago.promocion ?? null;
  }

  id: string | null = null;
  cliente: Usuario | null = null;
  cursos: Curso[] = [];
  sesiones: Sesion[] = [];
  token = "";
  url = "";
  resultado: ResultadoWebPay | undefined = undefined;
  anulado: AnuladoWebPay | undefined = undefined;
  promocion: Promocion | null = null;
  fecha = "";
  status: Array<StatusResultadoWebPay> = [
    "INITIALIZED",
    "AUTHORIZED",
    "REVERSED",
    "FAILED",
    "NULLIFIED",
    "PARTIALLY_NULLIFIED",
    "CAPTURED",
  ];
  payment_type_code: Array<PaymentTypeCodeResultadoWebPay> = [
    "VD",
    "VN",
    "VC",
    "SI",
    "S2",
    "NC",
    "VP",
  ];

  resultado2 = {
    vci: "TSY",
    amount: 0,
    status: this.status[Math.floor(Math.random() * (7 - 0)) + 0],
    buy_order: Math.floor(Math.random()).toString(),
    session_id: Math.floor(Math.random()).toString(),
    card_detail: {
      card_number: Math.floor(1000 + Math.random() * 9000).toString(),
    },
    accounting_date: new Date().toISOString(),
    transaction_date: new Date().toISOString(),
    authorization_code: Math.floor(100000 + Math.random() * 900000).toString(),
    payment_type_code: this.payment_type_code[
      Math.floor(Math.random() * (7 - 0)) + 0
    ],
    response_code: Math.floor(Math.random() * (0 - -5)) + -5,
    installments_amount: Math.floor(Math.random() * (400000 - 20000)) + 20000,
    installments_number: Math.floor(Math.random() * (13 - 2)) + 2,
    balance: Math.floor(Math.random() * (1200000 - 1000000)) + 1000000,
  };

  get titulo(): string {
    return this.pago ? "editar pago" : "nuevo pago";
  }

  @Emit("close")
  onClose(): null {
    return null;
  }

  @Emit("cerrar-dialogo")
  onCerrarDialogo(): void {
    return;
  }

  @Emit("click-guardar")
  onClickGuardar(data: DataFormularioPago): DataFormularioPago {
    return data;
  }

  guardar(): void {
    if (!this.cliente || !this.promocion) {
      console.error("error en el cliente");
      return;
    }

    const cursos = this.cursos?.reduce((docs, doc) => {
      if (!doc?.id) {
        console.error("doc.id == undefined => ", doc);
        return docs;
      }
      docs[doc.id] = doc;
      return docs;
    }, {} as Cursos);

    const sesiones = this.sesiones?.reduce((docs, doc) => {
      if (!doc?.id) {
        console.error("doc.id == undefined => ", doc);
        return docs;
      }
      docs[doc.id] = doc;
      return docs;
    }, {} as Sesiones);

    let valor = 0;

    if (cursos || sesiones) {
      valor = this.cursos.reduce((total, curso) => total + curso.precio, 0);
      valor + this.sesiones.reduce((suma, sesion) => suma + sesion.precio, 0);
      this.resultado2.amount = valor;
    }

    const doc: Pago = {
      id: this.id ?? "",
      cliente: this.cliente,
      cursos: cursos,
      sesiones: sesiones,
      token: this.token,
      url: this.url,
      resultado: this.resultado2,
      promocion: this.promocion,
      urlRetorno: location.origin + "/compraExitosa",
      estado: "pendiente",
    };
    if (this.id) {
      doc.id = this.id;
    }
    const data: DataFormularioPago = {
      doc: doc,
    };
    this.onClickGuardar(data);
  }
}
