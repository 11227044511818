





























































































































































































































































































































































































































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { CentroCosto } from "@/typings/store/plugins/easyFirestore/centrosCostos";
// helpers
//import { idtify } from "@/helpers/string";
// tipos
//import { Rutas } from "@/typings/store/modules/rutas";
//import {
//  Permisos,
//  TipoUsuario,
//  Usuario,
//} from "@/typings/store/plugins/easyFirestore/usuarios";
import {
  Vacancia,
  //Bancos
} from "@/typings/store/plugins/easyFirestore/vacancias";
// validaciones
import {
  // email,
  required,
} from "vuelidate/lib/validators";
import { DataFormularioVacancia } from "@/typings/components/vacancias/formulario";
import SVGIcon from "@/components/SVGIcon.vue";
//import { Archivo, Archivos } from "@/typings/store/modules/archivos";

@Component({
  components: {
    SVGIcon,
  },
  validations: {
    // nombre: { required },
    // posicion: { required },
    contrato: { required },
    centroCosto: { required },
    direccion: { required },
    local: { required },
    superficie: { required },
    ocupado: { required },
    fInicio: { required },
    fFin: { required },
    precioUf: { required },
    nivel: { required },
    rutLocatario: { required },
    razonSocial: { required },
    telefonoContacto: { required },
    periodo: { required },
    url: { required },
    imagen: { required },
  },
})
export default class FormularioBanco extends Vue {
  @Prop() readonly vacancia!: Vacancia | null;
  @Prop() readonly cargando!: boolean;
  @Prop() readonly centrosCostos!: CentroCosto[];
  //   @Prop() readonly rutas!: Rutas;
  //   @Prop() readonly ciudades!: Ciudad[];
  //   @Prop() readonly cargando!: boolean;
  //   @Prop() readonly regiones!: Region[];

  created(): void {
    // Object.keys(this.rutas).forEach((key) => {
    //   this.$set(this.permisos, key, false);
    // });
    // console.log(this.permisos);
    const vacancia = this.vacancia;
    if (!vacancia) {
      return;
    }
    const id = vacancia.id ?? "";
    const contrato = vacancia.contrato ?? "";
    const centroCosto = vacancia.centroCosto ?? "";
    const direccion = vacancia.direccion ?? "";
    const local = vacancia.local ?? "";
    const superficie = vacancia.superficie ?? "";
    const ocupado = vacancia.ocupado ?? "";
    const fInicio = vacancia.fInicio ?? "";
    const fFin = vacancia.fFin ?? "";
    const precioUf = vacancia.precioUf ?? "";
    const nivel = vacancia.nivel ?? "";
    const rutLocatario = vacancia.rutLocatario ?? "";
    const razonSocial = vacancia.razonSocial ?? "";
    const telefonoContacto = vacancia.telefonoContacto ?? "";
    const periodo = vacancia.periodo ?? "";
    const url = vacancia.url ?? "";
    const imagen = vacancia.imagen ?? "";
  }

  id: string | null = null;
  contrato = "";
  centroCosto = "";
  direccion = "";
  local = "";
  superficie = "";
  ocupado = "";
  fInicio = "";
  fFin = "";
  precioUf = "";
  nivel = "";
  rutLocatario = "";
  razonSocial = "";
  telefonoContacto = "";
  periodo = "";
  url = "";
  imagen = "";
  items_ocupado = [
    "SI","NO"
  ];

  get titulo(): string {
    return this.vacancia ? "editar vacancia" : "nuevo vacancia";
  }

  //   get permisosKey(): Array<string> {
  //     // return Object.keys(this.permisos).filter((key) => {
  //     //   if(this.rutas[key].tipos.includes(this.tipo)){
  //     //     this.permisos[key] = true;
  //     //   }
  //     // });
  //     return Object.keys(this.permisos).filter((key) => {
  //       if (!this.rutas[key]) {
  //         return false;
  //       }
  //       const tipo = this.rutas[key].tipos.includes(this.tipo);
  //       return tipo;
  //     });
  //   }

  get itemCentrosCostos(): Array<string> {
    const itemCentroCosto: Array<string> = [];
    this.centrosCostos.forEach((centroCosto) => {
      itemCentroCosto.push(centroCosto.Desc_formato);
    });

    return itemCentroCosto.sort((sesionA, sesionB) => {
      return parseInt(sesionA) - parseInt(sesionB);
    });
  }

  get contratoError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.contrato.$dirty) return errors;
    if (!this.$v.contrato.required) errors.push("Completar datos obligatorios");
    return errors;
  }

  get centroCostoError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.centroCosto.$dirty) return errors;
    if (!this.$v.centroCosto.required)
      errors.push("Completar datos obligatorios");
    return errors;
  }

  get direccionError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.direccion.$dirty) return errors;
    if (!this.$v.direccion.required)
      errors.push("Completar datos obligatorios");
    return errors;
  }

  get localError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.local.$dirty) return errors;
    if (!this.$v.local.required) errors.push("Completar datos obligatorios");
    return errors;
  }

  get superficieError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.superficie.$dirty) return errors;
    if (!this.$v.superficie.required)
      errors.push("Completar datos obligatorios");
    return errors;
  }

  get ocupadoError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.ocupado.$dirty) return errors;
    if (!this.$v.ocupado.required) errors.push("Completar datos obligatorios");
    return errors;
  }

  get fInicioError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.fInicio.$dirty) return errors;
    if (!this.$v.fInicio.required) errors.push("Completar datos obligatorios");
    return errors;
  }

  get fFinError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.fFin.$dirty) return errors;
    if (!this.$v.fFin.required) errors.push("Completar datos obligatorios");
    return errors;
  }

  get precioUfError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.precioUf.$dirty) return errors;
    if (!this.$v.precioUf.required) errors.push("Completar datos obligatorios");
    return errors;
  }

  get nivelError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.nivel.$dirty) return errors;
    if (!this.$v.nivel.required) errors.push("Completar datos obligatorios");
    return errors;
  }

  get rutLocatarioError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.rutLocatario.$dirty) return errors;
    if (!this.$v.rutLocatario.required)
      errors.push("Completar datos obligatorios");
    return errors;
  }

  get razonSocialError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.razonSocial.$dirty) return errors;
    if (!this.$v.razonSocial.required)
      errors.push("Completar datos obligatorios");
    return errors;
  }

  get telefonoContactoError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.telefonoContacto.$dirty) return errors;
    if (!this.$v.telefonoContacto.required)
      errors.push("Completar datos obligatorios");
    return errors;
  }

  get periodoError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.periodo.$dirty) return errors;
    if (!this.$v.periodo.required) errors.push("Completar datos obligatorios");
    return errors;
  }

  get urlError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.url.$dirty) return errors;
    if (!this.$v.url.required) errors.push("Completar datos obligatorios");
    return errors;
  }

  get imagenError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.imagen.$dirty) return errors;
    if (!this.$v.imagen.required) errors.push("Completar datos obligatorios");
    return errors;
  }

  @Emit("close")
  onClose(): null {
    return null;
  }

  @Emit("cerrar-dialogo")
  onCerrarDialogo(): void {
    return;
  }

  @Emit("click-guardar")
  onClickGuardar(data: DataFormularioVacancia): DataFormularioVacancia {
    return data;
  }

  //   @Watch("permisosKey", { immediate: true })
  //   onTipoChange(): void {
  //     console.log("a");
  //     for (const key of Object.keys(this.permisos)) {
  //       if (this.permisos[key]) {
  //         this.permisos[key] = false;
  //       }
  //     }
  //     this.permisosKey.forEach((key) => {
  //       this.permisos[key] = true;
  //     });
  //   }

  //   nombrePermiso(key: string): string {
  //     return this.rutas[key].text;
  //   }

  guardar(): void {
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }
    const doc: Vacancia = {
      //id: this.id ,
      contrato: this.contrato,
      centroCosto: this.centroCosto,
      direccion: this.direccion,
      local: this.local,
      superficie: this.superficie,
      ocupado: this.ocupado,
      fInicio: this.fInicio,
      fFin: this.fFin,
      precioUf: this.precioUf,
      nivel: this.nivel,
      rutLocatario: this.rutLocatario,
      razonSocial: this.razonSocial,
      telefonoContacto: this.telefonoContacto,
      periodo: this.periodo,
      url: this.url,
      imagen: this.imagen,
    };
    if (this.id) {
      doc.id = this.id;
    }
    const data: DataFormularioVacancia = {
      doc: doc,
    };
    this.onClickGuardar(data);
  }
}
