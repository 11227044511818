
































































































import Vue from "vue";

import {Component, Prop, Emit} from "vue-property-decorator";
import {Curso} from "@/typings/store/plugins/easyFirestore/cursos";
import {DataFormularioSesion} from "@/typings/components/sesiones/formulario";
import {Sesion, Sesiones} from "@/typings/store/plugins/easyFirestore/sesiones";
import SVGIcon from "@/components/SVGIcon.vue";
import Editar from "@/components/cursos/sesiones/Editar.vue";
import DialogoExito from "@/components/DialogoExito.vue";

@Component({
  components: {DialogoExito, Editar, SVGIcon}
})
export default class FormularioSesiones extends Vue {
  @Prop() curso!: Curso;
  @Prop() nombre!: string;
  @Prop() sesiones!: Sesiones;
  @Prop() nuevasSesiones!: DataFormularioSesion[];

  created(): void {
    const sesiones = Object.values(this.sesiones ?? {}).sort((a, b) => a.numero - b.numero);
    const nuevasSesiones = Object.values(this.nuevasSesiones ?? {}).sort((a, b) => a.numero - b.numero);
    for (const sesion of sesiones) {
      this.sesionesNuevas.push(sesion);
    }
    for (const sesion of nuevasSesiones) {
      this.sesionesNuevas.push(sesion);
    }
  }

  sesionesNuevas: DataFormularioSesion[] = [];
  sesionesEliminadas: DataFormularioSesion[] = [];
  dialogoExito = {
    model: false,
    icono: "",
    texto: ""
  };
  dialogoSesion: {
    model: boolean;
    sesion: DataFormularioSesion | null;
    numero: number;
    index: number;
  } = {
    model: false,
    sesion: null,
    numero: 0,
    index: -1
  };

  @Emit("close")
  onClose(): null {
    return null;
  }

  @Emit("click-guardar-sesion")
  onClickGuardarSesion(dataSesiones: DataFormularioSesion[] | null, dataEliminarSesiones: DataFormularioSesion[] | null):
    { dataSesiones: DataFormularioSesion[] | null, dataEliminarSesiones: DataFormularioSesion[] | null } {
    return {dataSesiones: dataSesiones, dataEliminarSesiones: dataEliminarSesiones};
  }

  abrirNuevaSesion(): void {
    this.dialogoSesion = {
      model: true,
      sesion: null,
      numero: this.sesionesNuevas.length + 1,
      index: -1
    };
  }

  guardarSesion(sesion: Sesion): void {
    if(this.dialogoSesion.index > -1) {
      this.sesionesNuevas.splice(this.dialogoSesion.index, 1, sesion);
    } else {
      this.sesionesNuevas.push(sesion)
    }
    this.cerrarEditarSesion();
  }

  guardar(): void {
    if (this.sesionesNuevas.some(sesion => !sesion.tema || !sesion.descripcion || !sesion.resumen || !sesion.duracion)) {
      this.dialogoExito = {
        model: true,
        icono: require('../../../assets/icons/cancelar-duo.svg'),
        texto: "¡Ingrese datos validos!"
      };
      return;
    }
    this.onClickGuardarSesion(this.sesionesNuevas, this.sesionesEliminadas)
  }

  abrirEditarSesion(index: number, sesion: DataFormularioSesion): void {
    this.dialogoSesion = {
      model: true,
      sesion: sesion,
      numero: index + 1,
      index: index
    };
  }

  cerrarDialogoExito(): void{
    this.dialogoExito = {
      model: false,
      icono: "",
      texto: ""
    };
  }

  cerrarEditarSesion(): void {
    this.dialogoSesion = {
      model: false,
      sesion: null,
      numero: 0,
      index: -1
    };
  }

  eliminarSesion(sesion: DataFormularioSesion): void {
    const index = this.sesionesNuevas.findIndex(clase => clase.numero === sesion.numero);
    if (sesion.id) {
      this.sesionesEliminadas.push(sesion);
    }
    this.sesionesNuevas.splice(index, 1);
  }

}
