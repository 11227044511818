









































































































































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { CodCuenta } from "@/typings/store/plugins/easyFirestore/codCuentas";
// validaciones
import { required } from "vuelidate/lib/validators";
import { DataFormularioCodCuenta } from "@/typings/components/codCuentas/formulario";
import SVGIcon from "@/components/SVGIcon.vue";

@Component({
  components: {
    SVGIcon,
  },
  validations: {
    ppcodi: { required },
    pcnivel: { required },
    pclnivel: { required },
    pcdesc: { required },
    pccodi2: { required },
  },
})
export default class FormularioBanco extends Vue {
  @Prop() readonly codCuenta!: CodCuenta | null;
  @Prop() readonly cargando!: boolean;
  //   @Prop() readonly rutas!: Rutas;
  //   @Prop() readonly ciudades!: Ciudad[];
  //   @Prop() readonly cargando!: boolean;
  //   @Prop() readonly regiones!: Region[];

  created(): void {
    // Object.keys(this.rutas).forEach((key) => {
    //   this.$set(this.permisos, key, false);
    // });
    // console.log(this.permisos);
    const codCuenta = this.codCuenta;
    if (!codCuenta) {
      return;
    }
    this.id = codCuenta.id ?? "";
    this.ppcodi = codCuenta.PCCODI ?? "";
    this.pcnivel = codCuenta.PCNIVEL ?? "";
    this.pclnivel = codCuenta.PCLNIVEL ?? "";
    this.pcdesc = codCuenta.PCDESC ?? "";
    this.pccodi2 = codCuenta.PCCODI2 ?? "";
  }

  id: string | null = null;
  ppcodi = "";
  pcnivel = "";
  pclnivel = "";
  pcdesc = "";
  pccodi2 = "";

  get titulo(): string {
    return this.codCuenta ? "editar codCuenta" : "nuevo codCuenta";
  }

  //   get permisosKey(): Array<string> {
  //     // return Object.keys(this.permisos).filter((key) => {
  //     //   if(this.rutas[key].tipos.includes(this.tipo)){
  //     //     this.permisos[key] = true;
  //     //   }
  //     // });
  //     return Object.keys(this.permisos).filter((key) => {
  //       if (!this.rutas[key]) {
  //         return false;
  //       }
  //       const tipo = this.rutas[key].tipos.includes(this.tipo);
  //       return tipo;
  //     });
  //   }
 
  get ppcodiError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.ppcodi.$dirty) return errors;
    if (!this.$v.ppcodi.required) errors.push("Completar datos obligatorios");
    return errors;
  }

  get pcnivelError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.pcnivel.$dirty) return errors;
    if (!this.$v.pcnivel.required) errors.push("Completar datos obligatorios");
    return errors;
  }

    get pclnivelError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.pclnivel.$dirty) return errors;
    if (!this.$v.pclnivel.required) errors.push("Completar datos obligatorios");
    return errors;
  }

  get pcdescError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.pcdesc.$dirty) return errors;
    if (!this.$v.pcdesc.required) errors.push("Completar datos obligatorios");
    return errors;
  }
    get pccodi2Error(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.pccodi2.$dirty) return errors;
    if (!this.$v.pccodi2.required) errors.push("Completar datos obligatorios");
    return errors;
  }

  @Emit("close")
  onClose(): null {
    return null;
  }

  @Emit("cerrar-dialogo")
  onCerrarDialogo(): void {
    return;
  }

  @Emit("click-guardar")
  onClickGuardar(data: DataFormularioCodCuenta): DataFormularioCodCuenta {
    return data;
  }

  //   @Watch("permisosKey", { immediate: true })
  //   onTipoChange(): void {
  //     console.log("a");
  //     for (const key of Object.keys(this.permisos)) {
  //       if (this.permisos[key]) {
  //         this.permisos[key] = false;
  //       }
  //     }
  //     this.permisosKey.forEach((key) => {
  //       this.permisos[key] = true;
  //     });
  //   }

  //   nombrePermiso(key: string): string {
  //     return this.rutas[key].text;
  //   }

  guardar(): void {
    this.$v.$touch();
    console.log(this.$v.$invalid);
    const doc: CodCuenta = {
      PCCODI: this.ppcodi.toUpperCase(),
      PCNIVEL: this.pcnivel,
      PCLNIVEL: this.pclnivel,
      PCDESC: this.pcdesc.toUpperCase(),
      PCCODI2: this.pccodi2.toUpperCase(),
    };
    if (this.id) {
      doc.id = this.id;
    }
    const data: DataFormularioCodCuenta = {
      doc: doc,
    };
    this.onClickGuardar(data);
  }
}
