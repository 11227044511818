










































































// decoradores
import {Component, Emit, Prop, Vue} from "vue-property-decorator";
// tipos
import {HeadersDataTable} from "@/typings/vuetify/vDataTable";
import {Pago} from "@/typings/store/plugins/easyFirestore/pagos";
import {ItemsTablaPagos, ItemsTablaPagosRelator} from "@/typings/components/pagos/tabla";
import {Curso} from "@/typings/store/plugins/easyFirestore/cursos"


@Component
export default class TablaPagos extends Vue {
  @Prop() readonly opciones!: boolean;
  @Prop() readonly pagos!: Array<Pago>;
  @Prop() readonly buscar!: string;
  @Prop() readonly cursos!: Array<Curso>
  @Prop() readonly tipoUsuario!: string;

  options = {
    itemsPerPage: -1,
  };
  headerProps = {
    sortIcon: "mdi-chevron-down"
  };
  tiposPagos = {
    "VN": "Crédito",
    "S2": "Crédito",
    "SI": "Crédito",
    "NC": "Crédito",
    "VC": "Crédito",
    "VD": "Débito",
    "VP": "Prepago",
  };

  get headers(): HeadersDataTable {
    if (this.tipoUsuario == "admin" || this.tipoUsuario == "cliente"){
    return [
      {text: "Fecha", value: "fecha"},
      {text: "Hora", value: "hora"},
      {text: "Curso", value: "cursos"},
      {text: "Relator", value: "relator"},
      {text: "Descuento", value: "descuento"},
      {text: "Medio de Pago", value: "metodoPago"},
      {text: "Valor", value: "valor"},
      {text: "Monto", value: "monto"},
      {text: "", value: "opciones", sortable: false}
    ];
    }else{
      return [
        {text:"", value:"estado",sortable:false},
        {text: "Curso", value: "nombre"},
        {text: "Inicio", value: "inicio"},
        {text: "Termino", value: "fin"},
        {text: "asistentes", value: "asistentes"},
        {text: "Ganancias" , value: "ganancias"},
      ]
    }
  }

  get items(): ItemsTablaPagos | ItemsTablaPagosRelator{
    if (this.tipoUsuario=="admin" || this.tipoUsuario=="cliente"){
    return this.pagos.sort((pago1, pago2) => {
      return (pago1.resultado?.transaction_date.toDate().getTime() ?? 0) - (pago2.resultado?.transaction_date.toDate().getTime() ?? 0)
    }).map((pago) => {
      const hora = pago.resultado?.transaction_date?.toDate()?.format("HH:mm:ss") ?? new Date().format("HH:mm:ss");
      const fecha = pago.resultado?.transaction_date?.toDate()?.format("DD-MM-YYYY") ?? new Date().format("DD-MM-YYYY");
      const cursos = Object.values(pago.cursos).map(pago => pago.nombre).join(", ");
      const relator = Object.values(pago.cursos).map(pago => pago.relator.nombre).join(", ");
      const descuento =this.obtenerDescuento(pago);
      const metodoPago = pago.resultado ? this.tiposPagos[pago.resultado.payment_type_code] : "";
      const valor = this.obtenerValor(pago);
      
      
      const monto = pago.resultado?.amount?.toCurrency({symbol: '$'}) ?? "";
      return {
        fecha,
        hora,
        cursos,
        relator,
        descuento,
        metodoPago,
        valor,
        monto,
        data: pago,
      };
    });
    }
    else{
      return this.cursos.map((curso) =>{
        let CursosRecorridos: Array<string> = [];
        const estado= curso.estado;
        const nombre= curso.nombre;
        const inicio=curso.inicio.toDate().format("DD-MM-YYYY") ;
        const fin=curso.fin.toDate().format("DD-MM-YYYY");
        const asistentes=this.pagos.reduce((total,pago) => {
          if (Object.values(pago.cursos).some((course)=> course.id == curso.id)){
            return total+1;
          }
          return total
        },0).toString();
        const ganancias= this.pagos.reduce((total,pago) => {
                            if(Object.values(pago.cursos).some((course)=> course.id == curso.id)){
                              if (curso.comision.tipo== "Por participante"){
                                if (curso.comision.valor.includes("%")){
                                  return total +Math.round(curso.precio*(parseFloat(curso.comision.valor.replace("%",""))/100))
                                }else{
                                  return total + parseFloat(curso.comision.valor);
                                }
                              }
                              else{
                                if (CursosRecorridos.isEmpty || !CursosRecorridos.includes(curso.id ?? "") && !curso.comision.valor.includes('$')) {
                                    CursosRecorridos.push(curso.id ?? "");
                                    return total + parseFloat(curso.comision.valor);
                                }   
                              }
                            }
                            return total
                          },0).toCurrency({symbol: '$'});
        return{
          estado,
          nombre,
          inicio,
          fin,
          asistentes,
          ganancias,
          data: curso,

        };
      })
    }

  }
  getColor(curso: Curso): Array<string> {
    switch (curso.estado) {
      case "pendiente":
        return ["#747c8d", "rgba(116, 124, 141, 0.4)"];
      case "revisado":
        return ["#e5dd0d", "rgba(229, 221, 13, 0.4)"];
      case "aceptado":
        return ["#279b18", "rgba(39, 155, 24, 0.4)"];
      case "rechazado":
        return ["#ea5143", "rgba(234, 81, 67, 0.4)"];
      case "impartiendo":
        return ["#3790d1", "rgb(55, 144, 209, 0.4)"];
      case "terminado":
        return ["#000000", "rgba(0, 0, 0, 0.4)"];
      default:
        return ["", ""];
    }
  }

  get datosFiltrados(): Array<Pago> {
    return Object.values(this.pagos)
      .filter((pago) => {
        const fecha = new Date(pago.resultado?.transaction_date ?? 0).toDateString();
        const fechaInicio = ""//this.fechaInicio;
        const fechaFin = "" //this.fechaFinal;
        return fecha >= fechaInicio && fecha <= fechaFin;
      })
  }

  obtenerValor(pago:Pago) : string{
    if (pago.promocion){
      if (pago.promocion.tipo=="curso"){
        return pago.promocion.descuento.includes("%") ? Object.values(pago.promocion.cursos).reduce((total,curso)=> total+ (curso.precio - Math.round(curso.precio*parseInt(pago.promocion?.descuento ?? "")/100))  ,0).toString() : Object.values(pago.promocion.cursos).reduce((total,curso) => total+ (curso.precio-parseInt(pago.promocion?.descuento ?? "")),0).toString()
      }
      else{
        return pago.promocion.descuento.includes("%") ? Object.values(pago.promocion.cursos).map((curso) => curso.precio).join(", ") : Object.values(pago.promocion.cursos).map((course)=> course.precio).join(", ")
      }
       
    }
    else{
      
      return Object.values(pago.cursos).map(curso => {
        if(curso.descuento){
          
          return curso.descuento.includes("%") ?  (curso.precio - (Math.round(curso.precio * parseInt(curso.descuento.replace("%",""))/100))).toCurrency({symbol: '$'}).toString() : (curso.precio - (parseInt(curso.descuento))).toCurrency({symbol: '$'}).toString()
        }
        else{
          return curso.precio.toCurrency({symbol: '$'})
        }
      }).join(", ")

    }
  }

  obtenerDescuento( pago:Pago) : string {

    if (pago.promocion){
      
      return pago.promocion.descuento
    }
    else{
      return Object.values(pago.cursos).map((curso)=> {
        if (curso.descuento.includes("%")){
          return curso.descuento;
        }
        return parseInt(curso.descuento).toCurrency({symbol:'$'})
      }).join(", ")
    }
  }
  @Emit("abrir-info")
  onAbrirInfo(pago: Pago): Pago {
    return pago;
  }

  @Emit("abrir-editar")
  onAbrirEditar(pago: Pago): Pago {
    return pago;
  }

  @Emit("abrir-eliminar")
  onAbrirEliminar(pago: Pago): Pago {
    return pago;
  }
}
