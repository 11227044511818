import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import es from "vuetify/src/locale/es";

import "@mdi/font/css/materialdesignicons.css";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#090909',
        //primary: '#0D0A39',
        //secondary: '#EA5143',
        secondary:'#217ac8',
        tertiary: '#747C8D',
        cuartiary: '#F1F0FA'
      }
    },
    options: {
      customProperties: true
    }
  },
  lang: {
    locales: { es },
    current: "es",
  }
});
