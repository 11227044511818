
























































































































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
// helpers
//import { idtify } from "@/helpers/string";
// tipos
//import { Rutas } from "@/typings/store/modules/rutas";
//import {
//  Permisos,
//  TipoUsuario,
//  Usuario,
//} from "@/typings/store/plugins/easyFirestore/usuarios";
import {
  DistribuidorEnergia,
  //Bancos
} from "@/typings/store/plugins/easyFirestore/distribuidoresEnergia";

// validaciones
import {
  // email,
  required,
} from "vuelidate/lib/validators";
import { DataFormularioDistribuidorEnergia } from "@/typings/components/distribuidoresEnergia/formulario";
import SVGIcon from "@/components/SVGIcon.vue";
//import { Archivo, Archivos } from "@/typings/store/modules/archivos";

@Component({
  components: {
    SVGIcon,
  },
  validations: {
    empresaDecretoTarifario: { required },
    comuna: { required },
    aereoOsubterraneo: { required },
  },
})
export default class FormularioDistribuidorEnergia extends Vue {
  @Prop() readonly distribuidorEnergia!: DistribuidorEnergia | null;
  @Prop() readonly cargando!: boolean;
  //   @Prop() readonly rutas!: Rutas;
  //   @Prop() readonly ciudades!: Ciudad[];
  //   @Prop() readonly cargando!: boolean;
  //   @Prop() readonly regiones!: Region[];

  created(): void {
    // Object.keys(this.rutas).forEach((key) => {
    //   this.$set(this.permisos, key, false);
    // });
    // console.log(this.permisos);
    const distribuidorEnergia = this.distribuidorEnergia;
    if (!distribuidorEnergia) {
      return;
    }
    this.id = distribuidorEnergia.id ?? "";
    this.empresaDecretoTarifario = distribuidorEnergia.empresaDecretoTarifario;
    this.comuna = distribuidorEnergia.comuna ?? "";
    this.sectorTarifario = distribuidorEnergia.sectorTarifario;
    this.aereoOsubterraneo = distribuidorEnergia.aereoOsubterraneo ?? "";
  }

  id: string | null = null;

  empresaDecretoTarifario = "";
  comuna = "";
  sectorTarifario = "";
  aereoOsubterraneo = "";

  itemsEmpresaDecretoTarifario = ["CGE DISTRIBUCIÓN","CONAFE","ELECDA","ELIQSA","EMELARI","EMELAT","ENEL","FRONTEL"];
  itemsAereoOsubterraneo = ["AÉREO","SUBT.1","SUBT.2","SUBT.3"];

  get titulo(): string {
    return this.distribuidorEnergia
      ? "editar distribuidorEnergia"
      : "nuevo distribuidorEnergia";
  }

  //   get permisosKey(): Array<string> {
  //     // return Object.keys(this.permisos).filter((key) => {
  //     //   if(this.rutas[key].tipos.includes(this.tipo)){
  //     //     this.permisos[key] = true;
  //     //   }
  //     // });
  //     return Object.keys(this.permisos).filter((key) => {
  //       if (!this.rutas[key]) {
  //         return false;
  //       }
  //       const tipo = this.rutas[key].tipos.includes(this.tipo);
  //       return tipo;
  //     });
  //   }

  get empresaDecretoTarifarioError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.empresaDecretoTarifario.$dirty) return errors;
    if (!this.$v.empresaDecretoTarifario.required) errors.push("");
    return errors;
  }

  get comunaError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.comuna.$dirty) return errors;
    if (!this.$v.comuna.required) errors.push("");
    return errors;
  }

  get aereoOsubterraneoError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.aereoOsubterraneo.$dirty) return errors;
    if (!this.$v.aereoOsubterraneo.required) errors.push("");
    return errors;
  }


  @Emit("close")
  onClose(): null {
    return null;
  }

  @Emit("cerrar-dialogo")
  onCerrarDialogo(): void {
    return;
  }

  @Emit("click-guardar")
  onClickGuardar(
    data: DataFormularioDistribuidorEnergia
  ): DataFormularioDistribuidorEnergia {
    return data;
  }

  //   @Watch("permisosKey", { immediate: true })
  //   onTipoChange(): void {
  //     console.log("a");
  //     for (const key of Object.keys(this.permisos)) {
  //       if (this.permisos[key]) {
  //         this.permisos[key] = false;
  //       }
  //     }
  //     this.permisosKey.forEach((key) => {
  //       this.permisos[key] = true;
  //     });
  //   }

  //   nombrePermiso(key: string): string {
  //     return this.rutas[key].text;
  //   }

  guardar(): void {
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }
    const doc: DistribuidorEnergia = {
      empresaDecretoTarifario: this.empresaDecretoTarifario,
      comuna: this.comuna,
      sectorTarifario: this.sectorTarifario,
      aereoOsubterraneo: this.aereoOsubterraneo,
    };
    if (this.id) {
      doc.id = this.id;
    }
    const data: DataFormularioDistribuidorEnergia = {
      doc: doc,
    };
    this.onClickGuardar(data);
  }
}
