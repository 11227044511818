
















































































































































































// decoradores
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
// tipos
import { HeadersDataTable } from "@/typings/vuetify/vDataTable";
import { CentroCosto } from "@/typings/store/plugins/easyFirestore/centrosCostos";
import { ItemsTablaCentrosCostos } from "@/typings/components/centrosCostos/tabla";
import "@/helpers/extensions";

@Component
export default class TablaCentrosCostos extends Vue {
  @Prop() readonly opciones!: boolean;
  @Prop() readonly search!: string;
  @Prop() readonly centrosCostos!: Array<CentroCosto>;

  options = {
    itemsPerPage: 15,
  };
  headerProps = {
    sortIcon: "mdi-chevron-down",
  };

  get headers(): HeadersDataTable {
    return [
      //{text: "", value: "estado", sortable: false},
      { text: "Codigo", value: "codigo" },
      { text: "Descripcion", value: "descripcion" },
      { text: "Nivel", value: "nivel" },
      { text: "Activo", value: "activo" },
      { text: "Desc. Formato", value: "descFormato" },
        { text: "Comuna", value: "comuna" },
      { text: "", value: "opciones", sortable: false },
    ];
  }

  get items(): ItemsTablaCentrosCostos {
    return this.centrosCostos.map((centroCosto) => {
      const codigo = centroCosto.CodiCC;
      const descripcion = centroCosto.DescCC;
      const nivel = centroCosto.NivelCC;
      const activo = centroCosto.Activo;
      const descFormato = centroCosto.Desc_formato;
      const comuna = centroCosto.Comuna;

      return {
        codigo,
        descripcion,
        nivel,
        activo,
        descFormato,
        comuna,


        data: centroCosto,
      };
    });
  }

  @Emit("abrir-info")
  onAbrirInfo(centroCosto: CentroCosto): CentroCosto {
    return centroCosto;
  }

  @Emit("abrir-editar")
  onAbrirEditar(centroCosto: CentroCosto): CentroCosto {
    return centroCosto;
  }

  @Emit("abrir-eliminar")
  onAbrirEliminar(centroCosto: CentroCosto): CentroCosto {
    return centroCosto;
  }
}
