































































// decoradores
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { MatrizPago } from "@/typings/store/plugins/easyFirestore/matrizPagos";
import SVGIcon from "@/components/SVGIcon.vue";
// tipos

// tipos
@Component({
  components: {
    SVGIcon,
  },
})
export default class InfoMatrizPago extends Vue {
  @Prop() readonly matrizPago!: MatrizPago;
  @Prop({ type: Boolean }) readonly showTitulo!: boolean;

  get id(): string {
    return this.matrizPago.id || "N/R";
  }

  // get nombre(): string {
  //   return this.matrizPago || "N/R";
  // }

  // get posicion(): string {
  //   return this.matrizPago.posicion || "N/R";
  // }


  @Emit("cerrar")
  onCerrar(): void {
    return;
  }
}
