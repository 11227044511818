import { ModuleTree } from "vuex";
import { State } from "@/typings/store";

// modules
import drawer from "./drawer";
import cargando from "./cargando";
import usuario from "./usuario";
import rutas from "./rutas";
import archivos from "./archivos";

const modules: ModuleTree<State> = {
  archivos,
  cargando,
  drawer,
  rutas,
  usuario,
};

export default modules;
