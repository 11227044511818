






import { Component, Vue } from "vue-property-decorator"; // Importa las utilidades de Vue si es necesario
import ufService from "@/services/ufService";

@Component({})
export default class MyComponent extends Vue {
  ufValue: number | null = null;
  // eslint-disable-next-line
  async mounted() {
    try {
      this.ufValue = await ufService.getUFValue("2023", "01", "01");
    } catch (error) {
      console.error("Error al obtener el valor de la UF", error);
    }
  }
}
