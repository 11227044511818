































































// decoradores
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { Lectura } from "@/typings/store/plugins/easyFirestore/lecturas";
import SVGIcon from "@/components/SVGIcon.vue";
// tipos

// tipos
@Component({
  components: {
    SVGIcon,
  },
})
export default class InfoLectura extends Vue {
  @Prop() readonly lectura!: Lectura;
  @Prop({ type: Boolean }) readonly showTitulo!: boolean;

  get id(): string {
    return this.lectura.id || "N/R";
  }

  get usuario(): string {
    return this.lectura.usuario || "N/R";
  }

  get fecha(): string {
    return this.lectura.fecha || "N/R";
  }


  @Emit("cerrar")
  onCerrar(): void {
    return;
  }
}
