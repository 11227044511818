












































































































































































// decoradores
import {Component, Emit, Prop, Vue} from "vue-property-decorator";
// tipos
import {HeadersDataTable} from "@/typings/vuetify/vDataTable";
import { Equipo } from "@/typings/store/plugins/easyFirestore/equipos";
import {ItemsTablaEquipos} from "@/typings/components/equipos/tabla";
import "@/helpers/extensions";

@Component
export default class TablaUsuarios extends Vue {
  @Prop() readonly opciones!: boolean;
  @Prop() readonly search!: string;
  @Prop() readonly equipos!: Array<Equipo>;

  options = {
    itemsPerPage: -1,
  };
  headerProps = {
    sortIcon: "mdi-chevron-down"
  }

  get headers(): HeadersDataTable {
    return [
      //{text: "", value: "estado", sortable: false},
      {text: "Desglose", value: "desglose"},
      {text: "Nombre", value: "nombre"},
      {text: "Marca", value: "marca"},
      {text: "Modelo", value: "modelo"},
      {text: "Ubicacion", value: "ubicacion"},
      {text: "Foto (Pendiente)", value: "foto"},
      {text: "Piso", value: "piso"},


      {text: "", value: "opciones", sortable: false}
    ];
  }

  get items(): ItemsTablaEquipos {
    return this.equipos.map((equipo) => {
      const nombre = equipo.nombre;
      const posicion = equipo.posicion;
      const desglose = equipo.desglose ;
      const marca = equipo.marca ;
      const modelo = equipo.modelo ;
      const ubicacion = equipo.ubicacion ;
      const foto = equipo.foto ;
      const piso = equipo.piso ;
    
    
      return {
        nombre,
        posicion,
        desglose,
        marca,
        modelo,
        ubicacion,
        foto,
        piso,
       
        data: equipo,
      };
    });
  }

//   actualizarEstado(item: Usuario): void {
//     let estado: EstadoUsuario;
//     item.estado === 'libre' ? estado = 'bloqueado' : estado = 'libre';
//     item.estado = estado;
//     this.onCambiarEstado(item);
//   }

//   @Emit("cambiar-estado")
//   onCambiarEstado(usuario: Usuario): Usuario {
//     return usuario;
//   }

  @Emit("abrir-info")
  onAbrirInfo(equipo: Equipo): Equipo {
    return equipo;
  }

  @Emit("abrir-editar")
  onAbrirEditar(equipo: Equipo): Equipo {
    return equipo;
  }

  @Emit("abrir-eliminar")
  onAbrirEliminar(equipo: Equipo): Equipo {
    return equipo;
  }
}
