















































// decoradores
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import {Ciudad} from "@/typings/store/plugins/easyFirestore/ciudades";
// tipos

@Component
export default class InfoCiudad extends Vue {
  @Prop() readonly ciudad!: Ciudad;
  @Prop({ type: Boolean }) readonly showTitulo!: boolean;

  get id(): string {
    return this.ciudad.id || "N/R";
  }

  get nombre(): string {
    return this.ciudad.nombre || "N/R";
  }

  get region(): string {
    return this.ciudad.region.nombre || "N/R";
  }

  @Emit("close")
  onClose(): null {
    return null;
  }
}
