

























import { Component, Vue, Prop } from "vue-property-decorator";
@Component
export default class ComponenteTotalCursosVendidos extends Vue {
  @Prop() readonly vendidosCursosTotal!: number;
  @Prop() readonly diferenciaPorcentualVendidos!: number;
}
