















































































































































































































































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
// helpers
//import { idtify } from "@/helpers/string";
// tipos
//import { Rutas } from "@/typings/store/modules/rutas";
//import {
//  Permisos,
//  TipoUsuario,
//  Usuario,
//} from "@/typings/store/plugins/easyFirestore/usuarios";
import {
  CondicionDivision,
  //Bancos
} from "@/typings/store/plugins/easyFirestore/condicionesDivisiones";
// validaciones
import {
  // email,
  required,
} from "vuelidate/lib/validators";
import { DataFormularioCondicionDivision } from "@/typings/components/condicionesDivisiones/formulario";
import SVGIcon from "@/components/SVGIcon.vue";
//import { Archivo, Archivos } from "@/typings/store/modules/archivos";

@Component({
  components: {
    SVGIcon,
  },
  validations: {
    // nombre: { required },
    // posicion: { required },
    codigoDivision: { required },
    nombreDivision: { required },
    moneda: { required },
    escala: { required },
    indexacion: { required },
    minimo: { required },
    aporte: { required },
    operador: { required },
    origen: { required },
    codigoContrato: { required },
    codigoCondicion: { required },
  },
})
export default class FormularioCondicionDivision extends Vue {
  @Prop() readonly condicionDivision!: CondicionDivision | null;
  @Prop() readonly cargando!: boolean;
  //   @Prop() readonly rutas!: Rutas;
  //   @Prop() readonly ciudades!: Ciudad[];
  //   @Prop() readonly cargando!: boolean;
  //   @Prop() readonly regiones!: Region[];

  created(): void {
    // Object.keys(this.rutas).forEach((key) => {
    //   this.$set(this.permisos, key, false);
    // });
    // console.log(this.permisos);
    const condicionDivision = this.condicionDivision;
    if (!condicionDivision) {
      return;
    }
    this.id = condicionDivision.id ?? "";
    // this.nombre = condicionDivision.nombre;
    // this.posicion = condicionDivision.posicion ?? "";

    this.codigoDivision = condicionDivision.codigo_division ?? 0;
    this.nombreDivision = condicionDivision.nombre_division ?? "";
    this.moneda = condicionDivision.moneda ?? "";
    this.escala = condicionDivision.escala ?? "";
    this.indexacion = condicionDivision.indexacion ?? "";
    this.minimo = condicionDivision.minimo ?? 0;
    this.aporte = condicionDivision.aporte ?? "";
    this.operador = condicionDivision.operador ?? "";
    this.origen = condicionDivision.origen ?? "";
    this.codigoContrato = condicionDivision.codigo_contrato ?? 0;
    this.codigoCondicion = condicionDivision.codigo_condicion ?? 0;
  }

  id: string | null = null;

  // nombre = "";
  // posicion = "";
  codigoDivision = 0;
  nombreDivision = "";
  moneda = "";
  escala = "";
  indexacion = "";
  minimo = 0;
  aporte = "";
  operador = "";
  origen = "";
  codigoContrato = 0;
  codigoCondicion = 0;

  itemsCodDivision = ["1", "2", "3", "4", "5"];

  itemsDivision = [
    "Alquiler Fijo",
    "Alquiler Variable",
    "Gastos Comunes",
    "Fdo. Promocion Fijo",
    "Fdo. Promocion Variable",
  ];
  itemsMoneda = ["PESOS CHILENOS", "DOLARES"];
  itemsEscala = ["DIRECTA", "PROGRESIVA", "NO APLICA"];
  itemsIndexacion = ["UNIDAD DE FOMENTO - INDEXACION", "PESOS CHILENOS"];
  itemsAporte = ["SI", "NO", "NO APLICA"];
  itemsOperador = ["SIN MINIMO", "OPCION", "MAS", "NO APLICA"];
  itemsOrigen = ["VEN/NETAS", "VEN/BRUTAS", "ALQUILER", "NO APLICA"];

  get titulo(): string {
    return this.condicionDivision
      ? "editar condicionDivision"
      : "nuevo condicionDivision";
  }

  //   get permisosKey(): Array<string> {
  //     // return Object.keys(this.permisos).filter((key) => {
  //     //   if(this.rutas[key].tipos.includes(this.tipo)){
  //     //     this.permisos[key] = true;
  //     //   }
  //     // });
  //     return Object.keys(this.permisos).filter((key) => {
  //       if (!this.rutas[key]) {
  //         return false;
  //       }
  //       const tipo = this.rutas[key].tipos.includes(this.tipo);
  //       return tipo;
  //     });
  //   }

  get codigoDivisionError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.codigoDivision.$dirty) return errors;
    if (!this.$v.codigoDivision.required)
      errors.push("Completar datos obligatorios");
    return errors;
  }

  get nombreDivisionError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.nombreDivision.$dirty) return errors;
    if (!this.$v.nombreDivision.required)
      errors.push("Completar datos obligatorios");
    return errors;
  }

  get monedaError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.moneda.$dirty) return errors;
    if (!this.$v.moneda.required) errors.push("Completar datos obligatorios");
    return errors;
  }

  get escalaError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.escala.$dirty) return errors;
    if (!this.$v.escala.required) errors.push("Completar datos obligatorios");
    return errors;
  }

  get indexacionError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.indexacion.$dirty) return errors;
    if (!this.$v.indexacion.required)
      errors.push("Completar datos obligatorios");
    return errors;
  }

  get minimoError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.minimo.$dirty) return errors;
    if (!this.$v.minimo.required) errors.push("Completar datos obligatorios");
    return errors;
  }

  get aporteError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.aporte.$dirty) return errors;
    if (!this.$v.aporte.required) errors.push("Completar datos obligatorios");
    return errors;
  }

  get operadorError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.operador.$dirty) return errors;
    if (!this.$v.operador.required) errors.push("Completar datos obligatorios");
    return errors;
  }

  get origenError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.origen.$dirty) return errors;
    if (!this.$v.origen.required) errors.push("Completar datos obligatorios");
    return errors;
  }

  get codigoContratoError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.codigoContrato.$dirty) return errors;
    if (!this.$v.codigoContrato.required)
      errors.push("Completar datos obligatorios");
    return errors;
  }

  get codigoCondicionError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.codigoCondicion.$dirty) return errors;
    if (!this.$v.codigoCondicion.required)
      errors.push("Completar datos obligatorios");
    return errors;
  }

  @Emit("close")
  onClose(): null {
    return null;
  }

  @Emit("cerrar-dialogo")
  onCerrarDialogo(): void {
    return;
  }

  @Emit("click-guardar")
  onClickGuardar(
    data: DataFormularioCondicionDivision
  ): DataFormularioCondicionDivision {
    return data;
  }

  //   @Watch("permisosKey", { immediate: true })
  //   onTipoChange(): void {
  //     console.log("a");
  //     for (const key of Object.keys(this.permisos)) {
  //       if (this.permisos[key]) {
  //         this.permisos[key] = false;
  //       }
  //     }
  //     this.permisosKey.forEach((key) => {
  //       this.permisos[key] = true;
  //     });
  //   }

  //   nombrePermiso(key: string): string {
  //     return this.rutas[key].text;
  //   }

  guardar(): void {
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }
    const doc: CondicionDivision = {
      codigo_division: this.codigoDivision,
      nombre_division: this.nombreDivision,
      moneda: this.moneda,
      escala: this.escala,
      indexacion: this.indexacion,
      minimo: this.minimo,
      aporte: this.aporte,
      operador: this.operador,
      origen: this.origen,
      codigo_contrato: this.codigoContrato,
      codigo_condicion: this.codigoCondicion,
    };
    if (this.id) {
      doc.id = this.id;
    }
    const data: DataFormularioCondicionDivision = {
      doc: doc,
    };
    this.onClickGuardar(data);
  }
}
