import { RouteConfig } from "vue-router";
import Bancos from "@/views/Bancos.vue";

const bancos: RouteConfig = {
    path: "/bancos",
    name: "bancos",
    component: Bancos,
};

export default bancos;
