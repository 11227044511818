import { RouteConfig } from "vue-router";
import CompraExitosa from "@/views/CompraExitosa.vue";

const compraExitosa: RouteConfig = {
  path: "/compraExitosa/:id",
  name: "compraExitosa",
  component: CompraExitosa
};

export default compraExitosa;
