










































































































































































// decoradores
import { Vue, Component } from "vue-property-decorator";
// vuex
import { mapActions, mapGetters } from "vuex";
// tipos

// componentes
import TablaSolicitud from "@/components/solicitudes/Tabla.vue";
import FormularioSoliciud from "@/components/solicitudes/Formulario.vue";
import FormularioAprobacion from "@/components/solicitudes/FormularioAprobacion.vue";
import { DataFormularioSolicitud } from "@/typings/components/solicitudes/formulario";
import DialogoExito from "@/components/DialogoExito.vue";
import DialogoEliminar from "@/components/DialogoEliminar.vue";
import InfoSolicitud from "@/components/solicitudes/Info.vue";
//import BotonesCrud from "@/components/BotonesCrud.vue";
import MensajeEliminar from "@/components/MensajeEliminar.vue";
//import TituloVista from "@/components/TituloVista.vue";
import {
  Solicitud,
  Solicitudes,
  //  Regiones,
} from "@/typings/store/plugins/easyFirestore/solicitudes";
//import { DataFormularioRegion } from "@/typings/components/regiones/formulario";
//import CargarBancosExcel from "@/components/excel/CargarBancos.vue";
import { MapeoGasto } from "@/typings/store/plugins/easyFirestore/MapeoGastos";

import { TDocumentDefinitions } from "pdfmake/interfaces";
import pdfMake from "pdfmake/build/pdfmake";

const routeGuard = (vm: Vue): boolean => {
  const permisos = vm.getUsuario?.permisos;
  if (permisos && permisos["solicitudes"]) {
    return true;
  }

  const rutas = vm.getRutasUsuario;
  if (rutas.length > 0) {
    const to = rutas[0].to;
    if (to) {
      if (vm.$route.name !== to.name) {
        vm.$router.replace(to);
      }
      return false;
    }
  }
  if (vm.$route.name !== "inicio") {
    vm.$router.replace({ name: "inicio" });
  }
  return false;
};

@Component({
  computed: mapGetters({
    getUsuarios: "usuarios/get",
    getSolicitudes: "solicitudes/get",
    getArrayUsuarios: "usuarios/getArray",
    getUsuario: "usuario/get",
    getRutas: "rutas/get",
    getRutasUsuario: "usuario/getRutas",
    getArraySolicitudes: "solicitudes/getArray",
    getArrayMapeoGastos: "mapeoGastos/getArray",
    getArrayCiudades: "ciudades/getArray",
  }),
  methods: mapActions({
    setSolicitud: "solicitudes/set",
    deleteSolicitud: "solicitudes/delete",
    insertBatchBancos: "solicitudes/insertBatch",
    //setArchivo: "archivos/set",
    //deleteArchivo: "archivos/delete",
  }),
  beforeRouteEnter(to, from, next) {
    next(routeGuard);
  },
  components: {
    DialogoEliminar,
    DialogoExito,
    TablaSolicitud,
    FormularioSoliciud,
    InfoSolicitud,
    //BotonesCrud,
    MensajeEliminar,
    FormularioAprobacion,
    //CargarBancosExcel,
    //TituloVista,
  },
})
export default class VistaSolicitudes extends Vue {
  created(): void {
    if (!routeGuard(this)) {
      return;
    }
  }

  cargando = false;
  dialogoExito = {
    model: false,
    texto: "",
    icono: "",
  };
  search = "";
  solicitud: Solicitud | null = null;
  mapeoGasto: MapeoGasto | null = null;
  dialogoInfo = false;
  dialogoFormulario = false;
  dialogoFormularioAprobacion = false;

  dialogoEliminar = false;
  textoEliminar = "¿ESTÁS SEGURO QUE DESEAS ELIMINAR ESTA SOLICITUD?";
  dialogoCargarExcel = false;

  // Variables de Filtros
  filtrarCentroCosto = "";
  filtrarProveedor = "";
  filtrarEstado = "";
  filtrarOrden = "";

  // Arrays para Filtros
  get centrosCostos(): Array<string> {
    let centrosCostos: Array<string> = [];
    this.getArraySolicitudes.forEach((solicitud) => {
      if (!centrosCostos.includes(solicitud.centroCosto)) {
        centrosCostos.push(solicitud.centroCosto);
      }
    });
    centrosCostos.sort((a, b) =>
      a.localeCompare(b, undefined, { numeric: true, sensitivity: "base" })
    );
    return centrosCostos;
  }

  get empresas(): Array<string> {
    let empresas: Array<string> = [];
    this.getArraySolicitudes.forEach((solicitud) => {
      if (!empresas.includes(solicitud.empresa)) {
        empresas.push(solicitud.empresa);
      }
    });
    empresas.sort((a, b) =>
      a.localeCompare(b, undefined, { numeric: true, sensitivity: "base" })
    );
    return empresas;
  }

  get estados(): Array<string> {
    let estados: Array<string> = [];
    this.getArraySolicitudes.forEach((solicitud) => {
      if (!estados.includes(solicitud.estado)) {
        estados.push(solicitud.estado);
      }
    });
    estados.sort((a, b) =>
      a.localeCompare(b, undefined, { numeric: true, sensitivity: "base" })
    );
    return estados;
  }

  get ordenesCompra(): Array<string> {
    // let centrosCostos: Array<string> = [];
    // this.getArraySolicitudes.forEach((solicitud) => {
    //   if (!centrosCostos.includes(solicitud.centroCosto)) {
    //     centrosCostos.push(solicitud.centroCosto);
    //   }
    // });
    // centrosCostos.sort((a, b) =>
    //   a.localeCompare(b, undefined, { numeric: true, sensitivity: "base" })
    // );
    return ["PENDIENTE"];
  }

  get solicitudes(): Solicitudes {
    return this.getSolicitudes;
  }

  get items(): Array<Solicitud> {
    return this.getArraySolicitudes.filter((solicitud) => {
      if (this.filtrarCentroCosto) {
        return (
          solicitud.centroCosto
            .toLowerCase()
            .indexOf(this.filtrarCentroCosto.toLowerCase()) !== -1
        );
      }
      if (this.filtrarProveedor) {
        return (
          solicitud.empresa
            .toLowerCase()
            .indexOf(this.filtrarProveedor.toLowerCase()) !== -1
        );
      }
      if (this.filtrarOrden) {
        return (
          solicitud.ordenCompra
            .toString()
            .toLowerCase()
            .indexOf(this.filtrarOrden.toLowerCase()) !== -1
        );
      }
      if (this.filtrarEstado) {
        return (
          solicitud.estado
            .toString()
            .toLowerCase()
            .indexOf(this.filtrarEstado.toLowerCase()) !== -1
        );
      }
      return solicitud.estado;
    });
  }

  clickItem(val: { id: string } | null): void {
    this.solicitud = val ? this.solicitudes[val.id] : null;
  }

  clickPlus(): void {
    this.solicitud = null;
    this.dialogoFormulario = true;
  }

  async clickGuardar(data: DataFormularioSolicitud): Promise<void> {
    this.cargando = true;
    const doc = data.doc;
    await this.setSolicitud(doc);
    if (doc.id != this.solicitud?.id) {
      this.dialogoExito = {
        model: true,
        texto: "¡Solicitud creada con éxito!",
        icono: require("../assets/icons/double-check-duo.svg"),
      };
    }
    if (doc.id == this.solicitud?.id) {
      this.dialogoExito = {
        model: true,
        texto: "¡Solicitud editada con éxito!",
        icono: require("../assets/icons/editar.svg"),
      };
      this.solicitud = doc;
    }
    this.cerrarDialogoFormulario();
    this.cargando = false;
  }

  cerrarDialogoExito(): void {
    this.dialogoExito = {
      model: false,
      texto: "",
      icono: "",
    };
  }

  clickEliminar(): void {
    const solicitud = this.solicitud;
    if (!solicitud?.id) {
      console.error("error id: ", solicitud);
      return;
    }
    this.deleteSolicitud(solicitud.id);
    this.solicitud = null;
    this.cerrarDialogoEliminar();
  }

  abrirDialogoInfo(solicitud: Solicitud): void {
    if (!solicitud.id) {
      console.error("error id: ", solicitud);
      return;
    }
    const doc = this.solicitudes[solicitud.id];
    if (doc) {
      this.solicitud = doc;
      this.dialogoInfo = true;
    }
  }

  cerrarDialogoInfo(): void {
    this.dialogoInfo = false;
    this.solicitud = null;
  }

  abrirDialogoFormulario(solicitud: Solicitud): void {
    if (!solicitud.id) {
      console.error("error id: ", solicitud);
      return;
    }
    const doc = this.solicitudes[solicitud.id];
    if (doc) {
      this.solicitud = doc;
      this.dialogoFormulario = true;
    }
  }

  abrirDialogoFormularioAprobacion(solicitud: Solicitud): void {
    if (!solicitud.id) {
      console.error("error id: ", solicitud);
      return;
    }
    const doc = this.solicitudes[solicitud.id];
    if (doc) {
      this.solicitud = doc;
      this.dialogoFormularioAprobacion = true;
    }
  }

  async generaPdf(solicitud: Solicitud): Promise<void> {
    if (!solicitud.id) {
      console.error("error id: ", solicitud);
      return;
    }
    const doc = this.solicitudes[solicitud.id];
    if (doc) {
      const link = { url: require("@/assets/logo_salhus_white2.jpg") };
      const linkAprobada = {
        url: require("@/assets/pdfestados_pages-to-jpg-aprobada.jpg"),
      };
      const result = await fetch(link.url);
      const resultAprobada = await fetch(linkAprobada.url);
      const result2 = await result.blob();
      const result2Aprobada = await resultAprobada.blob();
      //const fechaFormateada = new Date();
      const base64: string = await new Promise((callback) => {
        let reader = new FileReader();
        reader.onload = function () {
          callback(this.result?.toString() ?? "");
        };
        reader.readAsDataURL(result2);
      });

      const base64_1: string = await new Promise((callback) => {
        let reader = new FileReader();
        reader.onload = function () {
          callback(this.result?.toString() ?? "");
        };
        reader.readAsDataURL(result2Aprobada);
      });

      var dd: TDocumentDefinitions = {
        content: [
          {
            columns: [
              {
                image: "logo",
                alignment: "right",
                height: 30,
                width: 100,
              },
              {
                text: "",
              },
              {
                text: "",
              },
            ],
          },
          {
            text: "INVERSIONES SANTA FIDELMIRA LIMITADA",
            style: "header",
          },
          {
            text: "79.991.280-5\nARRIENDO Y EXPLOTACION DE BIENES INMUEBLES\nLAUTARO 429 PISO 2 - LINARES\n732 210881\n\n",
            style: "subheaderSB_Centro",
          },

          {
            style: "tableExample",
            table: {
              headerRows: 1,
              widths: ["*"],
              body: [
                [
                  {
                    text:
                      "Solicitud de Orden de Compra N° " +
                      solicitud.ordenCompra,
                    style: "titulo",
                    border: [false, false, false, true],
                    borderColor: "rgba(0, 0, 0, 0.5)" 
                  },
                ],
              ],
            },
          },

          "\n",
          {
            columns: [
              {
                style: "tableExample",
                table: {
                  widths: ["*"],
                  body: [
                    [
                      {
                        style: "tableExample",
                        table: {
                          headerRows: 1,
                          widths: [10, 60, "*", 60, "*"],
                          body: [
                            [
                              { text: "" },
                              { text: "", style: "subheaderCB" },
                              { text: "", style: "subheaderSB" },
                              { text: "Fecha Emisión:", style: "subheaderCB" },
                              {
                                text: solicitud.fechaEmision,
                                style: "subheaderSB",
                              },
                            ],
                            [
                              { text: "" },
                              { text: "", style: "subheaderCB" },
                              { text: "", style: "subheaderSB" },
                              { text: "Estado:", style: "subheaderCB" },
                              {
                                text: solicitud.estado.capitalizeFirstLetter(),
                                style: "subheaderSB",
                              },
                            ],
                            [
                              { text: "" },
                              { text: "", style: "subheaderCB" },
                              { text: "", style: "subheaderSB" },
                              {
                                text: "Centro de Costo:",
                                style: "subheaderCB",
                              },
                              {
                                text: solicitud.centroCosto,
                                style: "subheaderSB",
                              },
                            ],
                            [
                              { text: "" },
                              { text: "", style: "subheaderCB" },
                              { text: "", style: "subheaderSB" },
                              { text: "Moneda:", style: "subheaderCB" },
                              { text: solicitud.divisa, style: "subheaderSB" },
                            ],
                          ],
                        },
                        layout: "noBorders",
                      },
                    ],
                  ],
                },
                layout: "noBorders",
                
              },

            ],
          },
          "\n",

          {
            style: "tableExample",
            table: {
              widths: ["*"],
              body: [
                [
                  {
                    style: "tableExample",
                    table: {
                      headerRows: 1,
                      widths: [10, 60, "*", 60, "*"],
                      body: [
                        [
                          { text: "" },
                          { text: "Razón Social:", style: "subheaderCB" },
                          { text: solicitud.empresa, style: "subheaderSB" },
                          { text: "Rut:", style: "subheaderCB" },
                          { text: solicitud.rut, style: "subheaderSB" },
                        ],
                        [
                          { text: "" },
                          { text: "Dirección:", style: "subheaderCB" },
                          { text: solicitud.direccion, style: "subheaderSB" },
                          { text: "Teléfono:", style: "subheaderCB" },
                          { text: solicitud.telefono, style: "subheaderSB" },
                        ],
                        [
                          { text: "" },
                          { text: "Comuna:", style: "subheaderCB" },
                          { text: solicitud.comuna, style: "subheaderSB" },
                          { text: "Contacto:", style: "subheaderCB" },
                          { text: solicitud.contacto, style: "subheaderSB" },
                        ],
                        [
                          { text: "" },
                          { text: "Forma de Pago:", style: "subheaderCB" },
                          { text: solicitud.formaPago, style: "subheaderSB" },
                          { text: "Mail:", style: "subheaderCB" },
                          { text: solicitud.correo, style: "subheaderSB" },
                        ],
                      ],
                    },
                    layout: "noBorders",
                  },
                ],
              ],
            },
          },
          "\n",
          {
            style: "tableExample",
            table: {
              widths: [50, 50, 200, "*", "*"],
              body: [
                [
                  {
                    text: "Cantidad",
                    style: "subheaderCB",
                    alignment: "center",
                  },
                  {
                    text: "Uni. Medida",
                    alignment: "center",
                    style: "subheaderCB",
                  },
                  {
                    text: "Descripción",
                    style: "subheaderCB",
                    alignment: "center",
                  },
                  {
                    text: "P. Unitario",
                    style: "subheaderCB",
                    alignment: "center",
                  },
                  {
                    text: "Valor Total",
                    style: "subheaderCB",
                    alignment: "center",
                  },
                ],
                [
                  {
                    text: solicitud.cantidad_1,
                    style: "subheaderSB",
                    alignment: "center",
                  },
                  {
                    text: solicitud.unidadMedida_1,
                    style: "subheaderSB",
                    alignment: "center",
                  },
                  {
                    text: solicitud.descripcion_1,
                    style: "subheaderSB",
                    alignment: "center",
                  },
                  {
                    text: parseInt(
                      solicitud.precioUnitario_1.toString()
                    ).toCurrency({
                      symbol: "$",
                    }),
                    style: "subheaderSB_Derecha",
                  },
                  {
                    text: solicitud.precioTotal_1.toCurrency({ symbol: "$" }),
                    style: "subheaderSB_Derecha",
                  },
                ],
                solicitud.precioTotal_2
                  ? [
                      {
                        text: solicitud.cantidad_2,
                        style: "subheaderSB",
                        alignment: "center",
                      },
                      {
                        text: solicitud.unidadMedida_2,
                        style: "subheaderSB",
                        alignment: "center",
                      },
                      {
                        text: solicitud.descripcion_2,
                        style: "subheaderSB",
                        alignment: "center",
                      },
                      {
                        text: parseInt(
                          solicitud.precioUnitario_2.toString()
                        ).toCurrency({ symbol: "$" }),
                        style: "subheaderSB_Derecha",
                      },
                      {
                        text: solicitud.precioTotal_2.toCurrency({
                          symbol: "$",
                        }),
                        style: "subheaderSB_Derecha",
                      },
                    ]
                  : [
                      {
                        text: "",
                        style: "subheaderSB",
                        alignment: "center",
                        border: [false, false, false, false],
                      },
                      {
                        text: "",
                        style: "subheaderSB",
                        alignment: "center",
                        border: [false, false, false, false],
                      },
                      {
                        text: "",
                        style: "subheaderSB",
                        alignment: "center",
                        border: [false, false, false, false],
                      },

                      {
                        text: "",
                        style: "subheaderSB_Derecha",
                        border: [false, false, false, false],
                      },
                      {
                        text: "",
                        style: "subheaderSB_Derecha",
                        border: [false, false, false, false],
                      },
                    ],

                solicitud.precioTotal_3
                  ? [
                      {
                        text: solicitud.cantidad_3,
                        style: "subheaderSB",
                        alignment: "center",
                      },
                      {
                        text: solicitud.unidadMedida_3,
                        style: "subheaderSB",
                        alignment: "center",
                      },
                      {
                        text: solicitud.descripcion_3,
                        style: "subheaderSB",
                        alignment: "center",
                      },

                      {
                        text: parseInt(
                          solicitud.precioUnitario_3.toString()
                        ).toCurrency({ symbol: "$" }),
                        style: "subheaderSB_Derecha",
                      },
                      {
                        text: solicitud.precioTotal_3.toCurrency({
                          symbol: "$",
                        }),
                        style: "subheaderSB_Derecha",
                      },
                    ]
                  : [
                      {
                        text: "",
                        style: "subheaderSB",
                        alignment: "center",
                        border: [false, false, false, false],
                      },
                      {
                        text: "",
                        style: "subheaderSB",
                        alignment: "center",
                        border: [false, false, false, false],
                      },
                      {
                        text: "",
                        style: "subheaderSB",
                        alignment: "center",
                        border: [false, false, false, false],
                      },

                      {
                        text: "",
                        style: "subheaderSB_Derecha",
                        border: [false, false, false, false],
                      },
                      {
                        text: "",
                        style: "subheaderSB_Derecha",
                        border: [false, false, false, false],
                      },
                    ],

                solicitud.precioTotal_4
                  ? [
                      {
                        text: solicitud.cantidad_4,
                        style: "subheaderSB",
                        alignment: "center",
                      },
                      {
                        text: solicitud.unidadMedida_4,
                        style: "subheaderSB",
                        alignment: "center",
                      },
                      {
                        text: solicitud.descripcion_4,
                        style: "subheaderSB",
                        alignment: "center",
                      },
                      {
                        text: parseInt(
                          solicitud.precioUnitario_4.toString()
                        ).toCurrency({ symbol: "$" }),
                        style: "subheaderSB_Derecha",
                      },
                      {
                        text: solicitud.precioTotal_4.toCurrency({
                          symbol: "$",
                        }),
                        style: "subheaderSB_Derecha",
                      },
                    ]
                  : [
                      {
                        text: "",
                        style: "subheaderSB",
                        alignment: "center",
                        border: [false, false, false, false],
                      },
                      {
                        text: "",
                        style: "subheaderSB",
                        alignment: "center",
                        border: [false, false, false, false],
                      },
                      {
                        text: "",
                        style: "subheaderSB",
                        alignment: "center",
                        border: [false, false, false, false],
                      },

                      {
                        text: "",
                        style: "subheaderSB_Derecha",
                        border: [false, false, false, false],
                      },
                      {
                        text: "",
                        style: "subheaderSB_Derecha",
                        border: [false, false, false, false],
                      },
                    ],

                solicitud.precioTotal_5
                  ? [
                      {
                        text: solicitud.cantidad_5,
                        style: "subheaderSB",
                        alignment: "center",
                      },
                      {
                        text: solicitud.unidadMedida_5,
                        style: "subheaderSB",
                        alignment: "center",
                      },
                      {
                        text: solicitud.descripcion_5,
                        style: "subheaderSB",
                        alignment: "center",
                      },

                      {
                        text: parseInt(
                          solicitud.precioUnitario_5.toString()
                        ).toCurrency({ symbol: "$" }),
                        style: "subheaderSB_Derecha",
                      },
                      {
                        text: solicitud.precioTotal_5.toCurrency({
                          symbol: "$",
                        }),
                        style: "subheaderSB_Derecha",
                      },
                    ]
                  : [
                      {
                        text: "",
                        style: "subheaderSB",
                        alignment: "center",
                        border: [false, false, false, false],
                      },
                      {
                        text: "",
                        style: "subheaderSB",
                        alignment: "center",
                        border: [false, false, false, false],
                      },
                      {
                        text: "",
                        style: "subheaderSB",
                        alignment: "center",
                        border: [false, false, false, false],
                      },

                      {
                        text: "",
                        style: "subheaderSB_Derecha",
                        border: [false, false, false, false],
                      },
                      {
                        text: "",
                        style: "subheaderSB_Derecha",
                        border: [false, false, false, false],
                      },
                    ],
              ],
            },
          },
          "\n",

          {
            style: "tableExample",
            table: {
              widths: [50, 50, 150, 50, "*", "*"],
              body: [
                [
                  {
                    text: "Observación:",
                    style: "subheaderCB",
                    border: [true, true, true, false],
                    colSpan: 3,
                  },
                  { text: "", style: "subheaderCB" },
                  { text: "", style: "subheaderCB" },
                  {
                    text: " ",
                    style: "subheaderCB",
                    border: [false, false, false, false],
                  },
                  { text: "Sub Total", style: "subheaderCB" },
                  {
                    text: solicitud.subTotal.toCurrency({ symbol: "$" }),
                    style: "subheaderSB_Derecha",
                  },
                ],
                [
                  {
                    text: solicitud.observaciones,
                    style: "subheaderSB",
                    border: [true, false, true, true],
                    rowSpan: 5,
                    colSpan: 3,
                  },
                  "",
                  "",
                  {
                    text: " ",
                    style: "subheaderCB",
                    border: [false, false, false, false],
                  },
                  { text: "Descuento 1", style: "subheaderCB" },
                  {
                    text: solicitud.descuento.toCurrency({ symbol: "$" }),
                    style: "subheaderSB_Derecha",
                  },
                ],
                [
                  "",
                  "",
                  "",
                  {
                    text: " ",
                    style: "subheaderCB",
                    border: [false, false, false, false],
                  },
                  { text: "Afecto IVA", style: "subheaderCB" },
                  {
                    text: solicitud.afectoIVA.toCurrency({ symbol: "$" }),
                    style: "subheaderSB_Derecha",
                  },
                ],
                [
                  "",
                  "",
                  "",
                  {
                    text: " ",
                    style: "subheaderCB",
                    border: [false, false, false, false],
                  },
                  { text: "Excento IVA", style: "subheaderCB" },
                  {
                    text: solicitud.exentoIVA.toCurrency({ symbol: "$" }),
                    style: "subheaderSB_Derecha",
                  },
                ],
                [
                  "",
                  "",
                  "",
                  {
                    text: " ",
                    style: "subheaderCB",
                    border: [false, false, false, false],
                  },
                  { text: "Sub Total Neto", style: "subheaderCB" },
                  {
                    text: solicitud.subTotalNeto.toCurrency({ symbol: "$" }),
                    style: "subheaderSB_Derecha",
                  },
                ],
                [
                  "",
                  "",
                  "",
                  {
                    text: " ",
                    style: "subheaderCB",
                    border: [false, false, false, false],
                  },
                  { text: "IVA", style: "subheaderCB" },
                  {
                    text: solicitud.IVA.toCurrency({ symbol: "$" }),
                    style: "subheaderSB_Derecha",
                  },
                ],
                [
                  {
                    text: " ",
                    style: "subheaderCB",
                    border: [false, false, false, false],
                  },
                  {
                    text: " ",
                    style: "subheaderCB",
                    border: [false, false, false, false],
                  },
                  {
                    text: " ",
                    style: "subheaderCB",
                    border: [false, false, false, false],
                  },
                  {
                    text: " ",
                    style: "subheaderCB",
                    border: [false, false, false, false],
                  },

                  { text: "Total", style: "subheaderCB" },
                  {
                    text: solicitud.total.toCurrency({ symbol: "$" }),
                    style: "subheaderSB_Derecha",
                  },
                ],
              ],
            },
          },

          "\n",
          {
            style: "tableExample",
            table: {
              headerRows: 1,
              widths: ["*"],
              body: [
                [
                  {
                    text: "\nDatos de Transferencia",
                    style: "subheaderCB",
                    border: [false, true, false, false],
                  },
                ],
              ],
            },
          },
          "\n",
          {
            style: "tableExample",
            table: {
              widths: ["*"],
              body: [
                [
                  {
                    style: "tableExample",
                    table: {
                      headerRows: 1,
                      widths: [10, 60, "*", 60, "*"],
                      body: [
                        [
                          { text: "" },
                          { text: "Destinatario:", style: "subheaderCB" },
                          {
                            text: solicitud.destinatario,
                            style: "subheaderSB",
                          },
                          { text: "Rut:", style: "subheaderCB" },
                          {
                            text: solicitud.rutDestinatario,
                            style: "subheaderSB",
                          },
                        ],
                        [
                          { text: "" },
                          { text: "Banco:", style: "subheaderCB" },
                          { text: solicitud.banco, style: "subheaderSB" },
                          { text: "Tipo de Cuenta:", style: "subheaderCB" },
                          { text: solicitud.tipoCueta, style: "subheaderSB" },
                        ],
                        [
                          { text: "" },
                          { text: "N° Cuenta:", style: "subheaderCB" },
                          { text: solicitud.nroCuenta, style: "subheaderSB" },
                          { text: "Email:", style: "subheaderCB" },
                          {
                            text: solicitud.correoDestinatario,
                            style: "subheaderSB",
                          },
                        ],
                      ],
                    },
                    layout: "noBorders",
                  },
                ],
              ],
            },
          },
          "\n",
          {
            text: "NOTA:",
            style: "subheaderCB",
            //border: [false, false, false, false],
            //colSpan: 3,
          },
          {
            text: "La solicitud NO será validada si no se encuentra en estado APROBADA.",
            style: "subheaderSB",
            //border: [false, false, false, false],
            //colSpan: 3,
          },
          "\n",

          {
            text: "Para efectos de envío de Facturas y XML, realizar a la cuenta naguilar@iisf.cl. Las facturas emitidas deben tener en referencias el número de solicitud de compra.",
            style: ["quote", "small"],
          },
        ],
        images: {
          logo: base64,
          image: base64_1,
        },
        styles: {
          titulo: {
            fontSize: 12,
            bold: true,
            alignment: "center",
          },
          header: {
            fontSize: 10,
            bold: true,
            alignment: "center",
          },
          subheader: {
            fontSize: 15,
            bold: true,
          },
          subheaderSB: {
            fontSize: 8,
            bold: false,
            alignment: "left",
          },
          subheaderSB_Centro: {
            fontSize: 8,
            bold: false,
            alignment: "center",
          },
          subheaderSB_Derecha: {
            fontSize: 8,
            bold: false,
            alignment: "right",
          },
          subheaderCB: {
            fontSize: 8,
            bold: true,
            //aligment: "center",
          },
          quote: {
            italics: true,
          },
          small: {
            fontSize: 8,
          },
        },
      };

      pdfMake
        .createPdf(dd)
        .download(`Solicitud N° ${solicitud.ordenCompra}.pdf`);
    }
  }

  abrirDialogoEliminar(solicitud: Solicitud): void {
    if (!solicitud.id) {
      console.error("error id: ", solicitud);
      return;
    }
    const doc = this.solicitudes[solicitud.id];
    if (doc) {
      this.dialogoEliminar = true;
      this.solicitud = doc;
    }
  }

  cerrarDialogoEliminar(): void {
    this.dialogoEliminar = false;
    this.solicitud = null;
  }

  cerrarDialogoFormulario(): void {
    this.dialogoFormulario = false;
    this.solicitud = null;
  }

  cerrarDialogoFormularioAprobacion(): void {
    this.dialogoFormularioAprobacion = false;
    this.solicitud = null;
  }
}
