




























import {Component, Emit, Prop, Vue} from "vue-property-decorator";
import {Sesion, Sesiones} from "@/typings/store/plugins/easyFirestore/sesiones";
import {Curso} from "@/typings/store/plugins/easyFirestore/cursos";
@Component
export default class DeseaCurso extends Vue{
  @Prop() readonly sesionesCarrito!: Sesiones;


  @Emit("close")
  onClose(): null {
    return null;
  }

  @Emit("comprarCurso")
  onComprarCurso(data: {curso: Curso, sesion: Sesion}): {curso: Curso, sesion: Sesion} {
    return data;
  }


  comprarCurso(sesion: Sesion): void{
    const curso = sesion.curso
    const data = {
      curso: curso,
      sesion: sesion
    }
    this.onComprarCurso(data);
  }

}
