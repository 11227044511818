























































































































































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { CondicionComercial } from "@/typings/store/plugins/easyFirestore/condicionesComerciales";
import { Contrato } from "@/typings/store/plugins/easyFirestore/contratos";
import { required } from "vuelidate/lib/validators";
import { DataFormularioCondicionComercial } from "@/typings/components/condicionesComerciales/formulario";
import SVGIcon from "@/components/SVGIcon.vue";

@Component({
  components: {
    SVGIcon,
  },
  validations: {
    codigoContrato: { required },
    codigoCondicion: { required },
    desde: { required },
    hasta: { required },
    liquidacion: { required },
  },
})
export default class FormularioBanco extends Vue {
  @Prop() readonly condicionComercial!: CondicionComercial | null;
  @Prop() readonly cargando!: boolean;
  @Prop() readonly contrato!: Contrato;
  @Prop() readonly codigoCondicionContrato!: number;

  created(): void {
    const condicionComercial = this.condicionComercial;
    if (!condicionComercial) {
      return;
    }
    this.id = condicionComercial.id ?? "";
    this.codigoCondicion = condicionComercial.codigo_condicion ?? 0;
    this.codigoContrato = this.codigo;
    this.desde = condicionComercial.desde.toDate().format("YYYY-MM-DD") ?? "";
    this.hasta = condicionComercial.hasta.toDate().format("YYYY-MM-DD") ?? "";
    this.liquidacion = condicionComercial.liquidacion ?? "";
  }

  id: string | null = null;
  codigoContrato = 0;
  codigoCondicion = 0;
  liquidacion = "";
  menuDesde = false;
  menuHasta = false;
  desde = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
    .toISOString()
    .substr(0, 10);
  hasta = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
    .toISOString()
    .substr(0, 10);

  formateaFecha(fecha: string): string {
    let array_fecha = fecha.split("-");
    let dia = array_fecha[2];
    let mes = array_fecha[1];
    let ano = array_fecha[0];
    console.log(array_fecha);
    fecha = dia + "-" + mes + "-" + ano;
    return dia + "-" + mes + "-" + ano;
  }

  get titulo(): string {
    return this.condicionComercial
      ? "Editar Condición Comercial"
      : "Nuevo Condición Comercial";
  }

  get codigo(): number {
    this.codigoContrato = this.contrato.codigo;
    return this.contrato.codigo || 0;
  }

  get verificaCodigoCondicionContrato(): number {
    let numero = this.codigoCondicionContrato;
    let codigo = 0;
    if (this.codigoCondicion === 0) {
      this.codigoCondicion = numero + 1;
      codigo = this.codigoCondicion;
    } else {
      codigo = this.codigoCondicion;
    }
    return codigo;
  }

  get codigoContratoError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.codigoContrato.$dirty) return errors;
    if (!this.$v.codigoContrato.required) errors.push("");
    return errors;
  }

  get codigoCondicionError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.codigoCondicion.$dirty) return errors;
    if (!this.$v.codigoCondicion.required) errors.push("");
    return errors;
  }

  get desdeError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.desde.$dirty) return errors;
    if (!this.$v.desde.required) errors.push("");
    return errors;
  }

  get hastaError(): Array<string> {
    const errors: Array<string> = [];
    const fechaDesde = this.$v.desde.$model;
    const fechaHasta = this.$v.hasta.$model;

    if (!this.$v.hasta.$dirty) return errors;
    if (!this.$v.hasta.required) errors.push("");
    if (fechaDesde && fechaHasta && fechaDesde >= fechaHasta) {
      errors.push("");
    }
    return errors;
  }
  get liquidacionError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.liquidacion.$dirty) return errors;
    if (!this.$v.liquidacion.required) errors.push("");
    return errors;
  }

  @Emit("close")
  onClose(): null {
    return null;
  }

  @Emit("cerrar-dialogo")
  onCerrarDialogo(): void {
    return;
  }

  @Emit("click-guardar")
  onClickGuardar(
    data: DataFormularioCondicionComercial
  ): DataFormularioCondicionComercial {
    return data;
  }

  guardar(): void {
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }
    const errors = this.hastaError;
    if (errors.length > 0) {
      return;
    }
    const doc: CondicionComercial = {
      codigo_contrato: this.codigoContrato,
      codigo_condicion: this.codigoCondicion,
      desde: this.formateaFecha(this.desde),
      hasta: this.formateaFecha(this.hasta),
      liquidacion: this.liquidacion,
    };
    if (this.id) {
      doc.id = this.id;
    }

    const data: DataFormularioCondicionComercial = {
      doc: doc,
    };
    this.onClickGuardar(data);
    this.codigoCondicion = this.codigoCondicion + 1;
  }
}
