























































































// decoradores
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { Consumo } from "@/typings/store/plugins/easyFirestore/consumos";
import SVGIcon from "@/components/SVGIcon.vue";
// tipos

// tipos
@Component({
  components: {
    SVGIcon,
  },
})
export default class InfoConsumo extends Vue {
  @Prop() readonly consumo!: Consumo;
  @Prop({ type: Boolean }) readonly showTitulo!: boolean;

  get id(): string {
    return this.consumo.id || "N/R";
  }

  // get periodo(): string {
  //   return this.consumo.periodo || "N/R";
  // }
  get centroCosto(): string {
    return this.consumo.centroCosto || "N/R";
  }
  get unidadLocativa(): string {
    return this.consumo.unidadLocativa || "N/R";
  }
  get empresa(): string {
    return this.consumo.empresa || "N/R";
  }
  // get tipoServicio(): string {
  //   return this.consumo.tipoServicio || "N/R";
  // }
  // get nroContrato(): string {
  //   return this.consumo.nroContrato || "N/R";
  // }
  // get nroLectura(): string {
  //   return this.consumo.nroLectura || "N/R";
  // }
  // get nroCliente(): string {
  //   return this.consumo.nroCliente || "N/R";
  // }
  // get nroMedidor(): string {
  //   return this.consumo.nroMedidor || "N/R";
  // }
  // get imagen1(): string {
  //   return this.consumo.imagen1 || "N/R";
  // }
  // get imagen2(): string {
  //   return this.consumo.imagen2 || "N/R";
  // }
  // get imagen3(): string {
  //   return this.consumo.imagen3 || "N/R";
  // }
  // get monto(): number {
  //   return this.consumo.monto || 0;
  // }

  @Emit("cerrar")
  onCerrar(): void {
    return;
  }
}
