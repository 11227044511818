
























































































































































import {Component, Emit, Prop, Vue, Watch} from "vue-property-decorator";
import SVGIcon from "@/components/SVGIcon.vue";
import {required} from "vuelidate/lib/validators";
import {Curso} from "@/typings/store/plugins/easyFirestore/cursos";

@Component({
  components: {
    SVGIcon
  },
  validations: {
    nombre: {required},
    fechaInicio: {required},
    fechaFin: {required}
  }
})
export default class Duplicar extends Vue {
  @Prop() readonly curso!: Curso;

  created(): void {
    this.nombre = this.curso.nombre;
  }

  cargando = false;
  menuFecha = false;
  menuFecha2 = false;
  nombre = "";
  fechaInicio = "";
  fechaFin = "";

  get nombreErrors(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.nombre.$dirty) return errors;
    if (!this.$v.nombre.required) errors.push("Campo requerido");
    return errors;
  }

  get fechaInicioErrors(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.fechaInicio.$dirty) return errors;
    if (!this.$v.fechaInicio.required) errors.push("Campo requerido");
    return errors;
  }

  get fechaFinErrors(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.fechaFin.$dirty) return errors;
    if (!this.$v.fechaFin.required) errors.push("Campo requerido");
    return errors;
  }

  @Emit("duplicar")
  onDuplicar(curso: Curso): Curso {
    return curso;
  }

  @Emit("cerrar")
  onCerrar(): void {
    return;
  }

  @Watch("fechaInicio")
  onFechaInicioChange(fecha: string): void {
    const date = fecha.toDate();
    if (date.getTime() >= this.fechaFin.toDate().getTime()) {
      date.setDate(date.getDate() + 1);
      this.fechaFin = date.format("YYYY-MM-DD");
    }
  }

  fechasPermitidas(fecha: string): boolean {
    return fecha.toDate().getTime() > this.fechaInicio.toDate().getTime();
  }

  duplicar(): void {
    this.$v.$touch();
    if (this.$v.$invalid || this.cargando) return;
    this.cargando = true;
    const dateInicio = new Date(`${this.fechaInicio} 00:00`);
    const dateFin = new Date(`${this.fechaFin} 00:00`);
    const curso = Object.assign({}, this.curso, {
      nombre: this.nombre,
      inicio: dateInicio.toISOString(),
      fin: dateFin.toISOString(),
      estado: "pendiente",
      observacion: ""
    });
    this.$delete(curso, "id");
    this.onDuplicar(curso);
  }
}
