
































































































































































import {Component, Emit, Prop, Vue} from "vue-property-decorator";
import {Curso} from "@/typings/store/plugins/easyFirestore/cursos";
import {Sesion} from "@/typings/store/plugins/easyFirestore/sesiones";
import SVGIcon from "@/components/SVGIcon.vue";
import DialogoExito from "@/components/DialogoExito.vue";

@Component({
  components: {DialogoExito, SVGIcon}
})
export default class DialogoClases extends Vue {
  @Prop() curso!: Curso;
  @Prop() sesiones!: Array<Sesion>;

  dialogoExito = {
    model: false,
    icono: "",
    texto: ""
  };

  created(): void{
    for(const sesion of this.sesiones){
      if(sesion && sesion.id){
        this.$set(this.preciosSesiones, sesion.id, sesion.precio || (this.curso.precio / this.sesiones.length));
        this.$set(this.sesionesGrabadas, sesion.id, sesion.estado === 'grabada');
      }
    }
  }


  preciosSesiones: {
    [id: string]: number
  } = {};
  sesionesGrabadas: {
    [id: string]: boolean
  } = {};

  @Emit("cerrar")
  onCerrar(): void {
    return;
  }

  @Emit("guardar-sesiones")
  onGuardarSesiones(sesiones: Sesion[]): Sesion[] {
    return sesiones;
  }

  get sesionesOrdenadas(): Array<Sesion> {
    return this.sesiones.sort((a, b) => a.numero - b.numero);
  }

  guardarSesiones(): void{
    const sesiones = this.sesiones.slice();
    for(const sesion of sesiones){
      if(!sesion.tema || !sesion.resumen || !sesion.resumen || !sesion.duracion){
        this.dialogoExito = {
          model: true,
          icono: require('../../assets/icons/cancelar-duo.svg'),
          texto: "¡Error, necesita completar todos los campos solicitados!"
        };
        return;
      }
      if(sesion && sesion.id){
        sesion.precio = this.preciosSesiones[sesion.id];
        sesion.estado = this.sesionesGrabadas[sesion.id] ? 'grabada' : 'en-vivo';
        sesion.link = this.sesionesGrabadas[sesion.id] ? sesion.link : '';
      }
    }
    this.onGuardarSesiones(sesiones);
  }

  cerrarDialogoExito(): void{
    this.dialogoExito = {
      model: false,
      icono: "",
      texto: ""
    };
  }
}
