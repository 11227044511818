















































import {Component, Emit, Prop, Vue} from "vue-property-decorator";
import SVGIcon from "@/components/SVGIcon.vue";

@Component({
  components: {
    SVGIcon
  }
})
export default class DialogoExito extends Vue {
  @Prop() readonly texto!: string;

  @Emit("cancelar")
  onCancelar(): void {
    return;
  }

  @Emit("eliminar")
  onEliminar(): void {
    return;
  }
}
