//tipos
import { GetterTree } from "vuex";
import { State } from "@/typings/store";
import {
  SesionesProgramadas,
  SesionProgramada,
  StateSesionesProgramadas,
} from "@/typings/store/plugins/easyFirestore/sesionesProgramadas";

const state: StateSesionesProgramadas = {
  all: {},
};

const getters: GetterTree<StateSesionesProgramadas, State> = {
  get(state, getters) {
    return (getters["getArray"] as Array<SesionProgramada>).reduce(
      (docs, doc) => {
        if (!doc?.id) {
          console.error("doc.id == undefined => ", doc);
          return docs;
        }
        docs[doc.id] = doc;
        return docs;
      },
      {} as SesionesProgramadas
    );
  },
  getArray(state) {
    return Object.values(state.all).map((doc) => {
      return JSON.parse(JSON.stringify(doc)) as SesionProgramada;
    });
  },
};

const sesionesProgramadas = {
  firestorePath: "dev/v1/sesionesProgramadas",
  firestoreRefType: "collection",
  moduleName: "sesionesProgramadas",
  statePropName: "all",
  namespaced: true,
  serverChange: {
    convertTimestamps: {
      created_at: "%convertTimestamp%", // default
      updated_at: "%convertTimestamp%", // default
    },
  },
  state,
  getters,
  mutations: {},
  actions: {},
};

export default sesionesProgramadas;
