import { RouteConfig } from "vue-router";
import Reproductor from "@/views/Reproductor.vue";

const reproductor: RouteConfig = {
  path: "/reproductor/:sid",
  name: "reproductor",
  component: Reproductor
};

export default reproductor;
