








































import {Component, Emit, Prop, Vue} from "vue-property-decorator";
// helpers
// tipos
// validaciones
import {required} from "vuelidate/lib/validators";
import {Region} from "@/typings/store/plugins/easyFirestore/regiones";
import {DataFormularioRegion} from "@/typings/components/regiones/formulario";

@Component({
  validations: {
    nombre: { required },
  },
})
export default class FormularioRegiones extends Vue {
  @Prop() readonly region!: Region | null;

  created(): void {
    const region = this.region;
    if (!region) {
      return;
    }
    this.nombre = region.nombre ?? "";
  }

  id: string | null = null;
  nombre = "";
  numero = "";
  posicion=0;

  get titulo(): string {
    return this.region
        ? "editar region"
        : "nueva region";
  }

  get nombreError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.nombre.$dirty) return errors;
    if (!this.$v.nombre.required) errors.push("Campo requerido");
    return errors;
  }

  @Emit("close")
  onClose(): null {
    return null;
  }

  @Emit("cerrar-dialogo")
  onCerrarDialogo(): void {
    return;
  }

  @Emit("click-guardar")
  onClickGuardar(
      data: DataFormularioRegion
  ): DataFormularioRegion {
    return data;
  }

  guardar(): void {
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }
    const doc: Region = {
      nombre: this.nombre.toLowerCase(),
      numero: this.numero.toUpperCase(),
      posicion: this.posicion
    };
    if (this.id) {
      doc.id = this.id;
    }
    const data: DataFormularioRegion = {
      doc: doc,
    };
    this.onClickGuardar(data);
  }

}
