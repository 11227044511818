import axios from "axios";

export default {
  async getUFValue(
    year: string,
    month: string,
    day: string
  ): Promise<number | null> {
    const apiKey = "a74167edb2447c72e1dfae4e6a35b92001a7e940";
    const apiUrl = `https://api.sbif.cl/api-sbifv3/recursos_api/uf/${year}/${month}/dias/${day}?apikey=${apiKey}&formato=json`;
    try {
      const response = await axios.get(apiUrl);
      // Verifica la estructura de la respuesta de la API y extrae el valor de la UF
      const ufValue = response.data.UFs[0].Valor;
      return parseFloat(ufValue.replace(",", ".")); // Asegura que el valor sea numérico
    } catch (error) {
      console.log(error);
      return null;
    }
  },
};
