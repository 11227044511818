

























































































// decoradores
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { Pago } from "@/typings/store/plugins/easyFirestore/pagos";
import SVGIcon from "@/components/SVGIcon.vue";
// tipos

@Component({
  components: {
    SVGIcon,
  },
})
export default class InfoPago extends Vue {
  @Prop() readonly pago!: Pago;
  @Prop({ type: Boolean }) readonly showTitulo!: boolean;

  get id(): string {
    return this.pago.id || "N/R";
  }

  get nombre(): string {
    return this.pago.cliente.nombre || "N/R";
  }

  get rut(): string {
    return this.pago.cliente.rut || "N/R";
  }

  get email(): string {
    return this.pago.cliente.email || "N/R";
  }

  /* get cursos(): string {
    return Object.values(this.pago.cursos).map(curso => curso.categoria).join("\n") || "N/R";
  }*/

  @Emit("cerrar")
  onCerrar(): void {
    return;
  }
}
