





















































































































































































































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
// helpers
// tipos
import { Usuario } from "@/typings/store/plugins/easyFirestore/usuarios";
// validaciones
import { required } from "vuelidate/lib/validators";
import { DataFormularioUsuario } from "@/typings/components/usuarios/formulario";
import { Ciudad } from "@/typings/store/plugins/easyFirestore/ciudades";
import SVGIcon from "@/components/SVGIcon.vue";
import { Archivo, Archivos } from "@/typings/store/modules/archivos";
import VistaPreviaImagen from "@/components/VistaPreviaImagen.vue";
import { idtify } from "@/helpers/string";

@Component({
  components: {
    SVGIcon,
    VistaPreviaImagen,
  },
  validations: {
    rut: { required },
    nombre: { required },
  },
})
export default class FormularioPerfil extends Vue {
  @Prop() readonly usuario!: Usuario | null;
  @Prop() readonly arrayCiudades!: Ciudad[] | null;

  created(): void {
    const usuario = this.usuario;
    if (usuario) {
      this.id = usuario.id ?? "";
      this.rut = usuario.rut ?? "";
      this.nombre = usuario.nombre ?? "";
      this.telefono = usuario.telefono ?? "";
      this.ciudad = usuario.ciudad ?? null;
      this.profesion = usuario.profesion ?? "";
      this.correo = usuario.email ?? "";
      const archivos = Object.values(usuario.imagen ?? {});
      this.imagen = archivos[0] ?? null;
    }
  }

  vistaPrevia = {
    model: false,
    url: "",
  };
  id: string | null = null;
  rut = "";
  nombre = "";
  telefono = "";
  ciudad: Ciudad | null = null;
  profesion = "";
  correo = "";
  imagen: Archivo | null = null;
  archivosBorrar: Archivos = {};
  cargando = false;

  get rutError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.rut.$dirty) return errors;
    if (!this.$v.rut.required) errors.push("Completar Datos Obligatorios");
    return errors;
  }

  get nombreError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.nombre.$dirty) return errors;
    if (!this.$v.nombre.required) errors.push("Completar Datos Obligatorios");
    return errors;
  }

  get nombreFoto(): string {
    return this.imagen?.file?.name ?? this.imagen?.nombre ?? "";
  }

  @Emit("close")
  onClose(): null {
    return null;
  }

  @Emit("cerrar-dialogo")
  onCerrarDialogo(): void {
    return;
  }

  @Emit("click-guardar")
  onClickGuardar(data: DataFormularioUsuario): DataFormularioUsuario {
    return data;
  }

  cargarFoto(ev: Event): void {
    const input = ev.target as HTMLInputElement;
    const file = input?.files ? input.files[0] : null;
    if (file) {
      if (file.type.includes("image")) {
        const fecha = new Date();
        const extencion = file.name.split(".").pop() ?? "";
        const id = `usuarios/${
          this.nombre
        }/imagen/${fecha.valueOf()}.${extencion}`;
        this.imagen = {
          id: id,
          nombre: file.name,
          file: file,
        };
      }
    }
  }

  clickInputFoto(): void {
    const input = this.$refs.inputFoto as HTMLInputElement;
    input.click();
  }

  abrirVistaPrevia(imagen: Archivo): void {
    this.vistaPrevia = {
      model: true,
      url: this.urlArchivo(imagen),
    };
  }

  cerrarVistaPrevia(): void {
    this.vistaPrevia = {
      model: false,
      url: "",
    };
  }

  eliminarFoto(): void {
    if (this.usuario && this.imagen?.id) {
      this.archivosBorrar[this.imagen.id] = this.imagen;
    }
    this.imagen = null;
  }

  urlArchivo(media: Archivo): string {
    return media.url ?? URL.createObjectURL(media.file);
  }

  guardar(): void {
    if (!this.cargando) {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.cargando = true;
      const doc: Usuario = Object.assign({}, this.usuario, {
        rut: this.rut,
        nombre: this.nombre,
        telefono: this.telefono,
        ciudad: this.ciudad,
        profesion: this.profesion,
      });
      if (this.id) {
        doc.id = this.id;
      }
      const data: DataFormularioUsuario = {
        doc: doc,
        borrar: this.archivosBorrar,
        imagen: {},
      };
      if (this.imagen) {
        data.imagen = {
          [idtify(this.imagen.id)]: this.imagen,
        };
      }
      this.onClickGuardar(data);
    }
  }
}
