



















































































































import {Component, Emit, Prop, Vue} from "vue-property-decorator";
import SVGIcon from "@/components/SVGIcon.vue";
import {Invitado} from "@/typings/store/plugins/easyFirestore/cursos";
import FormularioInvitado from "@/components/cursos/invitados/Formulario.vue";

@Component({
  components: {
    FormularioInvitado,
    SVGIcon
  }
})
export default class DialogoInvitados extends Vue {
  @Prop() readonly invitadosCurso!: Invitado[];

  created(): void {
    this.invitados = this.invitadosCurso.slice();
  }

  invitados: Invitado[] = [];
  dialogoFormulario: {
    model: boolean;
    invitado: Invitado | null;
    index: number;
  } = {
    model: false,
    invitado: null,
    index: -1
  };

  @Emit("cerrar")
  onCerrar(): void {
    return;
  }

  @Emit("guardar")
  onGuardar(invitados: Array<Invitado>): Array<Invitado> {
    return invitados;
  }

  urlInvitado(invitado: Invitado): string {
    return invitado.imagen ? invitado.imagen.url ?? URL.createObjectURL(invitado.imagen.file) : require("../../assets/icons/usuario-duo.svg");
  }

  eliminarInvitado(index: number): void {
    this.invitados.splice(index, 1);
  }

  abrirDialogoNuevo(): void {
    this.dialogoFormulario = {
      model: true,
      invitado: null,
      index: -1
    };
  }

  abrirDialogoFormulario(index: number, invitado: Invitado): void {
    this.dialogoFormulario = {
      model: true,
      invitado,
      index: index
    };
  }

  cerrarDialogoFormulario(): void {
    this.dialogoFormulario = {
      model: false,
      invitado: null,
      index: -1
    };
  }

  confirmarInvitado(invitado: Invitado): void {
    if(this.dialogoFormulario.index > -1) {
      this.invitados.splice(this.dialogoFormulario.index, 1, invitado);
    } else {
      this.invitados.push(invitado);
    }
    this.cerrarDialogoFormulario();
  }

}
