





















































































































































import {Component, Emit, Prop, Vue} from "vue-property-decorator";
import {Invitado} from "@/typings/store/plugins/easyFirestore/cursos";
import SVGIcon from "@/components/SVGIcon.vue";
import {Archivo} from "@/typings/store/modules/archivos";
import {required} from "vuelidate/lib/validators";
import "@/helpers/extensions";

@Component({
  components: {
    SVGIcon
  },
  validations: {
    nombre: {required},
    rut: {required},
    profesion: {required},
    telefono: {required}
  }
})
export default class FormularioInvitado extends Vue {
  @Prop() readonly invitado?: Invitado;

  created(): void {
    if (!this.invitado) return;
    this.nombre = this.invitado.nombre;
    this.rut = this.invitado.rut;
    this.profesion = this.invitado.profesion;
    this.telefono = this.invitado.telefono;
    this.imagen = this.invitado.imagen;
  }

  nombre = "";
  rut = "";
  profesion = "";
  telefono = "";
  imagen: Archivo | null = null;

  get urlImagen(): string {
    return this.imagen ? this.imagen.url ?? URL.createObjectURL(this.imagen.file) : "";
  }

  get nombreErrors(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.nombre.$dirty) return errors;
    if (!this.$v.nombre.required) errors.push("El nombre es requerido");
    return errors;
  }

  get rutErrors(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.rut.$dirty) return errors;
    if (!this.$v.rut.required) errors.push("El RUT es requerido");
    if (!this.rut.validateRUT()) errors.push("El RUT es inválido");
    return errors;
  }

  get profesionErrors(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.profesion.$dirty) return errors;
    if (!this.$v.profesion.required) errors.push("La profesión es requerida");
    return errors;
  }

  get telefonoErrors(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.telefono.$dirty) return errors;
    if (!this.$v.telefono.required) errors.push("El teléfono es requerido");
    return errors;
  }

  @Emit('cerrar')
  onCerrar(): void {
    return;
  }

  @Emit("confirmar")
  onConfirmar(invitado: Invitado): Invitado {
    return invitado;
  }

  clickInputFile(): void {
    const input = this.$refs.inputFile as HTMLInputElement;
    input.click();
  }

  cargarFoto(ev: Event): void {
    const input = ev.target as HTMLInputElement;
    const file = input?.files ? input.files[0] : null;
    if (file) {
      if (file.type.includes("image")) {
        const fecha = new Date();
        const extension = file.name.split(".").pop() ?? "";
        const id = `cursos/invitados/${fecha.valueOf()}.${extension}`;
        this.imagen = {
          id: id,
          nombre: file.name,
          file: file
        };
      }
    }
  }

  confirmar(): void {
    this.$v.$touch();
    if (this.$v.$invalid || !this.rut.validateRUT()) return;
    const invitado: Invitado = {
      nombre: this.nombre,
      rut: this.rut,
      profesion: this.profesion,
      telefono: this.telefono,
      imagen: this.imagen
    };
    this.onConfirmar(invitado);
  }
}
