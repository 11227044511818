















































































































































































































































































































































































































































































































































import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
// helpers
import "@/helpers/extensions";
// tipos
// validaciones
import { required } from "vuelidate/lib/validators";
import {
  Comision,
  Curso,
  EstadoCurso,
  Invitado,
} from "@/typings/store/plugins/easyFirestore/cursos";
import { Usuario } from "@/typings/store/plugins/easyFirestore/usuarios";
import { CategoriaCurso } from "@/typings/store/plugins/easyFirestore/categoriasCurso";
import { Tag, Tags } from "@/typings/store/plugins/easyFirestore/tags";
import { DataFormularioCurso } from "@/typings/components/cursos/formulario";
import { fechaLocal } from "@/helpers/date";
import Formulario from "@/components/cursos/sesiones/Formulario.vue";
import {
  Sesion,
  Sesiones,
} from "@/typings/store/plugins/easyFirestore/sesiones";
import { DataFormularioSesion } from "@/typings/components/sesiones/formulario";
import { Archivo, Archivos } from "@/typings/store/modules/archivos";
import { idtify } from "@/helpers/string";
import SVGIcon from "@/components/SVGIcon.vue";
import Duplicar from "@/components/cursos/Duplicar.vue";
import DialogoInvitados from "@/components/cursos/DialogoInvitados.vue";
import DialogoExito from "@/components/DialogoExito.vue";

@Component({
  components: {DialogoExito, DialogoInvitados, Duplicar, SVGIcon, Formulario },
  validations: {
    nombre: { required },
    categoria: { required },
    relator: { required },
    precio: { required },
    fechaInicio: { required },
    fechaFin: { required },
    estado: { required },
  },
})
export default class FormularioPago extends Vue {
  @Prop() readonly curso!: Curso | null;
  @Prop() readonly usuario!: Usuario;
  @Prop() readonly arrayCategoriasCurso!: CategoriaCurso[];
  @Prop() readonly arraySesiones!: Sesion[];
  @Prop() readonly arrayTags!: Tag[] | null;
  @Prop() readonly relatores!: Usuario[];
  @Prop() readonly duplicar!: boolean;

  created(): void {
    this.relator = this.usuario;
    const curso = this.curso;
    if (!curso) {
      const hoy = new Date();
      hoy.setDate(hoy.getDate() + 1);
      this.fechaFin = hoy.format("YYYY-MM-DD");
      return;
    }
    this.id = curso.id ?? "";
    this.nombre = curso.nombre ?? "";
    this.resumen = curso.resumen;
    this.descripcion = curso.descripcion;
    this.categoria = curso.categoria;
    this.tag = curso.tags;
    this.relator = curso.relator;
    this.precio = curso.precio;
    this.estado = curso.estado;
    this.cupos = curso.cupos;
    this.fechaInicio = curso.inicio;
    this.fechaFin = curso.fin;
    this.observacion = curso.observacion;
    this.invitados = curso.invitados ?? [];
    this.imagen = Object.values(curso.imagen)[0] ?? null;
    this.arraySesiones.filter((sesion) => {
      if (sesion.curso.id === curso.id && sesion.id) {
        this.$set(this.sesiones, sesion.id, sesion);
      }
    });
  }

  showError = false;
  cargando = false;
  invitados: Invitado[] = [];
  archivosEliminados: Archivos = {};
  imagen: Archivo | null = null;
  dialogoSesiones = false;
  menuFecha2 = false;
  menuFecha = false;
  id: string | null = null;
  nombre = "";
  resumen = "";
  descripcion = "";
  categoria: CategoriaCurso | null = null;
  tag: Tags | null = null;
  relator: Usuario | null = null;
  precio = 0;
  cupos = 99;
  fechaInicio = fechaLocal();
  fechaFin = "";
  estado: EstadoCurso = "pendiente";
  observacion = "";
  sesiones: Sesiones = {};
  sesionesNuevas: DataFormularioSesion[] = [];
  sesionesEliminadas: DataFormularioSesion[] = [];
  dialogoDuplicar = false;
  dialogoInvitados = false;
  dialogoExito = {
    model: false,
    icono: "",
    texto: "",
  };

  get errorClase(): boolean {
    return this.showError && this.cantidadClases === 0;
  }

  get cantidadClases(): number {
    return [...Object.values(this.sesiones), ...this.sesionesNuevas].length;
  }

  get duracionTotal(): number {
    return (
      [...Object.values(this.sesiones), ...this.sesionesNuevas].reduce(
        (sum, sesion) => sum + sesion.duracion,
        0
      ) / 60
    );
  }

  get duracionPromedio(): number {
    const sesiones = [...Object.values(this.sesiones), ...this.sesionesNuevas];
    return sesiones.length > 0 ? this.duracionTotal / sesiones.length : 0;
  }

  get urlImagen(): string {
    return this.imagen
      ? this.imagen.url ?? URL.createObjectURL(this.imagen.file)
      : "";
  }

  get titulo(): string {
    return this.curso ? "editar curso" : "nuevo curso";
  }

  get nombreError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.nombre.$dirty) return errors;
    if (!this.$v.nombre.required) errors.push("Completar datos obligatorios");
    return errors;
  }

  get categoriaError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.categoria.$dirty) return errors;
    if (!this.$v.categoria.required)
      errors.push("Completar datos obligatorios");
    return errors;
  }

  get relatorError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.relator.$dirty) return errors;
    if (!this.$v.relator.required) errors.push("Completar datos obligatorios");
    return errors;
  }

  get precioError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.precio.$dirty) return errors;
    if (!this.$v.precio.required) errors.push("Completar datos obligatorios");
    if (!this.$v.precio.min) errors.push("El precio debe ser mayor a 0");
    return errors;
  }

  get fechaInicioMostrar(): string {
    return this.fechaInicio.toDate().format("DD-MM-YYYY") ?? "";
  }

  set fechaInicioMostrar(inicio: string) {
    if (this.curso) {
      this.fechaInicio = inicio.toDate().format("DD-MM-YYYY") ?? "";
    }
  }

  get fechaFinMostrar(): string {
    return this.fechaFin.toDate().format("DD-MM-YYYY") ?? "";
  }

  set fechaFinMostrar(fin: string) {
    if (this.curso) {
      this.fechaFin = fin.toDate().format("DD-MM-YYYY") ?? "";
    }
  }

  get fechaInicioError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.fechaInicio.$dirty) return errors;
    if (!this.$v.fechaInicio.required)
      errors.push("Completar datos obligatorios");
    return errors;
  }

  get fechaFinError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.fechaFin.$dirty) return errors;
    if (!this.$v.fechaFin.required) errors.push("Completar datos obligatorios");
    return errors;
  }

  @Emit("close")
  onClose(): null {
    return null;
  }

  @Emit("click-guardar")
  onClickGuardar(
    data: DataFormularioCurso,
    dataSesiones: DataFormularioSesion[] | null,
    dataSesionesEliminadas: DataFormularioSesion[] | null
  ): {
    data: DataFormularioCurso;
    dataSesiones: DataFormularioSesion[] | null;
    dataSesionesEliminadas: DataFormularioSesion[] | null;
  } {
    return {
      data: data,
      dataSesiones: dataSesiones,
      dataSesionesEliminadas: dataSesionesEliminadas,
    };
  }

  @Watch("fechaInicio")
  onFechaInicioChange(fecha: string): void {
    const date = fecha.toDate();
    if (date.getTime() >= this.fechaFin.toDate().getTime()) {
      date.setDate(date.getDate() + 1);
      this.fechaFin = date.format("YYYY-MM-DD");
    }
  }

  save(): void{
    if(this.duplicar){
      this.duplicarCurso();
    }else {
      this.guardar();
    }
  }

  fechasPermitidas(fecha: string): boolean {
    return fecha.toDate().getTime() > this.fechaInicio.toDate().getTime();
  }

  clickInputFile(): void {
    const input = this.$refs.inputFile as HTMLInputElement;
    input.click();
  }

  cargarFoto(ev: Event): void {
    const input = ev.target as HTMLInputElement;
    const file = input?.files ? input.files[0] : null;
    if (file) {
      if (file.type.includes("image")) {
        const fecha = new Date();
        const extension = file.name.split(".").pop() ?? "";
        const id = `cursos/${this.id}/imagen/${fecha.valueOf()}.${extension}`;
        this.imagen = {
          id: id,
          nombre: file.name,
          file: file,
        };
      }
    }
  }

  guardar(): void {
    this.$v.$touch();
    this.showError = true;
    if (this.$v.$invalid || !this.relator || !this.categoria || this.errorClase){
      this.dialogoExito = {
        model: true,
        icono: require("../../assets/icons/cancelar-duo.svg"),
        texto: "¡Ingrese todos los campos requeridos!",
      };
      return;
    }
    if (Object.values(this.imagen ?? {}).length <= 0) {
      this.dialogoExito = {
        model: true,
        icono: require("../../assets/icons/cancelar-duo.svg"),
        texto: "¡El curso  debe tener una imagen!",
      };
      return;
    }
    this.cargando = true;
    const dateInicio = new Date(`${this.fechaInicio}`);
    const dateFin = new Date(`${this.fechaFin}`);
    const comision: Comision = {
      tipo: this.curso?.comision.tipo ?? "Unico",
      valor: this.curso?.comision.valor ?? "0",
    };
    const doc: Curso = {
      nombre: this.nombre.toLowerCase(),
      resumen: this.resumen,
      descripcion: this.descripcion,
      categoria: this.categoria,
      tags: this.tag ?? {},
      relator: this.relator,
      precio: this.precio,
      inicio: dateInicio.toISOString(),
      fin: dateFin.toISOString(),
      estado: this.estado,
      observacion: this.observacion,
      cupos: this.cupos,
      imagen: {},
      invitados: this.invitados,
      descuento: "0%",
      comision: comision,
      certificado: false,
    };
    if (this.id) {
      doc.id = this.id;
    }
    let imagen: Archivos = {};
    if (this.imagen) {
      imagen = {
        [idtify(this.imagen.id)]: this.imagen,
      };
    }
    const data: DataFormularioCurso = {
      imagen: imagen,
      borrar: this.archivosEliminados,
      doc: doc,
    };
    const sesionesNuevas = [
      ...this.sesionesNuevas,
      ...Object.values(this.sesiones),
    ];
    this.onClickGuardar(data, sesionesNuevas, this.sesionesEliminadas);
  }

  duplicarCurso(): void {
    const curso = Object.assign({}, this.curso, {
      nombre: this.nombre,
      categoria: this.categoria,
      resumen: this.resumen,
      descripcion: this.descripcion,
      estado: 'pendiente',
      inicio: new Date().toISOString(),
      fin: new Date().toISOString(),
      observacion: "",
    });
    this.$delete(curso, "id");
    if(!curso){
      return;
    }
    const data: DataFormularioCurso = {
      imagen: {},
      borrar: {},
      doc: curso,
    };
    const sesionesDuplicadas = Object.values(this.sesiones).slice();
    for (const sesion of sesionesDuplicadas) {
      this.$delete(sesion, "id");
      this.$delete(sesion, "curso");
    }
    const dataSesiones: DataFormularioSesion[] = sesionesDuplicadas.slice();
    this.onClickGuardar(data, dataSesiones, []);
  }

  agregarSesion(doc: {
    dataSesiones: DataFormularioSesion[];
    dataEliminarSesiones: DataFormularioSesion[];
  }): void {
    this.sesionesNuevas = doc.dataSesiones.filter((sesion) => !sesion.id);
    const sesiones = doc.dataSesiones.filter((sesion) => sesion.id);
    for (const sesion of sesiones) {
      if (sesion.id) {
        this.sesiones[sesion.id] = sesion as Sesion;
      }
    }
    this.sesionesEliminadas = doc.dataEliminarSesiones;
    this.dialogoSesiones = false;
  }

  abrirDialogoSesiones(): void {
    this.dialogoSesiones = true;
  }

  guardarInvitados(invitados: Invitado[]): void {
    this.invitados = invitados;
    this.dialogoInvitados = false;
  }

  cerrarDialogoExito(): void {
    this.dialogoExito = {
      model: false,
      icono: "",
      texto: "",
    };
  }
}
