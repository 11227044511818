























































































































































































































































import { Component, Emit, Vue, Watch } from "vue-property-decorator";
import SVGIcon from "@/components/SVGIcon.vue";
import { Region } from "@/typings/store/plugins/easyFirestore/regiones";
import { Ciudad } from "@/typings/store/plugins/easyFirestore/ciudades";
import { mapActions, mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import "@/helpers/extensions";
import { Firebase } from "@/config/firebase";
import { Usuario } from "@/typings/store/plugins/easyFirestore/usuarios";

@Component({
  components: {
    SVGIcon,
  },
  validations: {
    nombre: { required },
    rut: { required },
    email: { required },
    telefono: { required },
    password: { required },
    region: { required },
    ciudad: { required },
  },
  computed: mapGetters({
    getArrayRegiones: "regiones/getArray",
    getArrayCiudades: "ciudades/getArray",
    getArrayUsuarios: "usuarios/getArray",
  }),
  methods: mapActions({
    setUsuario: "usuarios/set",
    setCarrito: "carrito/set",
  }),
})
export default class FormularioRegistro extends Vue {
  nombre = "";
  rut = "";
  email = "";
  password = "";
  telefono = "";
  region: Region | null = null;
  ciudad: Ciudad | null = null;
  cargando = false;
  invalidEmail = false;
  emailAlreadyExists = false;
  weakPassword = false;

  generaCiudad(): void {
    const Comunas = [
      {
        nombre: "Alto Hospicio",
        region: {
          id: "HbrHnh6lbZNlDeAlLTGi",
          nombre: "Tarapacá",
        },
      },
      {
        nombre: "Camiña",
        region: {
          id: "HbrHnh6lbZNlDeAlLTGi",
          nombre: "Tarapacá",
        },
      },
      {
        nombre: "Colchane",
        region: {
          id: "HbrHnh6lbZNlDeAlLTGi",
          nombre: "Tarapacá",
        },
      },
      {
        nombre: "Huara",
        region: {
          id: "HbrHnh6lbZNlDeAlLTGi",
          nombre: "Tarapacá",
        },
      },
      {
        nombre: "Iquique",
        region: {
          id: "HbrHnh6lbZNlDeAlLTGi",
          nombre: "Tarapacá",
        },
      },
      {
        nombre: "Pica",
        region: {
          id: "HbrHnh6lbZNlDeAlLTGi",
          nombre: "Tarapacá",
        },
      },
      {
        nombre: "Pozo Almonte",
        region: {
          id: "HbrHnh6lbZNlDeAlLTGi",
          nombre: "Tarapacá",
        },
      },

      {
        nombre: "Antofagasta",
        region: {
          id: "6TAy9WvFc6o3CXMR8sbL",
          nombre: "Antofagasta",
        },
      },
      {
        nombre: "Calama",
        region: {
          id: "6TAy9WvFc6o3CXMR8sbL",
          nombre: "Antofagasta",
        },
      },
      {
        nombre: "María Elena",
        region: {
          id: "6TAy9WvFc6o3CXMR8sbL",
          nombre: "Antofagasta",
        },
      },
      {
        nombre: "Mejillones",
        region: {
          id: "6TAy9WvFc6o3CXMR8sbL",
          nombre: "Antofagasta",
        },
      },
      {
        nombre: "Ollagüe",
        region: {
          id: "6TAy9WvFc6o3CXMR8sbL",
          nombre: "Antofagasta",
        },
      },
      {
        nombre: "San Pedro de Atacama",
        region: {
          id: "6TAy9WvFc6o3CXMR8sbL",
          nombre: "Antofagasta",
        },
      },
      {
        nombre: "Sierra Gorda",
        region: {
          id: "6TAy9WvFc6o3CXMR8sbL",
          nombre: "Antofagasta",
        },
      },
      {
        nombre: "Taltal",
        region: {
          id: "6TAy9WvFc6o3CXMR8sbL",
          nombre: "Antofagasta",
        },
      },
      {
        nombre: "Tocopilla",
        region: {
          id: "6TAy9WvFc6o3CXMR8sbL",
          nombre: "Antofagasta",
        },
      },

      {
        nombre: "Alto del Carmen",
        region: {
          id: "VGfqguo9R8GSF6l1wflq",
          nombre: "Atacama",
        },
      },
      {
        nombre: "Caldera",
        region: {
          id: "VGfqguo9R8GSF6l1wflq",
          nombre: "Atacama",
        },
      },
      {
        nombre: "Chañaral",
        region: {
          id: "VGfqguo9R8GSF6l1wflq",
          nombre: "Atacama",
        },
      },
      {
        nombre: "Copiapó",
        region: {
          id: "VGfqguo9R8GSF6l1wflq",
          nombre: "Atacama",
        },
      },
      {
        nombre: "Diego de Almagro",
        region: {
          id: "VGfqguo9R8GSF6l1wflq",
          nombre: "Atacama",
        },
      },
      {
        nombre: "Freirina",
        region: {
          id: "VGfqguo9R8GSF6l1wflq",
          nombre: "Atacama",
        },
      },
      {
        nombre: "Huasco",
        region: {
          id: "VGfqguo9R8GSF6l1wflq",
          nombre: "Atacama",
        },
      },
      {
        nombre: "Tierra Amarilla",
        region: {
          id: "VGfqguo9R8GSF6l1wflq",
          nombre: "Atacama",
        },
      },
      {
        nombre: "Vallenar",
        region: {
          id: "VGfqguo9R8GSF6l1wflq",
          nombre: "Atacama",
        },
      },

      {
        nombre: "Andacollo",
        region: {
          id: "xA2pkegSMKbuQxdzsy3n",
          nombre: "Coquimbo",
        },
      },
      {
        nombre: "Canela",
        region: {
          id: "xA2pkegSMKbuQxdzsy3n",
          nombre: "Coquimbo",
        },
      },
      {
        nombre: "Combarbalá",
        region: {
          id: "xA2pkegSMKbuQxdzsy3n",
          nombre: "Coquimbo",
        },
      },
      {
        nombre: "Coquimbo",
        region: {
          id: "xA2pkegSMKbuQxdzsy3n",
          nombre: "Coquimbo",
        },
      },
      {
        nombre: "Illapel",
        region: {
          id: "xA2pkegSMKbuQxdzsy3n",
          nombre: "Coquimbo",
        },
      },
      {
        nombre: "La Higuera",
        region: {
          id: "xA2pkegSMKbuQxdzsy3n",
          nombre: "Coquimbo",
        },
      },
      {
        nombre: "La Serena",
        region: {
          id: "xA2pkegSMKbuQxdzsy3n",
          nombre: "Coquimbo",
        },
      },
      {
        nombre: "Los Vilos",
        region: {
          id: "xA2pkegSMKbuQxdzsy3n",
          nombre: "Coquimbo",
        },
      },
      {
        nombre: "Monte Patria",
        region: {
          id: "xA2pkegSMKbuQxdzsy3n",
          nombre: "Coquimbo",
        },
      },
      {
        nombre: "Ovalle",
        region: {
          id: "xA2pkegSMKbuQxdzsy3n",
          nombre: "Coquimbo",
        },
      },
      {
        nombre: "Paiguano",
        region: {
          id: "xA2pkegSMKbuQxdzsy3n",
          nombre: "Coquimbo",
        },
      },
      {
        nombre: "Punitaqui",
        region: {
          id: "xA2pkegSMKbuQxdzsy3n",
          nombre: "Coquimbo",
        },
      },
      {
        nombre: "Río Hurtado",
        region: {
          id: "xA2pkegSMKbuQxdzsy3n",
          nombre: "Coquimbo",
        },
      },
      {
        nombre: "Salamanca",
        region: {
          id: "xA2pkegSMKbuQxdzsy3n",
          nombre: "Coquimbo",
        },
      },
      {
        nombre: "Vicuña",
        region: {
          id: "xA2pkegSMKbuQxdzsy3n",
          nombre: "Coquimbo",
        },
      },

      {
        nombre: "Algarrobo",
        region: {
          id: "qWtIxJ6p7v88jJ1dIrTG",
          nombre: "Valparaíso",
        },
      },
      {
        nombre: "Cabildo",
        region: {
          id: "qWtIxJ6p7v88jJ1dIrTG",
          nombre: "Valparaíso",
        },
      },
      {
        nombre: "Calera",
        region: {
          id: "qWtIxJ6p7v88jJ1dIrTG",
          nombre: "Valparaíso",
        },
      },
      {
        nombre: "Calle Larga",
        region: {
          id: "qWtIxJ6p7v88jJ1dIrTG",
          nombre: "Valparaíso",
        },
      },
      {
        nombre: "Cartagena",
        region: {
          id: "qWtIxJ6p7v88jJ1dIrTG",
          nombre: "Valparaíso",
        },
      },
      {
        nombre: "Casablanca",
        region: {
          id: "qWtIxJ6p7v88jJ1dIrTG",
          nombre: "Valparaíso",
        },
      },
      {
        nombre: "Catemu",
        region: {
          id: "qWtIxJ6p7v88jJ1dIrTG",
          nombre: "Valparaíso",
        },
      },
      {
        nombre: "Concón",
        region: {
          id: "qWtIxJ6p7v88jJ1dIrTG",
          nombre: "Valparaíso",
        },
      },
      {
        nombre: "El Quisco",
        region: {
          id: "qWtIxJ6p7v88jJ1dIrTG",
          nombre: "Valparaíso",
        },
      },
      {
        nombre: "El Tabo",
        region: {
          id: "qWtIxJ6p7v88jJ1dIrTG",
          nombre: "Valparaíso",
        },
      },
      {
        nombre: "Hijuelas",
        region: {
          id: "qWtIxJ6p7v88jJ1dIrTG",
          nombre: "Valparaíso",
        },
      },
      {
        nombre: "Isla de Pascua",
        region: {
          id: "qWtIxJ6p7v88jJ1dIrTG",
          nombre: "Valparaíso",
        },
      },
      {
        nombre: "Juan Fernández",
        region: {
          id: "qWtIxJ6p7v88jJ1dIrTG",
          nombre: "Valparaíso",
        },
      },
      {
        nombre: "La Cruz",
        region: {
          id: "qWtIxJ6p7v88jJ1dIrTG",
          nombre: "Valparaíso",
        },
      },
      {
        nombre: "La Ligua",
        region: {
          id: "qWtIxJ6p7v88jJ1dIrTG",
          nombre: "Valparaíso",
        },
      },
      {
        nombre: "Limache",
        region: {
          id: "qWtIxJ6p7v88jJ1dIrTG",
          nombre: "Valparaíso",
        },
      },
      {
        nombre: "Llaillay",
        region: {
          id: "qWtIxJ6p7v88jJ1dIrTG",
          nombre: "Valparaíso",
        },
      },
      {
        nombre: "Los Andes",
        region: {
          id: "qWtIxJ6p7v88jJ1dIrTG",
          nombre: "Valparaíso",
        },
      },
      {
        nombre: "Nogales",
        region: {
          id: "qWtIxJ6p7v88jJ1dIrTG",
          nombre: "Valparaíso",
        },
      },
      {
        nombre: "Olmué",
        region: {
          id: "qWtIxJ6p7v88jJ1dIrTG",
          nombre: "Valparaíso",
        },
      },
      {
        nombre: "Panquehue",
        region: {
          id: "qWtIxJ6p7v88jJ1dIrTG",
          nombre: "Valparaíso",
        },
      },
      {
        nombre: "Papudo",
        region: {
          id: "qWtIxJ6p7v88jJ1dIrTG",
          nombre: "Valparaíso",
        },
      },
      {
        nombre: "Petorca",
        region: {
          id: "qWtIxJ6p7v88jJ1dIrTG",
          nombre: "Valparaíso",
        },
      },
      {
        nombre: "Puchuncaví",
        region: {
          id: "qWtIxJ6p7v88jJ1dIrTG",
          nombre: "Valparaíso",
        },
      },
      {
        nombre: "Putaendo",
        region: {
          id: "qWtIxJ6p7v88jJ1dIrTG",
          nombre: "Valparaíso",
        },
      },
      {
        nombre: "Quillota",
        region: {
          id: "qWtIxJ6p7v88jJ1dIrTG",
          nombre: "Valparaíso",
        },
      },
      {
        nombre: "Quilpué",
        region: {
          id: "qWtIxJ6p7v88jJ1dIrTG",
          nombre: "Valparaíso",
        },
      },
      {
        nombre: "Quintero",
        region: {
          id: "qWtIxJ6p7v88jJ1dIrTG",
          nombre: "Valparaíso",
        },
      },
      {
        nombre: "Rinconada",
        region: {
          id: "qWtIxJ6p7v88jJ1dIrTG",
          nombre: "Valparaíso",
        },
      },
      {
        nombre: "San Antonio",
        region: {
          id: "qWtIxJ6p7v88jJ1dIrTG",
          nombre: "Valparaíso",
        },
      },
      {
        nombre: "San Esteban",
        region: {
          id: "qWtIxJ6p7v88jJ1dIrTG",
          nombre: "Valparaíso",
        },
      },
      {
        nombre: "San Felipe",
        region: {
          id: "qWtIxJ6p7v88jJ1dIrTG",
          nombre: "Valparaíso",
        },
      },
      {
        nombre: "Santa María",
        region: {
          id: "qWtIxJ6p7v88jJ1dIrTG",
          nombre: "Valparaíso",
        },
      },
      {
        nombre: "Santo Domingo",
        region: {
          id: "qWtIxJ6p7v88jJ1dIrTG",
          nombre: "Valparaíso",
        },
      },
      {
        nombre: "Valparaíso",
        region: {
          id: "qWtIxJ6p7v88jJ1dIrTG",
          nombre: "Valparaíso",
        },
      },
      {
        nombre: "Villa Alemana",
        region: {
          id: "qWtIxJ6p7v88jJ1dIrTG",
          nombre: "Valparaíso",
        },
      },
      {
        nombre: "Viña del Mar",
        region: {
          id: "qWtIxJ6p7v88jJ1dIrTG",
          nombre: "Valparaíso",
        },
      },
      {
        nombre: "Zapallar",
        region: {
          id: "qWtIxJ6p7v88jJ1dIrTG",
          nombre: "Valparaíso",
        },
      },

      {
        nombre: "Alhué",
        region: {
          id: "f6AeufG3DHt7OKvhzbNT",
          nombre: "Metropolitana de Santiago",
        },
      },
      {
        nombre: "Buin",
        region: {
          id: "f6AeufG3DHt7OKvhzbNT",
          nombre: "Metropolitana de Santiago",
        },
      },
      {
        nombre: "Calera de Tango",
        region: {
          id: "f6AeufG3DHt7OKvhzbNT",
          nombre: "Metropolitana de Santiago",
        },
      },
      {
        nombre: "Cerrillos",
        region: {
          id: "f6AeufG3DHt7OKvhzbNT",
          nombre: "Metropolitana de Santiago",
        },
      },
      {
        nombre: "Cerro Navia",
        region: {
          id: "f6AeufG3DHt7OKvhzbNT",
          nombre: "Metropolitana de Santiago",
        },
      },
      {
        nombre: "Colina",
        region: {
          id: "f6AeufG3DHt7OKvhzbNT",
          nombre: "Metropolitana de Santiago",
        },
      },
      {
        nombre: "Conchalí",
        region: {
          id: "f6AeufG3DHt7OKvhzbNT",
          nombre: "Metropolitana de Santiago",
        },
      },
      {
        nombre: "Curacaví",
        region: {
          id: "f6AeufG3DHt7OKvhzbNT",
          nombre: "Metropolitana de Santiago",
        },
      },
      {
        nombre: "El Bosque",
        region: {
          id: "f6AeufG3DHt7OKvhzbNT",
          nombre: "Metropolitana de Santiago",
        },
      },
      {
        nombre: "El Monte",
        region: {
          id: "f6AeufG3DHt7OKvhzbNT",
          nombre: "Metropolitana de Santiago",
        },
      },
      {
        nombre: "Estación Central",
        region: {
          id: "f6AeufG3DHt7OKvhzbNT",
          nombre: "Metropolitana de Santiago",
        },
      },
      {
        nombre: "Huechuraba",
        region: {
          id: "f6AeufG3DHt7OKvhzbNT",
          nombre: "Metropolitana de Santiago",
        },
      },
      {
        nombre: "Independencia",
        region: {
          id: "f6AeufG3DHt7OKvhzbNT",
          nombre: "Metropolitana de Santiago",
        },
      },
      {
        nombre: "Isla de Maipo",
        region: {
          id: "f6AeufG3DHt7OKvhzbNT",
          nombre: "Metropolitana de Santiago",
        },
      },
      {
        nombre: "La Cisterna",
        region: {
          id: "f6AeufG3DHt7OKvhzbNT",
          nombre: "Metropolitana de Santiago",
        },
      },
      {
        nombre: "La Florida",
        region: {
          id: "f6AeufG3DHt7OKvhzbNT",
          nombre: "Metropolitana de Santiago",
        },
      },
      {
        nombre: "La Granja",
        region: {
          id: "f6AeufG3DHt7OKvhzbNT",
          nombre: "Metropolitana de Santiago",
        },
      },
      {
        nombre: "La Pintana",
        region: {
          id: "f6AeufG3DHt7OKvhzbNT",
          nombre: "Metropolitana de Santiago",
        },
      },
      {
        nombre: "La Reina",
        region: {
          id: "f6AeufG3DHt7OKvhzbNT",
          nombre: "Metropolitana de Santiago",
        },
      },
      {
        nombre: "Lampa",
        region: {
          id: "f6AeufG3DHt7OKvhzbNT",
          nombre: "Metropolitana de Santiago",
        },
      },
      {
        nombre: "Las Condes",
        region: {
          id: "f6AeufG3DHt7OKvhzbNT",
          nombre: "Metropolitana de Santiago",
        },
      },
      {
        nombre: "Lo Barnechea",
        region: {
          id: "f6AeufG3DHt7OKvhzbNT",
          nombre: "Metropolitana de Santiago",
        },
      },
      {
        nombre: "Lo Espejo",
        region: {
          id: "f6AeufG3DHt7OKvhzbNT",
          nombre: "Metropolitana de Santiago",
        },
      },
      {
        nombre: "Lo Prado",
        region: {
          id: "f6AeufG3DHt7OKvhzbNT",
          nombre: "Metropolitana de Santiago",
        },
      },
      {
        nombre: "Macul",
        region: {
          id: "f6AeufG3DHt7OKvhzbNT",
          nombre: "Metropolitana de Santiago",
        },
      },
      {
        nombre: "Maipú",
        region: {
          id: "f6AeufG3DHt7OKvhzbNT",
          nombre: "Metropolitana de Santiago",
        },
      },
      {
        nombre: "María Pinto",
        region: {
          id: "f6AeufG3DHt7OKvhzbNT",
          nombre: "Metropolitana de Santiago",
        },
      },
      {
        nombre: "Melipilla",
        region: {
          id: "f6AeufG3DHt7OKvhzbNT",
          nombre: "Metropolitana de Santiago",
        },
      },
      {
        nombre: "Ñuñoa",
        region: {
          id: "f6AeufG3DHt7OKvhzbNT",
          nombre: "Metropolitana de Santiago",
        },
      },
      {
        nombre: "Padre Hurtado",
        region: {
          id: "f6AeufG3DHt7OKvhzbNT",
          nombre: "Metropolitana de Santiago",
        },
      },
      {
        nombre: "Paine",
        region: {
          id: "f6AeufG3DHt7OKvhzbNT",
          nombre: "Metropolitana de Santiago",
        },
      },
      {
        nombre: "Pedro Aguirre Cerda",
        region: {
          id: "f6AeufG3DHt7OKvhzbNT",
          nombre: "Metropolitana de Santiago",
        },
      },
      {
        nombre: "Peñaflor",
        region: {
          id: "f6AeufG3DHt7OKvhzbNT",
          nombre: "Metropolitana de Santiago",
        },
      },
      {
        nombre: "Peñalolén",
        region: {
          id: "f6AeufG3DHt7OKvhzbNT",
          nombre: "Metropolitana de Santiago",
        },
      },
      {
        nombre: "Pirque",
        region: {
          id: "f6AeufG3DHt7OKvhzbNT",
          nombre: "Metropolitana de Santiago",
        },
      },
      {
        nombre: "Providencia",
        region: {
          id: "f6AeufG3DHt7OKvhzbNT",
          nombre: "Metropolitana de Santiago",
        },
      },
      {
        nombre: "Pudahuel",
        region: {
          id: "f6AeufG3DHt7OKvhzbNT",
          nombre: "Metropolitana de Santiago",
        },
      },
      {
        nombre: "Puente Alto",
        region: {
          id: "f6AeufG3DHt7OKvhzbNT",
          nombre: "Metropolitana de Santiago",
        },
      },
      {
        nombre: "Quilicura",
        region: {
          id: "f6AeufG3DHt7OKvhzbNT",
          nombre: "Metropolitana de Santiago",
        },
      },
      {
        nombre: "Quinta Normal",
        region: {
          id: "f6AeufG3DHt7OKvhzbNT",
          nombre: "Metropolitana de Santiago",
        },
      },
      {
        nombre: "Recoleta",
        region: {
          id: "f6AeufG3DHt7OKvhzbNT",
          nombre: "Metropolitana de Santiago",
        },
      },
      {
        nombre: "Renca",
        region: {
          id: "f6AeufG3DHt7OKvhzbNT",
          nombre: "Metropolitana de Santiago",
        },
      },
      {
        nombre: "San Bernardo",
        region: {
          id: "f6AeufG3DHt7OKvhzbNT",
          nombre: "Metropolitana de Santiago",
        },
      },
      {
        nombre: "San Joaquín",
        region: {
          id: "f6AeufG3DHt7OKvhzbNT",
          nombre: "Metropolitana de Santiago",
        },
      },
      {
        nombre: "San José de Maipo",
        region: {
          id: "f6AeufG3DHt7OKvhzbNT",
          nombre: "Metropolitana de Santiago",
        },
      },
      {
        nombre: "San Miguel",
        region: {
          id: "f6AeufG3DHt7OKvhzbNT",
          nombre: "Metropolitana de Santiago",
        },
      },
      {
        nombre: "San Pedro",
        region: {
          id: "f6AeufG3DHt7OKvhzbNT",
          nombre: "Metropolitana de Santiago",
        },
      },
      {
        nombre: "San Ramón",
        region: {
          id: "f6AeufG3DHt7OKvhzbNT",
          nombre: "Metropolitana de Santiago",
        },
      },
      {
        nombre: "Santiago",
        region: {
          id: "f6AeufG3DHt7OKvhzbNT",
          nombre: "Metropolitana de Santiago",
        },
      },
      {
        nombre: "Talagante",
        region: {
          id: "f6AeufG3DHt7OKvhzbNT",
          nombre: "Metropolitana de Santiago",
        },
      },
      {
        nombre: "Tiltil",
        region: {
          id: "f6AeufG3DHt7OKvhzbNT",
          nombre: "Metropolitana de Santiago",
        },
      },
      {
        nombre: "Vitacura",
        region: {
          id: "f6AeufG3DHt7OKvhzbNT",
          nombre: "Metropolitana de Santiago",
        },
      },

      {
        nombre: "Chimbarongo",
        region: {
          id: "cqHXRKpG3cHLtcNDy5TG",
          nombre: "Libertador Gral. Bernardo O’Higgins",
        },
      },
      {
        nombre: "Chépica",
        region: {
          id: "cqHXRKpG3cHLtcNDy5TG",
          nombre: "Libertador Gral. Bernardo O’Higgins",
        },
      },
      {
        nombre: "Codegua",
        region: {
          id: "cqHXRKpG3cHLtcNDy5TG",
          nombre: "Libertador Gral. Bernardo O’Higgins",
        },
      },
      {
        nombre: "Coinco",
        region: {
          id: "cqHXRKpG3cHLtcNDy5TG",
          nombre: "Libertador Gral. Bernardo O’Higgins",
        },
      },
      {
        nombre: "Coltauco",
        region: {
          id: "cqHXRKpG3cHLtcNDy5TG",
          nombre: "Libertador Gral. Bernardo O’Higgins",
        },
      },
      {
        nombre: "Doñihue",
        region: {
          id: "cqHXRKpG3cHLtcNDy5TG",
          nombre: "Libertador Gral. Bernardo O’Higgins",
        },
      },
      {
        nombre: "Graneros",
        region: {
          id: "cqHXRKpG3cHLtcNDy5TG",
          nombre: "Libertador Gral. Bernardo O’Higgins",
        },
      },
      {
        nombre: "La Estrella",
        region: {
          id: "cqHXRKpG3cHLtcNDy5TG",
          nombre: "Libertador Gral. Bernardo O’Higgins",
        },
      },
      {
        nombre: "Las Cabras",
        region: {
          id: "cqHXRKpG3cHLtcNDy5TG",
          nombre: "Libertador Gral. Bernardo O’Higgins",
        },
      },
      {
        nombre: "Litueche",
        region: {
          id: "cqHXRKpG3cHLtcNDy5TG",
          nombre: "Libertador Gral. Bernardo O’Higgins",
        },
      },
      {
        nombre: "Lolol",
        region: {
          id: "cqHXRKpG3cHLtcNDy5TG",
          nombre: "Libertador Gral. Bernardo O’Higgins",
        },
      },
      {
        nombre: "Machalí",
        region: {
          id: "cqHXRKpG3cHLtcNDy5TG",
          nombre: "Libertador Gral. Bernardo O’Higgins",
        },
      },
      {
        nombre: "Malloa",
        region: {
          id: "cqHXRKpG3cHLtcNDy5TG",
          nombre: "Libertador Gral. Bernardo O’Higgins",
        },
      },
      {
        nombre: "Marchihue",
        region: {
          id: "cqHXRKpG3cHLtcNDy5TG",
          nombre: "Libertador Gral. Bernardo O’Higgins",
        },
      },
      {
        nombre: "Nancagua",
        region: {
          id: "cqHXRKpG3cHLtcNDy5TG",
          nombre: "Libertador Gral. Bernardo O’Higgins",
        },
      },
      {
        nombre: "Navidad",
        region: {
          id: "cqHXRKpG3cHLtcNDy5TG",
          nombre: "Libertador Gral. Bernardo O’Higgins",
        },
      },
      {
        nombre: "Olivar",
        region: {
          id: "cqHXRKpG3cHLtcNDy5TG",
          nombre: "Libertador Gral. Bernardo O’Higgins",
        },
      },
      {
        nombre: "Palmilla",
        region: {
          id: "cqHXRKpG3cHLtcNDy5TG",
          nombre: "Libertador Gral. Bernardo O’Higgins",
        },
      },
      {
        nombre: "Paredones",
        region: {
          id: "cqHXRKpG3cHLtcNDy5TG",
          nombre: "Libertador Gral. Bernardo O’Higgins",
        },
      },
      {
        nombre: "Peralillo",
        region: {
          id: "cqHXRKpG3cHLtcNDy5TG",
          nombre: "Libertador Gral. Bernardo O’Higgins",
        },
      },
      {
        nombre: "Peumo",
        region: {
          id: "cqHXRKpG3cHLtcNDy5TG",
          nombre: "Libertador Gral. Bernardo O’Higgins",
        },
      },
      {
        nombre: "Pichidegua",
        region: {
          id: "cqHXRKpG3cHLtcNDy5TG",
          nombre: "Libertador Gral. Bernardo O’Higgins",
        },
      },
      {
        nombre: "Pichilemu",
        region: {
          id: "cqHXRKpG3cHLtcNDy5TG",
          nombre: "Libertador Gral. Bernardo O’Higgins",
        },
      },
      {
        nombre: "Placilla",
        region: {
          id: "cqHXRKpG3cHLtcNDy5TG",
          nombre: "Libertador Gral. Bernardo O’Higgins",
        },
      },
      {
        nombre: "Pumanque",
        region: {
          id: "cqHXRKpG3cHLtcNDy5TG",
          nombre: "Libertador Gral. Bernardo O’Higgins",
        },
      },
      {
        nombre: "Quinta de Tilcoco",
        region: {
          id: "cqHXRKpG3cHLtcNDy5TG",
          nombre: "Libertador Gral. Bernardo O’Higgins",
        },
      },
      {
        nombre: "Rancagua",
        region: {
          id: "cqHXRKpG3cHLtcNDy5TG",
          nombre: "Libertador Gral. Bernardo O’Higgins",
        },
      },
      {
        nombre: "Rengo",
        region: {
          id: "cqHXRKpG3cHLtcNDy5TG",
          nombre: "Libertador Gral. Bernardo O’Higgins",
        },
      },
      {
        nombre: "Requínoa",
        region: {
          id: "cqHXRKpG3cHLtcNDy5TG",
          nombre: "Libertador Gral. Bernardo O’Higgins",
        },
      },
      {
        nombre: "San Fernando",
        region: {
          id: "cqHXRKpG3cHLtcNDy5TG",
          nombre: "Libertador Gral. Bernardo O’Higgins",
        },
      },
      {
        nombre: "San Francisco de Mostazal",
        region: {
          id: "cqHXRKpG3cHLtcNDy5TG",
          nombre: "Libertador Gral. Bernardo O’Higgins",
        },
      },
      {
        nombre: "San Vicente de Tagua Tagua",
        region: {
          id: "cqHXRKpG3cHLtcNDy5TG",
          nombre: "Libertador Gral. Bernardo O’Higgins",
        },
      },
      {
        nombre: "Santa Cruz",
        region: {
          id: "cqHXRKpG3cHLtcNDy5TG",
          nombre: "Libertador Gral. Bernardo O’Higgins",
        },
      },

      {
        nombre: "Cauquenes",
        region: {
          id: "Zk7rhs2BbYoj8oJtgIA5",
          nombre: "Maule",
        },
      },
      {
        nombre: "Chanco",
        region: {
          id: "Zk7rhs2BbYoj8oJtgIA5",
          nombre: "Maule",
        },
      },
      {
        nombre: "Colbún",
        region: {
          id: "Zk7rhs2BbYoj8oJtgIA5",
          nombre: "Maule",
        },
      },
      {
        nombre: "Constitución",
        region: {
          id: "Zk7rhs2BbYoj8oJtgIA5",
          nombre: "Maule",
        },
      },
      {
        nombre: "Curepto",
        region: {
          id: "Zk7rhs2BbYoj8oJtgIA5",
          nombre: "Maule",
        },
      },
      {
        nombre: "Curicó",
        region: {
          id: "Zk7rhs2BbYoj8oJtgIA5",
          nombre: "Maule",
        },
      },
      {
        nombre: "Empedrado",
        region: {
          id: "Zk7rhs2BbYoj8oJtgIA5",
          nombre: "Maule",
        },
      },
      {
        nombre: "Hualañé",
        region: {
          id: "Zk7rhs2BbYoj8oJtgIA5",
          nombre: "Maule",
        },
      },
      {
        nombre: "Licantén",
        region: {
          id: "Zk7rhs2BbYoj8oJtgIA5",
          nombre: "Maule",
        },
      },
      {
        nombre: "Linares",
        region: {
          id: "Zk7rhs2BbYoj8oJtgIA5",
          nombre: "Maule",
        },
      },
      {
        nombre: "Longaví",
        region: {
          id: "Zk7rhs2BbYoj8oJtgIA5",
          nombre: "Maule",
        },
      },
      {
        nombre: "Maule",
        region: {
          id: "Zk7rhs2BbYoj8oJtgIA5",
          nombre: "Maule",
        },
      },
      {
        nombre: "Molina",
        region: {
          id: "Zk7rhs2BbYoj8oJtgIA5",
          nombre: "Maule",
        },
      },
      {
        nombre: "Parral",
        region: {
          id: "Zk7rhs2BbYoj8oJtgIA5",
          nombre: "Maule",
        },
      },
      {
        nombre: "Pelarco",
        region: {
          id: "Zk7rhs2BbYoj8oJtgIA5",
          nombre: "Maule",
        },
      },
      {
        nombre: "Pelluhue",
        region: {
          id: "Zk7rhs2BbYoj8oJtgIA5",
          nombre: "Maule",
        },
      },
      {
        nombre: "Pencahue",
        region: {
          id: "Zk7rhs2BbYoj8oJtgIA5",
          nombre: "Maule",
        },
      },
      {
        nombre: "Rauco",
        region: {
          id: "Zk7rhs2BbYoj8oJtgIA5",
          nombre: "Maule",
        },
      },
      {
        nombre: "Retiro",
        region: {
          id: "Zk7rhs2BbYoj8oJtgIA5",
          nombre: "Maule",
        },
      },
      {
        nombre: "Romeral",
        region: {
          id: "Zk7rhs2BbYoj8oJtgIA5",
          nombre: "Maule",
        },
      },
      {
        nombre: "Río Claro",
        region: {
          id: "Zk7rhs2BbYoj8oJtgIA5",
          nombre: "Maule",
        },
      },
      {
        nombre: "Sagrada Familia",
        region: {
          id: "Zk7rhs2BbYoj8oJtgIA5",
          nombre: "Maule",
        },
      },
      {
        nombre: "San Clemente",
        region: {
          id: "Zk7rhs2BbYoj8oJtgIA5",
          nombre: "Maule",
        },
      },
      {
        nombre: "San Javier de Loncomilla",
        region: {
          id: "Zk7rhs2BbYoj8oJtgIA5",
          nombre: "Maule",
        },
      },
      {
        nombre: "San Rafael",
        region: {
          id: "Zk7rhs2BbYoj8oJtgIA5",
          nombre: "Maule",
        },
      },
      {
        nombre: "Talca",
        region: {
          id: "Zk7rhs2BbYoj8oJtgIA5",
          nombre: "Maule",
        },
      },
      {
        nombre: "Teno",
        region: {
          id: "Zk7rhs2BbYoj8oJtgIA5",
          nombre: "Maule",
        },
      },
      {
        nombre: "Vichuquén",
        region: {
          id: "Zk7rhs2BbYoj8oJtgIA5",
          nombre: "Maule",
        },
      },
      {
        nombre: "Villa Alegre",
        region: {
          id: "Zk7rhs2BbYoj8oJtgIA5",
          nombre: "Maule",
        },
      },
      {
        nombre: "Yerbas Buenas",
        region: {
          id: "Zk7rhs2BbYoj8oJtgIA5",
          nombre: "Maule",
        },
      },

      {
        nombre: "Bulnes",
        region: {
          id: "Fmt4sAuH1qPmT2aZG2Do",
          nombre: "Ñuble",
        },
      },
      {
        nombre: "Chillán Viejo",
        region: {
          id: "Fmt4sAuH1qPmT2aZG2Do",
          nombre: "Ñuble",
        },
      },
      {
        nombre: "Chillán",
        region: {
          id: "Fmt4sAuH1qPmT2aZG2Do",
          nombre: "Ñuble",
        },
      },
      {
        nombre: "Cobquecura",
        region: {
          id: "Fmt4sAuH1qPmT2aZG2Do",
          nombre: "Ñuble",
        },
      },
      {
        nombre: "Coelemu",
        region: {
          id: "Fmt4sAuH1qPmT2aZG2Do",
          nombre: "Ñuble",
        },
      },
      {
        nombre: "Coihueco",
        region: {
          id: "Fmt4sAuH1qPmT2aZG2Do",
          nombre: "Ñuble",
        },
      },
      {
        nombre: "El Carmen",
        region: {
          id: "Fmt4sAuH1qPmT2aZG2Do",
          nombre: "Ñuble",
        },
      },
      {
        nombre: "Ninhue",
        region: {
          id: "Fmt4sAuH1qPmT2aZG2Do",
          nombre: "Ñuble",
        },
      },
      {
        nombre: "Ñiquén",
        region: {
          id: "Fmt4sAuH1qPmT2aZG2Do",
          nombre: "Ñuble",
        },
      },
      {
        nombre: "Pemuco",
        region: {
          id: "Fmt4sAuH1qPmT2aZG2Do",
          nombre: "Ñuble",
        },
      },
      {
        nombre: "Pinto",
        region: {
          id: "Fmt4sAuH1qPmT2aZG2Do",
          nombre: "Ñuble",
        },
      },
      {
        nombre: "Portezuelo",
        region: {
          id: "Fmt4sAuH1qPmT2aZG2Do",
          nombre: "Ñuble",
        },
      },
      {
        nombre: "Quillón",
        region: {
          id: "Fmt4sAuH1qPmT2aZG2Do",
          nombre: "Ñuble",
        },
      },
      {
        nombre: "Quirihue",
        region: {
          id: "Fmt4sAuH1qPmT2aZG2Do",
          nombre: "Ñuble",
        },
      },
      {
        nombre: "Ránquil",
        region: {
          id: "Fmt4sAuH1qPmT2aZG2Do",
          nombre: "Ñuble",
        },
      },
      {
        nombre: "San Carlos",
        region: {
          id: "Fmt4sAuH1qPmT2aZG2Do",
          nombre: "Ñuble",
        },
      },
      {
        nombre: "San Fabián",
        region: {
          id: "Fmt4sAuH1qPmT2aZG2Do",
          nombre: "Ñuble",
        },
      },
      {
        nombre: "San Ignacio",
        region: {
          id: "Fmt4sAuH1qPmT2aZG2Do",
          nombre: "Ñuble",
        },
      },
      {
        nombre: "San Nicolás",
        region: {
          id: "Fmt4sAuH1qPmT2aZG2Do",
          nombre: "Ñuble",
        },
      },
      {
        nombre: "Treguaco",
        region: {
          id: "Fmt4sAuH1qPmT2aZG2Do",
          nombre: "Ñuble",
        },
      },
      {
        nombre: "Yungay",
        region: {
          id: "Fmt4sAuH1qPmT2aZG2Do",
          nombre: "Ñuble",
        },
      },

      {
        nombre: "Alto Biobío",
        region: {
          id: "DYOj6wGNcOsMVcLHVWIt",
          nombre: "Biobío",
        },
      },
      {
        nombre: "Antuco",
        region: {
          id: "DYOj6wGNcOsMVcLHVWIt",
          nombre: "Biobío",
        },
      },
      {
        nombre: "Arauco",
        region: {
          id: "DYOj6wGNcOsMVcLHVWIt",
          nombre: "Biobío",
        },
      },
      {
        nombre: "Cabrero",
        region: {
          id: "DYOj6wGNcOsMVcLHVWIt",
          nombre: "Biobío",
        },
      },
      {
        nombre: "Cañete",
        region: {
          id: "DYOj6wGNcOsMVcLHVWIt",
          nombre: "Biobío",
        },
      },
      {
        nombre: "Chiguayante",
        region: {
          id: "DYOj6wGNcOsMVcLHVWIt",
          nombre: "Biobío",
        },
      },
      {
        nombre: "Concepción",
        region: {
          id: "DYOj6wGNcOsMVcLHVWIt",
          nombre: "Biobío",
        },
      },
      {
        nombre: "Contulmo",
        region: {
          id: "DYOj6wGNcOsMVcLHVWIt",
          nombre: "Biobío",
        },
      },
      {
        nombre: "Coronel",
        region: {
          id: "DYOj6wGNcOsMVcLHVWIt",
          nombre: "Biobío",
        },
      },
      {
        nombre: "Curanilahue",
        region: {
          id: "DYOj6wGNcOsMVcLHVWIt",
          nombre: "Biobío",
        },
      },
      {
        nombre: "Florida",
        region: {
          id: "DYOj6wGNcOsMVcLHVWIt",
          nombre: "Biobío",
        },
      },
      {
        nombre: "Hualpén",
        region: {
          id: "DYOj6wGNcOsMVcLHVWIt",
          nombre: "Biobío",
        },
      },
      {
        nombre: "Hualqui",
        region: {
          id: "DYOj6wGNcOsMVcLHVWIt",
          nombre: "Biobío",
        },
      },
      {
        nombre: "Laja",
        region: {
          id: "DYOj6wGNcOsMVcLHVWIt",
          nombre: "Biobío",
        },
      },
      {
        nombre: "Lebu",
        region: {
          id: "DYOj6wGNcOsMVcLHVWIt",
          nombre: "Biobío",
        },
      },
      {
        nombre: "Los Álamos",
        region: {
          id: "DYOj6wGNcOsMVcLHVWIt",
          nombre: "Biobío",
        },
      },
      {
        nombre: "Los Ángeles",
        region: {
          id: "DYOj6wGNcOsMVcLHVWIt",
          nombre: "Biobío",
        },
      },
      {
        nombre: "Lota",
        region: {
          id: "DYOj6wGNcOsMVcLHVWIt",
          nombre: "Biobío",
        },
      },
      {
        nombre: "Mulchén",
        region: {
          id: "DYOj6wGNcOsMVcLHVWIt",
          nombre: "Biobío",
        },
      },
      {
        nombre: "Nacimiento",
        region: {
          id: "DYOj6wGNcOsMVcLHVWIt",
          nombre: "Biobío",
        },
      },
      {
        nombre: "Negrete",
        region: {
          id: "DYOj6wGNcOsMVcLHVWIt",
          nombre: "Biobío",
        },
      },
      {
        nombre: "Penco",
        region: {
          id: "DYOj6wGNcOsMVcLHVWIt",
          nombre: "Biobío",
        },
      },
      {
        nombre: "Quilaco",
        region: {
          id: "DYOj6wGNcOsMVcLHVWIt",
          nombre: "Biobío",
        },
      },
      {
        nombre: "Quilleco",
        region: {
          id: "DYOj6wGNcOsMVcLHVWIt",
          nombre: "Biobío",
        },
      },
      {
        nombre: "San Pedro de la Paz",
        region: {
          id: "DYOj6wGNcOsMVcLHVWIt",
          nombre: "Biobío",
        },
      },
      {
        nombre: "San Rosendo",
        region: {
          id: "DYOj6wGNcOsMVcLHVWIt",
          nombre: "Biobío",
        },
      },
      {
        nombre: "Santa Bárbara",
        region: {
          id: "DYOj6wGNcOsMVcLHVWIt",
          nombre: "Biobío",
        },
      },
      {
        nombre: "Santa Juana",
        region: {
          id: "DYOj6wGNcOsMVcLHVWIt",
          nombre: "Biobío",
        },
      },
      {
        nombre: "Talcahuano",
        region: {
          id: "DYOj6wGNcOsMVcLHVWIt",
          nombre: "Biobío",
        },
      },
      {
        nombre: "Tirúa",
        region: {
          id: "DYOj6wGNcOsMVcLHVWIt",
          nombre: "Biobío",
        },
      },
      {
        nombre: "Tomé",
        region: {
          id: "DYOj6wGNcOsMVcLHVWIt",
          nombre: "Biobío",
        },
      },
      {
        nombre: "Tucapel",
        region: {
          id: "DYOj6wGNcOsMVcLHVWIt",
          nombre: "Biobío",
        },
      },
      {
        nombre: "Yumbel",
        region: {
          id: "DYOj6wGNcOsMVcLHVWIt",
          nombre: "Biobío",
        },
      },

      {
        nombre: "Angol",
        region: {
          id: "cq2RMi2xFPKW5nSfzD1x",
          nombre: "Araucanía",
        },
      },
      {
        nombre: "Carahue",
        region: {
          id: "cq2RMi2xFPKW5nSfzD1x",
          nombre: "Araucanía",
        },
      },
      {
        nombre: "Cholchol",
        region: {
          id: "cq2RMi2xFPKW5nSfzD1x",
          nombre: "Araucanía",
        },
      },
      {
        nombre: "Collipulli",
        region: {
          id: "cq2RMi2xFPKW5nSfzD1x",
          nombre: "Araucanía",
        },
      },
      {
        nombre: "Cunco",
        region: {
          id: "cq2RMi2xFPKW5nSfzD1x",
          nombre: "Araucanía",
        },
      },
      {
        nombre: "Curacautín",
        region: {
          id: "cq2RMi2xFPKW5nSfzD1x",
          nombre: "Araucanía",
        },
      },
      {
        nombre: "Curarrehue",
        region: {
          id: "cq2RMi2xFPKW5nSfzD1x",
          nombre: "Araucanía",
        },
      },
      {
        nombre: "Ercilla",
        region: {
          id: "cq2RMi2xFPKW5nSfzD1x",
          nombre: "Araucanía",
        },
      },
      {
        nombre: "Freire",
        region: {
          id: "cq2RMi2xFPKW5nSfzD1x",
          nombre: "Araucanía",
        },
      },
      {
        nombre: "Galvarino",
        region: {
          id: "cq2RMi2xFPKW5nSfzD1x",
          nombre: "Araucanía",
        },
      },
      {
        nombre: "Gorbea",
        region: {
          id: "cq2RMi2xFPKW5nSfzD1x",
          nombre: "Araucanía",
        },
      },
      {
        nombre: "Lautaro",
        region: {
          id: "cq2RMi2xFPKW5nSfzD1x",
          nombre: "Araucanía",
        },
      },
      {
        nombre: "Loncoche",
        region: {
          id: "cq2RMi2xFPKW5nSfzD1x",
          nombre: "Araucanía",
        },
      },
      {
        nombre: "Lonquimay",
        region: {
          id: "cq2RMi2xFPKW5nSfzD1x",
          nombre: "Araucanía",
        },
      },
      {
        nombre: "Los Sauces",
        region: {
          id: "cq2RMi2xFPKW5nSfzD1x",
          nombre: "Araucanía",
        },
      },
      {
        nombre: "Lumaco",
        region: {
          id: "cq2RMi2xFPKW5nSfzD1x",
          nombre: "Araucanía",
        },
      },
      {
        nombre: "Melipeuco",
        region: {
          id: "cq2RMi2xFPKW5nSfzD1x",
          nombre: "Araucanía",
        },
      },
      {
        nombre: "Nueva Imperial",
        region: {
          id: "cq2RMi2xFPKW5nSfzD1x",
          nombre: "Araucanía",
        },
      },
      {
        nombre: "Padre las Casas",
        region: {
          id: "cq2RMi2xFPKW5nSfzD1x",
          nombre: "Araucanía",
        },
      },
      {
        nombre: "Perquenco",
        region: {
          id: "cq2RMi2xFPKW5nSfzD1x",
          nombre: "Araucanía",
        },
      },
      {
        nombre: "Pitrufquén",
        region: {
          id: "cq2RMi2xFPKW5nSfzD1x",
          nombre: "Araucanía",
        },
      },
      {
        nombre: "Pucón",
        region: {
          id: "cq2RMi2xFPKW5nSfzD1x",
          nombre: "Araucanía",
        },
      },
      {
        nombre: "Purén",
        region: {
          id: "cq2RMi2xFPKW5nSfzD1x",
          nombre: "Araucanía",
        },
      },
      {
        nombre: "Renaico",
        region: {
          id: "cq2RMi2xFPKW5nSfzD1x",
          nombre: "Araucanía",
        },
      },
      {
        nombre: "Saavedra",
        region: {
          id: "cq2RMi2xFPKW5nSfzD1x",
          nombre: "Araucanía",
        },
      },
      {
        nombre: "Temuco",
        region: {
          id: "cq2RMi2xFPKW5nSfzD1x",
          nombre: "Araucanía",
        },
      },
      {
        nombre: "Teodoro Schmidt",
        region: {
          id: "cq2RMi2xFPKW5nSfzD1x",
          nombre: "Araucanía",
        },
      },
      {
        nombre: "Toltén",
        region: {
          id: "cq2RMi2xFPKW5nSfzD1x",
          nombre: "Araucanía",
        },
      },
      {
        nombre: "Traiguén",
        region: {
          id: "cq2RMi2xFPKW5nSfzD1x",
          nombre: "Araucanía",
        },
      },
      {
        nombre: "Victoria",
        region: {
          id: "cq2RMi2xFPKW5nSfzD1x",
          nombre: "Araucanía",
        },
      },
      {
        nombre: "Vilcún",
        region: {
          id: "cq2RMi2xFPKW5nSfzD1x",
          nombre: "Araucanía",
        },
      },
      {
        nombre: "Villarrica",
        region: {
          id: "cq2RMi2xFPKW5nSfzD1x",
          nombre: "Araucanía",
        },
      },

      {
        nombre: "Corral",
        region: {
          id: "KxrDuBRCCuzKI1kpkJS4",
          nombre: "Los Ríos",
        },
      },
      {
        nombre: "Futrono",
        region: {
          id: "KxrDuBRCCuzKI1kpkJS4",
          nombre: "Los Ríos",
        },
      },
      {
        nombre: "La Unión",
        region: {
          id: "KxrDuBRCCuzKI1kpkJS4",
          nombre: "Los Ríos",
        },
      },
      {
        nombre: "Lago Ranco",
        region: {
          id: "KxrDuBRCCuzKI1kpkJS4",
          nombre: "Los Ríos",
        },
      },
      {
        nombre: "Lanco",
        region: {
          id: "KxrDuBRCCuzKI1kpkJS4",
          nombre: "Los Ríos",
        },
      },
      {
        nombre: "Los Lagos",
        region: {
          id: "KxrDuBRCCuzKI1kpkJS4",
          nombre: "Los Ríos",
        },
      },
      {
        nombre: "Mariquina",
        region: {
          id: "KxrDuBRCCuzKI1kpkJS4",
          nombre: "Los Ríos",
        },
      },
      {
        nombre: "Máfil",
        region: {
          id: "KxrDuBRCCuzKI1kpkJS4",
          nombre: "Los Ríos",
        },
      },
      {
        nombre: "Paillaco",
        region: {
          id: "KxrDuBRCCuzKI1kpkJS4",
          nombre: "Los Ríos",
        },
      },
      {
        nombre: "Panguipulli",
        region: {
          id: "KxrDuBRCCuzKI1kpkJS4",
          nombre: "Los Ríos",
        },
      },
      {
        nombre: "Río Bueno",
        region: {
          id: "KxrDuBRCCuzKI1kpkJS4",
          nombre: "Los Ríos",
        },
      },
      {
        nombre: "Valdivia",
        region: {
          id: "KxrDuBRCCuzKI1kpkJS4",
          nombre: "Los Ríos",
        },
      },

      {
        nombre: "Ancud",
        region: {
          id: "NCdcSlQbjb9C28LhkMjw",
          nombre: "Los Lagos",
        },
      },
      {
        nombre: "Calbuco",
        region: {
          id: "NCdcSlQbjb9C28LhkMjw",
          nombre: "Los Lagos",
        },
      },
      {
        nombre: "Castro",
        region: {
          id: "NCdcSlQbjb9C28LhkMjw",
          nombre: "Los Lagos",
        },
      },
      {
        nombre: "Chaitén",
        region: {
          id: "NCdcSlQbjb9C28LhkMjw",
          nombre: "Los Lagos",
        },
      },
      {
        nombre: "Chonchi",
        region: {
          id: "NCdcSlQbjb9C28LhkMjw",
          nombre: "Los Lagos",
        },
      },
      {
        nombre: "Cochamó",
        region: {
          id: "NCdcSlQbjb9C28LhkMjw",
          nombre: "Los Lagos",
        },
      },
      {
        nombre: "Curaco de Vélez",
        region: {
          id: "NCdcSlQbjb9C28LhkMjw",
          nombre: "Los Lagos",
        },
      },
      {
        nombre: "Dalcahue",
        region: {
          id: "NCdcSlQbjb9C28LhkMjw",
          nombre: "Los Lagos",
        },
      },
      {
        nombre: "Fresia",
        region: {
          id: "NCdcSlQbjb9C28LhkMjw",
          nombre: "Los Lagos",
        },
      },
      {
        nombre: "Frutillar",
        region: {
          id: "NCdcSlQbjb9C28LhkMjw",
          nombre: "Los Lagos",
        },
      },
      {
        nombre: "Futaleufú",
        region: {
          id: "NCdcSlQbjb9C28LhkMjw",
          nombre: "Los Lagos",
        },
      },
      {
        nombre: "Hualaihué",
        region: {
          id: "NCdcSlQbjb9C28LhkMjw",
          nombre: "Los Lagos",
        },
      },
      {
        nombre: "Llanquihue",
        region: {
          id: "NCdcSlQbjb9C28LhkMjw",
          nombre: "Los Lagos",
        },
      },
      {
        nombre: "Los Muermos",
        region: {
          id: "NCdcSlQbjb9C28LhkMjw",
          nombre: "Los Lagos",
        },
      },
      {
        nombre: "Maullín",
        region: {
          id: "NCdcSlQbjb9C28LhkMjw",
          nombre: "Los Lagos",
        },
      },
      {
        nombre: "Osorno",
        region: {
          id: "NCdcSlQbjb9C28LhkMjw",
          nombre: "Los Lagos",
        },
      },
      {
        nombre: "Palena",
        region: {
          id: "NCdcSlQbjb9C28LhkMjw",
          nombre: "Los Lagos",
        },
      },
      {
        nombre: "Puerto Montt",
        region: {
          id: "NCdcSlQbjb9C28LhkMjw",
          nombre: "Los Lagos",
        },
      },
      {
        nombre: "Puerto Octay",
        region: {
          id: "NCdcSlQbjb9C28LhkMjw",
          nombre: "Los Lagos",
        },
      },
      {
        nombre: "Puerto Varas",
        region: {
          id: "NCdcSlQbjb9C28LhkMjw",
          nombre: "Los Lagos",
        },
      },
      {
        nombre: "Puqueldón",
        region: {
          id: "NCdcSlQbjb9C28LhkMjw",
          nombre: "Los Lagos",
        },
      },
      {
        nombre: "Purranque",
        region: {
          id: "NCdcSlQbjb9C28LhkMjw",
          nombre: "Los Lagos",
        },
      },
      {
        nombre: "Puyehue",
        region: {
          id: "NCdcSlQbjb9C28LhkMjw",
          nombre: "Los Lagos",
        },
      },
      {
        nombre: "Queilén",
        region: {
          id: "NCdcSlQbjb9C28LhkMjw",
          nombre: "Los Lagos",
        },
      },
      {
        nombre: "Quellón",
        region: {
          id: "NCdcSlQbjb9C28LhkMjw",
          nombre: "Los Lagos",
        },
      },
      {
        nombre: "Quemchi",
        region: {
          id: "NCdcSlQbjb9C28LhkMjw",
          nombre: "Los Lagos",
        },
      },
      {
        nombre: "Quinchao",
        region: {
          id: "NCdcSlQbjb9C28LhkMjw",
          nombre: "Los Lagos",
        },
      },
      {
        nombre: "Río Negro",
        region: {
          id: "NCdcSlQbjb9C28LhkMjw",
          nombre: "Los Lagos",
        },
      },
      {
        nombre: "San Juan de la Costa",
        region: {
          id: "NCdcSlQbjb9C28LhkMjw",
          nombre: "Los Lagos",
        },
      },
      {
        nombre: "San Pablo",
        region: {
          id: "NCdcSlQbjb9C28LhkMjw",
          nombre: "Los Lagos",
        },
      },

      {
        nombre: "Aisén",
        region: {
          id: "abcWmjprlCU3oz5PjPBz",
          nombre: "Aisén del Gral. Carlos Ibáñez del Campo",
        },
      },
      {
        nombre: "Chile Chico",
        region: {
          id: "abcWmjprlCU3oz5PjPBz",
          nombre: "Aisén del Gral. Carlos Ibáñez del Campo",
        },
      },
      {
        nombre: "Cisnes",
        region: {
          id: "abcWmjprlCU3oz5PjPBz",
          nombre: "Aisén del Gral. Carlos Ibáñez del Campo",
        },
      },
      {
        nombre: "Cochrane",
        region: {
          id: "abcWmjprlCU3oz5PjPBz",
          nombre: "Aisén del Gral. Carlos Ibáñez del Campo",
        },
      },
      {
        nombre: "Coyhaique",
        region: {
          id: "abcWmjprlCU3oz5PjPBz",
          nombre: "Aisén del Gral. Carlos Ibáñez del Campo",
        },
      },
      {
        nombre: "Guaitecas",
        region: {
          id: "abcWmjprlCU3oz5PjPBz",
          nombre: "Aisén del Gral. Carlos Ibáñez del Campo",
        },
      },
      {
        nombre: "Lago Verde",
        region: {
          id: "abcWmjprlCU3oz5PjPBz",
          nombre: "Aisén del Gral. Carlos Ibáñez del Campo",
        },
      },
      {
        nombre: "O’Higgins",
        region: {
          id: "abcWmjprlCU3oz5PjPBz",
          nombre: "Aisén del Gral. Carlos Ibáñez del Campo",
        },
      },
      {
        nombre: "Río Ibáñez",
        region: {
          id: "abcWmjprlCU3oz5PjPBz",
          nombre: "Aisén del Gral. Carlos Ibáñez del Campo",
        },
      },
      {
        nombre: "Tortel",
        region: {
          id: "abcWmjprlCU3oz5PjPBz",
          nombre: "Aisén del Gral. Carlos Ibáñez del Campo",
        },
      },

      {
        nombre: "Antártica",
        region: {
          id: "UNdThGSpCwO2PGCqJmWV",
          nombre: "Magallanes y de la Antártica Chilena",
        },
      },
      {
        nombre: "Cabo de Hornos (Ex Navarino)",
        region: {
          id: "UNdThGSpCwO2PGCqJmWV",
          nombre: "Magallanes y de la Antártica Chilena",
        },
      },
      {
        nombre: "Laguna Blanca",
        region: {
          id: "UNdThGSpCwO2PGCqJmWV",
          nombre: "Magallanes y de la Antártica Chilena",
        },
      },
      {
        nombre: "Natales",
        region: {
          id: "UNdThGSpCwO2PGCqJmWV",
          nombre: "Magallanes y de la Antártica Chilena",
        },
      },
      {
        nombre: "Porvenir",
        region: {
          id: "UNdThGSpCwO2PGCqJmWV",
          nombre: "Magallanes y de la Antártica Chilena",
        },
      },
      {
        nombre: "Primavera",
        region: {
          id: "UNdThGSpCwO2PGCqJmWV",
          nombre: "Magallanes y de la Antártica Chilena",
        },
      },
      {
        nombre: "Punta Arenas",
        region: {
          id: "UNdThGSpCwO2PGCqJmWV",
          nombre: "Magallanes y de la Antártica Chilena",
        },
      },
      {
        nombre: "Río Verde",
        region: {
          id: "UNdThGSpCwO2PGCqJmWV",
          nombre: "Magallanes y de la Antártica Chilena",
        },
      },
      {
        nombre: "San Gregorio",
        region: {
          id: "UNdThGSpCwO2PGCqJmWV",
          nombre: "Magallanes y de la Antártica Chilena",
        },
      },
      {
        nombre: "Timaukel",
        region: {
          id: "UNdThGSpCwO2PGCqJmWV",
          nombre: "Magallanes y de la Antártica Chilena",
        },
      },
      {
        nombre: "Torres del Paine",
        region: {
          id: "UNdThGSpCwO2PGCqJmWV",
          nombre: "Magallanes y de la Antártica Chilena",
        },
      },
    ];
    for (const comuna of Comunas) {
      this.$store.dispatch("ciudades/set", comuna);
    }
  }

  generaRegion(): void {
    const RegionesYComunas = [
      {
        nombre: "Arica y Parinacota",
      },
      {
        nombre: "Tarapacá",
      },
      {
        nombre: "Antofagasta",
      },
      {
        nombre: "Atacama",
      },
      {
        nombre: "Coquimbo",
      },
      {
        nombre: "Valparaíso",
      },
      {
        nombre: "Metropolitana de Santiago",
      },
      {
        nombre: "Libertador Gral. Bernardo O’Higgins",
      },
      {
        nombre: "Maule",
      },
      {
        nombre: "Ñuble",
      },
      {
        nombre: "Biobío",
      },
      {
        nombre: "Araucanía",
      },
      {
        nombre: "Los Ríos",
      },
      {
        nombre: "Los Lagos",
      },
      {
        nombre: "Aisén del Gral. Carlos Ibáñez del Campo",
      },
      {
        nombre: "Magallanes y de la Antártica Chilena",
      },
    ];
    for (const region of RegionesYComunas) {
      this.$store.dispatch("regiones/set", region);
    }
  }

  get regionesOrdenadas(): Region[] {
    return this.getArrayRegiones.sort((a, b) =>
      a.nombre.localeCompare(b.nombre)
    );
  }

  get ciudadesRegion(): Ciudad[] {
    return this.getArrayCiudades.sort();
    // .filter(
    //   (ciudad) => ciudad.region.id === this.region?.id
    // );
  }

  get nombreErrors(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.nombre.$dirty) return errors;
    if (!this.$v.nombre.required) errors.push("El nombre es requerido");
    return errors;
  }

  get rutErrors(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.rut.$dirty) return errors;
    if (!this.$v.rut.required) errors.push("El RUT es requerido");
    if (!this.rut.validateRUT()) errors.push("El RUT no es válido");
    if (this.getArrayUsuarios.some((usuario) => usuario.rut === this.rut))
      errors.push("El RUT ya está registrado");
    return errors;
  }

  get emailErrors(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.email.$dirty) return errors;
    if (!this.$v.email.required) errors.push("El correo es requerido");
    if (this.invalidEmail) errors.push("El correo no es válido");
    if (this.emailAlreadyExists) errors.push("El correo ya está registrado");
    return errors;
  }

  get passwordErrors(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.password.$dirty) return errors;
    if (!this.$v.password.required) errors.push("La contraseña es requerida");
    if (this.weakPassword) errors.push("La contraseña es débil");
    return errors;
  }

  get telefonoErrors(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.telefono.$dirty) return errors;
    if (!this.$v.telefono.required) errors.push("El teléfono es requerido");
    return errors;
  }

  get regionErrors(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.region.$dirty) return errors;
    if (!this.$v.region.required) errors.push("La región es requerida");
    return errors;
  }

  get ciudadErrors(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.ciudad.$dirty) return errors;
    if (!this.$v.ciudad.required) errors.push("La ciudad es requerida");
    return errors;
  }

  @Watch("region")
  onRegionChange(): void {
    this.ciudad = null;
  }

  @Emit("cerrar")
  onCerrar(): void {
    return;
  }

  @Emit("terminos")
  onTerminos(): void {
    return;
  }

  resetErrors(): void {
    this.invalidEmail = false;
    this.emailAlreadyExists = false;
    this.weakPassword = false;
  }

  async registrarse(): Promise<void> {
    this.$v.$touch();
    this.resetErrors();
    if (this.cargando) return;
    this.cargando = true;
    if (
      this.$v.$invalid ||
      !this.rut.validateRUT() ||
      this.getArrayUsuarios.some((usuario) => usuario.rut === this.rut)
    ) {
      this.cargando = false;
      return;
    }
    try {
      const credentials = await Firebase.auth().createUserWithEmailAndPassword(
        this.email,
        this.password
      );
      if (!credentials.user) return;
      const usuario: Usuario = {
        id: credentials.user.uid,
        uid: credentials.user.uid,
        nombre: this.nombre,
        rut: this.rut,
        email: this.email,
        telefono: this.telefono,
        ciudad: this.ciudad,
        tipo: "cliente",
        fecha: new Date().toISOString(),
        permisos: {
          misCursos: true,
          cursosDisponibles: true,
          perfil: true,
          notificaciones: true,
          pagos: true,
          agenda: true,
        },
        estado: "libre",
        region: this.region,
      };
      await this.setUsuario(usuario);
      this.onCerrar();
    } catch (error) {
      switch (error.code) {
        case "auth/invalid-email":
          this.invalidEmail = true;
          break;
        case "auth/email-already-in-use":
          this.emailAlreadyExists = true;
          break;
        case "auth/weak-password":
          this.weakPassword = true;
          break;
      }
      this.$v.$touch();
    }
    this.cargando = false;
  }
}
