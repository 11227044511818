


































































































































// decoradores
import { Vue, Component } from "vue-property-decorator";
// vuex
import { mapActions, mapGetters } from "vuex";
// tipos

// componentes
import TablaLegal from "@/components/legales/Tabla.vue";
import FormularioLegal from "@/components/legales/Formulario.vue";
import { DataFormularioLegal } from "@/typings/components/legales/formulario";
import DialogoExito from "@/components/DialogoExito.vue";
import DialogoEliminar from "@/components/DialogoEliminar.vue";
import InfoLegal from "@/components/legales/Info.vue";
//import BotonesCrud from "@/components/BotonesCrud.vue";
import MensajeEliminar from "@/components/MensajeEliminar.vue";
//import TituloVista from "@/components/TituloVista.vue";
import {
Legal,
Legales,
  //  Regiones,
} from "@/typings/store/plugins/easyFirestore/legales";
//import { DataFormularioRegion } from "@/typings/components/regiones/formulario";
import CargarBancosExcel from "@/components/excel/CargarBancos.vue";

const routeGuard = (vm: Vue): boolean => {
  const permisos = vm.getUsuario?.permisos;
  if (permisos && permisos["legales"]) {
    return true;
  }

  const rutas = vm.getRutasUsuario;
  if (rutas.length > 0) {
    const to = rutas[0].to;
    if (to) {
      if (vm.$route.name !== to.name) {
        vm.$router.replace(to);
      }
      return false;
    }
  }
  if (vm.$route.name !== "inicio") {
    vm.$router.replace({ name: "inicio" });
  }
  return false;
};

@Component({
  computed: mapGetters({
    getUsuarios: "usuarios/get",
    getLegales: "legales/get",
    getArrayUsuarios: "usuarios/getArray",
    getUsuario: "usuario/get",
    getRutas: "rutas/get",
    getRutasUsuario: "usuario/getRutas",
    getArrayLegales: "legales/getArray",
  }),
  methods: mapActions({
    setLegal: "legales/set",
    deleteLegal: "legales/delete",
    insertBatchLegales: "legales/insertBatch",
    //setArchivo: "archivos/set",
    //deleteArchivo: "archivos/delete",
  }),
  beforeRouteEnter(to, from, next) {
    next(routeGuard);
  },
  components: {
    DialogoEliminar,
    DialogoExito,
    TablaLegal,
    FormularioLegal,
    InfoLegal,
    //BotonesCrud,
    MensajeEliminar,
    CargarBancosExcel,
    //TituloVista,
  },
})
export default class VistaBancos extends Vue {
  created(): void {
    if (!routeGuard(this)) {
      return;
    }
  }

  cargando = false;
  dialogoExito = {
    model: false,
    texto: "",
    icono: "",
  };
  search = "";
  legal: Legal | null = null;
  dialogoInfo = false;
  dialogoFormulario = false;
  dialogoEliminar = false;
  textoEliminar = "¿ESTÁS SEGURO QUE DESEAS ELIMINAR ESTE BANCO?";
  dialogoCargarExcel = false;

  get legales(): Legales {
    return this.getLegales;
  }

  get items(): Array<Legal> {
    return this.getArrayLegales;
  }

  clickItem(val: { id: string } | null): void {
    this.legal = val ? this.legales[val.id] : null;
  }

  clickPlus(): void {
    this.legal = null;
    this.dialogoFormulario = true;
  }

  async clickGuardar(data: DataFormularioLegal): Promise<void> {
    this.cargando = true;
    const doc = data.doc;
    await this.setLegal(doc);
    if (doc.id != this.legal?.id) {
      this.dialogoExito = {
        model: true,
        texto: "¡Banco creado con éxito!",
        icono: require("../assets/icons/double-check-duo.svg"),
      };
    }
    if (doc.id == this.legal?.id) {
      this.dialogoExito = {
        model: true,
        texto: "¡Banco editado con éxito!",
        icono: require("../assets/icons/editar.svg"),
      };
      this.legal = doc;
    }
    this.cerrarDialogoFormulario();
    this.cargando = false;
  }

  cerrarDialogoExito(): void {
    this.dialogoExito = {
      model: false,
      texto: "",
      icono: "",
    };
  }

  clickEliminar(): void {
    const legal = this.legal;
    if (!legal?.id) {
      console.error("error id: ", legal);
      return;
    }
    this.deleteLegal(legal.id);
    this.legal = null;
    this.cerrarDialogoEliminar();
  }

  abrirDialogoInfo(legal: Legal): void {
    if (!legal.id) {
      console.error("error id: ", legal);
      return;
    }
    const doc = this.legales[legal.id];
    if (doc) {
      this.legal = doc;
      this.dialogoInfo = true;
    }
  }

  cerrarDialogoInfo(): void {
    this.dialogoInfo = false;
    this.legal = null;
  }

  abrirDialogoFormulario(legal: Legal): void {
    if (!legal.id) {
      console.error("error id: ", legal);
      return;
    }
    const doc = this.legales[legal.id];
    if (doc) {
      this.legal = doc;
      this.dialogoFormulario = true;
    }
  }

  abrirDialogoEliminar(legal: Legal): void {
    if (!legal.id) {
      console.error("error id: ", legal);
      return;
    }
    const doc = this.legales[legal.id];
    if (doc) {
      this.dialogoEliminar = true;
      this.legal = doc;
    }
  }

  cerrarDialogoEliminar(): void {
    this.dialogoEliminar = false;
    this.legal = null;
  }

  cerrarDialogoFormulario(): void {
    this.dialogoFormulario = false;
    this.legal = null;
  }
}
