import { RouteConfig } from "vue-router";
import Legales from "@/views/Legales.vue";

const legales: RouteConfig = {
    path: "/legales",
    name: "legales",
    component: Legales,
};

export default legales;
