
















































































































































































// decoradores
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
// tipos
import { HeadersDataTable } from "@/typings/vuetify/vDataTable";
import { Factura } from "@/typings/store/plugins/easyFirestore/facturas";
import { ItemsTablaFacturas } from "@/typings/components/facturas/tabla";
import "@/helpers/extensions";

@Component
export default class TablaFacturas extends Vue {
  @Prop() readonly opciones!: boolean;
  @Prop() readonly search!: string;
  @Prop() readonly facturas!: Array<Factura>;

  options = {
    itemsPerPage: -1,
  };
  headerProps = {
    sortIcon: "mdi-chevron-down",
  };

  get headers(): HeadersDataTable {
    return [
      //{text: "", value: "estado", sortable: false},
      { text: "Nombre", value: "nombre" },
      { text: "Posicion", value: "posicion" },
      { text: "", value: "opciones", sortable: false },
    ];
  }

  get items(): ItemsTablaFacturas {
    return this.facturas.map((factura) => {
      const nombre = factura.nombre;
      const posicion = factura.posicion;

      return {
        nombre,
        posicion,

        data: factura,
      };
    });
  }

  //   actualizarEstado(item: Usuario): void {
  //     let estado: EstadoUsuario;
  //     item.estado === 'libre' ? estado = 'bloqueado' : estado = 'libre';
  //     item.estado = estado;
  //     this.onCambiarEstado(item);
  //   }

  //   @Emit("cambiar-estado")
  //   onCambiarEstado(usuario: Usuario): Usuario {
  //     return usuario;
  //   }

  @Emit("abrir-info")
  onAbrirInfo(factura: Factura): Factura {
    return factura;
  }

  @Emit("abrir-editar")
  onAbrirEditar(factura: Factura): Factura {
    return factura;
  }

  @Emit("abrir-eliminar")
  onAbrirEliminar(factura: Factura): Factura {
    return factura;
  }
}
