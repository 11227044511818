

























































































































































































































































































// decoradores
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { RegistrosEgresos } from "@/typings/store/plugins/easyFirestore/registrosEgresos";
import { Banco } from "@/typings/store/plugins/easyFirestore/bancos";
import {CodCuenta} from "@/typings/store/plugins/easyFirestore/codCuentas"
import SVGIcon from "@/components/SVGIcon.vue";

// tipos
@Component({
  components: {
    SVGIcon,
  },
})
export default class InfoRegistrosEgresos extends Vue {
  @Prop() readonly registrosEgresos!: RegistrosEgresos;
  // @Prop() readonly registrosEgresos!: RegistrosEgresos;
  @Prop() readonly banco!: Banco;
  @Prop() readonly codCuenta!: CodCuenta;
  @Prop({ type: Boolean }) readonly showTitulo!: boolean;

  created(): void {
    console.log(this.registrosEgresos.BANCO);
  }

  get id(): string {
    return this.registrosEgresos.id || "N/R";
  }

    get periodo(): string {
    return this.registrosEgresos.PERIODO  || "N/R";
  }

  get centroCosto(): string {
    return this.registrosEgresos.CENTRO_DE_COSTO  || "N/R";
  }

  get descripcionCuenta(): string {
    return this.registrosEgresos.DESCRIPCION_CUENTA || "N/R";
  }
  get codigoCuenta(): string {
    return this.registrosEgresos.CODIGO_CUENTA || "N/R";
  }

  get muestraBanco(): string {
    return this.registrosEgresos.BANCO || "N/R";
  }

  get ordenCompra(): string {
    return this.registrosEgresos.ORDEN_DE_COMPRA || "N/R";
  }

  get fechaEgreso(): string {
    return this.registrosEgresos.FECHA_EGRESO || "N/R";
  }

  get tipoEgreso(): string {
    return this.registrosEgresos.TIPO_EGRESO || "N/R";
  }

  get montoEgreso(): string {
    return (((parseInt(this.registrosEgresos.MONTO_EGRESO,10)).toCurrency({symbol: '$'})) ?? "").toString() || "N/R";
    //return this.registrosEgresos.MONTO_EGRESO || "N/R";
  }
  get glosa(): string {
    return this.registrosEgresos.GLOSA || "N/R";
  }
  get rutProveedor(): string {
    return this.registrosEgresos.RUT_PROVEEDOR || "N/R";
  }
  get nombreProveedor(): string {
    return this.registrosEgresos.NOMBRE_PROVEEDOR || "N/R";
  }
  get pagoCP(): string {
    return this.registrosEgresos.PAGO_C_P || "N/R";
  }
  get porcTotal(): string {
    return this.registrosEgresos.PORC_DEL_TOTAL || "N/R";
  }
  get nominaPago(): string {
    return this.registrosEgresos.NOMINA_PAGO || "N/R";
  }
  get tipoDocumento(): string {
    return this.registrosEgresos.TIPO_DE_DOCUMENTO;
  }
  get nDocumento(): string {
    return this.registrosEgresos.N_DOCUMENTO || "N/R";
  }
  get FacDocNota(): string {
    return this.registrosEgresos.FAC_DOC_NOTA || "N/R";
  }
  get ctaEstadoResultado(): string {
    return this.registrosEgresos.CTA_ESTADO_RESULTADO || "N/R";
  }
  get ctaControlGasto(): string {
    return this.registrosEgresos.CTA_CONTROL_GASTO || "N/R";
  }
  get tipoGasto(): string {
    return this.registrosEgresos.TIPO_GASTO || "N/R";
  }

  get nNomina(): string {
    return this.registrosEgresos.N_NOMINA || "N/R";
  }


  @Emit("close")
  onClose(): null {
    return null;
  }
}
