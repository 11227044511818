





























































// decoradores
import { Vue, Component, Prop, Emit } from "vue-property-decorator";
// validaciones
import { required } from "vuelidate/lib/validators";
// tipos
import {
  MapeoGasto,
  MapeoGastos,
} from "@/typings/store/plugins/easyFirestore/mapeoGastos";
// api excel
import Excel from "exceljs";

interface Registro {
  centroCosto: string;
  rut: string;
  nombreProveedor: string;
  tipoGasto: string;
  cuentaGasto: string;
  cuentaEstadoResultado: string;
  nCliente: string;
  fechaAsignacion: string;
  observacion: string;
  recurrente: string;
  descripcionCuenta: string;
  codigoCuenta: string;
  tipoDocumento: string;

}

interface NombresMapeoGastos {
  [nombre: string]: MapeoGasto;
}

// const date = (value: Date) => {
//   return value.toString() != "Invalid Date";
// };

@Component({
  validations: {
    archivo: { required },
  },
})
export default class CargarExcel extends Vue {
  @Prop() readonly mapeoGastos!: MapeoGastos;

  archivo: Blob | null = null;
  cargando = false;
  clickCargar = false;

  despachos: Array<MapeoGasto> = [];
  mensajes: Array<string> = [];

  get nombresMapeoGastos(): NombresMapeoGastos {
    const resultado: NombresMapeoGastos = {};
    Object.values(this.mapeoGastos).forEach((item) => {
      resultado[item.nombre] = Object.assign({}, item);
    });
    return resultado;
  }

  // get rfidPatentes(): RfidPatentes {
  //   const resultado: RfidPatentes = {};
  //   Object.values(this.rfids).forEach((item) => {
  //     resultado[item.patente] = Object.assign({}, item);
  //   });
  //   return resultado;
  // }

  get archivoError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.archivo.$dirty) return errors;
    if (!this.$v.archivo.required) errors.push("Requerido");
    return errors;
  }

  @Emit("carga-excel")
  onCargaExcel(): Array<MapeoGasto> {
    return this.despachos;
  }

  // valoresValidos(valores: Array<Excel.CellValue>): boolean {
  //   if (!valores[1] || !valores[2] ) {
  //     return false;
  //   }
  //   const nombre = valores[1] ;
  //   const posicion = valores[2];
  //   //return !!entrada && !!salida && date(entrada) && date(salida);
  //   return nombre && posicion
  // }

  async cargar(): Promise<void> {
    this.$v.$touch();
    if (this.$v.$invalid || !this.archivo) {
      return;
    }
    this.cargando = true;
    this.clickCargar = true;
    const workbook = new Excel.Workbook();
    const data = await this.archivo.arrayBuffer();
    await workbook.xlsx.load(data);
    // worksheet
    const worksheet = workbook.getWorksheet(1);
    console.log("registros", worksheet.actualRowCount - 1);
    if (worksheet.actualRowCount - 1 > 500) {
      console.log("registros mayores a 500");
      this.mensajes.push("error: cantidad de registros mayor a 500");
      this.cargando = false;
      return;
    }
    worksheet.eachRow((row, rowNumber) => {
      if (rowNumber != 1) {
        const valores = row.values as Array<Excel.CellValue>;
        if (valores[3] == "ejemplo") {
          console.log("error row", rowNumber);
          this.mensajes.push(
            "error: row " +
              rowNumber +
              " invalida, contiene campos vacios, fechas invalidas o la etiqueta prueba"
          );
          return;
        }
        const registro: Registro = {
          recurrente: valores[1] as string,
          nombreProveedor: valores[2] as string,
          rut: valores[3] as string,

          centroCosto: valores[4] as string,
          descripcionCuenta: valores[5] as string,
          codigoCuenta: valores[6] as string,
          observacion: valores[7] as string,
          cuentaEstadoResultado: valores[8] as string,
          cuentaGasto: valores[9] as string,
          tipoGasto: valores[10] as string,
          tipoDocumento: valores[11] as string,
          
          nCliente: valores[12] as string,
          fechaAsignacion: valores[13] as string,

         
        };
        // const banco = this.nombresMapeoGastos[registro.nombre];
        //  if (!banco) {
        //    console.log("error row", rowNumber);
        //    this.mensajes.push(
        //      "error: row " + rowNumber + " invalida, patente no registrada"
        //    );
        //    return;
        //  }
        const ingreso: MapeoGasto = {
          centroCosto: registro.centroCosto??"",
          rut: registro.rut??"",
          nombreProveedor: registro.nombreProveedor??"",
          tipoGasto: registro.tipoGasto??"",
          cuentaGasto: registro.cuentaGasto??"",
          cuentaEstadoResultado: registro.cuentaEstadoResultado??"",
          nCliente: registro.nCliente??"",
          fechaAsignacion: registro.fechaAsignacion??"",
          observacion: registro.observacion??"",
          recurrente: registro.recurrente??"",
          descripcionCuenta: registro.descripcionCuenta??"",
          codigoCuenta: registro.codigoCuenta??"",
          tipoDocumento: registro.tipoDocumento??"",
        
        };
        this.despachos.push(ingreso);
      }
    });
    if (this.mensajes.length > 0) {
      this.mensajes.push("cargado con errores");
      this.mensajes.push("corregir excel y volver a intentar");
      this.cargando = false;
    } else {
      this.mensajes.push("cargado completo");
      this.mensajes.push(this.despachos.length + " mapeoGastos cargados");
      this.onCargaExcel();
      this.cargando = false;
    }
  }
}
