




































































































































































































































































































































// decoradores
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
// tipos
import { HeadersDataTable } from "@/typings/vuetify/vDataTable";
import { Consumo } from "@/typings/store/plugins/easyFirestore/consumos";
import { ItemsTablaConsumos } from "@/typings/components/consumos/tabla";
import "@/helpers/extensions";

@Component
export default class TablaUsuarios extends Vue {
  @Prop() readonly opciones!: boolean;
  @Prop() readonly search!: string;
  @Prop() readonly consumos!: Array<Consumo>;

  options = {
    itemsPerPage: -1,
  };
  headerProps = {
    sortIcon: "mdi-chevron-down",
  };

  get headers(): HeadersDataTable {
    return [
      //{text: "", value: "estado", sortable: false},
      //{ text: "Periodo", value: "periodo" },
      { text: "Locales", value: "unidades" },
      { text: "Centro de Costo", value: "centroCosto" },
      { text: "Empresa", value: "empresa" },
      { text: "Rut", value: "rut" },
      { text: "Estado", value: "estado" },
      { text: "", value: "boton", sortable: false },
      { text: "", value: "opciones", sortable: false },
    ];
  }

  get items(): ItemsTablaConsumos {
    return this.consumos.map((consumo) => {
      //const periodo = consumo.periodo;
      const unidades = consumo.unidades;
      const centroCosto = consumo.centroCosto;
      const unidadLocativa = consumo.unidadLocativa;
      const empresa = consumo.empresa;
      const rut = consumo.rut;
      const estado = consumo.estado;
      // const tipoServicio = consumo.tipoServicio;
      // const nroContrato = consumo.nroContrato;
      // const nroLectura = consumo.nroLectura;
      // const nroCliente = consumo.nroCliente;
      // const nroMedidor = consumo.nroMedidor;
      // const imagen1 = consumo.imagen1;
      // const imagen2 = consumo.imagen2;
      // const imagen3 = consumo.imagen3;
      //const monto = consumo.monto;
      //formato: moneda (pesos chilenos)
      // const monto = consumo.monto
      // (
      //     parseInt((consumo.monto.toString()), 10).toCurrency({ symbol: "$" }) ?? ""
      //   ).toString();

      return {
        //periodo,
        unidades,
        centroCosto,
        unidadLocativa,
        empresa,
        rut,
        estado,

        // tipoServicio,
        // nroContrato,
        // nroLectura,
        // nroCliente,
        // nroMedidor,
        // imagen1,
        // imagen2,
        // imagen3,
        // monto,

        data: consumo,
      };
    });
  }

  //   actualizarEstado(item: Usuario): void {
  //     let estado: EstadoUsuario;
  //     item.estado === 'libre' ? estado = 'bloqueado' : estado = 'libre';
  //     item.estado = estado;
  //     this.onCambiarEstado(item);
  //   }

  //   @Emit("cambiar-estado")
  //   onCambiarEstado(usuario: Usuario): Usuario {
  //     return usuario;
  //   }

  @Emit("abrir-medidor")
  onAbrirMedidor(consumo: Consumo): Consumo {
    return consumo;
  }

  @Emit("abrir-info")
  onAbrirInfo(consumo: Consumo): Consumo {
    return consumo;
  }

  @Emit("abrir-editar")
  onAbrirEditar(consumo: Consumo): Consumo {
    return consumo;
  }

  @Emit("abrir-eliminar")
  onAbrirEliminar(consumo: Consumo): Consumo {
    return consumo;
  }
}
