



















































































































import {Vue, Component, Watch} from "vue-property-decorator";
import {required} from "vuelidate/lib/validators";
import {Ciudad} from "@/typings/store/plugins/easyFirestore/ciudades";
import {Region} from "@/typings/store/plugins/easyFirestore/regiones";
import {mapActions, mapGetters} from "vuex";
import DialogoExito from "@/components/DialogoExito.vue";

@Component({
  components: {DialogoExito},
  methods: mapActions({
    setUsuario: "usuarios/set"
  }),
  computed: mapGetters({
    getUsuario: "usuario/get",
    getArrayUsuarios: "usuarios/getArray",
    getArrayCiudades: "ciudades/getArray",
    getArrayRegiones: "regiones/getArray",
  }),
  validations: {
    rut: {required},
    nombre: {required},
    telefono: {required},
  },
})
export default class FormularioInicioSesion extends Vue {
  created(): void {
    this.nombre = this.getUsuario?.nombre ?? "";
    this.rut = this.getUsuario?.rut ?? "";
    this.telefono = this.getUsuario?.telefono ?? "";
    this.ciudad = this.getUsuario?.ciudad ?? null;
    this.region = this.getUsuario?.ciudad?.region ?? null;
  }

  rut = "";
  nombre = "";
  telefono = "";
  ciudad: Ciudad | null = null;
  region: Region | null = null;
  dialogoExito = {model: false, icono: "", texto: ""};

  get arrayRegiones(): Region[] {
    return this.getArrayRegiones;
  }

  get arrayCiudades(): Ciudad[] {
    return this.getArrayCiudades.filter(ciudad => ciudad.region.id === this.region?.id);
  }

  // get rutExiste(): boolean {
  //   return this.getArrayUsuarios.some(usuario => usuario.rut === this.rut);
  // }

  get rutError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.rut.$dirty) return errors;
    if (!this.$v.rut.required) errors.push("El RUT es requerido");
    if (!this.rut.validateRUT()) errors.push("El RUT no es válido");
    // if (this.rutExiste) errors.push("El RUT ya existe");
    return errors;
  }

  get nombreError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.nombre.$dirty) return errors;
    if (!this.$v.nombre.required) errors.push("El nombre es requerido");
    return errors;
  }

  get telefonoError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.telefono.$dirty) return errors;
    if (!this.$v.telefono.required) errors.push("El teléfono es requerido");
    return errors;
  }

  get ciudadError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.ciudad.$dirty) return errors;
    if (!this.$v.ciudad.required) errors.push("La ciudad es requerida");
    return errors;
  }

  get regionError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.region.$dirty) return errors;
    if (!this.$v.region.required) errors.push("La región es requerida");
    return errors;
  }

  @Watch("region")
  onRegionChange(): void {
    this.ciudad = null;
  }

  guardar(): void {
    this.$v.$touch();
    if (this.$v.$invalid || !this.rut.validateRUT()) return;
    const usuario = this.getUsuario;
    if (!usuario) {
      this.dialogoExito = {
        model: true,
        icono: require('../../../assets/icons/cancelar-duo.svg'),
        texto: "¡Usuario invalido!"
      };
      return;
    }
    usuario.rut = this.rut.toLowerCase();
    usuario.nombre = this.nombre;
    usuario.telefono = this.telefono;
    usuario.ciudad = this.ciudad;
    this.setUsuario(usuario);
    this.dialogoExito = {
      model: true,
      icono: require('../../../assets/icons/double-check-duo.svg'),
      texto: "¡Información guardada con éxito!"
    };
    this.$emit('closeDialog');
  }

  cerrarDialogoExito(): void {
    this.dialogoExito = {
      model: false,
      texto: "",
      icono: ""
    };
  }
}
