









































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { Solicitud } from "@/typings/store/plugins/easyFirestore/solicitudes";
import { required } from "vuelidate/lib/validators";
import { DataFormularioSolicitud } from "@/typings/components/solicitudes/formulario";
import SVGIcon from "@/components/SVGIcon.vue";
import { Archivo } from "@/typings/store/modules/archivos";
import { MapeoGasto } from "@/typings/store/plugins/easyFirestore/MapeoGastos";
import { Ciudad } from "@/typings/store/plugins/easyFirestore/ciudades";
import { Usuario } from "@/typings/store/plugins/easyFirestore/usuarios";

@Component({
  components: {
    SVGIcon,
  },
  validations: {
    tipoGasto: { required },
    codigoCuenta: { required },
    descripcionCuenta: { required },
  },
})
export default class FormularioSolicitud extends Vue {
  @Prop() readonly solicitud!: Solicitud | null;
  @Prop() readonly cargando!: boolean;
  @Prop() readonly mapeoGastos!: MapeoGasto[];
  @Prop() readonly ciudades!: Ciudad[];
  @Prop() readonly usuario!: Usuario;

  created(): void {
    // Object.keys(this.rutas).forEach((key) => {
    //   this.$set(this.permisos, key, false);
    // });
    // console.log(this.permisos);
    const solicitud = this.solicitud;
    if (!solicitud) {
      return;
    }
    this.id = solicitud.id ?? "";
    this.nombre = solicitud.nombre;
    // this.posicion = solicitud.posicion ?? "";
    //
    this.ordenCompra = solicitud.ordenCompra ?? 0;
    this.fechaEmision = solicitud.fechaEmision ?? "";
    this.estado = solicitud.estado ?? "";
    this.autorizado = solicitud.autorizado ?? "";
    // Datos de carga autmatica
    this.subTotal = solicitud.subTotal ?? 0;
    this.afectoIVA = solicitud.afectoIVA ?? 0;
    this.exentoIVA = solicitud.exentoIVA ?? 0;
    this.subTotalNeto = solicitud.subTotalNeto ?? 0;
    this.IVA = solicitud.IVA ?? 0;
    this.total = solicitud.total ?? 0;
    // cambiar
    this.tipoOrden = solicitud.tipoOrden ?? "";
    this.cantidad_1 = solicitud.cantidad_1 ?? 0;
    this.cantidad_2 = solicitud.cantidad_2 ?? 0;
    this.cantidad_3 = solicitud.cantidad_3 ?? 0;
    this.cantidad_4 = solicitud.cantidad_4 ?? 0;
    this.cantidad_5 = solicitud.cantidad_5 ?? 0;
    this.cantidad_6 = solicitud.cantidad_6 ?? 0;
    this.cantidad_7 = solicitud.cantidad_7 ?? 0;
    this.cantidad_8 = solicitud.cantidad_8 ?? 0;
    this.cantidad_9 = solicitud.cantidad_9 ?? 0;
    this.cantidad_10 = solicitud.cantidad_10 ?? 0;
    this.cantidad_11 = solicitud.cantidad_11 ?? 0;
    this.cantidad_12 = solicitud.cantidad_12 ?? 0;
    this.cantidad_13 = solicitud.cantidad_13 ?? 0;
    this.cantidad_14 = solicitud.cantidad_14 ?? 0;
    this.cantidad_15 = solicitud.cantidad_15 ?? 0;
    this.cantidad_16 = solicitud.cantidad_16 ?? 0;

    this.unidadMedida_1 = solicitud.unidadMedida_1 ?? "";
    this.unidadMedida_2 = solicitud.unidadMedida_2 ?? "";
    this.unidadMedida_3 = solicitud.unidadMedida_3 ?? "";
    this.unidadMedida_4 = solicitud.unidadMedida_4 ?? "";
    this.unidadMedida_5 = solicitud.unidadMedida_5 ?? "";
    this.unidadMedida_6 = solicitud.unidadMedida_6 ?? "";
    this.unidadMedida_7 = solicitud.unidadMedida_7 ?? "";
    this.unidadMedida_8 = solicitud.unidadMedida_8 ?? "";
    this.unidadMedida_9 = solicitud.unidadMedida_9 ?? "";
    this.unidadMedida_10 = solicitud.unidadMedida_10 ?? "";
    this.unidadMedida_11 = solicitud.unidadMedida_11 ?? "";
    this.unidadMedida_12 = solicitud.unidadMedida_12 ?? "";
    this.unidadMedida_13 = solicitud.unidadMedida_13 ?? "";
    this.unidadMedida_14 = solicitud.unidadMedida_14 ?? "";
    this.unidadMedida_15 = solicitud.unidadMedida_15 ?? "";
    this.unidadMedida_16 = solicitud.unidadMedida_16 ?? "";

    this.descripcion_1 = solicitud.descripcion_1 ?? "";
    this.descripcion_2 = solicitud.descripcion_2 ?? "";
    this.descripcion_3 = solicitud.descripcion_3 ?? "";
    this.descripcion_4 = solicitud.descripcion_4 ?? "";
    this.descripcion_5 = solicitud.descripcion_5 ?? "";
    this.descripcion_6 = solicitud.descripcion_6 ?? "";
    this.descripcion_7 = solicitud.descripcion_7 ?? "";
    this.descripcion_8 = solicitud.descripcion_8 ?? "";
    this.descripcion_9 = solicitud.descripcion_9 ?? "";
    this.descripcion_10 = solicitud.descripcion_10 ?? "";
    this.descripcion_11 = solicitud.descripcion_11 ?? "";
    this.descripcion_12 = solicitud.descripcion_12 ?? "";
    this.descripcion_13 = solicitud.descripcion_13 ?? "";
    this.descripcion_14 = solicitud.descripcion_14 ?? "";
    this.descripcion_15 = solicitud.descripcion_15 ?? "";
    this.descripcion_16 = solicitud.descripcion_16 ?? "";

    this.centroCosto_1 = solicitud.centroCosto_1 ?? "";
    this.centroCosto_2 = solicitud.centroCosto_2 ?? "";
    this.centroCosto_3 = solicitud.centroCosto_3 ?? "";
    this.centroCosto_4 = solicitud.centroCosto_4 ?? "";
    this.centroCosto_5 = solicitud.centroCosto_5 ?? "";
    this.centroCosto_6 = solicitud.centroCosto_6 ?? "";
    this.centroCosto_7 = solicitud.centroCosto_7 ?? "";
    this.centroCosto_8 = solicitud.centroCosto_8 ?? "";
    this.centroCosto_9 = solicitud.centroCosto_9 ?? "";
    this.centroCosto_10 = solicitud.centroCosto_10 ?? "";
    this.centroCosto_11 = solicitud.centroCosto_11 ?? "";
    this.centroCosto_12 = solicitud.centroCosto_12 ?? "";
    this.centroCosto_13 = solicitud.centroCosto_13 ?? "";
    this.centroCosto_14 = solicitud.centroCosto_14 ?? "";
    this.centroCosto_15 = solicitud.centroCosto_15 ?? "";
    this.centroCosto_16 = solicitud.centroCosto_16 ?? "";

    this.precioUnitario_1 = solicitud.precioUnitario_1 ?? 0;
    this.precioUnitario_2 = solicitud.precioUnitario_2 ?? 0;
    this.precioUnitario_3 = solicitud.precioUnitario_3 ?? 0;
    this.precioUnitario_4 = solicitud.precioUnitario_4 ?? 0;
    this.precioUnitario_5 = solicitud.precioUnitario_5 ?? 0;
    this.precioUnitario_6 = solicitud.precioUnitario_6 ?? 0;
    this.precioUnitario_7 = solicitud.precioUnitario_7 ?? 0;
    this.precioUnitario_8 = solicitud.precioUnitario_8 ?? 0;
    this.precioUnitario_9 = solicitud.precioUnitario_9 ?? 0;
    this.precioUnitario_10 = solicitud.precioUnitario_10 ?? 0;
    this.precioUnitario_11 = solicitud.precioUnitario_11 ?? 0;
    this.precioUnitario_12 = solicitud.precioUnitario_12 ?? 0;
    this.precioUnitario_13 = solicitud.precioUnitario_13 ?? 0;
    this.precioUnitario_14 = solicitud.precioUnitario_14 ?? 0;
    this.precioUnitario_15 = solicitud.precioUnitario_15 ?? 0;
    this.precioUnitario_16 = solicitud.precioUnitario_16 ?? 0;

    this.precioTotal_1 = solicitud.precioTotal_1 ?? 0;
    this.precioTotal_2 = solicitud.precioTotal_2 ?? 0;
    this.precioTotal_3 = solicitud.precioTotal_3 ?? 0;
    this.precioTotal_4 = solicitud.precioTotal_4 ?? 0;
    this.precioTotal_5 = solicitud.precioTotal_5 ?? 0;
    this.precioTotal_6 = solicitud.precioTotal_6 ?? 0;
    this.precioTotal_7 = solicitud.precioTotal_7 ?? 0;
    this.precioTotal_8 = solicitud.precioTotal_8 ?? 0;
    this.precioTotal_9 = solicitud.precioTotal_9 ?? 0;
    this.precioTotal_10 = solicitud.precioTotal_10 ?? 0;
    this.precioTotal_11 = solicitud.precioTotal_11 ?? 0;
    this.precioTotal_12 = solicitud.precioTotal_12 ?? 0;
    this.precioTotal_13 = solicitud.precioTotal_13 ?? 0;
    this.precioTotal_14 = solicitud.precioTotal_14 ?? 0;
    this.precioTotal_15 = solicitud.precioTotal_15 ?? 0;
    this.precioTotal_16 = solicitud.precioTotal_16 ?? 0;
    // Datos de carga manual
    this.centroCosto = solicitud.centroCosto ?? "";
    this.divisa = solicitud.divisa ?? "";

    this.empresa = solicitud.empresa ?? "";
    this.rut = solicitud.rut ?? "";
    this.direccion = solicitud.direccion ?? "";
    this.comuna = solicitud.comuna ?? "";
    this.telefono = solicitud.telefono ?? "";
    this.contacto = solicitud.contacto ?? "";
    this.formaPago = solicitud.formaPago ?? "";
    this.correo = solicitud.correo ?? "";
    // servicios = solicitud. ?? "";
    this.observaciones = solicitud.observaciones ?? "";
    this.descuento = solicitud.descuento ?? 0;
    this.tipoDescuento = solicitud.tipoDescuento ?? "";
    this.destinatario = solicitud.destinatario ?? "";
    this.rutDestinatario = solicitud.rutDestinatario ?? "";
    this.banco = solicitud.banco ?? "";
    this.tipoCueta = solicitud.tipoCueta ?? "";
    this.nroCuenta = solicitud.nroCuenta ?? "";
    this.correoDestinatario = solicitud.correoDestinatario ?? "";
    // this.archivo1 = solicitud.archivo1 ?? "";
    // this.archivo2 = solicitud.archivo2 ?? "";
    // this.archivo3 = solicitud.archivo3 ?? "";
    // this.archivo4 = solicitud.archivo4 ?? "";
    this.fechaUf = solicitud.fechaUf ?? "";
    this.solicitante = solicitud.solicitante ?? "";
    this.motivoAnulacion = solicitud.motivoAnulacion ?? "";

    this.archivo = null;
    this.archivo2 = null;
    this.archivo3 = null;
    this.archivo4 = null;
    this.archivo5 = null;
    this.archivo6 = null;

    this.tipoArchivo = solicitud.tipoArchivo ?? "";
    this.tipoArchivo2 = solicitud.tipoArchivo2 ?? "";
    this.tipoArchivo3 = solicitud.tipoArchivo3 ?? "";
    this.tipoArchivo4 = solicitud.tipoArchivo4 ?? "";
    this.tipoArchivo5 = solicitud.tipoArchivo5 ?? "";
    this.tipoArchivo6 = solicitud.tipoArchivo6 ?? "";

    this.subidoTipoArchivo = solicitud.subidoTipoArchivo ?? "";
    this.subidoTipoArchivo2 = solicitud.subidoTipoArchivo2 ?? "";

    //this.color = solicitud.color ?? "";
    this.observacion = solicitud.observacion ?? "";
    this.tipoGasto = solicitud.tipoGasto ?? "";

    this.nFactura = solicitud.nFactura ?? "";
    this.periodo = solicitud.periodo ?? "";
    this.recurrente = solicitud.recurrente ?? "";
    this.tipoRecurrencia = solicitud.tipoRecurrencia ?? "";
    this.periodoRecurrencia = solicitud.periodoRecurrencia ?? "";
    this.montoSaldo = solicitud.montoSaldo ?? "";
    this.montoAP = solicitud.montoAP ?? "";
    this.numeroCheque = solicitud.numeroCheque ?? "";

    this.codigoCuenta = solicitud.codigoCuenta ?? "";
    this.descripcionCuenta = solicitud.descripcionCuenta ?? "";

    this.tipoGastoCorrecto = solicitud.tipoGastoCorrecto ?? "";
    this.cuentaSoftlandCorrecto = solicitud.cuentaSoftlandCorrecto ?? "";
    this.observante = solicitud.observante ?? "";
    this.respuesta = solicitud.respuesta ?? "";
    this.respondedor = solicitud.respondedor ?? "";
    this.fechaVencimiento =
      solicitud.fechaVencimiento.toDate().format("YYYY-MM-DD") ?? "";

    this.valorDivisa = solicitud.valorDivisa;

    this.afectoExento = solicitud.afectoExento;

    this.divisa = solicitud.divisa; // nuevo
  }

  id: string | null = null;

  nombre = "";
  posicion = "";
  tipoOrden = "";
  //
  // Datos de carga autmatica
  ordenCompra = 0;
  fechaEmision = "";
  estado = "";
  autorizado = "";
  // Datos de carga autmatica
  subTotal = 0;
  afectoIVA = 0;
  exentoIVA = 0;
  subTotalNeto = 0;
  IVA = 0;
  total = 0;
  // cambiar
  //servicios = "";
  // Datos de carga manual
  centroCosto = "";
  divisa = "";
  empresa = "";
  rut = "";
  direccion = "";
  comuna = "";
  telefono = "";
  contacto = "";
  formaPago = "";
  correo = "";
  // servicios = "";
  cantidad_1 = 0;
  cantidad_2 = 0;
  cantidad_3 = 0;
  cantidad_4 = 0;
  cantidad_5 = 0;
  cantidad_6 = 0;
  cantidad_7 = 0;
  cantidad_8 = 0;
  cantidad_9 = 0;
  cantidad_10 = 0;
  cantidad_11 = 0;
  cantidad_12 = 0;
  cantidad_13 = 0;
  cantidad_14 = 0;
  cantidad_15 = 0;
  cantidad_16 = 0;
  unidadMedida_1 = "";
  unidadMedida_2 = "";
  unidadMedida_3 = "";
  unidadMedida_4 = "";
  unidadMedida_5 = "";
  unidadMedida_6 = "";
  unidadMedida_7 = "";
  unidadMedida_8 = "";
  unidadMedida_9 = "";
  unidadMedida_10 = "";
  unidadMedida_11 = "";
  unidadMedida_12 = "";
  unidadMedida_13 = "";
  unidadMedida_14 = "";
  unidadMedida_15 = "";
  unidadMedida_16 = "";
  descripcion_1 = "";
  descripcion_2 = "";
  descripcion_3 = "";
  descripcion_4 = "";
  descripcion_5 = "";
  descripcion_6 = "";
  descripcion_7 = "";
  descripcion_8 = "";
  descripcion_9 = "";
  descripcion_10 = "";
  descripcion_11 = "";
  descripcion_12 = "";
  descripcion_13 = "";
  descripcion_14 = "";
  descripcion_15 = "";
  descripcion_16 = "";
  centroCosto_1 = "";
  centroCosto_2 = "";
  centroCosto_3 = "";
  centroCosto_4 = "";
  centroCosto_5 = "";
  centroCosto_6 = "";
  centroCosto_7 = "";
  centroCosto_8 = "";
  centroCosto_9 = "";
  centroCosto_10 = "";
  centroCosto_11 = "";
  centroCosto_12 = "";
  centroCosto_13 = "";
  centroCosto_14 = "";
  centroCosto_15 = "";
  centroCosto_16 = "";
  precioUnitario_1 = 0;
  precioUnitario_2 = 0;
  precioUnitario_3 = 0;
  precioUnitario_4 = 0;
  precioUnitario_5 = 0;
  precioUnitario_6 = 0;
  precioUnitario_7 = 0;
  precioUnitario_8 = 0;
  precioUnitario_9 = 0;
  precioUnitario_10 = 0;
  precioUnitario_11 = 0;
  precioUnitario_12 = 0;
  precioUnitario_13 = 0;
  precioUnitario_14 = 0;
  precioUnitario_15 = 0;
  precioUnitario_16 = 0;
  precioTotal_1 = 0;
  precioTotal_2 = 0;
  precioTotal_3 = 0;
  precioTotal_4 = 0;
  precioTotal_5 = 0;
  precioTotal_6 = 0;
  precioTotal_7 = 0;
  precioTotal_8 = 0;
  precioTotal_9 = 0;
  precioTotal_10 = 0;
  precioTotal_11 = 0;
  precioTotal_12 = 0;
  precioTotal_13 = 0;
  precioTotal_14 = 0;
  precioTotal_15 = 0;
  precioTotal_16 = 0;

  observaciones = "";

  descuento = 0;
  tipoDescuento = "";
  destinatario = "";
  rutDestinatario = "";
  banco = "";
  tipoCueta = "";
  nroCuenta = "";
  correoDestinatario = "";
  // archivo1 = "";
  // archivo2 = "";
  // archivo3 = "";
  // archivo4 = "";
  fechaUf = "";
  solicitante = "";
  motivoAnulacion = "";
  bancos = [
    "BANCO CHILE / EDWARDS",
    "BANCO INTERNACIONAL",
    "BANCO ESTADO",
    "BANCO SCOTIABANK",
    "BANCO BCI",
    "BANCO DO BRASIL S.A",
    "CORPBANCA",
    "BANCO BICE",
    "BANCO HSBC BANK",
    "BANCO SANTANDER",
    "BANCO ITAU",
    "BANCO SECURITY",
    "BANCO FALABELLA",
    "BANCO RIPLEY",
    "RABOBANK",
    "BANCO CONSORCIO",
    "BANCO PARIS",
    "BBVA",
    "BANCO DEL DESARROLLO",
    "COOPEUCH",
    "CAJA LOS HEROES",
    "TENPO PREPAGO",
    "TAPP CAJA LOS ANDES",
    "MERCADO PAGO",
  ];
  tiposCuenta = ["CUENTA CORRIENTE", "CUENTA DE AHORRO", "CUENTA VISTA / RUT"];
  tiposFormaPago = [
    // "EFECTIVO",
    "TRANSFERENCIA",
    "CHEQUE",
    "CRÉDITO A 30 DÍAS",
    // "TARJETA DE CREDITO",
    // "TARJETA DE DEBITO",
    // "DEPOSITO",
  ];
  itemsDivisa = ["UF", "CLP", "USD", "EUR"];
  itemsUnidadesMedida = [
    "UNIDAD",
    "METRO",
    "METRO CUADRADO",
    "METRO CUBICO",
    "KILOGRAMO",
    "LITRO",
    "DOCENA",
  ];

  itemsTipoOrden = ["EXENTA", "AFECTA"];

  archivo?: Archivo | null = null;
  archivo2?: Archivo | null = null;
  archivo3?: Archivo | null = null;
  archivo4?: Archivo | null = null;
  archivo5?: Archivo | null = null;
  archivo6?: Archivo | null = null;

  tipoArchivo = "";
  tipoArchivo2 = "";
  tipoArchivo3 = "";
  tipoArchivo4 = "";
  tipoArchivo5 = "";
  tipoArchivo6 = "";
  subidoTipoArchivo = "";

  subidoTipoArchivo2 = "";

  color: "#D5D8E3" | "success" | "red" = "#D5D8E3";

  observacion = "";
  tipoGasto = "";

  nFactura = "";
  periodo = "";
  recurrente = "";
  tipoRecurrencia = "";
  periodoRecurrencia = "";
  montoSaldo = "";
  montoAP = "";
  numeroCheque = "";

  codigoCuenta = "";
  descripcionCuenta = "";

  tipoGastoCorrecto = false;
  cuentaSoftlandCorrecto = false;

  observante = "";
  respuesta = "";
  respondedor = "";

  // itemsComunas = ["a"];
  //itemCodigoCuentas = ["b"];
  //itemDescripcionCuentas = ["c"];
  //itemTipoGastos = "";

  fechaVencimiento = new Date(
    Date.now() - new Date().getTimezoneOffset() * 60000
  )
    .toISOString()
    .substr(0, 10);

  valorDivisa = 0; // nuevo

  afectoExento = "";

  get titulo(): string {
    return this.solicitud ? "editar solicitud" : "nuevo solicitud";
  }

  //   get permisosKey(): Array<string> {
  //     // return Object.keys(this.permisos).filter((key) => {
  //     //   if(this.rutas[key].tipos.includes(this.tipo)){
  //     //     this.permisos[key] = true;
  //     //   }
  //     // });
  //     return Object.keys(this.permisos).filter((key) => {
  //       if (!this.rutas[key]) {
  //         return false;
  //       }
  //       const tipo = this.rutas[key].tipos.includes(this.tipo);
  //       return tipo;
  //     });
  //   }

  // get itemCentrosCostos(): Array<string> {
  //   const itemCentroCosto: Array<string> = [];
  //   this.mapeoGastos.forEach((centroCosto) => {
  //     itemCentroCosto.push(centroCosto.centroCosto);
  //   });

  //   return itemCentroCosto.sort((sesionA, sesionB) => {
  //     return parseInt(sesionA) - parseInt(sesionB);
  //   });
  // }

  // get itemRutProveedores(): Array<string> {
  //   const itemRutProveedor: Array<string> = [];
  //   this.mapeoGastos.forEach((mapeoGasto) => {
  //     if (mapeoGasto.centroCosto === this.centroCosto) {
  //       itemRutProveedor.push(mapeoGasto.rut);
  //     }
  //   });
  //   return itemRutProveedor.sort();
  // }

  get itemNombreProveedores(): string | undefined {
    let itemNombreProveedor = "";
    this.mapeoGastos.forEach((proveedor) => {
      if (
        proveedor.rut === this.rut &&
        proveedor.centroCosto === this.centroCosto
      ) {
        itemNombreProveedor = proveedor.nombreProveedor;
        this.empresa = itemNombreProveedor;
      }
    });
    return itemNombreProveedor;
  }

  // get itemsComunas(): Array<string> {
  //   const itemComuna: Array<string> = [];
  //   this.ciudades.forEach((comuna) => {
  //     itemComuna.push(comuna.nombre.toUpperCase());
  //   });
  //   return itemComuna.sort();
  // }

  get itemTipoGastos(): Array<string> {
    const itemTipoGasto: Array<string> = [];
    this.mapeoGastos.forEach((mapeoGasto) => {
      if (
        mapeoGasto.centroCosto === this.centroCosto &&
        mapeoGasto.rut === this.rut
      ) {
        itemTipoGasto.push(mapeoGasto.tipoGasto);
      }
    });
    return itemTipoGasto.sort();
  }

  get itemCodigoCuentas(): Array<string> {
    const itemCodigoCuentas: Array<string> = [];
    this.mapeoGastos.forEach((mapeoGasto) => {
      if (
        mapeoGasto.centroCosto === this.centroCosto &&
        mapeoGasto.rut === this.rut
      ) {
        itemCodigoCuentas.push(mapeoGasto.codigoCuenta);
      }
    });
    return itemCodigoCuentas.sort();
  }

  get itemDescripcionCuentas(): string | undefined {
    let itemDescripcionCuentas = "";
    this.mapeoGastos.forEach((mapeoGasto) => {
      if (mapeoGasto.codigoCuenta === this.codigoCuenta) {
        itemDescripcionCuentas = mapeoGasto.descripcionCuenta;
        this.descripcionCuenta = itemDescripcionCuentas;
      }
    });
    return itemDescripcionCuentas;
  }

  get tipoGastoError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.tipoGasto.$dirty) return errors;
    if (!this.$v.tipoGasto.required) errors.push("");
    return errors;
  }

  get descripcionCuentasError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.descripcionCuenta.$dirty) return errors;
    if (!this.$v.descripcionCuenta.required) errors.push("");
    return errors;
  }

  get codigoCuentaError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.codigoCuenta.$dirty) return errors;
    if (!this.$v.codigoCuenta.required) errors.push("");
    return errors;
  }

  formatoPesoChileno(valor: number): string{
    const formatter = new Intl.NumberFormat("es-CL", {
      style: "currency",
      currency: "CLP",
    });
    return formatter.format(valor);
  }

  valorTotal(
    cantidad: number,
    valorDivisa: number,
    precioUnitario: number
  ): number {
    return cantidad * valorDivisa * precioUnitario;
  }

  actualizarValorDivisa(divisa: string): string {
    if (divisa === "CLP") {
      this.valorDivisa = 1;
    } else {
      this.valorDivisa = 0;
    }
    return divisa;
  }

  get options(): Record<string, unknown> {
    let prefix = "";
    let suffix = "";
    if (this.divisa === "UF") {
      suffix = " UF";
    } else if (this.divisa === "CLP") {
      prefix = "$";
    } else if (this.divisa === "USD") {
      prefix = "$";
      suffix = " USD";
    } else if (this.divisa === "EUR") {
      prefix = "€";
    }

    return {
      locale: "es-CL",
      prefix,
      suffix,
      length: 18,
      precision: 0,
    };
  }

  get subTotalCalculado(): number {
    let resultado =
      this.cantidad_1 * this.valorDivisa * this.precioUnitario_1 +
      this.cantidad_2 * this.valorDivisa * this.precioUnitario_2 +
      this.cantidad_3 * this.valorDivisa * this.precioUnitario_3 +
      this.cantidad_4 * this.valorDivisa * this.precioUnitario_4 +
      this.cantidad_5 * this.valorDivisa * this.precioUnitario_5 +
      this.cantidad_6 * this.valorDivisa * this.precioUnitario_6 +
      this.cantidad_7 * this.valorDivisa * this.precioUnitario_7 +
      this.cantidad_8 * this.valorDivisa * this.precioUnitario_8 +
      this.cantidad_9 * this.valorDivisa * this.precioUnitario_9 +
      this.cantidad_10 * this.valorDivisa * this.precioUnitario_10 +
      this.cantidad_11 * this.valorDivisa * this.precioUnitario_11 +
      this.cantidad_12 * this.valorDivisa * this.precioUnitario_12 +
      this.cantidad_13 * this.valorDivisa * this.precioUnitario_13 +
      this.cantidad_14 * this.valorDivisa * this.precioUnitario_14 +
      this.cantidad_15 * this.valorDivisa * this.precioUnitario_15 +
      this.cantidad_16 * this.valorDivisa * this.precioUnitario_16;
    this.subTotal = resultado;
    this.subTotalNeto = resultado;
    return resultado;
  }

  get afectoIVACalculado(): number {
    let resultado = 0;
    if (this.tipoOrden === "AFECTA") {
      resultado = this.subTotalCalculado;
    }
    return resultado;
  }

  get exentoIVACalculado(): number {
    let resultado = 0;
    if (this.tipoOrden === "EXENTA") {
      resultado = this.subTotalCalculado;
    }
    return resultado;
  }

  get IVACalculado(): number {
    let resultado = 0;
    if (this.tipoOrden === "AFECTA") {
      resultado = this.subTotalCalculado * 0.19;
    }
    return resultado;
  }

  get totalCalculado(): number {
    let resultado = 0;
    if (this.tipoOrden === "AFECTA") {
      resultado = this.subTotalCalculado * 1.19;
    } else {
      resultado = this.subTotalCalculado;
    }
    return resultado;
  }

  @Emit("close")
  onClose(): null {
    return null;
  }

  @Emit("cerrar-dialogo")
  onCerrarDialogo(): void {
    return;
  }

  @Emit("click-guardar")
  onClickGuardar(data: DataFormularioSolicitud): DataFormularioSolicitud {
    return data;
  }

  //   @Watch("permisosKey", { immediate: true })
  //   onTipoChange(): void {
  //     console.log("a");
  //     for (const key of Object.keys(this.permisos)) {
  //       if (this.permisos[key]) {
  //         this.permisos[key] = false;
  //       }
  //     }
  //     this.permisosKey.forEach((key) => {
  //       this.permisos[key] = true;
  //     });
  //   }

  //   nombrePermiso(key: string): string {
  //     return this.rutas[key].text;
  //   }

  aprobar(): void {
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }
    const doc: Solicitud = {
      nombre: this.nombre.toUpperCase() ?? "",
      ordenCompra: this.ordenCompra ?? "",
      fechaEmision: this.fechaEmision ?? "",
      estado: (this.estado = "aprobado"),
      autorizado: this.autorizado ?? "",
      subTotal: this.subTotal ?? "",
      afectoIVA: this.afectoIVA ?? "",
      exentoIVA: this.exentoIVA ?? "",
      subTotalNeto: this.subTotal ?? "",
      IVA: this.IVA ?? "",
      total: this.total ?? "",
      tipoOrden: this.tipoOrden ?? "",
      // cambiar
      //servicios: this.servicios?? "",
      cantidad_1: this.cantidad_1 ?? "",
      cantidad_2: this.cantidad_2 ?? "",
      cantidad_3: this.cantidad_3 ?? "",
      cantidad_4: this.cantidad_4 ?? "",
      cantidad_5: this.cantidad_5 ?? "",
      cantidad_6: this.cantidad_6 ?? "",
      cantidad_7: this.cantidad_7 ?? "",
      cantidad_8: this.cantidad_8 ?? "",
      cantidad_9: this.cantidad_9 ?? "",
      cantidad_10: this.cantidad_10 ?? "",
      cantidad_11: this.cantidad_11 ?? "",
      cantidad_12: this.cantidad_12 ?? "",
      cantidad_13: this.cantidad_13 ?? "",
      cantidad_14: this.cantidad_14 ?? "",
      cantidad_15: this.cantidad_15 ?? "",
      cantidad_16: this.cantidad_16 ?? "",

      unidadMedida_1: this.unidadMedida_1.toUpperCase() ?? "",
      unidadMedida_2: this.unidadMedida_2.toUpperCase() ?? "",
      unidadMedida_3: this.unidadMedida_3.toUpperCase() ?? "",
      unidadMedida_4: this.unidadMedida_4.toUpperCase() ?? "",
      unidadMedida_5: this.unidadMedida_5.toUpperCase() ?? "",
      unidadMedida_6: this.unidadMedida_6.toUpperCase() ?? "",
      unidadMedida_7: this.unidadMedida_7.toUpperCase() ?? "",
      unidadMedida_8: this.unidadMedida_8.toUpperCase() ?? "",
      unidadMedida_9: this.unidadMedida_9.toUpperCase() ?? "",
      unidadMedida_10: this.unidadMedida_10.toUpperCase() ?? "",
      unidadMedida_11: this.unidadMedida_11.toUpperCase() ?? "",
      unidadMedida_12: this.unidadMedida_12.toUpperCase() ?? "",
      unidadMedida_13: this.unidadMedida_13.toUpperCase() ?? "",
      unidadMedida_14: this.unidadMedida_14.toUpperCase() ?? "",
      unidadMedida_15: this.unidadMedida_15.toUpperCase() ?? "",
      unidadMedida_16: this.unidadMedida_16.toUpperCase() ?? "",

      descripcion_1: this.descripcion_1.toUpperCase() ?? "",
      descripcion_2: this.descripcion_2.toUpperCase() ?? "",
      descripcion_3: this.descripcion_3.toUpperCase() ?? "",
      descripcion_4: this.descripcion_4.toUpperCase() ?? "",
      descripcion_5: this.descripcion_5.toUpperCase() ?? "",
      descripcion_6: this.descripcion_6.toUpperCase() ?? "",
      descripcion_7: this.descripcion_7.toUpperCase() ?? "",
      descripcion_8: this.descripcion_8.toUpperCase() ?? "",
      descripcion_9: this.descripcion_9.toUpperCase() ?? "",
      descripcion_10: this.descripcion_10.toUpperCase() ?? "",
      descripcion_11: this.descripcion_11.toUpperCase() ?? "",
      descripcion_12: this.descripcion_12.toUpperCase() ?? "",
      descripcion_13: this.descripcion_13.toUpperCase() ?? "",
      descripcion_14: this.descripcion_14.toUpperCase() ?? "",
      descripcion_15: this.descripcion_15.toUpperCase() ?? "",
      descripcion_16: this.descripcion_16.toUpperCase() ?? "",

      centroCosto_1: this.centroCosto_1 ?? "",
      centroCosto_2: this.centroCosto_2 ?? "",
      centroCosto_3: this.centroCosto_3 ?? "",
      centroCosto_4: this.centroCosto_4 ?? "",
      centroCosto_5: this.centroCosto_5 ?? "",
      centroCosto_6: this.centroCosto_6 ?? "",
      centroCosto_7: this.centroCosto_7 ?? "",
      centroCosto_8: this.centroCosto_8 ?? "",
      centroCosto_9: this.centroCosto_9 ?? "",
      centroCosto_10: this.centroCosto_10 ?? "",
      centroCosto_11: this.centroCosto_11 ?? "",
      centroCosto_12: this.centroCosto_12 ?? "",
      centroCosto_13: this.centroCosto_13 ?? "",
      centroCosto_14: this.centroCosto_14 ?? "",
      centroCosto_15: this.centroCosto_15 ?? "",
      centroCosto_16: this.centroCosto_16 ?? "",

      precioUnitario_1: this.precioUnitario_1 ?? "",
      precioUnitario_2: this.precioUnitario_2 ?? "",
      precioUnitario_3: this.precioUnitario_3 ?? "",
      precioUnitario_4: this.precioUnitario_4 ?? "",
      precioUnitario_5: this.precioUnitario_5 ?? "",
      precioUnitario_6: this.precioUnitario_6 ?? "",
      precioUnitario_7: this.precioUnitario_7 ?? "",
      precioUnitario_8: this.precioUnitario_8 ?? "",
      precioUnitario_9: this.precioUnitario_9 ?? "",
      precioUnitario_10: this.precioUnitario_10 ?? "",
      precioUnitario_11: this.precioUnitario_11 ?? "",
      precioUnitario_12: this.precioUnitario_12 ?? "",
      precioUnitario_13: this.precioUnitario_13 ?? "",
      precioUnitario_14: this.precioUnitario_14 ?? "",
      precioUnitario_15: this.precioUnitario_15 ?? "",
      precioUnitario_16: this.precioUnitario_16 ?? "",

      precioTotal_1: this.precioTotal_1 ?? "",
      precioTotal_2: this.precioTotal_2 ?? "",
      precioTotal_3: this.precioTotal_3 ?? "",
      precioTotal_4: this.precioTotal_4 ?? "",
      precioTotal_5: this.precioTotal_5 ?? "",
      precioTotal_6: this.precioTotal_6 ?? "",
      precioTotal_7: this.precioTotal_7 ?? "",
      precioTotal_8: this.precioTotal_8 ?? "",
      precioTotal_9: this.precioTotal_9 ?? "",
      precioTotal_10: this.precioTotal_10 ?? "",
      precioTotal_11: this.precioTotal_11 ?? "",
      precioTotal_12: this.precioTotal_12 ?? "",
      precioTotal_13: this.precioTotal_13 ?? "",
      precioTotal_14: this.precioTotal_14 ?? "",
      precioTotal_15: this.precioTotal_15 ?? "",
      precioTotal_16: this.precioTotal_16 ?? "",

      // Datos de carga manual
      centroCosto: this.centroCosto ?? "",
      divisa: this.divisa ?? "",
      empresa: this.empresa ?? "",
      rut: this.rut.toUpperCase() ?? "",
      direccion: this.direccion.toUpperCase() ?? "",
      comuna: this.comuna ?? "",
      telefono: this.telefono ?? "",
      contacto: this.contacto.toUpperCase() ?? "",
      formaPago: this.formaPago.toUpperCase() ?? "",
      correo: this.correo.toUpperCase() ?? "",
      observaciones: this.observaciones.toUpperCase() ?? "",
      descuento: this.descuento ?? "",
      tipoDescuento: this.tipoDescuento ?? "",
      destinatario: this.destinatario.toUpperCase() ?? "",
      rutDestinatario: this.rutDestinatario.toUpperCase() ?? "",
      banco: this.banco.toUpperCase() ?? "",
      tipoCueta: this.tipoCueta.toUpperCase() ?? "",
      nroCuenta: this.nroCuenta ?? "",
      correoDestinatario: this.correoDestinatario.toUpperCase() ?? "",
      // archivo1: this.archivo1?? "",
      // archivo2: this.archivo2?? "",
      // archivo3: this.archivo3?? "",
      // archivo4: this.archivo4?? "",
      fechaUf: this.fechaUf ?? "",
      solicitante: this.solicitante.toUpperCase() ?? "",
      motivoAnulacion: this.motivoAnulacion.toUpperCase() ?? "",
      tipoArchivo: this.tipoArchivo ?? "",
      tipoArchivo2: this.tipoArchivo2 ?? "",
      tipoArchivo3: this.tipoArchivo3 ?? "",
      tipoArchivo4: this.tipoArchivo4 ?? "",
      tipoArchivo5: this.tipoArchivo5 ?? "",
      tipoArchivo6: this.tipoArchivo6 ?? "",
      subidoTipoArchivo: this.subidoTipoArchivo ?? "",
      subidoTipoArchivo2: this.subidoTipoArchivo2 ?? "",
      color: this.color ?? "",
      observacion: this.observacion ?? "",
      tipoGasto: this.tipoGasto ?? "",
      // tipoArchivo2: this.tipoArchivo2?? "",

      nFactura: this.nFactura ?? "",
      periodo: this.periodo ?? "",
      recurrente: this.recurrente ?? "",
      tipoRecurrencia: this.tipoRecurrencia ?? "",
      periodoRecurrencia: this.periodoRecurrencia ?? "",
      montoSaldo: this.montoSaldo ?? "",
      montoAP: this.montoAP ?? "",
      numeroCheque: this.numeroCheque ?? "",

      codigoCuenta: this.codigoCuenta ?? "",
      descripcionCuenta: this.descripcionCuenta ?? "",

      tipoGastoCorrecto: this.tipoGastoCorrecto ?? "",
      cuentaSoftlandCorrecto: this.cuentaSoftlandCorrecto ?? "",
      observante: this.observante ?? "",

      respuesta: this.respuesta.toUpperCase() ?? "",
      respondedor: this.respondedor.toUpperCase() ?? "",
      fechaVencimiento: this.fechaVencimiento ?? "" ?? "",

      valorDivisa: this.valorDivisa ?? 0, // nuevo

      afectoExento: this.afectoExento ?? "", // nuevo
    };
    if (this.id) {
      doc.id = this.id;
    }
    const data: DataFormularioSolicitud = {
      doc: doc,
    };
    this.onClickGuardar(data);
  }

  rechazar(): void {
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }
    const doc: Solicitud = {
      nombre: this.nombre.toUpperCase() ?? "",
      ordenCompra: this.ordenCompra ?? "",
      fechaEmision: this.fechaEmision ?? "",
      estado: (this.estado = "rechazado"),
      autorizado: this.autorizado ?? "",
      subTotal: this.subTotal ?? "",
      afectoIVA: this.afectoIVA ?? "",
      exentoIVA: this.exentoIVA ?? "",
      subTotalNeto: this.subTotal ?? "",
      IVA: this.IVA ?? "",
      total: this.total ?? "",
      tipoOrden: this.tipoOrden ?? "",
      // cambiar
      //servicios: this.servicios?? "",
      cantidad_1: this.cantidad_1 ?? "",
      cantidad_2: this.cantidad_2 ?? "",
      cantidad_3: this.cantidad_3 ?? "",
      cantidad_4: this.cantidad_4 ?? "",
      cantidad_5: this.cantidad_5 ?? "",
      cantidad_6: this.cantidad_6 ?? "",
      cantidad_7: this.cantidad_7 ?? "",
      cantidad_8: this.cantidad_8 ?? "",
      cantidad_9: this.cantidad_9 ?? "",
      cantidad_10: this.cantidad_10 ?? "",
      cantidad_11: this.cantidad_11 ?? "",
      cantidad_12: this.cantidad_12 ?? "",
      cantidad_13: this.cantidad_13 ?? "",
      cantidad_14: this.cantidad_14 ?? "",
      cantidad_15: this.cantidad_15 ?? "",
      cantidad_16: this.cantidad_16 ?? "",

      unidadMedida_1: this.unidadMedida_1.toUpperCase() ?? "",
      unidadMedida_2: this.unidadMedida_2.toUpperCase() ?? "",
      unidadMedida_3: this.unidadMedida_3.toUpperCase() ?? "",
      unidadMedida_4: this.unidadMedida_4.toUpperCase() ?? "",
      unidadMedida_5: this.unidadMedida_5.toUpperCase() ?? "",
      unidadMedida_6: this.unidadMedida_6.toUpperCase() ?? "",
      unidadMedida_7: this.unidadMedida_7.toUpperCase() ?? "",
      unidadMedida_8: this.unidadMedida_8.toUpperCase() ?? "",
      unidadMedida_9: this.unidadMedida_9.toUpperCase() ?? "",
      unidadMedida_10: this.unidadMedida_10.toUpperCase() ?? "",
      unidadMedida_11: this.unidadMedida_11.toUpperCase() ?? "",
      unidadMedida_12: this.unidadMedida_12.toUpperCase() ?? "",
      unidadMedida_13: this.unidadMedida_13.toUpperCase() ?? "",
      unidadMedida_14: this.unidadMedida_14.toUpperCase() ?? "",
      unidadMedida_15: this.unidadMedida_15.toUpperCase() ?? "",
      unidadMedida_16: this.unidadMedida_16.toUpperCase() ?? "",

      descripcion_1: this.descripcion_1.toUpperCase() ?? "",
      descripcion_2: this.descripcion_2.toUpperCase() ?? "",
      descripcion_3: this.descripcion_3.toUpperCase() ?? "",
      descripcion_4: this.descripcion_4.toUpperCase() ?? "",
      descripcion_5: this.descripcion_5.toUpperCase() ?? "",
      descripcion_6: this.descripcion_6.toUpperCase() ?? "",
      descripcion_7: this.descripcion_7.toUpperCase() ?? "",
      descripcion_8: this.descripcion_8.toUpperCase() ?? "",
      descripcion_9: this.descripcion_9.toUpperCase() ?? "",
      descripcion_10: this.descripcion_10.toUpperCase() ?? "",
      descripcion_11: this.descripcion_11.toUpperCase() ?? "",
      descripcion_12: this.descripcion_12.toUpperCase() ?? "",
      descripcion_13: this.descripcion_13.toUpperCase() ?? "",
      descripcion_14: this.descripcion_14.toUpperCase() ?? "",
      descripcion_15: this.descripcion_15.toUpperCase() ?? "",
      descripcion_16: this.descripcion_16.toUpperCase() ?? "",

      centroCosto_1: this.centroCosto_1 ?? "",
      centroCosto_2: this.centroCosto_2 ?? "",
      centroCosto_3: this.centroCosto_3 ?? "",
      centroCosto_4: this.centroCosto_4 ?? "",
      centroCosto_5: this.centroCosto_5 ?? "",
      centroCosto_6: this.centroCosto_6 ?? "",
      centroCosto_7: this.centroCosto_7 ?? "",
      centroCosto_8: this.centroCosto_8 ?? "",
      centroCosto_9: this.centroCosto_9 ?? "",
      centroCosto_10: this.centroCosto_10 ?? "",
      centroCosto_11: this.centroCosto_11 ?? "",
      centroCosto_12: this.centroCosto_12 ?? "",
      centroCosto_13: this.centroCosto_13 ?? "",
      centroCosto_14: this.centroCosto_14 ?? "",
      centroCosto_15: this.centroCosto_15 ?? "",
      centroCosto_16: this.centroCosto_16 ?? "",

      precioUnitario_1: this.precioUnitario_1 ?? "",
      precioUnitario_2: this.precioUnitario_2 ?? "",
      precioUnitario_3: this.precioUnitario_3 ?? "",
      precioUnitario_4: this.precioUnitario_4 ?? "",
      precioUnitario_5: this.precioUnitario_5 ?? "",
      precioUnitario_6: this.precioUnitario_6 ?? "",
      precioUnitario_7: this.precioUnitario_7 ?? "",
      precioUnitario_8: this.precioUnitario_8 ?? "",
      precioUnitario_9: this.precioUnitario_9 ?? "",
      precioUnitario_10: this.precioUnitario_10 ?? "",
      precioUnitario_11: this.precioUnitario_11 ?? "",
      precioUnitario_12: this.precioUnitario_12 ?? "",
      precioUnitario_13: this.precioUnitario_13 ?? "",
      precioUnitario_14: this.precioUnitario_14 ?? "",
      precioUnitario_15: this.precioUnitario_15 ?? "",
      precioUnitario_16: this.precioUnitario_16 ?? "",

      precioTotal_1: this.precioTotal_1 ?? "",
      precioTotal_2: this.precioTotal_2 ?? "",
      precioTotal_3: this.precioTotal_3 ?? "",
      precioTotal_4: this.precioTotal_4 ?? "",
      precioTotal_5: this.precioTotal_5 ?? "",
      precioTotal_6: this.precioTotal_6 ?? "",
      precioTotal_7: this.precioTotal_7 ?? "",
      precioTotal_8: this.precioTotal_8 ?? "",
      precioTotal_9: this.precioTotal_9 ?? "",
      precioTotal_10: this.precioTotal_10 ?? "",
      precioTotal_11: this.precioTotal_11 ?? "",
      precioTotal_12: this.precioTotal_12 ?? "",
      precioTotal_13: this.precioTotal_13 ?? "",
      precioTotal_14: this.precioTotal_14 ?? "",
      precioTotal_15: this.precioTotal_15 ?? "",
      precioTotal_16: this.precioTotal_16 ?? "",

      // Datos de carga manual
      centroCosto: this.centroCosto ?? "",
      divisa: this.divisa ?? "",
      empresa: this.empresa ?? "",
      rut: this.rut.toUpperCase() ?? "",
      direccion: this.direccion.toUpperCase() ?? "",
      comuna: this.comuna ?? "",
      telefono: this.telefono ?? "",
      contacto: this.contacto.toUpperCase() ?? "",
      formaPago: this.formaPago.toUpperCase() ?? "",
      correo: this.correo.toUpperCase() ?? "",
      observaciones: this.observaciones.toUpperCase() ?? "",
      descuento: this.descuento ?? "",
      tipoDescuento: this.tipoDescuento ?? "",
      destinatario: this.destinatario.toUpperCase() ?? "",
      rutDestinatario: this.rutDestinatario.toUpperCase() ?? "",
      banco: this.banco.toUpperCase() ?? "",
      tipoCueta: this.tipoCueta.toUpperCase() ?? "",
      nroCuenta: this.nroCuenta ?? "",
      correoDestinatario: this.correoDestinatario.toUpperCase() ?? "",
      // archivo1: this.archivo1?? "",
      // archivo2: this.archivo2?? "",
      // archivo3: this.archivo3?? "",
      // archivo4: this.archivo4?? "",
      fechaUf: this.fechaUf ?? "",
      solicitante: this.solicitante.toUpperCase() ?? "",
      motivoAnulacion: this.motivoAnulacion.toUpperCase() ?? "",
      tipoArchivo: this.tipoArchivo ?? "",
      tipoArchivo2: this.tipoArchivo2 ?? "",
      tipoArchivo3: this.tipoArchivo3 ?? "",
      tipoArchivo4: this.tipoArchivo4 ?? "",
      tipoArchivo5: this.tipoArchivo5 ?? "",
      tipoArchivo6: this.tipoArchivo6 ?? "",
      subidoTipoArchivo: this.subidoTipoArchivo ?? "",
      subidoTipoArchivo2: this.subidoTipoArchivo2 ?? "",
      color: this.color ?? "",
      observacion: this.observacion ?? "",
      tipoGasto: this.tipoGasto ?? "",
      // tipoArchivo2: this.tipoArchivo2?? "",

      nFactura: this.nFactura ?? "",
      periodo: this.periodo ?? "",
      recurrente: this.recurrente ?? "",
      tipoRecurrencia: this.tipoRecurrencia ?? "",
      periodoRecurrencia: this.periodoRecurrencia ?? "",
      montoSaldo: this.montoSaldo ?? "",
      montoAP: this.montoAP ?? "",
      numeroCheque: this.numeroCheque ?? "",

      codigoCuenta: this.codigoCuenta ?? "",
      descripcionCuenta: this.descripcionCuenta ?? "",

      tipoGastoCorrecto: this.tipoGastoCorrecto ?? "",
      cuentaSoftlandCorrecto: this.cuentaSoftlandCorrecto ?? "",
      observante: this.observante ?? "",

      respuesta: this.respuesta.toUpperCase() ?? "",
      respondedor: this.respondedor.toUpperCase() ?? "",
      fechaVencimiento: this.fechaVencimiento ?? "" ?? "",

      valorDivisa: this.valorDivisa ?? 0, // nuevo

      afectoExento: this.afectoExento ?? "", // nuevo
    };
    if (this.id) {
      doc.id = this.id;
    }
    const data: DataFormularioSolicitud = {
      doc: doc,
    };
    this.onClickGuardar(data);
  }
}
