








































































































































































































































































































































































import {Component, Emit, Prop, Vue, Watch} from "vue-property-decorator";
// helpers
// tipos
// validaciones
import {required} from "vuelidate/lib/validators";
import {Promocion, Tipo} from "@/typings/store/plugins/easyFirestore/promociones";
import {Curso, Cursos} from "@/typings/store/plugins/easyFirestore/cursos";
import {fechaLocal} from "@/helpers/date";
import {DataFormularioPromocion} from "@/typings/components/promociones/formulario";
import {Archivo, Archivos} from "@/typings/store/modules/archivos";
import SVGIcon from "@/components/SVGIcon.vue";
import VistaPreviaImagen from "@/components/VistaPreviaImagen.vue";
import {idtify} from "@/helpers/string";
import DialogoExito from "@/components/DialogoExito.vue";

@Component({
  components: {DialogoExito, VistaPreviaImagen, SVGIcon},
  validations: {
    nombre: {required},
    descripcion: {required},
    descuento: {required},
    inicio: {required},
    fin: {required},
  },
})
export default class FormularioPromocion extends Vue {
  @Prop() readonly promocion!: Promocion | null;
  @Prop() readonly arrayCursos!: Curso[];
  @Prop() readonly cargando!: boolean;

  created(): void {
    const promocion = this.promocion;
    if (!promocion) {
      return;
    }
    this.id = promocion.id ?? "";
    this.nombre = promocion.nombre ?? "";
    this.descripcion = promocion.descripcion ?? "";
    this.codigo = promocion.codigo ?? "";
    if (promocion.cursos) this.cursos = Object.values(promocion.cursos);
    this.imagen = Object.values(promocion.imagen ?? {})[0] ?? null;
    this.descuento = promocion.descuento ?? "";
    this.inicio = promocion.inicio.toDate().format('YYYY-MM-DD') ?? "";
    this.fin = promocion.fin.toDate().format('YYYY-MM-DD') ?? "";
    this.linkYoutube = promocion.social.youtube;
  }
  allCursos = false;
  linkYoutube = "";
  menuFecha = false;
  menuFecha2 = false;
  id: string | null = null;
  nombre = "";
  imagen: Archivo | null = null;
  archivosEliminados: Archivos = {};
  descripcion = "";
  codigo = "";
  tipo: Tipo = 'curso';
  cursos: Curso[] = [];
  descuento = "";
  inicio = fechaLocal();
  fin = fechaLocal();
  vistaPrevia = {
    model: false,
    url: ""
  };
  dialogoExito = {
    model: false,
    icono: "",
    texto: ""
  };

  get nombreFotoPromocion(): string {
    return this.imagen?.file?.name ?? this.imagen?.nombre ?? "";
  }

  get titulo(): string {
    return this.promocion ? "editar promoción" : "nueva promoción";
  }

  get nombreError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.nombre.$dirty) return errors;
    if (!this.$v.nombre.required) errors.push("Campo requerido");
    return errors;
  }

  get descripcionError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.descripcion.$dirty) return errors;
    if (!this.$v.descripcion.required) errors.push("Campo requerido");
    return errors;
  }

  get descuentoError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.descuento.$dirty) return errors;
    if (!this.$v.descuento.required) errors.push("Campo requerido");
    return errors;
  }

  get inicioError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.inicio.$dirty) return errors;
    if (!this.$v.inicio.required) errors.push("Campo requerido");
    return errors;
  }

  get finError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.fin.$dirty) return errors;
    if (!this.$v.fin.required) errors.push("Campo requerido");
    return errors;
  }

  get totalCursosSeleccionados(): number {
    return this.cursos.reduce((sum, curso) => sum + curso.precio - (isNaN(parseInt(curso.descuento)) ? 0 : parseInt(curso.descuento)), 0);
  }

  get totalConDescuento(): number {
    if (this.descuentoValido) {
      const descuento = this.descuento.endsWith("%") ? (this.totalCursosSeleccionados * parseInt(this.descuento.replace("%", ""))) / 100 : parseInt(this.descuento);
      return this.totalCursosSeleccionados - Math.floor(descuento);
    }
    return this.totalCursosSeleccionados;
  }

  get descuentoValido(): boolean {
    const re = /(^\d+$)|(^\d{1,2}%$)/g;
    return re.test(this.descuento);
  }

  @Watch('allCursos')
  onChangeAllCursos(): void {
    if (this.allCursos) {
      this.cursos = this.arrayCursos.filter(curso => curso.estado === 'aceptado');
    } else {
      this.cursos = [];
    }
    console.log(this.cursos);
  }

  @Emit("close")
  onClose(): null {
    return null;
  }

  @Emit("cerrar-dialogo")
  onCerrarDialogo(): void {
    return;
  }

  @Emit("click-guardar")
  onClickGuardar(data: DataFormularioPromocion): DataFormularioPromocion {
    return data;
  }

  clickInputFotoPromocion(): void {
    const input = this.$refs.inputFotoPromocion as HTMLInputElement;
    if (input) {
      input.click();
    }
  }


  cargarFotoPromocion(ev: Event): void {
    const target = ev.target as HTMLInputElement;
    const file = target.files ? target.files[0] : null;
    if (file) {
      if(file.type.includes("image")) {
        const fecha = new Date();
        const extencion = file.name.split(".").pop() ?? "";
        const id = `promociones/${this.nombre}/imagen/${fecha.valueOf()}.${extencion}`;
        this.imagen = {
          id: id,
          nombre: file.name,
          file: file
        };
      }
    }
  }

  abrirVistaPrevia(imagen: Archivo): void {
    this.vistaPrevia = {
      model: true,
      url: this.urlArchivo(imagen)
    };
  }

  cerrarVistaPrevia(): void {
    this.vistaPrevia = {
      model: false,
      url: ""
    };
  }

  urlArchivo(media: Archivo): string {
    return media.url ?? URL.createObjectURL(media.file);
  }

  eliminarImagen(): void {
    if(this.promocion && this.imagen) {
      this.$set(this.archivosEliminados, idtify(this.imagen.id), this.imagen);
    }
    this.imagen = null;
  }

  guardar(): void {
    this.$v.$touch();
    if (this.$v.$invalid) {
      this.dialogoExito = {
        model: true,
        icono: require("../../assets/icons/cancelar-duo.svg"),
        texto: "¡Complete todos los campos del formulario!",
      };
      return;
    }
    if (this.descuento && this.descuentoValido) {
      if (!this.descuento.includes("%") && this.totalConDescuento - parseInt(this.descuento) < 0) {
        this.dialogoExito = {
          model: true,
          icono: require("../../assets/icons/cancelar-duo.svg"),
          texto: "¡El descuento no puede ser mayor al precio!",
        };
        return;
      }
    } else {
      this.dialogoExito = {
        model: true,
        icono: require("../../assets/icons/cancelar-duo.svg"),
        texto: "¡El descuento no puede ser mayor al precio!",
      };
      console.log('descuento invalido');
      return;
    }
    let cursos = this.cursos?.reduce((docs, doc) => {
      if (!doc?.id) {
        console.error("doc.id == undefined => ", doc);
        return docs;
      }
      docs[doc.id] = doc;
      return docs;
    }, {} as Cursos);
    const social = {
      youtube: this.linkYoutube,
    };
    if(this.codigo){
      cursos = {};
    }
    const doc: Promocion = {
      imagen: {},
      nombre: this.nombre.toLowerCase(),
      descripcion: this.descripcion,
      codigo: this.codigo,
      tipo: Object.values(cursos).length > 0 ? 'curso' : 'compra',
      cursos: cursos,
      descuento: this.descuento,
      inicio: this.inicio.toDate().toISOString(),
      fin: this.fin.toDate().toISOString(),
      social: social
    };
    if (this.id) {
      doc.id = this.id;
    }
    const data: DataFormularioPromocion = {
      borrar: this.archivosEliminados,
      imagen: {},
      doc: doc,
    };
    if(this.imagen) {
      data.imagen = {
        [idtify(this.imagen.id)]: this.imagen
      };
    }
    this.onClickGuardar(data);
  }

  cerrarDialogoExito(): void {
    this.dialogoExito = {
      model: false,
      icono: "",
      texto: ""
    };
  }

}
