





























































// decoradores
import { Vue, Component, Prop, Emit } from "vue-property-decorator";
// validaciones
import { required } from "vuelidate/lib/validators";
// tipos
import {
  PlanCuentaIngreso,
  PlanCuentasIngresos,
} from "@/typings/store/plugins/easyFirestore/planCuentasIngresos";
// api excel
import Excel from "exceljs";

interface Registro {
  // entrada: Date;
  // salida: Date;
  nombre: string;
  posicion: string;
  TIPO_DOCUMENTO: string;
  NUMERO_DOCUMENTO: string;
  NUMERO_CUENTA: string;
  CUENTA_SOFTLAND: string;
  PRODUCTO: string;
  VALOR_NETO: string;
}

interface NombresPlanCuentasIngresos {
  [nombre: string]: PlanCuentaIngreso;
}

// const date = (value: Date) => {
//   return value.toString() != "Invalid Date";
// };

@Component({
  validations: {
    archivo: { required },
  },
})
export default class CargarExcel extends Vue {
  @Prop() readonly planCuentasIngresos!: PlanCuentasIngresos;

  archivo: Blob | null = null;
  cargando = false;
  clickCargar = false;

  despachos: Array<PlanCuentaIngreso> = [];
  mensajes: Array<string> = [];

  get nombresPlanCuentasIngresos(): NombresPlanCuentasIngresos {
    const resultado: NombresPlanCuentasIngresos = {};
    Object.values(this.planCuentasIngresos).forEach((item) => {
      resultado[item.nombre] = Object.assign({}, item);
    });
    return resultado;
  }

  // get rfidPatentes(): RfidPatentes {
  //   const resultado: RfidPatentes = {};
  //   Object.values(this.rfids).forEach((item) => {
  //     resultado[item.patente] = Object.assign({}, item);
  //   });
  //   return resultado;
  // }

  get archivoError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.archivo.$dirty) return errors;
    if (!this.$v.archivo.required) errors.push("Requerido");
    return errors;
  }

  @Emit("carga-excel")
  onCargaExcel(): Array<PlanCuentaIngreso> {
    return this.despachos;
  }

  // valoresValidos(valores: Array<Excel.CellValue>): boolean {
  //   if (!valores[1] || !valores[2] ) {
  //     return false;
  //   }
  //   const nombre = valores[1] ;
  //   const posicion = valores[2];
  //   //return !!entrada && !!salida && date(entrada) && date(salida);
  //   return nombre && posicion
  // }

  async cargar(): Promise<void> {
    this.$v.$touch();
    if (this.$v.$invalid || !this.archivo) {
      return;
    }
    this.cargando = true;
    this.clickCargar = true;
    const workbook = new Excel.Workbook();
    const data = await this.archivo.arrayBuffer();
    await workbook.xlsx.load(data);
    // worksheet
    const worksheet = workbook.getWorksheet(1);
    console.log("registros", worksheet.actualRowCount - 1);
    if (worksheet.actualRowCount - 1 > 500) {
      console.log("registros mayores a 500");
      this.mensajes.push("error: cantidad de registros mayor a 500");
      this.cargando = false;
      return;
    }
    worksheet.eachRow((row, rowNumber) => {
      if (rowNumber != 1) {
        const valores = row.values as Array<Excel.CellValue>;
        if (valores[3] == "ejemplo") {
          console.log("error row", rowNumber);
          this.mensajes.push(
            "error: row " +
              rowNumber +
              " invalida, contiene campos vacios, fechas invalidas o la etiqueta prueba"
          );
          return;
        }
        const registro: Registro = {
          nombre: valores[1] as string,
          posicion: valores[2] as string,

          TIPO_DOCUMENTO: valores[3] as string,
          NUMERO_DOCUMENTO: valores[4] as string,
          NUMERO_CUENTA: valores[5] as string,
          CUENTA_SOFTLAND: valores[6] as string,
          PRODUCTO: valores[7] as string,
          VALOR_NETO: valores[8] as string,
        };
        // const banco = this.nombresPlanCuentasIngresos[registro.nombre];
        //  if (!banco) {
        //    console.log("error row", rowNumber);
        //    this.mensajes.push(
        //      "error: row " + rowNumber + " invalida, patente no registrada"
        //    );
        //    return;
        //  }
        const ingreso: PlanCuentaIngreso = {
          nombre: registro.nombre,
          posicion: registro.posicion,

          TIPO_DOCUMENTO: registro.TIPO_DOCUMENTO,
          NUMERO_DOCUMENTO: registro.NUMERO_DOCUMENTO,
          NUMERO_CUENTA: registro.NUMERO_CUENTA,
          CUENTA_SOFTLAND: registro.CUENTA_SOFTLAND.toUpperCase(),
          PRODUCTO: registro.PRODUCTO.toUpperCase(),
          VALOR_NETO: registro.VALOR_NETO,
          
        };
        this.despachos.push(ingreso);
      }
    });
    if (this.mensajes.length > 0) {
      this.mensajes.push("cargado con errores");
      this.mensajes.push("corregir excel y volver a intentar");
      this.cargando = false;
    } else {
      this.mensajes.push("cargado completo");
      this.mensajes.push(
        this.despachos.length + " planCuentasIngresos cargados"
      );
      this.onCargaExcel();
      this.cargando = false;
    }
  }
}
