


























































































































import { CoreData } from "@/typings/vista/monitoreo";
import { Component, Prop, Vue } from "vue-property-decorator";
import { GChart } from "vue-google-charts";
import { Curso } from "@/typings/store/plugins/easyFirestore/cursos";
import { ItemsTablaCursos } from "@/typings/components/cursos/tabla";

@Component({
  components: {
    GChart,
  },
})
export default class ComponenteEstadoCurso extends Vue {
  @Prop() readonly cursos!: Array<Curso>;
  @Prop() readonly cursoItems!: ItemsTablaCursos;

  pieOptions = {
    pieHole: 0.5,
    backgroundColor: "#FFFFFF",
    pieSliceTextStyle: {
      color: "none",
    },
    colors: ["#0d0a39", "#251f50", "#3d3569", "#564c82", "#6f649c", "#897cb7"],
  };

  get coreData(): CoreData {
    let estado = "";
    let estadoImpartiendo = 0;
    let estadoAceptado = 0;
    let estadoRevisado = 0;
    let estadoPendiente = 0;
    let estadoRechazado = 0;
    let estadoTerminado = 0;
    let cantidadEstado = 0;

    const cursoEstado: { [id: string]: number } = {};
    this.cursoItems.forEach((item) => {
      if (!item.estado) {
        return;
      }
      if (item.estado == "pendiente") {
        estadoPendiente += 1;
        estado = "Pendiente";
        cantidadEstado = estadoPendiente;
        cursoEstado[estado] = cantidadEstado;
      }
      if (item.estado == "revisado") {
        estadoRevisado += 1;
        cantidadEstado = estadoRevisado;
        estado = "Revisado";
        cursoEstado[estado] = cantidadEstado;
      }
      if (item.estado == "aceptado") {
        estadoAceptado += 1;
        cantidadEstado = estadoAceptado;
        estado = "Aceptado";
        cursoEstado[estado] = cantidadEstado;
      }
      if (item.estado == "rechazado") {
        estadoRechazado += 1;
        cantidadEstado = estadoRechazado;
        estado = "Rechazado";
        cursoEstado[estado] = cantidadEstado;
      }
      if (item.estado == "impartiendo") {
        estadoImpartiendo += 1;
        cantidadEstado = estadoImpartiendo;
        estado = "Impartiendo";
        cursoEstado[estado] = cantidadEstado;
      }
      if (item.estado == "terminado") {
        estadoTerminado += 1;
        cantidadEstado = estadoTerminado;
        estado = "Terminado";
        cursoEstado[estado] = cantidadEstado;
      }
    });

    const data: CoreData = [
      ["Impartiendo", cursoEstado["Impartiendo"]],
      ["Aceptado", cursoEstado["Aceptado"]],
      ["Revisado", cursoEstado["Revisado"]],
      ["Pendiente", cursoEstado["Pendiente"]],
      ["Rechazado", cursoEstado["Rechazado"]],
      ["Terminado", cursoEstado["Terminado"]],
    ];
    data.unshift(["clasificacion", "porcentaje"]);

    return data;
  }

  get cuentaEstados(): Array<number> {
    let estadoImpartiendo = 0;
    let estadoAceptado = 0;
    let estadoRevisado = 0;
    let estadoPendiente = 0;
    let estadoRechazado = 0;
    let estadoTerminado = 0;
    this.cursoItems.forEach((item) => {
      if (!item.estado) {
        return;
      }
      if (item.estado == "pendiente") {
        estadoPendiente += 1;
      }
      if (item.estado == "revisado") {
        estadoRevisado += 1;
      }
      if (item.estado == "aceptado") {
        estadoAceptado += 1;
      }
      if (item.estado == "rechazado") {
        estadoRechazado += 1;
      }
      if (item.estado == "impartiendo") {
        estadoImpartiendo += 1;
      }
      if (item.estado == "terminado") {
        estadoTerminado += 1;
      }
    });

    return [
      estadoPendiente,
      estadoRevisado,
      estadoAceptado,
      estadoRechazado,
      estadoImpartiendo,
      estadoTerminado,
    ];
  }
}
