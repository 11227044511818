

















































































































































































// decoradores
import { Vue, Component, Prop } from "vue-property-decorator";
// vuex
import { mapActions, mapGetters } from "vuex";
// tipos

// componentes
import TablaInscripciones from "@/components/inscripciones/Tabla.vue";
import FormularioInscripcion from "@/components/inscripciones/Formulario.vue";
import InfoInscripcion from "@/components/inscripciones/Info.vue";
import BotonesCrud from "@/components/BotonesCrud.vue";
import MensajeEliminar from "@/components/MensajeEliminar.vue";
import TituloVista from "@/components/TituloVista.vue";
import DialogoExito from "@/components/DialogoExito.vue";
import DialogoEliminar from "@/components/DialogoEliminar.vue";
import {
  Inscripcion,
  Inscripciones,
} from "@/typings/store/plugins/easyFirestore/inscripciones";
import { Usuario } from "@/typings/store/plugins/easyFirestore/usuarios";
import SVGIcon from "@/components/SVGIcon.vue";

const routeGuard = (vm: Vue): boolean => {
  const permisos = vm.getUsuario?.permisos;
  if (permisos && permisos["inscripciones"]) {
    return true;
  }

  const rutas = vm.getRutasUsuario;
  if (rutas.length > 0) {
    const to = rutas[0].to;
    if (to) {
      if (vm.$route.name !== to.name) {
        vm.$router.replace(to);
      }
      return false;
    }
  }
  if (vm.$route.name !== "inicio") {
    vm.$router.replace({ name: "inicio" });
  }
  return false;
};

@Component({
  computed: mapGetters({
    getInscripciones: "inscripciones/get",
    getArrayInscripciones: "inscripciones/getArray",
    getUsuario: "usuario/get",
    getRutas: "rutas/get",
    getRutasUsuario: "usuario/getRutas",
  }),
  methods: mapActions({
    setInscripcion: "inscripciones/set",
    deleteInscripcion: "inscripciones/delete",
  }),
  beforeRouteEnter(to, from, next) {
    next(routeGuard);
  },
  components: {
    SVGIcon,
    DialogoEliminar,
    DialogoExito,
    TablaInscripciones,
    FormularioInscripcion,
    InfoInscripcion,
    BotonesCrud,
    MensajeEliminar,
    TituloVista,
  },
})
export default class VistaInscripciones extends Vue {
  @Prop() readonly usuario!: Usuario;

  created(): void {
    console.log(this.usuario);
    if (!routeGuard(this)) {
      return;
    }
  }

  dialogoExito = {
    model: false,
    texto: "",
    icono: "",
  };
  menu = false;
  search = "";
  inscripcion: Inscripcion | null = null;
  dialogoInfo = false;
  dialogoEliminar = false;
  cargando = false;
  fechas: Array<string> = [];
  estadosCurso = [
    { value: "aceptado", text: "Aceptado" },
    { value: "impartiendo", text: "Impartiendo" },
    { value: "finalizado", text: "Finalizado" },
  ];
  estadoCurso = "aceptado";

  get fechasMostrar(): string {
    return this.fechas
      .map((fecha) => fecha.toDate().format("DD-MM-YYYY"))
      .join(" ~ ");
  }

  set fechasMostrar(array: string) {
    this.fechas = [];
  }

  get inscripciones(): Inscripciones {
    return this.getInscripciones;
  }

  get items(): Array<Inscripcion> {
    return this.getArrayInscripciones.filter((inscripcion) => {
      let fechaInicio;
      let fechaFin;
      if (this.fechas.length > 0) {
        fechaInicio = `${this.fechas.firstOrNull} 00:00`.toDate();
        fechaFin = `${this.fechas.lastOrNull} 23:59`.toDate();
      }
      const fecha =
        this.fechas && fechaInicio && fechaFin
          ? inscripcion.fecha.toDate().getTime() >= fechaInicio.getTime() &&
            inscripcion.fecha.toDate().getTime() <= fechaFin.getTime()
          : true;
      const estadoCurso = Object.values(inscripcion.pago.cursos).some(
        (curso) => curso.estado === this.estadoCurso
      );
      return fecha && estadoCurso;
    });
  }

  get itemsRelator(): Array<Inscripcion> {
    return this.getArrayInscripciones.filter((inscripcion) => {
      const filtroRelator = Object.values(inscripcion.pago.cursos).some(
        (curso) => curso.relator.id === this.usuario.id
      );
      let fechaInicio;
      let fechaFin;
      if (this.fechas.length > 0) {
        fechaInicio = `${this.fechas.firstOrNull} 00:00`.toDate();
        fechaFin = `${this.fechas.lastOrNull} 23:59`.toDate();
      }
      const fecha =
        this.fechas && fechaInicio && fechaFin
          ? inscripcion.fecha.toDate().getTime() >= fechaInicio.getTime() &&
            inscripcion.fecha.toDate().getTime() <= fechaFin.getTime()
          : true;
      const estadoCurso = Object.values(inscripcion.pago.cursos).some(
        (curso) => curso.estado === this.estadoCurso
      );
      return filtroRelator && fecha && estadoCurso;
    });
  }

  get totalInscripciones(): number {
    return this.items.length;
  }

  get totalInscripcionesRelator(): number {
    return this.itemsRelator.length;
  }

  clickItem(val: { id: string } | null): void {
    this.inscripcion = val ? this.inscripciones[val.id] : null;
  }

  abrirDialogoInfo(inscripcion: Inscripcion): void {
    if (!inscripcion.id) {
      console.error("error id: ", inscripcion);
      return;
    }
    const doc = this.inscripciones[inscripcion.id];
    if (doc) {
      this.inscripcion = doc;
      this.dialogoInfo = true;
    }
  }

  cerrarDialogoInfo(): void {
    this.dialogoInfo = false;
    this.inscripcion = null;
  }

  abrirDialogoEliminar(inscripcion: Inscripcion): void {
    if (!inscripcion.id) {
      console.error("error id: ", inscripcion);
      return;
    }
    const doc = this.inscripciones[inscripcion.id];
    if (doc) {
      this.dialogoEliminar = true;
      this.inscripcion = doc;
    }
  }

  cerrarDialogoEliminar(): void {
    this.dialogoEliminar = false;
    this.inscripcion = null;
  }

  cerrarDialogoExito(): void {
    this.dialogoExito = {
      model: false,
      texto: "",
      icono: "",
    };
  }
}
