import { RouteConfig } from "vue-router";
import Ciudades from "@/views/Ciudades.vue";

const ciudades: RouteConfig = {
    path: "/ciudades",
    name: "ciudades",
    component: Ciudades,
};

export default ciudades;
