






















































import {Component, Emit, Prop, Vue} from "vue-property-decorator";
// helpers
// tipos
// validaciones
import {required} from "vuelidate/lib/validators";
import {Inscripcion} from "@/typings/store/plugins/easyFirestore/inscripciones";
import {Usuario} from "@/typings/store/plugins/easyFirestore/usuarios"
import {Pago } from "@/typings/store/plugins/easyFirestore/pagos"
import {DataFormularioInscripcion} from "@/typings/components/Inscripciones/formulario";

@Component({
  validations: {
    nombre: { required },
  },
})
export default class FormularioInscripciones extends Vue {
  @Prop() readonly inscripcion!: Inscripcion | null;
  @Prop() readonly arrayClientes!: Array<Usuario> ; 
  @Prop() readonly arrayPagos!: Array<Pago>;

  created(): void {
    const inscripcion = this.inscripcion;
    this.fecha = new Date().toISOString();
    if (!inscripcion) {
      return;
    }
    this.id = inscripcion.id ?? "";
    this.cliente=inscripcion.cliente;
    this.pago=inscripcion.pago;
    this.fecha= inscripcion.fecha;   
  }

  id: string | null = null;
  cliente: Usuario | null=null;
  pago: Pago | null=null;
  fecha= "";


  get titulo(): string {
    return this.inscripcion
        ? "editar inscripcion"
        : "nueva inscripcion";
  }

  get nombreError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.nombre.$dirty) return errors;
    if (!this.$v.nombre.required) errors.push("Campo requerido");
    return errors;
  }

  

  @Emit("close")
  onClose(): null {
    return null;
  }

  @Emit("cerrar-dialogo")
  onCerrarDialogo(): void {
    return;
  }

  @Emit("click-guardar")
  onClickGuardar(
      data: DataFormularioInscripcion
  ): DataFormularioInscripcion {
    return data;
  }

  guardar(): void {
    this.$v.$touch();
    if (this.$v.$invalid && !this.cliente) {
      return;
    }
    if(this.cliente && this.pago){
        const doc: Inscripcion = {
            cliente: this.cliente,
            pago: this.pago,
            fecha:this.fecha,
        };
        if (this.id) {
        doc.id = this.id;
        }
        const data: DataFormularioInscripcion = {
        doc: doc,
        };
        this.onClickGuardar(data);
    }
    
  }

}
