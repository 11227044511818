


























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































// decoradores
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
// vuex
import { mapGetters } from "vuex";
// componentes

import {
  //ColorPrenomina,
  EstadoPrenomina,
  Prenomina,
} from "@/typings/store/plugins/easyFirestore/prenominas";
import { Archivo } from "@/typings/store/modules/archivos";
import { ItemsTablaPrenominas } from "@/typings/components/prenominas/tabla";
import {
  Usuario,
  //TipoUsuario,
} from "@/typings/store/plugins/easyFirestore/usuarios";
import { fechaLocal } from "@/helpers/date";
import "@/helpers/extensions";
import VistaPreviaPdf from "@/components/VistaPreviaPdf.vue";
import { Orden } from "@/typings/store/plugins/easyFirestore/ordenes";

@Component({
  computed: mapGetters({
    getPrenominas: "prenominas/get",
  }),
  components: {
    VistaPreviaPdf,
  },
})
export default class TablaMapeo extends Vue {
  @Prop() readonly prenominas!: Array<Prenomina>;
  @Prop() readonly item!: ItemsTablaPrenominas;
  @Prop() readonly usuario?: Usuario;
  @Prop() readonly search!: string;
  @Prop() readonly ordenes!: Array<Orden>;

  ordenCentroCosto = 0;
  ordenRutProveedor = 0;
  ordenNombreProveedor = 0;
  ordenNumeroFactura = 0;
  ordenOrdenCompra = 0;
  ordenMontoPago = 0;
  ordenFiltros = 0;
  ordenTipoGasto = 0;
  ordenRecurrente = 0;
  ordenFechaVencimiento = 0;

  ascenteCentroCosto(): void {
    this.ordenCentroCosto += 1;
    this.ordenFiltros = 1;
  }

  descenteCentroCosto(): void {
    this.ordenCentroCosto -= 1;
    this.ordenFiltros = 1;
  }

  ascenteRutProveedor(): void {
    this.ordenRutProveedor += 1;
    this.ordenFiltros = 2;
  }

  descenteRutProveedor(): void {
    this.ordenRutProveedor -= 1;
    this.ordenFiltros = 2;
  }

  ascenteNombreProveedor(): void {
    this.ordenNombreProveedor += 1;
    this.ordenFiltros = 3;
  }

  descenteNombreProveedor(): void {
    this.ordenNombreProveedor -= 1;
    this.ordenFiltros = 3;
  }

  ascenteNumeroFactura(): void {
    this.ordenNumeroFactura += 1;
    this.ordenFiltros = 4;
  }

  descenteNumeroFactura(): void {
    this.ordenNumeroFactura -= 1;
    this.ordenFiltros = 4;
  }

  ascenteOrdenCompra(): void {
    this.ordenOrdenCompra += 1;
    this.ordenFiltros = 5;
  }

  descenteOrdenCompra(): void {
    this.ordenOrdenCompra -= 1;
    this.ordenFiltros = 5;
  }

  ascenteMontoPago(): void {
    this.ordenMontoPago += 1;
    this.ordenFiltros = 6;
  }

  descenteMontoPago(): void {
    this.ordenMontoPago -= 1;
    this.ordenFiltros = 6;
  }

  ascenteTipoGasto(): void {
    this.ordenTipoGasto += 1;
    this.ordenFiltros = 7;
  }

  descenteTipoGasto(): void {
    this.ordenTipoGasto -= 1;
    this.ordenFiltros = 7;
  }

  ascenteRecurrente(): void {
    this.ordenRecurrente += 1;
    this.ordenFiltros = 8;
  }

  descenteRecurrente(): void {
    this.ordenRecurrente -= 1;
    this.ordenFiltros = 8;
  }

  ascenteFechaVencimiento(): void {
    this.ordenFechaVencimiento += 1;
    this.ordenFiltros = 9;
  }

  descenteFechaVencimiento(): void {
    this.ordenFechaVencimiento -= 1;
    this.ordenFiltros = 9;
  }

  get itemsOrdenes(): Array<Orden> {
    return this.ordenes;
  }

  siEsCredito(texto: string): string {
    let formaPago = "";
    this.ordenes.forEach((orden) => {
      if (
        orden.ordenCompra === parseInt(texto) &&
        orden.formaPago === "CRÉDITO A 30 DÍAS"
      ) {
        //arrayNumeros.push(prenomina.numeroPrenominas);
        //CRÉDITO A 30 DÍAS
        formaPago = orden.formaPago;
      }
    });
    return formaPago;
    //return "NO";
  }

  muestraMes(text: string): string {
    const mesConsultado = text.toDate();
    const añoConsutado = text.substr(6, 10);
    const meses = [
      "ENERO",
      "FEBRERO",
      "MARZO",
      "ABRIL",
      "MAYO",
      "JUNIO",
      "JULIO",
      "AGOSTO",
      "SEPTIEMBRE",
      "OCTUBRE",
      "NOVIEMBRE",
      "DICIEMBRE",
    ];
    return meses[mesConsultado.getMonth()] + " " + añoConsutado;
  }

  traeComprobanteDeTrabajo(texto: string): Archivo | null {
    let comprobante: Archivo | null = null;

    this.ordenes.forEach((orden) => {
      if (orden.ordenCompra === parseInt(texto)) {
        if (orden.tipoArchivo === "COMPROBANTE FOTOGRAFICO DE TRABAJO") {
          if (orden.archivo !== undefined) {
            comprobante = Object.values(orden.archivo)[0];
          }
        }
        if (orden.tipoArchivo2 === "COMPROBANTE FOTOGRAFICO DE TRABAJO") {
          if (orden.archivo2 !== undefined) {
            comprobante = Object.values(orden.archivo2)[0];
          }
        }
        if (orden.tipoArchivo3 === "COMPROBANTE FOTOGRAFICO DE TRABAJO") {
          if (orden.archivo3 !== undefined) {
            comprobante = Object.values(orden.archivo3)[0];
          }
        }
        if (orden.tipoArchivo4 === "COMPROBANTE FOTOGRAFICO DE TRABAJO") {
          if (orden.archivo4 !== undefined) {
            comprobante = Object.values(orden.archivo4)[0];
          }
        }
        if (orden.tipoArchivo5 === "COMPROBANTE FOTOGRAFICO DE TRABAJO") {
          if (orden.archivo5 !== undefined) {
            comprobante = Object.values(orden.archivo5)[0];
          }
        }
        if (orden.tipoArchivo6 === "COMPROBANTE FOTOGRAFICO DE TRABAJO") {
          if (orden.archivo6 !== undefined) {
            comprobante = Object.values(orden.archivo6)[0];
          }
        }
      }
    });
    return comprobante;
  }

  traeOrdenDeCompra(texto: string): Archivo | null {
    let ordenDeCompra: Archivo | null = null;

    this.ordenes.forEach((orden) => {
      if (orden.ordenCompra === parseInt(texto)) {
        if (orden.tipoArchivo === "ORDEN DE COMPRA - SOFTLAND") {
          if (orden.archivo !== undefined) {
            ordenDeCompra = Object.values(orden.archivo)[0];
          }
        }
        if (orden.tipoArchivo2 === "ORDEN DE COMPRA - SOFTLAND") {
          if (orden.archivo2 !== undefined) {
            ordenDeCompra = Object.values(orden.archivo2)[0];
          }
        }
        if (orden.tipoArchivo3 === "ORDEN DE COMPRA - SOFTLAND") {
          if (orden.archivo3 !== undefined) {
            ordenDeCompra = Object.values(orden.archivo3)[0];
          }
        }
        if (orden.tipoArchivo4 === "ORDEN DE COMPRA - SOFTLAND") {
          if (orden.archivo4 !== undefined) {
            ordenDeCompra = Object.values(orden.archivo4)[0];
          }
        }
        if (orden.tipoArchivo5 === "ORDEN DE COMPRA - SOFTLAND") {
          if (orden.archivo5 !== undefined) {
            ordenDeCompra = Object.values(orden.archivo5)[0];
          }
        }
        if (orden.tipoArchivo6 === "ORDEN DE COMPRA - SOFTLAND") {
          if (orden.archivo6 !== undefined) {
            ordenDeCompra = Object.values(orden.archivo6)[0];
          }
        }
      }
    });
    return ordenDeCompra;
  }

  traeCotizacion(texto: string): Archivo | null {
    let cotizacion: Archivo | null = null;

    this.ordenes.forEach((orden) => {
      if (orden.ordenCompra === parseInt(texto)) {
        if (orden.tipoArchivo === "COTIZACIÓN O PRESUPUESTO") {
          if (orden.archivo !== undefined) {
            cotizacion = Object.values(orden.archivo)[0];
          }
        }
        if (orden.tipoArchivo2 === "COTIZACIÓN O PRESUPUESTO") {
          if (orden.archivo2 !== undefined) {
            cotizacion = Object.values(orden.archivo2)[0];
          }
        }
        if (orden.tipoArchivo3 === "COTIZACIÓN O PRESUPUESTO") {
          if (orden.archivo3 !== undefined) {
            cotizacion = Object.values(orden.archivo3)[0];
          }
        }
        if (orden.tipoArchivo4 === "COTIZACIÓN O PRESUPUESTO") {
          if (orden.archivo4 !== undefined) {
            cotizacion = Object.values(orden.archivo4)[0];
          }
        }
        if (orden.tipoArchivo5 === "COTIZACIÓN O PRESUPUESTO") {
          if (orden.archivo5 !== undefined) {
            cotizacion = Object.values(orden.archivo5)[0];
          }
        }
        if (orden.tipoArchivo6 === "COTIZACIÓN O PRESUPUESTO") {
          if (orden.archivo6 !== undefined) {
            cotizacion = Object.values(orden.archivo6)[0];
          }
        }
      }
    });
    return cotizacion;
  }

  defineColorAtraso(texto: string): string {
    let color = "";
    if (texto === undefined) {
      return "";
    } else {
      if (parseInt(texto) < 15) {
        color = "green";
      }
      if (parseInt(texto) >= 15 && parseInt(texto) <= 30) {
        color = "yellow";
      }
      if (parseInt(texto) > 30) {
        color = "red";
      }
    }
    return color;
  }

  calculaDias(texto: string | undefined): string {
    let fecha = "";
    let fechaInvertida = "";
    const fechaActual = fechaLocal();
    //si texto === undifined
    if (texto === undefined) {
      return "";
    } else {
      fecha = texto;
      //pasar texto de formato dd-mm-aaaa a aaaa-mm-dd
      const fechaArray = fecha.split("-");
      const fechaArrayInvertido = fechaArray.reverse();
      fechaInvertida = fechaArrayInvertido.join("-");
    }

    //calcula los dias entre fechaActual y fechaInvertida
    const fecha1 = new Date(fechaInvertida);
    const fecha2 = new Date(fechaActual);
    const diffTime = Math.abs(fecha2.getTime() - fecha1.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays.toString();

    // return fechaInvertida;
  }

  AlertaDias(texto: string): string {
    let mensaje = "";

    if (texto === undefined) {
      return "";
    } else {
      if (parseInt(texto) < 15) {
        mensaje = "NORMAL";
      }
      if (parseInt(texto) >= 15 && parseInt(texto) <= 30) {
        mensaje = "ATRASADO!";
      }
      if (parseInt(texto) > 30) {
        mensaje = "URGENTE!";
      }
    }

    return mensaje;
  }

  get items(): ItemsTablaPrenominas {
    return this.prenominas
      .map((prenomina) => {
        const id = prenomina.id;
        const centroCosto = prenomina.centroCosto;
        const numeroPrenominas = prenomina.numeroPrenominas;
        const ingresada = prenomina.ingresada;
        const modificado = prenomina.modificado;
        const fPago = prenomina.formaPago;
        const rut = prenomina.rut;
        const nombreProveedor = prenomina.nombreProveedor;
        const nFactura = prenomina.nFactura;
        const ordenCompra = prenomina.ordenCompra;
        const tipoGasto = prenomina.tipoGasto;
        //const tipoCambio = prenomina.tipoCambio;
        const montoOC = (
          parseInt(prenomina.montoOC, 10).toCurrency() ?? ""
        ).toString();
        const porcentajePagado = prenomina.porcentajePagado;
        const montoSaldo = prenomina.montoSaldo;
        const montoAP = prenomina.montoAP;
        const observacion = prenomina.observacion;
        const estado = prenomina.estado;
        const fechaAprobacion = prenomina.fechaAprobacion;
        const autorizado = prenomina.autorizado;
        const ordenaRut = prenomina.rut.replace(/\.+/g, "");
        const recurrente = prenomina.recurrente;
        const fechaVencimiento = prenomina.fechaVencimiento;
        const periodo = prenomina.periodo;

        return {
          id,
          centroCosto,
          modificado,
          fPago,
          numeroPrenominas,
          ingresada,
          rut,
          nombreProveedor,
          nFactura,
          ordenCompra,
          tipoGasto,
          montoOC,
          porcentajePagado,
          montoSaldo,
          montoAP,
          observacion,
          estado,
          fechaAprobacion,
          autorizado,
          ordenaRut,
          recurrente,
          fechaVencimiento,
          periodo,

          data: prenomina,
        };
      })
      .filter((prenomina) => prenomina.ingresada !== true)
      .sort((sesionA, sesionB) => {
        if (this.ordenFiltros === 1) {
          if (this.ordenCentroCosto === 0) {
            return (
              parseInt(sesionA.centroCosto) - parseInt(sesionB.centroCosto)
            );
          }
          if (this.ordenCentroCosto === 1) {
            return (
              parseInt(sesionB.centroCosto) - parseInt(sesionA.centroCosto)
            );
          }
        }
        if (this.ordenFiltros === 2) {
          if (this.ordenRutProveedor === 0) {
            return parseInt(sesionA.ordenaRut) - parseInt(sesionB.ordenaRut);
          }
          if (this.ordenRutProveedor === 1) {
            return parseInt(sesionB.ordenaRut) - parseInt(sesionA.ordenaRut);
          }
        }

        if (this.ordenFiltros === 3) {
          if (this.ordenNombreProveedor === 0) {
            return sesionA.nombreProveedor.localeCompare(
              sesionB.nombreProveedor
            );
          }
          if (this.ordenNombreProveedor === 1) {
            return sesionB.nombreProveedor.localeCompare(
              sesionA.nombreProveedor
            );
          }
        }
        if (this.ordenFiltros === 4) {
          if (this.ordenNumeroFactura === 0) {
            return parseInt(sesionA.nFactura) - parseInt(sesionB.nFactura);
          }
          if (this.ordenNumeroFactura === 1) {
            return parseInt(sesionB.nFactura) - parseInt(sesionA.nFactura);
          }
        }
        if (this.ordenFiltros === 5) {
          if (this.ordenOrdenCompra === 0) {
            return (
              parseInt(sesionA.ordenCompra) - parseInt(sesionB.ordenCompra)
            );
          }
          if (this.ordenOrdenCompra === 1) {
            return (
              parseInt(sesionB.ordenCompra) - parseInt(sesionA.ordenCompra)
            );
          }
        }
        if (this.ordenFiltros === 6) {
          if (this.ordenMontoPago === 0) {
            return parseInt(sesionA.montoAP) - parseInt(sesionB.montoAP);
          }
          if (this.ordenMontoPago === 1) {
            return parseInt(sesionB.montoAP) - parseInt(sesionA.montoAP);
          }
        }
        if (this.ordenFiltros === 7) {
          if (this.ordenTipoGasto === 0) {
            return sesionA.tipoGasto.localeCompare(sesionB.tipoGasto);
          }
          if (this.ordenTipoGasto === 1) {
            return sesionB.tipoGasto.localeCompare(sesionA.tipoGasto);
          }
        }
        if (this.ordenFiltros === 8) {
          if (this.ordenRecurrente === 0) {
            return sesionA.recurrente.localeCompare(sesionB.recurrente);
          }
          if (this.ordenRecurrente === 1) {
            return sesionB.recurrente.localeCompare(sesionA.recurrente);
          }
        }
        // if (this.ordenFiltros === 9) {
        //   if (this.ordenFechaVencimiento === 0) {
        //     //si fecha de vencimiento distinto de undifinded
        //     if (sesionA.fechaVencimiento !== undefined && sesionB.fechaVencimiento !== undefined) {
        //       return sesionA.fechaVencimiento.localeCompare(sesionB.fechaVencimiento);
        //     }

        //   }
        //   if (this.ordenFechaVencimiento === 1) {
        //     if (sesionA.fechaVencimiento !== undefined && sesionB.fechaVencimiento !== undefined) {
        //       return sesionB.fechaVencimiento.localeCompare(sesionA.fechaVencimiento);
        //     }
        //   }
        // }
        //       let a = sesionA.split('-').reverse().join('');
        //       let b = sesionB.split('-').reverse().join('');
        //return sesionA.localeCompare(sesionB);
        return parseInt(sesionA.rut) - parseInt(sesionB.rut);
      });
  }

  get smAndDown(): boolean {
    return this.$vuetify.breakpoint.smAndDown;
  }
  get fechaAprobacion(): string {
    let aprobada = fechaLocal().toString();
    let array_fecha = aprobada.split("-");
    let dia = array_fecha[2];
    let mes = array_fecha[1];
    let ano = array_fecha[0];
    console.log(array_fecha);
    let fecha = dia + "-" + mes + "-" + ano;

    console.log(fecha);
    return fecha;
  }

  actualizarEstadoApruebo(item: Prenomina): void {
    let estado: EstadoPrenomina;
    //let color: ColorPrenomina;

    if (this.usuario) {
      item.autorizado = this.usuario?.rut;
      console.log(item.autorizado);
    }
    console.log(this.usuario?.tipo);
    if (this.usuario) {
      if (this.usuario.tipo === "admin") {
        console.log("TENGO PERMISO");
        item.estado === "pendiente"
          ? (estado = "aprobado")
          : (estado = "pendiente");
        item.estado = estado;
        if (estado === "aprobado") {
          item.color = "success";
        }
        if (estado === "pendiente") {
          item.color = "#D5D8E3";
        }
      }
      if (this.usuario.tipo === "supervisor") {
        console.log("soy supervisor");
        item.estado === "pendiente"
          ? (estado = "revisado")
          : (estado = "pendiente");
        item.estado = estado;
        if (estado === "revisado") {
          item.color = "warning";
        }
        if (estado === "pendiente") {
          item.color = "#D5D8E3";
        }
      }
    }

    // if(estado ==="rechazado"){}
    // if(estado ==="revisado"){}

    item.fechaAprobacion = this.fechaAprobacion;
    this.onCambiarEstado(item);
  }

  actualizarEstado(item: Prenomina): void {
    //let estado: EstadoPrenomina;

    if (this.usuario) {
      item.autorizado = this.usuario?.rut;
      console.log(item.autorizado);
    }
    console.log(this.usuario?.tipo);
  }

  actualizarEstadoRevisado(item: Prenomina): void {
    let estado: EstadoPrenomina;

    if (this.usuario) {
      item.autorizado = this.usuario?.rut;
      console.log(item.autorizado);
    }

    item.estado === "pendiente"
      ? (estado = "revisado")
      : (estado = "pendiente");
    item.estado = estado;
    item.fechaAprobacion = this.fechaAprobacion;
    this.onCambiarEstado(item);
  }

  actualizarEstadoRechazo(item: Prenomina): void {
    let estado: EstadoPrenomina;

    if (this.usuario) {
      item.autorizado = this.usuario?.rut;
      console.log(item.autorizado);
    }

    item.estado === "pendiente"
      ? (estado = "rechazado")
      : (estado = "pendiente");
    item.estado = estado;
    if (estado === "rechazado") {
      item.color = "red";
    }
    if (estado === "pendiente") {
      item.color = "#D5D8E3";
    }
    item.fechaAprobacion = this.fechaAprobacion;
    this.onCambiarEstadoRechazo(item);
  }

  //   actualizarFiltroCentroCosto(): string {
  //   let estado: EstadoPrenomina;

  //   if (this.usuario) {
  //     item.autorizado = this.usuario?.rut;
  //     console.log(item.autorizado);
  //   }

  //   item.estado === "pendiente"
  //     ? (estado = "aprobado")
  //     : (estado = "pendiente");
  //   item.estado = estado;
  //   item.fechaAprobacion = this.fechaAprobacion;
  //   this.onCambiarEstado(item);
  // }

  @Emit("cambiar-estado")
  onCambiarEstado(prenomina: Prenomina): Prenomina {
    return prenomina;
  }

  @Emit("cambiar-estado-rechazo")
  onCambiarEstadoRechazo(prenomina: Prenomina): Prenomina {
    return prenomina;
  }

  @Emit("abrir-info")
  onAbrirInfo(prenomina: Prenomina): Prenomina {
    return prenomina;
  }

  @Emit("abrir-editar")
  onAbrirEditar(prenomina: Prenomina): Prenomina {
    return prenomina;
  }

  @Emit("abrir-eliminar")
  onAbrirEliminar(prenomina: Prenomina): Prenomina {
    return prenomina;
  }

  @Emit("abrir-comentario")
  onAbrirComentario(prenomina: Prenomina): Prenomina {
    return prenomina;
  }

  abrirVistaPrevia(archivo: Archivo | null): void {
    if (archivo === null) return;
    else {
      this.vistaPrevia = {
        model: true,
        url: this.urlArchivo(archivo),
      };
    }
  }

  urlArchivo(media: Archivo): string {
    return media.url ?? URL.createObjectURL(media.file);
  }

  vistaPrevia = {
    model: false,
    url: "",
  };
  cerrarVistaPrevia(): void {
    this.vistaPrevia = {
      model: false,
      url: "",
    };
  }

  prenomina: Prenomina | null = null;
  dialogoInfo = false;
  dialogoFormulario = false;
  dialogoEliminar = false;
  dialogoExito = {
    model: false,
    texto: "",
    icono: "",
  };
}
