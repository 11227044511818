












































































































































































// decoradores
import {Component, Emit, Prop, Vue} from "vue-property-decorator";
// tipos
import {HeadersDataTable} from "@/typings/vuetify/vDataTable";
import {EstadoUsuario, Usuario} from "@/typings/store/plugins/easyFirestore/usuarios";
import {ItemsTablaUsuarios} from "@/typings/components/usuarios/tabla";
import "@/helpers/extensions";

@Component
export default class TablaUsuarios extends Vue {
  @Prop() readonly opciones!: boolean;
  @Prop() readonly search!: string;
  @Prop() readonly usuarios!: Array<Usuario>;

  options = {
    itemsPerPage: -1,
  };
  headerProps = {
    sortIcon: "mdi-chevron-down"
  }

  get headers(): HeadersDataTable {
    return [
      {text: "", value: "estado", sortable: false},
      {text: "Nombre", value: "nombre"},
      {text: "RUT", value: "rut"},
      {text: "Tipo", value: "tipo"},
      {text: "E-mail", value: "email"},
      {text: "Fecha", value: "fecha"},
      {text: "", value: "opciones", sortable: false}
    ];
  }

  get items(): ItemsTablaUsuarios {
    return this.usuarios.map((usuario) => {
      const nombre = usuario.nombre;
      const rut = usuario.rut;
      const email = usuario.email;
      const tipo = usuario.tipo;
      const fecha = usuario.fecha?.toDate().format("DD/MM/YYYY") ?? "";
      return {
        nombre,
        rut,
        email,
        tipo,
        fecha,
        data: usuario,
      };
    });
  }

  actualizarEstado(item: Usuario): void {
    let estado: EstadoUsuario;
    item.estado === 'libre' ? estado = 'bloqueado' : estado = 'libre';
    item.estado = estado;
    this.onCambiarEstado(item);
  }

  @Emit("cambiar-estado")
  onCambiarEstado(usuario: Usuario): Usuario {
    return usuario;
  }

  @Emit("abrir-info")
  onAbrirInfo(usuario: Usuario): Usuario {
    return usuario;
  }

  @Emit("abrir-editar")
  onAbrirEditar(usuario: Usuario): Usuario {
    return usuario;
  }

  @Emit("abrir-eliminar")
  onAbrirEliminar(usuario: Usuario): Usuario {
    return usuario;
  }
}
