


























































































































































// decoradores
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
// tipos
import { HeadersDataTable } from "@/typings/vuetify/vDataTable";
import { Solicitud } from "@/typings/store/plugins/easyFirestore/solicitudes";
import { ItemsTablaSolicitudes } from "@/typings/components/solicitudes/tabla";
import "@/helpers/extensions";

@Component
export default class TablaUsuarios extends Vue {
  @Prop() readonly opciones!: boolean;
  @Prop() readonly search!: string;
  @Prop() readonly solicitudes!: Array<Solicitud>;

  options = {
    itemsPerPage: 15,
  };
  headerProps = {
    sortIcon: "mdi-chevron-down",
  };

  get headers(): HeadersDataTable {
    return [
      //{text: "", value: "estado", sortable: false},
      { text: "Estado", value: "estados", width: "5" },
      { text: "Nombre Solicitud", value: "nombre", width: "150" },
      { text: "C.C.", value: "centroCosto", width: "200" },
      { text: "Proveedor", value: "empresa", width: "150" },
      { text: "Rut Proveedor", value: "rut", width: "150" },
      { text: "Fecha Emisión", value: "fechaEmision", width: "150" },
      { text: "Total", value: "total", width: "100" },
      { text: "Observación", value: "observaciones", width: "150" },
      { text: "", value: "opciones", width: "150", sortable: false },
    ];
  }

  get items(): ItemsTablaSolicitudes {
    return this.solicitudes.map((solicitud) => {
      const nombre = solicitud.nombre;
      const estado = solicitud.estado;
      const centroCosto = solicitud.centroCosto;
      const empresa = solicitud.empresa;
      const rut = solicitud.rut;
      const fechaEmision = solicitud.fechaEmision;
      const total = solicitud.total.toCurrency({ symbol: "$", fixed: 0 });
      const observaciones = solicitud.observaciones;
      return {
        nombre,
        estado,
        centroCosto,
        empresa,
        rut,
        fechaEmision,
        total,
        observaciones,

        data: solicitud,
      };
    });
  }

  obtenerColor(estado: string): string {
    switch (estado) {
      case "aprobado":
        return "success";
      case "pendiente":
        return "warning";
      case "rechazado":
        return "red";
      default:
        return "valor inválido";
    }
  }

  obtenerColorTenue(estado: string): string {
    switch (estado) {
      case "aprobado":
        return "rgba(39, 155, 24, 0.4)";
      case "pendiente":
        return "rgba(255, 191, 0, 0.4)";
      case "rechazado":
        return "rgba(255, 0, 0, 0.4)";
      default:
        return "valor inválido";
    }
  }

  @Emit("abrir-info")
  onAbrirInfo(solicitud: Solicitud): Solicitud {
    return solicitud;
  }

  @Emit("abrir-editar")
  onAbrirEditar(solicitud: Solicitud): Solicitud {
    return solicitud;
  }

  @Emit("abrir-aprobar")
  onAbrirAprobar(solicitud: Solicitud): Solicitud {
    return solicitud;
  }

  @Emit("abrir-eliminar")
  onAbrirEliminar(solicitud: Solicitud): Solicitud {
    return solicitud;
  }

  @Emit("genera-pdf")
  onGenerarPdf(solicitud: Solicitud): Solicitud {
    return solicitud;
  }
}
