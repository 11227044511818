const fechaLocal = (fecha = new Date()): string => {
    return (
      fecha.getFullYear().toString() +
      "-" +
      (fecha.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      fecha
        .getDate()
        .toString()
        .padStart(2, "0")
    );
  };
  
  const horaLocal = (fecha = new Date()): string => {
    return (
      fecha
        .getHours()
        .toString()
        .padStart(2, "0") +
      ":" +
      fecha
        .getMinutes()
        .toString()
        .padStart(2, "0") +
      ":" +
      fecha
        .getSeconds()
        .toString()
        .padStart(2, "0")
    );
  };
  
  const horaLocalCorta = (fecha = new Date()): string => {
    return (
      fecha
        .getHours()
        .toString()
        .padStart(2, "0") +
      ":" +
      fecha
        .getMinutes()
        .toString()
        .padStart(2, "0")
    );
  };
  
  const meses = (fechaInicio = new Date(), fechaFin = new Date()): number => {
    return (
      (fechaFin.valueOf() - fechaInicio.valueOf()) /
      (1000 * 60 * 60 * 24 * 30.4375)
    );
  };
  
  const date = {
    fechaLocal,
    horaLocal,
    meses,
  };
  
  export default date;
  
  export { fechaLocal, horaLocal, meses, horaLocalCorta };
  