















// decoradores
import { Component, Vue } from "vue-property-decorator";

@Component
export default class MensajeEliminar extends Vue {}
