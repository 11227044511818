



































import {Component, Vue, Prop, Emit, Watch} from "vue-property-decorator";
import { mapActions, mapGetters } from "vuex";
import {
  Carrito,
  CursoCarrito,
} from "@/typings/store/plugins/easyFirestore/carritos";
import Detalle from "@/components/carrito/Detalle.vue";
import {Pago, Pagos} from "@/typings/store/plugins/easyFirestore/pagos";
import { Usuario } from "@/typings/store/plugins/easyFirestore/usuarios";
import {Curso, Cursos} from "@/typings/store/plugins/easyFirestore/cursos";
import { Sesion } from "@/typings/store/plugins/easyFirestore/sesiones";
import { Promocion } from "@/typings/store/plugins/easyFirestore/promociones";
import {Inscripcion} from "@/typings/store/plugins/easyFirestore/inscripciones";
import DialogoExito from "@/components/DialogoExito.vue";

@Component({
  components: {DialogoExito, Detalle },
  methods: mapActions({
    setPago: "pagos/set",
    setCarrito: "carritos/set",
    deleteCarrito: "carritos/delete",
  }),
  computed: mapGetters({
    getCarritos: "carritos/get",
    getCursos: "cursos/get",
    getPagos: "pagos/get",
    getArrayCarritos: "carritos/getArray",
    getArraySesiones: "sesiones/getArray",
    getArrayInscripciones: "inscripciones/getArray",
    getArrayCursos: "cursos/getArray",
    getArrayPromociones: "promociones/getArray",
  }),
})
export default class VistaCarritoCliente extends Vue {
  @Prop() readonly usuario!: Usuario;

  cargando = false;
  idPagoActual = "";
  dialogDetalleCarrito = false;
  dialogoExito = {
    model: false,
    icono: "",
    texto: ""
  };

  created(): void {
    console.log(this.usuario);
    let carrito: Carrito | null = this.carrito;
    if (!carrito && this.usuario) {
      carrito = {
        id: this.usuario.id,
        cursos: {},
        sesiones: {},
        usuario: this.usuario,
        estado: "revisado",
        promocion: null
      };
      this.$store.dispatch("carritos/set", carrito);
    }
  }

  get pagoActual(): Pago {
    return this.pagos[this.idPagoActual];
  }

  get pagos(): Pagos {
    return this.getPagos;
  }

  get cursos(): Cursos {
    return this.getCursos;
  }

  get arrayPromociones(): Promocion[] {
    return this.getArrayPromociones;
  }

  get arraySesiones(): Sesion[] {
    return this.getArraySesiones;
  }

  get arrayCursos(): Curso[] {
    return this.getArrayCursos;
  }

  get arrayInscripciones(): Inscripcion[] {
    return this.getArrayInscripciones;
  }

  get carrito(): Carrito | null {
    return this.usuario ? this.getCarritos[this.usuario.id] : null;
  }

  @Watch("pagoActual")
  onChangePagoActual(pago: Pago): void {
    if(pago.url && pago.token){
      const formulario = this.$refs.formulario as HTMLFormElement;
      const input = formulario['token_ws'] as HTMLInputElement;
      input.value = pago.token;
      formulario.submit();
    }
  }

  @Emit("abrir-login")
  onAbrirDialogoLogin(): void {
    return;
  }

  eliminarCurso(curso: Curso): void {
    const carrito = Object.assign({}, this.carrito);
    this.deleteCarrito(`${carrito.id}.cursos.${curso.id}`);
  }

  eliminarSesion(sesion: Sesion): void {
    const carrito = Object.assign({}, this.carrito);
    this.deleteCarrito(`${carrito.id}.sesiones.${sesion.id}`);
  }

  addCurso(curso: Curso): void {
    const carrito = Object.assign({}, this.carrito);
    if (carrito && curso && curso.id) {
      const cursoCarrito: CursoCarrito = {
        id: curso.id,
        curso: curso,
        estado: "pendiente",
      };
      carrito.estado = "revisado";
      this.$set(carrito?.cursos, curso.id, cursoCarrito);
      this.setCarrito(carrito);
    }
  }

  addPack(cursos: Cursos): void {
    const inscripcionesUsuario = this.arrayInscripciones.filter(inscripcion => inscripcion.cliente.id === this.usuario.id);
    const carrito = Object.assign({}, this.carrito);
    for(let curso of Object.values(cursos)){
      if(curso.id){
        curso = this.cursos[curso.id];
        const cursoObtenido = inscripcionesUsuario.some(inscripcionUsuario => Object.values(inscripcionUsuario.pago.cursos).some(cursoInscripcion => cursoInscripcion.id === curso.id))
        const cursoRepetido = Object.values(this.carrito?.cursos ?? {}).some(cursoCarrito => cursoCarrito.id === curso.id);
        if (carrito && curso && curso.id && !cursoObtenido && !cursoRepetido) {
          const cursoCarrito: CursoCarrito = {
            id: curso.id,
            curso: curso,
            estado: "pendiente",
          };
          carrito.estado = "revisado"
          this.$set(carrito?.cursos, curso.id, cursoCarrito);
        }
      }
    }
    this.setCarrito(carrito);
  }

  async guardarPago(pago: Pago): Promise<void> {
    this.cargando = true;
    this.idPagoActual = await this.setPago(pago);
    this.dialogDetalleCarrito = false;
    this.dialogoExito = {
      model: true,
      icono: require('../assets/icons/double-check-duo.svg'),
      texto: "El curso se habilitará cuando se procese el pago"
    };
  }

    guardarPromocion(promo: Promocion): void {
    const carrito = Object.assign({}, this.carrito, {
      promocion: promo,
    });
    this.setCarrito(carrito);
  }

  abrirDetalleCarrito(): void {
    if (this.usuario) {
      this.dialogDetalleCarrito = true;
    } else {
      this.onAbrirDialogoLogin();
    }
  }

  cerrarDialogoExito(): void{
    this.dialogoExito = {
      model: false,
      icono: "",
      texto: ""
    };
  }
}
