






































































































































































































// decoradores
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
// // tipos
import { HeadersDataTable } from "@/typings/vuetify/vDataTable";
// import {
//   EstadoUsuario,
//   Usuario,
// } from "@/typings/store/plugins/easyFirestore/usuarios";
import { RegistrosEgresos } from "@/typings/store/plugins/easyFirestore/registrosEgresos";
// import { ItemsTablaUsuarios } from "@/typings/components/usuarios/tabla";
import { ItemsTablaRegistrosEgresos } from "@/typings/components/registrosEgresos/tabla";
import { Usuario } from "@/typings/store/plugins/easyFirestore/usuarios";

import "@/helpers/extensions";

@Component
export default class TablaRegistrosEgresos extends Vue {
  //@Prop() readonly opciones!: boolean;
  @Prop() readonly search!: string;
  @Prop() readonly egresos!: Array<RegistrosEgresos>;
  @Prop() readonly usuario?: Usuario;
  options = {
    itemsPerPage: 15,
  };
  headerProps = {
    sortIcon: "mdi-chevron-down",
  };
  get headers(): HeadersDataTable {
    return [
      //     { text: "", value: "estado", sortable: false },
      { text: "Pago Efectuado", value: "PAGADO" },
      { text: "Nomina", value: "N_NOMINA" },
      { text: "Fecha Egreso", value: "FECHA_EGRESO" },
      { text: "Periodo", value: "PERIODO" },
      { text: "Rut Proveedor", value: "RUT_PROVEEDOR" },
      { text: "Nombre Proveedor", value: "NOMBRE_PROVEEDOR" },
      // { text: "Cuenta Estado Resultado", value: "CTA_ESTADO_RESULTADO" },
      // { text: "Cuenta Control Gasto", value: "CTA_CONTROL_GASTO" },
      { text: "Tipo Gasto", value: "TIPO_GASTO" },
      { text: "Monto Egreso", value: "MONTO_EGRESO" },
      { text: "Orden Compra", value: "ORDEN_DE_COMPRA" },
      { text: "Factura", value: "FAC_DOC_NOTA" },
      { text: "CENTRO COSTO", value: "CENTRO_DE_COSTO" },
      { text: "CUENTA", value: "DESCRIPCION_CUENTA" },
      { text: "N CLIENTE", value: "N_CLIENTE" },
      { text: "GLOSA", value: "GLOSA" },

      { text: "", value: "opciones", sortable: false },
    ];
  }

  get items(): ItemsTablaRegistrosEgresos {
    return this.egresos.map((egreso) => {
      // //nombre: string;
      const N_NOMINA = egreso.N_NOMINA;
      const PERIODO = egreso.PERIODO;
      const CENTRO_DE_COSTO = egreso.CENTRO_DE_COSTO;
      const DESCRIPCION_CUENTA = egreso.DESCRIPCION_CUENTA;
      const CODIGO_CUENTA = egreso.CODIGO_CUENTA;
      const BANCO = egreso.BANCO;
      const ORDEN_DE_COMPRA = egreso.ORDEN_DE_COMPRA;
      const FECHA_EGRESO = egreso.FECHA_EGRESO;
      const TIPO_EGRESO = egreso.TIPO_EGRESO;
      const MONTO_EGRESO = (
        parseInt(egreso.MONTO_EGRESO, 10).toCurrency({ symbol: "$" }) ?? ""
      ).toString();
      const GLOSA = egreso.GLOSA;
      const RUT_PROVEEDOR = egreso.RUT_PROVEEDOR;
      const NOMBRE_PROVEEDOR = egreso.NOMBRE_PROVEEDOR;
      const PAGO_C_P = egreso.PAGO_C_P;
      const PORC_DEL_TOTAL = egreso.PORC_DEL_TOTAL;
      const NOMINA_PAGO = egreso.NOMINA_PAGO;
      const TIPO_DE_DOCUMENTO = egreso.TIPO_DE_DOCUMENTO;
      const N_DOCUMENTO = egreso.N_DOCUMENTO;
      const FAC_DOC_NOTA = egreso.FAC_DOC_NOTA;
      const CTA_ESTADO_RESULTADO = egreso.CTA_ESTADO_RESULTADO;
      const CTA_CONTROL_GASTO = egreso.CTA_CONTROL_GASTO;
      const TIPO_GASTO = egreso.TIPO_GASTO;
      const PAGADO = egreso.PAGADO;
      const N_CLIENTE = egreso.N_CLIENTE;
      return {
        N_NOMINA,
        PERIODO,
        CENTRO_DE_COSTO,
        DESCRIPCION_CUENTA,
        CODIGO_CUENTA,
        BANCO,
        ORDEN_DE_COMPRA,
        FECHA_EGRESO,
        TIPO_EGRESO,
        MONTO_EGRESO,
        GLOSA,
        RUT_PROVEEDOR,
        NOMBRE_PROVEEDOR,
        PAGO_C_P,
        PORC_DEL_TOTAL,
        NOMINA_PAGO,
        TIPO_DE_DOCUMENTO,
        N_DOCUMENTO,
        FAC_DOC_NOTA,
        CTA_ESTADO_RESULTADO,
        CTA_CONTROL_GASTO,
        TIPO_GASTO,
        PAGADO,
        N_CLIENTE,
        data: egreso,
      };
    });
  }

  @Emit("abrir-info")
  onAbrirInfo(registroEgreso: RegistrosEgresos): RegistrosEgresos {
    return registroEgreso;
  }
  @Emit("abrir-editar")
  onAbrirEditar(registroEgreso: RegistrosEgresos): RegistrosEgresos {
    return registroEgreso;
  }
  @Emit("abrir-eliminar")
  onAbrirEliminar(registroEgreso: RegistrosEgresos): RegistrosEgresos {
    return registroEgreso;
  }
}
