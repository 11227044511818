import { RouteConfig } from "vue-router";
import Pagos from "@/views/Pagos.vue";

const pagos: RouteConfig = {
    path: "/pagos",
    name: "pagos",
    component: Pagos,
};

export default pagos;
