import { RouteConfig } from "vue-router";
import Ordenes from "@/views/Ordenes.vue";

const ordenes: RouteConfig = {
    path: "/ordenes",
    name: "ordenes",
    component: Ordenes,
};

export default ordenes;
