

















































// decoradores
import { Vue, Component } from "vue-property-decorator";
// vuex
import { mapGetters } from "vuex";
// tipos

// componentes
import TablaNotificaciones from "@/components/notificaciones/Tabla.vue";
import InfoNotificacion from "@/components/notificaciones/Info.vue";
import MensajeEliminar from "@/components/MensajeEliminar.vue";
import TituloVista from "@/components/TituloVista.vue";
import {
  Notificacion,
  Notificaciones,
} from "@/typings/store/plugins/easyFirestore/notificaciones";

const routeGuard = (vm: Vue): boolean => {
  const permisos = vm.getUsuario?.permisos;
  if (permisos && permisos["notificaciones"]) {
    return true;
  }

  const rutas = vm.getRutasUsuario;
  if (rutas.length > 0) {
    const to = rutas[0].to;
    if (to) {
      if (vm.$route.name !== to.name) {
        vm.$router.replace(to);
      }
      return false;
    }
  }
  if (vm.$route.name !== "inicio") {
    vm.$router.replace({ name: "inicio" });
  }
  return false;
};

@Component({
  computed: mapGetters({
    getNotificaciones: "notificaciones/get",
    getArrayNotificaciones: "notificaciones/getArray",
    getUsuario: "usuario/get",
    getRutasUsuario: "usuario/getRutas",
  }),
  beforeRouteEnter(to, from, next) {
    next(routeGuard);
  },
  components: {
    TablaNotificaciones,
    InfoNotificacion,
    MensajeEliminar,
    TituloVista,
  },
})
export default class VistaNotificaciones extends Vue {
  created(): void {
    if (!routeGuard(this)) {
      return;
    }
  }

  search = "";
  dialogoInfo = false;
  notificacion: Notificacion | null = null;

  get notificaciones(): Notificaciones {
    return this.getNotificaciones;
  }

  get items(): Array<Notificacion> {
    return this.getArrayNotificaciones.filter((notificacion) => {
      if (this.getUsuario?.tipo === "admin") {
        return (
          (notificacion.usuario === null &&
            (notificacion.topico === "admins" ||
              notificacion.topico === "todos")) ||
          notificacion.usuario?.id === this.getUsuario?.id
        );
      } else if (this.getUsuario?.tipo === "relator") {
        return (
          (notificacion.usuario === null &&
            (notificacion.topico === "relatores" ||
              notificacion.topico === "todos")) ||
          notificacion.usuario?.id === this.getUsuario?.id
        );
      } else if (this.getUsuario?.tipo === "cliente") {
        return (
          (notificacion.usuario === null &&
            (notificacion.topico === "clientes" ||
              notificacion.topico === "todos")) ||
          notificacion.usuario?.id === this.getUsuario?.id
        );
      }
    });
  }

  abrirDialogoInfo(notificacion: Notificacion): void {
    if (!notificacion.id) {
      console.error("error id: ", notificacion);
      return;
    }
    const doc = this.notificaciones[notificacion.id];
    if (doc) {
      this.notificacion = doc;
      this.dialogoInfo = true;
    }
  }

  cerrarDialogoInfo(): void {
    this.dialogoInfo = false;
    this.notificacion = null;
  }
}
