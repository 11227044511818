
















































































































































































// decoradores
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
// tipos
import { HeadersDataTable } from "@/typings/vuetify/vDataTable";
import { DistribuidorEnergia } from "@/typings/store/plugins/easyFirestore/distribuidoresEnergia";
import { ItemsTablaDistribuidoresEnergia } from "@/typings/components/distribuidoresEnergia/tabla";
import "@/helpers/extensions";

@Component
export default class TablaUsuarios extends Vue {
  @Prop() readonly opciones!: boolean;
  @Prop() readonly search!: string;
  @Prop() readonly distribuidoresEnergia!: Array<DistribuidorEnergia>;

  options = {
    itemsPerPage: -1,
  };
  headerProps = {
    sortIcon: "mdi-chevron-down",
  };

  get headers(): HeadersDataTable {
    return [
      //{text: "", value: "estado", sortable: false},
      { text: "Empresa Decreto Tarifario", value: "empresaDecretoTarifario" },
      { text: "Comuna", value: "comuna" },
      { text: "Sector Tarifario", value: "sectorTarifario" },
      { text: "Aéreo o Subterraneo", value: "aereoOsubterraneo" },
      { text: "", value: "opciones", sortable: false },
    ];
  }

  get items(): ItemsTablaDistribuidoresEnergia {
    return this.distribuidoresEnergia.map((distribuidorEnergia) => {
      const empresaDecretoTarifario =
        distribuidorEnergia.empresaDecretoTarifario;
      const comuna = distribuidorEnergia.comuna;
      const sectorTarifario = distribuidorEnergia.sectorTarifario;
      const aereoOsubterraneo = distribuidorEnergia.aereoOsubterraneo;

      return {
        empresaDecretoTarifario,
        comuna,
        sectorTarifario,
        aereoOsubterraneo,

        data: distribuidorEnergia,
      };
    });
  }

  //   actualizarEstado(item: Usuario): void {
  //     let estado: EstadoUsuario;
  //     item.estado === 'libre' ? estado = 'bloqueado' : estado = 'libre';
  //     item.estado = estado;
  //     this.onCambiarEstado(item);
  //   }

  //   @Emit("cambiar-estado")
  //   onCambiarEstado(usuario: Usuario): Usuario {
  //     return usuario;
  //   }

  @Emit("abrir-info")
  onAbrirInfo(distribuidorEnergia: DistribuidorEnergia): DistribuidorEnergia {
    return distribuidorEnergia;
  }

  @Emit("abrir-editar")
  onAbrirEditar(distribuidorEnergia: DistribuidorEnergia): DistribuidorEnergia {
    return distribuidorEnergia;
  }

  @Emit("abrir-eliminar")
  onAbrirEliminar(
    distribuidorEnergia: DistribuidorEnergia
  ): DistribuidorEnergia {
    return distribuidorEnergia;
  }
}
