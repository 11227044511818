








































































































































// decoradores
import { Vue, Component } from "vue-property-decorator";
// vuex
import { mapActions, mapGetters } from "vuex";
// tipos

// componentes
import TablaLectura from "@/components/lecturas/Tabla.vue";
import FormularioLectura from "@/components/lecturas/Formulario.vue";
import { DataFormularioLectura } from "@/typings/components/lecturas/formulario";
import DialogoExito from "@/components/DialogoExito.vue";
import DialogoEliminar from "@/components/DialogoEliminar.vue";
import InfoLectura from "@/components/lecturas/Info.vue";
//import BotonesCrud from "@/components/BotonesCrud.vue";
import MensajeEliminar from "@/components/MensajeEliminar.vue";
//import TituloVista from "@/components/TituloVista.vue";
import {
  Lectura,
  Lecturas,
} from "@/typings/store/plugins/easyFirestore/lecturas";

// import {
//   Tarifa,
//   Tarifas,
// } from "@/typings/store/plugins/easyFirestore/tarifas";

import CargarBancosExcel from "@/components/excel/CargarBancos.vue";

const routeGuard = (vm: Vue): boolean => {
  const permisos = vm.getUsuario?.permisos;
  if (permisos && permisos["lecturas"]) {
    return true;
  }

  const rutas = vm.getRutasUsuario;
  if (rutas.length > 0) {
    const to = rutas[0].to;
    if (to) {
      if (vm.$route.name !== to.name) {
        vm.$router.replace(to);
      }
      return false;
    }
  }
  if (vm.$route.name !== "inicio") {
    vm.$router.replace({ name: "inicio" });
  }
  return false;
};

@Component({
  computed: mapGetters({
    getUsuarios: "usuarios/get",
    getLecturas: "lecturas/get",
    getMedidores: "medidores/get",
    getTarifas: "tarifas/get",
    getArrayUsuarios: "usuarios/getArray",
    getUsuario: "usuario/get",
    getRutas: "rutas/get",
    getRutasUsuario: "usuario/getRutas",
    getArrayLecturas: "lecturas/getArray",
    getArrayMedidores: "medidores/getArray",
    getArrayTarifas: "tarifas/getArray",
    getArrayCentrosCostos: "centrosCostos/getArray",

  }),
  methods: mapActions({
    setLectura: "lecturas/set",
    deleteLectura: "lecturas/delete",
    insertBatchLecturas: "lecturas/insertBatch",
    //setArchivo: "archivos/set",
    //deleteArchivo: "archivos/delete",
  }),
  beforeRouteEnter(to, from, next) {
    next(routeGuard);
  },
  components: {
    DialogoEliminar,
    DialogoExito,
    TablaLectura,
    FormularioLectura,
    InfoLectura,
    //BotonesCrud,
    MensajeEliminar,
    CargarBancosExcel,
    //TituloVista,
  },
})
export default class VistaBancos extends Vue {
  created(): void {
    if (!routeGuard(this)) {
      return;
    }
  }

  cargando = false;
  dialogoExito = {
    model: false,
    texto: "",
    icono: "",
  };
  search = "";
  lectura: Lectura | null = null;
  dialogoInfo = false;
  dialogoFormulario = false;
  dialogoEliminar = false;
  textoEliminar = "¿ESTÁS SEGURO QUE DESEAS ELIMINAR ESTE BANCO?";
  dialogoCargarExcel = false;

  get lecturas(): Lecturas {
    return this.getLecturas;
  }

  get items(): Array<Lectura> {
    return this.getArrayLecturas;
  }
  

//  get itemsMedidores(): Array<Medidor> {
//     return this.getArrayMedidores;
//   }


  clickItem(val: { id: string } | null): void {
    this.lectura = val ? this.lecturas[val.id] : null;
  }

  clickPlus(): void {
    this.lectura = null;
    this.dialogoFormulario = true;
  }

  async clickGuardar(data: DataFormularioLectura): Promise<void> {
    this.cargando = true;
    const doc = data.doc;
    await this.setLectura(doc);
    if (doc.id != this.lectura?.id) {
      this.dialogoExito = {
        model: true,
        texto: "¡Lectura creada con éxito!",
        icono: require("../assets/icons/double-check-duo.svg"),
      };
    }
    if (doc.id == this.lectura?.id) {
      this.dialogoExito = {
        model: true,
        texto: "¡Lectura editada con éxito!",
        icono: require("../assets/icons/editar.svg"),
      };
      this.lectura = doc;
    }
    this.cerrarDialogoFormulario();
    this.cargando = false;
  }

  cerrarDialogoExito(): void {
    this.dialogoExito = {
      model: false,
      texto: "",
      icono: "",
    };
  }

  clickEliminar(): void {
    const lectura = this.lectura;
    if (!lectura?.id) {
      console.error("error id: ", lectura);
      return;
    }
    this.deleteLectura(lectura.id);
    this.lectura = null;
    this.cerrarDialogoEliminar();
  }

  abrirDialogoInfo(lectura: Lectura): void {
    if (!lectura.id) {
      console.error("error id: ", lectura);
      return;
    }
    const doc = this.lecturas[lectura.id];
    if (doc) {
      this.lectura = doc;
      this.dialogoInfo = true;
    }
  }

  cerrarDialogoInfo(): void {
    this.dialogoInfo = false;
    this.lectura = null;
  }

  abrirDialogoFormulario(lectura: Lectura): void {
    if (!lectura.id) {
      console.error("error id: ", lectura);
      return;
    }
    const doc = this.lecturas[lectura.id];
    if (doc) {
      this.lectura = doc;
      this.dialogoFormulario = true;
    }
  }

  abrirDialogoEliminar(lectura: Lectura): void {
    if (!lectura.id) {
      console.error("error id: ", lectura);
      return;
    }
    const doc = this.lecturas[lectura.id];
    if (doc) {
      this.dialogoEliminar = true;
      this.lectura = doc;
    }
  }

  cerrarDialogoEliminar(): void {
    this.dialogoEliminar = false;
    this.lectura = null;
  }

  cerrarDialogoFormulario(): void {
    this.dialogoFormulario = false;
    this.lectura = null;
  }
}
