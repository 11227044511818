













































































































































































































































































































import { Component, Emit, Vue } from "vue-property-decorator";
import { mapActions, mapGetters } from "vuex";
import { Curso, Cursos } from "@/typings/store/plugins/easyFirestore/cursos";
import "@/helpers/extensions";
import { Sesion } from "@/typings/store/plugins/easyFirestore/sesiones";
import {
  Carrito,
  CursoCarrito,
  SesionCarrito,
} from "@/typings/store/plugins/easyFirestore/carritos";
import formularioEncuesta from "@/components/cursos/formularioEncuesta.vue";
import SVGIcon from "@/components/SVGIcon.vue";
import { Usuario } from "@/typings/store/plugins/easyFirestore/usuarios";
import { DataFormularioEncuesta } from "@/typings/components/encuestas/formulario";
import Reproductor from "@/views/Reproductor.vue";
import { VideoVisto } from "@/typings/store/plugins/easyFirestore/videosVistos";
import DialogoExito from "@/components/DialogoExito.vue";
import {
  Encuestas,
  Encuesta,
} from "@/typings/store/plugins/easyFirestore/encuestas";
import { Pago, Pagos } from "@/typings/store/plugins/easyFirestore/pagos";
import Formulario from "@/components/login/Formulario.vue";
import { SesionProgramada } from "@/typings/store/plugins/easyFirestore/sesionesProgramadas";
@Component({
  components: {
    Formulario,
    DialogoExito,
    Reproductor,
    SVGIcon,
    formularioEncuesta,
  },
  computed: mapGetters({
    getEncuestas: "encuestas/get",
    getArrayEncuestas: "encuestas/getArray",
    getRutasUsuario: "usuario/getRutas",
    getArraySesiones: "sesiones/getArray",
    getCursos: "cursos/get",
    getUsuario: "usuario/get",
    getCarritos: "carritos/get",
    getSesiones: "sesiones/get",
    getPagos: "pagos/get",
    getArrayPagos: "pagos/getArray",
    getArrayInscripciones: "inscripciones/getArray",
    getVideosVistos: "videosVistos/get",
    getArraySesionesProgramadas: "sesionesProgramadas/getArray",
  }),
  methods: mapActions({
    setCarrito: "carritos/set",
    setEncuesta: "encuestas/set",
  }),
})
export default class InformacionCompletaCurso extends Vue {
  created(): void {
    this.cursoId = this.$route.params?.id ?? "";
    if (!this.cursoId && this.$route.name !== "misCursos") {
      this.$router.push({
        name: "misCursos",
      });
    }
  }

  cursoId = "";
  encuesta: Encuesta | null = null;
  dialogoExito = {
    model: false,
    icono: "",
    texto: "",
  };

  get nuevaEncuesta(): boolean {
    let booleano = true;
    if (this.getArrayEncuestas.length != 0 && this.usuario && this.curso) {
      this.getArrayEncuestas.forEach((encuesta) => {
        booleano = encuesta.estado !== "existente";
      });
    }
    return booleano;
  }

  get encuestas(): Encuestas {
    return this.getEncuestas;
  }

  get usuario(): Usuario | null {
    return this.getUsuario;
  }

  get pagos(): Pagos {
    return this.getPagos;
  }

  get arrayPagos(): Pago[] {
    return this.getArrayPagos;
  }

  get cursos(): Cursos {
    return this.getCursos;
  }

  get carrito(): Carrito | null {
    return this.getCarritos[this.usuario?.id ?? ""];
  }

  get curso(): Curso {
    return this.cursos[this.cursoId];
  }

  get disabledLoQuiero(): boolean {
    const cliente = !this.usuario ? true : this.usuario?.tipo === "cliente";
    const cursoEnElCarrito = Object.values(this.carrito?.cursos ?? {}).some(
      (producto) => producto.curso.id === this.curso.id
    );
    const inscripciones = this.getArrayInscripciones
      .filter((inscripcion) => inscripcion.cliente.id === this.usuario?.id)
      .some((inscripcion) =>
        Object.values(inscripcion.pago.cursos).some(
          (cursso) => cursso.id === this.curso.id
        )
      );
    return !cliente || cursoEnElCarrito || inscripciones;
  }

  get comprado(): boolean {
    return this.getArrayInscripciones.some((inscripcion) =>
      Object.values(inscripcion.pago.cursos).some(
        (curso) => curso.id === this.curso.id
      )
    );
  }

  get urlImagen(): string {
    return Object.values(this.curso.imagen).firstOrNull?.url ?? "";
  }

  get urlImagenRelator(): string {
    return (
      Object.values(this.curso.relator.imagen ?? {}).firstOrNull?.url ||
      "https://secure.gravatar.com/avatar/4a487a74f37e80048a5e9446113d8db7?s=500&d=mm&r=g"
    );
  }

  get sesionesCurso(): Array<Sesion> {
    return this.getArraySesiones
      .filter((sesion) => sesion.curso.id === this.curso.id)
      .sort((sesion1, sesion2) => sesion1.numero - sesion2.numero);
  }

  get totalDuracion(): number {
    return (
      this.sesionesCurso.reduce((sum, sesion) => sum + sesion.duracion, 0) / 60
    );
  }

  get promedioDuracion(): string {
    const result = this.totalDuracion / this.sesionesCurso.length;
    return isNaN(result)
      ? "0"
      : result % 1 === 0
      ? result.toString()
      : result.toFixed(1);
  }

  get videosVistosCurso(): Array<VideoVisto> {
    return Object.values(this.getVideosVistos).filter((videoVisto) =>
      this.sesionesCurso.some((sesion) => sesion.id === videoVisto.id)
    );
  }

  get ultimoVideoVisto(): VideoVisto | null {
    const videosVistos = this.videosVistosCurso.sort(
      (videoVisto1, videoVisto2) =>
        videoVisto2.fecha.toDate().getTime() -
        videoVisto1.fecha.toDate().getTime()
    );
    return videosVistos.firstOrNull;
  }

  get tiempoUltimoVideoVisto(): string {
    const tiempo = this.ultimoVideoVisto?.tiempo ?? 0;
    const minutos = Math.floor(tiempo / 60)
      .toString()
      .padStart(2, "0");
    const segundos = Math.floor(tiempo % 60)
      .toString()
      .padStart(2, "0");
    return `${minutos}:${segundos}`;
  }

  get sesionUltimoVideoVisto(): Sesion | null {
    return (
      this.sesionesCurso.find(
        (sesion) => sesion.id === this.ultimoVideoVisto?.id
      ) ?? null
    );
  }

  @Emit("abrir-iniciar-sesion")
  onAbrirIniciarSesion(): void {
    return;
  }

  onVolver(): void {
    if (this.$route.name !== "misCursos") {
      this.$router.push({
        name: "misCursos",
      });
    }
  }

  onLoQuiero(): void {
    if (!this.usuario) {
      this.onAbrirIniciarSesion();
      return;
    }
    if (this.carrito) {
      const carrito = Object.assign({}, this.carrito);
      if (!this.curso.id) {
        console.error("cursoSeleccionado no tiene id");
        return;
      }
      const cursoCarrito: CursoCarrito = {
        id: this.curso.id,
        curso: this.curso,
        estado: "pendiente",
      };
      if (!cursoCarrito || !cursoCarrito.curso.id) {
        console.error("error curso", cursoCarrito);
        return;
      }
      carrito.estado = "revisado";
      this.$set(carrito.cursos, cursoCarrito.curso.id, cursoCarrito);
      this.setCarrito(carrito);
      this.dialogoExito = {
        model: true,
        icono: require("../assets/icons/double-check-duo.svg"),
        texto: "Curso agregado al carrito",
      };
    }
  }

  onLoQuieroSesion(sesion: Sesion): void {
    if (!this.usuario) {
      this.onAbrirIniciarSesion();
      return;
    }
    if (this.carrito) {
      const carrito = Object.assign({}, this.carrito);
      if (!sesion.id) {
        console.error("sesionSeleccionada no tiene id");
        return;
      }
      const sesionCarrito: SesionCarrito = {
        id: sesion.id,
        sesion: sesion,
        estado: "pendiente",
      };
      if (!sesionCarrito || !sesionCarrito.sesion.id) {
        console.error("error session", sesionCarrito);
        return;
      }
      carrito.estado = "revisado";
      this.$set(carrito.sesiones, sesionCarrito.sesion.id, sesionCarrito);
      this.setCarrito(carrito);
      this.dialogoExito = {
        model: true,
        icono: require("../assets/icons/double-check-duo.svg"),
        texto: "Clase agregada al carrito",
      };
    }
  }

  getSesionProgramada(sesion: Sesion): SesionProgramada | null {
    return (
      this.getArraySesionesProgramadas.find(
        (sesionProgramada) => sesionProgramada.sesion.id === sesion.id
      ) ?? null
    );
  }

  disabledLoQuieroSesion(sesion: Sesion): boolean {
    const cliente = !this.usuario ? true : this.usuario?.tipo === "cliente";
    const sesiones = Object.values(this.carrito?.sesiones ?? {}).some(
      (session) => session.sesion.id === sesion.id
    );
    const cursos = Object.values(this.carrito?.cursos ?? {}).some(
      (curso) => curso.curso.id === sesion.curso.id
    );
    const inscripciones = this.getArrayInscripciones
      .filter((inscripcion) => inscripcion.cliente.id === this.usuario?.id)
      .some((inscripcion) =>
        Object.values(inscripcion.pago.sesiones).some(
          (session) => session.id === sesion.id
        )
      );
    return !cliente || sesiones || cursos || inscripciones;
  }

  clickGuardar(data: DataFormularioEncuesta): void {
    const doc = data.doc;
    this.setEncuesta(doc);
    if (doc.id != this.encuesta?.id) {
      this.dialogoExito = {
        model: true,
        icono: require("../assets/icons/double-check-duo.svg"),
        texto: "Has enviado la encuesta con éxito",
      };
    }
    if (doc.id == this.encuesta?.id) {
      this.dialogoExito = {
        model: true,
        icono: require("../assets/icons/double-check-duo.svg"),
        texto: "Has modificado la encuesta con éxito",
      };
    }
  }

  continuarViendo(): void {
    const sid = this.sesionUltimoVideoVisto?.id ?? "";
    const tiempo = this.ultimoVideoVisto?.tiempo?.toString() ?? "0";
    if (
      this.$route.name !== "reproductor" ||
      this.$route.params.sid !== sid ||
      this.$route.params.tiempo !== tiempo
    ) {
      this.$router.push({
        name: "reproductor",
        params: {
          sid: sid,
          tiempo: tiempo,
        },
      });
    }
  }

  verSesion(sesion: Sesion): void {
    if (sesion.estado === "grabada") {
      const sid = sesion.id ?? "";
      if (
        this.$route.name !== "reproductor" ||
        this.$route.params.sid !== sid
      ) {
        this.$router.push({
          name: "reproductor",
          params: {
            sid: sid,
          },
        });
      }
    } else {
      const sesionProgramada = this.getSesionProgramada(sesion);
      if (sesionProgramada) window.open(sesionProgramada.link, "_blank");
    }
  }

  mostrarTextoSesion(sesion: Sesion): boolean {
    const sesionProgramada = this.getSesionProgramada(sesion);
    if (!sesionProgramada) return false;
    const fecha = sesionProgramada.fecha
      .split("-")
      .reverse()
      .join("-");
    const fechaInicio = new Date(`${fecha} ${sesionProgramada.horaInicio}`);
    const fechaActual = new Date();
    return fechaInicio >= fechaActual;
  }

  sesionBloqueada(sesion: Sesion): boolean {
    if (sesion.estado === "en-vivo") {
      const sesionProgramada = this.getSesionProgramada(sesion);
      if (!sesionProgramada) return false;
      const fecha = sesionProgramada.fecha
        .split("-")
        .reverse()
        .join("-");
      const fechaInicio = new Date(`${fecha} ${sesionProgramada.horaInicio}`);
      const fechaFin = new Date(`${fecha} ${sesionProgramada.horaFin}`);
      const fechaActual = new Date();
      return fechaInicio >= fechaActual || fechaFin <= fechaActual;
    }
    const videoSesion = this.getVideosVistos[sesion.id ?? ""];
    if (videoSesion) return false;
    const sesionAnterior = this.sesionesCurso.find(
      (sesion1) => sesion1.numero === sesion.numero - 1
    );
    if (sesionAnterior?.id) {
      const videoVisto = this.getVideosVistos[sesionAnterior.id];
      if (videoVisto) {
        const diferencia = videoVisto.duracion - videoVisto.tiempo;
        return sesion.numero > 1 && diferencia > 60;
      }
    }
    return sesion.numero > 1;
  }

  cerrarDialogoExito(): void {
    this.dialogoExito = {
      model: false,
      icono: "",
      texto: "",
    };
  }
}
