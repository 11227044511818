




























































































































































































// decoradores
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { CondicionDivision } from "@/typings/store/plugins/easyFirestore/condicionesDivisiones";
import SVGIcon from "@/components/SVGIcon.vue";
// tipos

// tipos
@Component({
  components: {
    SVGIcon,
  },
})
export default class InfoCondicionDivision extends Vue {
  @Prop() readonly condicionDivision!: CondicionDivision;
  @Prop({ type: Boolean }) readonly showTitulo!: boolean;

  get id(): string {
    return this.condicionDivision.id || "N/R";
  }

  get codigo_division(): number {
    return this.condicionDivision.codigo_division || 0;
  }

  get nombre_division(): string {
    return this.condicionDivision.nombre_division || "N/R";
  }

  get moneda(): string {
    return this.condicionDivision.moneda || "N/R";
  }

  get escala(): string {
    return this.condicionDivision.escala || "N/R";
  }

  get indexacion(): string {
    return this.condicionDivision.indexacion || "N/R";
  }

  get minimo(): number {
    return this.condicionDivision.minimo || 0;
  }

  get aporte(): string {
    return this.condicionDivision.aporte || "N/R";
  }

  get operador(): string {
    return this.condicionDivision.operador || "N/R";
  }

  get origen(): string {
    return this.condicionDivision.origen || "N/R";
  }

  get codigo_contrato(): number {
    return this.condicionDivision.codigo_contrato || 0;
  }

  get codigo_condicion(): number {
    return this.condicionDivision.codigo_condicion || 0;
  }

  @Emit("cerrar")
  onCerrar(): void {
    return;
  }
}
