









































































// decoradores
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { CajaChica } from "@/typings/store/plugins/easyFirestore/cajaChicas";
import SVGIcon from "@/components/SVGIcon.vue";
// tipos

// tipos
@Component({
  components: {
    SVGIcon,
  },
})
export default class InfoCajaChica extends Vue {
  @Prop() readonly cajaChica!: CajaChica;
  @Prop({ type: Boolean }) readonly showTitulo!: boolean;

  get id(): string {
    return this.cajaChica.id || "N/R";
  }

  get numero(): string {
    return this.cajaChica.numero.toString();
  }

  get fecha(): string {
    return this.cajaChica.fecha || "N/R";
  }

  get rutProveedor(): string {
    return this.cajaChica.rutProveedor || "N/R";
  }

  get nombreProveedor(): string {
    return this.cajaChica.nombreProveedor || "N/R";
  }
    
  get documento(): string {
    return this.cajaChica.documento || "N/R";
  }

  get importe(): string {
    return this.cajaChica.ingreso || this.cajaChica.egreso|| "";
  }



  @Emit("cerrar")
  onCerrar(): void {
    return;
  }
}
