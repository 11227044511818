













import { Component, Vue, Prop } from "vue-property-decorator";
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import { TDocumentDefinitions } from "pdfmake/interfaces";
import { Curso } from "@/typings/store/plugins/easyFirestore/cursos";
import { Usuario } from "@/typings/store/plugins/easyFirestore/usuarios";

const pdfmaker = pdfMake;
pdfmaker.vfs = pdfFonts.pdfMake.vfs;

@Component
export default class GeneraPDF extends Vue {
  @Prop() readonly curso!: Curso;
  @Prop() readonly usuario!: Usuario;

  async clickGeneraInforme(): Promise<void> {
    const nombre = this.usuario.nombre.toUpperCase();
    const curso = this.curso.nombre.toUpperCase();
    const fechaFormateada = this.curso.fin.toDate();
    // Logo
    const link = { url: require("@/assets/logo_salhus.png") };
    const result = await fetch(link.url);
    const result2 = await result.blob();
    const base64: string = await new Promise((callback) => {
      let reader = new FileReader();
      reader.onload = function() {
        callback(this.result?.toString() ?? "");
      };
      reader.readAsDataURL(result2);
    });

    // Imagen de fondo
    const link2 = { url: require("@/assets/prueba1.jpg") };
    const resultImagen = await fetch(link2.url);
    const resultImagen2 = await resultImagen.blob();
    const base64Imagen: string = await new Promise((callback) => {
      let reader = new FileReader();
      reader.onload = function() {
        callback(this.result?.toString() ?? "");
      };
      reader.readAsDataURL(resultImagen2);
    });

    const certificadoPDF: TDocumentDefinitions = {
      pageOrientation: "landscape",
      header: {
        margin: [50, 30, 50, 0],

        columns: [
          {
            image: "logo",
            alignment: "right",
            height: 64,
            //width: 100,
          },
        ],
      },

      content: [
        // { image: "logo", opacity: 0.5 },

        { text: "Certificado de Finalización de Curso\n\n", style: "header" },
        "Con este diploma se reconoce que:\n",

        { text: nombre, style: "textoRescatado" },
        "\n",
        "Ha finalizado correctamente la totalidad de las lecciones de nuestro curso",
        "\n",
        { text: curso, style: "textoRescatado" },
        "\n",
        "En la fecha:",
        fechaFormateada.format("DD de MMMM del YYYY"),
      ],
      background: {
        image: "imagenFondo",
        width: 600,
        opacity: 0.5,
        // absolutePosition: {
        //   x: 40,
        //   y: 600,
        // },
      },
      images: {
        logo: base64,
        imagenFondo: base64Imagen,
      },
      styles: {
        header: {
          color: "#0d0a39",
          fontSize: 28,
          bold: true,
          margin: [0, 80, 0, 10],
        },
        subheader: {
          color: "#0d0a39",
          fontSize: 22,
          bold: true,
          margin: [0, 10, 0, 5],
        },
        textoRescatado: {
          color: "#ea5143",
          fontSize: 22,
          bold: true,
          margin: [0, 10, 0, 5],
        },
      },
      defaultStyle: {
        color: "#0d0a39",
        fontSize: 16,
        alignment: "center",
      },
    };

    const pdf = pdfmaker.createPdf(certificadoPDF);

    pdf.getBlob((blob) => {
      window.open(window.URL.createObjectURL(blob), "_blank");
    });
  }
}
