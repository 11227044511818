























































































































































// decoradores
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { Prenomina } from "@/typings/store/plugins/easyFirestore/prenominas";
import SVGIcon from "@/components/SVGIcon.vue";
// tipos

// tipos
@Component({
  components: {
    SVGIcon,
  },
})
export default class InfoPrenomina extends Vue {
  @Prop() readonly prenomina!: Prenomina;
  @Prop({ type: Boolean }) readonly showTitulo!: boolean;

  get centroCosto(): string {
    return this.prenomina.centroCosto || "N/R";
  }

    get periodo(): string {
    return this.prenomina.periodo || "N/R";
  }

    get formaPago(): string {
    return this.prenomina.formaPago || "N/R";
  }

    get id(): number {
    return this.prenomina.id || 0;
  }

  get estado(): string {
    return this.prenomina.estado || "N/R";
  }

  get observacion(): string {
    return this.prenomina.observacion || "N/R";
  }

  get rut(): string {
    return this.prenomina.rut || "N/R";
  }

  get nombreProveedor(): string {
    return this.prenomina.nombreProveedor || "N/R";
  }

  get nFactura(): string {
    return this.prenomina.nFactura || "N/R";
  }

  get ordenCompra(): string {
    return this.prenomina.ordenCompra || "N/R";
  }

  get montoOC(): string {
    return (
      parseInt(this.prenomina.montoOC, 10).toCurrency({ symbol: "$" }) ?? "N/R"
    ).toString();
  }

  get porcentajePagado(): string {
    return this.prenomina.porcentajePagado || "N/R";
  }

  get montoSaldo(): string {
    return (
      parseInt(this.prenomina.montoSaldo, 10).toCurrency({ symbol: "$" }) ??
      "N/R"
    ).toString();
  }
  get montoAP(): string {
    return (
      parseInt(this.prenomina.montoAP, 10).toCurrency({ symbol: "$" }) ?? "N/R"
    ).toString();
  }

  @Emit("cerrar")
  onCerrar(): void {
    return;
  }
}
