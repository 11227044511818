



















































































































// decoradores
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { CodCuenta } from "@/typings/store/plugins/easyFirestore/codCuentas";
import SVGIcon from "@/components/SVGIcon.vue";
// tipos

// tipos
@Component({
  components: {
    SVGIcon,
  },
})
export default class InfoBanco extends Vue {
  @Prop() readonly CodCuenta!: CodCuenta;
  @Prop({ type: Boolean }) readonly showTitulo!: boolean;

  get id(): string {
    return this.CodCuenta.id || "N/R";
  }

  get codigo(): string {
    return this.CodCuenta.PCCODI || "N/R";
  }

  get nivel(): string {
    return this.CodCuenta.PCNIVEL || "N/R";
  }

  get lnivel(): string {
    return this.CodCuenta.PCLNIVEL || "N/R";
  }

  get descripcion(): string {
    return this.CodCuenta.PCDESC || "N/R";
  }

  get codigo2(): string {
    return this.CodCuenta.PCCODI2 || "N/R";
  }

  @Emit("cerrar")
  onCerrar(): void {
    return;
  }
}
