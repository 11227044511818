import { RouteConfig } from "vue-router";
import Prenominas from "@/views/Prenominas.vue";

const prenominas: RouteConfig = {
    path: "/prenominas",
    name: "prenominas",
    component: Prenominas,
};

export default prenominas;
