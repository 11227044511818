



































































// decoradores
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { Inscripcion } from "@/typings/store/plugins/easyFirestore/inscripciones";
import SVGIcon from "@/components/SVGIcon.vue";
import InLinePago from "@/components/pagos/InLine.vue";
// tipos
@Component({
  components: {
    SVGIcon,
    InLinePago,
  },
})
export default class InfoInscripcion extends Vue {
  @Prop() readonly inscripcion!: Inscripcion;
  @Prop({ type: Boolean }) readonly showTitulo!: boolean;

  get id(): string {
    return this.inscripcion.id || "N/R";
  }

  get nombre(): string {
    return this.inscripcion.cliente.nombre || "N/R";
  }

  get fecha(): string {
    return this.inscripcion.fecha.toDate().format("DD-MM-YYYY") || "N/R";
  }

  @Emit("cerrar")
  onCerrar(): void {
    return;
  }
}
