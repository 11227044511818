
















































































































































































// decoradores
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
// tipos
import { HeadersDataTable } from "@/typings/vuetify/vDataTable";
import { Vacancia } from "@/typings/store/plugins/easyFirestore/vacancias";
import { ItemsTablaVacancias } from "@/typings/components/vacancias/tabla";
import "@/helpers/extensions";

@Component
export default class TablaUsuarios extends Vue {
  @Prop() readonly opciones!: boolean;
  @Prop() readonly search!: string;
  @Prop() readonly vacancias!: Array<Vacancia>;

  options = {
    itemsPerPage: -1,
  };
  headerProps = {
    sortIcon: "mdi-chevron-down",
  };

  get headers(): HeadersDataTable {
    return [
      //{text: "", value: "estado", sortable: false},
      { text: "Centro Costo", value: "centroCosto" },
      { text: "Direccion", value: "direccion" },
      { text: "Local", value: "local" },
      { text: "Superficie", value: "superficie" },
          { text: "Ocupado", value: "ocupado" },
      { text: "Precio UF", value: "precioUf" },
      { text: "", value: "opciones", sortable: false },
    ];
  }

  get items(): ItemsTablaVacancias {
    return this.vacancias.map((vacancia) => {
      const centroCosto = vacancia.centroCosto;
      const direccion = vacancia.direccion;
      const local = vacancia.local;
      const superficie = vacancia.superficie;
      const ocupado = vacancia.ocupado;
      const precioUf = vacancia.precioUf;


      return {
        centroCosto,
        direccion,
        local,
        superficie,
        ocupado,
        precioUf,

        data: vacancia,
      };
    });
  }

  //   actualizarEstado(item: Usuario): void {
  //     let estado: EstadoUsuario;
  //     item.estado === 'libre' ? estado = 'bloqueado' : estado = 'libre';
  //     item.estado = estado;
  //     this.onCambiarEstado(item);
  //   }

  //   @Emit("cambiar-estado")
  //   onCambiarEstado(usuario: Usuario): Usuario {
  //     return usuario;
  //   }

  @Emit("abrir-info")
  onAbrirInfo(vacancia: Vacancia): Vacancia {
    return vacancia;
  }

  @Emit("abrir-editar")
  onAbrirEditar(vacancia: Vacancia): Vacancia {
    return vacancia;
  }

  @Emit("abrir-eliminar")
  onAbrirEliminar(vacancia: Vacancia): Vacancia {
    return vacancia;
  }
}
