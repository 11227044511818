









































































// decoradores
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { CajaChicaLinares } from "@/typings/store/plugins/easyFirestore/cajaChicasLinares";
import SVGIcon from "@/components/SVGIcon.vue";
// tipos

// tipos
@Component({
  components: {
    SVGIcon,
  },
})
export default class InfoCajaChicaLinares extends Vue {
  @Prop() readonly cajaChicaLinares!: CajaChicaLinares;
  @Prop({ type: Boolean }) readonly showTitulo!: boolean;

  get id(): string {
    return this.cajaChicaLinares.id || "N/R";
  }

  get numero(): string {
    return this.cajaChicaLinares.numero.toString();
  }

  get fecha(): string {
    return this.cajaChicaLinares.fecha || "N/R";
  }

  get rutProveedor(): string {
    return this.cajaChicaLinares.rutProveedor || "N/R";
  }

  get nombreProveedor(): string {
    return this.cajaChicaLinares.nombreProveedor || "N/R";
  }
    
  get documento(): string {
    return this.cajaChicaLinares.documento || "N/R";
  }

  get importe(): string {
    return this.cajaChicaLinares.ingreso || this.cajaChicaLinares.egreso|| "";
  }



  @Emit("cerrar")
  onCerrar(): void {
    return;
  }
}
