import { RouteConfig } from "vue-router";
import CentrosCostos from "@/views/CentrosCostos.vue";

const centrosCostos: RouteConfig = {
    path: "/centrosCostos",
    name: "centrosCostos",
    component: CentrosCostos,
};

export default centrosCostos;
