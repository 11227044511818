






































































































































































import { Vue, Component } from "vue-property-decorator";
import { mapActions, mapGetters } from "vuex";
import TablaContrato from "@/components/contratos/Tabla.vue";
import FormularioContrato from "@/components/contratos/Formulario.vue";
import { DataFormularioContrato } from "@/typings/components/contratos/formulario";
import DialogoExito from "@/components/DialogoExito.vue";
import DialogoEliminar from "@/components/DialogoEliminar.vue";
import InfoContrato from "@/components/contratos/Info.vue";
import Condicion from "@/components/contratos/Condicion.vue";
import MensajeEliminar from "@/components/MensajeEliminar.vue";
import {
  Contrato,
  Contratos,
} from "@/typings/store/plugins/easyFirestore/contratos";
import { Local } from "@/typings/store/plugins/easyFirestore/locales";
import { CondicionComercial } from "@/typings/store/plugins/easyFirestore/condicionesComerciales";

import CargarBancosExcel from "@/components/excel/CargarBancos.vue";

const routeGuard = (vm: Vue): boolean => {
  const permisos = vm.getUsuario?.permisos;
  if (permisos && permisos["contratos"]) {
    return true;
  }

  const rutas = vm.getRutasUsuario;
  if (rutas.length > 0) {
    const to = rutas[0].to;
    if (to) {
      if (vm.$route.name !== to.name) {
        vm.$router.replace(to);
      }
      return false;
    }
  }
  if (vm.$route.name !== "inicio") {
    vm.$router.replace({ name: "inicio" });
  }
  return false;
};

@Component({
  computed: mapGetters({
    getUsuarios: "usuarios/get",
    getContratos: "contratos/get",
    getArrayUsuarios: "usuarios/getArray",
    getUsuario: "usuario/get",
    getRutas: "rutas/get",
    getRutasUsuario: "usuario/getRutas",
    getArrayContratos: "contratos/getArray",
    getArrayLocales: "locales/getArray",
    getArrayClientes: "clientes/getArray",
    // Condiciones Comerciales
    getArrayCondicionesComerciales: "condicionesComerciales/getArray",
  }),
  methods: mapActions({
    setContrato: "contratos/set",
    deleteContrato: "contratos/delete",
    insertBatchContratos: "contratos/insertBatch",
  }),
  beforeRouteEnter(to, from, next) {
    next(routeGuard);
  },
  components: {
    DialogoEliminar,
    DialogoExito,
    TablaContrato,
    FormularioContrato,
    InfoContrato,
    MensajeEliminar,
    CargarBancosExcel,
    Condicion,
  },
})
export default class VistaContratos extends Vue {
  created(): void {
    if (!routeGuard(this)) {
      return;
    }
  }
  local: Local | null = null;
  cargando = false;
  dialogoExito = {
    model: false,
    texto: "",
    icono: "",
  };
  search = "";
  contrato: Contrato | null = null;
  condicion: CondicionComercial | null = null;
  dialogoInfo = false;
  dialogoFormulario = false;
  dialogoEliminar = false;
  dialogoCondicion = false;
  textoEliminar = "¿ESTÁS SEGURO QUE DESEAS ELIMINAR ESTE CONTRATO?";
  dialogoCargarExcel = false;
  // filtros Corregir
  filtrarCentroCosto = "";
  filtrarProveedor = "";
  filtrarEstado = "";
  filtrarOrden = "";
  filtrarSolicitante = "";
  // filtros Corregir
  empresas = [""];
  centrosCostos = [""];
  estados = [""];
  ordenesCompra = [""];
  solicitantes = [""];

  get contratos(): Contratos {
    return this.getContratos;
  }

  

  get codigoContrato(): number {
    let numero = 0;
    let arrayNumeros = [numero];
    this.getArrayContratos.forEach((contrato) => {
      if (contrato.codigo != numero) {
        arrayNumeros.push(contrato.codigo);
      }
    });
    let max = Math.max.apply(Math, arrayNumeros);
    return max;
  }

  get items(): Array<Contrato> {
    return this.getArrayContratos;
  }

  get itemsCondiciones(): Array<CondicionComercial> {
    return this.getArrayCondicionesComerciales;
  }

  clickItem(val: { id: string } | null): void {
    this.contrato = val ? this.contratos[val.id] : null;
  }

  clickPlus(): void {
    this.contrato = null;
    this.dialogoFormulario = true;
  }

  async clickGuardar(data: DataFormularioContrato): Promise<void> {
    this.cargando = true;
    const doc = data.doc;
    await this.setContrato(doc);
    if (doc.id != this.contrato?.id) {
      this.dialogoExito = {
        model: true,
        texto: "¡Banco creado con éxito!",
        icono: require("../assets/icons/double-check-duo.svg"),
      };
    }
    if (doc.id == this.contrato?.id) {
      this.dialogoExito = {
        model: true,
        texto: "¡Banco editado con éxito!",
        icono: require("../assets/icons/editar.svg"),
      };
      this.contrato = doc;
    }
    this.cerrarDialogoFormulario();
    this.cargando = false;
  }

  cerrarDialogoExito(): void {
    this.dialogoExito = {
      model: false,
      texto: "",
      icono: "",
    };
  }

  clickEliminar(): void {
    const contrato = this.contrato;
    if (!contrato?.id) {
      console.error("error id: ", contrato);
      return;
    }
    this.deleteContrato(contrato.id);
    this.contrato = null;
    this.cerrarDialogoEliminar();
  }

  abrirDialogoInfo(contrato: Contrato): void {
    if (!contrato.id) {
      console.error("error id: ", contrato);
      return;
    }
    const doc = this.contratos[contrato.id];
    if (doc) {
      this.contrato = doc;
      this.dialogoInfo = true;
    }
  }

  abrirDialogoCondicion(contrato: Contrato): void {
    if (!contrato.id) {
      console.error("error id: ", contrato);
      return;
    }
    const doc = this.contratos[contrato.id];
    if (doc) {
      this.contrato = doc;
      this.dialogoCondicion = true;
    }
  }

  cerrarDialogoInfo(): void {
    this.dialogoInfo = false;
    this.contrato = null;
  }

  cerrarDialogoCondicion(): void {
    this.dialogoCondicion = false;
    this.contrato = null;
  }

  abrirDialogoFormulario(contrato: Contrato): void {
    if (!contrato.id) {
      console.error("error id: ", contrato);
      return;
    }
    const doc = this.contratos[contrato.id];
    if (doc) {
      this.contrato = doc;
      this.dialogoFormulario = true;
    }
  }

  abrirDialogoEliminar(contrato: Contrato): void {
    if (!contrato.id) {
      console.error("error id: ", contrato);
      return;
    }
    const doc = this.contratos[contrato.id];
    if (doc) {
      this.dialogoEliminar = true;
      this.contrato = doc;
    }
  }

  cerrarDialogoEliminar(): void {
    this.dialogoEliminar = false;
    this.contrato = null;
  }

  cerrarDialogoFormulario(): void {
    this.dialogoFormulario = false;
    this.contrato = null;
  }
}
