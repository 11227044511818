import { RouteConfig } from "vue-router";
import PlanCuentasIngresos from "@/views/PlanCuentasIngresos.vue";

const planCuentasIngresos: RouteConfig = {
    path: "/planCuentasIngresos",
    name: "planCuentasIngresos",
    component: PlanCuentasIngresos,
};

export default planCuentasIngresos;
