var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"rounded-0 tabla poppins primary--text font-weight-light text-capitalize",attrs:{"headers":_vm.headers,"options":_vm.options,"items":_vm.items,"header-props":_vm.headerProps,"hide-default-footer":"","search":_vm.buscar},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([(_vm.tipoUsuario =='relator')?{key:"item.estado",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-card',_vm._g({staticClass:"rounded-pill",attrs:{"width":"24px","height":"24px","flat":"","color":_vm.getColor(item).last}},on),[_c('v-row',{staticClass:"fill-height",attrs:{"no-gutters":"","justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-card',_vm._g({staticClass:"rounded-pill",attrs:{"width":"8px","height":"8px","flat":"","color":_vm.getColor(item).first}},on))],1)],1)],1)],1)],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.estado)+" ")])])]}}:(_vm.tipoUsuario=='admin')?{key:"item.opciones",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"info","icon":"","x-small":"","fab":""},on:{"click":function($event){return _vm.onAbrirInfo(item.data)}}},'v-btn',attrs,false),on),[_c('img',{attrs:{"src":require("../../assets/icons/informacion.svg"),"alt":"Información","width":"20px","height":"20px"}})])]}}],null,true)},[_c('span',[_vm._v("Información")])])],1)],1)]}}:null],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }