
































import {Component, Emit, Prop, Vue} from "vue-property-decorator";
import SVGIcon from "@/components/SVGIcon.vue";

@Component({
  components: {
    SVGIcon
  }
})
export default class VistaPreviaImagen extends Vue {
  @Prop() readonly url!: string;

  @Emit("cerrar")
  onCerrar(): void {
    return;
  }
}
