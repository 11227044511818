
















































































































































































// decoradores
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
// tipos
import { HeadersDataTable } from "@/typings/vuetify/vDataTable";
import { Tarifa } from "@/typings/store/plugins/easyFirestore/tarifas";
import { ItemsTablaTarifas } from "@/typings/components/tarifas/tabla";
import "@/helpers/extensions";

@Component
export default class TablaUsuarios extends Vue {
  @Prop() readonly opciones!: boolean;
  @Prop() readonly search!: string;
  @Prop() readonly tarifas!: Array<Tarifa>;

  options = {
    itemsPerPage: -1,
  };
  headerProps = {
    sortIcon: "mdi-chevron-down",
  };

  get headers(): HeadersDataTable {
    return [
      //{text: "", value: "estado", sortable: false},
      { text: "ID", value: "" },
      { text: "ID Tarifa", value: "" },
      { text: "ID Lectura", value: "" },
      { text: "Tipo Lectura", value: "tipoLectura" },
      { text: "Tipo Tarifa", value: "tipoTarifa" },
      { text: "Fecha", value: "fecha" },
      { text: "Zona", value: "zona" },
      { text: "Costo", value: "costo" },
      { text: "Costo Fijo", value: "costoFijo" },
      { text: "Condiciones Especiales", value: "condicionesEspeciales" },
      { text: "", value: "opciones", sortable: false },
    ];
  }

  get items(): ItemsTablaTarifas {
    return this.tarifas.map((tarifa) => {
      const tipoLectura = tarifa.tipoLectura;
      const tipoTarifa = tarifa.tipoTarifa;
      const fecha = tarifa.fecha;
      const zona = tarifa.zona;
      const costo = parseInt((tarifa.costo.toString()),10).toCurrency({symbol: "$"})??"";
      const costoFijo = parseInt((tarifa.costoFijo.toString()),10).toCurrency({symbol: "$"})??"";
      const condicionesEspeciales = tarifa.condicionesEspeciales;

      return {
        tipoLectura,
        tipoTarifa,
        fecha,
        zona,
        costo,
        costoFijo,
        condicionesEspeciales,

        data: tarifa,
      };
    });
  }

  //   actualizarEstado(item: Usuario): void {
  //     let estado: EstadoUsuario;
  //     item.estado === 'libre' ? estado = 'bloqueado' : estado = 'libre';
  //     item.estado = estado;
  //     this.onCambiarEstado(item);
  //   }

  //   @Emit("cambiar-estado")
  //   onCambiarEstado(usuario: Usuario): Usuario {
  //     return usuario;
  //   }

  @Emit("abrir-info")
  onAbrirInfo(tarifa: Tarifa): Tarifa {
    return tarifa;
  }

  @Emit("abrir-editar")
  onAbrirEditar(tarifa: Tarifa): Tarifa {
    return tarifa;
  }

  @Emit("abrir-eliminar")
  onAbrirEliminar(tarifa: Tarifa): Tarifa {
    return tarifa;
  }
}
