
































import { Component, Prop, Vue } from "vue-property-decorator";
import "@/helpers/extensions";
import { Pago } from "@/typings/store/plugins/easyFirestore/pagos";
import SVGIcon from "@/components/SVGIcon.vue";
import InfoPago from "@/components/pagos/Info.vue";
@Component({
  components: {
    SVGIcon,
    InfoPago,
  },
})
export default class InLinePago extends Vue {
  @Prop() readonly pago!: Pago;
  @Prop() readonly cuartiary!: boolean;
  dialogoInfo = false;

  get text(): string {
    return (
      `${this.pago.id} - ${this.pago.resultado?.amount.toCurrency({
        symbol: "$",
      }) ?? ""}` || "N/R"
    );
  }
}
