
































































// decoradores
import {Component, Emit, Prop, Vue} from "vue-property-decorator";
// tipos
import {HeadersDataTable} from "@/typings/vuetify/vDataTable";
import "@/helpers/extensions";
import {Inscripcion} from "@/typings/store/plugins/easyFirestore/inscripciones";
import {ItemsTablaInscripciones} from "@/typings/components/Inscripciones/tabla";
import {Usuario} from "@/typings/store/plugins/easyFirestore/usuarios";

@Component
export default class TablaUsuarios extends Vue {
  @Prop() readonly opciones!: boolean;
  @Prop() readonly usuario!: Usuario;
  @Prop() readonly search!: string;
  @Prop() readonly inscripciones!: Array<Inscripcion>;
  @Prop() readonly inscripcionesRelator!: Array<Inscripcion>;

  options = {
    itemsPerPage: -1,
  };
  headerProps = {
    sortIcon: "mdi-chevron-down"
  }

  get headers(): HeadersDataTable {
    return [
      {text: "Cursos", value: "curso"},
      {text: "Fecha inicio", value: "fechaInicio"},
      {text: "Fecha fin", value: "fechaFin"},
      {text: "Inscrito", value: "cliente"},
      {text: "Inscripción", value: "fechaInscripcion"},
      {text: "", value: "opciones", sortable: false}
    ];
  }

  get items(): ItemsTablaInscripciones {
    return this.inscripciones.map((inscripcion) => {
      let curso = "";
      let fechaInicio = "";
      let fechaFin = "";
      let cliente = "";
      let fechaInscripcion = "";
      for(const cursoInscripcion of Object.values(inscripcion.pago.cursos)){
        curso = cursoInscripcion.nombre;
        fechaInicio = cursoInscripcion.inicio.toDate().format('DD-MM-YYYY');
        fechaFin = cursoInscripcion.fin.toDate().format('DD-MM-YYYY');
        cliente = inscripcion.cliente.nombre;
        fechaInscripcion = inscripcion.fecha.toDate().format("DD/MM/YYYY") ?? "";
      }
      return {
        curso,
        fechaInicio,
        fechaFin,
        cliente,
        fechaInscripcion,
        data: inscripcion,
      };
    });
  }

  get itemsRelator(): ItemsTablaInscripciones {
    return this.inscripciones.map((inscripcion) => {
      let curso = "";
      let fechaInicio = "";
      let fechaFin = "";
      let cliente = "";
      let fechaInscripcion = "";
      for(const cursoInscripcion of Object.values(inscripcion.pago.cursos)){
        curso = cursoInscripcion.nombre;
        fechaInicio = cursoInscripcion.inicio.toDate().format('DD-MM-YYYY');
        fechaFin = cursoInscripcion.fin.toDate().format('DD-MM-YYYY');
        cliente = inscripcion.cliente.nombre;
        fechaInscripcion = inscripcion.fecha.toDate().format("DD/MM/YYYY") ?? "";
      }
      return {
        curso,
        fechaInicio,
        fechaFin,
        cliente,
        fechaInscripcion,
        data: inscripcion,
      };
    });
  }


  @Emit("abrir-info")
  onAbrirInfo(inscripcion: Inscripcion): Inscripcion {
    return inscripcion;
  }

  @Emit("abrir-eliminar")
  onAbrirEliminar(inscripcion: Inscripcion): Inscripcion {
    return inscripcion;
  }

}
