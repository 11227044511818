

























































































// decoradores
import { Component, Emit, Prop, Vue} from "vue-property-decorator";
// tipos
import { HeadersDataTable } from "@/typings/vuetify/vDataTable";
import {Ciudad} from "@/typings/store/plugins/easyFirestore/ciudades";
import {ItemsTablaCiudades} from "@/typings/components/ciudades/tabla";
@Component
export default class TablaCiudades extends Vue {
  @Prop() readonly opciones!: boolean;
  @Prop() readonly search!: string;
  @Prop() readonly ciudades!: Array<Ciudad>;

  options = {
    itemsPerPage: -1,
  };


  get headers(): HeadersDataTable {
    return [
      { text: "Nombre", value: "nombre" },
      { text: "Region", value: "region" },
      { text: "Opciones", value: "opciones" },
    ];
  }

  get items(): ItemsTablaCiudades {
    return this.ciudades.map((ciudad) => {
      const nombre = ciudad.nombre;
      const region = ciudad.region.nombre;
      return {
        nombre,
        region,
        data: ciudad,
      };
    });
  }
  @Emit("abrir-info")
  onAbrirInfo(ciudad: Ciudad): Ciudad {
    return ciudad;
  }

  @Emit("abrir-editar")
  onAbrirEditar(ciudad: Ciudad): Ciudad {
    return ciudad;
  }

  @Emit("abrir-eliminar")
  onAbrirEliminar(ciudad: Ciudad): Ciudad {
    return ciudad;
  }
}
