




































































































































// decoradores
import { Vue, Component } from "vue-property-decorator";
// vuex
import { mapActions, mapGetters } from "vuex";
// tipos

// componentes
import TablaVacancia from "@/components/vacancias/Tabla.vue";
import FormularioBanco from "@/components/vacancias/Formulario.vue";
import { DataFormularioVacancia } from "@/typings/components/vacancias/formulario";
import DialogoExito from "@/components/DialogoExito.vue";
import DialogoEliminar from "@/components/DialogoEliminar.vue";
import InfoBanco from "@/components/vacancias/Info.vue";
//import BotonesCrud from "@/components/BotonesCrud.vue";
import MensajeEliminar from "@/components/MensajeEliminar.vue";
//import TituloVista from "@/components/TituloVista.vue";
import {
  Vacancia,
  Vacancias,
  //  Regiones,
} from "@/typings/store/plugins/easyFirestore/vacancias";
import { CentroCosto } from "@/typings/store/plugins/easyFirestore/centrosCostos";
//import { DataFormularioRegion } from "@/typings/components/regiones/formulario";
import CargarBancosExcel from "@/components/excel/CargarBancos.vue";

const routeGuard = (vm: Vue): boolean => {
  const permisos = vm.getUsuario?.permisos;
  if (permisos && permisos["vacancias"]) {
    return true;
  }

  const rutas = vm.getRutasUsuario;
  if (rutas.length > 0) {
    const to = rutas[0].to;
    if (to) {
      if (vm.$route.name !== to.name) {
        vm.$router.replace(to);
      }
      return false;
    }
  }
  if (vm.$route.name !== "inicio") {
    vm.$router.replace({ name: "inicio" });
  }
  return false;
};

@Component({
  computed: mapGetters({
    getUsuarios: "usuarios/get",
    getVacancias: "vacancias/get",
    getArrayUsuarios: "usuarios/getArray",
    getUsuario: "usuario/get",
    getRutas: "rutas/get",
    getRutasUsuario: "usuario/getRutas",
    getArrayVacancias: "vacancias/getArray",
    getArrayCentrosCostos: "centrosCostos/getArray",
  }),
  methods: mapActions({
    setVacancia: "vacancias/set",
    deleteVacancia: "vacancias/delete",
    insertBatchVacancias: "vacancias/insertBatch",
    //setArchivo: "archivos/set",
    //deleteArchivo: "archivos/delete",
  }),
  beforeRouteEnter(to, from, next) {
    next(routeGuard);
  },
  components: {
    DialogoEliminar,
    DialogoExito,
    TablaVacancia,
    FormularioBanco,
    InfoBanco,
    MensajeEliminar,
    CargarBancosExcel,
  },
})
export default class VistaBancos extends Vue {
  created(): void {
    if (!routeGuard(this)) {
      return;
    }
  }
  centroCosto: CentroCosto | null = null;
  cargando = false;
  dialogoExito = {
    model: false,
    texto: "",
    icono: "",
  };
  search = "";
  vacancia: Vacancia | null = null;
  dialogoInfo = false;
  dialogoFormulario = false;
  dialogoEliminar = false;
  textoEliminar = "¿ESTÁS SEGURO QUE DESEAS ELIMINAR ESTE BANCO?";
  dialogoCargarExcel = false;

  get vacancias(): Vacancias {
    return this.getVacancias;
  }

  get items(): Array<Vacancia> {
    return this.getArrayVacancias;
  }

  clickItem(val: { id: string } | null): void {
    this.vacancia = val ? this.vacancias[val.id] : null;
  }

  clickPlus(): void {
    this.vacancia = null;
    this.dialogoFormulario = true;
  }

  async clickGuardar(data: DataFormularioVacancia): Promise<void> {
    this.cargando = true;
    const doc = data.doc;
    await this.setVacancia(doc);
    if (doc.id != this.vacancia?.id) {
      this.dialogoExito = {
        model: true,
        texto: "¡Banco creado con éxito!",
        icono: require("../assets/icons/double-check-duo.svg"),
      };
    }
    if (doc.id == this.vacancia?.id) {
      this.dialogoExito = {
        model: true,
        texto: "¡Banco editado con éxito!",
        icono: require("../assets/icons/editar.svg"),
      };
      this.vacancia = doc;
    }
    this.cerrarDialogoFormulario();
    this.cargando = false;
  }

  cerrarDialogoExito(): void {
    this.dialogoExito = {
      model: false,
      texto: "",
      icono: "",
    };
  }

  clickEliminar(): void {
    const vacancia = this.vacancia;
    if (!vacancia?.id) {
      console.error("error id: ", vacancia);
      return;
    }
    this.deleteVacancia(vacancia.id);
    this.vacancia = null;
    this.cerrarDialogoEliminar();
  }

  abrirDialogoInfo(vacancia: Vacancia): void {
    if (!vacancia.id) {
      console.error("error id: ", vacancia);
      return;
    }
    const doc = this.vacancias[vacancia.id];
    if (doc) {
      this.vacancia = doc;
      this.dialogoInfo = true;
    }
  }

  cerrarDialogoInfo(): void {
    this.dialogoInfo = false;
    this.vacancia = null;
  }

  abrirDialogoFormulario(vacancia: Vacancia): void {
    if (!vacancia.id) {
      console.error("error id: ", vacancia);
      return;
    }
    const doc = this.vacancias[vacancia.id];
    if (doc) {
      this.vacancia = doc;
      this.dialogoFormulario = true;
    }
  }

  abrirDialogoEliminar(vacancia: Vacancia): void {
    if (!vacancia.id) {
      console.error("error id: ", vacancia);
      return;
    }
    const doc = this.vacancias[vacancia.id];
    if (doc) {
      this.dialogoEliminar = true;
      this.vacancia = doc;
    }
  }

  cerrarDialogoEliminar(): void {
    this.dialogoEliminar = false;
    this.vacancia = null;
  }

  cerrarDialogoFormulario(): void {
    this.dialogoFormulario = false;
    this.vacancia = null;
  }
}
