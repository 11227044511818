import { RouteConfig } from "vue-router";
import Medidores from "@/views/Medidores.vue";

const medidores: RouteConfig = {
    path: "/medidores",
    name: "medidores",
    component: Medidores,
    
};

export default medidores;
