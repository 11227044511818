





























































// decoradores
import { Vue, Component, Prop, Emit } from "vue-property-decorator";
// validaciones
import { required } from "vuelidate/lib/validators";
// tipos
import { Local, Locales } from "@/typings/store/plugins/easyFirestore/locales";
// api excel
import Excel from "exceljs";

interface Registro {
  // entrada: Date;
  // salida: Date;
  //centroCosto: string;
  //direccion: string;
codigo:number;
      centroCosto:string;
  //  direccion:string;
    unidadLocativa:string;
    superficie:string;
    nivel:string;
    fachada:string;
    vista_planta:string;
    tipo_local:string;
    estado:string;
}

interface NombresLocales {
  [centroCosto: string]: Local;
}

// const date = (value: Date) => {
//   return value.toString() != "Invalid Date";
// };

@Component({
  validations: {
    archivo: { required },
  },
})
export default class CargarExcel extends Vue {
  @Prop() readonly locales!: Locales;

  archivo: Blob | null = null;
  cargando = false;
  clickCargar = false;

  despachos: Array<Local> = [];
  mensajes: Array<string> = [];

  get nombresLocales(): NombresLocales {
    const resultado: NombresLocales = {};
    Object.values(this.locales).forEach((item) => {
      resultado[item.centroCosto] = Object.assign({}, item);
    });
    return resultado;
  }

  // get rfidPatentes(): RfidPatentes {
  //   const resultado: RfidPatentes = {};
  //   Object.values(this.rfids).forEach((item) => {
  //     resultado[item.patente] = Object.assign({}, item);
  //   });
  //   return resultado;
  // }

  get archivoError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.archivo.$dirty) return errors;
    if (!this.$v.archivo.required) errors.push("Requerido");
    return errors;
  }

  @Emit("carga-excel")
  onCargaExcel(): Array<Local> {
    return this.despachos;
  }

  // valoresValidos(valores: Array<Excel.CellValue>): boolean {
  //   if (!valores[1] || !valores[2] ) {
  //     return false;
  //   }
  //   const centroCosto = valores[1] ;
  //   const direccion = valores[2];
  //   //return !!entrada && !!salida && date(entrada) && date(salida);
  //   return centroCosto && direccion
  // }

  async cargar(): Promise<void> {
    this.$v.$touch();
    if (this.$v.$invalid || !this.archivo) {
      return;
    }
    this.cargando = true;
    this.clickCargar = true;
    const workbook = new Excel.Workbook();
    const data = await this.archivo.arrayBuffer();
    await workbook.xlsx.load(data);
    // worksheet
    const worksheet = workbook.getWorksheet(1);
    console.log("registros", worksheet.actualRowCount - 1);
    if (worksheet.actualRowCount - 1 > 500) {
      console.log("registros mayores a 500");
      this.mensajes.push("error: cantidad de registros mayor a 500");
      this.cargando = false;
      return;
    }
    worksheet.eachRow((row, rowNumber) => {
      if (rowNumber != 1) {
        const valores = row.values as Array<Excel.CellValue>;
        if (valores[3] == "ejemplo") {
          console.log("error row", rowNumber);
          this.mensajes.push(
            "error: row " +
              rowNumber +
              " invalida, contiene campos vacios, fechas invalidas o la etiqueta prueba"
          );
          return;
        }
        const registro: Registro = {
          centroCosto: valores[1] as string,
         // direccion: valores[2] as string,
          unidadLocativa: valores[3] as string,
          superficie: valores[4] as string,
          nivel: valores[5] as string,
          fachada: valores[6] as string,
          vista_planta: valores[7] as string,
          tipo_local: valores[8] as string,
          estado: valores[9] as string,
          codigo: valores[10] as number,

        };
        // const banco = this.nombresLocales[registro.centroCosto];
        //  if (!banco) {
        //    console.log("error row", rowNumber);
        //    this.mensajes.push(
        //      "error: row " + rowNumber + " invalida, patente no registrada"
        //    );
        //    return;
        //  }
        const ingreso: Local = {
          codigo: registro.codigo,
          centroCosto: registro.centroCosto,
          //direccion: registro.direccion,
          unidadLocativa: registro.unidadLocativa,
          superficie: registro.superficie,
          nivel: registro.nivel,
          fachada: registro.fachada,
          vista_planta: registro.vista_planta,
          tipo_local: registro.tipo_local,
          estado: registro.estado,
          
        };
        this.despachos.push(ingreso);
      }
    });
    if (this.mensajes.length > 0) {
      this.mensajes.push("cargado con errores");
      this.mensajes.push("corregir excel y volver a intentar");
      this.cargando = false;
    } else {
      this.mensajes.push("cargado completo");
      this.mensajes.push(this.despachos.length + " locales cargados");
      this.onCargaExcel();
      this.cargando = false;
    }
  }
}
