import { ActionTree, GetterTree, MutationTree } from "vuex";

//tipos
import { State } from "@/typings/store";
import { StateRutas } from "@/typings/store/modules/rutas";

const state: StateRutas = {
  all: {
    ["prenominas"]: {
      id: "prenominas",
      icon: "prenominas",
      text: "Prenominas",
      to: { name: "prenominas" },
      number: 1,
      tipos: [
        "admin",
        "relator",
        "supervisor",
        "gerente_operaciones",
        "gerente_comercial",
      ],
      show: true,
    },
    ["visualizacionPagos"]: {
      id: "visualizacionPagos",
      icon: "visualizacionPagos",
      text: "Visualización Pagos",
      to: { name: "visualizacionPagos" },
      number: 2,
      tipos: [
        "admin",
        "relator",
        "supervisor",
        "gerente_comercial",
        "gerente_operaciones",
      ],
      show: true,
    },
    ["registrosEgresos"]: {
      id: "registrosEgresos",
      icon: "registrosEgresos",
      text: "Matriz Conciliación",
      to: { name: "registrosEgresos" },
      number: 3,
      tipos: ["admin", "relator", "supervisor"],
      show: true,
    },
    ["ordenes"]: {
      id: "ordenes",
      icon: "ordenes",
      text: "Ordenes de Compra",
      to: { name: "ordenes" },
      number: 4,
      tipos: [
        "admin",
        "relator",
        "supervisor",
        "gerente_comercial",
        "gerente_operaciones",
      ],
      show: true,
    },
    ["usuarios"]: {
      id: "usuarios",
      icon: "usuarios",
      text: "Usuarios",
      to: { name: "usuarios" },
      number: 5,
      tipos: ["admin", "supervisor"],
      show: true,
    },
    ["perfil"]: {
      id: "perfil",
      icon: "mdi-account",
      text: "Perfil",
      to: { name: "perfil" },
      number: 6,
      tipos: [
        "admin",
        "cliente",
        "relator",
        "sudo",
        "supervisor",
        "gerente_comercial",
        "gerente_operaciones",
      ],
      show: false,
    },
    ["ingresos"]: {
      id: "ingresos",
      icon: "ingresos",
      text: "Matriz Ingresos",
      to: { name: "ingresos" },
      number: 7,
      tipos: [
        "admin",
        "relator",
        "supervisor",
        "gerente_operaciones",
        "gerente_comercial",
      ],
      show: true,
    },
    ["tarifas"]: {
      id: "tarifas",
      icon: "tarifas",
      text: "Tarifas",
      to: { name: "tarifas" },
      number: 8,
      tipos: ["admin"],
      show: true,
    },
    ["lecturas"]: {
      id: "lecturas",
      icon: "lecturas",
      text: "Lecturas",
      to: { name: "lecturas" },
      number: 9,
      tipos: ["admin"],
      show: true,
    },
    ["proveedores"]: {
      id: "proveedores",
      icon: "proveedores",
      text: "Proveedores",
      to: { name: "proveedores" },
      number: 10,
      tipos: ["admin", "relator", "supervisor"],
      show: true,
    },

    ["tipoDocumentos"]: {
      id: "tipoDocumentos",
      icon: "tipoDocumentos",
      text: "Tipo Documentos",
      to: { name: "tipoDocumentos" },
      number: 11,
      tipos: ["admin", "supervisor"],
      show: true,
    },
    ["tipoCargos"]: {
      id: "tipoCargos",
      icon: "tipoCargos",
      text: "Tipo Gastos",
      to: { name: "tipoCargos" },
      number: 12,
      tipos: ["admin", "supervisor"],
      show: true,
    },
    ["matrizPagos"]: {
      id: "matrizPagos",
      icon: "matrizPagos",
      text: "Matriz Control Pagos",
      to: { name: "matrizPagos" },
      number: 13,
      tipos: ["admin", "supervisor"],
      show: true,
    },

    ["bancos"]: {
      id: "bancos",
      icon: "bancos",
      text: "Bancos",
      to: { name: "bancos" },
      number: 14,
      tipos: ["admin", "supervisor"],
      show: true,
    },
    ["centrosCostos"]: {
      id: "centrosCostos",
      icon: "centrosCostos",
      text: "Centros Costos",
      to: { name: "centrosCostos" },
      number: 15,
      tipos: ["admin", "supervisor"],
      show: true,
    },
    ["codCuentas"]: {
      id: "codCuentas",
      icon: "codCuentas",
      text: "Codigo Cuentas",
      to: { name: "codCuentas" },
      number: 16,
      tipos: ["admin", "supervisor"],
      show: true,
    },
    ["tipoEgresos"]: {
      id: "tipoEgresos",
      icon: "tipoEgresos",
      text: "Tipo Egresos",
      to: { name: "tipoEgresos" },
      number: 17,
      tipos: ["admin", "supervisor"],
      show: true,
    },
    ["saldoCuentas"]: {
      id: "saldoCuentas",
      icon: "saldoCuentas",
      text: "Saldos Cuentas",
      to: { name: "saldoCuentas" },
      number: 18,
      tipos: ["admin", "supervisor"],
      show: true,
    },
    ["legales"]: {
      id: "legales",
      icon: "legales",
      text: "Legales",
      to: { name: "legales" },
      number: 19,
      tipos: ["admin", "supervisor"],
      show: true,
    },
    ["cajaChicas"]: {
      id: "cajaChicas",
      icon: "cajaChicas",
      text: "Caja Chica Mall",
      to: { name: "cajaChicas" },
      number: 20,
      tipos: ["admin", "relator", "supervisor", "gerente_operaciones"],
      show: true,
    },
    ["mapeoGastos"]: {
      id: "mapeoGastos",
      icon: "mapeoGastos",
      text: "Mapeo Gastos x Centro Costo",
      to: { name: "mapeoGastos" },
      number: 21,
      tipos: ["admin", "supervisor"],
      show: true,
    },
    ["vacancias"]: {
      id: "vacancias",
      icon: "vacancias",
      text: "Vacancias",
      to: { name: "vacancias" },
      number: 22,
      tipos: ["admin", "supervisor"],
      show: true,
    },
    ["contratos"]: {
      id: "contratos",
      icon: "contratos",
      text: "Contratos",
      to: { name: "contratos" },
      number: 23,
      tipos: ["admin", "supervisor"],
      show: true,
    },
    ["locales"]: {
      id: "locales",
      icon: "locales",
      text: "Locales",
      to: { name: "locales" },
      number: 24,
      tipos: ["admin", "supervisor"],
      show: true,
    },
    ["consumos"]: {
      id: "consumos",
      icon: "consumos",
      text: "Consumos",
      to: { name: "consumos" },
      number: 25,
      tipos: ["admin", "administrador_locales"],
      show: true,
    },
    ["clientes"]: {
      id: "clientes",
      icon: "clientes",
      text: "Clientes",
      to: { name: "clientes" },
      number: 26,
      tipos: ["admin"],
      show: true,
    },

    ["medidores"]: {
      id: "medidores",
      icon: "medidores",
      text: "Medidores",
      to: { name: "medidores" },
      number: 27,
      tipos: ["admin"],
      show: true,
    },
    ["distribuidoresEnergia"]: {
      id: "distribuidoresEnergia",
      icon: "distribuidoresEnergia",
      text: "Distribuidores Servicios",
      to: { name: "distribuidoresEnergia" },
      number: 28,
      tipos: ["admin"],
      show: true,
    },

    ["cajaChicasLinares"]: {
      id: "cajaChicasLinares",
      icon: "cajaChicasLinares",
      text: "Caja Chica Linares",
      to: { name: "cajaChicasLinares" },
      number: 29,
      tipos: ["admin", "relator", "supervisor"],
      show: true,
    },
    ["cajaChicasTalca"]: {
      id: "cajaChicasTalca",
      icon: "cajaChicasTalca",
      text: "Caja Chica Talca",
      to: { name: "cajaChicasTalca" },
      number: 30,
      tipos: ["admin", "relator", "supervisor"],
      show: true,
    },
    ["solicitudes"]: {
      id: "solicitudes",
      icon: "solicitudes",
      text: "Solicitudes",
      to: { name: "solicitudes" },
      number: 31,
      tipos: [
        "admin",
        "relator",
        "supervisor",
        "gerente_comercial",
        "gerente_operaciones",
      ],
      show: true,
    },

    ["mantenimientos"]: {
      id: "mantenimientos",
      icon: "mantenimientos",
      text: "Mantenimientos",
      to: { name: "mantenimientos" },
      number: 32,
      tipos: ["admin", "supervisor"],
      show: true,
    },

    ["equipos"]: {
      id: "equipos",
      icon: "equipos",
      text: "Equipos",
      to: { name: "equipos" },
      number: 33,
      tipos: ["admin", "supervisor"],
      show: true,
    },

    ["ordenesERG"]: {
      id: "ordenesERG",
      icon: "ordenesERG",
      text: "Ordenes de CompraERG",
      to: { name: "ordenesERG" },
      number: 34,
      tipos: ["admin", "supervisor"],
      show: true,
    },

    ["planCuentasIngresos"]: {
      id: "planCuentasIngresos",
      icon: "planCuentasIngresos",
      text: "Plan Cuentas Ingresos",
      to: { name: "planCuentasIngresos" },
      number: 35,
      tipos: ["admin", "relator", "supervisor"],
      show: true,
    },

    ["condicionesComerciales"]: {
      id: "condicionesComerciales",
      icon: "condicionesComerciales",
      text: "Condiciones Comerciales",
      to: { name: "condicionesComerciales" },
      number: 36,
      tipos: ["admin"],
      show: true,
    },

    ["condicionesDivisiones"]: {
      id: "condicionesDivisiones",
      icon: "condicionesDivisiones",
      text: "Condiciones Divisiones",
      to: { name: "condicionesDivisiones" },
      number: 37,
      tipos: ["admin"],
      show: true,
    },
    ["facturas"]: {
      id: "facturas",
      icon: "facturas",
      text: "Facturas",
      to: { name: "facturas" },
      number: 38,
      tipos: ["admin"],
      show: true,
    },

    // No Conciderar
    ["agenda"]: {
      id: "agenda",
      icon: "agenda",
      text: "agenda",
      to: { name: "agenda" },
      number: 39,
      tipos: ["admin"],
      show: false,
    },
    ["pagos"]: {
      id: "pagos",
      icon: "pagos",
      text: "pagos",
      to: { name: "pagos" },
      number: 40,
      tipos: ["admin"],
      show: false,
    },
    ["cursosDisponibles"]: {
      id: "cursosDisponibles",
      icon: "cursosDisponibles",
      text: "cursosDisponibles",
      to: { name: "cursosDisponibles" },
      number: 41,
      tipos: ["admin"],
      show: false,
    },
    ["notificaciones"]: {
      id: "notificaciones",
      icon: "notificaciones",
      text: "notificaciones",
      to: { name: "notificaciones" },
      number: 42,
      tipos: ["admin"],
      show: false,
    },
    ["misCursos"]: {
      id: "misCursos",
      icon: "misCursos",
      text: "misCursos",
      to: { name: "misCursos" },
      number: 43,
      tipos: ["admin"],
      show: false,
    },
  },
};

const getters: GetterTree<StateRutas, State> = {
  get(state) {
    return state.all;
  },
  getArray(state) {
    return Object.values(state.all);
  },
};

const mutations: MutationTree<StateRutas> = {};

const actions: ActionTree<StateRutas, State> = {};

const rutas = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default rutas;
