var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',{staticClass:"py-1",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"pl-2",attrs:{"cols":"12","md":"3"}},[_c('strong',[_vm._v(" Codigo Contrato: ")])]),_c('v-col',{staticClass:"pl-2",attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"placeholder":"Codigo Contrato","error-messages":_vm.codigoContratoError,"hide-details":"auto","dense":"","outlined":"","disabled":""},on:{"input":function($event){return _vm.$v.codigoContrato.$touch()},"blur":function($event){return _vm.$v.codigoContrato.$touch()}},model:{value:(_vm.codigo),callback:function ($$v) {_vm.codigo=$$v},expression:"codigo"}})],1),_c('v-col',{staticClass:"pl-2",attrs:{"cols":"12","md":"3"}},[_c('strong',[_vm._v(" Codigo Condicion: ")])]),_c('v-col',{staticClass:"pl-2",attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"placeholder":"Codigo Condicion","error-messages":_vm.codigoCondicionError,"hide-details":"auto","dense":"","outlined":"","disabled":""},on:{"input":function($event){return _vm.$v.codigoCondicion.$touch()},"blur":function($event){return _vm.$v.codigoCondicion.$touch()}},model:{value:(_vm.verificaCodigoCondicionContrato),callback:function ($$v) {_vm.verificaCodigoCondicionContrato=$$v},expression:"verificaCodigoCondicionContrato"}})],1)],1),_c('v-row',{staticClass:"py-1",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"pl-2",attrs:{"cols":"12","md":"3"}},[_c('strong',[_vm._v(" Desde: ")])]),_c('v-col',{staticClass:"pl-2",attrs:{"cols":"12","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":20,"transition":"scale-transition","offset-y":"","max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"error-messages":_vm.desdeError,"readonly":"","outlined":"","hide-details":"auto","dense":""},on:{"input":function($event){return _vm.$v.desde.$touch()},"blur":function($event){return _vm.$v.desde.$touch()}},model:{value:(_vm.desde),callback:function ($$v) {_vm.desde=$$v},expression:"desde"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuDesde),callback:function ($$v) {_vm.menuDesde=$$v},expression:"menuDesde"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menuDesde = false}},model:{value:(_vm.desde),callback:function ($$v) {_vm.desde=$$v},expression:"desde"}})],1)],1),_c('v-col',{staticClass:"pl-2",attrs:{"cols":"12","md":"3"}},[_c('strong',[_vm._v(" Hasta: ")])]),_c('v-col',{staticClass:"pl-2",attrs:{"cols":"12","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":20,"transition":"scale-transition","offset-y":"","max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"error-messages":_vm.hastaError,"readonly":"","outlined":"","hide-details":"auto","dense":""},on:{"input":function($event){return _vm.$v.hasta.$touch()},"blur":function($event){return _vm.$v.hasta.$touch()}},model:{value:(_vm.hasta),callback:function ($$v) {_vm.hasta=$$v},expression:"hasta"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuHasta),callback:function ($$v) {_vm.menuHasta=$$v},expression:"menuHasta"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menuHasta = false}},model:{value:(_vm.hasta),callback:function ($$v) {_vm.hasta=$$v},expression:"hasta"}})],1)],1)],1),_c('v-row',{staticClass:"py-1",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"pl-2",attrs:{"cols":"12","md":"3"}},[_c('strong',[_vm._v(" Liquidación: ")])]),_c('v-col',{staticClass:"pl-2",attrs:{"cols":"12","md":"9"}},[_c('v-autocomplete',{attrs:{"placeholder":"Liquidación","items":[
          'GENERAL UF DIA 5',
          'SUPERM. WALMART UF DIA 15',
          'UF DIA 9 TOTTUS - BANCO CHILE' ],"error-messages":_vm.liquidacionError,"hide-details":"auto","dense":"","outlined":""},on:{"input":function($event){return _vm.$v.liquidacion.$touch()},"blur":function($event){return _vm.$v.liquidacion.$touch()}},model:{value:(_vm.liquidacion),callback:function ($$v) {_vm.liquidacion=$$v},expression:"liquidacion"}})],1)],1),_c('v-row',{staticClass:"py-1",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"poppins font-weight-bold text-initial rounded-lg",attrs:{"color":"secondary","block":"","depressed":"","disabled":_vm.cargando},on:{"click":_vm.guardar}},[(!_vm.cargando)?_c('span',[_vm._v(" Guardar ")]):_c('img',{attrs:{"src":require("../../assets/three-dots.svg"),"alt":"Three dots","width":"64px"}})])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }