//tipos
import { GetterTree } from "vuex";
import { State } from "@/typings/store";
import { StateCategoriasCurso } from "@/typings/store/plugins/easyFirestore/categoriasCurso";

const getters: GetterTree<StateCategoriasCurso, State> = {
    get(state) {
        return state.all;
    },
    getArray(state) {
        return Object.values(state.all);
    },
};

const categoriasCurso = {
    firestorePath: "dev/v1/categoriasCurso",
    firestoreRefType: "collection",
    moduleName: "categoriasCurso",
    statePropName: "all",
    namespaced: true,
    serverChange: {
        convertTimestamps: {
            created_at: "%convertTimestamp%", // default
            updated_at: "%convertTimestamp%", // default
        },
    },
    state: {},
    getters,
    mutations: {},
    actions: {},
};

export default categoriasCurso;
