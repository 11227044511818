import { RouteConfig } from "vue-router";
import Cursos from "@/views/Cursos.vue";

const cursos: RouteConfig = {
    path: "/cursos",
    name: "cursos",
    component: Cursos,
};

export default cursos;
