







































































































// decoradores
import { Vue, Component } from "vue-property-decorator";
import { mapActions, mapGetters } from "vuex";
import TablaFactura from "@/components/facturas/Tabla.vue";
import FormularioFactura from "@/components/facturas/Formulario.vue";
import { DataFormularioFactura } from "@/typings/components/facturas/formulario";
import DialogoExito from "@/components/DialogoExito.vue";
import DialogoEliminar from "@/components/DialogoEliminar.vue";
import InfoFactura from "@/components/facturas/Info.vue";
import MensajeEliminar from "@/components/MensajeEliminar.vue";
import {
  Factura,
  Facturas,
  //  Regiones,
} from "@/typings/store/plugins/easyFirestore/facturas";

const routeGuard = (vm: Vue): boolean => {
  const permisos = vm.getUsuario?.permisos;
  if (permisos && permisos["facturas"]) {
    return true;
  }

  const rutas = vm.getRutasUsuario;
  if (rutas.length > 0) {
    const to = rutas[0].to;
    if (to) {
      if (vm.$route.name !== to.name) {
        vm.$router.replace(to);
      }
      return false;
    }
  }
  if (vm.$route.name !== "inicio") {
    vm.$router.replace({ name: "inicio" });
  }
  return false;
};

@Component({
  computed: mapGetters({
    getUsuarios: "usuarios/get",
    getFacturas: "facturas/get",
    getArrayUsuarios: "usuarios/getArray",
    getUsuario: "usuario/get",
    getRutas: "rutas/get",
    getRutasUsuario: "usuario/getRutas",
    getArrayFacturas: "facturas/getArray",
  }),
  methods: mapActions({
    setFactura: "facturas/set",
    deleteFactura: "facturas/delete",
    //insertBatchBancos: "facturas/insertBatch",
    //setArchivo: "archivos/set",
    //deleteArchivo: "archivos/delete",
  }),
  beforeRouteEnter(to, from, next) {
    next(routeGuard);
  },
  components: {
    DialogoEliminar,
    DialogoExito,
    TablaFactura,
    FormularioFactura,
    InfoFactura,
    MensajeEliminar,
  },
})
export default class VistaFacturas extends Vue {
  created(): void {
    if (!routeGuard(this)) {
      return;
    }
  }

  cargando = false;
  dialogoExito = {
    model: false,
    texto: "",
    icono: "",
  };
  search = "";
  factura: Factura | null = null;
  dialogoInfo = false;
  dialogoFormulario = false;
  dialogoEliminar = false;
  textoEliminar = "¿ESTÁS SEGURO QUE DESEAS ELIMINAR ESTA FACTURA?";
  dialogoCargarExcel = false;

  get facturas(): Facturas {
    return this.getFacturas;
  }

  get items(): Array<Factura> {
    return this.getArrayFacturas;
  }

  clickItem(val: { id: string } | null): void {
    this.factura = val ? this.facturas[val.id] : null;
  }

  clickPlus(): void {
    this.factura = null;
    this.dialogoFormulario = true;
  }

  async clickGuardar(data: DataFormularioFactura): Promise<void> {
    this.cargando = true;
    const doc = data.doc;
    await this.setFactura(doc);
    if (doc.id != this.factura?.id) {
      this.dialogoExito = {
        model: true,
        texto: "¡Factura creada con éxito!",
        icono: require("../assets/icons/double-check-duo.svg"),
      };
    }
    if (doc.id == this.factura?.id) {
      this.dialogoExito = {
        model: true,
        texto: "¡Factura editada con éxito!",
        icono: require("../assets/icons/editar.svg"),
      };
      this.factura = doc;
    }
    this.cerrarDialogoFormulario();
    this.cargando = false;
  }

  cerrarDialogoExito(): void {
    this.dialogoExito = {
      model: false,
      texto: "",
      icono: "",
    };
  }

  clickEliminar(): void {
    const factura = this.factura;
    if (!factura?.id) {
      console.error("error id: ", factura);
      return;
    }
    this.deleteFactura(factura.id);
    this.factura = null;
    this.cerrarDialogoEliminar();
  }

  abrirDialogoInfo(factura: Factura): void {
    if (!factura.id) {
      console.error("error id: ", factura);
      return;
    }
    const doc = this.facturas[factura.id];
    if (doc) {
      this.factura = doc;
      this.dialogoInfo = true;
    }
  }

  cerrarDialogoInfo(): void {
    this.dialogoInfo = false;
    this.factura = null;
  }

  abrirDialogoFormulario(factura: Factura): void {
    if (!factura.id) {
      console.error("error id: ", factura);
      return;
    }
    const doc = this.facturas[factura.id];
    if (doc) {
      this.factura = doc;
      this.dialogoFormulario = true;
    }
  }

  abrirDialogoEliminar(factura: Factura): void {
    if (!factura.id) {
      console.error("error id: ", factura);
      return;
    }
    const doc = this.facturas[factura.id];
    if (doc) {
      this.dialogoEliminar = true;
      this.factura = doc;
    }
  }

  cerrarDialogoEliminar(): void {
    this.dialogoEliminar = false;
    this.factura = null;
  }

  cerrarDialogoFormulario(): void {
    this.dialogoFormulario = false;
    this.factura = null;
  }
}
