import { RouteConfig } from "vue-router";
import RegistrosEgresos from "@/views/RegistrosEgresos.vue";

const registrosEgresos: RouteConfig = {
  path: "/registrosEgresos",
  name: "registrosEgresos",
  component: RegistrosEgresos,
};

export default registrosEgresos;
