import Firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import "firebase/messaging";

function initFirebase(): Promise<void> {
  Firebase.initializeApp({
    apiKey: "AIzaSyB2QiUQfrQjuwSSdXF-Y7q5SBZCmPc33fg",
    authDomain: "control-gestion-e3e43.firebaseapp.com",
    databaseURL: "https://control-gestion-e3e43-default-rtdb.firebaseio.com",
    projectId: "control-gestion-e3e43",
    storageBucket: "control-gestion-e3e43.appspot.com",
    messagingSenderId: "883044092638",
    appId: "1:883044092638:web:c7d73694b397802ecdd04f",
  });
  return new Promise((resolve, reject) => {
    Firebase.firestore()
      .enablePersistence()
      .then(resolve)
      .catch((err) => {
        if (err.code === "failed-precondition") {
          reject(err);
          // Multiple tabs open, persistence can only be
          // enabled in one tab at a a time.
        } else if (err.code === "unimplemented") {
          reject(err);
          // The current browser does not support all of
          // the features required to enable persistence
        }
      });
  });
}

export { Firebase, initFirebase };
