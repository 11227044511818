










































































































































import { Vue, Component } from "vue-property-decorator";
import TituloVista from "@/components/TituloVista.vue";
import {
  TipoUsuario,
  Usuario,
} from "@/typings/store/plugins/easyFirestore/usuarios";
import { mapActions, mapGetters } from "vuex";
import { Archivo, Archivos } from "@/typings/store/modules/archivos";
import { idtify } from "@/helpers/string";
import Formulario from "@/components/perfil/Formulario.vue";
import { Ciudad } from "@/typings/store/plugins/easyFirestore/ciudades";
import { DataFormularioUsuario } from "@/typings/components/usuarios/formulario";
import SVGIcon from "@/components/SVGIcon.vue";
import HistorialCursos from "@/components/perfil/HistorialCursos.vue";
import { Curso } from "@/typings/store/plugins/easyFirestore/cursos";
import { Sesion } from "@/typings/store/plugins/easyFirestore/sesiones";

const routeGuard = (vm: Vue): boolean => {
  const permisos = vm.getUsuario?.permisos;
  if (permisos && permisos["perfil"]) {
    return true;
  }

  const rutas = vm.getRutasUsuario;
  if (rutas.length > 0) {
    const to = rutas[0].to;
    if (to) {
      if (vm.$route.name !== to.name) {
        vm.$router.replace(to);
      }
      return false;
    }
  }
  if (vm.$route.name !== "inicio") {
    vm.$router.replace({ name: "inicio" });
  }
  return false;
};

@Component({
  computed: mapGetters({
    getUsuario: "usuario/get",
    getArrayCiudades: "ciudades/getArray",
    getArrayCursos: "cursos/getArray",
    getArraySesiones: "sesiones/getArray",
    getRutasUsuario: "usuario/getRutas",
  }),
  methods: mapActions({
    setUsuario: "usuarios/set",
    setArchivo: "archivos/set",
    deleteArchivo: "archivos/delete",
  }),
  beforeRouteEnter(to, from, next) {
    next(routeGuard);
  },
  components: {
    HistorialCursos,
    SVGIcon,
    Formulario,
    TituloVista,
  },
})
export default class VistaPerfil extends Vue {
  created(): void {
    if (!routeGuard(this)) {
      return;
    }
    if (this.usuario) {
      const usuario = this.usuario;
      const archivos = Object.values(usuario.imagen ?? {});
      this.imagen = archivos[0] ?? null;
      this.nombre = usuario.nombre;
      this.rut = usuario.rut;
      this.correo = usuario.email;
      this.telefono = usuario.telefono || "Teléfono no registrado";
      this.ciudad = usuario.ciudad?.nombre || "Ciudad no registrada";
      this.profesion = usuario.profesion || "Profesión no registrada";
    }
  }

  dialogEditar = false;
  imagen: Archivo | null = null;
  archivosEliminados: Archivos = {};
  nombre = "";
  rut = "";
  correo = "";
  telefono = "";
  ciudad = "";
  profesion = "";

  get usuario(): Usuario | null {
    return this.getUsuario;
  }

  get tipo(): TipoUsuario {
    return this.usuario?.tipo ?? "cliente";
  }

  get arrayCiudades(): Ciudad[] {
    return this.getArrayCiudades;
  }

  get arrayCursos(): Curso[] {
    return this.getArrayCursos.filter(
      (curso) =>
        curso.relator.id === this.usuario?.id &&
        (curso.estado === "aceptado" ||
          curso.estado === "impartiendo" ||
          curso.estado === "terminado")
    );
  }

  get arraySesiones(): Sesion[] {
    return this.getArraySesiones.filter((sesion) =>
      this.arrayCursos.some((curso) => curso.id === sesion.curso.id)
    );
  }

  get horasSesiones(): number {
    return Math.floor(
      this.arraySesiones.reduce((total, sesion) => total + sesion.duracion, 0) /
        60
    );
  }

  urlArchivo(media: Archivo): string {
    if (!media) {
      return "";
    }
    return media.url ?? URL.createObjectURL(media.file);
  }

  async editarUsuario(data: DataFormularioUsuario): Promise<void> {
    const usuario = data.doc;
    const imagen = data.imagen;
    const borrar = data.borrar;
    for (const key in imagen) {
      if (imagen[key].file) {
        const doc = imagen[key];
        const res = await this.setArchivo(doc);
        if (res && res.url) {
          this.$set(imagen, idtify(res.id), res);
        }
      }
    }
    for (const key in borrar) {
      if (borrar[key]) {
        const doc = borrar[key];
        await this.deleteArchivo(doc.id);
      }
    }
    usuario.imagen = imagen;
    this.imagen = Object.values(imagen)[0] ?? null;
    this.nombre = usuario.nombre;
    this.telefono = usuario.telefono || this.telefono;
    this.ciudad = usuario.ciudad?.nombre || this.ciudad;
    this.profesion = usuario.profesion || this.profesion;
    await this.setUsuario(usuario);
    this.dialogEditar = false;
  }
}
