import { RouteConfig } from "vue-router";
import OrdenesERG from "@/views/OrdenesERG.vue";

const ordenesERG: RouteConfig = {
    path: "/ordenesERG",
    name: "ordenesERG",
    component: OrdenesERG,
};

export default ordenesERG;
