
































































































































































































































































































































































































































































































































































































































































































































































































































































































// decoradores
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
// vuex
import { mapGetters } from "vuex";
// componentes

import {
  EstadoPrenomina,
  Prenomina,
} from "@/typings/store/plugins/easyFirestore/prenominas";
import { ItemsTablaPrenominas } from "@/typings/components/prenominas/tabla";
import {
  Usuario,
  //TipoUsuario,
} from "@/typings/store/plugins/easyFirestore/usuarios";
import { fechaLocal } from "@/helpers/date";
import "@/helpers/extensions";
//import centrosCostos from "@/router/centrosCostos";
//import centrosCostos from "@/router/centrosCostos";

@Component({
  computed: mapGetters({
    getPrenominas: "prenominas/get",
  }),
  components: {},
})
export default class TablaMapeo extends Vue {
  @Prop() readonly prenominas!: Array<Prenomina>;
  @Prop() readonly item!: ItemsTablaPrenominas;
  @Prop() readonly usuario?: Usuario;
  @Prop() readonly search!: string;

  ordenCentroCosto = 0;
  ordenRutProveedor = 0;
  ordenNombreProveedor = 0;
  ordenNumeroFactura = 0;
  ordenOrdenCompra = 0;
  ordenMontoPago = 0;
  ordenFiltros = 0;

  ascenteCentroCosto(): void {
    this.ordenCentroCosto += 1;
    this.ordenFiltros = 1;
  }

  descenteCentroCosto(): void {
    this.ordenCentroCosto -= 1;
    this.ordenFiltros = 1;
  }

  ascenteRutProveedor(): void {
    this.ordenRutProveedor += 1;
    this.ordenFiltros = 2;
  }

  descenteRutProveedor(): void {
    this.ordenRutProveedor -= 1;
    this.ordenFiltros = 2;
  }

  ascenteNombreProveedor(): void {
    this.ordenNombreProveedor += 1;
    this.ordenFiltros = 3;
  }

  descenteNombreProveedor(): void {
    this.ordenNombreProveedor -= 1;
    this.ordenFiltros = 3;
  }

  ascenteNumeroFactura(): void {
    this.ordenNumeroFactura += 1;
    this.ordenFiltros = 4;
  }

  descenteNumeroFactura(): void {
    this.ordenNumeroFactura -= 1;
    this.ordenFiltros = 4;
  }

  ascenteOrdenCompra(): void {
    this.ordenOrdenCompra += 1;
    this.ordenFiltros = 5;
  }

  descenteOrdenCompra(): void {
    this.ordenOrdenCompra -= 1;
    this.ordenFiltros = 5;
  }

  ascenteMontoPago(): void {
    this.ordenMontoPago += 1;
    this.ordenFiltros = 6;
  }

  descenteMontoPago(): void {
    this.ordenMontoPago -= 1;
    this.ordenFiltros = 6;
  }

  get items(): ItemsTablaPrenominas {
    return this.prenominas
      .map((prenomina) => {
        const id = prenomina.id;
        const centroCosto = prenomina.centroCosto;
        const numeroPrenominas = prenomina.numeroPrenominas;
        const ingresada = prenomina.ingresada;
        const modificado = prenomina.modificado;
        const fPago = prenomina.formaPago;
        const rut = prenomina.rut;
        const nombreProveedor = prenomina.nombreProveedor;
        const nFactura = prenomina.nFactura;
        const ordenCompra = prenomina.ordenCompra;
        const tipoGasto = prenomina.tipoGasto;
        //const tipoCambio = prenomina.tipoCambio;
        const montoOC = (
          parseInt(prenomina.montoOC, 10).toCurrency() ?? ""
        ).toString();
        const porcentajePagado = prenomina.porcentajePagado;
        const montoSaldo = prenomina.montoSaldo;
        const montoAP = (
          parseInt(prenomina.montoAP, 10).toCurrency({ symbol: "$" }) ?? ""
        ).toString();
        const observacion = prenomina.observacion;
        const estado = prenomina.estado;
        const fechaAprobacion = prenomina.fechaAprobacion;
        const autorizado = prenomina.autorizado;

        return {
          id,
          centroCosto,
          modificado,
          fPago,
          numeroPrenominas,
          ingresada,
          rut,
          nombreProveedor,
          nFactura,
          ordenCompra,
          tipoGasto,
          montoOC,
          porcentajePagado,
          montoSaldo,
          montoAP,
          observacion,
          estado,
          fechaAprobacion,
          autorizado,

          data: prenomina,
        };
      })
      .filter((prenomina) => prenomina.ingresada !== true)
      .sort((sesionA, sesionB) => {
        if (this.ordenFiltros === 1) {
          if (this.ordenCentroCosto === 0) {
            return (
              parseInt(sesionA.centroCosto) - parseInt(sesionB.centroCosto)
            );
          }
          if (this.ordenCentroCosto === 1) {
            return (
              parseInt(sesionB.centroCosto) - parseInt(sesionA.centroCosto)
            );
          }
        }
        if (this.ordenFiltros === 2) {
          if (this.ordenRutProveedor === 0) {
            return parseInt(sesionA.rut) - parseInt(sesionB.rut);
          }
          if (this.ordenRutProveedor === 1) {
            return parseInt(sesionB.rut) - parseInt(sesionA.rut);
          }
        }
        if (this.ordenFiltros === 3) {
          if (this.ordenNombreProveedor === 0) {
            return (
              parseInt(sesionA.nombreProveedor) -
              parseInt(sesionB.nombreProveedor)
            );
          }
          if (this.ordenNombreProveedor === 1) {
            return (
              parseInt(sesionB.nombreProveedor) -
              parseInt(sesionA.nombreProveedor)
            );
          }
        }
        if (this.ordenFiltros === 4) {
          if (this.ordenNumeroFactura === 0) {
            return parseInt(sesionA.nFactura) - parseInt(sesionB.nFactura);
          }
          if (this.ordenNumeroFactura === 1) {
            return parseInt(sesionB.nFactura) - parseInt(sesionA.nFactura);
          }
        }
        if (this.ordenFiltros === 5) {
          if (this.ordenOrdenCompra === 0) {
            return (
              parseInt(sesionA.ordenCompra) - parseInt(sesionB.ordenCompra)
            );
          }
          if (this.ordenOrdenCompra === 1) {
            return (
              parseInt(sesionB.ordenCompra) - parseInt(sesionA.ordenCompra)
            );
          }
        }
        if (this.ordenFiltros === 6) {
          if (this.ordenMontoPago === 0) {
            return parseInt(sesionA.montoAP) - parseInt(sesionB.montoAP);
          }
          if (this.ordenMontoPago === 1) {
            return parseInt(sesionB.montoAP) - parseInt(sesionA.montoAP);
          }
        }

        return parseInt(sesionB.autorizado) - parseInt(sesionA.autorizado);
      });
  }

  get smAndDown(): boolean {
    return this.$vuetify.breakpoint.smAndDown;
  }
  get fechaAprobacion(): string {
    let aprobada = fechaLocal().toString();
    let array_fecha = aprobada.split("-");
    let dia = array_fecha[2];
    let mes = array_fecha[1];
    let ano = array_fecha[0];
    console.log(array_fecha);
    let fecha = dia + "-" + mes + "-" + ano;

    console.log(fecha);
    return fecha;
  }

  actualizarEstadoApruebo(item: Prenomina): void {
    let estado: EstadoPrenomina;

    if (this.usuario) {
      item.autorizado = this.usuario?.rut;
      console.log(item.autorizado);
    }

    item.estado === "pendiente"
      ? (estado = "aprobado")
      : (estado = "pendiente");
    item.estado = estado;
    item.fechaAprobacion = this.fechaAprobacion;
    this.onCambiarEstado(item);
  }

  //   actualizarFiltroCentroCosto(): string {
  //   let estado: EstadoPrenomina;

  //   if (this.usuario) {
  //     item.autorizado = this.usuario?.rut;
  //     console.log(item.autorizado);
  //   }

  //   item.estado === "pendiente"
  //     ? (estado = "aprobado")
  //     : (estado = "pendiente");
  //   item.estado = estado;
  //   item.fechaAprobacion = this.fechaAprobacion;
  //   this.onCambiarEstado(item);
  // }

  @Emit("cambiar-estado")
  onCambiarEstado(prenomina: Prenomina): Prenomina {
    return prenomina;
  }

  @Emit("abrir-info")
  onAbrirInfo(prenomina: Prenomina): Prenomina {
    return prenomina;
  }

  @Emit("abrir-editar")
  onAbrirEditar(prenomina: Prenomina): Prenomina {
    return prenomina;
  }

  @Emit("abrir-eliminar")
  onAbrirEliminar(prenomina: Prenomina): Prenomina {
    return prenomina;
  }

  prenomina: Prenomina | null = null;
  dialogoInfo = false;
  dialogoFormulario = false;
  dialogoEliminar = false;
  dialogoExito = {
    model: false,
    texto: "",
    icono: "",
  };
}
