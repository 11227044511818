






















































































































































































































































































































































// decoradores
import { Component, Vue, Prop } from "vue-property-decorator";

import "@/helpers/extensions";

@Component({})
export default class TablaMapeo extends Vue {
  @Prop() readonly funcionCuentaTotal!: number;
  @Prop() readonly funcionCuentaAprobados!: number;
  @Prop() readonly funcionCuentaPendientes!: number;
  @Prop() readonly montoCuentaTotal!: number;
  @Prop() readonly montoCuentaAprobados!: number;
  @Prop() readonly montoCuentaPendientes!: number;
}
