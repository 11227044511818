import { RouteConfig } from "vue-router";
import MisCursos from "@/views/MisCursos.vue";

const misCursos: RouteConfig = {
    path: "/misCursos",
    name: "misCursos",
    component: MisCursos,
};

export default misCursos;
