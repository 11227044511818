import { RouteConfig } from "vue-router";
import Lecturas from "@/views/Lecturas.vue";

const lecturas: RouteConfig = {
    path: "/lecturas",
    name: "lecturas",
    component: Lecturas,
};

export default lecturas;
