








































































































































































































































































































import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
// helpers
import { idtify } from "@/helpers/string";
// tipos
import { Rutas } from "@/typings/store/modules/rutas";
import {
  Permisos,
  TipoUsuario,
  Usuario,
} from "@/typings/store/plugins/easyFirestore/usuarios";
// validaciones
import { email, required } from "vuelidate/lib/validators";
import { DataFormularioUsuario } from "@/typings/components/usuarios/formulario";
import { Ciudad } from "@/typings/store/plugins/easyFirestore/ciudades";
import { Region } from "@/typings/store/plugins/easyFirestore/regiones";
import SVGIcon from "@/components/SVGIcon.vue";
import { Archivo, Archivos } from "@/typings/store/modules/archivos";
import VistaPreviaImagen from "@/components/VistaPreviaImagen.vue";

@Component({
  components: {
    SVGIcon,
    VistaPreviaImagen,
  },
  validations: {
    rut: { required },
    email: { required, email },
    nombre: { required },
    tipo: { required },
    telefono: { required },
    profesion: { required },
    ciudad: { required },
    region: { required },
  },
})
export default class FormularioUsuario extends Vue {
  @Prop() readonly usuario!: Usuario | null;
  @Prop() readonly rutas!: Rutas;
  @Prop() readonly ciudades!: Ciudad[];
  @Prop() readonly cargando!: boolean;
  @Prop() readonly regiones!: Region[];

  created(): void {
    Object.keys(this.rutas).forEach((key) => {
      this.$set(this.permisos, key, false);
    });
    // console.log(this.permisos);

    const usuario = this.usuario;
    // console.log(usuario);
    this.fecha = new Date().toISOString();
    if (!usuario) {
      return;
    }
    this.id = usuario.id ?? "";
    this.email = usuario.email;
    this.rut = usuario.rut ?? "";
    this.imagen = Object.values(usuario.imagen ?? {})[0] ?? null;
    this.nombre = usuario.nombre ?? "";
    this.fecha = usuario.fecha;
    this.region = usuario.region;
    this.ciudad = usuario.ciudad;
    this.telefono = usuario.telefono ?? "";
    this.profesion = usuario.profesion ?? "";
    this.permisos = Object.assign({}, this.permisos, usuario.permisos);
    this.tipo = usuario.tipo;
    console.log("-usuario-", usuario);
  }

  vistaPrevia = {
    model: false,
    url: "",
  };
  id: string | null = null;
  fecha = "";
  email = "";
  imagen: Archivo | null = null;
  rut = "";
  nombre = "";
  telefono = "";
  profesion = "";
  tipo: TipoUsuario = "relator";
  ciudad: Ciudad | null = null;
  region: Region | null = null;
  estado: "libre" | "bloqueado" = "libre";
  archivosBorrar: Archivos = {};
  permisos: Permisos = {
    ["pagos"]: true,
    ["perfil"]: true,
    ["cursos"]: true,
    ["agenda"]: true,
    ["notificaciones"]: true,
    ["inscripciones"]: true,
  };
  tipos = [
    { value: "admin", text: "Administrador" },
    { value: "cliente", text: "Cliente" },
    { value: "relator", text: "Relator" },
    { value: "supervisor", text:  "Supervisor" },
    { value: "operador", text: "Operador" },
    { value: "administrador_locales", text: "Administrador Locales",  },
    { value: "gerente_operaciones", text: "Gerente Operaciones" },
    { value: "gerente_general", text: "Gerente General" },
    { value: "gerente_comercial", text: "Gerente Comercial" },
  ];
  get nombreFoto(): string {
    return this.imagen?.file?.name ?? this.imagen?.nombre ?? "";
  }

  get titulo(): string {
    return this.usuario ? "editar usuario" : "nuevo usuario";
  }

  // get tipos(): Array<{ text: string; value: string }> {
  //   return [
  //     { text: "Administrador", value: "admin" },
  //     { text: "Relator", value: "relator" },
  //     { text: "Supervisor", value: "supervisor" },
  //     { text: "Operador", value: "operador" },
  //     { text: "Administrador Locales", value: "administrador_locales" },
  //   ];
  // }

  get filtroCiudades(): Array<Ciudad> {
    return this.ciudades.filter((ciudad) => {
      if (this.region?.nombre === ciudad.region.nombre) {
        return ciudad;
      }
      //     // return this.regiones.some((region) => {
      //     //   return region.nombre == ciudad.region.nombre;
      //     // });
    });
  }

  get permisosKey(): Array<string> {
    // return Object.keys(this.permisos).filter((key) => {
    //   if(this.rutas[key].tipos.includes(this.tipo)){
    //     this.permisos[key] = true;
    //   }
    // });
    return Object.keys(this.permisos).filter((key) => {
      if (!this.rutas[key]) {
        return false;
      }
      const tipo = this.rutas[key].tipos.includes(this.tipo);
      return tipo;
    });
  }

  get rutError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.rut.$dirty) return errors;
    if (!this.$v.rut.required) errors.push("Completar datos obligatorios");
    return errors;
  }

  get emailError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.email.$dirty) return errors;
    if (!this.$v.email.required) errors.push("Completar datos obligatorios");
    if (!this.$v.email.email) errors.push("El campo tiene que tener un email");
    return errors;
  }

  get nombreError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.nombre.$dirty) return errors;
    if (!this.$v.nombre.required) errors.push("Completar datos obligatorios");
    return errors;
  }

  get tipoError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.tipo.$dirty) return errors;
    if (!this.$v.tipo.required) errors.push("Completar datos obligatorios");
    return errors;
  }

  get telefonoError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.telefono.$dirty) return errors;
    if (!this.$v.telefono.required) errors.push("Completar datos obligatorios");
    return errors;
  }

  get profesionError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.profesion.$dirty) return errors;
    if (!this.$v.profesion.required)
      errors.push("Completar datos obligatorios");
    return errors;
  }

  get ciudadError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.ciudad.$dirty) return errors;
    if (!this.$v.ciudad.required) errors.push("Completar datos obligatorios");
    return errors;
  }

  get regionError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.region.$dirty) return errors;
    if (!this.$v.region.required) errors.push("Completar datos obligatorios");
    return errors;
  }

  @Emit("close")
  onClose(): null {
    return null;
  }

  @Emit("cerrar-dialogo")
  onCerrarDialogo(): void {
    return;
  }

  @Emit("click-guardar")
  onClickGuardar(data: DataFormularioUsuario): DataFormularioUsuario {
    return data;
  }

  @Watch("permisosKey", { immediate: true })
  onTipoChange(): void {
    console.log("a");
    for (const key of Object.keys(this.permisos)) {
      if (this.permisos[key]) {
        this.permisos[key] = false;
      }
    }
    this.permisosKey.forEach((key) => {
      this.permisos[key] = true;
    });
  }

  nombrePermiso(key: string): string {
    return this.rutas[key].text;
  }

  cargarFoto(ev: Event): void {
    const input = ev.target as HTMLInputElement;
    const file = input?.files ? input.files[0] : null;
    if (file) {
      if (file.type.includes("image")) {
        const fecha = new Date();
        const extencion = file.name.split(".").pop() ?? "";
        const id = `usuarios/${
          this.nombre
        }/imagen/${fecha.valueOf()}.${extencion}`;
        this.imagen = {
          id: id,
          nombre: file.name,
          file: file,
        };
      }
    }
  }

  clickInputFoto(): void {
    const input = this.$refs.inputFoto as HTMLInputElement;
    input.click();
  }

  abrirVistaPrevia(imagen: Archivo): void {
    this.vistaPrevia = {
      model: true,
      url: this.urlArchivo(imagen),
    };
  }

  cerrarVistaPrevia(): void {
    this.vistaPrevia = {
      model: false,
      url: "",
    };
  }

  eliminarFoto(): void {
    if (this.usuario && this.imagen?.id) {
      this.archivosBorrar[this.imagen.id] = this.imagen;
    }
    this.imagen = null;
  }

  urlArchivo(media: Archivo): string {
    return media.url ?? URL.createObjectURL(media.file);
  }

  guardar(): void {
    this.$v.$touch();
    console.log(this.$v.$invalid);
    console.log(this.rut.validateRUT());
    if (this.$v.$invalid || !this.rut.validateRUT()) {
      return;
    }
    const doc: Usuario = {
      id: idtify(this.email),
      rut: this.rut.toLowerCase(),
      email: this.email.toLowerCase(),
      nombre: this.nombre.toLowerCase(),
      tipo: this.tipo,
      permisos: this.permisos,
      region: this.region,
      ciudad: this.ciudad,
      profesion: this.profesion,
      telefono: this.telefono,
      fecha: this.fecha ?? new Date().toISOString(),
      estado: this.estado,
    };
    if (this.id) {
      doc.id = this.id;
    }
    const data: DataFormularioUsuario = {
      doc: doc,
      borrar: this.archivosBorrar,
      imagen: {},
    };
    if (this.imagen) {
      data.imagen = {
        [idtify(this.imagen.id)]: this.imagen,
      };
    }
    this.onClickGuardar(data);
  }
}
