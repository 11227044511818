import { RouteConfig } from "vue-router";
import Tarifas from "@/views/Tarifas.vue";

const tarifas: RouteConfig = {
    path: "/tarifas",
    name: "tarifas",
    component: Tarifas,
};

export default tarifas;
