





























// decoradores
import { Component, Vue, Prop } from "vue-property-decorator";
@Component
export default class BotonesCrud extends Vue {
  @Prop() readonly showEditar?: boolean;
  @Prop() readonly showReporte?: boolean;
  @Prop() readonly showEliminar?: boolean;
}
