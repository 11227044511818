















































































































































































// decoradores
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
// tipos
import { HeadersDataTable } from "@/typings/vuetify/vDataTable";
import { CodCuenta } from "@/typings/store/plugins/easyFirestore/codCuentas";
import { ItemsTablaCodCuentas } from "@/typings/components/codCuentas/tabla";
import "@/helpers/extensions";

@Component
export default class TablaCodCuentas extends Vue {
  @Prop() readonly opciones!: boolean;
  @Prop() readonly search!: string;
  @Prop() readonly codCuentas!: Array<CodCuenta>;

  options = {
    itemsPerPage: 15,
  };
  headerProps = {
    sortIcon: "mdi-chevron-down",
  };

  get headers(): HeadersDataTable {
    return [
      //{text: "", value: "estado", sortable: false},
      { text: "Nombre", value: "pccodi" },
      { text: "Posicion", value: "pcnivel" },
      { text: "Nombre", value: "pclnivel" },
      { text: "Posicion", value: "pcdesc" },
      { text: "Nombre", value: "pccodi2" },
      { text: "", value: "opciones", sortable: false },
    ];
  }

  get items(): ItemsTablaCodCuentas {
    return this.codCuentas.map((codCuenta) => {
      const pccodi = codCuenta.PCCODI;
      const pcnivel = codCuenta.PCNIVEL;
      const pclnivel = codCuenta.PCLNIVEL;
      const pcdesc = codCuenta.PCDESC;
      const pccodi2 = codCuenta.PCCODI2;

      return {
        pccodi,
        pcnivel,
        pclnivel,
        pcdesc,
        pccodi2,

        data: codCuenta,
      };
    });
  }

  //   actualizarEstado(item: Usuario): void {
  //     let estado: EstadoUsuario;
  //     item.estado === 'libre' ? estado = 'bloqueado' : estado = 'libre';
  //     item.estado = estado;
  //     this.onCambiarEstado(item);
  //   }

  //   @Emit("cambiar-estado")
  //   onCambiarEstado(usuario: Usuario): Usuario {
  //     return usuario;
  //   }

  @Emit("abrir-info")
  onAbrirInfo(codCuenta: CodCuenta): CodCuenta {
    return codCuenta;
  }

  @Emit("abrir-editar")
  onAbrirEditar(codCuenta: CodCuenta): CodCuenta {
    return codCuenta;
  }

  @Emit("abrir-eliminar")
  onAbrirEliminar(codCuenta: CodCuenta): CodCuenta {
    return codCuenta;
  }
}
