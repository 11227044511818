





































































































// decoradores
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { Proveedor } from "@/typings/store/plugins/easyFirestore/proveedores";
import SVGIcon from "@/components/SVGIcon.vue";
// tipos

// tipos
@Component({
  components: {
    SVGIcon,
  },
})
export default class InfoProveedor extends Vue {
  @Prop() readonly proveedor!: Proveedor;
  @Prop({ type: Boolean }) readonly showTitulo!: boolean;

  get id(): string {
    return this.proveedor.id || "N/R";
  }

  get codigo(): string {
    return this.proveedor.CodAux || "N/R";
  }

  get nombre(): string {
    return this.proveedor.NomAux || "N/R";
  }

  get rut(): string {
    return this.proveedor.RutAux || "N/R";
  }

  get nombreAuxiliar(): string {
    return this.proveedor.NomAux2 || "N/R";
  }

  @Emit("cerrar")
  onCerrar(): void {
    return;
  }
}
