


































































































































// decoradores
import { Vue, Component } from "vue-property-decorator";
// vuex
import { mapActions, mapGetters } from "vuex";
// tipos

// componentes
import TablaPlanCuentaIngreso from "@/components/planCuentasIngresos/Tabla.vue";
import FormularioPlanCuentaIngreso from "@/components/planCuentasIngresos/Formulario.vue";
import { DataFormularioPlanCuentaIngreso } from "@/typings/components/planCuentasIngresos/formulario";
import DialogoExito from "@/components/DialogoExito.vue";
import DialogoEliminar from "@/components/DialogoEliminar.vue";
import InfoPlanCuentaIngreso from "@/components/planCuentasIngresos/Info.vue";
//import BotonesCrud from "@/components/BotonesCrud.vue";
import MensajeEliminar from "@/components/MensajeEliminar.vue";
//import TituloVista from "@/components/TituloVista.vue";
import {
  PlanCuentaIngreso,
  PlanCuentasIngresos,
  //  Regiones,
} from "@/typings/store/plugins/easyFirestore/planCuentasIngresos";
//import { DataFormularioRegion } from "@/typings/components/regiones/formulario";
import CargarPlanCuentasIngresosExcel from "@/components/excel/CargarPlanCuentasIngresos.vue";

const routeGuard = (vm: Vue): boolean => {
  const permisos = vm.getUsuario?.permisos;
  if (permisos && permisos["planCuentasIngresos"]) {
    return true;
  }

  const rutas = vm.getRutasUsuario;
  if (rutas.length > 0) {
    const to = rutas[0].to;
    if (to) {
      if (vm.$route.name !== to.name) {
        vm.$router.replace(to);
      }
      return false;
    }
  }
  if (vm.$route.name !== "inicio") {
    vm.$router.replace({ name: "inicio" });
  }
  return false;
};

@Component({
  computed: mapGetters({
    getUsuarios: "usuarios/get",
    getPlanCuentasIngresos: "planCuentasIngresos/get",
    getArrayUsuarios: "usuarios/getArray",
    getUsuario: "usuario/get",
    getRutas: "rutas/get",
    getRutasUsuario: "usuario/getRutas",
    getArrayPlanCuentasIngresos: "planCuentasIngresos/getArray",
  }),
  methods: mapActions({
    setPlanCuentaIngreso: "planCuentasIngresos/set",
    deletePlanCuentaIngreso: "planCuentasIngresos/delete",
    insertBatchPlanCuentasIngresos: "planCuentasIngresos/insertBatch",
    //setArchivo: "archivos/set",
    //deleteArchivo: "archivos/delete",
  }),
  beforeRouteEnter(to, from, next) {
    next(routeGuard);
  },
  components: {
    DialogoEliminar,
    DialogoExito,
    TablaPlanCuentaIngreso,
    FormularioPlanCuentaIngreso,
    InfoPlanCuentaIngreso,
    //BotonesCrud,
    MensajeEliminar,
    CargarPlanCuentasIngresosExcel,
    //TituloVista,
  },
})
export default class VistaPlanCuentasIngresos extends Vue {
  created(): void {
    if (!routeGuard(this)) {
      return;
    }
  }

  cargando = false;
  dialogoExito = {
    model: false,
    texto: "",
    icono: "",
  };
  search = "";
  planCuentaIngreso: PlanCuentaIngreso | null = null;
  dialogoInfo = false;
  dialogoFormulario = false;
  dialogoEliminar = false;
  textoEliminar = "¿ESTÁS SEGURO QUE DESEAS ELIMINAR ESTE BANCO?";
  dialogoCargarExcel = false;

  get planCuentasIngresos(): PlanCuentasIngresos {
    return this.getPlanCuentasIngresos;
  }

  get items(): Array<PlanCuentaIngreso> {
    return this.getArrayPlanCuentasIngresos;
  }

  clickItem(val: { id: string } | null): void {
    this.planCuentaIngreso = val ? this.planCuentasIngresos[val.id] : null;
  }

  clickPlus(): void {
    this.planCuentaIngreso = null;
    this.dialogoFormulario = true;
  }

  async clickGuardar(data: DataFormularioPlanCuentaIngreso): Promise<void> {
    this.cargando = true;
    const doc = data.doc;
    await this.setPlanCuentaIngreso(doc);
    if (doc.id != this.planCuentaIngreso?.id) {
      this.dialogoExito = {
        model: true,
        texto: "¡PlanCuentaIngreso creado con éxito!",
        icono: require("../assets/icons/double-check-duo.svg"),
      };
    }
    if (doc.id == this.planCuentaIngreso?.id) {
      this.dialogoExito = {
        model: true,
        texto: "¡PlanCuentaIngreso editado con éxito!",
        icono: require("../assets/icons/editar.svg"),
      };
      this.planCuentaIngreso = doc;
    }
    this.cerrarDialogoFormulario();
    this.cargando = false;
  }

  cerrarDialogoExito(): void {
    this.dialogoExito = {
      model: false,
      texto: "",
      icono: "",
    };
  }

  clickEliminar(): void {
    const planCuentaIngreso = this.planCuentaIngreso;
    if (!planCuentaIngreso?.id) {
      console.error("error id: ", planCuentaIngreso);
      return;
    }
    this.deletePlanCuentaIngreso(planCuentaIngreso.id);
    this.planCuentaIngreso = null;
    this.cerrarDialogoEliminar();
  }

  abrirDialogoInfo(planCuentaIngreso: PlanCuentaIngreso): void {
    if (!planCuentaIngreso.id) {
      console.error("error id: ", planCuentaIngreso);
      return;
    }
    const doc = this.planCuentasIngresos[planCuentaIngreso.id];
    if (doc) {
      this.planCuentaIngreso = doc;
      this.dialogoInfo = true;
    }
  }

  cerrarDialogoInfo(): void {
    this.dialogoInfo = false;
    this.planCuentaIngreso = null;
  }

  abrirDialogoFormulario(planCuentaIngreso: PlanCuentaIngreso): void {
    if (!planCuentaIngreso.id) {
      console.error("error id: ", planCuentaIngreso);
      return;
    }
    const doc = this.planCuentasIngresos[planCuentaIngreso.id];
    if (doc) {
      this.planCuentaIngreso = doc;
      this.dialogoFormulario = true;
    }
  }

  abrirDialogoEliminar(planCuentaIngreso: PlanCuentaIngreso): void {
    if (!planCuentaIngreso.id) {
      console.error("error id: ", planCuentaIngreso);
      return;
    }
    const doc = this.planCuentasIngresos[planCuentaIngreso.id];
    if (doc) {
      this.dialogoEliminar = true;
      this.planCuentaIngreso = doc;
    }
  }

  cerrarDialogoEliminar(): void {
    this.dialogoEliminar = false;
    this.planCuentaIngreso = null;
  }

  cerrarDialogoFormulario(): void {
    this.dialogoFormulario = false;
    this.planCuentaIngreso = null;
  }
}
