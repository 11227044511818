


































































































































// decoradores
import { Vue, Component } from "vue-property-decorator";
// vuex
import { mapActions, mapGetters } from "vuex";
// tipos

// componentes
import TablaBanco from "@/components/distribuidoresEnergia/Tabla.vue";
import FormularioDistribuidoresEnergia from "@/components/distribuidoresEnergia/Formulario.vue";
import { DataFormularioDistribuidorEnergia } from "@/typings/components/distribuidoresEnergia/formulario";
import DialogoExito from "@/components/DialogoExito.vue";
import DialogoEliminar from "@/components/DialogoEliminar.vue";
import InfoDistribuidorEnergia from "@/components/distribuidoresEnergia/Info.vue";
//import BotonesCrud from "@/components/BotonesCrud.vue";
import MensajeEliminar from "@/components/MensajeEliminar.vue";
//import TituloVista from "@/components/TituloVista.vue";
import {
  DistribuidorEnergia,
  DistribuidoresEnergia,
  //  Regiones,
} from "@/typings/store/plugins/easyFirestore/distribuidoresEnergia";
//import { DataFormularioRegion } from "@/typings/components/regiones/formulario";
import CargarDistribuidoresEnergiaExcel from "@/components/excel/CargarDistribuidoresEnergia.vue";

//import Exceljs from "exceljs";
const routeGuard = (vm: Vue): boolean => {
  const permisos = vm.getUsuario?.permisos;
  if (permisos && permisos["distribuidoresEnergia"]) {
    return true;
  }

  const rutas = vm.getRutasUsuario;
  if (rutas.length > 0) {
    const to = rutas[0].to;
    if (to) {
      if (vm.$route.name !== to.name) {
        vm.$router.replace(to);
      }
      return false;
    }
  }
  if (vm.$route.name !== "inicio") {
    vm.$router.replace({ name: "inicio" });
  }
  return false;
};

@Component({
  computed: mapGetters({
    getUsuarios: "usuarios/get",
    getDistribuidoresEnergia: "distribuidoresEnergia/get",
    getArrayUsuarios: "usuarios/getArray",
    getUsuario: "usuario/get",
    getRutas: "rutas/get",
    getRutasUsuario: "usuario/getRutas",
    getArrayDistribuidoresEnergia: "distribuidoresEnergia/getArray",
    getArrayCiudades: "ciudades/getArray",
  }),
  methods: mapActions({
    setDistribuidorEnergia: "distribuidoresEnergia/set",
    deleteDistribuidorEnergia: "distribuidoresEnergia/delete",
    insertBatchDistribuidoresEnergia: "distribuidoresEnergia/insertBatch",
    //setArchivo: "archivos/set",
    //deleteArchivo: "archivos/delete",
  }),
  beforeRouteEnter(to, from, next) {
    next(routeGuard);
  },
  components: {
    DialogoEliminar,
    DialogoExito,
    TablaBanco,
    FormularioDistribuidoresEnergia,
    InfoDistribuidorEnergia,
    //BotonesCrud,
    MensajeEliminar,
    CargarDistribuidoresEnergiaExcel,
    //TituloVista,
  },
})
export default class VistaBancos extends Vue {
  created(): void {
    if (!routeGuard(this)) {
      return;
    }
  }

  cargando = false;
  dialogoExito = {
    model: false,
    texto: "",
    icono: "",
  };
  search = "";
  distribuidorEnergia: DistribuidorEnergia | null = null;
  dialogoInfo = false;
  dialogoFormulario = false;
  dialogoEliminar = false;
  textoEliminar = "¿ESTÁS SEGURO QUE DESEAS ELIMINAR ESTE BANCO?";
  dialogoCargarExcel = false;

  get distribuidoresEnergia(): DistribuidoresEnergia {
    return this.getDistribuidoresEnergia;
  }

  get items(): Array<DistribuidorEnergia> {
    return this.getArrayDistribuidoresEnergia;
  }

  clickItem(val: { id: string } | null): void {
    this.distribuidorEnergia = val ? this.distribuidoresEnergia[val.id] : null;
  }

  clickPlus(): void {
    this.distribuidorEnergia = null;
    this.dialogoFormulario = true;
  }

  async clickGuardar(data: DataFormularioDistribuidorEnergia): Promise<void> {
    this.cargando = true;
    const doc = data.doc;
    await this.setDistribuidorEnergia(doc);
    if (doc.id != this.distribuidorEnergia?.id) {
      this.dialogoExito = {
        model: true,
        texto: "¡Banco creado con éxito!",
        icono: require("../assets/icons/double-check-duo.svg"),
      };
    }
    if (doc.id == this.distribuidorEnergia?.id) {
      this.dialogoExito = {
        model: true,
        texto: "¡Banco editado con éxito!",
        icono: require("../assets/icons/editar.svg"),
      };
      this.distribuidorEnergia = doc;
    }
    this.cerrarDialogoFormulario();
    this.cargando = false;
  }

  cerrarDialogoExito(): void {
    this.dialogoExito = {
      model: false,
      texto: "",
      icono: "",
    };
  }

  clickEliminar(): void {
    const distribuidorEnergia = this.distribuidorEnergia;
    if (!distribuidorEnergia?.id) {
      console.error("error id: ", distribuidorEnergia);
      return;
    }
    this.deleteDistribuidorEnergia(distribuidorEnergia.id);
    this.distribuidorEnergia = null;
    this.cerrarDialogoEliminar();
  }

  abrirDialogoInfo(distribuidorEnergia: DistribuidorEnergia): void {
    if (!distribuidorEnergia.id) {
      console.error("error id: ", distribuidorEnergia);
      return;
    }
    const doc = this.distribuidoresEnergia[distribuidorEnergia.id];
    if (doc) {
      this.distribuidorEnergia = doc;
      this.dialogoInfo = true;
    }
  }

  cerrarDialogoInfo(): void {
    this.dialogoInfo = false;
    this.distribuidorEnergia = null;
  }

  abrirDialogoFormulario(distribuidorEnergia: DistribuidorEnergia): void {
    if (!distribuidorEnergia.id) {
      console.error("error id: ", distribuidorEnergia);
      return;
    }
    const doc = this.distribuidoresEnergia[distribuidorEnergia.id];
    if (doc) {
      this.distribuidorEnergia = doc;
      this.dialogoFormulario = true;
    }
  }

  abrirDialogoEliminar(distribuidorEnergia: DistribuidorEnergia): void {
    if (!distribuidorEnergia.id) {
      console.error("error id: ", distribuidorEnergia);
      return;
    }
    const doc = this.distribuidoresEnergia[distribuidorEnergia.id];
    if (doc) {
      this.dialogoEliminar = true;
      this.distribuidorEnergia = doc;
    }
  }

  cerrarDialogoEliminar(): void {
    this.dialogoEliminar = false;
    this.distribuidorEnergia = null;
  }

  cerrarDialogoFormulario(): void {
    this.dialogoFormulario = false;
    this.distribuidorEnergia = null;
  }
}
