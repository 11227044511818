

















































































































































































// decoradores
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
// vuex
import { mapGetters } from "vuex";
// componentes

import {
  //ColorPrenomina,
  EstadoPrenomina,
  Prenomina,
} from "@/typings/store/plugins/easyFirestore/prenominas";
import { ItemsTablaMapeoGastos } from "@/typings/components/mapeoGastos/tabla";
import {
  Usuario,
  //TipoUsuario,
} from "@/typings/store/plugins/easyFirestore/usuarios";
import { fechaLocal } from "@/helpers/date";
import "@/helpers/extensions";
import { MapeoGasto } from "@/typings/store/plugins/easyFirestore/MapeoGastos";

@Component({
  computed: mapGetters({
    getPrenominas: "prenominas/get",
  }),
  components: {},
})
export default class TablaMapeo extends Vue {
  @Prop() readonly prenominas!: Array<Prenomina>;
  @Prop() readonly ArrayMapeoGastos!: Array<MapeoGasto>;
  @Prop() readonly item!: ItemsTablaMapeoGastos;
  @Prop() readonly usuario?: Usuario;

  get items(): ItemsTablaMapeoGastos {
    return this.ArrayMapeoGastos.map((mapeoGasto) => {
      //const id = mapeoGasto.id;
      const centroCosto = mapeoGasto.centroCosto;
      const rut = mapeoGasto.rut;
      const nombreProveedor = mapeoGasto.nombreProveedor;
      const tipoGasto = mapeoGasto.tipoGasto;
      const cuentaGasto = mapeoGasto.cuentaGasto;
      const cuentaEstadoResultado = mapeoGasto.cuentaEstadoResultado;
      const nCliente = mapeoGasto.nCliente;
      const observacion = mapeoGasto.observacion;
      const recurrente = mapeoGasto.recurrente;
      const fechaAsignacion = mapeoGasto.fechaAsignacion;
      const descripcionCuenta = mapeoGasto.descripcionCuenta;
      const codigoCuenta = mapeoGasto.codigoCuenta;
      const tipoDocumento = mapeoGasto.tipoDocumento;
      const ordenaRut = mapeoGasto.rut.replace(/\.+/g, "");

      const color = "#D5D8E3";
      return {
        //  id,
        centroCosto,
        rut,
        nombreProveedor,
        tipoGasto,
        cuentaGasto,
        cuentaEstadoResultado,
        observacion,
        nCliente,
        recurrente,
        fechaAsignacion,
        descripcionCuenta,
        codigoCuenta,
        tipoDocumento,
        ordenaRut,
        color,

        data: mapeoGasto,
      };
    }).sort((sesionA, sesionB) => {
      return parseInt(sesionA.ordenaRut) - parseInt(sesionB.ordenaRut);
    });
  }

  @Emit("cambiar-estado")
  onCambiarEstado(prenomina: Prenomina): Prenomina {
    return prenomina;
  }

  @Emit("cambiar-estado-rechazo")
  onCambiarEstadoRechazo(prenomina: Prenomina): Prenomina {
    return prenomina;
  }

  @Emit("abrir-info")
  onAbrirInfo(prenomina: Prenomina): Prenomina {
    return prenomina;
  }

  @Emit("abrir-editar")
  onAbrirEditar(prenomina: Prenomina): Prenomina {
    return prenomina;
  }

  @Emit("abrir-eliminar")
  onAbrirEliminar(prenomina: Prenomina): Prenomina {
    return prenomina;
  }

  prenomina: Prenomina | null = null;
  dialogoInfo = false;
  dialogoFormulario = false;
  dialogoEliminar = false;
  dialogoExito = {
    model: false,
    texto: "",
    icono: "",
  };
}
