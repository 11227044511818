//tipos
import { GetterTree } from "vuex";
import { State } from "@/typings/store";
import {
  Sesion,
  Sesiones,
  StateSesiones,
} from "@/typings/store/plugins/easyFirestore/sesiones";

const getters: GetterTree<StateSesiones, State> = {
  get(state, getters) {
    return (getters["getArray"] as Array<Sesion>).reduce((docs, doc) => {
      if (!doc?.id) {
        console.error("doc.id == undefined => ", doc);
        return docs;
      }
      docs[doc.id] = doc;
      return docs;
    }, {} as Sesiones);
  },
  getArray(state) {
    return Object.values(state.all).map((doc) => {
      return JSON.parse(JSON.stringify(doc)) as Sesion;
    });
  },
};

const sesiones = {
  firestorePath: "dev/v1/sesiones",
  firestoreRefType: "collection",
  moduleName: "sesiones",
  statePropName: "all",
  namespaced: true,
  serverChange: {
    convertTimestamps: {
      created_at: "%convertTimestamp%", // default
      updated_at: "%convertTimestamp%", // default
    },
  },
  state: {},
  getters,
  mutations: {},
  actions: {},
};

export default sesiones;
