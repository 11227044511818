import { RouteConfig } from "vue-router";
import Proveedores from "@/views/Proveedores.vue";

const proveedores: RouteConfig = {
    path: "/proveedores",
    name: "proveedores",
    component: Proveedores,
};

export default proveedores;
