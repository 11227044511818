

























































































// decoradores
import { Component, Emit, Prop, Vue} from "vue-property-decorator";
// tipos
import { HeadersDataTable } from "@/typings/vuetify/vDataTable";
import {Region} from "@/typings/store/plugins/easyFirestore/regiones";
import {ItemsTablaRegiones} from "@/typings/components/regiones/tabla";
@Component
export default class TablaUsuarios extends Vue {
  @Prop() readonly opciones!: boolean;
  @Prop() readonly search!: string;
  @Prop() readonly regiones!: Array<Region>;

  options = {
    itemsPerPage: -1,
  };


  get headers(): HeadersDataTable {
    return [
      { text: "Nombre", value: "nombre" },
      { text: "Opciones", value: "opciones" },
    ];
  }

  get items(): ItemsTablaRegiones {
    return this.regiones.map((region) => {
      const nombre = region.nombre;
      return {
        nombre,
        data: region,
      };
    });
  }
  @Emit("abrir-info")
  onAbrirInfo(region: Region): Region {
    return region;
  }

  @Emit("abrir-editar")
  onAbrirEditar(region: Region): Region {
    return region;
  }

  @Emit("abrir-eliminar")
  onAbrirEliminar(region: Region): Region{
    return region;
  }
}
