


































































































































































































































































































































































import { idtify } from "@/helpers/string";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
// helpers
//import { idtify } from "@/helpers/string";
// tipos
//import { Rutas } from "@/typings/store/modules/rutas";
//import {
//  Permisos,
//  TipoUsuario,
//  Usuario,
//} from "@/typings/store/plugins/easyFirestore/usuarios";
import {
  CajaChicaLinares,
  //Bancos
} from "@/typings/store/plugins/easyFirestore/cajaChicasLinares";
// validaciones
import {
  // email,
  required,
} from "vuelidate/lib/validators";
import { DataFormularioCajaChicaLinares } from "@/typings/components/cajaChicasLinares/formulario";
import SVGIcon from "@/components/SVGIcon.vue";

import { Archivo, Archivos } from "@/typings/store/modules/archivos";
import VistaPreviaPdf from "@/components/VistaPreviaPdf.vue";

@Component({
  components: {
    SVGIcon,
    VistaPreviaPdf,
  },
  validations: {
    // nombre: { required },
    // posicion: { required },
    fecha: { required },
    rutProveedor: { required },
    nombreProveedor: { required },
    accion: { required },
    documento: { required },
    concepto: { required },
    descripcion: { required },
  },
})
export default class FormularioCajaChicaLinares extends Vue {
  @Prop() readonly cajaChicaLinares!: CajaChicaLinares | null;
  @Prop() readonly cargando!: boolean;
  @Prop() readonly totalItems!: number;
  @Prop() readonly total!: number;
  //   @Prop() readonly rutas!: Rutas;
  //   @Prop() readonly ciudades!: Ciudad[];
  //   @Prop() readonly cargando!: boolean;
  //   @Prop() readonly regiones!: Region[];

  created(): void {
    // Object.keys(this.rutas).forEach((key) => {
    //   this.$set(this.permisos, key, false);
    // });
    // console.log(this.permisos);
    const cajaChicaLinares = this.cajaChicaLinares;
    if (!cajaChicaLinares) {
      return;
    }
    this.id = cajaChicaLinares.id ?? "";
    // this.nombre = cajaChicaLinares.nombre;
    // this.posicion = cajaChicaLinares.posicion ?? "";
    this.checkbox = cajaChicaLinares.checkbox ?? false;
    this.numero = cajaChicaLinares.numero;
    this.fecha = cajaChicaLinares.fecha.toDate().format("YYYY-MM-DD") ?? "";
    this.saldoActual = cajaChicaLinares.saldoActual;
    this.rutProveedor = cajaChicaLinares.rutProveedor ?? "";
    this.nombreProveedor = cajaChicaLinares.nombreProveedor ?? "";
    this.accion = cajaChicaLinares.accion ?? "";
    this.ingreso = cajaChicaLinares.ingreso ?? "";
    this.egreso = cajaChicaLinares.egreso ?? "";
    this.documento = cajaChicaLinares.documento ?? "";
    this.concepto = cajaChicaLinares.concepto ?? "";
    this.descripcion = cajaChicaLinares.descripcion ?? "";
    this.archivo = Object.values(cajaChicaLinares.archivo ?? {})[0] ?? null;
  }

  id: string | null = null;

  // nombre = "";
  // posicion = "";
  numero = 0;
  fecha = "";
  saldoActual = 0;
  rutProveedor = "";
  nombreProveedor = "";
  accion = "";
  ingreso = "";
  egreso = "";
  documento = "";
  concepto = "";
  descripcion = "";

  archivo?: Archivo | null = null;
  archivosBorrar: Archivos = {};

  vistaPrevia = {
    model: false,
    url: "",
  };

  menu = false;
  checkbox = false;

  itemsAccion = ["ABONAR", "PAGAR"];
  itemsDocumento = [
    "SALDO INICIAL",
    "BOLETA DE PAGO",
    "C. DE CREDITO FISCAL",
    "FACTURA COMERCIAL",
    "NOTA DE CREDITO",
    "NOTA DE REVISIÓN",
    "NOTA DE ENVIO",
  ];

  optionsCosto = {
    locale: "es-CL",
    prefix: "$",
    suffix: "",
    length: 18,
    precision: 0,
  };

  get numeroDato(): number {
    let numero = this.totalItems + 1;

    return numero;
  }

  get titulo(): string {
    return this.cajaChicaLinares
      ? "Editar Dato de Caja Chica"
      : "Nuevo Dato de Caja Chica";
  }

  get verificaNumero(): number {
    let numeroVerificar = this.numero;
    if (this.numero === 0) {
      numeroVerificar = this.numeroDato;
      return numeroVerificar;
    } else {
      return this.numero;
    }
  }

  get verificaSaldoActual(): number {
    let saldoActualVerificar = this.saldoActual;
    if (this.saldoActual === 0 && this.verificaNumero !== 0) {
      saldoActualVerificar = this.total;
      return saldoActualVerificar;
    } else {
      return this.saldoActual;
    }
  }

  get verificaRutProveedorTrue(): string {
    if (this.checkbox === true) {
      this.rutProveedor = "79.991.280-5";
      this.nombreProveedor = "INVERSIONES SANTA FIDELMIRA S.A";

      return this.rutProveedor;
    } else {
      return this.rutProveedor;
    }
  }

  // get verificaNombreProveedor(): string {
  //   if (this.checkbox === true) {
  //     this.nombreProveedor = "INVERSIONES SANTA FIDELMIRA S.A";
  //     return this.nombreProveedor;
  //   } else {
  //     this.nombreProveedor = "";
  //     return "";
  //   }
  // }

  //   get permisosKey(): Array<string> {
  //     // return Object.keys(this.permisos).filter((key) => {
  //     //   if(this.rutas[key].tipos.includes(this.tipo)){
  //     //     this.permisos[key] = true;
  //     //   }
  //     // });
  //     return Object.keys(this.permisos).filter((key) => {
  //       if (!this.rutas[key]) {
  //         return false;
  //       }
  //       const tipo = this.rutas[key].tipos.includes(this.tipo);
  //       return tipo;
  //     });
  //   }
  get importeIngreso(): string {
    let importe = this.ingreso;
    if (importe === "") {
      return "0";
    }
    return importe;
  }

  get importeEgreso(): string {
    let importe = this.egreso;
    if (importe === "") {
      return "0";
    }
    return importe;
  }

  get numeroAuto(): number {
    return this.cajaChicaLinares?.numero ?? 0;
  }

  get fechaError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.fecha.$dirty) return errors;
    if (!this.$v.fecha.required) errors.push("");
    return errors;
  }

  get rutProveedorError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.rutProveedor.$dirty) return errors;
    if (!this.$v.rutProveedor.required) errors.push("");
    return errors;
  }

  get nombreProveedorError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.nombreProveedor.$dirty) return errors;
    if (!this.$v.nombreProveedor.required) errors.push("");
    return errors;
  }

  get accionError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.accion.$dirty) return errors;
    if (!this.$v.accion.required) errors.push("");
    return errors;
  }

  get documentoError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.documento.$dirty) return errors;
    if (!this.$v.documento.required) errors.push("");
    return errors;
  }

  get conceptoError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.concepto.$dirty) return errors;
    if (!this.$v.concepto.required) errors.push("");
    return errors;
  }

  get descripcionError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.descripcion.$dirty) return errors;
    if (!this.$v.descripcion.required) errors.push("");
    return errors;
  }

  @Emit("close")
  onClose(): null {
    return null;
  }

  @Emit("cerrar-dialogo")
  onCerrarDialogo(): void {
    return;
  }

  @Emit("click-guardar")
  onClickGuardar(data: DataFormularioCajaChicaLinares): DataFormularioCajaChicaLinares {
    return data;
  }

  //   @Watch("permisosKey", { immediate: true })
  //   onTipoChange(): void {
  //     console.log("a");
  //     for (const key of Object.keys(this.permisos)) {
  //       if (this.permisos[key]) {
  //         this.permisos[key] = false;
  //       }
  //     }
  //     this.permisosKey.forEach((key) => {
  //       this.permisos[key] = true;
  //     });
  //   }

  //   nombrePermiso(key: string): string {
  //     return this.rutas[key].text;
  //   }
  get nombreArchivo(): string {
    return this.archivo?.file?.name ?? this.archivo?.nombre ?? "";
  }

  cargarArchivo(ev: Event): void {
    const input = ev.target as HTMLInputElement;
    const file = input?.files ? input.files[0] : null;
    if (file) {
      if (file.type.includes("pdf")) {
        const fecha = new Date();
        const extencion = file.name.split(".").pop() ?? "";
        const id = `cajaChicasLinares/${
          this.verificaNumero
        }/archivo/${fecha.valueOf()}.${extencion}`;
        this.archivo = {
          id: id,
          nombre: file.name,
          file: file,
        };
      }
    }
  }

  clickInputArchivo(): void {
    const input = this.$refs.inputArchivo as HTMLInputElement;
    input.click();
  }

  abrirVistaPrevia(archivo: Archivo): void {
    this.vistaPrevia = {
      model: true,
      url: this.urlArchivo(archivo),
    };
  }

  cerrarVistaPrevia(): void {
    this.vistaPrevia = {
      model: false,
      url: "",
    };
  }

  eliminarArchivo(): void {
    if (this.cajaChicaLinares && this.archivo?.id) {
      this.archivosBorrar[this.archivo.id] = this.archivo;
    }
    this.archivo = null;
  }

  urlArchivo(media: Archivo): string {
    return media.url ?? URL.createObjectURL(media.file);
  }

  guardar(): void {
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }
    const doc: CajaChicaLinares = {
      checkbox: this.checkbox,
      numero: this.verificaNumero,
      fecha: this.fecha.toDate().format("DD-MM-YYYY") ?? "",
      saldoActual: this.verificaSaldoActual,
      rutProveedor: this.rutProveedor.toUpperCase(),
      nombreProveedor: this.nombreProveedor.toUpperCase(),
      accion: this.accion,
      ingreso: this.importeIngreso,
      egreso: this.importeEgreso,
      documento: this.documento,
      concepto: this.concepto,
      descripcion: this.descripcion.toUpperCase(),
    };
    if (this.id) {
      doc.id = this.id;
    }
    const data: DataFormularioCajaChicaLinares = {
      doc: doc,
      borrar: this.archivosBorrar,
      archivo: {},
    };
    if (this.archivo) {
      data.archivo = {
        [idtify(this.archivo.id)]: this.archivo,
      };
    }
    this.onClickGuardar(data);
  }
}
