















































































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import "@/helpers/extensions";
//import { Orden } from "@/typings/store/plugins/easyFirestore/ordenes";

@Component({
  components: {
    //  VistaPreviaPdf,
  },
})
export default class TablaVisualizacionPago extends Vue {

  @Prop() readonly prenominas!: Array<Array<string | number>>;
  // @Prop() readonly ordenes!: Array<Orden>;

  options = {
    itemsPerPage: -1,
  };
  headerProps = {
    sortIcon: "mdi-chevron-down",
  };


  ordenFecha = 0;
  ordenNumero = 0;
  ordenNombre = 0;
  ordenCC = 0;
  ordenProveedor = 0;
  ordenCostoTotal = 0;
  ordenSolicitante = 0;
  ordenFiltros = 0;
  ordenColor = 0;
  checkboxTipoGasto = false;

  ascenteFecha(): void {
    this.ordenFecha += 1;
    this.ordenFiltros = 1;
  }

  descenteFecha(): void {
    this.ordenFecha -= 1;
    this.ordenFiltros = 1;
  }

  ascenteNumero(): void {
    this.ordenNumero += 1;
    this.ordenFiltros = 2;
  }

  descenteNumero(): void {
    this.ordenNumero -= 1;
    this.ordenFiltros = 2;
  }

  ascenteNombre(): void {
    this.ordenNombre += 1;
    this.ordenFiltros = 3;
  }

  descenteNombre(): void {
    this.ordenNombre -= 1;
    this.ordenFiltros = 3;
  }

  ascenteCC(): void {
    this.ordenCC += 1;
    this.ordenFiltros = 4;
  }

  descenteCC(): void {
    this.ordenCC -= 1;
    this.ordenFiltros = 4;
  }

  ascenteProveedor(): void {
    this.ordenProveedor += 1;
    this.ordenFiltros = 5;
  }

  descenteProveedor(): void {
    this.ordenProveedor -= 1;
    this.ordenFiltros = 5;
  }

  ascenteCostoTotal(): void {
    this.ordenCostoTotal += 1;
    this.ordenFiltros = 6;
  }

  descenteCostoTotal(): void {
    this.ordenCostoTotal -= 1;
    this.ordenFiltros = 6;
  }

  ascenteSolicitante(): void {
    this.ordenSolicitante += 1;
    this.ordenFiltros = 7;
  }

  descenteSolicitante(): void {
    this.ordenSolicitante -= 1;
    this.ordenFiltros = 7;
  }

  ascenteColor(): void {
    this.ordenColor += 1;
    this.ordenFiltros = 8;
  }

  descenteColor(): void {
    this.ordenColor -= 1;
    this.ordenFiltros = 8;
  }


}
