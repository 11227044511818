





























































// decoradores
import { Vue, Component, Prop, Emit } from "vue-property-decorator";
// validaciones
import { required } from "vuelidate/lib/validators";
// tipos
import {
  CentroCosto,
  CentrosCostos,
} from "@/typings/store/plugins/easyFirestore/centrosCostos";
// api excel
import Excel from "exceljs";

interface Registro {
  // entrada: Date;
  // salida: Date;
  CodiCC: string;
  DescCC: string;
  NivelCC: string;
  Activo: string;
  Desc_formato: string;
  Comuna: string;
}

interface NombresCentrosCostos {
  [DescCC: string]: CentroCosto;
}

// const date = (value: Date) => {
//   return value.toString() != "Invalid Date";
// };

@Component({
  validations: {
    archivo: { required },
  },
})
export default class CargarExcel extends Vue {
  @Prop() readonly bancos!: CentrosCostos;

  archivo: Blob | null = null;
  cargando = false;
  clickCargar = false;

  despachos: Array<CentroCosto> = [];
  mensajes: Array<string> = [];

  get nombresBancos(): NombresCentrosCostos {
    const resultado: NombresCentrosCostos = {};
    Object.values(this.bancos).forEach((item) => {
      resultado[item.DescCC] = Object.assign({}, item);
    });
    return resultado;
  }

  // get rfidPatentes(): RfidPatentes {
  //   const resultado: RfidPatentes = {};
  //   Object.values(this.rfids).forEach((item) => {
  //     resultado[item.patente] = Object.assign({}, item);
  //   });
  //   return resultado;
  // }

  get archivoError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.archivo.$dirty) return errors;
    if (!this.$v.archivo.required) errors.push("Requerido");
    return errors;
  }

  @Emit("carga-excel")
  onCargaExcel(): Array<CentroCosto> {
    return this.despachos;
  }

  // valoresValidos(valores: Array<Excel.CellValue>): boolean {
  //   if (!valores[1] || !valores[2] ) {
  //     return false;
  //   }
  //   const DescCC = valores[1] ;
  //   const posicion = valores[2];
  //   //return !!entrada && !!salida && date(entrada) && date(salida);
  //   return DescCC && posicion
  // }

  async cargar(): Promise<void> {
    this.$v.$touch();
    if (this.$v.$invalid || !this.archivo) {
      return;
    }
    this.cargando = true;
    this.clickCargar = true;
    const workbook = new Excel.Workbook();
    const data = await this.archivo.arrayBuffer();
    await workbook.xlsx.load(data);
    // worksheet
    const worksheet = workbook.getWorksheet(1);
    console.log("registros", worksheet.actualRowCount - 1);
    if (worksheet.actualRowCount - 1 > 500) {
      console.log("registros mayores a 500");
      this.mensajes.push("error: cantidad de registros mayor a 500");
      this.cargando = false;
      return;
    }
    worksheet.eachRow((row, rowNumber) => {
      if (rowNumber != 1) {
        const valores = row.values as Array<Excel.CellValue>;
        if (valores[3] == "ejemplo") {
          console.log("error row", rowNumber);
          this.mensajes.push(
            "error: row " +
              rowNumber +
              " invalida, contiene campos vacios, fechas invalidas o la etiqueta prueba"
          );
          return;
        }
        const registro: Registro = {
          CodiCC: valores[1] as string,
          DescCC: valores[2] as string,
          NivelCC: valores[3] as string,
          Activo: valores[4] as string,
          Desc_formato: valores[5] as string,
          Comuna: valores[6] as string,
        };
        // const banco = this.nombresBancos[registro.DescCC];
        //  if (!banco) {
        //    console.log("error row", rowNumber);
        //    this.mensajes.push(
        //      "error: row " + rowNumber + " invalida, patente no registrada"
        //    );
        //    return;
        //  }
        const ingreso: CentroCosto = {
          CodiCC: registro.CodiCC,
          DescCC: registro.DescCC,
          NivelCC: registro.NivelCC,
          Activo: registro.Activo,
          Desc_formato: registro.Desc_formato,
          Comuna: registro.Comuna,
        };
        this.despachos.push(ingreso);
      }
    });
    if (this.mensajes.length > 0) {
      this.mensajes.push("cargado con errores");
      this.mensajes.push("corregir excel y volver a intentar");
      this.cargando = false;
    } else {
      this.mensajes.push("cargado completo");
      this.mensajes.push(this.despachos.length + " bancos cargados");
      this.onCargaExcel();
      this.cargando = false;
    }
  }
}
