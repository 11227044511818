
























import {Component, Emit, Prop, Vue} from "vue-property-decorator";
import SVGIcon from "@/components/SVGIcon.vue";

@Component({
  components: {
    SVGIcon
  }
})
export default class DialogoExito extends Vue {
  @Prop() readonly texto!: string;
  @Prop() readonly icono!: string;

  mounted(): void {
    setTimeout(() => {
      this.onCerrar();
    }, 1500);
  }

  @Emit("cerrar")
  onCerrar(): void {
    return;
  }
}
