















































































































































import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
// helpers
// tipos
// validaciones
import { required } from "vuelidate/lib/validators";
import { DataFormularioSesionProgramada } from "@/typings/components/agenda/formulario";
import { SesionProgramada } from "@/typings/store/plugins/easyFirestore/sesionesProgramadas";
import { Sesion } from "@/typings/store/plugins/easyFirestore/sesiones";
import { Curso } from "@/typings/store/plugins/easyFirestore/cursos";
import Time from "@/helpers/Time";

@Component({
  validations: {
    curso: { required },
    sesion: { required },
    fecha: { required },
    hora: { required },
    link: { required },
  },
})
export default class FormularioAgenda extends Vue {
  @Prop() readonly sesionProgramada!: SesionProgramada | null;
  @Prop() readonly cursos!: Array<Curso>;
  @Prop() readonly sesiones!: Array<Sesion>;
  @Prop() readonly diaSeleccionado!: string;
  @Prop() readonly sesionesProgramadas!: Array<SesionProgramada>;

  created(): void {
    const sesionProgramada = this.sesionProgramada;
    const diaSeleccionado = this.diaSeleccionado;

    this.fecha = diaSeleccionado;
    if (!sesionProgramada) {
      //agregar condicion si la sesion fue programada
      return;
    }

    this.id = sesionProgramada.id ?? "";
    this.curso = sesionProgramada.sesion.curso;
    this.sesion = sesionProgramada.sesion;
    this.fecha = sesionProgramada.fecha;
    this.hora = sesionProgramada.horaInicio;
    this.horaFin = sesionProgramada.horaFin;
    this.link = sesionProgramada.link;
  }

  @Watch("hora")
  revisarHora(): void {
    if (this.hora !== null && this.hora.length >= 5) {
      const hora = this.hora;
      const horario: Array<string> = hora.split(":", 2);
      const time = new Time(0, parseInt(horario[0]), parseInt(horario[1]), 0);
      time.add(Time.fromMinutes(this.sesion?.duracion ?? 0));
      this.horaFin = time.toString(false, true, true, false);
    }
  }

  menuFecha = false;

  id: string | null = null;
  curso: Curso | null = null;
  sesion: Sesion | null = null;
  fecha = "";
  hora = "";
  horaFin = "";
  link = "";
  disabled = true;

  get titulo(): string {
    return this.sesionProgramada ? "editar Clase" : "Programar Clase";
  }

  get cursoError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.curso.$dirty) return errors;
    if (!this.$v.curso.required) errors.push("Completar datos obligatorios");
    return errors;
  }
  get sesionError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.sesion.$dirty) return errors;
    if (!this.$v.sesion.required) errors.push("Completar datos obligatorios");
    return errors;
  }

  get fechaError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.fecha.$dirty) return errors;
    if (!this.$v.fecha.required) errors.push("Completar datos obligatorios");
    return errors;
  }

  get horaInicioError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.hora.$dirty) return errors;
    if (!this.$v.hora.required) errors.push("Completar datos obligatorios");
    return errors;
  }

  get linkError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.link.$dirty) return errors;
    if (!this.$v.link.required) errors.push("Completar datos obligatorios");
    return errors;
  }

  get sesionesFiltradas(): Array<Sesion> {
    return this.sesiones
      .filter((sesion) => {
        return this.curso && sesion.curso.id == this.curso.id;
      })
      .sort((sesionA, sesionB) => {
        return sesionA.numero - sesionB.numero;
      });
  }

  @Emit("close")
  onClose(): null {
    return null;
  }

  @Emit("cerrar-dialogo")
  onCerrarDialogo(): void {
    return;
  }

  @Emit("click-guardar")
  onClickGuardar(
    data: DataFormularioSesionProgramada
  ): DataFormularioSesionProgramada {
    console.log("formulario" + data);
    return data;
  }

  guardar(): void {
    this.$v.$touch();
    if (this.$v.$invalid || !this.sesion) {
      return;
    }
    const doc: SesionProgramada = {
      sesion: this.sesion,
      fecha: this.fecha,
      horaInicio: this.hora,
      horaFin: this.horaFin,
      link: this.link,
    };
    if (this.id) {
      doc.id = this.id;
    }
    const data: DataFormularioSesionProgramada = {
      doc: doc,
    };
    this.onClickGuardar(data);
  }
}
