//tipos
import { GetterTree } from "vuex";
import { State } from "@/typings/store";
import { StateTipoCargos } from "@/typings/store/plugins/easyFirestore/tipoCargos";

const getters: GetterTree<StateTipoCargos, State> = {
    get(state) {
        return state.all;
    },
    getArray(state) {
        return Object.values(state.all);
    },
};

const tipoCargos = {
    firestorePath: "dev/v1/tipoCargos",
    firestoreRefType: "collection",
    moduleName: "tipoCargos",
    statePropName: "all",
    namespaced: true,
    serverChange: {
        convertTimestamps: {
            created_at: "%convertTimestamp%", // default
            updated_at: "%convertTimestamp%", // default
        },
    },
    state: {},
    getters,
    mutations: {},
    actions: {},
};

export default tipoCargos;
