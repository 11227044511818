








































































































































































































































































































































































































































































import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { mapGetters } from "vuex";
import {
  EstadoPrenomina,
  Prenomina,
} from "@/typings/store/plugins/easyFirestore/prenominas";
import {
  Usuario,
  TipoUsuario,
} from "@/typings/store/plugins/easyFirestore/usuarios";
import { ItemsTablaPrenominas } from "@/typings/components/prenominas/tabla";
// Helpers
import { fechaLocal } from "@/helpers/date";
import "@/helpers/extensions";
// PDF
import pdfMake from "pdfmake/build/pdfmake";
import { TDocumentDefinitions } from "pdfmake/interfaces";
import VistaPreviaPdf from "@/components/VistaPreviaPdf.vue";
import { Archivo } from "@/typings/store/modules/archivos";
// Excel
import Exceljs from "exceljs";
import { HeadersDataTable } from "@/typings/vuetify/vDataTable";

import SVGIcon from "@/components/SVGIcon.vue";
import "@/helpers/extensions";
//import { throws } from "assert";
// import prenominas from "@/router/prenominas";
// import { formatMoney } from "@/helpers/money";

@Component({
  computed: mapGetters({
    getPrenominas: "prenominas/get",
  }),
  components: {
    SVGIcon,
    VistaPreviaPdf,
  },
})
export default class VisualizaNomina extends Vue {
  @Prop() readonly prenominas!: Array<Prenomina>;
  @Prop() readonly items!: Array<Prenomina>;
  @Prop() readonly ArrayPrenominas!: Array<Prenomina>;
  @Prop() readonly usuario?: Usuario;

  get ArrayPrenominasOrdenado(): Array<Prenomina> {
    return this.ArrayPrenominas.map((prenomina) => {
      const id = prenomina.id;
      const centroCosto = prenomina.centroCosto;
      const numeroPrenominas = prenomina.numeroPrenominas;
      const ingresada = prenomina.ingresada;
      const modificado = prenomina.modificado;
      const fPago = prenomina.formaPago;
      const rut = prenomina.rut;
      const nombreProveedor = prenomina.nombreProveedor;
      const nFactura = prenomina.nFactura;
      const ordenCompra = prenomina.ordenCompra;
      const tipoGasto = prenomina.tipoGasto;
      //const tipoCambio = prenomina.tipoCambio;
      const montoOC = (
        parseInt(prenomina.montoOC, 10).toCurrency() ?? ""
      ).toString();
      const porcentajePagado = prenomina.porcentajePagado;
      const montoSaldo = prenomina.montoSaldo;
      // const montoAP = (
      //   parseInt(prenomina.montoAP, 10).toCurrency({ symbol: "$" }) ?? ""
      // ).toString();
      const montoAP = prenomina.montoAP;
      const observacion = prenomina.observacion;
      const estado = prenomina.estado;
      const fechaAprobacion = prenomina.fechaAprobacion;
      const autorizado = prenomina.autorizado;
      const ordenaRut = prenomina.rut.replace(/\.+/g, "");
      const formaPago = prenomina.formaPago;
      const banco = prenomina.banco;
      const numeroCheque = prenomina.numeroCheque;
      const periodo = prenomina.periodo;
      const recurrente = prenomina.recurrente;
      const tipoRecurrencia = prenomina.recurrente;
      const periodoRecurrencia = prenomina.periodoRecurrencia;
      const motivoRechazo = prenomina.motivoRechazo;
      const color = prenomina.color;
      const pasoNomina = prenomina.pasoNomina;
      const fechaVencimiento = prenomina.fechaVencimiento;
      const comentario = prenomina.comentario;
      const emisorComentario = prenomina.emisorComentario;

      return {
        id,
        centroCosto,
        modificado,
        fPago,
        numeroPrenominas,
        ingresada,
        rut,
        nombreProveedor,
        nFactura,
        ordenCompra,
        tipoGasto,
        montoOC,
        porcentajePagado,
        montoSaldo,
        montoAP,
        observacion,
        estado,
        fechaAprobacion,
        autorizado,
        ordenaRut,
        formaPago,
        banco,
        numeroCheque,
        periodo,
        recurrente,
        tipoRecurrencia,
        periodoRecurrencia,
        motivoRechazo,
        color,
        pasoNomina,
        fechaVencimiento,
        comentario,
    emisorComentario,
        data: prenomina,
      };
    }).sort((sesionA, sesionB) => {
      return parseInt(sesionA.ordenaRut) - parseInt(sesionB.ordenaRut);
    });
  }

  prenomina: Prenomina | null = null;
  dialogoInfo = false;
  dialogoFormulario = false;
  dialogoEliminar = false;
  dialogoExito = {
    model: false,
    texto: "",
    icono: "",
  };
  searchNomina = "";
  selectPagos = "";
  selectEstado = "";
  selectNominas = "";
  selectCentroCosto = "";
  selectProveedor = "";
  selectTipoGasto = "";
  selectNomina = Math.max(...this.cantidadNominas);
  arrayNominas = ["PROVEEDORES", "REMUNERACIONES"];
  arrayPagos = ["CHEQUE", "NOMINA"];
  arrayEstado = ["APROBADO", "RECHAZADO"];

  // VISUALIZA NOMINA
  headerProps = {
    sortIcon: "mdi-chevron-down",
  };

  get headers(): HeadersDataTable {
    return [
      { text: "", value: "estado", sortable: false },
      { text: "Nómina", value: "numeroPrenominas" },
      { text: "Centro Costo", value: "centroCosto" },
      { text: "Tipo Gasto", value: "tipoGasto" },
      { text: "F. Pago", value: "formaPago" },
      { text: "Rut Proveedor", value: "rut" },
      { text: "Nombre Proveedor", value: "nombreProveedor" },
      { text: "N° Factura", value: "nFactura" },
      { text: "Orden de Compra", value: "ordenCompra" },
      { text: "Monto a Pago", value: "montoAP" },
      { text: "Observacion", value: "observacion" },
      { text: "Paso a Nomina", value: "pasoNomina" },

      { text: "Factura", value: "pdf", sortable: false },
    ];
  }

  get tipoUsuario(): TipoUsuario {
    if (!this.getUsuario) {
      return "sudo";
    }
    return this.getUsuario.tipo;
  }

  /* aqui  separa cheque de nomina */
  get cantidadNominas(): Array<number> {
    let numero = 1;
    let arrayNumeros = [numero];
    this.ArrayPrenominas.forEach((prenomina) => {
      if (prenomina.numeroPrenominas != numero) {
        arrayNumeros.push(prenomina.numeroPrenominas);
      }
    });
    return arrayNumeros
      .sort((sesionA, sesionB) => {
        return sesionA - sesionB;
      })
      .reverse();
  }

  get sumaNomina(): number {
    let numero = 0;
    let arrayNumeros = [numero];
    this.ArrayPrenominas.forEach((prenomina) => {
      if (prenomina.ingresada === true) {
        if (prenomina.numeroPrenominas === this.selectNomina) {
          arrayNumeros.push(parseInt(prenomina.montoAP));
        }
      }
    });
    let suma = arrayNumeros.reduce((a, b) => a + b, 0);
    return suma;
  }

  get sumaNominaRemuneracion(): number {
    let numero = 0;
    let arrayNumeros = [numero];
    this.ArrayPrenominas.forEach((prenomina) => {
      if (prenomina.ingresada === true) {
        if (prenomina.numeroPrenominas === this.selectNomina) {
          if (
            prenomina.tipoGasto === "SUELDOS" &&
            prenomina.formaPago === "NOMINA"
          ) {
            arrayNumeros.push(parseInt(prenomina.montoAP));
          }
        }
      }
    });
    let suma = arrayNumeros.reduce((a, b) => a + b, 0);
    return suma;
  }

  get sumaNominaProveedor(): number {
    let numero = 0;
    let arrayNumeros = [numero];
    this.ArrayPrenominas.forEach((prenomina) => {
      if (prenomina.formaPago !== "CHEQUE") {
        if (prenomina.ingresada === true) {
          if (prenomina.numeroPrenominas === this.selectNomina) {
            if (
              prenomina.tipoGasto === "SUELDOS" &&
              prenomina.nFactura !== "0"
            ) {
              arrayNumeros.push(parseInt(prenomina.montoAP));
            }
            if (prenomina.tipoGasto !== "SUELDOS") {
              arrayNumeros.push(parseInt(prenomina.montoAP));
            }
          }
        }
      }
    });
    let suma = arrayNumeros.reduce((a, b) => a + b, 0);
    return suma;
  }

  get sumaProveedoresAprobado(): number {
    let numero = 0;
    let arrayNumeros = [numero];
    this.ArrayPrenominas.forEach((prenomina) => {
      if (prenomina.ingresada === true) {
        if (prenomina.estado === "aprobado") {
          if (prenomina.formaPago === "NOMINA") {
            if (prenomina.numeroPrenominas === this.selectNomina) {
              if (
                prenomina.tipoGasto === "SUELDOS" &&
                prenomina.nFactura !== "0"
              ) {
                arrayNumeros.push(parseInt(prenomina.montoAP));
              }
              if (prenomina.tipoGasto !== "SUELDOS") {
                arrayNumeros.push(parseInt(prenomina.montoAP));
              }
            }
          }
        }
      }
    });
    let suma = arrayNumeros.reduce((a, b) => a + b, 0);
    return suma;
  }

  get sumaProveedoresRechazado(): number {
    let numero = 0;
    let arrayNumeros = [numero];
    this.ArrayPrenominas.forEach((prenomina) => {
      if (prenomina.ingresada === true) {
        if (prenomina.estado === "rechazado") {
          if (prenomina.formaPago === "NOMINA") {
            if (prenomina.numeroPrenominas === this.selectNomina) {
              if (
                prenomina.tipoGasto === "SUELDOS" &&
                prenomina.nFactura !== "0"
              ) {
                arrayNumeros.push(parseInt(prenomina.montoAP));
              }
              if (prenomina.tipoGasto !== "SUELDOS") {
                arrayNumeros.push(parseInt(prenomina.montoAP));
              }
            }
          }
        }
      }
    });
    let suma = arrayNumeros.reduce((a, b) => a + b, 0);
    return suma;
  }

  get sumaRemuneracionesAprobado(): number {
    let numero = 0;
    let arrayNumeros = [numero];
    this.ArrayPrenominas.forEach((prenomina) => {
      if (prenomina.ingresada === true) {
        if (prenomina.estado === "aprobado") {
          if (prenomina.formaPago === "NOMINA") {
            if (prenomina.numeroPrenominas === this.selectNomina) {
              if (
                prenomina.tipoGasto === "SUELDOS" &&
                prenomina.nFactura === "0"
              ) {
                arrayNumeros.push(parseInt(prenomina.montoAP));
              }
            }
          }
        }
      }
    });
    let suma = arrayNumeros.reduce((a, b) => a + b, 0);
    return suma;
  }

  get sumaRemuneracionesRechazado(): number {
    let numero = 0;
    let arrayNumeros = [numero];
    this.ArrayPrenominas.forEach((prenomina) => {
      if (prenomina.ingresada === true) {
        if (prenomina.estado === "rechazado") {
          if (prenomina.formaPago === "NOMINA") {
            if (prenomina.numeroPrenominas === this.selectNomina) {
              if (
                prenomina.tipoGasto === "SUELDOS" &&
                prenomina.nFactura === "0"
              ) {
                arrayNumeros.push(parseInt(prenomina.montoAP));
              }
            }
          }
        }
      }
    });
    let suma = arrayNumeros.reduce((a, b) => a + b, 0);
    return suma;
  }

  get sumaNominaCheque(): number {
    let numero = 0;
    let arrayNumeros = [numero];
    this.ArrayPrenominas.forEach((prenomina) => {
      if (prenomina.ingresada === true) {
        if (
          prenomina.estado === "aprobado" &&
          prenomina.numeroPrenominas === this.selectNomina &&
          prenomina.formaPago === "CHEQUE"
        ) {
          arrayNumeros.push(parseInt(prenomina.montoAP));
        }
      }
    });
    let suma = arrayNumeros.reduce((a, b) => a + b, 0);

    return suma;
  }

  get sumaNominaBanco(): number {
    let numero = 0;
    let arrayNumeros = [numero];
    this.ArrayPrenominas.forEach((prenomina) => {
      if (
        prenomina.estado === "aprobado" &&
        prenomina.numeroPrenominas === this.selectNomina &&
        prenomina.formaPago === "NOMINA"
      ) {
        if (prenomina.tipoGasto === "SUELDOS" && prenomina.nFactura !== "0") {
          arrayNumeros.push(parseInt(prenomina.montoAP));
        }
        if (prenomina.tipoGasto !== "SUELDOS") {
          arrayNumeros.push(parseInt(prenomina.montoAP));
        }
      }
    });
    let suma = arrayNumeros.reduce((a, b) => a + b, 0);
    return suma;
  }

  get montoPDF(): number {
    let monto = 0;
    if (this.selectPagos === "CHEQUE") {
      monto = this.sumaNominaCheque;
    }
    if (this.selectPagos === "NOMINA") {
      monto = this.sumaNominaBanco;
    }
    if (this.selectPagos === "TODAS") {
      monto = this.sumaNomina;
    }
    return monto;
  }

  //ITEMS PDF
  get itemsPdfFiltrados(): Array<Array<string | number>> {
    //let PrenominasRecorridas: Array<string> = [];
    let correlativo = 0;

    return this.ArrayPrenominasOrdenado.filter((prenomina) => {
      console.log(this.selectPagos);
      if (this.selectPagos === "CHEQUE" || this.selectPagos === "NOMINA") {
        //const estado = prenomina.estado === "aprobado";
        const numeroPrenomina =
          prenomina.numeroPrenominas === this.selectNomina;
        const formaPago = prenomina.formaPago === this.selectPagos;
        const ingresada = prenomina.ingresada === true;
        return numeroPrenomina && formaPago && ingresada;
      } else {
        const numeroPrenominas =
          prenomina.numeroPrenominas === this.selectNomina;
        //const estado = prenomina.estado === "aprobado";
        const ingresada = (prenomina.ingresada = true);

        return numeroPrenominas && ingresada;
      }
    }).map((prenomina) => {
      correlativo = correlativo + 1;
      //const numeroPrenominas = prenomina.numeroPrenominas ?? 0;
      const rut = prenomina.rut;
      const nombreProveedor = prenomina.nombreProveedor;
      const nFactura = prenomina.nFactura;
      const ordenCompra = prenomina.ordenCompra;
      const montoAP = parseInt(prenomina.montoAP).toCurrency({ symbol: "$" }); //se modifica por monto a pagar
      const estado = prenomina.estado.toUpperCase();
      const observacion = prenomina.observacion;
      const centroCosto = prenomina.centroCosto;
      const tipoGasto = prenomina.tipoGasto;
      //const ordenaRut = prenomina.rut.replace(/\.+/g, '')
      const formaPago = prenomina.formaPago;
      return Object.values({
        correlativo,
        centroCosto,
        tipoGasto,
        rut,
        nombreProveedor,
        nFactura,
        ordenCompra,
        montoAP,
        observacion,
        formaPago,
        estado,
        //ordenaRut,
      });
    });
    //    .sort(function(sesionA, sesionB)  {
    //       return (sesionA.ordenaRut[0]) - (sesionB.ordenaRut[0])
    // })

    //  return (parseInt(sesionA.) - parseInt(sesionB.ordenaRut))
  }

  // obtiene centroCosto sin repetir de itemsPdfFiltrados
  get itemsCentroCosto(): Array<string> {
    let arrayCentroCosto: Array<string> = [];
    this.itemsHistoricos.forEach((item) => {
      if (!arrayCentroCosto.includes(item[2].toString())) {
        arrayCentroCosto.push(item[2].toString());
      }
    });
    return arrayCentroCosto.sort((sesionA, sesionB) => {
      return parseInt(sesionA) - parseInt(sesionB);
    });
  }

  get itemsTipoGasto(): Array<string> {
    let arrayTipoGasto: Array<string> = [];
    this.itemsHistoricos.forEach((item) => {
      if (!arrayTipoGasto.includes(item[3].toString())) {
        arrayTipoGasto.push(item[3].toString());
      }
    });
    return arrayTipoGasto.sort();
  }

  get itemsProveedor(): Array<string> {
    let arrayProveedor: Array<string> = [];
    this.itemsHistoricos.forEach((item) => {
      if (!arrayProveedor.includes(item[5].toString())) {
        arrayProveedor.push(item[5].toString());
      }
    });
    return arrayProveedor.sort();
  }

  // extrae de itemsCentroCostoFiltrados los centroCosto sin repetir
  get itemsCentroCostoFiltrados(): Array<string> {
    let centroCostoFiltrados: Array<string> = [];
    this.itemsCentroCostoFiltrados.forEach((item) => {
      if (!centroCostoFiltrados.includes(item[0])) {
        centroCostoFiltrados.push(item[0]);
      }
    });
    return centroCostoFiltrados;
  }

  get itemsHistoricos(): Array<Array<string | number>> {
    //let PrenominasRecorridas: Array<string> = [];
    let correlativo = 0;

    return this.ArrayPrenominasOrdenado.filter((prenomina) => {
      // if (this.selectCentroCosto) {
      //   const estado = prenomina.estado === "aprobado";
      //   // const numeroPrenomina =
      //   //   prenomina.numeroPrenominas === this.selectNomina;
      //   //const formaPago = prenomina.formaPago === this.selectPagos;

      //   const distintoCero = prenomina.numeroPrenominas !== 0;
      //   const centroCosto = prenomina.centroCosto === this.selectCentroCosto;
      //   const ingresada = prenomina.ingresada === true;
      //   return ingresada && centroCosto && distintoCero && estado;
      // } else {
      //   // const numeroPrenominas =
      //   //   prenomina.numeroPrenominas === this.selectNomina;
      //   //const estado = prenomina.estado === "aprobado";
      //   const estado = prenomina.estado === "aprobado";
      //   const distintoCero = prenomina.numeroPrenominas !== 0;
      //   const ingresada = (prenomina.ingresada = true);

      //   return ingresada && distintoCero && estado;
      // }

      const ingresada = prenomina.ingresada === true;
        const proveedores = prenomina.nombreProveedor === this.selectProveedor;
        const distintoCero = prenomina.numeroPrenominas !== 0;
        const centroCosto = prenomina.centroCosto === this.selectCentroCosto;
        const tipoGasto = prenomina.tipoGasto === this.selectTipoGasto;

        if (this.selectCentroCosto) {
          return ingresada && distintoCero && centroCosto;
        }

        if (this.selectProveedor) {
          return ingresada && distintoCero && proveedores;
        }

        if (this.selectTipoGasto){
          return ingresada && distintoCero && tipoGasto;
        }
        else {
       
       //   const ingresada = prenomina.ingresada === true;

          return ingresada && distintoCero;
        }
    }).map((prenomina) => {
      correlativo = correlativo + 1;
      const numeroPrenominas = prenomina.numeroPrenominas ?? 0;
      const rut = prenomina.rut;
      const nombreProveedor = prenomina.nombreProveedor;
      const nFactura = prenomina.nFactura;
      const ordenCompra = prenomina.ordenCompra;
      const montoAP = parseInt(prenomina.montoAP).toCurrency({ symbol: "$" }); //se modifica por monto a pagar
      const estado = prenomina.estado.toUpperCase();
      const observacion = prenomina.observacion;
      const centroCosto = prenomina.centroCosto;
      const tipoGasto = prenomina.tipoGasto;
      //const ordenaRut = prenomina.rut.replace(/\.+/g, '')
      const formaPago = prenomina.formaPago;
      return Object.values({
        correlativo,
        numeroPrenominas,
        centroCosto,
        tipoGasto,
        rut,
        nombreProveedor,
        nFactura,
        ordenCompra,
        montoAP,
        observacion,
        formaPago,
        estado,
        //ordenaRut,
      });
    });
    //    .sort(function(sesionA, sesionB)  {
    //       return (sesionA.ordenaRut[0]) - (sesionB.ordenaRut[0])
    // })

    //  return (parseInt(sesionA.) - parseInt(sesionB.ordenaRut))
  }

  get itemsFiltradoNomina(): ItemsTablaPrenominas {
    return this.items
      .map((prenomina) => {
        const centroCosto = prenomina.centroCosto;
        const numeroPrenominas = prenomina.numeroPrenominas;
        const ingresada = prenomina.ingresada;
        const modificado = prenomina.modificado;
        const rut = prenomina.rut;
        const nombreProveedor = prenomina.nombreProveedor;
        const nFactura = prenomina.nFactura;
        const ordenCompra = prenomina.ordenCompra;
        //const tipoCambio = prenomina.tipoCambio;
        const montoOC = (
          parseInt(prenomina.montoOC, 10).toCurrency() ?? ""
        ).toString();
        const porcentajePagado = prenomina.porcentajePagado;
        const montoSaldo = prenomina.montoSaldo;
        const montoAP = (
          parseInt(prenomina.montoAP, 10).toCurrency({ symbol: "$" }) ?? ""
        ).toString();
        const observacion = prenomina.observacion;
        const estado = prenomina.estado;
        const formaPago = prenomina.formaPago;
        const tipoGasto = prenomina.tipoGasto;
        const pasoNomina = prenomina.pasoNomina;

        return {
          modificado,
          numeroPrenominas,
          ingresada,
          rut,
          nombreProveedor,
          nFactura,
          ordenCompra,
          montoOC,
          porcentajePagado,
          montoSaldo,
          montoAP,
          observacion,
          estado,
          formaPago,
          centroCosto,
          tipoGasto,
          pasoNomina,

          data: prenomina,
        };
      })
      .filter((prenomina) => {
        // const numeroPrenomina =
        //   prenomina.numeroPrenominas === this.selectNomina;
        const ingresada = prenomina.ingresada === true;
        const proveedores = prenomina.nombreProveedor === this.selectProveedor;
        const distintoCero = prenomina.numeroPrenominas !== 0;
        const centroCosto = prenomina.centroCosto === this.selectCentroCosto;
        const tipoGasto = prenomina.tipoGasto === this.selectTipoGasto;

        if (this.selectCentroCosto) {
          return ingresada && distintoCero && centroCosto;
        }

        if (this.selectProveedor) {
          return ingresada && distintoCero && proveedores;
        }

        if (this.selectTipoGasto){
          return ingresada && distintoCero && tipoGasto;
        }
        else {
       
       //   const ingresada = prenomina.ingresada === true;

          return ingresada && distintoCero;
        }
      })
      .sort((sesionA, sesionB) => {
        return sesionB.numeroPrenominas - sesionA.numeroPrenominas;
      });
  }
  // Items Nomina Proveedores
  get itemsNominaProveedores(): Array<Array<string | number>> {
    //let PrenominasRecorridas: Array<string> = [];
    let correlativo = 0;

    return this.ArrayPrenominasOrdenado.filter((prenomina) => {
      const formaPago = prenomina.formaPago === "NOMINA";
      const tipoGasto = prenomina.tipoGasto !== "SUELDOS";
      const ingresada = prenomina.ingresada === true;
      const numeroPrenomina = prenomina.numeroPrenominas === this.selectNomina;
      return numeroPrenomina && formaPago && ingresada && tipoGasto;
    }).map((prenomina) => {
      correlativo = correlativo + 1;
      //const numeroPrenominas = prenomina.numeroPrenominas ?? 0;
      const rut = prenomina.rut;
      const nombreProveedor = prenomina.nombreProveedor;
      const nFactura = prenomina.nFactura;
      const ordenCompra = prenomina.ordenCompra;
      const montoAP = parseInt(prenomina.montoAP).toCurrency({ symbol: "$" }); //se modifica por monto a pagar
      const estado = prenomina.estado.toUpperCase();
      const observacion = prenomina.observacion;
      const centroCosto = prenomina.centroCosto;
      const tipoGasto = prenomina.tipoGasto;
      //const ordenaRut = prenomina.rut.replace(/\.+/g, '')
      const formaPago = prenomina.formaPago;
      return Object.values({
        correlativo,
        centroCosto,
        tipoGasto,
        rut,
        nombreProveedor,
        nFactura,
        ordenCompra,
        montoAP,
        observacion,
        formaPago,
        estado,
        //ordenaRut,
      });
    });
    //    .sort(function(sesionA, sesionB)  {
    //       return (sesionA.ordenaRut[0]) - (sesionB.ordenaRut[0])
    // })

    //  return (parseInt(sesionA.) - parseInt(sesionB.ordenaRut))
  }

  // Items Nomina Remuneraciones
  get itemsNominaRemuneraciones(): Array<Array<string | number>> {
    //let PrenominasRecorridas: Array<string> = [];
    let correlativo = 0;

    return this.ArrayPrenominasOrdenado.filter((prenomina) => {
      // console.log(this.selectPagos);
      // if (this.selectPagos === "CHEQUE" || this.selectPagos === "NOMINA") {
      //   //const estado = prenomina.estado === "aprobado";
      //   const numeroPrenomina =
      //     prenomina.numeroPrenominas === this.selectNomina;
      //   const formaPago = prenomina.formaPago === this.selectPagos;
      //   const ingresada = prenomina.ingresada === true;
      //   return numeroPrenomina && formaPago && ingresada;
      // } else {
      //   const numeroPrenominas =
      //     prenomina.numeroPrenominas === this.selectNomina;
      //   //const estado = prenomina.estado === "aprobado";
      //   const ingresada = (prenomina.ingresada = true);

      //   return numeroPrenominas && ingresada;
      // }

      const formaPago = prenomina.formaPago === "NOMINA";
      const tipoGasto = prenomina.tipoGasto === "SUELDOS";
      const ingresada = prenomina.ingresada === true;
      const numeroPrenomina = prenomina.numeroPrenominas === this.selectNomina;
      return numeroPrenomina && formaPago && ingresada && tipoGasto;
    }).map((prenomina) => {
      correlativo = correlativo + 1;
      //const numeroPrenominas = prenomina.numeroPrenominas ?? 0;
      const rut = prenomina.rut;
      const nombreProveedor = prenomina.nombreProveedor;
      const nFactura = prenomina.nFactura;
      const ordenCompra = prenomina.ordenCompra;
      const montoAP = parseInt(prenomina.montoAP).toCurrency({ symbol: "$" }); //se modifica por monto a pagar
      const estado = prenomina.estado.toUpperCase();
      const observacion = prenomina.observacion;
      const centroCosto = prenomina.centroCosto;
      const tipoGasto = prenomina.tipoGasto;
      //const ordenaRut = prenomina.rut.replace(/\.+/g, '')
      const formaPago = prenomina.formaPago;
      return Object.values({
        correlativo,
        centroCosto,
        tipoGasto,
        rut,
        nombreProveedor,
        nFactura,
        ordenCompra,
        montoAP,
        observacion,
        formaPago,
        estado,
        //ordenaRut,
      });
    });
    //    .sort(function(sesionA, sesionB)  {
    //       return (sesionA.ordenaRut[0]) - (sesionB.ordenaRut[0])
    // })

    //  return (parseInt(sesionA.) - parseInt(sesionB.ordenaRut))
  }

  // Items Cheques
  get itemsCheques(): Array<Array<string | number>> {
    //let PrenominasRecorridas: Array<string> = [];
    let correlativo = 0;

    return this.ArrayPrenominasOrdenado.filter((prenomina) => {
      console.log(this.selectPagos);
      const formaPago = prenomina.formaPago === "CHEQUE";
      const ingresada = prenomina.ingresada === true;
      const numeroPrenomina = prenomina.numeroPrenominas === this.selectNomina;
      return numeroPrenomina && formaPago && ingresada;

      // if (this.selectPagos === "CHEQUE" || this.selectPagos === "NOMINA") {
      //   //const estado = prenomina.estado === "aprobado";
      //   const numeroPrenomina =
      //     prenomina.numeroPrenominas === this.selectNomina;
      //   const formaPago = prenomina.formaPago === this.selectPagos;
      //   const ingresada = prenomina.ingresada === true;
      //   return numeroPrenomina && formaPago && ingresada;
      // } else {
      //   const numeroPrenominas =
      //     prenomina.numeroPrenominas === this.selectNomina;
      //   //const estado = prenomina.estado === "aprobado";
      //   const ingresada = (prenomina.ingresada = true);

      //   return numeroPrenominas && ingresada;
      // }
    }).map((prenomina) => {
      correlativo = correlativo + 1;
      //const numeroPrenominas = prenomina.numeroPrenominas ?? 0;
      const rut = prenomina.rut;
      const nombreProveedor = prenomina.nombreProveedor;
      const nFactura = prenomina.nFactura;
      const ordenCompra = prenomina.ordenCompra;
      const montoAP = parseInt(prenomina.montoAP).toCurrency({ symbol: "$" }); //se modifica por monto a pagar
      const estado = prenomina.estado.toUpperCase();
      const observacion = prenomina.observacion;
      const centroCosto = prenomina.centroCosto;
      const tipoGasto = prenomina.tipoGasto;
      //const ordenaRut = prenomina.rut.replace(/\.+/g, '')
      const formaPago = prenomina.formaPago;
      return Object.values({
        correlativo,
        centroCosto,
        tipoGasto,
        rut,
        nombreProveedor,
        nFactura,
        ordenCompra,
        montoAP,
        observacion,
        formaPago,
        estado,
        //ordenaRut,
      });
    });
    //    .sort(function(sesionA, sesionB)  {
    //       return (sesionA.ordenaRut[0]) - (sesionB.ordenaRut[0])
    // })

    //  return (parseInt(sesionA.) - parseInt(sesionB.ordenaRut))
  }

  actualizarEstado(item: Prenomina): void {
    let estado: EstadoPrenomina;
    item.estado === "rechazado"
      ? (estado = "aprobado")
      : (estado = "rechazado");
    item.estado = estado;
    this.onCambiarEstado(item);
  }

  @Emit("cambiar-estado")
  onCambiarEstado(prenomina: Prenomina): Prenomina {
    return prenomina;
  }

  @Emit("abrir-info")
  onAbrirInfo(prenomina: Prenomina): Prenomina {
    return prenomina;
  }

  @Emit("abrir-editar")
  onAbrirEditar(prenomina: Prenomina): Prenomina {
    return prenomina;
  }

  @Emit("abrir-eliminar")
  onAbrirEliminar(prenomina: Prenomina): Prenomina {
    return prenomina;
  }

  @Emit("cerrar")
  onCerrar(): void {
    return;
  }

  abrirVistaPrevia(archivo: Archivo): void {
    this.vistaPrevia = {
      model: true,
      url: this.urlArchivo(archivo),
    };
  }

  urlArchivo(media: Archivo): string {
    return media.url ?? URL.createObjectURL(media.file);
  }

  vistaPrevia = {
    model: false,
    url: "",
  };
  cerrarVistaPrevia(): void {
    this.vistaPrevia = {
      model: false,
      url: "",
    };
  }

  async crearPdfFiltrado(): Promise<void> {
    console.log("hola");
    const link = { url: require("@/assets/logo_salhus_white2.jpg") };
    const result = await fetch(link.url);
    const result2 = await result.blob();
    const fechaFormateada = new Date();
    const base64: string = await new Promise((callback) => {
      let reader = new FileReader();
      reader.onload = function () {
        callback(this.result?.toString() ?? "");
      };
      reader.readAsDataURL(result2);
    });

    const Proveedores: TDocumentDefinitions = {
      pageOrientation: "landscape",
      content: [
        {
          columns: [
            {
              text: " ",
            },
            {
              image: "logo",
              alignment: "right",
              height: 30,
              width: 100,
            },
          ],
        },
        {
          columns: [
            {
              text: "Detalle Nómina Proveedores",
              style: "titulo",
            },
            {
              text: " ",
            },
          ],
        },
        "\n",
        {
          canvas: [
            { type: "line", x1: 4, y1: 0, x2: 775, y2: 0, lineWidth: 1 },
          ],
        },
        "\n",
        {
          columns: [
            {
              width: "13%",
              text: "Sr.(a):",
              style: "subheader",
            },
            {
              text: "",
            },
            {
              width: "13%",
              text: "Fecha:",
              style: "subheader",
            },
            {
              text: fechaFormateada.format("DD de MMMM del YYYY"),
              style: "subheaderSB",
            },
          ],
        },
        {
          columns: [
            {
              width: "13%",
              text: "Empresa:",
              style: "subheader",
            },
            {
              text: "INVERSIONES STA FIDELMIRA LTDA",
              style: "subheaderSB",
            },

            {
              width: "13%",
              text: "Rut Empresa:",
              style: "subheader",
            },
            {
              text: "79.991.280-5",
              style: "subheaderSB",
            },
          ],
        },
        {
          columns: [
            {
              width: "13%",
              text: "Folio Nómina:",
              style: "subheader",
            },
            {
              text: "NP - " + this.selectNomina.toString(),
              style: "subheaderSB",
            },
            {
              width: "13%",
              text: "Total a Pago:",
              style: "subheader",
            },
            {
              text: this.sumaNominaProveedor.toCurrency({ symbol: "$" }),
              style: "subheaderSB",
            },
          ],
        },

        "\n",
        {
          table: {
            widths: [
              //  "*",
              "2%",
              "10%",
              "*",
              "*",
              "*",
              "8%",
              "8%",
              "*",
              "25%",
              "5%",
              "6%",
            ],
            body: [
              [
                { text: "N°", style: "tableHeader" },
                //              { text: " ", style: "tableHeader" },
                { text: "CENTRO COSTO", style: "tableHeader" },
                { text: "TIPO GASTO", style: "tableHeader" },

                { text: "RUT PROVEEDOR", style: "tableHeader" },
                { text: "NOMBRE PROVEEDOR", style: "tableHeader" },
                { text: "N° FACTURA", style: "tableHeader" },
                { text: "ORDEN DE COMPRA", style: "tableHeader" },

                { text: "MONTO PAGO", style: "tableHeader" },
                { text: "OBSERVACION", style: "tableHeader" },
                { text: "FORMA PAGO", style: "tableHeader" },
                { text: "ESTADO", style: "tableHeader" },
              ],
              ...this.itemsNominaProveedores,
            ],
          },
        },
      ],
      images: {
        logo: base64,
      },

      styles: {
        titulo: {
          fontSize: 10,
          bold: true,
          alignment: "left",
        },
        subheader: {
          fontSize: 8,
          bold: true,
          alignment: "left",
        },
        subheaderSB: {
          fontSize: 8,
          bold: false,
          alignment: "left",
        },
        quote: {
          italics: true,
        },
        small: {
          fontSize: 6,
        },
        header: {
          color: "#0d0a39",
          fontSize: 10,
          bold: true,
          margin: [0, 20, 0, 10],
        },
        tableHeader: {
          bold: true,
          fontSize: 6,
          color: "black",
          alignment: "center",
        },
      },
      defaultStyle: {
        fontSize: 6,
        alignment: "center",
      },
    };

    const Remuneraciones: TDocumentDefinitions = {
      pageOrientation: "landscape",
      content: [
        {
          columns: [
            {
              text: " ",
            },
            {
              image: "logo",
              alignment: "right",
              height: 30,
              width: 100,
            },
          ],
        },
        {
          columns: [
            {
              text: "Detalle Nómina Remuneraciones",
              style: "titulo",
            },
            {
              text: " ",
            },
          ],
        },
        "\n",
        {
          canvas: [
            { type: "line", x1: 4, y1: 0, x2: 775, y2: 0, lineWidth: 1 },
          ],
        },
        "\n",
        {
          columns: [
            {
              width: "13%",
              text: "Sr.(a):",
              style: "subheader",
            },
            {
              text: "",
            },
            {
              width: "13%",
              text: "Fecha:",
              style: "subheader",
            },
            {
              text: fechaFormateada.format("DD de MMMM del YYYY"),
              style: "subheaderSB",
            },
          ],
        },
        {
          columns: [
            {
              width: "13%",
              text: "Empresa:",
              style: "subheader",
            },
            {
              text: "INVERSIONES STA FIDELMIRA LTDA",
              style: "subheaderSB",
            },

            {
              width: "13%",
              text: "Rut Empresa:",
              style: "subheader",
            },
            {
              text: "79.991.280-5",
              style: "subheaderSB",
            },
          ],
        },
        {
          columns: [
            {
              width: "13%",
              text: "Folio Nómina:",
              style: "subheader",
            },
            {
              text: "NR - " + this.selectNomina.toString(),
              style: "subheaderSB",
            },
            {
              width: "13%",
              text: "Total a Pago:",
              style: "subheader",
            },
            {
              text: this.sumaNominaRemuneracion.toCurrency({ symbol: "$" }),
              style: "subheaderSB",
            },
          ],
        },

        "\n",
        {
          table: {
            widths: [
              //  "*",
              "2%",
              "10%",
              "*",
              "*",
              "*",
              "8%",
              "8%",
              "*",
              "25%",
              "5%",
              "6%",
            ],
            body: [
              [
                { text: "N°", style: "tableHeader" },
                //              { text: " ", style: "tableHeader" },
                { text: "CENTRO COSTO", style: "tableHeader" },
                { text: "TIPO GASTO", style: "tableHeader" },

                { text: "RUT PROVEEDOR", style: "tableHeader" },
                { text: "NOMBRE PROVEEDOR", style: "tableHeader" },
                { text: "N° FACTURA", style: "tableHeader" },
                { text: "ORDEN DE COMPRA", style: "tableHeader" },

                { text: "MONTO PAGO", style: "tableHeader" },
                { text: "OBSERVACION", style: "tableHeader" },
                { text: "FORMA PAGO", style: "tableHeader" },
                { text: "ESTADO", style: "tableHeader" },
              ],
              ...this.itemsNominaRemuneraciones,
            ],
          },
        },
      ],
      images: {
        logo: base64,
      },

      styles: {
        titulo: {
          fontSize: 10,
          bold: true,
          alignment: "left",
        },
        subheader: {
          fontSize: 8,
          bold: true,
          alignment: "left",
        },
        subheaderSB: {
          fontSize: 8,
          bold: false,
          alignment: "left",
        },
        quote: {
          italics: true,
        },
        small: {
          fontSize: 6,
        },
        header: {
          color: "#0d0a39",
          fontSize: 10,
          bold: true,
          margin: [0, 20, 0, 10],
        },
        tableHeader: {
          bold: true,
          fontSize: 6,
          color: "black",
          alignment: "center",
        },
      },
      defaultStyle: {
        fontSize: 6,
        alignment: "center",
      },
    };
    const Cheques: TDocumentDefinitions = {
      pageOrientation: "landscape",
      content: [
        {
          columns: [
            {
              text: " ",
            },
            {
              image: "logo",
              alignment: "right",
              height: 30,
              width: 100,
            },
          ],
        },
        {
          columns: [
            {
              text: "Detalle Nómina Cheques",
              style: "titulo",
            },
            {
              text: " ",
            },
          ],
        },
        "\n",
        {
          canvas: [
            { type: "line", x1: 4, y1: 0, x2: 775, y2: 0, lineWidth: 1 },
          ],
        },
        "\n",
        {
          columns: [
            {
              width: "13%",
              text: "Sr.(a):",
              style: "subheader",
            },
            {
              text: "",
            },
            {
              width: "13%",
              text: "Fecha:",
              style: "subheader",
            },
            {
              text: fechaFormateada.format("DD de MMMM del YYYY"),
              style: "subheaderSB",
            },
          ],
        },
        {
          columns: [
            {
              width: "13%",
              text: "Empresa:",
              style: "subheader",
            },
            {
              text: "INVERSIONES STA FIDELMIRA LTDA",
              style: "subheaderSB",
            },

            {
              width: "13%",
              text: "Rut Empresa:",
              style: "subheader",
            },
            {
              text: "79.991.280-5",
              style: "subheaderSB",
            },
          ],
        },
        {
          columns: [
            {
              width: "13%",
              text: "Folio Nómina:",
              style: "subheader",
            },
            {
              text: "CH - " + this.selectNomina.toString(),
              style: "subheaderSB",
            },
            {
              width: "13%",
              text: "Total a Pago:",
              style: "subheader",
            },
            {
              text: this.sumaNominaCheque.toCurrency({ symbol: "$" }),
              style: "subheaderSB",
            },
          ],
        },

        "\n",
        {
          table: {
            widths: [
              //  "*",
              "2%",
              "10%",
              "*",
              "*",
              "*",
              "8%",
              "8%",
              "*",
              "25%",
              "5%",
              "6%",
            ],
            body: [
              [
                { text: "N°", style: "tableHeader" },
                //              { text: " ", style: "tableHeader" },
                { text: "CENTRO COSTO", style: "tableHeader" },
                { text: "TIPO GASTO", style: "tableHeader" },

                { text: "RUT PROVEEDOR", style: "tableHeader" },
                { text: "NOMBRE PROVEEDOR", style: "tableHeader" },
                { text: "N° FACTURA", style: "tableHeader" },
                { text: "ORDEN DE COMPRA", style: "tableHeader" },

                { text: "MONTO PAGO", style: "tableHeader" },
                { text: "OBSERVACION", style: "tableHeader" },
                { text: "FORMA PAGO", style: "tableHeader" },
                { text: "ESTADO", style: "tableHeader" },
              ],
              ...this.itemsCheques,
            ],
          },
        },
      ],
      images: {
        logo: base64,
      },

      styles: {
        titulo: {
          fontSize: 10,
          bold: true,
          alignment: "left",
        },
        subheader: {
          fontSize: 8,
          bold: true,
          alignment: "left",
        },
        subheaderSB: {
          fontSize: 8,
          bold: false,
          alignment: "left",
        },
        quote: {
          italics: true,
        },
        small: {
          fontSize: 6,
        },
        header: {
          color: "#0d0a39",
          fontSize: 10,
          bold: true,
          margin: [0, 20, 0, 10],
        },
        tableHeader: {
          bold: true,
          fontSize: 6,
          color: "black",
          alignment: "center",
        },
      },
      defaultStyle: {
        fontSize: 6,
        alignment: "center",
      },
    };
    pdfMake
      .createPdf(Proveedores)
      .download(`Nómina Proveedores ${fechaLocal()}`);
    pdfMake.createPdf(Cheques).download(`Nómina Cheques ${fechaLocal()}`);
    pdfMake
      .createPdf(Remuneraciones)
      .download(`Nómina Remuneraciones ${fechaLocal()}`);
  }

  async crearExcelFiltrado(): Promise<void> {
    const workbook = new Exceljs.Workbook();
    const sheet = workbook.addWorksheet("Nómina", {
      properties: { defaultColWidth: 25 },
    });

    const rows2 = [
      [
        "N°",
        "CENTRO COSTO",
        "TIPO GASTO",
        "RUT PROVEEDOR",
        "NOMBRE PROVEEDOR",
        "N° FACTURA",
        "ORDEN DE COMPRA",
        "MONTO A PAGO",
        "OBSERVACION",
        "FORMA PAGO",
        "ESTADO ",
      ],
      ...this.itemsPdfFiltrados,
      [],
    ];
    sheet.addRows(rows2);

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: "application/xlsx" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "Nomina " + fechaLocal() + ".xlsx";
    link.click();
  }

  async crearExcelHistorico(): Promise<void> {
    const workbook = new Exceljs.Workbook();
    const sheet = workbook.addWorksheet("Nómina", {
      properties: { defaultColWidth: 25 },
    });

    const rows2 = [
      [
        "N°",
        "N° NÓMINA",
        "CENTRO COSTO",
        "TIPO GASTO",
        "RUT PROVEEDOR",
        "NOMBRE PROVEEDOR",
        "N° FACTURA",
        "ORDEN DE COMPRA",
        "MONTO A PAGO",
        "OBSERVACION",
        "FORMA PAGO",
        "ESTADO ",
      ],
      ...this.itemsHistoricos,
      [],
    ];
    sheet.addRows(rows2);

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: "application/xlsx" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "Nomina " + fechaLocal() + ".xlsx";
    link.click();
  }
}
