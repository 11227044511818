





















































































































































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { HeadersDataTable } from "@/typings/vuetify/vDataTable";
import { Contrato } from "@/typings/store/plugins/easyFirestore/contratos";
import { CondicionComercial } from "@/typings/store/plugins/easyFirestore/condicionesComerciales";
import { ItemsTablaContratos } from "@/typings/components/contratos/tabla";
import "@/helpers/extensions";

@Component
export default class TablaUsuarios extends Vue {
  @Prop() readonly opciones!: boolean;
  @Prop() readonly search!: string;
  @Prop() readonly contratos!: Array<Contrato>;

  options = {
    itemsPerPage: -1,
  };
  headerProps = {
    sortIcon: "mdi-chevron-down",
  };

  get headers(): HeadersDataTable {
    return [
      //{text: "", value: "estado", sortable: false},
      { text: "Contrato", value: "codigo" },
      { text: "Centro Costo", value: "centroCosto" },
      { text: "Local", value: "local" },
      { text: "Nombre Comercial", value: "nombre_comercial" },
      { text: "Razon Social", value: "razonSocial" },
      { text: "Desde", value: "fechaInicio" },
      { text: "Hasta", value: "fechaFin" },
      { text: "F. Entrada", value: "" },
      { text: "F. Salida", value: "" },
      { text: "Pdf", value: "pdfContrato" },
      { text: "", value: "opciones", sortable: false },
    ];
  }

  get items(): ItemsTablaContratos {
    return this.contratos.map((contrato) => {
      const codigo = contrato.codigo;
      const centroCosto = contrato.centroCosto;
      const local = contrato.local;
      const nombre_comercial = contrato.nombre_comercial;
      const razonSocial = contrato.razonSocial;
      const fechaInicio = contrato.fechaInicio;
      const fechaFin = contrato.fechaFin;

      return {
        codigo,
        centroCosto,
        local,
        nombre_comercial,
        razonSocial,
        fechaInicio,
        fechaFin,

        data: contrato,
      };
    });
  }

  @Emit("abrir-condicion")
  onAbrirCondicion(condicion: CondicionComercial): CondicionComercial {
    return condicion;
  }

  @Emit("abrir-info")
  onAbrirInfo(contrato: Contrato): Contrato {
    return contrato;
  }

  @Emit("abrir-editar")
  onAbrirEditar(contrato: Contrato): Contrato {
    return contrato;
  }

  @Emit("abrir-eliminar")
  onAbrirEliminar(contrato: Contrato): Contrato {
    return contrato;
  }
}
