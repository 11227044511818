







































// decoradores
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import {Region} from "@/typings/store/plugins/easyFirestore/regiones";
// tipos

@Component
export default class InfoRegion extends Vue {
  @Prop() readonly region!: Region;
  @Prop({ type: Boolean }) readonly showTitulo!: boolean;

  get id(): string {
    return this.region.id || "N/R";
  }

  get nombre(): string {
    return this.region.nombre || "N/R";
  }

  @Emit("close")
  onClose(): null {
    return null;
  }
}
