













// decoradores
import { Component, Vue, Prop } from "vue-property-decorator";
@Component
export default class TituloVista extends Vue {
  @Prop() readonly titulo!: string;
}
