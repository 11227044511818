







































// decoradores
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import {CategoriaCurso} from "@/typings/store/plugins/easyFirestore/categoriasCurso";
// tipos

@Component
export default class InfoCategoriaCurso extends Vue {
  @Prop() readonly categoriaCurso!: CategoriaCurso;
  @Prop({ type: Boolean }) readonly showTitulo!: boolean;

  get id(): string {
    return this.categoriaCurso.id || "N/R";
  }

  get nombre(): string {
    return this.categoriaCurso.nombre || "N/R";
  }

  @Emit("close")
  onClose(): null {
    return null;
  }
}
