


























import { Component, Vue, Prop } from "vue-property-decorator";
import { ChartData } from "@/typings/vista/monitoreo";
import { GChart } from "vue-google-charts";
import { Curso } from "@/typings/store/plugins/easyFirestore/cursos";
import { ItemsTablaCursos } from "@/typings/components/cursos/tabla";
import { Pago } from "@/typings/store/plugins/easyFirestore/pagos";

@Component({
  components: { GChart },
})
export default class ComponenteCursoMes extends Vue {
  @Prop() readonly cursos!: Array<Curso>;
  @Prop() readonly cursoItems!: ItemsTablaCursos;
  @Prop() readonly getArrayPagos!: Array<Pago>;

  // chartData = [
  //   ["Mes", "Ventas", { role: "style" }],
  //   ["Enero", 1000, "#1976D2"],
  //   ["Febrero", 1170, "#1976D2"],
  //   ["Marzo", 660, "#1976D2"],
  //   ["Abril", 1030, "#1976D2"],
  //   ["Mayo", 1000, "#1976D2"],
  //   ["Junio", 1170, "#1976D2"],
  //   ["Julio", 660, "#1976D2"],
  //   ["Agosto", 1030, "#1976D2"],
  //   ["Septiembre", 1000, "#1976D2"],
  //   ["Octubre", 1170, "#1976D2"],
  //   ["Noviembre", 660, "#1976D2"],
  //   ["Diciembre", 1030, "#1976D2"],
  // ];
  chartOptions = {
    legend: "none",

    chart: {
      title: "Company Performance",
      subtitle: "Ventas, Expenses, and Profit: 2014-2017",
    },
  };
  get chartData(): ChartData {
    let recaudacionCurso: {
      [id: string]: { mes: string; recaudado: number; color: string };
    } = {};
    let recaudado = 1;
    //console.log(this.getArrayPagos);
    for (const pago of this.getArrayPagos) {
      if (pago.resultado?.status == "AUTHORIZED") {
        if (
          recaudacionCurso[
            pago.resultado.transaction_date.toDate().format("MMMM")
          ]
        ) {
          recaudacionCurso[
            pago.resultado.transaction_date.toDate().format("MMMM")
          ].recaudado += recaudado;
        } else {
          recaudacionCurso = Object.assign({}, recaudacionCurso, {
            [pago.resultado.transaction_date.toDate().format("MMMM")]: {
              mes: pago.resultado.transaction_date.toDate().format("MMMM"),
              recaudado: recaudado,
              color: "#3d3569",
            },
          });
        }
        //}
      }
    }
    const data: ChartData = Object.values(recaudacionCurso).map((pago) => {
      return [pago.mes, pago.recaudado, pago.color];
    });
    //console.log(recaudacionCurso);

    data.unshift(["Mes", "Ventas", { role: "style" }]);
    return data;
  }
}
