














































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import {
  Encuesta,
  // Encuestas,
} from "@/typings/store/plugins/easyFirestore/encuestas";
//import { Pago } from "@/typings/store/plugins/easyFirestore/pagos"
import { Curso } from "@/typings/store/plugins/easyFirestore/cursos";
import "@/helpers/extensions";
import { mapGetters } from "vuex";
//import string from "@/helpers/string";
import { DataFormularioEncuesta } from "@/typings/components/encuestas/formulario";
import { Usuario } from "@/typings/store/plugins/easyFirestore/usuarios";
import GeneraCertificado from "./GeneraCertificado.vue";

@Component({
  computed: mapGetters({
    getUsuario: "usuario/get",
  }),
  components: {
    GeneraCertificado,
  },
})

// los datos salen de curso y de getUsuario
export default class FormularioEncuesta extends Vue {
  @Prop() readonly curso!: Curso;
  @Prop() readonly usuario!: Usuario;
  @Prop() readonly nuevaEncuesta!: boolean;

  //@Prop() readonly pagos!: Array<Pago>;
  dialog = false;

  encuesta: Encuesta | null = null;

  created(): void {
    if (this.nuevaEncuesta === true) {
      this.e1 = 1;
    } else {
      this.e1 = 6;
    }
    // const encuesta = this.encuesta;
    // if (!encuesta) {
    //   this.e1 = this.nuevaEncuesta ? 1 : 6;
    // }
  }

  e1 = 1;

  respuestaUno = 0;
  respuestaDos = 0;
  respuestaTres = 0;
  respuestaCuatro = 0;
  estado = "nueva";
  opinion = "";

  id: string | null = null;

  descargaCertificado(): void {
    this.dialog = false;
    this.e1 = 1;
  }

  guardar(): void {
    this.e1 = 6;
    // console.log(this.usuario);
    // this.dialog = false;
    // this.e1 = 1;

    const doc: Encuesta = {
      id: this.usuario.id + this.curso.id,
      cliente: this.usuario,
      curso: this.curso,
      estado: "existente",
      respuestas: [
        this.respuestaUno,
        this.respuestaDos,
        this.respuestaTres,
        this.respuestaCuatro,
        this.opinion,
      ],
    };
    if (this.id) {
      doc.id = this.id;
    }
    const data: DataFormularioEncuesta = {
      doc: doc,
    };
    this.onClickGuardar(data);

    return;
  }

  @Emit("click-guardar")
  onClickGuardar(data: DataFormularioEncuesta): DataFormularioEncuesta {
    return data;
  }
}
