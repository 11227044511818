




















































































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
// helpers
//import { idtify } from "@/helpers/string";
// tipos
//import { Rutas } from "@/typings/store/modules/rutas";
//import {
//  Permisos,
//  TipoUsuario,
//  Usuario,
//} from "@/typings/store/plugins/easyFirestore/usuarios";
import {
  Banco,
  //Bancos
} from "@/typings/store/plugins/easyFirestore/bancos";
// validaciones
import {
  // email,
  required,
} from "vuelidate/lib/validators";
import { DataFormularioBanco } from "@/typings/components/bancos/formulario";
import SVGIcon from "@/components/SVGIcon.vue";
//import { Archivo, Archivos } from "@/typings/store/modules/archivos";

@Component({
  components: {
    SVGIcon,
  },
  validations: {
    nombre: { required },
    posicion: { required },
  },
})
export default class FormularioBanco extends Vue {
  @Prop() readonly banco!: Banco | null;
  @Prop() readonly cargando!: boolean;
  //   @Prop() readonly rutas!: Rutas;
  //   @Prop() readonly ciudades!: Ciudad[];
  //   @Prop() readonly cargando!: boolean;
  //   @Prop() readonly regiones!: Region[];

  created(): void {
    // Object.keys(this.rutas).forEach((key) => {
    //   this.$set(this.permisos, key, false);
    // });
    // console.log(this.permisos);
    const banco = this.banco;
    if (!banco) {
      return;
    }
    this.id = banco.id ?? "";
    this.nombre = banco.nombre;
    this.posicion = banco.posicion ?? "";
  }

  id: string | null = null;

  nombre = "";
  posicion = "";

  get titulo(): string {
    return this.banco ? "editar banco" : "nuevo banco";
  }

  //   get permisosKey(): Array<string> {
  //     // return Object.keys(this.permisos).filter((key) => {
  //     //   if(this.rutas[key].tipos.includes(this.tipo)){
  //     //     this.permisos[key] = true;
  //     //   }
  //     // });
  //     return Object.keys(this.permisos).filter((key) => {
  //       if (!this.rutas[key]) {
  //         return false;
  //       }
  //       const tipo = this.rutas[key].tipos.includes(this.tipo);
  //       return tipo;
  //     });
  //   }

  get nombreError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.nombre.$dirty) return errors;
    if (!this.$v.nombre.required) errors.push("Completar datos obligatorios");
    return errors;
  }

  get posicionError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.posicion.$dirty) return errors;
    if (!this.$v.posicion.required) errors.push("Completar datos obligatorios");
    return errors;
  }

  @Emit("close")
  onClose(): null {
    return null;
  }

  @Emit("cerrar-dialogo")
  onCerrarDialogo(): void {
    return;
  }

  @Emit("click-guardar")
  onClickGuardar(data: DataFormularioBanco): DataFormularioBanco {
    return data;
  }

  //   @Watch("permisosKey", { immediate: true })
  //   onTipoChange(): void {
  //     console.log("a");
  //     for (const key of Object.keys(this.permisos)) {
  //       if (this.permisos[key]) {
  //         this.permisos[key] = false;
  //       }
  //     }
  //     this.permisosKey.forEach((key) => {
  //       this.permisos[key] = true;
  //     });
  //   }

  //   nombrePermiso(key: string): string {
  //     return this.rutas[key].text;
  //   }

  guardar(): void {
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }
    const doc: Banco = {
      nombre: this.nombre,
      posicion: this.posicion,
    };
    if (this.id) {
      doc.id = this.id;
    }
    const data: DataFormularioBanco = {
      doc: doc,
    };
    this.onClickGuardar(data);
  }
}
