import { RouteConfig } from "vue-router";
import CajaChicasLinares from "@/views/CajaChicasLinares.vue";

const cajaChicasLinares: RouteConfig = {
    path: "/cajaChicasLinares",
    name: "cajaChicasLinares",
    component: CajaChicasLinares,
};

export default cajaChicasLinares;
