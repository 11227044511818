
















































































































































































// decoradores
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
// tipos
import { HeadersDataTable } from "@/typings/vuetify/vDataTable";
import { Medidor } from "@/typings/store/plugins/easyFirestore/medidores";
import { ItemsTablaMedidores } from "@/typings/components/medidores/tabla";
import "@/helpers/extensions";

@Component
export default class TablaUsuarios extends Vue {
  @Prop() readonly opciones!: boolean;
  @Prop() readonly search!: string;
  @Prop() readonly medidores!: Array<Medidor>;

  options = {
    itemsPerPage: -1,
  };
  headerProps = {
    sortIcon: "mdi-chevron-down",
  };

  get headers(): HeadersDataTable {
    return [
      //{text: "", value: "nroMedidor", sortable: false},
      { text: "OPM Medidor", value: "nroMedidor" },
      { text: "Punto de Medidas", value: "" },
      { text: "Ultimo Operador en el SUC", value: "" },
      { text: "Estado Medidor", value: "estado" },
      { text: "Fecha Creación", value: "" },
      { text: "Tipo Medidor", value: "" },
      { text: "Tipo Tarifa", value: "" },
      { text: "Marca Medidor", value: "" },
      { text: "Modelo Medidor", value: "" },
      { text: "Serie Medidor", value: "" },
      { text: "Limite kw", value: "" },
      { text: "Arriendo Equipo", value: "" },
      { text: "Pot H Normal", value: "" },
      { text: "Pot H Punta", value: "" },
      { text: "Pot Respaldo", value: "" },
      { text: "Constante", value: "" },
      { text: "Diametro", value: "" },
      { text: "Entero", value: "" },
      { text: "Decimal", value: "" },
      { text: "Con Cobro", value: "" },
      { text: "Opciones", value: "" },



      { text: "", value: "opciones", sortable: false },
    ];
  }

  get items(): ItemsTablaMedidores {
    return this.medidores.map((medidor) => {
      const estado = medidor.estado;
      const operador = medidor.operador;
      const nroMedidor = medidor.nroMedidor;

      return {
        operador,
        nroMedidor,
        estado,

        data: medidor,
      };
    });
  }

  //   actualizarEstado(item: Usuario): void {
  //     let nroMedidor: EstadoUsuario;
  //     item.nroMedidor === 'libre' ? nroMedidor = 'bloqueado' : nroMedidor = 'libre';
  //     item.nroMedidor = nroMedidor;
  //     this.onCambiarEstado(item);
  //   }

  //   @Emit("cambiar-nroMedidor")
  //   onCambiarEstado(usuario: Usuario): Usuario {
  //     return usuario;
  //   }

  @Emit("abrir-info")
  onAbrirInfo(medidor: Medidor): Medidor {
    return medidor;
  }

  @Emit("abrir-editar")
  onAbrirEditar(medidor: Medidor): Medidor {
    return medidor;
  }

  @Emit("abrir-eliminar")
  onAbrirEliminar(medidor: Medidor): Medidor {
    return medidor;
  }
}
