


































































































































// decoradores
import { Vue, Component } from "vue-property-decorator";
// vuex
import { mapActions, mapGetters } from "vuex";
// tipos

// componentes
import TablaMedidor from "@/components/medidores/Tabla.vue";
import FormularioMedidor from "@/components/medidores/Formulario.vue";
import { DataFormularioMedidor } from "@/typings/components/medidores/formulario";
import DialogoExito from "@/components/DialogoExito.vue";
import DialogoEliminar from "@/components/DialogoEliminar.vue";
import InfoMedidor from "@/components/medidores/Info.vue";
//import BotonesCrud from "@/components/BotonesCrud.vue";
import MensajeEliminar from "@/components/MensajeEliminar.vue";
//import TituloVista from "@/components/TituloVista.vue";
import {
  Medidor,
  Medidores,
  //  Regiones,
} from "@/typings/store/plugins/easyFirestore/medidores";
//import { DataFormularioRegion } from "@/typings/components/regiones/formulario";
import CargarMedidoresExcel from "@/components/excel/CargarMedidores.vue";

const routeGuard = (vm: Vue): boolean => {
  const permisos = vm.getUsuario?.permisos;
  if (permisos && permisos["medidores"]) {
    return true;
  }

  const rutas = vm.getRutasUsuario;
  if (rutas.length > 0) {
    const to = rutas[0].to;
    if (to) {
      if (vm.$route.name !== to.name) {
        vm.$router.replace(to);
      }
      return false;
    }
  }
  if (vm.$route.name !== "inicio") {
    vm.$router.replace({ name: "inicio" });
  }
  return false;
};

@Component({
  computed: mapGetters({
    getUsuarios: "usuarios/get",
    getMedidores: "medidores/get",
    getArrayUsuarios: "usuarios/getArray",
    getUsuario: "usuario/get",
    getRutas: "rutas/get",
    getRutasUsuario: "usuario/getRutas",
    getArrayMedidores: "medidores/getArray",
  }),
  methods: mapActions({
    setMedidor: "medidores/set",
    deleteMedidor: "medidores/delete",
    insertBatchMedidor: "medidores/insertBatch",
    //setArchivo: "archivos/set",
    //deleteArchivo: "archivos/delete",
  }),
  beforeRouteEnter(to, from, next) {
    next(routeGuard);
  },
  components: {
    DialogoEliminar,
    DialogoExito,
    TablaMedidor,
    FormularioMedidor,
    InfoMedidor,
    //BotonesCrud,
    MensajeEliminar,
    CargarMedidoresExcel,
    //TituloVista,
  },
})
export default class VistaBancos extends Vue {
  created(): void {
    if (!routeGuard(this)) {
      return;
    }
  }

  cargando = false;
  dialogoExito = {
    model: false,
    texto: "",
    icono: "",
  };
  search = "";
  medidor: Medidor | null = null;
  dialogoInfo = false;
  dialogoFormulario = false;
  dialogoEliminar = false;
  textoEliminar = "¿ESTÁS SEGURO QUE DESEAS ELIMINAR ESTE BANCO?";
  dialogoCargarExcel = false;

  get medidores(): Medidores {
    return this.getMedidores;
  }

  get items(): Array<Medidor> {
    return this.getArrayMedidores;
  }

  clickItem(val: { id: string } | null): void {
    this.medidor = val ? this.medidores[val.id] : null;
  }

  clickPlus(): void {
    this.medidor = null;
    this.dialogoFormulario = true;
  }

  async clickGuardar(data: DataFormularioMedidor): Promise<void> {
    this.cargando = true;
    const doc = data.doc;
    await this.setMedidor(doc);
    if (doc.id != this.medidor?.id) {
      this.dialogoExito = {
        model: true,
        texto: "¡Banco creado con éxito!",
        icono: require("../assets/icons/double-check-duo.svg"),
      };
    }
    if (doc.id == this.medidor?.id) {
      this.dialogoExito = {
        model: true,
        texto: "¡Banco editado con éxito!",
        icono: require("../assets/icons/editar.svg"),
      };
      this.medidor = doc;
    }
    this.cerrarDialogoFormulario();
    this.cargando = false;
  }

  cerrarDialogoExito(): void {
    this.dialogoExito = {
      model: false,
      texto: "",
      icono: "",
    };
  }

  clickEliminar(): void {
    const medidor = this.medidor;
    if (!medidor?.id) {
      console.error("error id: ", medidor);
      return;
    }
    this.deleteMedidor(medidor.id);
    this.medidor = null;
    this.cerrarDialogoEliminar();
  }

  abrirDialogoInfo(medidor: Medidor): void {
    if (!medidor.id) {
      console.error("error id: ", medidor);
      return;
    }
    const doc = this.medidores[medidor.id];
    if (doc) {
      this.medidor = doc;
      this.dialogoInfo = true;
    }
  }

  cerrarDialogoInfo(): void {
    this.dialogoInfo = false;
    this.medidor = null;
  }

  abrirDialogoFormulario(medidor: Medidor): void {
    if (!medidor.id) {
      console.error("error id: ", medidor);
      return;
    }
    const doc = this.medidores[medidor.id];
    if (doc) {
      this.medidor = doc;
      this.dialogoFormulario = true;
    }
  }

  abrirDialogoEliminar(medidor: Medidor): void {
    if (!medidor.id) {
      console.error("error id: ", medidor);
      return;
    }
    const doc = this.medidores[medidor.id];
    if (doc) {
      this.dialogoEliminar = true;
      this.medidor = doc;
    }
  }

  cerrarDialogoEliminar(): void {
    this.dialogoEliminar = false;
    this.medidor = null;
  }

  cerrarDialogoFormulario(): void {
    this.dialogoFormulario = false;
    this.medidor = null;
  }
}
