import { RouteConfig } from "vue-router";
import Vacancias from "@/views/Vacancias.vue";

const vacancias: RouteConfig = {
    path: "/vacancias",
    name: "vacancias",
    component: Vacancias,
};

export default vacancias;
