




























































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { CondicionComercial } from "@/typings/store/plugins/easyFirestore/condicionesComerciales";
import { ItemsTablaCondicionesComerciales } from "@/typings/components/condicionesComerciales/tabla";
import "@/helpers/extensions";
import { CondicionDivision } from "@/typings/store/plugins/easyFirestore/condicionesDivisiones";

@Component
export default class TablaUsuarios extends Vue {
  @Prop() readonly opciones!: boolean;
  @Prop() readonly search!: string;
  @Prop() readonly condicionesComerciales!: Array<CondicionComercial>;
  @Prop() readonly condicionDivision!: CondicionDivision;

  get items(): ItemsTablaCondicionesComerciales {
    return this.condicionesComerciales.map((condicionComercial) => {
      const codigo_condicion = condicionComercial.codigo_condicion;
      const desde = condicionComercial.desde;
      const hasta = condicionComercial.hasta;
      const liquidacion = condicionComercial.liquidacion;

      return {
        codigo_condicion,
        desde,
        hasta,
        liquidacion,

        data: condicionComercial,
      };
    });
  }

  @Emit("abrir-info")
  onAbrirInfo(condicionComercial: CondicionComercial): CondicionComercial {
    return condicionComercial;
  }

  @Emit("abrir-editar")
  onAbrirEditar(condicionComercial: CondicionComercial): CondicionComercial {
    return condicionComercial;
  }

  @Emit("abrir-eliminar")
  onAbrirEliminar(condicionComercial: CondicionComercial): CondicionComercial {
    return condicionComercial;
  }

  // @Emit("abrir-editar-division")
  // onAbrirEditarDivision(condicionDivision: CondicionDivision): CondicionDivision {
  //   return condicionDivision;
  // }
}
