



















































































// decoradores
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { Usuario } from "@/typings/store/plugins/easyFirestore/usuarios";
import SVGIcon from "@/components/SVGIcon.vue";
// tipos

// tipos
@Component({
  components: {
    SVGIcon,
  },
})
export default class InfoUsuario extends Vue {
  @Prop() readonly usuario!: Usuario;
  @Prop({ type: Boolean }) readonly showTitulo!: boolean;

  get id(): string {
    return this.usuario.id || "N/R";
  }

  get nombre(): string {
    return this.usuario.nombre || "N/R";
  }

  get rut(): string {
    return this.usuario.rut || "N/R";
  }

  get email(): string {
    return this.usuario.email || "N/R";
  }

  get tipo(): string {
    return this.usuario.tipo || "N/R";
  }

  @Emit("cerrar")
  onCerrar(): void {
    return;
  }
}
