






















































































































































































































































































































































































































































































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import {
  Contrato,
  //Bancos
} from "@/typings/store/plugins/easyFirestore/contratos";
// validaciones
import {
  // email,
  required,
} from "vuelidate/lib/validators";
import { DataFormularioContrato } from "@/typings/components/contratos/formulario";
import SVGIcon from "@/components/SVGIcon.vue";
import { Local } from "@/typings/store/plugins/easyFirestore/locales";
import { Cliente } from "@/typings/store/plugins/easyFirestore/clientes";
//import { Archivo, Archivos } from "@/typings/store/modules/archivos";
//importa moment

@Component({
  components: {
    SVGIcon,
  },
  validations: {
    centroCosto: { required },
    razonSocial: { required },
    nombreComercial: { required },
    renovacion: { required },
    facturacion: { required },
    fechaInicio: { required },
    fechaFin: { required },
    responsable: { required },
    telefono: { required },
    pdfContrato: { required },
    diaUf: { required },
    local: { required },
    rubro: { required },
  },
})
export default class FormularioBanco extends Vue {
  @Prop() readonly contrato!: Contrato | null;
  @Prop() readonly cargando!: boolean;
  @Prop() readonly codigoContrato!: number;
  @Prop() readonly locales!: Local[];
  @Prop() readonly clientes!: Cliente[];

  created(): void {
    // Object.keys(this.rutas).forEach((key) => {
    //   this.$set(this.permisos, key, false);
    // });
    // console.log(this.permisos);
    const contrato = this.contrato;
    if (!contrato) {
      return;
    }
    this.centroCosto = contrato.centroCosto ?? "";
    this.nombreComercial = contrato.nombre_comercial ?? "";
    this.codigo = contrato.codigo ?? 0;
    this.id = contrato.id ?? "";
    this.razonSocial = contrato.razonSocial ?? "";
    this.superficieTotal = contrato.superficieTotal ?? "";
    this.fechaInicio = contrato.fechaInicio.toDate().format("YYYY-MM-DD") ?? "";
    this.fechaFin = contrato.fechaFin.toDate().format("YYYY-MM-DD") ?? "";
    this.responsable = contrato.responsable ?? "";
    this.telefono = contrato.telefono ?? "";
    this.pdfContrato = contrato.pdfContrato ?? "";
    this.local = contrato.local ?? "";
    this.diaUf = contrato.dia_uf ?? 0;
    this.renovacion = contrato.renovacion ?? "";
    this.mesesRenovacion = contrato.meses_renovacion ?? 0;
    this.facturacion = contrato.facturacion ?? "";
    this.rubro = contrato.rubro ?? "";
  }

  id: string | null = null;
  codigo = 0;
  local = "";
  centroCosto = "";
  razonSocial = "";
  nombreComercial = "";
  superficieTotal = "";
  fechaInicio = new Date(
    Date.now() - new Date().getTimezoneOffset() * 60000
  )
    .toISOString()
    .substr(0, 10);

  formateaFecha( fecha : string  ): string {
    let array_fecha = fecha.split("-");
    let dia = array_fecha[2];
    let mes = array_fecha[1];
    let ano = array_fecha[0];
    console.log(array_fecha);
    fecha = dia + "-" + mes + "-" + ano;
    return dia + "-" + mes + "-" + ano;
  }

  fechaFin = new Date(
    Date.now() - new Date().getTimezoneOffset() * 60000
  )
    .toISOString()
    .substr(0, 10);
  responsable = "";
  telefono = "";
  pdfContrato = "";
  diaUf = 0;
  items_ocupado = ["SI", "NO"];
  itemsRenovacion = ["Nueva Vigencia", "Sin Renovación"];
  itemsRubro = ["GENERAL"];
  itemsFacturacion = ["ALQUILER Y GTO AFECTO", "ALQUILER Y GTO EXENTO", "ALQUILER AFECTO Y GTO EXENTO", "ALQUILER EXENTO Y GTO AFECTO"];
  renovacion = "";
  mesesRenovacion = 0;
  facturacion = "";
  rubro = "";
  menuFechaInicio = false;
  menuFechaFin = false;


  get itemsDiaUf(): Array<number> {
    const items: Array<number> = [];
    for (let i = 1; i <= 31; i++) {
      items.push(i);
    }
    return items;
  }

  get itemsMesesRenovacion(): Array<number> {
    const items: Array<number> = [];
    for (let i = 1; i <= 540; i++) {
      items.push(i);
    }
    return items;
  }

  get titulo(): string {
    return this.contrato ? "editar contrato" : "nuevo contrato";
  }

  get verificaCodigoContrato(): number {
    let numero = this.codigoContrato;
    let codigo = 0;
    if (this.codigo === 0) {
      this.codigo = numero + 1;
      codigo = this.codigo;
    } else {
      codigo = this.codigo;
    }
    return codigo;
  }

  get itemsCentroCosto(): Array<string> {
    const itemLocal: Array<string> = [];
    this.locales.forEach((local) => {
      itemLocal.push(local.centroCosto);
    });

    return itemLocal.sort((sesionA, sesionB) => {
      return parseInt(sesionA) - parseInt(sesionB);
    });
  }

  get itemsLocales(): Array<string> {
    const itemLocal: Array<string> = [];
    this.locales.forEach((local) => {
      if (local.centroCosto === this.centroCosto) {
        itemLocal.push(local.unidadLocativa);
      }
    });

    return itemLocal.sort((sesionA, sesionB) => {
      return parseInt(sesionA) - parseInt(sesionB);
    });
  }

  get itemSuperficie(): string | undefined {
    let itemSuperficie = "";
    this.locales.forEach((local) => {
      if (
        local.centroCosto === this.centroCosto &&
        local.unidadLocativa === this.local
      ) {
        itemSuperficie = local.superficie;
        this.superficieTotal = itemSuperficie;
      }
    });
    return itemSuperficie;
  }

  get itemsClientes(): Array<string> {
    const itemCliente: Array<string> = [];
    this.clientes.forEach((cliente) => {
      itemCliente.push(cliente.nombre);
    });

    // ordenar por nombre
    return itemCliente.sort((sesionA, sesionB) => {
      return sesionA.localeCompare(sesionB);
    });
  }

  get diaUfError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.diaUf.$dirty) return errors;
    if (!this.$v.diaUf.required) errors.push("");
    return errors;
  }

  get renovacionError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.renovacion.$dirty) return errors;
    if (!this.$v.renovacion.required) errors.push("");
    return errors;
  }


  get razonSocialError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.razonSocial.$dirty) return errors;
    if (!this.$v.razonSocial.required) errors.push("");
    return errors;
  }

  get nombreComercialError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.nombreComercial.$dirty) return errors;
    if (!this.$v.nombreComercial.required) errors.push("");
    return errors;
  }

  get centroCostoError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.centroCosto.$dirty) return errors;
    if (!this.$v.centroCosto.required) errors.push("");
    return errors;
  }

  get fechaInicioError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.fechaInicio.$dirty) return errors;
    if (!this.$v.fechaInicio.required) errors.push("");
    return errors;
  }

  get fechaFinError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.fechaFin.$dirty) return errors;
    if (!this.$v.fechaFin.required) errors.push("");
    return errors;
  }

  get responsableError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.responsable.$dirty) return errors;
    if (!this.$v.responsable.required) errors.push("");
    return errors;
  }

  get telefonoError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.telefono.$dirty) return errors;
    if (!this.$v.telefono.required) errors.push("");
    return errors;
  }

  get pdfContratoError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.pdfContrato.$dirty) return errors;
    if (!this.$v.pdfContrato.required) errors.push("");
    return errors;
  }

  get superficieTotalError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.superficieTotal.$dirty) return errors;
    if (!this.$v.superficieTotal.required) errors.push("");
    return errors;
  }

  get localError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.local.$dirty) return errors;
    if (!this.$v.local.required) errors.push("");
    return errors;
  }

  get facturacionError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.facturacion.$dirty) return errors;
    if (!this.$v.facturacion.required) errors.push("");
    return errors;
  }

  get rubroError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.rubro.$dirty) return errors;
    if (!this.$v.rubro.required) errors.push("");
    return errors;
  }

  @Emit("close")
  onClose(): null {
    return null;
  }

  @Emit("cerrar-dialogo")
  onCerrarDialogo(): void {
    return;
  }

  @Emit("click-guardar")
  onClickGuardar(data: DataFormularioContrato): DataFormularioContrato {
    return data;
  }

  //   @Watch("permisosKey", { immediate: true })
  //   onTipoChange(): void {
  //     console.log("a");
  //     for (const key of Object.keys(this.permisos)) {
  //       if (this.permisos[key]) {
  //         this.permisos[key] = false;
  //       }
  //     }
  //     this.permisosKey.forEach((key) => {
  //       this.permisos[key] = true;
  //     });
  //   }

  //   nombrePermiso(key: string): string {
  //     return this.rutas[key].text;
  //   }

  guardar(): void {
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }
    const doc: Contrato = {
      // id: this.id,
      codigo: this.codigo,
      centroCosto: this.centroCosto,
      razonSocial: this.razonSocial,
      superficieTotal: this.superficieTotal,
      fechaInicio: this.formateaFecha(this.fechaInicio),
      fechaFin: this.formateaFecha(this.fechaFin),
      responsable: this.responsable,
      telefono: this.telefono,
      pdfContrato: this.pdfContrato,
      dia_uf: this.diaUf,
      local: this.local,
      nombre_comercial: this.nombreComercial,
      renovacion: this.renovacion,
      meses_renovacion: this.mesesRenovacion,
      facturacion: this.facturacion,
      rubro: this.rubro,
    };
    if (this.id) {
      doc.id = this.id;
    }
    const data: DataFormularioContrato = {
      doc: doc,
    };
    this.onClickGuardar(data);
  }
}
