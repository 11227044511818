



















import { Component, Vue, Prop } from "vue-property-decorator";
import { ChartData } from "@/typings/vista/monitoreo";
import { GChart } from "vue-google-charts";
//import { Pago } from "@/typings/store/plugins/easyFirestore/pagos";
import { Curso } from "@/typings/store/plugins/easyFirestore/cursos";
import { ItemsTablaCursos } from "@/typings/components/cursos/tabla";

@Component({
  components: { GChart },
})
export default class ComponenteCursosCategoria extends Vue {
  @Prop() readonly cursos!: Array<Curso>;
  @Prop() readonly cursoItems!: ItemsTablaCursos;
  dialog = false;
  // chartData = [
  //   ["Mes", "Ventas", { role: "style" }],
  //   ["Legales", 1000, "#1E88E5"],
  //   ["Informatica", 1170, "#1E88E5"],
  //   ["Cocina", 660, "#1E88E5"],
  // ];
  // chartOptions = {
  //   chart: {
  //     title: "Company Performance",
  //     subtitle: "Ventas, Expenses, and Profit: 2014-2017",
  //   },
  // };
  chartOptions = {
    legend: "none",
  };
  get chartData(): ChartData {
    let categorias: {
      [id: string]: { categoria: string; cantidad: number; color: string };
    } = {};

    let cantidad = 1;

    this.cursoItems.forEach((item) => {
      if (categorias[item.categoria]) {
        categorias[item.categoria].cantidad += cantidad;
      } else {
        categorias = Object.assign({}, categorias, {
          [item.categoria]: {
            categoria: item.categoria,
            cantidad: cantidad,
            color: "#3d3569",
          },
        });
      }
    });

    const data: ChartData = Object.values(categorias).map((item) => {
      return [item.categoria, item.cantidad, item.color];
    });
    //console.log(data);
    data.unshift(["Relator", "Cursos", { role: "style" }]);
    //console.log(Object.values(categorias));
    return data;
  }
}
