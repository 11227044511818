




































































































































































































// decoradores
import {Component, Emit, Prop, Vue} from "vue-property-decorator";
// tipos
import {HeadersDataTable} from "@/typings/vuetify/vDataTable";
import {Curso} from "@/typings/store/plugins/easyFirestore/cursos";
import {ItemsTablaCursos} from "@/typings/components/cursos/tabla";
import "@/helpers/extensions";
import {Usuario} from "@/typings/store/plugins/easyFirestore/usuarios";
import {Sesion} from "@/typings/store/plugins/easyFirestore/sesiones";
import {Inscripcion} from "@/typings/store/plugins/easyFirestore/inscripciones";
import Time from "@/helpers/Time";

@Component
export default class TablaCursos extends Vue {
  @Prop() readonly opciones!: boolean;
  @Prop() readonly cursos!: Array<Curso>;
  @Prop() readonly sesiones!: Array<Sesion>;
  @Prop() readonly arrayInscripciones!: Array<Inscripcion>;
  @Prop() readonly usuario!: Usuario;
  @Prop() readonly search!: string;

  options = {
    itemsPerPage: -1,
  };
  headerProps = {
    sortIcon: "mdi-chevron-down"
  }

  get headers(): HeadersDataTable {
    return [
      {text: "", value: "estado", sortable: false},
      {text: "Nombre", value: "nombre"},
      {text: "Inicio", value: "inicio"},
      {text: "Fin", value: "fin"},
      {text: "N° Clases", value: "clases"},
      {text: "Asistentes", value: "asistentes"},
      {text: "Duración", value: "duracion"},
      {text: "Categoría", value: "categoria"},
      {text: "Precio", value: "precio"},
      {text: "", value: "opciones", sortable: false}
    ];
  }

  get items(): ItemsTablaCursos {
    return this.cursos.map((curso) => {
      const nombre = curso.nombre;
      const descripcion = curso.descripcion;
      const categoria = curso.categoria.nombre;
      const relator = curso.relator.nombre;
      const precio = curso.precio.toCurrency({symbol: '$'});
      const inicio = curso.inicio.toDate().format('DD/MM/YYYY');
      const fin = curso.fin.toDate().format('DD/MM/YYYY');
      const estado = curso.estado.toString();
      return {
        nombre,
        descripcion,
        categoria,
        relator,
        precio,
        inicio,
        fin,
        estado,
        data: curso
      };
    });
  }

  @Emit("abrir-info")
  onAbrirInfo(curso: Curso): Curso {
    return curso;
  }

  @Emit("abrir-editar")
  onAbrirEditar(curso: Curso): Curso {
    return curso;
  }

  @Emit("abrir-revision")
  onAbrirRevision(curso: Curso): Curso {
    return curso;
  }

  @Emit("abrir-eliminar")
  onAbrirEliminar(curso: Curso): Curso {
    return curso;
  }

  @Emit("abrir-duplicar")
  onAbrirDuplicar(curso: Curso): Curso {
    return curso;
  }

  cantMinutos(curso: Curso): string {
    const sesiones: Sesion[] = this.sesiones.filter(sesion => sesion.curso.id === curso.id);
    let raw = sesiones.reduce((sum, sesion) => sum + sesion.duracion, 0);
    const time = Time.fromMinutes(raw);
    return time.toString(false, true, true, false);
    // console.log(raw)
    // if (raw == null) {
    //   return "--:--";
    // }
    // let signo = "";
    // if (raw < 0) {
    //   signo = "- ";
    //   raw *= -1;
    // }
    // const horas = Math.floor(raw / 60)
    //   .toString()
    //   .padStart(2, "0");
    // const minutos = Math.round(raw % 60)
    //   .toString()
    //   .padStart(2, "0");
    // return signo + horas + ":" + minutos;
  }

  getColor(curso: Curso): Array<string> {
    switch (curso.estado) {
      case "pendiente":
        return ["#747c8d", "rgba(116, 124, 141, 0.4)"];
      case "revisado":
        return ["#e5dd0d", "rgba(229, 221, 13, 0.4)"];
      case "aceptado":
        return ["#279b18", "rgba(39, 155, 24, 0.4)"];
      case "rechazado":
        return ["#ea5143", "rgba(234, 81, 67, 0.4)"];
      case "impartiendo":
        return ["#3790d1", "rgb(55, 144, 209, 0.4)"];
      case "terminado":
        return ["#000000", "rgba(0, 0, 0, 0.4)"];
      default:
        return ["", ""];
    }
  }

  cantClases(curso: Curso): number {
    return this.sesiones.filter(sesion => sesion.curso.id === curso.id).length;
  }

  cantAsistentes(curso: Curso): number {
    return this.arrayInscripciones.filter(inscripcion => Object.values(inscripcion.pago.cursos).some(cursso => cursso.id === curso.id)).length;

  }
}
