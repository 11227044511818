import { RouteConfig } from "vue-router";
import DistribuidoresEnergia from "@/views/DistribuidoresEnergia.vue";

const distribuidoresEnergia: RouteConfig = {
    path: "/distribuidoresEnergia",
    name: "distribuidoresEnergia",
    component: DistribuidoresEnergia,
};

export default distribuidoresEnergia;
