


























































































































































































































































































































































































































// decoradores
import { Vue, Component, Prop } from "vue-property-decorator";
// vuex
import { mapActions, mapGetters } from "vuex";
// tipos

// componentes
import TablaPrenominaWeb from "@/components/prenominas/Tabla2.vue";
import TablaPrenominaMobile from "@/components/prenominas/Tabla.vue";
import FormularioPrenomina from "@/components/prenominas/Formulario.vue";
import { DataFormularioPrenomina } from "@/typings/components/prenominas/formulario";
import DialogoExito from "@/components/DialogoExito.vue";
import DialogoEliminar from "@/components/DialogoEliminar.vue";
import InfoPrenomina from "@/components/prenominas/Info.vue";
import ResumenPrenomina from "@/components/prenominas/Resumen.vue";

//import BotonesCrud from "@/components/BotonesCrud.vue";
import MensajeEliminar from "@/components/MensajeEliminar.vue";
//import TituloVista from "@/components/TituloVista.vue";
import {
  Prenomina,
  Prenominas,
  //  Regiones,
} from "@/typings/store/plugins/easyFirestore/prenominas";
import {
  //Usuario,
  TipoUsuario,
} from "@/typings/store/plugins/easyFirestore/usuarios";
import { Proveedor } from "@/typings/store/plugins/easyFirestore/proveedores";
import { RegistrosEgresos } from "@/typings/store/plugins/easyFirestore/registrosEgresos";
import { idtify } from "@/helpers/string";
// Helpers
import { fechaLocal } from "@/helpers/date";
import "@/helpers/extensions";
// PDF
import pdfMake from "pdfmake/build/pdfmake";
//import pdfFonts from "pdfmake/build/vfs_fonts";
import { TDocumentDefinitions } from "pdfmake/interfaces";
import VistaPreviaPdf from "@/components/VistaPreviaPdf.vue";
import { Archivo } from "@/typings/store/modules/archivos";

// Excel
import Exceljs from "exceljs";

//import { DataFormularioRegion } from "@/typings/components/regiones/formulario";
import CargarPrenominasExcel from "@/components/excel/CargarBancos.vue";
import { HeadersDataTable } from "@/typings/vuetify/vDataTable";
import { ItemsTablaPrenominas } from "@/typings/components/prenominas/tabla";
import SVGIcon from "@/components/SVGIcon.vue";
import { CentroCosto } from "@/typings/store/plugins/easyFirestore/centrosCostos";
import { TipoCargo } from "@/typings/store/plugins/easyFirestore/tipoCargos";
import { Usuario } from "@/typings/store/plugins/easyFirestore/usuarios";
import Nomina from "@/components/prenominas/Nomina.vue";
import ComentarioPrenomina from "@/components/prenominas/ComentarioPrenomina.vue";

import Historico from "@/components/prenominas/Historico.vue";
import Recurrente from "@/components/prenominas/Recurrente.vue";
import {
  // MapeoGastos,
  MapeoGasto,
  //MapeoGastos,
} from "@/typings/store/plugins/easyFirestore/MapeoGastos";
//import { Orden, Ordenes } from "@/typings/store/plugins/easyFirestore/ordenes";
//import es from "vuetify/src/locale/es";

const routeGuard = (vm: Vue): boolean => {
  const permisos = vm.getUsuario?.permisos;
  if (permisos && permisos["prenominas"]) {
    return true;
  }

  const rutas = vm.getRutasUsuario;
  if (rutas.length > 0) {
    const to = rutas[0].to;
    if (to) {
      if (vm.$route.name !== to.name) {
        vm.$router.replace(to);
      }
      return false;
    }
  }
  if (vm.$route.name !== "inicio") {
    vm.$router.replace({ name: "inicio" });
  }
  return false;
};

@Component({
  computed: mapGetters({
    getUsuarios: "usuarios/get",
    getPrenominas: "prenominas/get",
    getArrayUsuarios: "usuarios/getArray",
    getUsuario: "usuario/get",
    getRutas: "rutas/get",
    getRutasUsuario: "usuario/getRutas",
    getArrayBancos: "bancos/getArray",
    getArrayPrenominas: "prenominas/getArray",
    getArrayProveedores: "proveedores/getArray",
    getArrayCentrosCostos: "centrosCostos/getArray",
    getArrayTipoCargos: "tipoCargos/getArray",
    getArrayMapeoGastos: "mapeoGastos/getArray",
    getArrayRegistrosEgresos: "registrosEgresos/getArray",
    getArrayOrdenes: "ordenes/getArray",
  }),
  methods: mapActions({
    setPrenomina: "prenominas/set",
    deletePrenomina: "prenominas/delete",
    insertBatchPrenominas: "prenominas/insertBatch",
    setArchivo: "archivos/set",
    deleteArchivo: "archivos/delete",
  }),
  beforeRouteEnter(to, from, next) {
    next(routeGuard);
  },
  components: {
    DialogoEliminar,
    DialogoExito,
    TablaPrenominaWeb,
    TablaPrenominaMobile,
    FormularioPrenomina,
    InfoPrenomina,
    //BotonesCrud,
    MensajeEliminar,
    CargarPrenominasExcel,
    //TituloVista,
    Historico,
    SVGIcon,
    VistaPreviaPdf,
    Nomina,
    Recurrente,
    ResumenPrenomina,
    ComentarioPrenomina,
  },
})
export default class VistaBancos extends Vue {
  @Prop() readonly usuario?: Usuario;

  created(): void {
    if (!routeGuard(this)) {
      return;
    }
  }
  vistaPrevia = {
    model: false,
    url: "",
  };
  cargando = false;
  dialogoExito = {
    model: false,
    texto: "",
    icono: "",
  };
  search = "";
  searchNomina = "";
  selectPagos = "";
  arrayNominas = ["TODAS", "PROVEEDORES", "REMUNERACIONES"];
  arrayPagos = ["TODAS", "CHEQUE", "NOMINA"];
  arrayEstado = ["APROBADO", "RECHAZADO"];
  proveedor: Proveedor | null = null;
  prenomina: Prenomina | null = null;
  centroCosto: CentroCosto | null = null;
  tipoCargo: TipoCargo | null = null;
  mapeoGasto: MapeoGasto | null = null;
  registroEgresos: RegistrosEgresos | null = null;

  dialogoInfo = false;
  dialogoFormulario = false;
  dialogoEliminar = false;
  textoEliminar = "¿ESTÁS SEGURO QUE DESEAS ELIMINAR ESTA PRENOMINA?";
  dialogoCargarExcel = false;
  dialog2 = false;
  dialog3 = false;
  dialog4 = false;

  selectNomina = 0;
  // snackbar
  snackbar = false;
  text = "";
  timeout = 2000;

  dialogoComentario = false;

  get prenominas(): Prenominas {
    return this.getPrenominas;
  }

  get ArrayMapeoGastos(): Array<MapeoGasto> {
    return this.getArrayMapeoGastos.filter(
      (mapeado) => mapeado.recurrente === "SI"
    );
  }

  get ArrayPrenominas(): Array<Prenomina> {
    return this.getArrayPrenominas;
  }

  buscar = "";
  estados = ["URGENTE!", "ATRASADO!", "NORMAL"];
  filtrarCentroCosto = "";
  filtrarProveedor = "";
  filtrarOrden = "";
  filtrarEstado = "";
  filtrarAprobacion = "";
  estadosAprobacion = ["APROBADO", "PENDIENTE", "VISADO", "RECHAZADO"];

  get items(): Array<Prenomina> {
    return this.getArrayPrenominas.filter((prenomina) => {
      const normal =
        parseInt(this.calculaDias(prenomina.fechaVencimiento)) < 15;
      const atrasada =
        parseInt(this.calculaDias(prenomina.fechaVencimiento)) >= 15 &&
        parseInt(this.calculaDias(prenomina.fechaVencimiento)) < 30;
      const urgente =
        parseInt(this.calculaDias(prenomina.fechaVencimiento)) >= 30;

      if (this.buscar) {
        return (
          prenomina.observacion
            .toLowerCase()
            .indexOf(this.buscar.toLowerCase()) !== -1
        );
      }
      if (this.filtrarCentroCosto) {
        return (
          prenomina.centroCosto
            .toLowerCase()
            .indexOf(this.filtrarCentroCosto.toLowerCase()) !== -1
        );
      }
      if (this.filtrarProveedor) {
        return (
          prenomina.nombreProveedor
            .toLowerCase()
            .indexOf(this.filtrarProveedor.toLowerCase()) !== -1
        );
      }
      if (this.filtrarOrden) {
        return (
          prenomina.ordenCompra
            .toString()
            .toLowerCase()
            .indexOf(this.filtrarOrden.toLowerCase()) !== -1
        );
      }
      if (this.filtrarAprobacion) {
        return (
          prenomina.estado
            .toString()
            .toLowerCase()
            .indexOf(this.filtrarAprobacion.toLowerCase()) !== -1
        );
      }
      if (this.filtrarEstado) {
        if (this.filtrarEstado === "NORMAL") {
          return normal;
        }
        if (this.filtrarEstado === "URGENTE!") {
          return urgente;
        }
        if (this.filtrarEstado === "ATRASADO!") {
          return atrasada;
        }
        //   return (
        //     prenomina.estado
        //       .toString()
        //       .toLowerCase()
        //       .indexOf(this.filtrarEstado.toLowerCase()) !== -1
        //   );
      }
      return true;
    });
  }

  calculaDias(texto: string | undefined): string {
    let fecha = "";
    let fechaInvertida = "";
    const fechaActual = fechaLocal();
    //si texto === undifined
    if (texto === undefined) {
      return "";
    } else {
      fecha = texto;
      //pasar texto de formato dd-mm-aaaa a aaaa-mm-dd
      const fechaArray = fecha.split("-");
      const fechaArrayInvertido = fechaArray.reverse();
      fechaInvertida = fechaArrayInvertido.join("-");
    }

    //calcula los dias entre fechaActual y fechaInvertida
    const fecha1 = new Date(fechaInvertida);
    const fecha2 = new Date(fechaActual);
    const diffTime = Math.abs(fecha2.getTime() - fecha1.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays.toString();

    // return fechaInvertida;
  }

  get tipoUsuario(): TipoUsuario {
    if (!this.getUsuario) {
      return "sudo";
    }
    return this.getUsuario.tipo;
  }

  get numeroMaximo(): number {
    let numero = 0;
    let arrayNumeros = [numero];
    this.getArrayPrenominas.forEach((prenomina) => {
      if (prenomina.numeroPrenominas != numero) {
        arrayNumeros.push(prenomina.numeroPrenominas);
      }
    });
    let max = Math.max.apply(Math, arrayNumeros);
    return max;
  }

  /* aqui  separa cheque de nomina */
  get cantidadNominas(): Array<number> {
    let numero = 1;
    let arrayNumeros = [numero];
    this.getArrayPrenominas.forEach((prenomina) => {
      if (prenomina.numeroPrenominas != numero) {
        arrayNumeros.push(prenomina.numeroPrenominas);
      }
    });
    return arrayNumeros
      .sort((sesionA, sesionB) => {
        return sesionA - sesionB;
      })
      .reverse();
  }

  get sumaNomina(): number {
    let numero = 0;
    let arrayNumeros = [numero];
    this.getArrayPrenominas.forEach((prenomina) => {
      if (prenomina.estado === "aprobado") {
        if (prenomina.numeroPrenominas === this.selectNomina) {
          arrayNumeros.push(parseInt(prenomina.montoAP));
        }
      }
    });
    let suma = arrayNumeros.reduce((a, b) => a + b, 0);
    return suma;
  }

  get sumaNominaCheque(): number {
    let numero = 0;
    let arrayNumeros = [numero];
    this.getArrayPrenominas.forEach((prenomina) => {
      if (
        prenomina.estado === "aprobado" &&
        prenomina.numeroPrenominas === this.selectNomina &&
        prenomina.formaPago === "CHEQUE"
      ) {
        arrayNumeros.push(parseInt(prenomina.montoAP));
      }
    });
    let suma = arrayNumeros.reduce((a, b) => a + b, 0);
    console.log(arrayNumeros);
    return suma;
  }

  get sumaNominaBanco(): number {
    let numero = 0;
    let arrayNumeros = [numero];
    this.getArrayPrenominas.forEach((prenomina) => {
      if (
        prenomina.estado === "aprobado" &&
        prenomina.numeroPrenominas === this.selectNomina &&
        prenomina.formaPago === "NOMINA"
      ) {
        arrayNumeros.push(parseInt(prenomina.montoAP));
      }
    });
    let suma = arrayNumeros.reduce((a, b) => a + b, 0);
    return suma;
  }

  get montoPDF(): number {
    let monto = 0;
    if (this.selectPagos === "CHEQUE") {
      monto = this.sumaNominaCheque;
    }
    if (this.selectPagos === "NOMINA") {
      monto = this.sumaNominaBanco;
    }
    if (this.selectPagos === "TODAS") {
      monto = this.sumaNomina;
    }
    // else {
    //
    // }

    return monto;
  }

  get sumaPrenomina(): number {
    let numero = 0;
    let arrayNumeros = [numero];
    this.getArrayPrenominas.forEach((prenomina) => {
      if (prenomina.estado === "aprobado") {
        if (prenomina.ingresada === false) {
          arrayNumeros.push(parseInt(prenomina.montoAP));
        }
      }
    });
    let suma = arrayNumeros.reduce((a, b) => a + b, 0);
    return suma;
  }

  get sumaPrenominaCheque(): number {
    let numero = 0;
    let arrayNumeros = [numero];
    this.getArrayPrenominas.forEach((prenomina) => {
      if (prenomina.estado === "aprobado") {
        if (prenomina.ingresada === false) {
          if (prenomina.formaPago === "CHEQUE") {
            arrayNumeros.push(parseInt(prenomina.montoAP));
          }
        }
      }
    });
    let suma = arrayNumeros.reduce((a, b) => a + b, 0);
    return suma;
  }

  get sumaPrenominaNomina(): number {
    let numero = 0;
    let arrayNumeros = [numero];
    this.getArrayPrenominas.forEach((prenomina) => {
      if (prenomina.estado === "aprobado") {
        if (prenomina.ingresada === false) {
          if (prenomina.formaPago === "NOMINA") {
            arrayNumeros.push(parseInt(prenomina.montoAP));
          }
        }
      }
    });
    let suma = arrayNumeros.reduce((a, b) => a + b, 0);
    return suma;
  }

  fechaActual = new Date().toString();

  get funcionCuentaTotal(): number {
    let numero = 0;
    let arrayNumeros = [numero];
    this.getArrayPrenominas.forEach((prenomina) => {
      if (
        prenomina.estado === "pendiente" ||
        (prenomina.estado === "aprobado" && prenomina.ingresada === false)
      ) {
        arrayNumeros.push(1);
      }
    });
    let suma = arrayNumeros.reduce((a, b) => a + b, 0);
    return suma;
  }

  get montoCuentaTotal(): number {
    let numero = 0;
    let arrayNumeros = [numero];
    this.getArrayPrenominas.forEach((prenomina) => {
      if (
        prenomina.estado === "pendiente" ||
        (prenomina.estado === "aprobado" && prenomina.ingresada === false)
      ) {
        arrayNumeros.push(parseInt(prenomina.montoAP));
      }
    });
    let suma = arrayNumeros.reduce((a, b) => a + b, 0);
    return suma;
  }

  get funcionCuentaAprobados(): number {
    let numero = 0;
    let arrayNumeros = [numero];
    this.getArrayPrenominas.forEach((prenomina) => {
      if (prenomina.estado === "aprobado" && prenomina.ingresada === false) {
        arrayNumeros.push(1);
      }
    });
    let suma = arrayNumeros.reduce((a, b) => a + b, 0);
    return suma;
  }

  get montoCuentaAprobados(): number {
    let numero = 0;
    let arrayNumeros = [numero];
    this.getArrayPrenominas.forEach((prenomina) => {
      if (prenomina.estado === "aprobado" && prenomina.ingresada === false) {
        arrayNumeros.push(parseInt(prenomina.montoAP));
      }
    });
    let suma = arrayNumeros.reduce((a, b) => a + b, 0);
    return suma;
  }

  get funcionCuentaPendientes(): number {
    let numero = 0;
    let arrayNumeros = [numero];
    this.getArrayPrenominas.forEach((prenomina) => {
      if (prenomina.estado === "pendiente") {
        arrayNumeros.push(1);
      }
    });
    let suma = arrayNumeros.reduce((a, b) => a + b, 0);
    return suma;
  }

  get montoCuentaPendientes(): number {
    let numero = 0;
    let arrayNumeros = [numero];
    this.getArrayPrenominas.forEach((prenomina) => {
      if (prenomina.estado === "pendiente") {
        arrayNumeros.push(parseInt(prenomina.montoAP));
      }
    });
    let suma = arrayNumeros.reduce((a, b) => a + b, 0);
    return suma;
  }

  get centrosCostos(): Array<string> {
    let centrosCostos: Array<string> = [];
    this.items.forEach((prenomina) => {
      if (!centrosCostos.includes(prenomina.centroCosto)) {
        centrosCostos.push(prenomina.centroCosto);
      }
    });
    centrosCostos.sort((a, b) =>
      a.localeCompare(b, undefined, { numeric: true, sensitivity: "base" })
    );
    return centrosCostos;
  }

  get empresas(): Array<string> {
    let empresas: Array<string> = [];
    this.items.forEach((prenomina) => {
      if (!empresas.includes(prenomina.nombreProveedor)) {
        empresas.push(prenomina.nombreProveedor);
      }
    });
    empresas.sort();
    return empresas;
  }

  get ordenesCompra(): Array<string> {
    let ordenes: Array<string> = [];
    this.items.forEach((prenomina) => {
      if (!ordenes.includes(prenomina.ordenCompra.toString())) {
        ordenes.push(prenomina.ordenCompra.toString());
      }
    });
    ordenes.sort((a, b) => {
      return parseInt(b) - parseInt(a);
    });
    return ordenes;
  }

  generaPrenomina(): void {
    let counterVal = this.numeroMaximo;
    let numero = counterVal;
    let existePrenomina = false;
    let cuentaAprobados = 0;
    this.ArrayPrenominas.forEach((prenomina) => {
      if (prenomina.estado === "aprobado") {
        console.log("Existen aprobados");
        if (prenomina.modificado === false) {
          if (
            prenomina.autorizado === "11111111-1" ||
            prenomina.autorizado === "12021663-5"
          ) {
            prenomina.numeroPrenominas = numero + 1;
            // this.setPrenomina(prenomina);
            //console.log("aprobados y modificados");
            cuentaAprobados++;
            existePrenomina = true;
            prenomina.modificado = true;
            prenomina.ingresada = true;
            prenomina.estado;
            prenomina.pasoNomina = new Date().format("DD-MM-YYYY");

            this.setPrenomina(prenomina);
          }
        }
      } else {
        prenomina.numeroPrenominas = numero;
        // this.setPrenomina(prenomina);
        console.log("No Existen Aprobados");
      }
    });

    if (existePrenomina) {
      this.snackbar = true;
      this.text =
        "SE CARGAN PAGOS: " + cuentaAprobados + " A LA NOMINA " + numero;
      ++counterVal;
    } else {
      this.snackbar = true;
      this.text =
        "NO EXISTEN PAGOS PARA ENVIAR A LA NOMINA " + (numero + 1).toString();
    }
  }

  visualizaPrenomina(): void {
    this.dialog2 = true;
  }

  visualizaRecurrentes(): void {
    this.dialog3 = true;
  }

  visualizaHistorico(): void {
    this.dialog4 = true;
  }
  // reseteaPrenomina(): number {
  //   this.counterVal = 1;
  //   return this.counterVal;
  // }

  clickItem(val: { id: number } | null): void {
    this.prenomina = val ? this.prenominas[val.id] : null;
  }

  clickPlus(): void {
    this.prenomina = null;
    this.dialogoFormulario = true;
  }

  async clickGuardar(data: DataFormularioPrenomina): Promise<void> {
    this.cargando = true;
    const doc = data.doc;
    const archivo = data.archivo;
    const borrar = data.borrar;
    for (const key in archivo) {
      if (archivo[key].file) {
        const doc = archivo[key];
        const res = await this.setArchivo(doc);
        if (res && res.url) {
          this.$set(archivo, idtify(res.id), res);
        }
      }
    }
    for (const key in borrar) {
      if (borrar[key]) {
        const doc = borrar[key];
        await this.deleteArchivo(doc.id);
      }
    }
    doc.archivo = archivo;
    await this.setPrenomina(doc);
    if (doc.id != this.prenomina?.id) {
      this.dialogoExito = {
        model: true,
        texto: "¡Prenomina creada con éxito!",
        icono: require("../assets/icons/double-check-duo.svg"),
      };
    }
    if (doc.id == this.prenomina?.id) {
      this.dialogoExito = {
        model: true,
        texto: "¡Prenomina editada con éxito!",
        icono: require("../assets/icons/editar.svg"),
      };
      this.prenomina = doc;
    }
    this.cerrarDialogoFormulario();
    this.cerrarDialogoComentario();
    this.cargando = false;
  }

  cerrarDialogoExito(): void {
    this.dialogoExito = {
      model: false,
      texto: "",
      icono: "",
    };
  }

  clickEliminar(): void {
    const prenomina = this.prenomina;
    if (!prenomina?.id) {
      console.error("error id: ", prenomina);
      return;
    }
    this.deletePrenomina(prenomina.id.toString());
    this.prenomina = null;
    this.cerrarDialogoEliminar();
  }

  abrirDialogoInfo(prenomina: Prenomina): void {
    if (!prenomina.id) {
      console.error("error id: ", prenomina);
      return;
    }
    const doc = this.prenominas[prenomina.id];
    if (doc) {
      this.prenomina = doc;
      this.dialogoInfo = true;
    }
  }
  //
  cambiarEstado(prenomina: Prenomina): void {
    this.setPrenomina(prenomina);
  }

  cambiarEstadoRechazo(prenomina: Prenomina): void {
    this.setPrenomina(prenomina);
  }

  cerrarDialogoInfo(): void {
    this.dialogoInfo = false;
    this.prenomina = null;
  }

  abrirDialogoFormulario(prenomina: Prenomina): void {
    if (!prenomina.id) {
      console.error("error id: ", prenomina);
      return;
    }
    const doc = this.prenominas[prenomina.id];
    if (doc) {
      this.prenomina = doc;
      this.dialogoFormulario = true;
    }
  }

  abrirDialogoEliminar(prenomina: Prenomina): void {
    if (!prenomina.id) {
      console.error("error id: ", prenomina);
      return;
    }
    const doc = this.prenominas[prenomina.id];
    if (doc) {
      this.dialogoEliminar = true;
      this.prenomina = doc;
    }
  }

  abrirDialogoComentario(prenomina: Prenomina): void {
    if (!prenomina.id) {
      console.error("error id: ", prenomina);
      return;
    }
    const doc = this.prenominas[prenomina.id];
    if (doc) {
      this.prenomina = doc;
      this.dialogoComentario = true;
    }
  }

  cerrarDialogoEliminar(): void {
    this.dialogoEliminar = false;
    this.prenomina = null;
  }

  cerrarDialogoFormulario(): void {
    this.dialogoFormulario = false;
    this.prenomina = null;
  }

  cerrarDialogoComentario(): void {
    this.dialogoComentario = false;
    this.prenomina = null;
  }

  //CORREGIR
  async crearPDF(): Promise<void> {
    const link = { url: require("@/assets/logo_salhus_white2.jpg") };
    const result = await fetch(link.url);
    const result2 = await result.blob();
    const fechaFormateada = new Date();
    const base64: string = await new Promise((callback) => {
      let reader = new FileReader();
      reader.onload = function () {
        callback(this.result?.toString() ?? "");
      };
      reader.readAsDataURL(result2);
    });

    if (this.tipoUsuario == "admin" || this.tipoUsuario == "relator") {
      const dd2: TDocumentDefinitions = {
        pageOrientation: "landscape",
        // // pageMargins: [20, 60, 20, 60],
        // header: {
        //   margin: [50, 30, 50, 0],

        //   columns: [
        //     {
        //       text: "Prenomina",
        //       alignment: "center",
        //       fontSize: 10,
        //       bold: true,
        //     },
        //     // {
        //     //   image: "logo",
        //     //   alignment: "right",
        //     //   height: 30,
        //     //   width: 100,
        //     // },
        //   ],
        // },
        content: [
          {
            columns: [
              {
                text: " ",
              },
              {
                image: "logo",
                alignment: "right",
                height: 30,
                width: 100,
              },
            ],
          },
          {
            columns: [
              {
                text: "Detalle Nómina Proveedores",
                style: "titulo",
              },
              {
                text: " ",
              },
            ],
          },
          "\n",
          {
            canvas: [
              { type: "line", x1: 4, y1: 0, x2: 775, y2: 0, lineWidth: 1 },
            ],
          },
          "\n",
          {
            columns: [
              {
                width: "13%",
                text: "Sr.(a):",
                style: "subheader",
              },
              {
                text: "",
              },
              {
                width: "13%",
                text: "Fecha:",
                style: "subheader",
              },
              {
                text: fechaFormateada.format("DD de MMMM del YYYY"),
                style: "subheaderSB",
              },
            ],
          },
          {
            columns: [
              {
                width: "13%",
                text: "Empresa:",
                style: "subheader",
              },
              {
                text: "INVERSIONES STA FIDELMIRA LTDA",
                style: "subheaderSB",
              },

              {
                width: "13%",
                text: "Rut Empresa:",
                style: "subheader",
              },
              {
                text: "79.991.280-5",
                style: "subheaderSB",
              },
            ],
          },
          {
            columns: [
              {
                width: "13%",
                text: "Folio Nómina:",
                style: "subheader",
              },
              {
                text: this.selectNomina.toString(),
                style: "subheaderSB",
              },
              {
                width: "13%",
                text: "Total a Pago:",
                style: "subheader",
              },
              {
                text: this.sumaItemsPdf.toCurrency({ symbol: "$" }),
                style: "subheaderSB",
              },
            ],
          },

          "\n",
          {
            table: {
              widths: [
                //  "*",
                "2%",
                "10%",
                "*",
                "*",
                "*",
                "8%",
                "8%",
                "*",
                "25%",
                "5%",
                "5%",
              ],
              body: [
                [
                  { text: "N°", style: "tableHeader" },
                  //              { text: " ", style: "tableHeader" },
                  { text: "CENTRO COSTO", style: "tableHeader" },
                  { text: "TIPO GASTO", style: "tableHeader" },

                  { text: "RUT PROVEEDOR", style: "tableHeader" },
                  { text: "NOMBRE PROVEEDOR", style: "tableHeader" },
                  { text: "N° FACTURA", style: "tableHeader" },
                  { text: "ORDEN DE COMPRA", style: "tableHeader" },

                  { text: "MONTO PAGO", style: "tableHeader" },
                  { text: "OBSERVACION", style: "tableHeader" },
                  { text: "FORMA PAGO", style: "tableHeader" },
                  { text: "ESTADO", style: "tableHeader" },
                ],
                ...this.itemsPdf,
              ],
            },
          },
        ],
        images: {
          logo: base64,
        },
        // styles: {
        //   header: {
        //     color: "#0d0a39",
        //     fontSize: 10,
        //     bold: true,
        //     margin: [0, 20, 0, 10],
        //   },
        //   tableHeader: {
        //     bold: true,
        //     fontSize: 8,
        //     color: "black",
        //     alignment: "center",
        //   },
        // },
        // defaultStyle: {
        //   fontSize: 6,
        //   alignment: "center",
        // },
        styles: {
          titulo: {
            fontSize: 10,
            bold: true,
            alignment: "left",
          },
          subheader: {
            fontSize: 8,
            bold: true,
            alignment: "left",
          },
          subheaderSB: {
            fontSize: 8,
            bold: false,
            alignment: "left",
          },
          quote: {
            italics: true,
          },
          small: {
            fontSize: 6,
          },
          header: {
            color: "#0d0a39",
            fontSize: 10,
            bold: true,
            margin: [0, 20, 0, 10],
          },
          tableHeader: {
            bold: true,
            fontSize: 6,
            color: "black",
            alignment: "center",
          },
        },
        defaultStyle: {
          fontSize: 6,
          alignment: "center",
        },
      };

      pdfMake.createPdf(dd2).download(`Nómina ${fechaLocal()}`);
    }
  }

  async createExcel(): Promise<void> {
    const workbook = new Exceljs.Workbook();
    const sheet = workbook.addWorksheet("Prenomina", {
      properties: { defaultColWidth: 25 },
    });

    const rows2 = [
      [
        "PRENOMINA",
        "CENTRO COSTO",
        "TIPO GASTO",
        "RUT PROVEEDOR",
        "NOMBRE PROVEEDOR",
        "N° FACTURA",
        "ORDEN DE COMPRA",
        "MONTO A PAGO",
        "OBSERVACION",
        "FORMA PAGO",
        "ESTADO ",
      ],
      ...this.itemsPdf,
      [],
    ];
    sheet.addRows(rows2);

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: "application/xlsx" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "Prenomina " + fechaLocal() + ".xlsx";
    link.click();
  }

  get itemsPdf(): Array<Array<string | number>> {
    //let PrenominasRecorridas: Array<string> = [];
    return this.items.filter((prenomina) => {
      //const estado = prenomina.estado !== "aprobado";
      const nPrenomina = prenomina.ingresada === false ;
      return nPrenomina;
    }).map((prenomina) => {
      const centroCosto = prenomina.centroCosto;
      const numeroPrenominas = prenomina.numeroPrenominas ?? 0;
      const rut = prenomina.rut;
      const nombreProveedor = prenomina.nombreProveedor;
      const nFactura = prenomina.nFactura;
      const ordenCompra = prenomina.ordenCompra;
      const montoAP = parseInt(prenomina.montoAP).toCurrency({ symbol: "$" }); //se modifica por monto a pagar
      const estado = prenomina.estado;
      const observacion = prenomina.observacion;
      const tipoGasto = prenomina.tipoGasto ?? "";
      const formaPago = prenomina.formaPago ?? "";
      return Object.values({
        numeroPrenominas,
        centroCosto,
        tipoGasto,
        rut,
        nombreProveedor,
        nFactura,
        ordenCompra,
        montoAP,
        observacion,
        formaPago,
        estado,
      });
    });
  }
  //necesito la sumatorria de los montos a pagar de items pdf
  get sumaItemsPdf(): number {
    let numero = 0;
    let arrayNumeros = [numero];
    this.items.forEach((prenomina) => {
      if (prenomina.estado !== "aprobado") {
        arrayNumeros.push(parseInt(prenomina.montoAP));
      }
    });
    let suma = arrayNumeros.reduce((a, b) => a + b, 0);
    return suma;
  }


  // VISUALIZA NOMINA
  headerProps = {
    sortIcon: "mdi-chevron-down",
  };

  get headers(): HeadersDataTable {
    return [
      { text: "", value: "estado", sortable: false },
      { text: "Centro Costo", value: "" },
      { text: "Tipo Gasto", value: "" },
      { text: "F. Pago", value: "formaPago" },
      { text: "Rut Proveedor", value: "rut" },
      { text: "Nombre Proveedor", value: "nombreProveedor" },
      { text: "N° Factura", value: "nFactura" },

      { text: "Orden de Compra", value: "ordenCompra" },
      { text: "Monto a Pago", value: "montoAP" },
      { text: "Observacion", value: "observacion" },
      { text: "Reseña", value: "" },
      { text: "", value: "pdf", sortable: false },
      { text: "", value: "rechazos", sortable: false },
    ];
  }

  get itemsFiltradoNomina(): ItemsTablaPrenominas {
    return this.items
      .map((prenomina) => {
        const centroCosto = prenomina.centroCosto;
        const numeroPrenominas = prenomina.numeroPrenominas;
        const ingresada = prenomina.ingresada;
        const modificado = prenomina.modificado;
        const rut = prenomina.rut;
        const nombreProveedor = prenomina.nombreProveedor;
        const nFactura = prenomina.nFactura;
        const ordenCompra = prenomina.ordenCompra;
        //const tipoCambio = prenomina.tipoCambio;
        const montoOC = (
          parseInt(prenomina.montoOC, 10).toCurrency() ?? ""
        ).toString();
        const porcentajePagado = prenomina.porcentajePagado;
        const montoSaldo = prenomina.montoSaldo;
        const montoAP = (
          parseInt(prenomina.montoAP, 10).toCurrency({ symbol: "$" }) ?? ""
        ).toString();
        const observacion = prenomina.observacion;
        const estado = prenomina.estado;
        const formaPago = prenomina.formaPago;
        const tipoGasto = prenomina.tipoGasto;

        return {
          modificado,
          numeroPrenominas,
          ingresada,
          rut,
          nombreProveedor,
          nFactura,
          ordenCompra,
          //   tipoCambio,
          montoOC,
          porcentajePagado,
          montoSaldo,
          montoAP,
          observacion,
          estado,
          formaPago,
          centroCosto,
          tipoGasto,
          data: prenomina,
        };
      })
      .filter(
        (prenomina) =>
          prenomina.ingresada == true &&
          prenomina.numeroPrenominas === this.selectNomina
      );
  }

  //ITEMS PDF
  get itemsPdfFiltrados(): Array<Array<string | number>> {
    //let PrenominasRecorridas: Array<string> = [];
    let correlativo = 0;
    return this.ArrayPrenominas.filter((prenomina) => {
      console.log(this.selectPagos);
      if (this.selectPagos === "CHEQUE" || this.selectPagos === "NOMINA") {
        console.log(this.selectPagos);
        const estado = prenomina.estado === "aprobado";
        const numeroPrenomina =
          prenomina.numeroPrenominas === this.selectNomina;
        const formaPago = prenomina.formaPago === this.selectPagos;
        const ingresada = prenomina.ingresada === true;
        return estado && numeroPrenomina && formaPago && ingresada;
      } else {
        const numeroPrenominas =
          prenomina.numeroPrenominas === this.selectNomina;
        const estado = prenomina.estado === "aprobado";
        const ingresada = (prenomina.ingresada = true);

        return estado && numeroPrenominas && ingresada;
      }
    }).map((prenomina) => {
      correlativo = correlativo + 1;
      //const numeroPrenominas = prenomina.numeroPrenominas ?? 0;
      const rut = prenomina.rut;
      const nombreProveedor = prenomina.nombreProveedor;
      const nFactura = prenomina.nFactura;
      const ordenCompra = prenomina.ordenCompra;
      const montoAP = parseInt(prenomina.montoAP).toCurrency({ symbol: "$" }); //se modifica por monto a pagar
      const estado = prenomina.estado.toUpperCase();
      const observacion = prenomina.observacion;
      const centroCosto = prenomina.centroCosto;
      const tipoGasto = prenomina.tipoGasto;
      const formaPago = prenomina.formaPago;
      return Object.values({
        correlativo,
        //numeroPrenominas,
        centroCosto,
        tipoGasto,
        rut,
        nombreProveedor,
        nFactura,
        ordenCompra,
        montoAP,
        observacion,
        formaPago,
        estado,
      });
    });
  }

  abrirVistaPrevia(archivo: Archivo): void {
    this.vistaPrevia = {
      model: true,
      url: this.urlArchivo(archivo),
    };
  }

  urlArchivo(media: Archivo): string {
    return media.url ?? URL.createObjectURL(media.file);
  }

  cerrarVistaPrevia(): void {
    this.vistaPrevia = {
      model: false,
      url: "",
    };
  }
}
