


































































// decoradores
import { Vue, Component } from "vue-property-decorator";
// vuex
import { mapActions, mapGetters } from "vuex";
// tipos

// componentes
import TablaCiudades from "@/components/ciudades/Tabla.vue";
import FormularioCiudad from "@/components/ciudades/Formulario.vue";
import InfoCiudad from "@/components/ciudades/Info.vue";
import BotonesCrud from "@/components/BotonesCrud.vue";
import MensajeEliminar from "@/components/MensajeEliminar.vue";
import TituloVista from "@/components/TituloVista.vue";
import {
  Ciudad,
  Ciudades,
} from "@/typings/store/plugins/easyFirestore/ciudades";
import { Region } from "@/typings/store/plugins/easyFirestore/regiones";
import { DataFormularioCiudad } from "@/typings/components/ciudades/formulario";

const routeGuard = (vm: Vue): boolean => {
  const permisos = vm.getUsuario?.permisos;
  if (permisos && permisos["ciudades"]) {
    return true;
  }

  const rutas = vm.getRutasUsuario;
  if (rutas.length > 0) {
    const to = rutas[0].to;
    if (to) {
      if (vm.$route.name !== to.name) {
        vm.$router.replace(to);
      }
      return false;
    }
  }
  if (vm.$route.name !== "inicio") {
    vm.$router.replace({ name: "inicio" });
  }
  return false;
};

@Component({
  computed: mapGetters({
    getCiudades: "ciudades/get",
    getRegiones: "regiones/get",
    getArrayRegiones: "regiones/getArray",
    getArrayCiudades: "ciudades/getArray",
    getUsuario: "usuario/get",
    getRutasUsuario: "usuario/getRutas",
  }),
  methods: mapActions({
    setCiudad: "ciudades/set",
    deleteCiudad: "ciudades/delete",
  }),
  beforeRouteEnter(to, from, next) {
    next(routeGuard);
  },
  components: {
    TablaCiudades,
    FormularioCiudad,
    InfoCiudad,
    BotonesCrud,
    MensajeEliminar,
    TituloVista,
  },
})
export default class VistaCiudad extends Vue {
  created(): void {
    if (!routeGuard(this)) {
      return;
    }
  }
  search = "";
  ciudad: Ciudad | null = null;
  dialogoInfo = false;
  dialogoFormulario = false;
  dialogoEliminar = false;

  get ciudades(): Ciudades {
    return this.getCiudades;
  }

  get regiones(): Array<Region> {
    return this.getArrayRegiones;
  }

  get items(): Array<Ciudad> {
    return this.getArrayCiudades;
  }

  clickItem(val: { id: string } | null): void {
    this.ciudad = val ? this.ciudades[val.id] : null;
  }

  clickPlus(): void {
    this.ciudad = null;
    this.dialogoFormulario = true;
  }

  async clickGuardar(data: DataFormularioCiudad): Promise<void> {
    const doc = data.doc;
    this.setCiudad(doc);
    if (doc.id == this.ciudad?.id) {
      this.ciudad = doc;
    }
    this.cerrarDialogoFormulario();
  }

  clickEliminar(): void {
    const ciudad = this.ciudad;
    if (!ciudad?.id) {
      console.error("error id: ", ciudad);
      return;
    }
    this.deleteCiudad(ciudad.id);
    this.ciudad = null;
    this.cerrarDialogoEliminar();
  }

  abrirDialogoInfo(ciudad: Ciudad): void {
    if (!ciudad.id) {
      console.error("error id: ", ciudad);
      return;
    }
    const doc = this.ciudades[ciudad.id];
    if (doc) {
      this.ciudad = doc;
      this.dialogoInfo = true;
    }
  }

  cerrarDialogoInfo(): void {
    this.dialogoInfo = false;
    this.ciudad = null;
  }

  abrirDialogoFormulario(ciudad: Ciudad): void {
    if (!ciudad.id) {
      console.error("error id: ", ciudad);
      return;
    }
    const doc = this.ciudades[ciudad.id];
    if (doc) {
      this.ciudad = doc;
      this.dialogoFormulario = true;
    }
  }

  cerrarDialogoFormulario(): void {
    this.dialogoFormulario = false;
    this.ciudad = null;
  }

  abrirDialogoEliminar(ciudad: Ciudad): void {
    if (!ciudad.id) {
      console.error("error id: ", ciudad);
      return;
    }
    const doc = this.ciudades[ciudad.id];
    if (doc) {
      this.dialogoEliminar = true;
      this.ciudad = doc;
    }
  }

  cerrarDialogoEliminar(): void {
    this.dialogoEliminar = false;
    this.ciudad = null;
  }
}
