import { RouteConfig } from "vue-router";
import CajaChicasTalca from "@/views/CajaChicasTalca.vue";

const cajaChicasTalca: RouteConfig = {
    path: "/cajaChicasTalca",
    name: "cajaChicasTalca",
    component: CajaChicasTalca,
};

export default cajaChicasTalca;
