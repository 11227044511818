


























































































































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { HeadersDataTable } from "@/typings/vuetify/vDataTable";
import { CajaChica } from "@/typings/store/plugins/easyFirestore/cajaChicas";
import { ItemsTablaCajaChicas } from "@/typings/components/cajaChicas/tabla";
import "@/helpers/extensions";

@Component
export default class TablaCajaChicas extends Vue {
  @Prop() readonly opciones!: boolean;
  @Prop() readonly search!: string;
  @Prop() readonly cajaChicas!: Array<CajaChica>;
  @Prop() readonly usuario!: string;

  options = {
    itemsPerPage: -1,
  };
  headerProps = {
    sortIcon: "mdi-chevron-down",
  };

  get headers(): HeadersDataTable {
    return [
      { text: "N°", value: "numero" },
      { text: "Fecha", value: "fecha" },
      { text: "Rut Prov", value: "rutProveedor" },
      { text: "Nombre Prov", value: "nombreProveedor" },
      { text: "Acción", value: "accion" },
      { text: "Ingresos", value: "ingreso" },
      { text: "Egresos", value: "egreso" },
      { text: "Documento", value: "documento" },
      { text: "Concepto", value: "concepto" },
      { text: "Descripcion", value: "descripcion" },
      { text: "", value: "opciones", sortable: false },
    ];
  }

  get items(): ItemsTablaCajaChicas {
    return this.cajaChicas.map((cajaChica) => {
      const numero = cajaChica.numero;
      const fecha = cajaChica.fecha;
      const saldoActual = cajaChica.saldoActual;
      const rutProveedor = cajaChica.rutProveedor;
      const nombreProveedor = cajaChica.nombreProveedor;
      const accion = cajaChica.accion;
      const ingreso = (
        parseInt(cajaChica.ingreso, 10).toCurrency({ symbol: "$" }) ?? 0
      ).toString();
      const egreso = (
        parseInt(cajaChica.egreso, 10).toCurrency({ symbol: "$" }) ?? 0
      ).toString();
      const documento = cajaChica.documento;
      const concepto = cajaChica.concepto;
      const descripcion = cajaChica.descripcion;

      return {
        numero,
        fecha,
        saldoActual,
        rutProveedor,
        nombreProveedor,
        accion,
        ingreso,
        egreso,
        documento,
        concepto,
        descripcion,

        data: cajaChica,
      };
    });
  }

  @Emit("abrir-info")
  onAbrirInfo(cajaChica: CajaChica): CajaChica {
    return cajaChica;
  }

  @Emit("abrir-editar")
  onAbrirEditar(cajaChica: CajaChica): CajaChica {
    return cajaChica;
  }

  @Emit("abrir-eliminar")
  onAbrirEliminar(cajaChica: CajaChica): CajaChica {
    return cajaChica;
  }
}
