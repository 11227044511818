import { RouteConfig } from "vue-router";
import CodCuentas from "@/views/CodCuentas.vue";

const codCuentas: RouteConfig = {
    path: "/codCuentas",
    name: "codCuentas",
    component: CodCuentas,
};

export default codCuentas;
