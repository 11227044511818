



































































































// decoradores
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { Contrato } from "@/typings/store/plugins/easyFirestore/contratos";
import SVGIcon from "@/components/SVGIcon.vue";
// tipos

// tipos
@Component({
  components: {
    SVGIcon,
  },
})
export default class InfoBanco extends Vue {
  @Prop() readonly contrato!: Contrato;
  @Prop({ type: Boolean }) readonly showTitulo!: boolean;

  get id(): string {
    return this.contrato.id || "N/R";
  }

  get razonSocial(): string {
    return this.contrato.razonSocial || "N/R";
  }

  get dia_uf(): number {
    return this.contrato.dia_uf || 0;
  }

  get superficieTotal(): string {
    return this.contrato.superficieTotal || "N/R";
  }

  get fechaInicio(): string {
    return this.contrato.fechaInicio  || "N/R";
  }

  get fechaFin(): string {
    return this.contrato.fechaFin || "N/R";
  }

    get responsable(): string {
    return this.contrato.responsable || "N/R";
  }

    get telefono(): string {
    return this.contrato.telefono || "N/R";
  }

    get pdfContrato(): string {
    return this.contrato.pdfContrato || "N/R";
  }



  @Emit("cerrar")
  onCerrar(): void {
    return;
  }
}
