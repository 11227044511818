































































// decoradores
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { TipoEgreso } from "@/typings/store/plugins/easyFirestore/tipoEgresos";
import SVGIcon from "@/components/SVGIcon.vue";
// tipos

// tipos
@Component({
  components: {
    SVGIcon,
  },
})
export default class InfoBanco extends Vue {
  @Prop() readonly tipoEgreso!: TipoEgreso;
  @Prop({ type: Boolean }) readonly showTitulo!: boolean;

  get id(): string {
    return this.tipoEgreso.id || "N/R";
  }

  get nombre(): string {
    return this.tipoEgreso.nombre || "N/R";
  }

  get posicion(): string {
    return this.tipoEgreso.posicion || "N/R";
  }


  @Emit("cerrar")
  onCerrar(): void {
    return;
  }
}
