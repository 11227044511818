











import {Component, Prop, Vue, Watch} from "vue-property-decorator";

@Component
export default class SVGIcon extends Vue {
  /// The color you want to use for the icon. It can be any valid CSS color or a Vuetify Light Theme color.
  @Prop() readonly color!: string;
  /// The src of the SVG file. It must be a valid SVG file and loaded with require().
  @Prop() readonly src?: string;
  /// The name of the icon. It must be a valid SVG icon name and exist in the folder @/assets/icons with SVG extension.
  @Prop() readonly name?: string
  /// The opacity of the icon. It must be a number between 0 and 1.
  @Prop() readonly opacity?: number | string;
  /// The width of the icon. It must be a valid CSS length.
  @Prop({default: "24px"}) readonly width!: string;
  /// The height of the icon. It must be a valid CSS length.
  @Prop({default: "24px"}) readonly height!: string;
  /// Set this to true if you want to stop the animation of the icon.
  @Prop({type: Boolean, default: false}) readonly noAnimations!: boolean;

  mounted(): void {
    this.mountSVG();
  }

  @Watch("color")
  onColorChange(): void {
    this.mountSVG();
  }

  @Watch("width")
  onWidthChange(): void {
    this.mountSVG();
  }

  @Watch("height")
  onHeightChange(): void {
    this.mountSVG();
  }

  @Watch("src")
  onSrcChange(): void {
    this.mountSVG();
  }

  @Watch("name")
  onNameChange(): void {
    this.mountSVG();
  }

  @Watch("opacity")
  onOpacityChange(): void {
    this.mountSVG();
  }

  @Watch("noAnimations")
  onNoAnimationsChange(): void {
    this.mountSVG();
  }

  async mountSVG(): Promise<void> {
    let src = this.src;
    if(this.name) {
      const split = this.name.split("/");
      const name = split[split.length - 1].split(".")[0];
      const icons = require.context('@/assets/icons', false)
      src = icons(`./${name}.svg`);
    }
    if(src) {
      const response = await fetch(src);
      const svg = await response.text();
      const svgContent = this.$refs.svgContent as HTMLElement;
      const vuetifyTheme = this.$vuetify.theme?.themes?.light;
      const primaryColor = vuetifyTheme[this.color] as string ?? this.color;
      if (svgContent && svg) {
        svgContent.innerHTML = svg;
        const svgElement = svgContent.querySelector("svg") as SVGElement;
        if (this.noAnimations) svgElement.style.animation = "none";
        svgElement.style.width = this.width;
        svgElement.style.height = this.height;
        for (const children of svgElement.querySelectorAll("*")) {
          const child = children as SVGElement;
          if (this.noAnimations) child.style.animation = "none";
          if (child.style.fill || child.getAttribute("fill")) {
            child.style.fill = primaryColor;
          }
          if (this.opacity) child.style.opacity = this.opacity.toString();
          if (child.style.stroke || child.getAttribute("stroke")) {
            child.style.stroke = primaryColor;
          }
        }
      }
    } else {
      throw new Error("No SVG source provided");
    }
  }
}
