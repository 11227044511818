// vuex
import { Store } from "vuex";
// firebase
import { Firebase, initFirebase } from "@/config/firebase";
// tipos
import { State } from "@/typings/store";
import { Usuario } from "@/typings/store/plugins/easyFirestore/usuarios";
// helpers
import { idtify } from "@/helpers/string";
// imports
import abrirCanalesBD from "./abrirCanalesBD";
import cerrarCanalesBD from "./cerrarCanalesBD";
import { Carrito } from "@/typings/store/plugins/easyFirestore/carritos";

const iniciarFirebase = (store: Store<State>): void => {
  initFirebase().then(() => {
    store.dispatch("usuarios/openDBChannel");
    store.dispatch("promociones/openDBChannel");
    store.dispatch("sesiones/openDBChannel");
    store.dispatch("cursos/openDBChannel");
    store.dispatch("regiones/openDBChannel");
    store.dispatch("registrosEgresos/openDBChannel");
    store.dispatch("ciudades/openDBChannel");
    store.dispatch("contactos/openDBChannel");
    store.dispatch("bancos/openDBChannel");
    store.dispatch("centrosCostos/openDBChannel");
    store.dispatch("codCuentas/openDBChannel");
    store.dispatch("tipoEgresos/openDBChannel");
    store.dispatch("proveedores/openDBChannel");
    store.dispatch("tipoDocumentos/openDBChannel");
    store.dispatch("tipoCargos/openDBChannel");
    store.dispatch("matrizPagos/openDBChannel");
    store.dispatch("prenominas/openDBChannel");
    store.dispatch("legales/openDBChannel");
    store.dispatch("cajaChicas/openDBChannel");
    store.dispatch("mapeoGastos/openDBChannel");
    store.dispatch("vacancias/openDBChannel");
    store.dispatch("contratos/openDBChannel");
    store.dispatch("saldoCuentas/openDBChannel");
    store.dispatch("locales/openDBChannel");
    store.dispatch("consumos/openDBChannel");
    store.dispatch("clientes/openDBChannel");
    store.dispatch("ordenes/openDBChannel");
    store.dispatch("medidores/openDBChannel");
    store.dispatch("ingresos/openDBChannel");
    store.dispatch("tarifas/openDBChannel");
    store.dispatch("lecturas/openDBChannel");
    store.dispatch("distribuidoresEnergia/openDBChannel");
    store.dispatch("cajaChicasLinares/openDBChannel");
    store.dispatch("cajaChicasTalca/openDBChannel");
    store.dispatch("solicitudes/openDBChannel");
    store.dispatch("mantenimientos/openDBChannel");
    store.dispatch("equipos/openDBChannel");
    store.dispatch("ordenesERG/openDBChannel");
    store.dispatch("planCuentasIngresos/openDBChannel");
    store.dispatch("condicionesComerciales/openDBChannel");
    store.dispatch("facturas/openDBChannel");
    store.dispatch("condicionesDivisiones/openDBChannel");

    //store.dispatch("visualizacionPagos/openDBChannel");

    Firebase.auth().onAuthStateChanged(async (user) => {
      store.commit("cargando/set", true);
      if (user) {
        if (user.email) {
          await store
            .dispatch("usuarios/fetchById", user.uid)
            .catch((error) => {
              console.error(error);
            });
          const usuarioUID = store.getters["usuarios/get"][user.uid] as
            | Usuario
            | undefined;
          const id = idtify(user.email);
          await store.dispatch("usuarios/fetchById", id).catch((error) => {
            console.error(error);
          });
          const usuarioEmail = store.getters["usuarios/get"][id] as
            | Usuario
            | undefined;
          let usuario: Usuario | null = null;
          if (usuarioEmail) {
            usuario = Object.assign({}, usuarioUID, usuarioEmail);
            usuario.id = user.uid;
            usuario.uid = user.uid;
            await store.dispatch("usuarios/set", usuario);
            await store.dispatch("usuarios/delete", id);
          } else if (!usuarioUID) {
            const clienteUsuario: Usuario = {
              id: user.uid,
              uid: user.uid,
              rut: "",
              nombre: user.providerData[0]?.displayName ?? "",
              email: user.providerData[0]?.email ?? "",
              permisos: {
                misCursos: true,
                cursosDisponibles: true,
                perfil: true,
                notificaciones: true,
                pagos: true,
                agenda: true,
              },
              fecha: new Date().toISOString(),
              telefono: "",
              ciudad: null,
              tipo: "cliente",
              estado: "libre",
              region: "",
            };
            usuario = clienteUsuario;
            await store.dispatch("usuarios/set", clienteUsuario);
          }
          store.dispatch("carritos/fetchById", user.uid).catch((error) => {
            console.error(error);
          });
          console.log(usuario);
          const carrito = store.getters["carritos/get"][user.uid] as
            | Carrito
            | undefined;
          if (!carrito && usuario?.tipo === "cliente") {
            const carrito: Carrito = {
              id: user.uid,
              usuario: usuario,
              estado: "revisado",
              cursos: {},
              promocion: null,
              sesiones: {},
            };
            await store.dispatch("carritos/set", carrito);
          }
        }
        const usuario = store.getters["usuarios/get"][user.uid] as
          | Usuario
          | undefined;
        if (usuario?.estado === "bloqueado") {
          store.commit("setErrorLogin", "inhabilitado");
          store.commit("cargando/set", false);
          Firebase.auth().signOut();
          return;
        }
        if (usuario) {
          usuario.token = await Firebase.messaging()
            .getToken({
              vapidKey:
                "BMFqY0n8SMHXvMsNlfOHtz1vyuD-_FUI29yBXgRnM_W8Equf2K6ro34y2qMIHlwdxqQl0OI4yS1B7zuhpg5m0BI",
            })
            .catch((error) => {
              console.error(error);
              return "";
            });
          await store.dispatch("usuarios/set", usuario);
        }
        store.commit("usuario/set", usuario ? usuario : null);
        if (
          !usuario?.permisos ||
          Object.values(usuario.permisos).length === 0
        ) {
          Firebase.auth().signOut();
          store.commit("cargando/set", false);
          return;
        }
        await abrirCanalesBD(store, usuario);
      } else {
        const usuario = store.getters["usuario/get"];
        if (usuario) {
          await cerrarCanalesBD(store, usuario);
        }
        store.commit("usuario/set", null);
      }
      setTimeout(() => {
        store.commit("cargando/set", false);
      }, 3000);
    });
  });
};

export default iniciarFirebase;
