






















































































































// decoradores
import {Component, Vue, Prop, Emit} from "vue-property-decorator";
import {Curso} from "@/typings/store/plugins/easyFirestore/cursos";
import SVGIcon from "@/components/SVGIcon.vue";
// tipos
@Component({
  components: {SVGIcon}
})
export default class InfoCiudad extends Vue {
  @Prop() readonly curso!: Curso;
  @Prop({type: Boolean}) readonly showTitulo!: boolean;

  get id(): string {
    return this.curso.id || "N/R";
  }

  get nombre(): string {
    return this.curso.nombre || "N/R";
  }

  get resumen(): string {
    return this.curso.resumen || "N/R";
  }

  get descripcion(): string {
    return this.curso.descripcion || "N/R";
  }

  get categoria(): string {
    return this.curso.categoria.nombre || "N/R";
  }

  get tags(): string {
    return Object.values(this.curso.tags).map(tag => tag.nombre).join(", ") || "N/R";
  }

  get relator(): string {
    return this.curso.relator.nombre || "N/R";
  }

  get precio(): string {
    return this.curso.precio.toCurrency({symbol: '$'}) || "N/R";
  }

  get inicio(): string {
    return this.curso.inicio.toDate().format('DD-MM-YYYY') || "N/R";
  }

  get fin(): string {
    return this.curso.fin.toDate().format('DD-MM-YYYY') || "N/R";
  }

  get estado(): string {
    return this.curso.estado || "N/R";
  }

  get observacion(): string {
    return this.curso.observacion || "N/R";
  }

  @Emit("cerrar")
  onCerrar(): void {
    return;
  }
}
