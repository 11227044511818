































































// decoradores
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { Orden } from "@/typings/store/plugins/easyFirestore/ordenes";
import SVGIcon from "@/components/SVGIcon.vue";
// tipos

// tipos
@Component({
  components: {
    SVGIcon,
  },
})
export default class InfoOrden extends Vue {
  @Prop() readonly orden!: Orden;
  @Prop({ type: Boolean }) readonly showTitulo!: boolean;

  get id(): string {
    return this.orden.id || "N/R";
  }

  get nombre(): string {
    return this.orden.nombre || "N/R";
  }

  // get posicion(): string {
  //   return this.orden.posicion || "N/R";
  // }


  @Emit("cerrar")
  onCerrar(): void {
    return;
  }
}
