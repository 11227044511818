export default class Time {
  hours: number;
  minutes: number;
  seconds: number;
  days: number;

  constructor(days: number, hours: number, minutes: number, seconds: number) {
    this.days = days;
    this.hours = hours;
    this.minutes = minutes;
    this.seconds = seconds;
  }

  get inDays(): number {
    return this.days + (this.hours / 24) + (this.minutes / 1440) + (this.seconds / 86400);
  }

  get inHours(): number {
    return (this.days * 24) * this.hours + Math.floor(this.minutes / 60) + Math.floor(this.seconds / 3600);
  }

  get inMinutes(): number {
    return (this.days * 1440) + (this.hours * 60) + this.minutes + Math.floor(this.seconds / 60)
  }

  get inSeconds(): number {
    return (this.days * 86400) + (this.hours * 3600) + (this.minutes * 60) + this.seconds;
  }

  get daysString(): string {
    return this.days.toString().padStart(2, "0");
  }

  get hoursString(): string {
    return this.hours.toString().padStart(2, "0");
  }

  get minutesString(): string {
    return this.minutes.toString().padStart(2, "0");
  }

  get secondsString(): string {
    return this.seconds.toString().padStart(2, "0");
  }

  private static converter(inSeconds: number) {
    let days = 0;
    let hours = 0;
    let minutes = 0;
    let seconds;
    if (inSeconds > 59) {
      days = Math.floor(inSeconds / 86400);
      const modDays = (inSeconds % 86400);
      hours = Math.floor(modDays / 3600);
      const modHours = (modDays % 3600);
      minutes = Math.abs(Math.floor(modHours / 60));
      seconds = Math.abs(Math.floor(modHours % 60));
    } else {
      seconds = inSeconds;
    }
    return {
      days: days,
      hours: hours,
      minutes: minutes,
      seconds: seconds
    };
  }

  static fromString(time: string): Time {
    if (/^\d{2}:\d{2}(:\d{2})*$/g.test(time)) {
      const split = time.split(":");
      const hours = parseInt(split[0]);
      const minutes = parseInt(split[1]);
      let seconds = 0;
      if (split[2]) {
        seconds = parseInt(split[2]);
      }
      return new Time(0, hours, minutes, seconds);
    }
    throw new Error("Invalid time format");
  }

  static fromSeconds(seconds: number): Time {
    const converted = Time.converter(seconds);
    return new Time(converted.days, converted.hours, converted.minutes, converted.seconds);
  }

  static fromMinutes(minutes: number): Time {
    const converted = Time.converter(minutes * 60);
    return new Time(converted.days, converted.hours, converted.minutes, converted.seconds);
  }

  static fromHours(hours: number): Time {
    const converted = Time.converter(hours * 3600);
    return new Time(converted.days, converted.hours, converted.minutes, converted.seconds);
  }

  static fromMilliseconds(ms: number): Time {
    const converted = Time.converter(ms / 1000);
    return new Time(converted.days, converted.hours, converted.minutes, converted.seconds);
  }

  static fromDays(days: number): Time {
    const converted = Time.converter(days * 86400);
    return new Time(converted.days, converted.hours, converted.minutes, converted.seconds);
  }

  add(time: Time): void {
    const converted = Time.converter(time.inSeconds + this.inSeconds);
    this.days = converted.days;
    this.hours = converted.hours;
    this.minutes = converted.minutes;
    this.seconds = converted.seconds;
  }

  subtract(time: Time): void {
    const inSeconds = this.inSeconds - time.inSeconds;
    if (inSeconds > 0) {
      const converted = Time.converter(inSeconds);
      this.days = converted.days;
      this.hours = converted.hours;
      this.minutes = converted.minutes;
      this.seconds = converted.seconds;
    } else {
      throw new Error("Cannot subtract time");
    }
  }

  toString(days?: boolean, hours?: boolean, minutes?: boolean, seconds?: boolean): string {
    const result: Array<string> = [];
    let hoursSum = this.hours;
    let minutesSum = this.minutes;
    let secondsSum = this.seconds;
    if (days === undefined || days) {
      result.push(this.daysString)
    } else {
      hoursSum += this.days * 24;
    }
    if (hours === undefined || hours) {
      result.push(hoursSum.toString().padStart(2, "0"));
    } else {
      minutesSum += hoursSum * 60;
    }
    if (minutes === undefined || minutes) {
      result.push(minutesSum.toString().padStart(2, "0"));
    } else {
      secondsSum += minutesSum * 60;
    }
    if (seconds === undefined || seconds) {
      result.push(secondsSum.toString().padStart(2, "0"));
    }
    return result.join(":");
  }

}