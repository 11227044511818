







































































































































































































































































































































































import { Vue, Component } from "vue-property-decorator";
import { mapActions, mapGetters } from "vuex";
import { idtify } from "@/helpers/string";
import TablaOrden from "@/components/ordenes/Tabla.vue";
import FormularioOrden from "@/components/ordenes/Formulario.vue";
import { DataFormularioOrden } from "@/typings/components/ordenes/formulario";
import DialogoExito from "@/components/DialogoExito.vue";
import DialogoEliminar from "@/components/DialogoEliminar.vue";
import InfoBanco from "@/components/ordenes/Info.vue";
import ArchivoOrden from "@/components/ordenes/Archivos.vue";
import ObservacionOrden from "@/components/ordenes/Observacion.vue";
import RespuestaOrden from "@/components/ordenes/Respuesta.vue";
import MensajeEliminar from "@/components/MensajeEliminar.vue";
import { CodCuenta } from "@/typings/store/plugins/easyFirestore/codCuentas";

import { Orden, Ordenes } from "@/typings/store/plugins/easyFirestore/ordenes";
import { MapeoGasto } from "@/typings/store/plugins/easyFirestore/MapeoGastos";
import {
  Prenomina,
  //Prenominas,
} from "@/typings/store/plugins/easyFirestore/prenominas";
import { RegistrosEgresos } from "@/typings/store/plugins/easyFirestore/registrosEgresos";
import { DataFormularioPrenomina } from "@/typings/components/prenominas/formulario";
import { DataFormularioRegistrosEgresos } from "@/typings/components/registrosEgresos/formulario";
import CargarBancosExcel from "@/components/excel/CargarBancos.vue";

import { TDocumentDefinitions } from "pdfmake/interfaces";
import pdfMake from "pdfmake/build/pdfmake";

const routeGuard = (vm: Vue): boolean => {
  const permisos = vm.getUsuario?.permisos;
  if (permisos && permisos["ordenes"]) {
    return true;
  }

  const rutas = vm.getRutasUsuario;
  if (rutas.length > 0) {
    const to = rutas[0].to;
    if (to) {
      if (vm.$route.name !== to.name) {
        vm.$router.replace(to);
      }
      return false;
    }
  }
  if (vm.$route.name !== "inicio") {
    vm.$router.replace({ name: "inicio" });
  }
  return false;
};

@Component({
  computed: mapGetters({
    getUsuarios: "usuarios/get",
    getOrdenes: "ordenes/get",
    getProveedores: "proveedores/get",
    getArrayUsuarios: "usuarios/getArray",
    getUsuario: "usuario/get",
    getRutas: "rutas/get",
    getPrenominas: "prenominas/get",
    getRutasUsuario: "usuario/getRutas",
    getArrayOrdenes: "ordenes/getArray",
    getArrayProveedores: "proveedores/getArray",
    getArrayMapeoGastos: "mapeoGastos/getArray",
    getArrayCiudades: "ciudades/getArray",
    getArrayPrenominas: "prenominas/getArray",
    getArrayBancos: "bancos/getArray",
    getArrayCodCuentas: "codCuentas/getArray",

    getRegistrosEgresos: "registrosEgresos/get",
    getArrayRegistrosEgresos: "registrosEgresos/getArray",
  }),
  methods: mapActions({
    //ORDENES
    setOrden: "ordenes/set",
    deleteOrden: "ordenes/delete",
    insertBatchOrdenes: "ordenes/insertBatch",
    //ARCHIVOS
    setArchivo: "archivos/set",
    deleteArchivo: "archivos/delete",
    //PRENOMINAS
    setPrenomina: "prenominas/set",
    deletePrenomina: "prenominas/delete",
    insertBatchPrenominas: "prenominas/insertBatch",
    //REGISTROS EGRESOS
    setEgreso: "registrosEgresos/set",
    deleteEgreso: "registrosEgresos/delete",
    insertBatchEgreso: "registrosEgresos/insertBatch",
  }),
  beforeRouteEnter(to, from, next) {
    next(routeGuard);
  },
  components: {
    DialogoEliminar,
    DialogoExito,
    TablaOrden,
    FormularioOrden,
    InfoBanco,
    ArchivoOrden,
    ObservacionOrden,
    RespuestaOrden,
    //BotonesCrud,
    MensajeEliminar,
    CargarBancosExcel,
    //TituloVista,
  },
})
export default class VistaOrdenes extends Vue {
  created(): void {
    if (!routeGuard(this)) {
      return;
    }
  }

  cargando = false;
  dialogoExito = {
    model: false,
    texto: "",
    icono: "",
  };
  buscar = "";
  estados = [
    "TODO",
    "PENDIENTE",
    "APROBADO CON FACTURA",
    "APROBADO SIN FACTURA",
    "APROBADO",
    "RECHAZADO",
  ];
  filtrarCentroCosto = "";
  filtrarProveedor = "";

  filtrarOrden = "";
  filtrarEstado = "";
  filtrarSolicitante = "";
  orden: Orden | null = null;
  prenomina: Prenomina | null = null;
  registrosEgresos: RegistrosEgresos | null = null;
  codCuenta: CodCuenta | null = null;
  dialogoInfo = false;
  dialogoFormulario = false;
  dialogoEliminar = false;
  textoEliminar = "¿ESTÁS SEGURO QUE DESEAS ELIMINAR ESTA ORDEN DE COMPRA?";
  dialogoCargarExcel = false;
  dialogoArchivo = false;
  dialogoObservacion = false;
  dialogoRespuesta = false;

  snackbarPrenomina = false;
  textSnackbarPrenomina = "SE HA ENVIADO EL PAGO A PRENOMINA";
  timeoutPrenomina = 3000;

  textSnackbarExistentePrenomina = "PAGO YA EXISTE EN PRENOMINA";

  snackbarConciliacion = false;
  textSnackbarConciliacion = "SE HA CONCILIADO EL PAGO";
  timeoutConciliacion = 3000;

  objects = [
    {
      message: "",
      color: "",
      timeout: 0,
    },
  ];

  get ordenes(): Ordenes {
    return this.getOrdenes;
  }

  get items(): Array<Orden> {
    return this.getArrayOrdenes.filter((orden) => {
      // muestrame los datos que contengan buscar
      const aprobadoFactura =
        (orden.estado === "APROBADO" &&
          orden.tipoArchivo === "BOLETA/FACTURA") ||
        orden.tipoArchivo2 === "BOLETA/FACTURA" ||
        orden.tipoArchivo3 === "BOLETA/FACTURA" ||
        orden.tipoArchivo4 === "BOLETA/FACTURA" ||
        orden.tipoArchivo5 === "BOLETA/FACTURA" ||
        orden.tipoArchivo6 === "BOLETA/FACTURA";

      const aprobadoSinFactura = orden.color === "#FFA726";

      if (this.buscar) {
        return (
          orden.nombre.toLowerCase().indexOf(this.buscar.toLowerCase()) !== -1
        );
      }
      if (this.filtrarCentroCosto) {
        return (
          orden.centroCosto
            .toLowerCase()
            .indexOf(this.filtrarCentroCosto.toLowerCase()) !== -1
        );
      }
      if (this.filtrarProveedor) {
        return (
          orden.empresa
            .toLowerCase()
            .indexOf(this.filtrarProveedor.toLowerCase()) !== -1
        );
      }
      if (this.filtrarOrden) {
        return (
          orden.ordenCompra
            .toString()
            .toLowerCase()
            .indexOf(this.filtrarOrden.toLowerCase()) !== -1
        );
      }
      if (this.filtrarEstado) {
        if (this.filtrarEstado === "TODO") {
          return true;
        }
        if (this.filtrarEstado === "APROBADO CON FACTURA") {
          return aprobadoFactura;
        }
        if (this.filtrarEstado === "APROBADO SIN FACTURA") {
          return aprobadoSinFactura;
        }
        return (
          orden.estado
            .toString()
            .toLowerCase()
            .indexOf(this.filtrarEstado.toLowerCase()) !== -1
        );
      }
      if (this.filtrarSolicitante) {
        return (
          orden.solicitante
            .toLowerCase()
            .indexOf(this.filtrarSolicitante.toLowerCase()) !== -1
        );
      }
      return orden.estado.toString().toLowerCase().indexOf("pendiente") !== -1;
      // if (this.filtrarProveedor === orden.empresa) {
      //   return true;
      // }
      // return false;
    });
  }

  // calculo de la cantidad de ordenes
  get cantidadOrdenes(): number {
    let numero = 0;
    let arrayNumeros = [numero];
    this.getArrayOrdenes.forEach((orden) => {
      if (orden.ordenCompra != numero) {
        arrayNumeros.push(orden.ordenCompra);
      }
    });
    let max = Math.max.apply(Math, arrayNumeros);
    return max;
  }

  mapeoGasto: MapeoGasto | null = null;

  // ATRIBUTO EMPRESA DE ITEMS SIN REPETIR ordenados alfabeticamente
  get empresas(): Array<string> {
    let empresas: Array<string> = [];
    this.getArrayOrdenes.forEach((orden) => {
      if (!empresas.includes(orden.empresa)) {
        empresas.push(orden.empresa);
      }
    });
    empresas.sort();
    return empresas;
  }

  get centrosCostos(): Array<string> {
    let centrosCostos: Array<string> = [];
    this.getArrayOrdenes.forEach((orden) => {
      if (!centrosCostos.includes(orden.centroCosto)) {
        centrosCostos.push(orden.centroCosto);
      }
    });
    centrosCostos.sort((a, b) =>
      a.localeCompare(b, undefined, { numeric: true, sensitivity: "base" })
    );
    return centrosCostos;
  }

  //ATRIBUTO ESTADO DE ITEMS SIN REPETIR
  // get estados(): Array<string> {
  //   let estados: Array<string> = [];
  //   this.getArrayOrdenes.forEach((orden) => {
  //     if (!estados.includes(orden.estado)) {
  //       estados.push(orden.estado.toUpperCase());
  //     }
  //   });
  //   return estados;
  // }

  //ATRIBUTO ORDEN DE ITEMS SIN REPETIR ordenadas de mayor a menor
  get ordenesCompra(): Array<string> {
    let ordenes: Array<string> = [];
    this.getArrayOrdenes.forEach((orden) => {
      if (!ordenes.includes(orden.ordenCompra.toString())) {
        ordenes.push(orden.ordenCompra.toString());
      }
    });
    ordenes.sort((a, b) => {
      return parseInt(b) - parseInt(a);
    });
    return ordenes;
  }

  get solicitantes(): Array<string> {
    const ordenesSet = new Set<string>(); // Usar un conjunto para almacenar solicitantes únicos

    this.getArrayOrdenes.forEach((orden) => {
      ordenesSet.add(orden.solicitante.toString().toUpperCase()); // Agregar solicitantes al conjunto en mayúsculas
    });

    // Convertir el conjunto ordenesSet a un array, ordenar alfabéticamente y devolverlo
    const ordenesArray = Array.from(ordenesSet).sort((a, b) => {
      return a.localeCompare(b); // Comparar alfabéticamente
    });

    return ordenesArray; // Devolver el array ordenado alfabéticamente sin duplicados
  }

  cambiarEstado(orden: Orden): void {
    this.setOrden(orden);
  }

  cambiarTipoGasto(orden: Orden): void {
    this.setOrden(orden);
  }

  cambiarCuentaSoftland(orden: Orden): void {
    this.setOrden(orden);
  }

  clickItem(val: { id: string } | null): void {
    this.orden = val ? this.ordenes[val.id] : null;
  }

  clickPlus(): void {
    this.orden = null;
    this.dialogoFormulario = true;
  }

  async clickGuardar(data: DataFormularioOrden): Promise<void> {
    this.cargando = true;
    const doc = data.doc;
    const archivo = data.archivo;
    const archivo2 = data.archivo2;
    const archivo3 = data.archivo3;
    const archivo4 = data.archivo4;
    const archivo5 = data.archivo5;
    const archivo6 = data.archivo6;

    const borrar = data.borrar;
    for (const key in archivo) {
      if (archivo[key].file) {
        const doc = archivo[key];
        const res = await this.setArchivo(doc);
        if (res && res.url) {
          this.$set(archivo, idtify(res.id), res);
        }
      }
    }
    for (const key in archivo2) {
      if (archivo2[key].file) {
        const doc = archivo2[key];
        const res = await this.setArchivo(doc);
        if (res && res.url) {
          this.$set(archivo2, idtify(res.id), res);
        }
      }
    }
    for (const key in archivo3) {
      if (archivo3[key].file) {
        const doc = archivo3[key];
        const res = await this.setArchivo(doc);
        if (res && res.url) {
          this.$set(archivo3, idtify(res.id), res);
        }
      }
    }
    for (const key in archivo4) {
      if (archivo4[key].file) {
        const doc = archivo4[key];
        const res = await this.setArchivo(doc);
        if (res && res.url) {
          this.$set(archivo4, idtify(res.id), res);
        }
      }
    }
    for (const key in archivo5) {
      if (archivo5[key].file) {
        const doc = archivo5[key];
        const res = await this.setArchivo(doc);
        if (res && res.url) {
          this.$set(archivo5, idtify(res.id), res);
        }
      }
    }
    for (const key in archivo6) {
      if (archivo6[key].file) {
        const doc = archivo6[key];
        const res = await this.setArchivo(doc);
        if (res && res.url) {
          this.$set(archivo6, idtify(res.id), res);
        }
      }
    }
    for (const key in borrar) {
      if (borrar[key]) {
        const doc = borrar[key];
        await this.deleteArchivo(doc.id);
      }
    }
    doc.archivo = archivo;
    doc.archivo2 = archivo2;
    doc.archivo3 = archivo3;
    doc.archivo4 = archivo4;
    doc.archivo5 = archivo5;
    doc.archivo6 = archivo6;
    await this.setOrden(doc);
    if (doc.id != this.orden?.id) {
      this.dialogoExito = {
        model: true,
        texto: "¡Orden de compra creada con éxito!",
        icono: require("../assets/icons/double-check-duo.svg"),
      };
    }
    if (doc.id == this.orden?.id) {
      this.dialogoExito = {
        model: true,
        texto: "¡Orden de compra editada con éxito!",
        icono: require("../assets/icons/editar.svg"),
      };
      this.orden = doc;
    }
    this.cerrarDialogoFormulario();
    this.cerrarDialogoObservacion();
    this.cerrarDialogoArchivo();

    //   this.objects.push({
    // message:"Success",
    // color:"green",
    // timeout:5000
    // })

    this.cargando = false;
  }

  async clickGuardarPrenomina(data: DataFormularioPrenomina): Promise<void> {
    this.cargando = true;
    const doc = data.doc;
    const archivo = data.archivo;
    const borrar = data.borrar;
    for (const key in archivo) {
      if (archivo[key].file) {
        const doc = archivo[key];
        const res = await this.setArchivo(doc);
        if (res && res.url) {
          this.$set(archivo, idtify(res.id), res);
        }
      }
    }
    for (const key in borrar) {
      if (borrar[key]) {
        const doc = borrar[key];
        await this.deleteArchivo(doc.id);
      }
    }
    doc.archivo = archivo;
    await this.setPrenomina(doc);
    if (doc.id != this.prenomina?.id) {
      // console.log("Paso el dato a prenomina");
      // this.dialogoExito = {
      //   model: true,
      //   texto: "¡Prenomina creada con éxito!",
      //   icono: require("../assets/icons/double-check-duo.svg"),
      // };
      this.snackbarPrenomina = true;
    }
    // if (doc.id == this.prenomina?.id) {
    //   this.dialogoExito = {
    //     model: true,
    //     texto: "¡Prenomina editada con éxito!",
    //     icono: require("../assets/icons/editar.svg"),
    //   };
    //   this.prenomina = doc;
    // }
    this.cerrarDialogoFormulario();

    this.cargando = false;
  }

  async clickGuardarConciliacion(
    data: DataFormularioRegistrosEgresos
  ): Promise<void> {
    this.cargando = true;
    const doc = data.doc;
    this.setEgreso(doc);
    if (doc.id != this.registrosEgresos?.id) {
      // this.dialogoExito = {
      //   model: true,
      //   texto: "¡Registro creado con éxito!",
      //   icono: require("../assets/icons/double-check-duo.svg"),
      // };
      this.snackbarConciliacion = true;
    }
    if (doc.id == this.registrosEgresos?.id) {
      this.dialogoExito = {
        model: true,
        texto: "¡Registro editado con éxito!",
        icono: require("../assets/icons/editar.svg"),
      };
      this.registrosEgresos = doc;
    }
    this.cerrarDialogoFormulario();
    // this.messages.push("This is a message 2");
    this.cargando = false;
  }

  cerrarDialogoExito(): void {
    this.dialogoExito = {
      model: false,
      texto: "",
      icono: "",
    };
  }

  clickEliminar(): void {
    const orden = this.orden;
    if (!orden?.id) {
      console.error("error id: ", orden);
      return;
    }
    this.deleteOrden(orden.id);
    this.orden = null;
    this.cerrarDialogoEliminar();
  }

  abrirDialogoInfo(orden: Orden): void {
    if (!orden.id) {
      console.error("error id: ", orden);
      return;
    }
    const doc = this.ordenes[orden.id];
    if (doc) {
      this.orden = doc;
      this.dialogoInfo = true;
    }
  }

  cerrarDialogoInfo(): void {
    this.dialogoInfo = false;
    this.orden = null;
  }

  abrirDialogoFormulario(orden: Orden): void {
    if (!orden.id) {
      console.error("error id: ", orden);
      return;
    }
    const doc = this.ordenes[orden.id];
    if (doc) {
      this.orden = doc;
      this.dialogoFormulario = true;
    }
  }

  abrirDialogoEliminar(orden: Orden): void {
    if (!orden.id) {
      console.error("error id: ", orden);
      return;
    }
    const doc = this.ordenes[orden.id];
    if (doc) {
      this.dialogoEliminar = true;
      this.orden = doc;
    }
  }

  abrirDialogoArchivo(orden: Orden): void {
    if (!orden.id) {
      console.error("error id: ", orden);
      return;
    }
    const doc = this.ordenes[orden.id];
    if (doc) {
      this.orden = doc;
      this.dialogoArchivo = true;
    }
  }

  abrirDialogoObservacion(orden: Orden): void {
    if (!orden.id) {
      console.error("error id: ", orden);
      return;
    }
    const doc = this.ordenes[orden.id];
    if (doc) {
      this.orden = doc;
      this.dialogoObservacion = true;
    }
  }

  abrirDialogoRespuesta(orden: Orden): void {
    if (!orden.id) {
      console.error("error id: ", orden);
      return;
    }
    const doc = this.ordenes[orden.id];
    if (doc) {
      this.orden = doc;
      this.dialogoRespuesta = true;
    }
  }

  cerrarDialogoEliminar(): void {
    this.dialogoEliminar = false;
    this.orden = null;
  }

  cerrarDialogoFormulario(): void {
    this.dialogoFormulario = false;
    this.orden = null;
  }

  cerrarDialogoArchivo(): void {
    this.dialogoArchivo = false;
    this.orden = null;
  }

  cerrarDialogoObservacion(): void {
    this.dialogoObservacion = false;
    this.orden = null;
  }

  cerrarDialogoRespuesta(): void {
    this.dialogoRespuesta = false;
    this.orden = null;
  }

  abrirSnackbarPrenomina(): void {
    this.dialogoArchivo = false;
  }

  async crearPdfOrdenPago(orden: Orden): Promise<void> {
    const link = { url: require("@/assets/logo_salhus_white2.jpg") };
    const linkAprobada = {
      url: require("@/assets/pdfestados_pages-to-jpg-aprobada.jpg"),
    };
    const result = await fetch(link.url);
    const resultAprobada = await fetch(linkAprobada.url);
    const result2 = await result.blob();
    const result2Aprobada = await resultAprobada.blob();
    //const fechaFormateada = new Date();
    const base64: string = await new Promise((callback) => {
      let reader = new FileReader();
      reader.onload = function () {
        callback(this.result?.toString() ?? "");
      };
      reader.readAsDataURL(result2);
    });

    const base64_1: string = await new Promise((callback) => {
      let reader = new FileReader();
      reader.onload = function () {
        callback(this.result?.toString() ?? "");
      };
      reader.readAsDataURL(result2Aprobada);
    });

    var dd: TDocumentDefinitions = {
      content: [
        {
          columns: [
            {
              image: "logo",
              alignment: "right",
              height: 30,
              width: 100,
            },
            {
              text: "",
            },
            {
              text: "",
            },
          ],
        },
        {
          text: "INVERSIONES SANTA FIDELMIRA LIMITADA",
          style: "header",
        },
        {
          text: "79.991.280-5\nARRIENDO Y EXPLOTACION DE BIENES INMUEBLES\nLAUTARO 429 PISO 2 - LINARES\n732 210881\n\n",
          style: "subheaderSB_Centro",
        },

        {
          style: "tableExample",
          table: {
            headerRows: 1,
            widths: ["*"],
            body: [
              [
                {
                  text: "Orden de Pago N° " + orden.ordenCompra,
                  style: "titulo",
                  border: [false, false, false, true],
                },
              ],
            ],
          },
        },

        "\n",
        {
          columns: [
            {
              image: base64_1,
              alignment: "center",
              height: 60,
              width: 200,
            },
            {
              style: "tableExample",
              table: {
                widths: ["*", "*", "*"],

                body: [
                  [
                    { text: "", style: "subheaderSB" },
                    { text: "Fecha Emisón:", style: "subheaderCB" },
                    { text: orden.fechaEmision, style: "subheaderSB" },
                  ],
                  [
                    { text: "", style: "subheaderSB" },
                    { text: "Estado:", style: "subheaderCB" },
                    {
                      text: orden.estado.capitalizeFirstLetter(),
                      style: "subheaderSB",
                    },
                  ],
                  [
                    { text: "", style: "subheaderSB" },
                    { text: "Aprobado por:", style: "subheaderCB" },
                    { text: orden.autorizado, style: "subheaderSB" },
                  ],
                  [
                    { text: "", style: "subheaderSB" },
                    { text: "Centro de Costo:", style: "subheaderCB" },
                    { text: orden.centroCosto, style: "subheaderSB" },
                  ],
                  [
                    { text: "", style: "subheaderSB" },
                    { text: "Moneda:", style: "subheaderCB" },
                    { text: orden.divisa, style: "subheaderSB" },
                  ],
                ],
              },

              layout: "noBorders",
            },
          ],
        },
        "\n",

        {
          style: "tableExample",
          table: {
            widths: ["*"],
            body: [
              [
                {
                  style: "tableExample",
                  table: {
                    headerRows: 1,
                    widths: [10, 60, "*", 60, "*"],
                    body: [
                      [
                        { text: "" },
                        { text: "Razón Social:", style: "subheaderCB" },
                        { text: orden.empresa, style: "subheaderSB" },
                        { text: "Rut:", style: "subheaderCB" },
                        { text: orden.rut, style: "subheaderSB" },
                      ],
                      [
                        { text: "" },
                        { text: "Dirección:", style: "subheaderCB" },
                        { text: orden.direccion, style: "subheaderSB" },
                        { text: "Teléfono:", style: "subheaderCB" },
                        { text: orden.telefono, style: "subheaderSB" },
                      ],
                      [
                        { text: "" },
                        { text: "Comuna:", style: "subheaderCB" },
                        { text: orden.comuna, style: "subheaderSB" },
                        { text: "Contacto:", style: "subheaderCB" },
                        { text: orden.contacto, style: "subheaderSB" },
                      ],
                      [
                        { text: "" },
                        { text: "Forma de Pago:", style: "subheaderCB" },
                        { text: orden.formaPago, style: "subheaderSB" },
                        { text: "Mail:", style: "subheaderCB" },
                        { text: orden.correo, style: "subheaderSB" },
                      ],
                    ],
                  },
                  layout: "noBorders",
                },
              ],
            ],
          },
        },
        "\n",
        {
          style: "tableExample",
          table: {
            widths: [50, 50, 150, 50, "*", "*"],
            body: [
              [
                { text: "Cantidad", style: "subheaderCB", alignment: "center" },
                {
                  text: "Uni. Medida",
                  alignment: "center",
                  style: "subheaderCB",
                },
                {
                  text: "Descripción",
                  style: "subheaderCB",
                  alignment: "center",
                },
                {
                  text: "Centro Costo",
                  style: "subheaderCB",
                  alignment: "center",
                },
                {
                  text: "P. Unitario",
                  style: "subheaderCB",
                  alignment: "center",
                },
                {
                  text: "Valor Total",
                  style: "subheaderCB",
                  alignment: "center",
                },
              ],
              [
                {
                  text: orden.cantidad_1,
                  style: "subheaderSB",
                  alignment: "center",
                },
                {
                  text: orden.unidadMedida_1,
                  style: "subheaderSB",
                  alignment: "center",
                },
                {
                  text: orden.descripcion_1,
                  style: "subheaderSB",
                  alignment: "center",
                },
                {
                  text: orden.centroCosto_1,
                  style: "subheaderSB",
                  alignment: "center",
                },
                {
                  text: parseInt(orden.precioUnitario_1.toString()).toCurrency({
                    symbol: "$",
                  }),
                  style: "subheaderSB_Derecha",
                },
                {
                  text: orden.precioTotal_1.toCurrency({ symbol: "$" }),
                  style: "subheaderSB_Derecha",
                },
              ],
              orden.precioTotal_2
                ? [
                    {
                      text: orden.cantidad_2,
                      style: "subheaderSB",
                      alignment: "center",
                    },
                    {
                      text: orden.unidadMedida_2,
                      style: "subheaderSB",
                      alignment: "center",
                    },
                    {
                      text: orden.descripcion_2,
                      style: "subheaderSB",
                      alignment: "center",
                    },
                    {
                      text: orden.centroCosto_2,
                      style: "subheaderSB",
                      alignment: "center",
                    },
                    {
                      text: parseInt(
                        orden.precioUnitario_2.toString()
                      ).toCurrency({ symbol: "$" }),
                      style: "subheaderSB_Derecha",
                    },
                    {
                      text: orden.precioTotal_2.toCurrency({ symbol: "$" }),
                      style: "subheaderSB_Derecha",
                    },
                  ]
                : [
                    {
                      text: "",
                      style: "subheaderSB",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB_Derecha",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB_Derecha",
                      border: [false, false, false, false],
                    },
                  ],

              orden.precioTotal_3
                ? [
                    {
                      text: orden.cantidad_3,
                      style: "subheaderSB",
                      alignment: "center",
                    },
                    {
                      text: orden.unidadMedida_3,
                      style: "subheaderSB",
                      alignment: "center",
                    },
                    {
                      text: orden.descripcion_3,
                      style: "subheaderSB",
                      alignment: "center",
                    },
                    {
                      text: orden.centroCosto_3,
                      style: "subheaderSB",
                      alignment: "center",
                    },
                    {
                      text: parseInt(
                        orden.precioUnitario_3.toString()
                      ).toCurrency({ symbol: "$" }),
                      style: "subheaderSB_Derecha",
                    },
                    {
                      text: orden.precioTotal_3.toCurrency({ symbol: "$" }),
                      style: "subheaderSB_Derecha",
                    },
                  ]
                : [
                    {
                      text: "",
                      style: "subheaderSB",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB_Derecha",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB_Derecha",
                      border: [false, false, false, false],
                    },
                  ],

              orden.precioTotal_4
                ? [
                    {
                      text: orden.cantidad_4,
                      style: "subheaderSB",
                      alignment: "center",
                    },
                    {
                      text: orden.unidadMedida_4,
                      style: "subheaderSB",
                      alignment: "center",
                    },
                    {
                      text: orden.descripcion_4,
                      style: "subheaderSB",
                      alignment: "center",
                    },
                    {
                      text: orden.centroCosto_4,
                      style: "subheaderSB",
                      alignment: "center",
                    },
                    {
                      text: parseInt(
                        orden.precioUnitario_4.toString()
                      ).toCurrency({ symbol: "$" }),
                      style: "subheaderSB_Derecha",
                    },
                    {
                      text: orden.precioTotal_4.toCurrency({ symbol: "$" }),
                      style: "subheaderSB_Derecha",
                    },
                  ]
                : [
                    {
                      text: "",
                      style: "subheaderSB",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB_Derecha",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB_Derecha",
                      border: [false, false, false, false],
                    },
                  ],

              orden.precioTotal_5
                ? [
                    {
                      text: orden.cantidad_5,
                      style: "subheaderSB",
                      alignment: "center",
                    },
                    {
                      text: orden.unidadMedida_5,
                      style: "subheaderSB",
                      alignment: "center",
                    },
                    {
                      text: orden.descripcion_5,
                      style: "subheaderSB",
                      alignment: "center",
                    },
                    {
                      text: orden.centroCosto_5,
                      style: "subheaderSB",
                      alignment: "center",
                    },
                    {
                      text: parseInt(
                        orden.precioUnitario_5.toString()
                      ).toCurrency({ symbol: "$" }),
                      style: "subheaderSB_Derecha",
                    },
                    {
                      text: orden.precioTotal_5.toCurrency({ symbol: "$" }),
                      style: "subheaderSB_Derecha",
                    },
                  ]
                : [
                    {
                      text: "",
                      style: "subheaderSB",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB_Derecha",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB_Derecha",
                      border: [false, false, false, false],
                    },
                  ],
            ],
          },
        },
        "\n",

        {
          style: "tableExample",
          table: {
            widths: [50, 50, 150, 50, "*", "*"],
            body: [
              [
                {
                  text: "Observación:",
                  style: "subheaderCB",
                  border: [true, true, true, false],
                  colSpan: 3,
                },
                { text: "", style: "subheaderCB" },
                { text: "", style: "subheaderCB" },
                {
                  text: " ",
                  style: "subheaderCB",
                  border: [false, false, false, false],
                },
                { text: "Sub Total", style: "subheaderCB" },
                {
                  text: orden.subTotal.toCurrency({ symbol: "$" }),
                  style: "subheaderSB_Derecha",
                },
              ],
              [
                {
                  text: orden.observaciones,
                  style: "subheaderSB",
                  border: [true, false, true, true],
                  rowSpan: 5,
                  colSpan: 3,
                },
                "",
                "",
                {
                  text: " ",
                  style: "subheaderCB",
                  border: [false, false, false, false],
                },
                { text: "Descuento 1", style: "subheaderCB" },
                {
                  text: orden.descuento.toCurrency({ symbol: "$" }),
                  style: "subheaderSB_Derecha",
                },
              ],
              [
                "",
                "",
                "",
                {
                  text: " ",
                  style: "subheaderCB",
                  border: [false, false, false, false],
                },
                { text: "Afecto IVA", style: "subheaderCB" },
                {
                  text: orden.afectoIVA.toCurrency({ symbol: "$" }),
                  style: "subheaderSB_Derecha",
                },
              ],
              [
                "",
                "",
                "",
                {
                  text: " ",
                  style: "subheaderCB",
                  border: [false, false, false, false],
                },
                { text: "Excento IVA", style: "subheaderCB" },
                {
                  text: orden.exentoIVA.toCurrency({ symbol: "$" }),
                  style: "subheaderSB_Derecha",
                },
              ],
              [
                "",
                "",
                "",
                {
                  text: " ",
                  style: "subheaderCB",
                  border: [false, false, false, false],
                },
                { text: "Sub Total Neto", style: "subheaderCB" },
                {
                  text: orden.subTotalNeto.toCurrency({ symbol: "$" }),
                  style: "subheaderSB_Derecha",
                },
              ],
              [
                "",
                "",
                "",
                {
                  text: " ",
                  style: "subheaderCB",
                  border: [false, false, false, false],
                },
                { text: "IVA", style: "subheaderCB" },
                {
                  text: orden.IVA.toCurrency({ symbol: "$" }),
                  style: "subheaderSB_Derecha",
                },
              ],
              [
                {
                  text: " ",
                  style: "subheaderCB",
                  border: [false, false, false, false],
                },
                {
                  text: " ",
                  style: "subheaderCB",
                  border: [false, false, false, false],
                },
                {
                  text: " ",
                  style: "subheaderCB",
                  border: [false, false, false, false],
                },
                {
                  text: " ",
                  style: "subheaderCB",
                  border: [false, false, false, false],
                },

                { text: "Total", style: "subheaderCB" },
                {
                  text: orden.total.toCurrency({ symbol: "$" }),
                  style: "subheaderSB_Derecha",
                },
              ],
            ],
          },
        },

        "\n",
        {
          style: "tableExample",
          table: {
            headerRows: 1,
            widths: ["*"],
            body: [
              [
                {
                  text: "\nDatos de Transferencia",
                  style: "subheaderCB",
                  border: [false, true, false, false],
                },
              ],
            ],
          },
        },
        "\n",
        {
          style: "tableExample",
          table: {
            widths: ["*"],
            body: [
              [
                {
                  style: "tableExample",
                  table: {
                    headerRows: 1,
                    widths: [10, 60, "*", 60, "*"],
                    body: [
                      [
                        { text: "" },
                        { text: "Destinatario:", style: "subheaderCB" },
                        { text: orden.destinatario, style: "subheaderSB" },
                        { text: "Rut:", style: "subheaderCB" },
                        { text: orden.rutDestinatario, style: "subheaderSB" },
                      ],
                      [
                        { text: "" },
                        { text: "Banco:", style: "subheaderCB" },
                        { text: orden.banco, style: "subheaderSB" },
                        { text: "Tipo de Cuenta:", style: "subheaderCB" },
                        { text: orden.tipoCueta, style: "subheaderSB" },
                      ],
                      [
                        { text: "" },
                        { text: "N° Cuenta:", style: "subheaderCB" },
                        { text: orden.nroCuenta, style: "subheaderSB" },
                        { text: "Email:", style: "subheaderCB" },
                        {
                          text: orden.correoDestinatario,
                          style: "subheaderSB",
                        },
                      ],
                    ],
                  },
                  layout: "noBorders",
                },
              ],
            ],
          },
        },
        "\n",
        {
          text: "NOTA:",
          style: "subheaderCB",
          //border: [false, false, false, false],
          //colSpan: 3,
        },
        {
          text: "La orden NO será validada si no se encuentra en estado APROBADA.",
          style: "subheaderSB",
          //border: [false, false, false, false],
          //colSpan: 3,
        },
        "\n",

        {
          text: "Para efectos de envío de Facturas y XML, realizar a la cuenta naguilar@iisf.cl. Las facturas emitidas deben tener en referencias el número de orden de compra.",
          style: ["quote", "small"],
        },
      ],
      images: {
        logo: base64,
        image: base64_1,
      },
      styles: {
        titulo: {
          fontSize: 12,
          bold: true,
          alignment: "center",
        },
        header: {
          fontSize: 10,
          bold: true,
          alignment: "center",
        },
        subheader: {
          fontSize: 15,
          bold: true,
        },
        subheaderSB: {
          fontSize: 8,
          bold: false,
          alignment: "left",
        },
        subheaderSB_Centro: {
          fontSize: 8,
          bold: false,
          alignment: "center",
        },
        subheaderSB_Derecha: {
          fontSize: 8,
          bold: false,
          alignment: "right",
        },
        subheaderCB: {
          fontSize: 8,
          bold: true,
          //aligment: "center",
        },
        quote: {
          italics: true,
        },
        small: {
          fontSize: 8,
        },
      },
    };

    pdfMake.createPdf(dd).download(`Orden de Pago ${orden.ordenCompra}.pdf`);
  }

  async crearPdfOrdenCompra(orden: Orden): Promise<void> {
    const link = { url: require("@/assets/logo_salhus_white2.jpg") };
    const linkAprobada = {
      url: require("@/assets/pdfestados_pages-to-jpg-aprobada.jpg"),
    };
    const linkPendiente = {
      url: require("@/assets/pdfestados_pages-to-jpg-pendiente.jpg"),
    };
    const linkRechazada = {
      url: require("@/assets/pdfestados_pages-to-jpg-rechazada.jpg"),
    };

    const result = await fetch(link.url);
    const resultAprobada = await fetch(linkAprobada.url);
    const resultPendiente = await fetch(linkPendiente.url);
    const resultRechazada = await fetch(linkRechazada.url);

    const result2 = await result.blob();
    const result2Aprobada = await resultAprobada.blob();
    const result2Pendiente = await resultPendiente.blob();
    const result2Rechazada = await resultRechazada.blob();
    //const fechaFormateada = new Date();
    const base64: string = await new Promise((callback) => {
      let reader = new FileReader();
      reader.onload = function () {
        callback(this.result?.toString() ?? "");
      };
      reader.readAsDataURL(result2);
    });

    const base64_1: string = await new Promise((callback) => {
      let reader = new FileReader();
      reader.onload = function () {
        callback(this.result?.toString() ?? "");
      };
      reader.readAsDataURL(result2Aprobada);
    });

    const base64_2: string = await new Promise((callback) => {
      let reader = new FileReader();
      reader.onload = function () {
        callback(this.result?.toString() ?? "");
      };
      reader.readAsDataURL(result2Pendiente);
    });

    const base64_3: string = await new Promise((callback) => {
      let reader = new FileReader();
      reader.onload = function () {
        callback(this.result?.toString() ?? "");
      };
      reader.readAsDataURL(result2Rechazada);
    });

    var dd: TDocumentDefinitions = {
      content: [
        {
          columns: [
            {
              image: "logo",
              alignment: "right",
              height: 30,
              width: 100,
            },
            {
              text: "",
            },
            {
              text: "",
            },
          ],
        },
        {
          text: "INVERSIONES SANTA FIDELMIRA LIMITADA",
          style: "header",
        },
        {
          text: "79.991.280-5\nARRIENDO Y EXPLOTACION DE BIENES INMUEBLES\nLAUTARO 429 PISO 2 - LINARES\n732 210881\n\n",
          style: "subheaderSB_Centro",
        },

        {
          style: "tableExample",
          table: {
            headerRows: 1,
            widths: ["*"],
            body: [
              [
                {
                  text: "Orden de Compra N° " + orden.ordenCompra,
                  style: "titulo",
                  border: [false, false, false, true],
                },
              ],
            ],
          },
        },

        "\n",
        {
          columns: [
            // si estado es aprobado muestra imagen1, si estado es pendiente imagen2, si estado es rechazado imagen3
            {
              image:
                orden.estado === "aprobado"
                  ? base64_1
                  : orden.estado === "pendiente"
                  ? base64_2
                  : base64_3,
              alignment: "center",
              height: 60,
              width: 200,
            },

            // {
            //   image: "imagen1",
            //   alignment: "right",
            //   height: 60,
            //   width: 200,
            // },
            {
              style: "tableExample",

              table: {
                widths: ["*", "*", "*"],

                body: [
                  [
                    { text: "", style: "subheaderSB" },
                    { text: "Fecha Emisón:", style: "subheaderCB" },
                    { text: orden.fechaEmision, style: "subheaderSB" },
                  ],

                  [
                    { text: "", style: "subheaderSB" },
                    { text: "Estado:", style: "subheaderCB" },
                    {
                      text: orden.estado.toUpperCase(),
                      style: "subheaderSB",
                    },
                  ],
                  [
                    { text: "", style: "subheaderSB" },
                    { text: "Aprobado por:", style: "subheaderCB" },
                    {
                      text: orden.autorizado.toUpperCase(),
                      style: "subheaderSB",
                    },
                  ],
                  [
                    { text: "", style: "subheaderSB" },
                    { text: "Centro de Costo:", style: "subheaderCB" },
                    { text: orden.centroCosto, style: "subheaderSB" },
                  ],
                  [
                    { text: "", style: "subheaderSB" },
                    { text: "Moneda:", style: "subheaderCB" },
                    { text: orden.divisa, style: "subheaderSB" },
                  ],
                  [
                    { text: "", style: "subheaderSB" },
                    { text: "Tipo de Gasto:", style: "subheaderCB" },
                    { text: orden.tipoGasto, style: "subheaderSB" },
                  ],
                  [
                    { text: "", style: "subheaderSB" },
                    { text: "Cuenta Softland:", style: "subheaderCB" },
                    { text: orden.descripcionCuenta, style: "subheaderSB" },
                  ],
                  [
                    { text: "", style: "subheaderSB" },
                    { text: "Codigo Softland:", style: "subheaderCB" },
                    { text: orden.codigoCuenta, style: "subheaderSB" },
                  ],
                ],
              },

              layout: "noBorders",
            },
          ],
        },
        // {
        //   style: "tableExample",

        //   table: {
        //     widths: ["*", "*", "*", "*", "*"],

        //     body: [
        //       [
        //         { text: "" },
        //         { text: "", style: "subheaderCB" },
        //         { text: "", style: "subheaderSB" },
        //         { text: "Fecha Emisón:", style: "subheaderCB" },
        //         { text: orden.fechaEmision, style: "subheaderSB" },
        //       ],
        //       [
        //         { text: "" },
        //         { text: "", style: "subheaderCB" },
        //         { text: "", style: "subheaderSB" },
        //         { text: "Estado:", style: "subheaderCB" },
        //         {
        //           text: orden.estado.capitalizeFirstLetter(),
        //           style: "subheaderSB",
        //         },
        //       ],
        //       [
        //         { text: "" },
        //         { text: "", style: "subheaderCB" },
        //         { text: "", style: "subheaderSB" },
        //         { text: "Aprobado por:", style: "subheaderCB" },
        //         { text: orden.autorizado, style: "subheaderSB" },
        //       ],
        //       [
        //         { text: "" },
        //         { text: "", style: "subheaderCB" },
        //         { text: "", style: "subheaderSB" },
        //         { text: "Centro de Costo:", style: "subheaderCB" },
        //         { text: orden.centroCosto, style: "subheaderSB" },
        //       ],
        //       [
        //         { text: "" },
        //         { text: "", style: "subheaderCB" },
        //         { text: "", style: "subheaderSB" },
        //         { text: "Moneda:", style: "subheaderCB" },
        //         { text: orden.divisa, style: "subheaderSB" },
        //       ],
        //     ],
        //   },

        //   layout: "noBorders",
        // },
        "\n",

        {
          style: "tableExample",
          table: {
            widths: ["*"],
            body: [
              [
                {
                  style: "tableExample",
                  table: {
                    headerRows: 1,
                    widths: [10, 60, "*", 60, "*"],
                    body: [
                      [
                        { text: "" },
                        { text: "Razón Social:", style: "subheaderCB" },
                        { text: orden.empresa, style: "subheaderSB" },
                        { text: "Rut:", style: "subheaderCB" },
                        { text: orden.rut, style: "subheaderSB" },
                      ],
                      [
                        { text: "" },
                        { text: "Dirección:", style: "subheaderCB" },
                        { text: orden.direccion, style: "subheaderSB" },
                        { text: "Teléfono:", style: "subheaderCB" },
                        { text: orden.telefono, style: "subheaderSB" },
                      ],
                      [
                        { text: "" },
                        { text: "Comuna:", style: "subheaderCB" },
                        { text: orden.comuna, style: "subheaderSB" },
                        { text: "Contacto:", style: "subheaderCB" },
                        { text: orden.contacto, style: "subheaderSB" },
                      ],
                      [
                        { text: "" },
                        { text: "Forma de Pago:", style: "subheaderCB" },
                        { text: orden.formaPago, style: "subheaderSB" },
                        { text: "Mail:", style: "subheaderCB" },
                        { text: orden.correo, style: "subheaderSB" },
                      ],
                    ],
                  },
                  layout: "noBorders",
                },
              ],
            ],
          },
        },
        "\n",
        {
          style: "tableExample",
          table: {
            widths: [50, 50, 150, 50, "*", "*"],
            body: [
              [
                { text: "Cantidad", style: "subheaderCB", alignment: "center" },
                {
                  text: "Uni. Medida",
                  alignment: "center",
                  style: "subheaderCB",
                },
                {
                  text: "Descripción",
                  style: "subheaderCB",
                  alignment: "center",
                },
                {
                  text: "Centro Costo",
                  style: "subheaderCB",
                  alignment: "center",
                },
                {
                  text: "P. Unitario",
                  style: "subheaderCB",
                  alignment: "center",
                },
                {
                  text: "Valor Total",
                  style: "subheaderCB",
                  alignment: "center",
                },
              ],

              [
                {
                  text: orden.cantidad_1,
                  style: "subheaderSB",
                  alignment: "center",
                },
                {
                  text: orden.unidadMedida_1,
                  style: "subheaderSB",
                  alignment: "center",
                },
                {
                  text: orden.descripcion_1,
                  style: "subheaderSB",
                  alignment: "center",
                },
                {
                  text: orden.centroCosto_1,
                  style: "subheaderSB",
                  alignment: "center",
                },
                {
                  text: parseInt(orden.precioUnitario_1.toString()).toCurrency({
                    symbol: "$",
                  }),
                  style: "subheaderSB_Derecha",
                },
                {
                  text: orden.precioTotal_1.toCurrency({ symbol: "$" }),
                  style: "subheaderSB_Derecha",
                },
              ],

              orden.precioTotal_2
                ? [
                    {
                      text: orden.cantidad_2,
                      style: "subheaderSB",
                      alignment: "center",
                    },
                    {
                      text: orden.unidadMedida_2,
                      style: "subheaderSB",
                      alignment: "center",
                    },
                    {
                      text: orden.descripcion_2,
                      style: "subheaderSB",
                      alignment: "center",
                    },
                    {
                      text: orden.centroCosto_2,
                      style: "subheaderSB",
                      alignment: "center",
                    },
                    {
                      text: parseInt(
                        orden.precioUnitario_2.toString()
                      ).toCurrency({ symbol: "$" }),
                      style: "subheaderSB_Derecha",
                    },
                    {
                      text: orden.precioTotal_2.toCurrency({ symbol: "$" }),
                      style: "subheaderSB_Derecha",
                    },
                  ]
                : [
                    {
                      text: "",
                      style: "subheaderSB",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB_Derecha",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB_Derecha",
                      border: [false, false, false, false],
                    },
                  ],

              orden.precioTotal_3
                ? [
                    {
                      text: orden.cantidad_3,
                      style: "subheaderSB",
                      alignment: "center",
                    },
                    {
                      text: orden.unidadMedida_3,
                      style: "subheaderSB",
                      alignment: "center",
                    },
                    {
                      text: orden.descripcion_3,
                      style: "subheaderSB",
                      alignment: "center",
                    },
                    {
                      text: orden.centroCosto_3,
                      style: "subheaderSB",
                      alignment: "center",
                    },
                    {
                      text: parseInt(
                        orden.precioUnitario_3.toString()
                      ).toCurrency({ symbol: "$" }),
                      style: "subheaderSB_Derecha",
                    },
                    {
                      text: orden.precioTotal_3.toCurrency({ symbol: "$" }),
                      style: "subheaderSB_Derecha",
                    },
                  ]
                : [
                    {
                      text: "",
                      style: "subheaderSB",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB_Derecha",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB_Derecha",
                      border: [false, false, false, false],
                    },
                  ],

              orden.precioTotal_4
                ? [
                    {
                      text: orden.cantidad_4,
                      style: "subheaderSB",
                      alignment: "center",
                    },
                    {
                      text: orden.unidadMedida_4,
                      style: "subheaderSB",
                      alignment: "center",
                    },
                    {
                      text: orden.descripcion_4,
                      style: "subheaderSB",
                      alignment: "center",
                    },
                    {
                      text: orden.centroCosto_4,
                      style: "subheaderSB",
                      alignment: "center",
                    },
                    {
                      text: parseInt(
                        orden.precioUnitario_4.toString()
                      ).toCurrency({ symbol: "$" }),
                      style: "subheaderSB_Derecha",
                    },
                    {
                      text: orden.precioTotal_4.toCurrency({ symbol: "$" }),
                      style: "subheaderSB_Derecha",
                    },
                  ]
                : [
                    {
                      text: "",
                      style: "subheaderSB",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB_Derecha",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB_Derecha",
                      border: [false, false, false, false],
                    },
                  ],

              orden.precioTotal_5
                ? [
                    {
                      text: orden.cantidad_5,
                      style: "subheaderSB",
                      alignment: "center",
                    },
                    {
                      text: orden.unidadMedida_5,
                      style: "subheaderSB",
                      alignment: "center",
                    },
                    {
                      text: orden.descripcion_5,
                      style: "subheaderSB",
                      alignment: "center",
                    },
                    {
                      text: orden.centroCosto_5,
                      style: "subheaderSB",
                      alignment: "center",
                    },
                    {
                      text: parseInt(
                        orden.precioUnitario_5.toString()
                      ).toCurrency({ symbol: "$" }),
                      style: "subheaderSB_Derecha",
                    },
                    {
                      text: orden.precioTotal_5.toCurrency({ symbol: "$" }),
                      style: "subheaderSB_Derecha",
                    },
                  ]
                : [
                    {
                      text: "",
                      style: "subheaderSB",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB_Derecha",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      style: "subheaderSB_Derecha",
                      border: [false, false, false, false],
                    },
                  ],
            ],
          },
        },
        "\n",

        {
          style: "tableExample",
          table: {
            widths: [50, 50, 150, 50, "*", "*"],
            body: [
              [
                {
                  text: "Observación:",
                  style: "subheaderCB",
                  border: [true, true, true, false],
                  colSpan: 3,
                },
                { text: "", style: "subheaderCB" },
                { text: "", style: "subheaderCB" },
                {
                  text: " ",
                  style: "subheaderCB",
                  border: [false, false, false, false],
                },
                { text: "Sub Total", style: "subheaderCB" },
                {
                  text: orden.subTotal.toCurrency({ symbol: "$" }),
                  style: "subheaderSB_Derecha",
                },
              ],
              [
                {
                  text: orden.observaciones,
                  style: "subheaderSB",
                  border: [true, false, true, true],
                  rowSpan: 5,
                  colSpan: 3,
                },
                "",
                "",
                {
                  text: " ",
                  style: "subheaderCB",
                  border: [false, false, false, false],
                },
                { text: "Descuento 1", style: "subheaderCB" },
                {
                  text: orden.descuento.toCurrency({ symbol: "$" }),
                  style: "subheaderSB_Derecha",
                },
              ],
              [
                "",
                "",
                "",
                {
                  text: " ",
                  style: "subheaderCB",
                  border: [false, false, false, false],
                },
                { text: "Afecto IVA", style: "subheaderCB" },
                {
                  text: orden.afectoIVA.toCurrency({ symbol: "$" }),
                  style: "subheaderSB_Derecha",
                },
              ],
              [
                "",
                "",
                "",
                {
                  text: " ",
                  style: "subheaderCB",
                  border: [false, false, false, false],
                },
                { text: "Excento IVA", style: "subheaderCB" },
                {
                  text: orden.exentoIVA.toCurrency({ symbol: "$" }),
                  style: "subheaderSB_Derecha",
                },
              ],
              [
                "",
                "",
                "",
                {
                  text: " ",
                  style: "subheaderCB",
                  border: [false, false, false, false],
                },
                { text: "Sub Total Neto", style: "subheaderCB" },
                {
                  text: orden.subTotalNeto.toCurrency({ symbol: "$" }),
                  style: "subheaderSB_Derecha",
                },
              ],
              [
                "",
                "",
                "",
                {
                  text: " ",
                  style: "subheaderCB",
                  border: [false, false, false, false],
                },
                { text: "IVA", style: "subheaderCB" },
                {
                  text: orden.IVA.toCurrency({ symbol: "$" }),
                  style: "subheaderSB_Derecha",
                },
              ],
              [
                {
                  text: " ",
                  style: "subheaderCB",
                  border: [false, false, false, false],
                },
                {
                  text: " ",
                  style: "subheaderCB",
                  border: [false, false, false, false],
                },
                {
                  text: " ",
                  style: "subheaderCB",
                  border: [false, false, false, false],
                },
                {
                  text: " ",
                  style: "subheaderCB",
                  border: [false, false, false, false],
                },

                { text: "Total", style: "subheaderCB" },
                {
                  text: orden.total.toCurrency({ symbol: "$" }),
                  style: "subheaderSB_Derecha",
                },
              ],
            ],
          },
        },

        "\n",
        {
          style: "tableExample",
          table: {
            headerRows: 1,
            widths: ["*"],
            body: [
              [
                {
                  text: "\nDatos de Transferencia",
                  style: "subheaderCB",
                  border: [false, true, false, false],
                },
              ],
            ],
          },
        },
        "\n",
        {
          style: "tableExample",
          table: {
            widths: ["*"],
            body: [
              [
                {
                  style: "tableExample",
                  table: {
                    headerRows: 1,
                    widths: [10, 60, "*", 60, "*"],
                    body: [
                      [
                        { text: "" },
                        { text: "Destinatario:", style: "subheaderCB" },
                        { text: orden.destinatario, style: "subheaderSB" },
                        { text: "Rut:", style: "subheaderCB" },
                        { text: orden.rutDestinatario, style: "subheaderSB" },
                      ],
                      [
                        { text: "" },
                        { text: "Banco:", style: "subheaderCB" },
                        { text: orden.banco, style: "subheaderSB" },
                        { text: "Tipo de Cuenta:", style: "subheaderCB" },
                        { text: orden.tipoCueta, style: "subheaderSB" },
                      ],
                      [
                        { text: "" },
                        { text: "N° Cuenta:", style: "subheaderCB" },
                        { text: orden.nroCuenta, style: "subheaderSB" },
                        { text: "Email:", style: "subheaderCB" },
                        {
                          text: orden.correoDestinatario,
                          style: "subheaderSB",
                        },
                      ],
                    ],
                  },
                  layout: "noBorders",
                },
              ],
            ],
          },
        },
        "\n",
        {
          text: "NOTA:",
          style: "subheaderCB",
          //border: [false, false, false, false],
          //colSpan: 3,
        },
        {
          text: "La orden NO será validada si no se encuentra en estado APROBADA.",
          style: "subheaderSB",
          //border: [false, false, false, false],
          //colSpan: 3,
        },
        "\n",

        {
          text: "Para efectos de envío de Facturas y XML, realizar a la cuenta naguilar@iisf.cl. Las facturas emitidas deben tener en referencias el número de orden de compra.",
          style: ["quote", "small"],
        },
      ],
      images: {
        logo: base64,
        imagen1: base64_1,
        imagen2: base64_2,
        imagen3: base64_3,
      },
      styles: {
        titulo: {
          fontSize: 12,
          bold: true,
          alignment: "center",
        },
        header: {
          fontSize: 10,
          bold: true,
          alignment: "center",
        },
        subheader: {
          fontSize: 15,
          bold: true,
        },
        subheaderSB: {
          fontSize: 8,
          bold: false,
          alignment: "left",
        },
        subheaderSB_Centro: {
          fontSize: 8,
          bold: false,
          alignment: "center",
        },
        subheaderSB_Derecha: {
          fontSize: 8,
          bold: false,
          alignment: "right",
        },
        subheaderCB: {
          fontSize: 8,
          bold: true,
          //aligment: "center",
        },
        quote: {
          italics: true,
        },
        small: {
          fontSize: 8,
        },
      },
    };

    pdfMake.createPdf(dd).download(`Orden de Compra ${orden.ordenCompra}.pdf`);
  }
}
