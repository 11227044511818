import { RouteConfig } from "vue-router";
import SaldoCuentas from "@/views/SaldoCuentas.vue";

const saldoCuentas: RouteConfig = {
    path: "/saldoCuentas",
    name: "saldoCuentas",
    component: SaldoCuentas,
};

export default saldoCuentas;
