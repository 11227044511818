





























































































































































































































































































































































































import { Component, Emit, Prop, Vue } from "vue-property-decorator";

//import { idtify } from "@/helpers/string";
import { MapeoGasto } from "@/typings/store/plugins/easyFirestore/mapeoGastos";
import { CentroCosto } from "@/typings/store/plugins/easyFirestore/centrosCostos";
import { Banco } from "@/typings/store/plugins/easyFirestore/bancos";
import { Proveedor } from "@/typings/store/plugins/easyFirestore/proveedores";
import { TipoCargo } from "@/typings/store/plugins/easyFirestore/tipoCargos";
import { fechaLocal } from "@/helpers/date";
import { CodCuenta } from "@/typings/store/plugins/easyFirestore/codCuentas";
import { TipoDocumento } from "@/typings/store/plugins/easyFirestore/tipoDocumentos";
// validaciones
import {
  // email,
  required,
} from "vuelidate/lib/validators";
import { DataFormularioMapeoGastos } from "@/typings/components/mapeoGastos/formulario";
import SVGIcon from "@/components/SVGIcon.vue";
//import { Archivo, Archivos } from "@/typings/store/modules/archivos";
import VistaPreviaPdf from "@/components/VistaPreviaPdf.vue";

@Component({
  components: {
    SVGIcon,
    VistaPreviaPdf,
  },
  validations: {
    centroCosto: { required },
    rut: { required },
    tipoGasto: { required },
    cuentaGasto: { required },
    cuentaEstadoResultado: { required },
    //observacion: { required },
    // nCliente: { required },
    // recurrente: { required },
  },
})
export default class FormularioPrenomina extends Vue {
  @Prop() readonly mapeoGasto!: MapeoGasto | null;
  @Prop() readonly cargando!: boolean;
  @Prop() readonly centrosCostos!: CentroCosto[];
  @Prop() readonly bancos!: Banco[];
  @Prop() readonly proveedores!: Proveedor[];
  @Prop() readonly tipoCargos!: TipoCargo[];
  @Prop() readonly codCuentas!: CodCuenta[];
  @Prop() readonly tipoDocumentos!: TipoDocumento[];
  //   @Prop() readonly rutas!: Rutas;
  //   @Prop() readonly ciudades!: Ciudad[];
  //   @Prop() readonly cargando!: boolean;
  //   @Prop() readonly regiones!: Region[];

  created(): void {
    // Object.keys(this.rutas).forEach((key) => {
    //   this.$set(this.permisos, key, false);
    // });
    // console.log(this.permisos);

    const mapeoGasto = this.mapeoGasto;
    if (!mapeoGasto) {
      return;
    }
    this.id = mapeoGasto.id ?? 0;
    this.centroCosto = mapeoGasto.centroCosto;
    this.rut = mapeoGasto.rut ?? "";
    this.nombreProveedor = mapeoGasto.nombreProveedor ?? "";
    this.tipoGasto = mapeoGasto.tipoGasto ?? "";
    this.cuentaGasto = mapeoGasto.cuentaGasto ?? "";
    this.cuentaEstadoResultado = mapeoGasto.cuentaEstadoResultado ?? "";
    this.nCliente = mapeoGasto.nCliente ?? "";
    this.recurrente = mapeoGasto.recurrente ?? "";
    this.observacion = mapeoGasto.observacion ?? "";
    this.descripcionCuenta = mapeoGasto.descripcionCuenta ?? "";
    this.codigoCuenta = mapeoGasto.codigoCuenta ?? "";
    this.tipoDocumento = mapeoGasto.tipoDocumento ?? "";
    // this.glosa = mapeoGasto.glosa ?? "";
    // this.fechaAsignacion = mapeoGasto.fechaAsignacion;
  }

  datePeriodo = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
    .toISOString()
    .substr(0, 10);

  id: number | null = null;
  item_recurrente = ["SI", "NO"];
  recurrente = "";
  centroCosto = "";
  rut = "";
  nombreProveedor = "";
  tipoGasto = "";
  cuentaGasto = "";
  cuentaEstadoResultado = "";
  nCliente = "";
  descripcionCuenta = "";
  codigoCuenta = "";
  observacion = "";
  tipoDocumento = "";
  glosa = "";
  // recurrente = "";
  // fechaAsignacion = "";
  // itemsRecurrente = ["SI", "NO"];

  // get fechaPeriodoF(): string {
  //   let array_fecha = this.datePeriodo.split("-");
  //   let dia = "01";
  //   let mes = array_fecha[1];
  //   let ano = array_fecha[0];
  //   console.log(array_fecha);
  //   this.periodo = dia + "-" + mes + "-" + ano;
  //   return dia + "-" + mes + "-" + ano;
  // }

  get titulo(): string {
    return this.mapeoGasto ? "editar mapeoGasto" : "nueva mapeoGasto";
  }

  get rutError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.rut.$dirty) return errors;
    if (!this.$v.rut.required) errors.push("Completar datos obligatorios");
    return errors;
  }

  get itemCentrosCostos(): Array<string> {
    const itemCentroCosto: Array<string> = [];
    this.centrosCostos.forEach((centroCosto) => {
      itemCentroCosto.push(centroCosto.Desc_formato);
    });
    return itemCentroCosto.sort((sesionA, sesionB) => {
      return parseInt(sesionA) - parseInt(sesionB);
    });
  }

  get itemRutProveedores(): Array<string> {
    const itemRutProveedor: Array<string> = [];
    this.proveedores.forEach((rutProveedor) => {
      itemRutProveedor.push(rutProveedor.RutAux);
    });
    return itemRutProveedor.sort((sesionA, sesionB) => {
      return (
        parseInt(sesionA.replace(/\.+/g, "")) -
        parseInt(sesionB.replace(/\.+/g, ""))
      );
    });
  }

  get itemNombreProveedores(): string | undefined {
    let itemNombreProveedor = "";
    this.proveedores.forEach((proveedor) => {
      if (proveedor.RutAux === this.rut) {
        itemNombreProveedor = proveedor.NomAux;
        this.nombreProveedor = itemNombreProveedor;
      }
    });
    return itemNombreProveedor;
  }

  get itemCodigoCuentas(): Array<string> {
    const itemCodigoCuentas: Array<string> = [];
    this.codCuentas.forEach((descripcionCuenta) => {
      itemCodigoCuentas.push(descripcionCuenta.PCCODI);
    });
    return itemCodigoCuentas.sort();
  }

  get itemDescripcionCuentas(): string | undefined {
    let itemDescripcionCuentas = "";
    this.codCuentas.forEach((descripcionCuenta) => {
      if (descripcionCuenta.PCCODI === this.codigoCuenta) {
        itemDescripcionCuentas = descripcionCuenta.PCDESC;
        this.descripcionCuenta = itemDescripcionCuentas;
      }
    });
    return itemDescripcionCuentas;
  }

  get itemTipoGastos(): Array<string> {
    const itemTipoGasto: Array<string> = [];
    this.tipoCargos.forEach((tipoCargo) => {
      itemTipoGasto.push(tipoCargo.nombre);
    });
    return itemTipoGasto.sort();
  }

  get itemControlGastos(): string | undefined {
    let itemControlGasto = "";
    this.tipoCargos.forEach((tipoCargo) => {
      if (tipoCargo.nombre === this.tipoGasto) {
        itemControlGasto = tipoCargo.cuentaControlGasto;
        this.cuentaGasto = itemControlGasto;
      }
    });
    return itemControlGasto;
  }

  get itemEstadoResultados(): string | undefined {
    let itemEstadoResultado = "";
    this.tipoCargos.forEach((tipoCargo) => {
      if (tipoCargo.nombre === this.tipoGasto) {
        itemEstadoResultado = tipoCargo.cuentaEstadoResultado;
        this.cuentaEstadoResultado = itemEstadoResultado;
      }
    });
    return itemEstadoResultado;
  }

  get itemRutTipoDocumentos(): Array<string> {
    const itemTipoDocumento: Array<string> = [];
    this.tipoDocumentos.forEach((tipoDocumento) => {
      itemTipoDocumento.push(tipoDocumento.nombre);
    });
    return itemTipoDocumento.sort();
  }

  get glosaError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.glosa.$dirty) return errors;
    if (!this.$v.glosa.required) errors.push("Campo requerido");
    return errors;
  }

  get fechaAsignacion(): string {
    let aprobada = fechaLocal().toString();
    let array_fecha = aprobada.split("-");
    let dia = array_fecha[2];
    let mes = array_fecha[1];
    let ano = array_fecha[0];
    console.log(array_fecha);
    let fecha = dia + "-" + mes + "-" + ano;

    console.log(fecha);
    return fecha;
  }

  get centroCostoError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.centroCosto.$dirty) return errors;
    if (!this.$v.centroCosto.required) errors.push("Campo requerido");
    return errors;
  }

  get tipoGastoError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.tipoGasto.$dirty) return errors;
    if (!this.$v.tipoGasto.required) errors.push("Campo requerido");
    return errors;
  }

  get recurrenteError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.recurrente.$dirty) return errors;
    if (!this.$v.recurrente.required) errors.push("Campo requerido");
    return errors;
  }

  @Emit("close")
  onClose(): null {
    return null;
  }

  @Emit("cerrar-dialogo")
  onCerrarDialogo(): void {
    return;
  }

  @Emit("click-guardar")
  onClickGuardar(data: DataFormularioMapeoGastos): DataFormularioMapeoGastos {
    return data;
  }

  guardar(): void {
    this.$v.$touch();

    if (this.$v.$invalid) {
      return;
    }
    const doc: MapeoGasto = {
      centroCosto: this.centroCosto.toUpperCase().replace(",", "|"),
      rut: this.rut.toUpperCase(),
      nombreProveedor: this.nombreProveedor.toUpperCase(),
      tipoGasto: this.tipoGasto,
      cuentaGasto: this.cuentaGasto,
      cuentaEstadoResultado: this.cuentaEstadoResultado,
      nCliente: this.nCliente,
      recurrente: this.recurrente,
      observacion: this.observacion,
      descripcionCuenta: this.descripcionCuenta,
      codigoCuenta: this.codigoCuenta,
      tipoDocumento: this.tipoDocumento,
      // glosa: this.glosa,

      // recurrente: this.recurrente,
      fechaAsignacion: this.fechaAsignacion,
    };
    if (this.id) {
      doc.id = this.id;
    }

    const data: DataFormularioMapeoGastos = {
      doc: doc,
    };

    this.onClickGuardar(data);
  }
}
