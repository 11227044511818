






































































































































































































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
// helpers
//import { idtify } from "@/helpers/string";
// tipos
//import { Rutas } from "@/typings/store/modules/rutas";
//import {
//  Permisos,
//  TipoUsuario,
//  Usuario,
//} from "@/typings/store/plugins/easyFirestore/usuarios";
import {
  PlanCuentaIngreso,
  //PlanCuentaIngresos
} from "@/typings/store/plugins/easyFirestore/planCuentasIngresos";
// validaciones
import {
  // email,
  required,
} from "vuelidate/lib/validators";
import { DataFormularioPlanCuentaIngreso } from "@/typings/components/planCuentasIngresos/formulario";
import SVGIcon from "@/components/SVGIcon.vue";
//import { Archivo, Archivos } from "@/typings/store/modules/archivos";

@Component({
  components: {
    SVGIcon,
  },
  validations: {
    // nombre: { required },
    // posicion: { required },
    TIPO_DOCUMENTO: { required },
    NUMERO_DOCUMENTO: { required },
    CUENTA_SOFTLAND: { required },
    NUMERO_CUENTA: { required },
    PRODUCTO: { required },
    VALOR_NETO: { required },
  },
})
export default class FormularioPlanCuentaIngreso extends Vue {
  @Prop() readonly planCuentaIngreso!: PlanCuentaIngreso | null;
  @Prop() readonly cargando!: boolean;
  //   @Prop() readonly rutas!: Rutas;
  //   @Prop() readonly ciudades!: Ciudad[];
  //   @Prop() readonly cargando!: boolean;
  //   @Prop() readonly regiones!: Region[];

  created(): void {
    // Object.keys(this.rutas).forEach((key) => {
    //   this.$set(this.permisos, key, false);
    // });
    // console.log(this.permisos);
    const planCuentaIngreso = this.planCuentaIngreso;
    if (!planCuentaIngreso) {
      return;
    }
    this.id = planCuentaIngreso.id ?? "";
    this.nombre = planCuentaIngreso.nombre;
    this.posicion = planCuentaIngreso.posicion ?? "";
    this.TIPO_DOCUMENTO = planCuentaIngreso.TIPO_DOCUMENTO ?? "";
    this.NUMERO_DOCUMENTO = planCuentaIngreso.NUMERO_DOCUMENTO ?? "";
    this.CUENTA_SOFTLAND = planCuentaIngreso.CUENTA_SOFTLAND ?? "";
    this.NUMERO_CUENTA = planCuentaIngreso.NUMERO_CUENTA ?? "";
    this.PRODUCTO = planCuentaIngreso.PRODUCTO ?? "";
    this.VALOR_NETO = planCuentaIngreso.VALOR_NETO ?? "";
  }

  id: string | null = null;

  nombre = "";
  posicion = "";
  TIPO_DOCUMENTO = "";
  NUMERO_DOCUMENTO = "";
  CUENTA_SOFTLAND = "";
  PRODUCTO = "";
  VALOR_NETO = "";
  NUMERO_CUENTA = "";

  itemsTipoDocumento = ["31","33","61"];

  get titulo(): string {
    return this.planCuentaIngreso ? "editar planCuentaIngreso" : "nuevo planCuentaIngreso";
  }

  //   get permisosKey(): Array<string> {
  //     // return Object.keys(this.permisos).filter((key) => {
  //     //   if(this.rutas[key].tipos.includes(this.tipo)){
  //     //     this.permisos[key] = true;
  //     //   }
  //     // });
  //     return Object.keys(this.permisos).filter((key) => {
  //       if (!this.rutas[key]) {
  //         return false;
  //       }
  //       const tipo = this.rutas[key].tipos.includes(this.tipo);
  //       return tipo;
  //     });
  //   }

  get nombreError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.nombre.$dirty) return errors;
    if (!this.$v.nombre.required) errors.push("Completar datos obligatorios");
    return errors;
  }

  get posicionError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.posicion.$dirty) return errors;
    if (!this.$v.posicion.required) errors.push("Completar datos obligatorios");
    return errors;
  }

  get TIPO_DOCUMENTOError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.TIPO_DOCUMENTO.$dirty) return errors;
    if (!this.$v.TIPO_DOCUMENTO.required) errors.push("Completar datos obligatorios");
    return errors;
  }
  get NUMERO_DOCUMENTOError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.NUMERO_DOCUMENTO.$dirty) return errors;
    if (!this.$v.NUMERO_DOCUMENTO.required) errors.push("Completar datos obligatorios");
    return errors;
  }

  get NUMERO_CUENTAError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.NUMERO_CUENTA.$dirty) return errors;
    if (!this.$v.NUMERO_CUENTA.required) errors.push("Completar datos obligatorios");
    return errors;
  }

  get CUENTA_SOFTLANDError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.CUENTA_SOFTLAND.$dirty) return errors;
    if (!this.$v.CUENTA_SOFTLAND.required) errors.push("Completar datos obligatorios");
    return errors;
  }
  get PRODUCTOError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.PRODUCTO.$dirty) return errors;
    if (!this.$v.PRODUCTO.required) errors.push("Completar datos obligatorios");
    return errors;
  }
  get VALOR_NETOError(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.VALOR_NETO.$dirty) return errors;
    if (!this.$v.VALOR_NETO.required) errors.push("Completar datos obligatorios");
    return errors;
  }

  @Emit("close")
  onClose(): null {
    return null;
  }

  @Emit("cerrar-dialogo")
  onCerrarDialogo(): void {
    return;
  }

  @Emit("click-guardar")
  onClickGuardar(data: DataFormularioPlanCuentaIngreso): DataFormularioPlanCuentaIngreso {
    return data;
  }

  //   @Watch("permisosKey", { immediate: true })
  //   onTipoChange(): void {
  //     console.log("a");
  //     for (const key of Object.keys(this.permisos)) {
  //       if (this.permisos[key]) {
  //         this.permisos[key] = false;
  //       }
  //     }
  //     this.permisosKey.forEach((key) => {
  //       this.permisos[key] = true;
  //     });
  //   }

  //   nombrePermiso(key: string): string {
  //     return this.rutas[key].text;
  //   }

  guardar(): void {
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }
    const doc: PlanCuentaIngreso = {
      nombre: this.nombre,
      posicion: this.posicion,

      TIPO_DOCUMENTO: this.TIPO_DOCUMENTO,
      NUMERO_DOCUMENTO: this.NUMERO_DOCUMENTO,
      NUMERO_CUENTA: this.NUMERO_CUENTA, 
      CUENTA_SOFTLAND: this.CUENTA_SOFTLAND,
      PRODUCTO: this.PRODUCTO,
      VALOR_NETO: this.VALOR_NETO,
    
    };
    if (this.id) {
      doc.id = this.id;
    }
    const data: DataFormularioPlanCuentaIngreso = {
      doc: doc,
    };
    this.onClickGuardar(data);
  }
}
