import {GetterTree} from "vuex";
import {State} from "@/typings/store";
import {StateContactos} from "@/typings/store/plugins/easyFirestore/contactos";

const getters: GetterTree<StateContactos, State> = {
  get(state) {
    return state.all;
  },
  getArray(state) {
    return Object.values(state.all);
  }
}

const contactos = {
  firestorePath: "dev/v1/contactos",
  firestoreRefType: "collection",
  moduleName: "contactos",
  statePropName: "all",
  namespaced: true,
  serverChange: {
    convertTimestamps: {
      created_at: "%convertTimestamp%", // default
      updated_at: "%convertTimestamp%", // default
    },
  },
  state: {},
  getters,
  mutations: {},
  actions: {},
};

export default contactos;
