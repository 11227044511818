































































// decoradores
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { Medidor } from "@/typings/store/plugins/easyFirestore/medidores";
import SVGIcon from "@/components/SVGIcon.vue";
// tipos

// tipos
@Component({
  components: {
    SVGIcon,
  },
})
export default class InfoMedidor extends Vue {
  @Prop() readonly medidor!: Medidor;
  @Prop({ type: Boolean }) readonly showTitulo!: boolean;

  get id(): string {
    return this.medidor.id || "N/R";
  }

  get estado(): string {
    return this.medidor.estado || "N/R";
  }

  get operador(): string {
    return this.medidor.operador || "N/R";
  }


  @Emit("cerrar")
  onCerrar(): void {
    return;
  }
}
