





































































































































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { DataFormularioSesion } from "@/typings/components/sesiones/formulario";
import SVGIcon from "@/components/SVGIcon.vue";
import { required, minValue } from "vuelidate/lib/validators";
import { Curso } from "@/typings/store/plugins/easyFirestore/cursos";

@Component({
  components: {
    SVGIcon,
  },
  validations: {
    tema: { required },
    resumen: { required },
    descripcion: { required },
    duracion: {
      required,
      minValue: minValue(1),
    },
  },
})
export default class EditarSesion extends Vue {
  @Prop() readonly numero!: number;
  @Prop() readonly nombreCurso!: string;
  @Prop() readonly sesion?: DataFormularioSesion;
  @Prop() readonly curso?: Curso;

  created(): void {
    if (!this.sesion) return;
    this.id = this.sesion?.id ?? "";
    this.tema = this.sesion?.tema ?? "";
    this.resumen = this.sesion?.resumen ?? "";
    this.descripcion = this.sesion?.descripcion ?? "";
    this.duracion = this.sesion?.duracion ?? 0;
    this.estado = this.sesion?.estado ?? "grabada";
    this.link = this.sesion?.link ?? "";
  }

  id = "";
  tema = "";
  resumen = "";
  descripcion = "";
  duracion = 0;
  estado: "grabada" | "en-vivo" = "grabada";
  link = "";

  get grabada(): boolean {
    return this.estado === "grabada";
  }

  set grabada(value: boolean) {
    this.estado = value ? "grabada" : "en-vivo";
  }

  get temaErrors(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.tema.$dirty) return errors;
    if (!this.$v.tema.required) errors.push("Completar datos obligatorios");
    return errors;
  }

  get resumenErrors(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.resumen.$dirty) return errors;
    if (!this.$v.resumen.required) errors.push("Completar datos obligatorios");
    return errors;
  }

  get descripcionErrors(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.descripcion.$dirty) return errors;
    if (!this.$v.descripcion.required)
      errors.push("Completar datos obligatorios");
    return errors;
  }

  get duracionErrors(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.duracion.$dirty) return errors;
    if (!this.$v.duracion.required) errors.push("Completar datos obligatorios");
    if (!this.$v.duracion.minValue) errors.push("Debe ser mayor a 0");
    return errors;
  }

  @Emit("cerrar")
  onCerrar(): void {
    return;
  }

  @Emit("guardar")
  onGuardar(sesion: DataFormularioSesion): DataFormularioSesion {
    return sesion;
  }

  guardar(): void {
    this.$v.$touch();
    if (this.$v.$invalid) return;
    const sesion: DataFormularioSesion = {
      numero: this.numero,
      tema: this.tema,
      resumen: this.resumen,
      descripcion: this.descripcion,
      duracion: this.duracion,
      precio: 0,
      estado: this.estado,
      link: this.grabada ? this.link : '',
    };
    if (this.id) sesion.id = this.id;
    if (this.curso) sesion.curso = this.curso;
    this.onGuardar(sesion);
  }
}
