







































































import { Component, Prop, Vue } from "vue-property-decorator";

import { Usuario } from "@/typings/store/plugins/easyFirestore/usuarios";
import { Inscripcion } from "@/typings/store/plugins/easyFirestore/inscripciones";

@Component({})
export default class ComponenteInscritosRegistrados extends Vue {
  @Prop() readonly usuarios!: Array<Usuario>;
  @Prop() readonly inscripciones!: Array<Inscripcion>;

  get cuentaRegistradosRelator(): number {
    let totalRegistrados = 0;
    this.usuarios.forEach((usuario) => {
      if (!usuario.tipo) {
        return;
      }
      if (usuario.tipo === "relator") {
        totalRegistrados += 1;
      }
    });
    return totalRegistrados;
  }

  get cuentaRegistradosCliente(): number {
    let totalRegistrados = 0;
    this.usuarios.forEach((usuario) => {
      if (!usuario.tipo) {
        return;
      }
      if (usuario.tipo === "cliente") {
        totalRegistrados += 1;
      }
    });
    return totalRegistrados;
  }

  get cuentaInscritos(): number {
    let totalInscritos = 0;
    this.inscripciones.forEach((inscripcion) => {
      if (!inscripcion.cliente.tipo) {
        return;
      }
      if (inscripcion.cliente.tipo === "cliente") {
        totalInscritos += 1;
      }
    });
    return totalInscritos;
  }
}
