


































































































































// decoradores
import { Component, Emit, Vue } from "vue-property-decorator";
// validaciones
import { email, required } from "vuelidate/lib/validators";
import SVGIcon from "@/components/SVGIcon.vue";
import { Firebase } from "@/config/firebase";
import firebase from "firebase";
import FirebaseError = firebase.FirebaseError;
import { mapGetters } from "vuex";

@Component({
  components: { SVGIcon },
  computed: mapGetters({
    getErrorLogin: "getErrorLogin",
  }),
  validations: {
    email: { required, email },
    password: { required },
  },
})
export default class Formulario extends Vue {
  created(): void {
    this.email = "";
    this.password = "";
  }

  checkbox = false;
  email = "";
  password = "";
  registroLogin = 0;
  tipo: "password" | "text" = "password";
  recuperarClave = false;
  invalidEmail = false;
  userNotFound = false;
  userDisabled = false;
  wrongPassword = false;

  get emailErrors(): Array<string> {
    const errors: string[] = [];
    if (!this.$v.email.$dirty) return errors;
    if (!this.$v.email.email) errors.push("Debe ser un correo válido");
    if (!this.$v.email.required) errors.push("Campo requerido");
    if (this.invalidEmail) errors.push("Correo inválido");
    if (this.userNotFound) errors.push("Usuario no encontrado");
    if (this.userDisabled) errors.push("Usuario deshabilitado");
    return errors;
  }

  get passwordErrors(): Array<string> {
    const errors: string[] = [];
    if (!this.$v.password.$dirty) return errors;
    if (!this.$v.password.required) errors.push("Campo requerido");
    if (this.wrongPassword) errors.push("Contraseña incorrecta");
    return errors;
  }

  @Emit("cerrar")
  onCerrar(): void {
    this.recuperarClave = false;
    return;
  }

  @Emit("click-google")
  onClickGoogle(): void {
    return;
  }

  @Emit("click-facebook")
  onClickFacebook(): void {
    return;
  }

  @Emit("open-snackbar")
  onOpenSnackbar(mensaje: { text: string; color: string }): {
    text: string;
    color: string;
  } {
    return mensaje;
  }

  @Emit("terminos")
  onTerminos(): void {
    return;
  }

  @Emit("loginError")
  onLoginError(data: string): string {
    return data;
  }

  resetErrors(): void {
    this.invalidEmail = false;
    this.userNotFound = false;
    this.userDisabled = false;
    this.wrongPassword = false;
  }

  mostrarClave(): void {
    if (this.tipo === "password") {
      this.tipo = "text";
    } else {
      this.tipo = "password";
    }
  }

  async entrar(): Promise<void> {
    this.$v.$touch();
    this.resetErrors();
    if (this.$v.$invalid) return;
    if (this.getErrorLogin === "inhabilitado") {
      this.onLoginError("inhabilitado");
      return;
    }
    try {
      await Firebase.auth().signInWithEmailAndPassword(
        this.email,
        this.password
      );
      this.onCerrar();
    } catch (error) {
      console.log(error);
      switch (error.code) {
        case "auth/invalid-email":
          this.invalidEmail = true;
          break;
        case "auth/user-disabled":
          this.userDisabled = true;
          break;
        case "auth/user-not-found":
          this.userNotFound = true;
          break;
        case "auth/wrong-password":
          this.wrongPassword = true;
          break;
      }
      this.$v.$touch();
    }
  }

  async recuperar(): Promise<void> {
    this.$v.email.$touch();
    this.resetErrors();
    if (this.$v.email.$invalid) return;
    try {
      await Firebase.auth().sendPasswordResetEmail(this.email);
      this.onOpenSnackbar({
        text: "Se ha enviado un correo para recuperar la contraseña",
        color: "success",
      });
    } catch (error) {
      const errorFirebase = error as FirebaseError;
      switch (errorFirebase.code) {
        case "auth/invalid-email":
          this.invalidEmail = true;
          break;
        case "auth/user-not-found":
          this.userNotFound = true;
          break;
      }
      this.$v.email.$touch();
    }
  }
}
