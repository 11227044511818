




















































































































// decoradores
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { CentroCosto } from "@/typings/store/plugins/easyFirestore/centrosCostos";
import SVGIcon from "@/components/SVGIcon.vue";
// tipos

// tipos
@Component({
  components: {
    SVGIcon,
  },
})
export default class InfoCentroCosto extends Vue {
  @Prop() readonly centroCosto!: CentroCosto;
  @Prop({ type: Boolean }) readonly showTitulo!: boolean;

  get id(): string {
    return this.centroCosto.id || "N/R";
  }

  get codigo(): string {
    return this.centroCosto.CodiCC || "N/R";
  }

  get descripcion(): string {
    return this.centroCosto.DescCC || "N/R";
  }

  get nivel(): string {
    return this.centroCosto.NivelCC || "N/R";
  }

  get activo(): string {
    return this.centroCosto.Activo || "N/R";
  }

  get descFormato(): string {
    return this.centroCosto.Desc_formato || "N/R";
  }

  @Emit("cerrar")
  onCerrar(): void {
    return;
  }
}
