import { RouteConfig } from "vue-router";
import TipoEgresos from "@/views/TipoEgresos.vue";

const tipoEgresos: RouteConfig = {
    path: "/tipoEgresos",
    name: "tipoEgresos",
    component: TipoEgresos,
};

export default tipoEgresos;
