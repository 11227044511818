


































































































































































// decoradores
import { Vue, Component } from "vue-property-decorator";
// vuex
import { mapActions, mapGetters } from "vuex";
// tipos
import { Calendario } from "@/typings/views/agenda";
// componentes
import TituloVista from "@/components/TituloVista.vue";
import FormularioAgenda from "@/components/agenda/Formulario.vue";
import Informacion from "@/components/agenda/Info.vue";
import { fechaLocal } from "@/helpers/date";
import {
  DateCalendar,
  EventCalendarClick,
  EventsCalendar,
  SesionesxCurso,
} from "@/typings/vuetify/vCalendar";
import { Sesion } from "@/typings/store/plugins/easyFirestore/sesiones";
import { SesionProgramada } from "@/typings/store/plugins/easyFirestore/sesionesProgramadas";
import { DataFormularioSesionProgramada } from "@/typings/components/agenda/formulario";
import { TipoUsuario } from "@/typings/store/plugins/easyFirestore/usuarios";
import { Curso } from "@/typings/store/plugins/easyFirestore/cursos";
import { Inscripcion } from "@/typings/store/plugins/easyFirestore/inscripciones";
import Time from "@/helpers/Time";
import SVGIcon from "@/components/SVGIcon.vue";

const routeGuard = (vm: Vue): boolean => {
  const permisos = vm.getUsuario?.permisos;
  if (permisos && permisos["agenda"]) {
    return true;
  }
  const rutas = vm.getRutasUsuario;
  if (!rutas) {
    console.error("error => !rutas => rutas: ", rutas);
    return false;
  }
  if (rutas.length > 0) {
    const to = rutas[0].to;
    if (to) {
      const ruta = vm.$route;
      if (to.name == ruta.name) {
        return false;
      }
      if (vm.$route.name !== to.name) {
        vm.$router.replace(to);
      }
      return false;
    }
  }
  if (vm.$route.name !== "inicio") {
    vm.$router.replace({ name: "inicio" });
  }
  return false;
};

@Component({
  computed: mapGetters({
    getUsuario: "usuario/get",
    getRutasUsuario: "usuario/getRutas",
    getArraySesionesProgramadas: "sesionesProgramadas/getArray",
    getArraySesiones: "sesiones/getArray",
    getArrayCursos: "cursos/getArray",
    getArrayInscripciones: "inscripciones/getArray",
  }),
  methods: mapActions({
    setSesionProgramada: "sesionesProgramadas/set",
  }),
  beforeRouteEnter(to, from, next) {
    next(routeGuard);
  },
  components: {
    TituloVista,
    FormularioAgenda,
    SVGIcon,
    Informacion,
  },
})
export default class VistaUsuarios extends Vue {
  created(): void {
    if (!routeGuard(this)) {
      return;
    }
    this.calendario.model = fechaLocal();
  }

  dialogoFormulario = false;
  dialogoEvento = false;

  selectedOpen = false;
  sesionProgramada: SesionProgramada | null = null;
  selectedDay = "";

  calendario: Calendario = {
    model: "",
    fechaInicio: "",
    fechaFin: "",
    tipo: "week",
  };

  colors = {
    grabado: "red",
    envivo: "green",
    offline: "grey",
  };

  cantidadCursos: Array<SesionesxCurso> = [];

  snackbar = {
    model: false,
    color: "error",
    texto: "",
  };

  async openSnackbar(texto: string, color: string): Promise<void> {
    this.snackbar.model = false;
    await this.$nextTick();
    this.snackbar.texto = texto;
    this.snackbar.color = color;
    this.snackbar.model = true;
  }

  get semana(): string {
    const lunes = this.calendario.model.toDate();
    const domingo = this.calendario.model.toDate();
    lunes.setDate(
      lunes.getDate() - (lunes.getDay() == 0 ? 6 : lunes.getDay() - 1)
    );
    domingo.setDate(
      domingo.getDate() + 7 - (domingo.getDay() == 0 ? 7 : domingo.getDay())
    );
    lunes.setHours(0, 0, 0, 0);
    domingo.setHours(23, 59, 59);
    return `${(this.calendario.fechaInicio = lunes.format(
      "DD-MM-YYYY"
    ))} ~ ${(this.calendario.fechaFin = domingo.format("DD-MM-YYYY"))}`;
  }

  get tipoUsuario(): TipoUsuario {
    return this.getUsuario?.tipo ?? "sudo";
  }

  get sesionesProgramadas(): Array<SesionProgramada> {
    return this.getArraySesionesProgramadas.filter((sesionProgramada) => {
      const filtrarPorCliente =
        this.tipoUsuario == "cliente"
          ? this.inscripciones.some((inscripcion) =>
              Object.values(inscripcion.pago.cursos).some(
                (curso) => curso.id == sesionProgramada.sesion.curso.id
              )
            )
          : true;
      const filtrarPorRelator =
        this.tipoUsuario == "relator"
          ? sesionProgramada.sesion.curso.relator.id == this.getUsuario?.id
          : true;
      const filtrarPorFecha =
        this.calendario.fechaInicio && this.calendario.fechaFin
          ? sesionProgramada.fecha.toDate().getTime() >=
              this.calendario.fechaInicio.toDate().getTime() &&
            sesionProgramada.fecha.toDate().getTime() <=
              this.calendario.fechaFin.toDate().getTime()
          : true;
      return (
        sesionProgramada &&
        filtrarPorCliente &&
        filtrarPorRelator &&
        filtrarPorFecha
      );
    });
  }

  get sesiones(): Array<Sesion> {
    return this.getArraySesiones.filter((sesion) => {
      if (this.tipoUsuario == "relator") {
        const filtroRelator = sesion.curso.relator.id == this.getUsuario?.id;
        const filtroEstado =
          this.getArrayCursos.find((curso) => curso.id == sesion.curso.id)
            ?.estado == "aceptado";
        const filtroSesionEnvivo = sesion.estado == "en-vivo";
        return sesion && filtroRelator && filtroEstado && filtroSesionEnvivo;
      } else {
        return sesion;
      }
    });
  }

  get cursos(): Array<Curso> {
    return this.getArrayCursos.filter((curso) => {
      if (this.tipoUsuario == "relator") {
        const filtroCurso = curso.estado == "aceptado";
        const filtroRelatorId = curso.relator.id == this.getUsuario?.id;
        const filtroSesionEnvivo = this.getArraySesiones
          .filter((sesion) => sesion.curso.id == curso.id)
          .some((item) => item.estado == "en-vivo");
        return curso && filtroCurso && filtroRelatorId && filtroSesionEnvivo;
      } else {
        const cursoinscrito =
          this.tipoUsuario == "cliente"
            ? this.inscripciones.some((inscripcion) =>
                Object.values(inscripcion.pago.cursos).some(
                  (course) => course.id == curso.id
                )
              )
            : true;
        return curso && cursoinscrito;
      }
    });
  }

  get numeroClases(): number {
    if (this.tipoUsuario == "admin") {
      return this.events.reduce((suma, evento) => {
        if (this.calendario.fechaInicio && this.calendario.fechaFin) {
          if (
            evento.start.getTime() >=
              this.calendario.fechaInicio.toDate().getTime() &&
            evento.end.getTime() <= this.calendario.fechaFin.toDate().getTime()
          ) {
            return suma + 1;
          }
        }
        return suma;
      }, 0);
    }
    return this.sesionesProgramadas.reduce((suma, sesion) => {
      if (this.calendario.fechaInicio && this.calendario.fechaFin) {
        if (
          sesion.fecha.toDate().getTime() >=
            this.calendario.fechaInicio.toDate().getTime() &&
          sesion.fecha.toDate().getTime() <=
            this.calendario.fechaFin.toDate().getTime()
        ) {
          return suma + 1;
        }
      }
      return suma;
    }, 0);
  }

  get numeroAsistentes(): number {
    if (this.tipoUsuario == "admin") {
      return this.getArrayInscripciones.reduce((total, inscripcion) => {
        return (
          total +
          Object.values(inscripcion.pago.cursos).reduce((suma, curso) => {
            if (
              this.events
                .filter(
                  (event) =>
                    event.start.getTime() >=
                      this.calendario.fechaInicio.toDate().getTime() &&
                    event.end.getTime() <=
                      this.calendario.fechaFin.toDate().getTime()
                )
                .some((evento) => curso.id == evento.curso.id)
            ) {
              return suma + 1;
            }
            return suma;
          }, 0)
        );
      }, 0);
    }
    // if (this.tipoUsuario=="admin"){
    //   return this.events.reduce(evento)
    // }
    console.log(this.events.length);
    return this.getArrayInscripciones.reduce((total, inscripcion) => {
      return (
        total +
        Object.values(inscripcion.pago.cursos).reduce((suma, curso) => {
          if (
            this.sesionesProgramadas
              .filter(
                (sesionProgramada) =>
                  new Date(
                    `${sesionProgramada.fecha}${sesionProgramada.horaInicio}`
                  ).getTime() >=
                    this.calendario.fechaInicio.toDate().getTime() &&
                  new Date(
                    `${sesionProgramada.fecha}${sesionProgramada.horaFin}`
                  ).getTime()
              )
              .some((sesion) => curso.id == sesion.sesion.curso.id)
          ) {
            return suma + 1;
          }
          return suma;
        }, 0)
      );
    }, 0);
  }

  get inscripciones(): Array<Inscripcion> {
    return this.getArrayInscripciones.filter((inscripcion) => {
      if (this.getUsuario?.tipo == "cliente") {
        if (inscripcion.cliente.id == this.getUsuario?.id) {
          return inscripcion;
        }
        return inscripcion;
      }
      return inscripcion;
    });
  }

  get events(): EventsCalendar {
    this.CantidadSesionesXCurso();
    if (this.getUsuario?.tipo == "admin") {
      return this.sesiones.map((sesion) => {
        const id = sesion.id ?? "";
        const curso = sesion.curso;
        const name = `Tema: ${sesion.tema} - Sesion: ${sesion.numero}`;
        const number = sesion.numero.toString();
        const start =
          sesion.estado == "grabada"
            ? this.ObtenerFechaInicio(sesion)
            : this.ObtenerFechaInicio(sesion);
        const end =
          sesion.estado == "grabada"
            ? this.ObtenerFechaFin(start, sesion)
            : this.ObtenerFechaFin(start, sesion);
        const color =
          new Date().getTime() < end.getTime()
            ? sesion.estado == "grabada"
              ? this.colors.grabado
              : this.colors.envivo
            : this.colors.offline;

        return {
          id,
          curso,
          name,
          number,
          start,
          end,
          color,
          timed: true,
        };
      });
    } else {
      return this.sesionesProgramadas.map((sesionProgramada) => {
        const id = sesionProgramada.id ?? "";
        const curso = sesionProgramada.sesion.curso;
        const name = `Tema: ${sesionProgramada.sesion.tema} - Sesion: ${sesionProgramada.sesion.numero}/`;
        const number = sesionProgramada.sesion.numero.toString();
        const start = `${sesionProgramada.fecha}${sesionProgramada.horaInicio}`.toDate();
        const end = `${sesionProgramada.fecha}${sesionProgramada.horaFin}`.toDate();
        const color =
          new Date().getTime() < end.getTime()
            ? this.colors.envivo
            : this.colors.offline;

        return {
          id,
          curso,
          name,
          number,
          start,
          end,
          color,
          timed: true,
        };
      });
    }
  }

  ObtenerFechaFin(fecha: Date, sesion: Sesion): Date {
    if (sesion.estado == "grabada") {
      const time = new Time(
        fecha.getDate(),
        fecha.getHours(),
        fecha.getMinutes(),
        0
      );
      time.add(Time.fromMinutes(sesion.duracion));
      const fechaInicio = new Date(fecha);
      fechaInicio.setHours(time.hours, time.minutes);
      return fechaInicio;
    }
    if (
      this.sesionesProgramadas.find(
        (sesionProgramada) => sesionProgramada.sesion.id == sesion.id
      )
    ) {
      const sesionOnline = this.sesionesProgramadas.find(
        (sesionProgramada) => sesionProgramada.sesion.id == sesion.id
      );
      const fechaFin = new Date(
        `${sesionOnline?.fecha}${sesionOnline?.horaFin}`.toDate()
      );
      return fechaFin;
    }

    return new Date();
  }

  ObtenerFechaInicio(sesion: Sesion): Date {
    if (sesion.estado == "grabada") {
      const duracion = this.cantidadCursos[
        this.cantidadCursos.findIndex(
          (cantidad) => cantidad.nombre == sesion.curso.nombre
        )
      ].duracionDias;
      const cantidadSesiones = this.cantidadCursos[
        this.cantidadCursos.findIndex(
          (cantidad) => cantidad.nombre == sesion.curso.nombre
        )
      ].cantidad;
      if (sesion.numero == 1) {
        const fecha1 = sesion.curso.inicio.toDate();
        fecha1.setHours(this.random(10, 20), this.random(1, 58));
        return fecha1;
      } else {
        if (sesion.numero > 1 && sesion.numero < cantidadSesiones) {
          const diasExtras =
            Math.floor(duracion / cantidadSesiones) * sesion.numero;
          const fecha2 = sesion.curso.inicio.toDate();
          fecha2.setDate(fecha2.getDate() + diasExtras);
          fecha2.setHours(this.random(10, 20), this.random(1, 58));
          return fecha2;
        } else {
          const fecha3 = sesion.curso.fin.toDate();
          fecha3.setHours(this.random(10, 20), this.random(1, 58));
          return fecha3;
        }
      }
    }
    if (
      this.sesionesProgramadas.find(
        (sesionProgramada) => sesionProgramada.sesion.id == sesion.id
      )
    ) {
      const sesionOnline = this.sesionesProgramadas.find(
        (sesionProgramada) => sesionProgramada.sesion.id == sesion.id
      );
      return new Date(
        `${sesionOnline?.fecha}${sesionOnline?.horaInicio}`.toDate()
      );
    }
    return new Date();
  }

  CantidadSesionesXCurso(): void {
    console.log(this.sesiones.length);
    this.sesiones.forEach((sesion) => {
      if (
        this.cantidadCursos.isEmpty ||
        !this.cantidadCursos.some(
          (curso) => curso.nombre == sesion.curso.nombre
        )
      ) {
        const cantidad = {} as SesionesxCurso;
        cantidad.nombre = sesion.curso.nombre;
        cantidad.duracionDias =
          sesion.curso.fin.toDate().getDate() -
          sesion.curso.inicio.toDate().getDate();
        cantidad.cantidad = 1;
        this.cantidadCursos.push(cantidad);
      } else {
        this.cantidadCursos[
          this.cantidadCursos.findIndex(
            (valor) => valor.nombre === sesion.curso.nombre
          )
        ].cantidad =
          this.cantidadCursos[
            this.cantidadCursos.findIndex(
              (valor) => valor.nombre === sesion.curso.nombre
            )
          ].cantidad + 1;
      }
    });
  }

  abrirFormulario(fechaCalendario: DateCalendar): void {
    if (this.tipoUsuario == "relator") {
      this.selectedDay = fechaCalendario.date.toDate().format("DD-MM-YYYY");
      this.dialogoFormulario = true;
    } else {
      this.selectedDay = "";
      this.dialogoFormulario = false;
    }
  }

  abrirDialogo(): void {
    this.selectedDay = "";
    this.dialogoFormulario = true;
  }

  abrirMenu(selectedEvent: EventCalendarClick): void {
    if (this.tipoUsuario == "relator") {
      this.sesionProgramada =
        this.sesionesProgramadas.find(
          (sesion) => selectedEvent.event.id == sesion.id
        ) ?? null;
      this.dialogoFormulario = true;
    } else {
      if (this.tipoUsuario == "cliente") {
        this.sesionProgramada =
          this.sesionesProgramadas.find(
            (sesion) => selectedEvent.event.id == sesion.id
          ) ?? null;
        this.dialogoEvento = true;
      }
      if (this.tipoUsuario == "admin") {
        this.dialogoEvento = false;
      }
    }
  }

  async clickGuardar(data: DataFormularioSesionProgramada): Promise<void> {
    const sesionProgramada = data.doc;

    this.setSesionProgramada(sesionProgramada);
    if (sesionProgramada.id != this.sesionProgramada?.id) {
      this.openSnackbar(
        `Haz agendado la clase ${sesionProgramada.sesion.tema} ${sesionProgramada.sesion.numero} con Éxito`,
        "success"
      );
    }
    if (sesionProgramada.id == this.sesionProgramada?.id) {
      this.openSnackbar(
        `Haz editado  la clase ${sesionProgramada.sesion.tema} ${sesionProgramada.sesion.numero} con Éxito`,
        "warning"
      );
    }
    this.dialogoFormulario = false;
  }

  cerrarDialogoFormulario(tipo: string): void {
    if (tipo == "dialogo") {
      this.dialogoFormulario = false;
    } else {
      this.dialogoEvento = false;
    }
    this.sesionProgramada = null;
  }

  random(inicio: number, fin: number): number {
    return inicio + Math.random() * (fin - inicio);
  }
}
