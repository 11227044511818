













































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { Sesion } from "@/typings/store/plugins/easyFirestore/sesiones";
import { Curso } from "@/typings/store/plugins/easyFirestore/cursos";
import { mapActions, mapGetters } from "vuex";
import SVGIcon from "@/components/SVGIcon.vue";
import { VideoVisto } from "@/typings/store/plugins/easyFirestore/videosVistos";

@Component({
  components: { SVGIcon },
  computed: mapGetters({
    getSesiones: "sesiones/get",
    getCursos: "cursos/get",
  }),
  methods: mapActions({
    setVideoVisto: "videosVistos/set",
  }),
})
export default class Reproductor extends Vue {
  created(): void {
    this.sid = (this.$route.params.sid as string) ?? "";
    if (!this.sid && this.$route.name !== "cursosDisponibles") {
      this.$router.push({
        name: "cursosDisponibles",
      });
    }
  }

  mounted(): void {
    document.addEventListener("mouseup", this.mouseUpHandler);
    document.addEventListener("mousemove", this.mouseMoveHandler);
    document.addEventListener("keydown", this.keyDownHandler);
    document.addEventListener("fullscreenchange", this.fullScreenChangeHandler);
  }

  hide = false;
  sid = "";
  interval: number | null = null;
  timeOut: number | null = null;
  status = false;
  currentTime = 0;
  duration = 0;
  muted = false;
  volume = 1;
  progressIndicatorClicked = false;
  showingControls = true;
  fullscreen = false;
  lastSecondSended = 0;
  showPlayNext = false;
  infoIcon = "";
  infoText = "";

  get sesion(): Sesion {
    return this.getSesiones[this.sid];
  }

  get curso(): Curso {
    const id = this.sesion.curso.id ?? "";
    return this.getCursos[id];
  }

  get nextSesion(): Sesion | null {
    const sesiones = Object.values(this.getSesiones).filter(
      (sesion) =>
        sesion.curso.id === this.curso.id && sesion.estado === "grabada"
    );
    const numero = this.sesion.numero ?? 0;
    return sesiones.find((sesion) => sesion.numero === numero + 1) ?? null;
  }

  get videoId(): string {
    if (this.sesion?.link) {
      const url = new URL(this.sesion.link);
      const pathSplit = url.pathname.split("/");
      return pathSplit.find((path) => path.length > 10) ?? "";
    }
    return "";
  }

  get video(): HTMLVideoElement {
    return this.$refs.video as HTMLVideoElement;
  }

  get progressBar(): HTMLDivElement {
    return this.$refs.progressBar as HTMLDivElement;
  }

  get videoContainer(): HTMLDivElement {
    return this.$refs.videoContainer as HTMLDivElement;
  }

  get controlsContainer(): HTMLDivElement {
    return this.$refs.controlsContainer as HTMLDivElement;
  }

  get infoCard(): HTMLDivElement {
    return this.$refs.infoCard as HTMLDivElement;
  }

  get durationString(): string {
    let minutes: string | number = Math.floor(this.duration / 60);
    minutes = isNaN(minutes) ? "00" : minutes.toString().padStart(2, "0");
    let seconds: string | number = Math.floor(this.duration % 60);
    seconds = isNaN(seconds) ? "00" : seconds.toString().padStart(2, "0");
    return `${minutes}:${seconds}`;
  }

  get currentTimeString(): string {
    let minutes: string | number = Math.floor(this.currentTime / 60);
    minutes = isNaN(minutes) ? "00" : minutes.toString().padStart(2, "0");
    let seconds: string | number = Math.floor(this.currentTime % 60);
    seconds = isNaN(seconds) ? "00" : seconds.toString().padStart(2, "0");
    return `${minutes}:${seconds}`;
  }

  @Watch("videoId")
  onVideoId(videoId: string): void {
    this.resetView();
    this.status = false;
    this.video.src = `https://drive.google.com/uc?export=preview&id=${videoId}`;
    this.video.load();
    this.infoIcon = "sync-alt";
    this.infoText = "Cargando...";
    this.infoCard.style.opacity = "1";
  }

  showInfo(icon: string, text?: string): void {
    this.infoIcon = icon;
    this.infoText = text ?? "";
    this.infoCard.style.opacity = "1";
    if (this.timeOut) window.clearTimeout(this.timeOut);
    this.timeOut = window.setTimeout(() => {
      this.infoCard.style.opacity = "0";
    }, 1000);
  }

  goBack(): void {
    const id = this.curso?.id ?? "";
    if (
      this.$route.name !== "informacionCompleta" ||
      this.$route.params.id !== id
    ) {
      this.$router.push({
        name: "informacionCompleta",
        params: {
          id: id,
        },
      });
    }
  }

  onProgress(): void {
    if (this.video.readyState === 4 && this.duration > 0) {
      try {
        const buffered = this.video.buffered;
        const progress = buffered.end(0);
        const perc = (progress * 100) / this.duration;
        this.progressBar.style.setProperty("--loaded-bar-width", `${perc}%`);
      } catch (error) {
        console.error("ERROR HANDLED");
      }
    }
  }

  onLoadedDataVideo(): void {
    this.duration = this.video?.duration ?? 0;
    this.playPause();
    if (this.infoIcon || this.infoText) this.infoCard.style.opacity = "0";
    if (this.$route.params.tiempo) {
      this.video.currentTime = parseFloat(this.$route.params.tiempo);
    }
  }

  onTimeUpdate(): void {
    this.currentTime = this.video.currentTime;
    const res = Math.round(this.currentTime % 30);
    if (res === 0 && Math.round(this.currentTime) !== this.lastSecondSended) {
      this.lastSecondSended = Math.round(this.currentTime);
      const videoVisto: VideoVisto = {
        id: this.sesion.id ?? "",
        tiempo: this.currentTime,
        fecha: new Date().toISOString(),
        duracion: this.duration,
      };
      this.setVideoVisto(videoVisto);
    }
    this.showPlayNext =
      this.duration > 0 &&
      this.duration - this.currentTime < 60 &&
      !!this.nextSesion;
    const perc =
      this.duration > 0 ? (this.currentTime * 100) / this.duration : 0;
    this.progressBar.style.setProperty("--progress-bar-width", `${perc}%`);
  }

  playPause(): void {
    if (this.status) {
      this.showInfo("pause-circle");
      this.video.pause();
    } else {
      this.showInfo("play-circle");
      this.video.play().catch((error) => {
        this.showInfo("", "Error Carga Video");
        console.error(error);
      });
    }
    this.status = !this.status;
  }

  muteUnmute(): void {
    this.muted = !this.muted;
    this.showInfo(
      this.muted ? "volume-mute" : "volume-up",
      this.muted ? "Silenciado" : `Volumen ${Math.round(this.volume * 100)}%`
    );
    this.video.muted = this.muted;
  }

  volumePlus(): void {
    this.volume += 0.1;
    if (this.volume > 1) this.volume = 1;
    this.showInfo("volume-up", `Volumen ${Math.round(this.volume * 100)}%`);
    this.video.volume = this.volume;
  }

  volumeMinus(): void {
    this.volume -= 0.1;
    if (this.volume < 0) this.volume = 0;
    this.showInfo(
      Math.round(this.volume * 100) === 0 ? "volume-off" : "volume-down",
      `Volumen ${Math.round(this.volume * 100)}%`
    );
    this.video.volume = this.volume;
  }

  backward5sec(): void {
    this.showInfo("undo-alt", "- 5 segundos");
    this.video.currentTime -= 5;
  }

  forward5sec(): void {
    this.showInfo("redo-alt", "+ 5 segundos");
    this.video.currentTime += 5;
  }

  showFullscreen(): void {
    if (!this.fullscreen) {
      this.showInfo("expand-alt");
      this.videoContainer.requestFullscreen();
    } else {
      this.showInfo("compress-alt");
      document.exitFullscreen();
    }
  }

  mouseDownHandler(): void {
    this.progressIndicatorClicked = true;
  }

  mouseUpHandler(): void {
    this.progressIndicatorClicked = false;
  }

  mouseMoveHandler(ev: MouseEvent): void {
    if (this.interval) window.clearInterval(this.interval);
    if (!this.showingControls) {
      this.showingControls = true;
      this.controlsContainer.classList.remove("hidden-controls");
    }
    if (this.progressIndicatorClicked) {
      this.changeCurrentTime(ev);
    } else {
      if (this.status && this.showingControls) {
        this.interval = window.setInterval(() => {
          this.showingControls = false;
          this.controlsContainer.classList.add("hidden-controls");
        }, 5000);
      }
    }
  }

  keyDownHandler({ key }: KeyboardEvent): void {
    if (key === " ") this.playPause();
    if (key === "m") this.muteUnmute();
    if (key === "f") this.showFullscreen();
    if (key === "ArrowLeft") this.backward5sec();
    if (key === "ArrowRight") this.forward5sec();
    if (key === "ArrowUp") this.volumePlus();
    if (key === "ArrowDown") this.volumeMinus();
    if (key === "r") this.reload();
  }

  fullScreenChangeHandler(): void {
    this.fullscreen = document.fullscreenElement !== null;
  }

  changeCurrentTime({ screenX }: MouseEvent): void {
    const rect = this.progressBar.getBoundingClientRect();
    const position = screenX - rect.left;
    const perc = (position * 100) / rect.width;
    this.video.currentTime = this.duration * (perc / 100);
  }

  resetView(): void {
    this.interval = null;
    this.timeOut = null;
    this.currentTime = 0;
    this.duration = 0;
    this.progressIndicatorClicked = false;
    this.showingControls = true;
    this.lastSecondSended = 0;
    this.showPlayNext = false;
  }

  playNext(): void {
    if (this.nextSesion) {
      this.sid = this.nextSesion.id ?? "";
      if (
        this.$route.name !== "reproductor" ||
        this.$route.params.sid !== this.sid
      ) {
        this.$router.push({
          name: "reproductor",
          params: {
            sid: this.sid,
          },
        });
      }
    }
  }

  reload(): void {
    this.video.load();
    this.status = false;
    this.video.currentTime = this.currentTime;
    this.infoIcon = "sync-alt";
    this.infoText = "Recargando...";
    this.infoCard.style.opacity = "1";
  }

  destroyed(): void {
    if (this.interval) clearInterval(this.interval);
    if (this.timeOut) clearTimeout(this.timeOut);
    document.removeEventListener("mouseup", this.mouseUpHandler);
    document.removeEventListener("mousemove", this.mouseMoveHandler);
    document.removeEventListener("keydown", this.keyDownHandler);
    document.removeEventListener(
      "fullscreenchange",
      this.fullScreenChangeHandler
    );
    const videoVisto: VideoVisto = {
      id: this.sesion.id ?? "",
      tiempo: this.currentTime,
      fecha: new Date().toISOString(),
      duracion: this.duration,
    };
    this.setVideoVisto(videoVisto);
  }
}
