

















































import { Component, Vue, Prop } from "vue-property-decorator";
import { ChartData } from "@/typings/vista/monitoreo";
import { Curso } from "@/typings/store/plugins/easyFirestore/cursos";
import { ItemsTablaCursos } from "@/typings/components/cursos/tabla";
import { Pago } from "@/typings/store/plugins/easyFirestore/pagos";
import { GChart } from "vue-google-charts";
//import string from "@/helpers/string";
@Component({
  components: { GChart },
})
export default class ComponenteRecaudacionHistorica extends Vue {
  //tabs = null;
  @Prop() readonly cursos!: Array<Curso>;
  @Prop() readonly cursoItems!: ItemsTablaCursos;
  @Prop() readonly getArrayPagos!: Array<Pago>;
  @Prop() readonly filtroPagoFecha!: Array<Pago>;
  @Prop() readonly tabs!: number;

  dialog = false;
  expectativa = 0;

  chartOptions = {
    //title: "Company Performance",
    //subtitle: "Ventas, Expenses, and Profit: 2014-2017",
    curveType: "function",
    legend: "none",
  };
  get chartData(): ChartData {
    //console.log(this.tabs);
    let recaudacionCurso: {
      [id: string]: {
        mes: string;
        recaudado: number;
        esperado: number;
        color: string;
      };
    } = {};
    //let recaudado = 0;

    for (const pago of this.filtroPagoFecha) {
      if (pago.resultado?.status == "AUTHORIZED") {
        if (this.tabs == 0) {
          if (
            recaudacionCurso[
              pago.resultado.transaction_date.toDate().format("HH:mm DD-MM")
            ]
          ) {
            recaudacionCurso[
              pago.resultado.transaction_date.toDate().format("HH:mm DD-MM")
            ].recaudado += pago.resultado.amount;
          } else {
            recaudacionCurso = Object.assign({}, recaudacionCurso, {
              [pago.resultado.transaction_date
                .toDate()
                .format("HH:mm DD-MM")]: {
                mes: pago.resultado.transaction_date
                  .toDate()
                  .format("HH:mm DD-MM"),
                recaudado: pago.resultado.amount,
                esperado: this.expectativa || 0,
                color: "#3d3569",
              },
            });
          }
        }
        if (this.tabs == 1) {
          if (
            recaudacionCurso[
              pago.resultado.transaction_date.toDate().format("DD-MMMM")
            ]
          ) {
            recaudacionCurso[
              pago.resultado.transaction_date.toDate().format("DD-MMMM")
            ].recaudado += pago.resultado.amount;
          } else {
            recaudacionCurso = Object.assign({}, recaudacionCurso, {
              [pago.resultado.transaction_date.toDate().format("DD-MMMM")]: {
                mes: pago.resultado.transaction_date.toDate().format("DD-MMMM"),
                recaudado: pago.resultado.amount,
                esperado: this.expectativa || 0,
                color: "#3d3569",
              },
            });
          }
        }
        if (this.tabs == 2) {
          if (
            recaudacionCurso[
              pago.resultado.transaction_date.toDate().format("DD-MMMM")
            ]
          ) {
            recaudacionCurso[
              pago.resultado.transaction_date.toDate().format("DD-MMMM")
            ].recaudado += pago.resultado.amount;
          } else {
            recaudacionCurso = Object.assign({}, recaudacionCurso, {
              [pago.resultado.transaction_date.toDate().format("DD-MMMM")]: {
                mes: pago.resultado.transaction_date.toDate().format("DD-MMMM"),
                recaudado: pago.resultado.amount,
                esperado: this.expectativa || 0,
                color: "#3d3569",
              },
            });
          }
        }
        if (this.tabs == 3) {
          if (
            recaudacionCurso[
              pago.resultado.transaction_date.toDate().format("YYYY")
            ]
          ) {
            recaudacionCurso[
              pago.resultado.transaction_date.toDate().format("YYYY")
            ].recaudado += pago.resultado.amount;
          } else {
            recaudacionCurso = Object.assign({}, recaudacionCurso, {
              [pago.resultado.transaction_date.toDate().format("YYYY")]: {
                mes: pago.resultado.transaction_date.toDate().format("YYYY"),
                recaudado: pago.resultado.amount,
                esperado: this.expectativa || 0,
                color: "#3d3569",
              },
            });
          }
        }
      }
    }

    const data: ChartData = Object.values(recaudacionCurso)
      .map((pago) => {
        return [pago.mes, pago.recaudado, pago.esperado, pago.color];
      })
      .sort();
    //console.log(recaudacionCurso);

    data.unshift(["Mes", "Recaudado", "Esperado", { role: "style" }]);
    return data;
  }
}
